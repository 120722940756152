import React from 'react'

const FBIconMap = ({ fill, onClick }) => {
    return (
        <div className='float-icon-container' onClick={onClick}>
            <svg id="new_icons" data-name="new icons" xmlns="http://www.w3.org/2000/svg" width="22mm" height="22mm" viewBox="0 0 62.3622 62.3622">
                <path d="m31.1811.7624C18.2036.7624,7.6835,11.2828,7.6835,24.2606s20.2714,37.3392,23.4976,37.3392,23.4976-24.362,23.4976-37.3392S44.1583.7624,31.1811.7624Zm0,34.6028c-6.483,0-11.7388-5.2558-11.7388-11.7388s5.2558-11.7388,11.7388-11.7388,11.7386,5.2558,11.7386,11.7388-5.2556,11.7388-11.7386,11.7388Z" fill={fill} />
            </svg></div>
    )
}

export default FBIconMap