// MAP COMPONENT
import { MapSymbol } from '../ComponentGeneral/MapSymbol'

// DATA
import TrackAccessData from '../../../../data/FixedLocationsGeoData/TrackAccess.json'


// IMAGES
import Pedestrian from '../../../../assets/MapSymbols/Icons/AccessPedestrian-Dot.png'
import RRV from '../../../../assets/MapSymbols/Icons/AccessRRV-Dot.png'

import PedestrianDot from '../../../../assets/MapSymbols/Icons/AccessPedestrian-Dot.png'
import RRVDot from '../../../../assets/MapSymbols/Icons/AccessRRV-Dot.png'

import {
    AccessHeaderData,

}
    from '../../../../constants/appSetup/tableFilters'

import { JSONDataFilterByProperty } from '../../../../constants/functions/jsonDataFilterFunctions'



export const TrackAccessPoints = (map, isVisible, createPopUp, pathname, routesStructure, addTableData, tableContent) => {

    addTableData(TrackAccessData, AccessHeaderData)

    const relatedPathNames = [routesStructure.civils_all_route, routesStructure.civils_signals_route, routesStructure.civils_equipment_route, routesStructure.civils_route_works_route,
    routesStructure.signalling_all_route, routesStructure.signalling_heads_route, routesStructure.signalling_cubicles_route, routesStructure.cabling_all_route,
    routesStructure.cabling_power, routesStructure.cabling_fibre]



    const pedestrianPoints = JSONDataFilterByProperty(TrackAccessData, { "type": "Pedestrian Access" }, "Pedestrian Points", "type")

    const rrvPoints = JSONDataFilterByProperty(TrackAccessData, { "type": "RRV Access" }, "Pedestrian Points", "type")



    const symbolsData = [
        {
            data: pedestrianPoints,
            source: 'PedestrianAccessDot',
            imageSource: PedestrianDot,
            imageName: 'PedestrianAccessDot-img',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 13,
            iconSize: 0.05,
            rotation: ['get', 'rotation'],
            relatedPathNames: relatedPathNames,
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: rrvPoints,
            source: 'RRVAccessDot',
            imageSource: RRVDot,
            imageName: 'RRVAccessDot-img',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 13,
            iconSize: 0.05,
            rotation: ['get', 'rotation'],
            relatedPathNames: relatedPathNames,
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: pedestrianPoints,
            source: 'PedestrianAccess',
            imageSource: Pedestrian,
            imageName: 'PedestrianAccess-img',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 13,
            maxZoom: 19,
            iconSize: 0.2,
            rotation: ['get', 'rotation'],
            relatedPathNames: relatedPathNames,
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: rrvPoints,
            source: 'RRVAccess',
            imageSource: RRV,
            imageName: 'RRVAccess-img',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 13,
            maxZoom: 19,
            iconSize: 0.2,
            rotation: ['get', 'rotation'],
            relatedPathNames: relatedPathNames,
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },



    ]

    // // CIVILS
    symbolsData.forEach((symbolData) => MapSymbol(symbolData));

}
