// MAP COMPONENT
import { MapSymbol } from '../ComponentGeneral/MapSymbol'

// DATA
import LevelCrossingData from '../../../../data/FixedLocationsGeoData/LevelCrossingData.json'

// IMAGES

import LevelCrossing from '../../../../assets/MapSymbols/Icons/LX-Existing.png'

import {
    levelCrossingHeaderData,

}
    from '../../../../constants/appSetup/tableFilters'


export const LevelCrossingComponents = (map, isVisible, createPopUp, pathname, routesStructure, addTableData, tableContent) => {

    const dataAddLX = addTableData(LevelCrossingData, levelCrossingHeaderData)



    const symbolsData = [
        {
            data: LevelCrossingData,
            source: 'LevelCrossingzoom',
            imageSource: LevelCrossing,
            imageName: 'LevelCrossing',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 12,
            iconSize: 0.2,
            rotation: ['get', 'rotation'],
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_signals_route, routesStructure.civils_equipment_route, routesStructure.civils_route_works_route,
            routesStructure.signalling_all_route, routesStructure.signalling_heads_route, routesStructure.signalling_cubicles_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: LevelCrossingData,
            source: 'LevelCrossing',
            imageSource: LevelCrossing,
            imageName: 'LevelCrossing',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 12,
            maxZoom: 18,
            iconSize: 0.4,
            rotation: ['get', 'rotation'],
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_signals_route, routesStructure.civils_equipment_route, routesStructure.civils_route_works_route,
            routesStructure.signalling_all_route, routesStructure.signalling_heads_route, routesStructure.signalling_cubicles_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
    ]

    // // CIVILS
    symbolsData.forEach((symbolData) => MapSymbol(symbolData));

}
