import React from 'react'

const TestingGeneralIcon = () => {
    return (
        <g id="Icons"><rect x="7.927" y="11.3484" width="14.4715" height="2.179" fill="#ffffff" />
            <rect x="7.927" y="18.5854" width="14.4715" height="2.179" fill="#ffffff" />
            <rect x="7.927" y="25.8224" width="14.4715" height="2.179" fill="#ffffff" />
            <rect x="7.927" y="33.0594" width="14.4715" height="2.179" fill="#ffffff" />
            <g>
                <g>
                    <path d="M23.9919,29.5235c1.736,1.4169,.9314,2.4024,.9314,2.4024l-4.5965,2.2229,1.2747-4.9348s.6546-1.1077,2.3904,.3095Z" fill="#ffffff" />
                    <g>
                        <path d="M35.934,8.7954s1.7621-.2037,3.8441,1.4961c2.0819,1.6994,2.3155,3.5322,2.3155,3.5322l-12.3224,15.095s.339-1.3655-2.3154-3.5325c-2.6548-2.1668-3.8443-1.4958-3.8443-1.4958l12.3225-15.095Z" fill="#ffffff" />
                        <path d="M29.7714,29.1763c-.0388,0-.0788-.0088-.115-.0275-.1075-.0538-.1638-.175-.135-.2919,.0013-.0075,.2288-1.2658-2.2277-3.271-2.4428-1.994-3.5479-1.4733-3.5604-1.4683-.11,.0544-.2438,.0275-.3188-.0669-.0763-.095-.0788-.2288-.0025-.3232l12.3226-15.0954c.0425-.0519,.1038-.085,.17-.0925,.0763-.0113,1.894-.1969,4.0354,1.5527,2.1377,1.7439,2.399,3.6198,2.409,3.6991,.01,.07-.0113,.1406-.055,.1956l-12.3226,15.0947c-.0513,.0613-.125,.0944-.2,.0944Zm-5.5331-5.645c.6613,.0238,1.7689,.34,3.3804,1.6558,1.6489,1.3451,2.189,2.419,2.3553,3.0766l11.845-14.5097c-.0875-.3994-.5113-1.8815-2.204-3.2628-1.6914-1.3801-3.1991-1.457-3.5529-1.4445l-11.8238,14.4847Z" fill="#ffffff" />
                    </g>
                    <path d="M23.2782,26.2599c.4261,.0822,1.203,.3741,2.377,1.3323,1.1794,.9628,1.63,1.6756,1.8019,2.0896l-5.7857,2.798,1.6069-6.2199m-.2784-1.0599c-.3632,0-.5371,.0981-.5371,.0981l-2.3452,9.0774,8.4555-4.089s.388-1.3255-2.2664-3.4923c-1.6396-1.3383-2.7201-1.5942-3.3069-1.5942h0Z" fill="#ffffff" />
                </g>
                <g>
                    <path d="M29.536,13.5862V7.7243c0-2.0541-1.6712-3.7251-3.7254-3.7251h-3.4121v1.999h3.4121c.9521,0,1.7264,.7742,1.7264,1.7261v8.3056l1.999-2.4437Z" fill="#ffffff" />
                    <path d="M5.3422,3.9992c-2.0541,0-3.7252,1.671-3.7252,3.7251v29.2608c0,2.054,1.6712,3.7252,3.7252,3.7252H25.8106c2.0542,0,3.7254-1.6713,3.7254-3.7252v-4.8056l-1.999,2.4437v2.3619c0,.9519-.7743,1.7263-1.7264,1.7263H5.3422c-.9517,0-1.7264-.7743-1.7264-1.7263V7.7243c0-.9519,.7747-1.7261,1.7264-1.7261h3.412v-1.999h-3.412Z" fill="#ffffff" />
                </g>
                <path d="M22.3981,8.8197H8.7542V2.2896h13.644v6.5301Zm-12.5538-1.0901h11.4637V3.3798H9.8443V7.7296Z" fill="#ffffff" />
            </g>
        </g>
    )
}
export default TestingGeneralIcon