import React from 'react'

const SignallingGeneralIcon = () => {
    return (
        <g id="Icons">
            <g>
                <path d="M42.4041,26.5034l-1.1946-3.3906h-2.1077l-.9338-3.5422h1.9389l-.9769-2.7725h-1.693l-.8657-3.2838h1.5288l-.7866-2.2326h-1.3308l-.635-2.4086h1.2145l-.6408-1.8188h-1.0532l-.4835-1.8337h.9672l-.5261-1.4932h-.8347l-.153-.5803h-1.1594l.1254,.5803h-6.004l.1254-.5803h-1.1594l-.153,.5803h-.8348l-.5261,1.4932h.9672l-.4835,1.8337h-1.0532l-.6409,1.8188h1.2145l-.635,2.4086h-1.3308l-.7866,2.2326h1.5288l-.8657,3.2838h-1.693l-.9769,2.7725h1.9389l-.7828,2.9692,2.4674-1.7928,.2543-1.1764h12.8537l.7657,3.5422h-4.636l4.2773,3.3906h1.0916l.6275,2.903h2.4057l-.7653-2.903h2.4084ZM26.4774,5.2205h6.6496l.3964,1.8337h-7.4424l.3964-1.8337Zm-.7896,3.6525h8.2287l.5207,2.4086h-9.2701l.5207-2.4086Zm-1.7131,7.925l.7099-3.2838h10.2352l.7099,3.2838h-11.655Z" fill="#ffffff" />
                <path d="M11.8379,3.1931H4.4178c-1.9152,0-3.4678,1.5526-3.4678,3.4678v30.349c0,1.9152,1.5526,3.4678,3.4678,3.4678h7.4201c1.9153,0,3.4678-1.5526,3.4678-3.4678V6.6609c0-1.9152-1.5526-3.4678-3.4678-3.4678Zm-3.71,34.6438c-2.472,0-4.476-2.004-4.476-4.476s2.004-4.476,4.476-4.476,4.476,2.004,4.476,4.476-2.004,4.476-4.476,4.476Zm0-11.5512c-2.472,0-4.476-2.004-4.476-4.4759s2.004-4.476,4.476-4.476,4.476,2.004,4.476,4.476-2.004,4.4759-4.476,4.4759Zm0-11.4999c-2.472,0-4.476-2.004-4.476-4.476s2.004-4.476,4.476-4.476,4.476,2.004,4.476,4.476-2.004,4.476-4.476,4.476Z" fill="#ffffff" />
                <path d="M38.512,30.8701l-9.2604-7.653c-1.7455-1.4425-4.2818-1.3977-5.9752,.1054l-3.9381,3.4955c-1.1232,.9971-1.7661,2.4272-1.7661,3.9291v6.2852c0,1.2685,1.0283,2.2968,2.2968,2.2968h17.577c1.276,0,2.3103-1.0343,2.3103-2.3102v-3.5064c0-1.0223-.4562-1.9911-1.2442-2.6423Zm-16.1007,7.1991c-1.7399,0-3.1503-1.3651-3.1503-3.0491s1.4104-3.0491,3.1503-3.0491,3.1503,1.3652,3.1503,3.0491-1.4105,3.0491-3.1503,3.0491Zm4.0271-8.175c-1.7399,0-3.1503-1.3651-3.1503-3.0491s1.4104-3.0491,3.1503-3.0491,3.1503,1.3652,3.1503,3.0491-1.4104,3.0491-3.1503,3.0491Zm8.3843,8.175c-1.7399,0-3.1503-1.3651-3.1503-3.0491s1.4105-3.0491,3.1503-3.0491,3.1503,1.3652,3.1503,3.0491-1.4104,3.0491-3.1503,3.0491Z" fill="#ffffff" />
            </g>
        </g>
    )
}
export default SignallingGeneralIcon