import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../Contexts/AuthContext'
import { DataContext } from '../../Contexts/DataContext';
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../firebase'
import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'
import { addUpdateLog } from '../../constants/databaseFunctions/UpdateLog'
import DbCard from '../../components/cards/DbCard'
import './form.css'

import FormLineMap from '../../components/FormMap/FormLineMap';
import { defaultFlyToCoord } from '../../constants/styles/mapStyles';
const CableForm = () => {
    const params = useParams();
    const navigate = useNavigate()
    const selectedItemId = params.id
    const userData = useContext(UserContext)
    const data = useContext(DataContext)


    const selectCurrentItem = (dataSet) => {
        let output = [];
        dataSet.features.forEach((item, index) => {

            if (item.id === selectedItemId) {
                output.push(item)
            }
        })
        return output
    }



    const [formDirect, setFormDirect] = useState("/app/project-summary")

    const latestURL = window.location.href

    const [cableType, setCableType] = useState({})

    const powerDetails = {
        name: "Power",
        currentItemData: selectCurrentItem(data.PowerCablesData)[0],
        firebaseFolder: "PowerCables",
        pathRedirect: "/app/cabling/power?type=table"
    }
    const fibreDetails = {
        name: "Fibre",
        currentItemData: selectCurrentItem(data.FibreCablesData)[0],
        firebaseFolder: "FibreCables",
        pathRedirect: "/app/cabling/fibre?type=table"
    }

    const powerFormChecker = "powercablesform"

    useEffect(() => {
        const urlArray = latestURL.split(/[/]/);
        urlArray.includes(powerFormChecker) ?
            setCableType(powerDetails) : setCableType(fibreDetails)

    }, [latestURL])



    // const currentItemData = selectCurrentItem(data.PowerCablesData)[0]
    const itemName = cableType.currentItemData ? cableType.currentItemData.properties.Name : ""

    // const lng = selectedItemId ? currentItemData.geometry.coordinates[0] : -0.6832668084
    // const lat = selectedItemId ? currentItemData.geometry.coordinates[1] : 51.1020658558
    const lng = defaultFlyToCoord[0]
    const lat = defaultFlyToCoord[1]
    const zoom = selectedItemId ? 16 : 10

    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`

    const statusOptions = ["Outstanding", "Complete", "Existing"]
    const type = ["Power", "Fibre"]

    let defaultFormData = {
        properties: {
            Name: ""

        }, geometry: { coordinates: [] }, type: "LineString"
    }

    const coordinatesFactory = (coordinates) => coordinates.map((coordinate => {
        return { lat: coordinate[0], long: coordinate[1] }
    }));


    // IE CREATE 
    if (!selectedItemId || selectedItemId === undefined) {
        defaultFormData["dateCreated"] = new Date()
        defaultFormData["createdBy"] = logInUser
        defaultFormData["geometry"]["coordinates"] = []
        defaultFormData["type"] = "Feature"
        defaultFormData["geometry"]["type"] = "LineString"
        defaultFormData["properties"]["type"] = ""
        defaultFormData["properties"]["Name"] = "Cable Route Name"
        defaultFormData["properties"]["status"] = statusOptions[0]
        defaultFormData["properties"]["length total"] = ""
        defaultFormData["properties"]["length complete"] = ""

    }
    // IE UPDATE
    if (selectedItemId) {
        defaultFormData["dateUpdated"] = new Date()
        defaultFormData["updatedBy"] = logInUser
    }

    const [formData, setFormData] = useState(defaultFormData);



    // To display data recieved from mapbox on map only
    // Firebase cannot accept data that is nested as per a geojson Linestring so it needs converting when pushing and pulling to and from the database
    const [mapBoxGeoJsonData, setMapBoxGeoJsonData] = useState({
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                geometry: {
                    "type": "LineString",
                    coordinates: defaultFormData["geometry"]["coordinates"]
                }
            }
        ]
    })

    const updateMapboxSelectedCoords = (coordinates) => {
        let _mapBoxGeoJsonData = { ...mapBoxGeoJsonData };
        _mapBoxGeoJsonData.features[0].geometry.coordinates = coordinates
        setMapBoxGeoJsonData(_mapBoxGeoJsonData)
    }


    const handleFormPropertiesInputChange = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            properties: { ...formData.properties, [name]: value }
        }))
    }

    const setTotalDistance = (value) => {

        setFormData((curr) => ({
            ...curr,
            properties: { ...formData.properties, ["length total"]: value }
        }))
    }

    const handleLngLatMapBox = (lngLat) => {
        setFormData((curr) => ({
            ...curr,
            geometry: {
                ...curr.geometry,
                coordinates: [lngLat.lng, lngLat.lat]
            }
        }))
    }

    useEffect(() => {
        if (params.id && cableType.firebaseFolder) {
            const docRef = doc(db, cableType.firebaseFolder, selectedItemId)
            getDoc(docRef).then(snapshot => {
                let documentData = snapshot.data();
                setFormData({ ...documentData, id: snapshot.id, dateUpdated: defaultFormData.dateUpdated, updatedBy: logInUser })
                updateMapboxSelectedCoords(documentData.geometry.coordinates.map(({ lat, long }) => [lat, long]))
            })

        }
    }, [cableType])

    const [loadingData, setLoadingData] = useState(false)

    const updateDataOnFireBase = async () => {
        setLoadingData(true)
        let _formData = { ...formData };
        _formData.geometry.coordinates = coordinatesFactory(mapBoxGeoJsonData.features[0].geometry.coordinates)


        const collectionRef = doc(db, cableType.firebaseFolder, selectedItemId)
        await setDoc(collectionRef, _formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "Cable route details updated",
            update: `${userName} updated`
        })
        localStorage.removeItem("ProjectDetails")
        setLoadingData(false)
        navigate(cableType.pathRedirect)
        // window.location.reload(false);
    }

    const CreateDataEntryToFireBase = async () => {
        setLoadingData(true)
        let _formData = { ...formData };
        _formData.geometry.coordinates = coordinatesFactory(formData.geometry.coordinates)
        _formData.properties.type = cableType.name


        await addDoc(collection(db, cableType.firebaseFolder), _formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: `New ${cableType.name} Cable Route`,
            update: `${userName} added ${formData.properties.Name}`,
        })
        navigate(cableType.pathRedirect)
        setLoadingData(false)
        // window.location.reload(false);
    }

    let cardTitle = selectedItemId ? `Edit ${itemName}` : `Add ${cableType.name} Cable`
    let mainBtnTitle = selectedItemId ? "Update" : "Add"
    let btnFunction = selectedItemId ? updateDataOnFireBase : CreateDataEntryToFireBase

    return (
        <>

            <div className="dashboard-container">
                <DbCard
                    cardType={'graph-card-wide card-height-min card-static'}
                    icon={""}
                    title={`${cardTitle}`}
                    cardContent={""}
                    btnText={"Go back"}
                    mainBtnText={`${mainBtnTitle} Cable`}
                    loadingData={loadingData}
                    btnDisplayBool={true}
                    btnMainDisplayBool={true}
                    OnClickFuncMainBtn={btnFunction}
                    OnClickFunc={() => navigate(-1)}>

                    <>
                        <div className='form-sub-title'>{`Cable Details`}</div>
                        <div className='form-boxes-container'>
                            {/* GENERAL SECTION */}
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>{`${cableType.name} Cable Route Name`}</p>
                                    <input className='input-box' type="text" value={formData["properties"]["Name"]} name={'Name'} placeholder={"Edit cable route name..."} onChange={handleFormPropertiesInputChange} /><br />
                                </label>

                            </div>

                            {/* <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Cable Type</p>
                                    <select className='input-box' name={"type"} value={formData["properties"]["type"]} onChange={handleFormPropertiesInputChange}>
                                        {type.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div> */}
                        </div>
                        <div className='form-sub-title'>Installation status</div>
                        <div className='form-boxes-container'>

                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>{`${cableType.name} Route Status`}</p>
                                    <select className='input-box' name={"status"} value={formData["properties"]["status"]} onChange={handleFormPropertiesInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Cable Total Length(m)</p>
                                    <input className='input-box' type="number" value={formData["properties"]["length total"]} name={'length total'} placeholder={"Edit total length"} onChange={handleFormPropertiesInputChange} /><br />
                                </label>
                                <label className='label-container'>
                                    <p className='label-title'>Cable Total Complete(m)</p>
                                    <input className='input-box' type="number" value={formData["properties"]["length complete"]} name={'length complete'} placeholder={"Edit total complete"} onChange={handleFormPropertiesInputChange} /><br />
                                </label>

                            </div>

                        </div>

                        <div className='form-sub-title'>Asset Location</div>
                        <div className='form-boxes-container'>

                            <div className="input-container">
                                <FormLineMap lng={lng} lat={lat} zoom={zoom} geoJsonData={mapBoxGeoJsonData} setTotalDistance={setTotalDistance} setGeoJsonData={setMapBoxGeoJsonData}></FormLineMap>
                                <div className='form-group-column-container'>


                                </div>

                            </div>

                        </div>
                    </>
                </DbCard>
            </div >
        </>
    )
}

export default CableForm