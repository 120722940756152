

import SignalDotOutstanding from '../../assets/MapSymbols/Icons/Signal-Dot-Outstanding.png'
import SignalDotComplete from '../../assets/MapSymbols/Icons/Signal-Dot-Complete.png'
import SignalDotRetained from '../../assets/MapSymbols/Icons/Signal-Dot-Retained.png'


import Signal2AOutstanding from '../../assets/MapSymbols/Icons/Signal-2-Aspect-Outstanding.png'
import Signal2ABaseComplete from '../../assets/MapSymbols/Icons/Signal-2-Aspect-Base-Complete.png'
import Signal2AStructureComplete from '../../assets/MapSymbols/Icons/Signal-2-Aspect-Structure-Complete.png'
import Signal2AComplete from '../../assets/MapSymbols/Icons/Signal-2-Aspect-Complete.png'
import Signal2AStructureExisting from '../../assets/MapSymbols/Icons/Signal-2-Aspect-Structure-Existing.png'
import Signal2AHeadExisting from '../../assets/MapSymbols/Icons/Signal-2-Aspect-Structure-Head-Existing.png'

import Signal3AOutstanding from '../../assets/MapSymbols/Icons/Signal-3-Aspect-Outstanding.png'
import Signal3ABaseComplete from '../../assets/MapSymbols/Icons/Signal-3-Aspect-Base-Complete.png'
import Signal3AStructureComplete from '../../assets/MapSymbols/Icons/Signal-3-Aspect-Structure-Complete.png'
import Signal3AComplete from '../../assets/MapSymbols/Icons/Signal-3-Aspect-Complete.png'
import Signal3AStructureExisting from '../../assets/MapSymbols/Icons/Signal-3-Aspect-Structure-Existing.png'
import Signal3AHeadExisting from '../../assets/MapSymbols/Icons/Signal-3-Aspect-Structure-Head-Existing.png'

import Signal4AOutstanding from '../../assets/MapSymbols/Icons/Signal-4-Aspect-Outstanding.png'
import Signal4ABaseComplete from '../../assets/MapSymbols/Icons/Signal-4-Aspect-Base-Complete.png'
import Signal4AStructureComplete from '../../assets/MapSymbols/Icons/Signal-4-Aspect-Structure-Complete.png'
import Signal4AComplete from '../../assets/MapSymbols/Icons/Signal-4-Aspect-Complete.png'
import Signal4AStructureExisting from '../../assets/MapSymbols/Icons/Signal-4-Aspect-Structure-Existing.png'
import Signal4AHeadExisting from '../../assets/MapSymbols/Icons/Signal-4-Aspect-Structure-Head-Existing.png'

import SignalGroundOutstanding from '../../assets/MapSymbols/Icons/Signal-Ground-Outstanding.png'
import SignalGroundBaseComplete from '../../assets/MapSymbols/Icons/Signal-Ground-Base-Complete.png'
import SignalGroundStructureComplete from '../../assets/MapSymbols/Icons/Signal-Ground-Structure-Complete.png'
import SignalGroundComplete from '../../assets/MapSymbols/Icons/Signal-Ground-Complete.png'
import SignalGroundStructureExisting from '../../assets/MapSymbols/Icons/Signal-Ground-Structure-Existing.png'
import SignalGroundHeadExisting from '../../assets/MapSymbols/Icons/Signal-Ground-Structure-Head-Existing.png'

import SignalBannerOutstanding from '../../assets/MapSymbols/Icons/Signal-Banner-Outstanding.png'
import SignalBannerBaseComplete from '../../assets/MapSymbols/Icons/Signal-Banner-Base-Complete.png'
import SignalBannerStructureComplete from '../../assets/MapSymbols/Icons/Signal-Banner-Structure-Complete.png'
import SignalBannerComplete from '../../assets/MapSymbols/Icons/Signal-Banner-Complete.png'
import SignalBannerStructureExisting from '../../assets/MapSymbols/Icons/Signal-Banner-Structure-Existing.png'
import SignalBannerHeadExisting from '../../assets/MapSymbols/Icons/Signal-Banner-Head-Existing.png'

import SignalOffOutstanding from '../../assets/MapSymbols/Icons/Signal-Off-Outstanding.png'
import SignalOffComplete from '../../assets/MapSymbols/Icons/Signal-Off-Complete.png'
import SignalOffExisting from '../../assets/MapSymbols/Icons/Signal-Off-Existing.png'

export const signalMapSymbols = {

    SignalDotOutstanding,
    SignalDotComplete,
    SignalDotRetained,

    Signal2AOutstanding,
    Signal2ABaseComplete,
    Signal2AStructureComplete,
    Signal2AComplete,
    Signal2AStructureExisting,
    Signal2AHeadExisting,

    Signal3AOutstanding,
    Signal3ABaseComplete,
    Signal3AStructureComplete,
    Signal3AComplete,
    Signal3AStructureExisting,
    Signal3AHeadExisting,

    Signal4AOutstanding,
    Signal4ABaseComplete,
    Signal4AStructureComplete,
    Signal4AComplete,
    Signal4AStructureExisting,
    Signal4AHeadExisting,

    SignalGroundOutstanding,
    SignalGroundBaseComplete,
    SignalGroundStructureComplete,
    SignalGroundComplete,
    SignalGroundStructureExisting,
    SignalGroundHeadExisting,

    SignalBannerOutstanding,
    SignalBannerBaseComplete,
    SignalBannerStructureComplete,
    SignalBannerComplete,
    SignalBannerStructureExisting,
    SignalBannerHeadExisting,

    SignalOffOutstanding,
    SignalOffExisting,
    SignalOffComplete,




}


