

var mapLoaded = false;
const visibleByRelatedPathname = (relatedPathnames, pathname) => {

    return relatedPathnames.includes(pathname) ? "visible" : "none"
}
const generateFeaturesFromFirebaseData = (data) => {

    return data.features.map(item => {
        return {
            type: "Feature",
            geometry: {
                "type": "LineString",
                coordinates: item["geometry"]["coordinates"]
            }
        }
    })
}
function addLayerElements(configData) {

    let { data, source, map, isVisible, minZoom, maxZoom, relatedPathNames, paint, type, currentPathName } = configData


    map.addSource(source, {
        'type': 'geojson',
        'data': {
            type: "FeatureCollection",
            features: generateFeaturesFromFirebaseData(data)
        },

    });

    map.addLayer({
        id: source,
        type: "line",
        source: source,
        "minzoom": minZoom,
        // "maxzoom": maxZoom,
        // For ones not using map symbol use an array instead
        // of relatedPathNames
        metadata: { pathLayer: true, relatedPathNames },
        layout: {
            visibility: currentPathName === false ? "visible" : visibleByRelatedPathname(relatedPathNames, window.location.pathname),
        },
        paint: paint


    });


}
export const MapLine = (configData) => {
    const { data, source, map, isVisible, minZoom, maxZoom, paint, type } = configData

    map.on('load', () => addLayerElements(configData))

    if (map.loaded() && map.isStyleLoaded()) {
        addLayerElements(configData)
    }
}
