import React from 'react'

const SignalPostIcon = () => {
    return (
        <g>
            <rect x="5.253" y="33.8004" width="3.3474" height="3.3474" fill="#fff" />
            <rect x="34.3997" y="33.8004" width="3.3474" height="3.3474" fill="#fff" />
            <rect x="11.3917" y="33.8004" width="20.2167" height="3.3474" fill="#fff" />
            <g>
                <circle cx="24.5472" cy="5.2065" r="3.2667" fill="#808e93" />
                <path d="m14.7693,4.864v28.9588h13.024V4.864c0,1.7982-1.4578,3.256-3.256,3.256s-3.256-1.4578-3.256-3.256-1.4578-3.256-3.256-3.256-3.256,1.4578-3.256,3.256Z" fill="#fff" />
            </g>
            <rect x="9.4468" y="29.5563" width="1.0984" height="11.8356" fill="#fff" />
            <rect x="32.4548" y="29.5563" width="1.0984" height="11.8356" fill="#fff" />
        </g>
    )
}


export default SignalPostIcon;