import React, { useContext } from 'react'
import { useDataContext } from '../Contexts/DataContext';
import { UserAuth } from '../Contexts/AuthContext';
import Dashboard from '../Dashboard'
import Loading from './Loading'
import { Outlet, Navigate } from 'react-router-dom';

export default function DataLoadedPage() {
    const data = useDataContext()
    const { user, loading } = UserAuth();
    if(user == null && !loading){
        console.log("No user sign in", "user fail")
        return <Navigate to="/"/>
    }
    if(loading || data.dataLoading){
        return <Loading/>
    }
    
    return (
        !data.loading ? <Dashboard>
            <Outlet></Outlet>
        </Dashboard> : <Loading />
    )
}
