import React, { useEffect, useState } from 'react'
import './SummaryBox.css'

import SummaryBoxItem from './SummaryBoxItem'

import Icons from '../../../../constants/icons/Icons';

import PieChart from '../../../../components/GraphBarLeft/svgGraph/PieChartFrontPage'

import { useDataContext } from '../../../../Contexts/DataContext';

import { UserAuth } from '../../../../Contexts/AuthContext';

const SummaryBoxNew = () => {

    const selectDispline = (dataSet, selector) => {
        let output = [];
        dataSet.forEach((item) => {

            if (item.title === selector) {
                output.push(item)
            }
        })
        return output
    }

    const data = useDataContext();

    const { dropDownMenuTitles, ProjectDetails } = data

    const civilsData = selectDispline(dropDownMenuTitles, "Civils")[0].subMenu[0].pieData

    const signallingData = selectDispline(dropDownMenuTitles, "Signalling")[0].subMenu[0].pieData

    const cableData = selectDispline(dropDownMenuTitles, "Cabling")[0].subMenu[0].pieData




    function percentageCalculator(data) {
        let percentageTotal = 0
        let totalQty = data.length
        let percentageValue = 0

        data.forEach((item) => {
            percentageTotal += item.percentageValue


        })
        percentageValue = percentageTotal / totalQty

        return Math.round(percentageValue)
    }




    const civilsPercent = percentageCalculator(civilsData)
    let cablePercent = ProjectDetails.cablePercentage
    const cablingPercent = percentageCalculator(cableData)

    const signallingPercent = percentageCalculator(signallingData)
    let testingPercent = ProjectDetails.testingPercentage






    return (
        <>
            <SummaryBoxItem
                value={`${civilsPercent}%`}
                title={"Civils Works"}
            >
                <PieChart
                    PieIcon={Icons.CivilsGeneralIcon}

                    percentageValue={civilsPercent}

                    styleChange={"item.styleChange"}>
                </PieChart>
            </SummaryBoxItem>

            <SummaryBoxItem
                value={`${signallingPercent}%`}
                title={"Signalling"}
            >
                <PieChart
                    PieIcon={Icons.SignallingGeneralIcon}
                    percentageValue={signallingPercent}
                    styleChange={"item.styleChange"}>
                </PieChart>
            </SummaryBoxItem>

            <SummaryBoxItem
                value={`${cablingPercent}%`}
                title={"Cabling Works"}

            >
                <PieChart
                    PieIcon={Icons.CableGeneralIcon}
                    percentageValue={cablingPercent}
                    styleChange={"item.styleChange"}>
                </PieChart>
            </SummaryBoxItem>

            <SummaryBoxItem
                value={`${testingPercent}%`}
                title={"Testing"}
                buttonDisplay={true}
            >
                <PieChart
                    PieIcon={Icons.TestingGeneralIcon}

                    percentageValue={testingPercent}

                    styleChange={"item.styleChange"}>
                </PieChart>
            </SummaryBoxItem>

        </ >
    )
}

export default SummaryBoxNew
