import { useContext, useEffect, useState } from "react";
import { DataContext } from "../Contexts/DataContext";
import { UserAuth } from "../Contexts/AuthContext";

const useCheckUserRights = () => {

    const data = useContext(DataContext)
    const { user } = UserAuth();
    const [buttonStatus, setButtonStatus] = useState(true)

    useEffect(() => {
        const currentUserId = user.uid
        const userStatus = data.UserMetaData.some((user) => user.id == currentUserId)
        userStatus ? setButtonStatus(true) : setButtonStatus(false)
    }, []);

    return { data, user, buttonStatus };
}


export default useCheckUserRights;