import React, { useEffect, useState } from "react";
import WhiteDot from "../assets/GeneralIcons/White_dot.svg";

import { useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import { navigationPathFactory } from "./SideNav"

export default function DropdownItem({ onClick, isSelected, img, title, isHamburgerNavCollapsed, setIsHamburgerNavCollapsed, subMenu, pathname, isMobileLayout }) {

  const [listButton, setListButton] = useState(false);
  const [logoClass, setLogoClass] = useState("dropdownmenu-logo");
  const [searchParams, setSearchParams] = useSearchParams();


  const ListButtonHandler = () => {
    setListButton((prev) => !prev);
  };
  const [subMenuList, setSubMenuList] = useState([])


  useEffect(() => {
    setSubMenuList(subMenu)

  }, [subMenu])
  const location = useLocation();



  const handleLogoClass = () => {
    if (isHamburgerNavCollapsed && !isSelected) {
      setLogoClass("dropdownmenu-logo dropdownCenter noSubMenu");
    } else if (!isHamburgerNavCollapsed && isSelected) {
      setLogoClass(`dropdownmenu-logo titleOn ${subMenu.length == 0 ? "noSubMenu" : ""} `);
    } else if (!isHamburgerNavCollapsed && !isSelected) {
      setLogoClass("dropdownmenu-logo ");
    } else if (isHamburgerNavCollapsed && isSelected) {
      setLogoClass(`dropdownmenu-logo dropdownCenter titleOn noSubMenu`);
    }
  };

  useEffect(() => {
    handleLogoClass();
  }, [isHamburgerNavCollapsed, isSelected]);
  let navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const typeParam = urlParams.get("type");


  const getMenuItemClassNameBySubMenu = (subMenu) => {
    if (subMenu.length) {
      if (isSelected && isHamburgerNavCollapsed) {
        return `dropdownmenu-list listOff`
      } else if (isSelected && !isHamburgerNavCollapsed) {
        return `dropdownmenu-list`
      }
      return isSelected ? "dropdownmenu-list" : "dropdownmenu-list listOff"
    }

    return "dropdownmenu-list listOff"

  }

  const isMenuItemActive = (menuItem, currentPath) => {
    if (menuItem.path == currentPath) {

      return "active";
    }

    if (menuItem.path == undefined && menuItem.hasOwnProperty("query") && menuItem.query == typeParam) {
      return "active"
    }

    return ""
  }

  const getMenuItemDisplayClass = (item) => {
    if (item.metadata) {
      return item.metadata.displayOffOnMobile ? "mobile-display-off" : ""
    }
    return "flex"
  }
  return (
    <div className="dropdownmenu">
      <div
        onClick={onClick}

        className={logoClass}
      >
        <img src={img} alt="" />
        <p
          className={
            isHamburgerNavCollapsed
              ? "dropdownmenu-logo-titleHidden"
              : "dropdownmenu-logo-title"
          }
        >
          {title}
        </p>
      </div>
      <div
        className={
          getMenuItemClassNameBySubMenu(subMenu)
        }
      >

        <ul>

          {(subMenu.map((item, index) => {
            return (<li className={isMenuItemActive(item, location.pathname) + " " + getMenuItemDisplayClass(item)} key={index + "sub-menu"} style={{ display: "flex" }} onClick={() => {
              Object.keys(item).includes("path") ? navigate(navigationPathFactory(item.path, typeParam)) : setSearchParams({ "type": item.query })


              if (isMobileLayout)
                setIsHamburgerNavCollapsed(true);
            }}>
              <img src={WhiteDot} alt={item.alt}


              />  <label>{item.listItemtitle}</label>
            </li>
            )

          }))}

        </ul>

      </div>
    </div>
  );
}
