import React, { useState } from 'react'

import BarButton from '../../components/Buttons/BarButton/BarButton'

import ButtonWhite from '../../assets/ComponentIcons/ButtonIcon01_Button_white.svg'

import './SideBarRight.css'

const SideBarRight = ({ assetData, sidebarRightDisplayToggle, fixMapSize, flyToSignal, createPopUp, sideBarRightTitle, filteredData }) => {



    const [isCollapsed, setCollapse] = useState(false)
        ;
    const [filterSearch, setFilterSearch] = useState("");
    let classNameToggle = "sidebar-right-background";

    if (isCollapsed) {
        classNameToggle += " sidebar-right-collapsed";

    }
    const SidebarRightbuttonFunction = () => {
        setCollapse(!isCollapsed);

    }
    const createPopupTableData = (tableData, featureProperties) => {
        let popUpTableData = {}
        let tablePopUpKeyTitles = tableData.filter((tableKey, index) => tableKey.popUpDisplay)
        tablePopUpKeyTitles.forEach((tableKeyTitleData, index) => {


            popUpTableData[tableKeyTitleData.Title] = featureProperties[tableKeyTitleData.property]
        })
        return popUpTableData;
    }
    const onItemClick = (item, tableData) => {

        flyToSignal(item);
        createPopUp(item, null, createPopupTableData(tableData, item.properties));
    }

    return (
        <>
            <div className={`flex-vertical ${classNameToggle}`}>
                <div className='heading flex-horizontal' >
                    <BarButton
                        className={'button-white-img'}
                        image={ButtonWhite}
                        buttonFunction={SidebarRightbuttonFunction}
                        id={'button-sidebar-right'} />
                    <h3 className="side-bar-right-title">{sideBarRightTitle}</h3>
                </div>
                <div id='listings' className='listings'>
                    <input id="sidebarRight-input" placeholder={"Search for asset"} value={filterSearch} onChange={(event) => setFilterSearch(event.target.value)}
                        type="search" />
                    {
                        assetData.map((groupData, index) => <div key={index + "sb"}>

                            {groupData.features.filter((feature) => {
                                if (filterSearch == "")
                                    return true;
                                return feature.properties.Name.toLowerCase().includes(filterSearch.toLowerCase());
                            }).sort((a, b) => (a.properties.Name).localeCompare(b.properties.Name)).map((feature, index) =>
                                <div id={`listing-${feature.properties.Name}`} className="item item-text " key={index + feature.properties.Name} onClick={() => onItemClick(feature, groupData.sideBarRightData)} >
                                    <a key={index} className="title item-title" id={`link-${feature.properties.id}`}>
                                        {feature.properties.Name}
                                    </a>
                                    {groupData.sideBarRightData.map((tableValuePair, index) =>
                                        <div className='item-line-text' key={index + "gd"}>
                                            <div key={index + "prop-pair-title"} className="item-text-title">{tableValuePair.Title}: </div>
                                            <div key={index + "prop-pair-value"} className="item-text-value"> {feature.properties[tableValuePair.property]}</div>
                                        </div>)}
                                </div>
                            )}
                        </div>)
                    }
                </div>
            </div >
        </>
    )
}

export default SideBarRight