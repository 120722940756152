

// Map styles for the different mapbox view styles
export const mapStyles = {
    accessToken: process.env.REACT_APP_MAPBOX_API,
    mapStyle: process.env.REACT_APP_MAPBOX_STYLE,
    geoMapstyle: process.env.REACT_APP_MAPBOX_STYLE_GEO,
    mapStylePlain: process.env.REACT_APP_MAPBOX_STYLE_PLAIN
}

export const defaultFlyToCoord = [-0.6832668084, 51.1020658558]


export const statusTypes = ["Outstanding", "Complete", "Existing"]

export const statusColors = [{ status: "Outstanding", color: "#EF3E36" }, { status: "Complete", color: "#17BEBB" }, { status: "Existing", color: "#C5C3C6" }]

const defaultPowerCablePaint = { 'line-width': 1.5, 'line-dasharray': [8, 4] }

const defaultFibreCablePaint = { 'line-width': 1, 'line-dasharray': [2, 2] }


export const powerCableDisplayStyles = statusTypes.map((statusType) => {
    return {
        status: statusType,
        paint: {
            'line-color': statusColors.filter((color) => color.status === statusType)[0].color,
            ...defaultPowerCablePaint
        }
    }
})

export const fibreCableDisplayStyles = statusTypes.map((statusType) => {
    return {
        status: statusType,
        paint: {
            'line-color': statusColors.filter((color) => color.status === statusType)[0].color,
            ...defaultFibreCablePaint
        }
    }
})

export const equipmentDotDisplayStyles = {
    type: "symbol",
    minZoom: 10,
    maxZoom: 16,
    iconSize: 0.15,
    rotation: 0,
    text: "",
    textOffset: [0, 0],
    paint: {},
}