import React from 'react'

const RebBaseIcon = () => {
    return (
        <g id="Icons">
            <rect x="3.4852" y="9.2123" width="36.0296" height="12.2482" fill="#808e93" opacity=".6" />
            <text transform="translate(12.8801 18.5986)" fill="#fff" font-family="MyriadPro-Bold, &apos;Myriad Pro&apos;" font-size="9.9602" font-weight="700"><tspan x="0" y="0">REB</tspan></text>
            <g>
                <circle cx="3.4676" cy="24.9714" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m5.68,23.7801c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m6.2573,25.7396c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="9.4135" cy="25.4302" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m12.2293,22.9702c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m3.3854,28.6289c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m2.4107,31.3364c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857s-.1857.0831-.1857.1857c0,.1025.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="5.7003" cy="28.9732" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m7.6342,28.9422c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="8.7327" cy="27.6272" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m7.5414,31.3867c0,.1025.0831.1857.1856.1857s.1857-.0831.1857-.1857-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m12.2447,29.0814c-.1025,0-.1856.0831-.1856.1857s.0831.1857.1856.1857c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="11.9507" cy="26.5442" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="13.3741" cy="25.5849" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.5247,22.8155c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m17.273,25.3993c.1025,0,.1856-.0831.1856-.1857s-.0831-.1856-.1856-.1856c-.1025,0-.1857.0831-.1857.1856s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m19.2842,27.395c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m16.2209,29.0196c.1025,0,.1856-.0831.1856-.1857s-.0831-.1856-.1856-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m16.145,31.2789c0,.1025.0831.1856.1857.1856s.1857-.0831.1857-.1856-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m13.4824,31.4331c0,.1025.0831.1856.1857.1856s.1857-.0831.1857-.1856-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m21.2622,23.0399c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m23.5002,27.5266c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="23.7994" cy="23.9682" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="26.3082" cy="25.0434" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m26.9812,23.7671c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.495" cy="22.67" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m28.9771,26.3198c.1025,0,.1856-.0831.1856-.1856s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="29.0234" cy="28.4549" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.188" cy="31.1005" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m25.8905,29.5224c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="22.4326" cy="31.1469" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m21.5972,29.6152c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857s-.1857.0831-.1857.1857c0,.1025.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="19.23" cy="29.8937" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="30.5551" cy="25.4495" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="35.4054" cy="29.4528" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m33.4299,27.0741c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="34.4278" cy="28.2112" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.9793,26.9696c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m30.6248,28.7797c0,.1025.0831.1857.1856.1857.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="30.5551" cy="31.1237" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m35.6606,25.9949c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856c-.1025,0-.1856.0831-.1856.1856s.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m33.9666,23.651c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="32.9454" cy="23.0708" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m30.0214,27.6426c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m40.2176,27.5817c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.6776,29.2207c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.1518,22.7426c.1025,0,.1857-.0831.1857-.1857,0-.1025-.0831-.1856-.1857-.1856s-.1857.0831-.1857.1856c0,.1025.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m34.0129,31.054c-.1025,0-.1856.0831-.1856.1857s.0831.1857.1856.1857c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
            </g>
            <path d="m42.3718,33.1352H.6286v-12.0923h41.7432v12.0923Zm-40.9932-.75h40.2432v-10.5923H1.3786v10.5923Z" fill="#fff" />
        </g>
    )
}


export default RebBaseIcon;