import React from 'react'

const SignalHeadIcon = () => {
    return (
        <g id="Icons">
            <path d="m14.3072,4.4872v33.6963c0,1.93,1.5646,3.4946,3.4946,3.4946h6.92c2.3988,0,4.3434-1.9446,4.3434-4.3434V4.6431c0-1.7434-1.4133-3.1568-3.1568-3.1568h-8.6003c-1.6573,0-3.0009,1.3435-3.0009,3.0009Zm8.3615,32.8974c-2.9407.7089-5.5228-1.8735-4.8138-4.814.3332-1.3821,1.4667-2.5155,2.8488-2.8488,2.9405-.7089,5.5229,1.8731,4.814,4.8138-.3332,1.3822-1.4667,2.5157-2.8489,2.8489Zm-.0004-12.1961c-2.9403.7084-5.522-1.8733-4.8136-4.8136.333-1.3821,1.4663-2.5157,2.8483-2.8491,2.941-.7096,5.524,1.8734,4.8144,4.8144-.3334,1.382-1.467,2.5153-2.8491,2.8483Zm0-11.7457c-2.9403.7084-5.522-1.8733-4.8136-4.8136.333-1.3821,1.4663-2.5157,2.8483-2.8491,2.941-.7096,5.524,1.8734,4.8144,4.8144-.3334,1.382-1.467,2.5153-2.8491,2.8483Z" fill="#fff" />
        </g>
    )
}

export default SignalHeadIcon;
