import React from 'react'

import { useNavigate } from "react-router-dom";

import './pages.css'

const NoMobileView = () => {

    const navigate = useNavigate();

    const backRedirect = () => {
        navigate(`/app/project-summary`)

    }


    return (

        <div className='no-data-container'>
            <div className='go-back-header'> This content isnt available on Mobile, use the Desktop version</div>
            <button
                className={"log-btn"}
                onClick={backRedirect}>
                <div className='btn-go-back-text'>Go back</div>

            </button>

        </div>

    )
}

export default NoMobileView