import React from 'react'

const SignalAxleCounterIcon = () => {
    return (
        <g>
            <g>
                <polygon points="17.93 16.83 17.93 13.99 2.77 13.99 2.77 16.83 8.98 16.83 8.98 26.83 2.77 26.83 2.77 29.78 17.93 29.78 17.93 26.83 11.72 26.83 11.72 16.83 17.93 16.83" fill="#bce3f4" opacity="0.6" />
                <path d="M20.66,26.21a4.11,4.11,0,0,1-2.12-.54,4.09,4.09,0,0,1-1.67-2.27,3.53,3.53,0,0,0-1.11-1.72,4.26,4.26,0,0,0-3.31-.55l-.19-1.25a5.43,5.43,0,0,1,4.26.79A4.73,4.73,0,0,1,18,22.93a2.87,2.87,0,0,0,1.15,1.65,4.21,4.21,0,0,0,3.24.06c.76-.23,1.37-.59,3.77-2.89,1.15-1.1,1.85-1.82,2.38-2.38a18.43,18.43,0,0,1,1.93-1.81A19.19,19.19,0,0,1,34.84,15l.5,1.16a17.75,17.75,0,0,0-4,2.4,16,16,0,0,0-1.81,1.7c-.54.56-1.25,1.29-2.41,2.41-2.45,2.35-3.25,2.88-4.28,3.19A7.63,7.63,0,0,1,20.66,26.21Z" fill="#0f516e" />
                <rect x="34.52" y="9.45" width="3.04" height="17.6" fill="#0f516e" />
                <rect x="31.65" y="6.3" width="8.76" height="5.76" fill="#0f516e" />
            </g>
        </g>
    )
}


export default SignalAxleCounterIcon;