import React, { useEffect, useState } from 'react'
import './SummaryBox.css'

import { useNavigate } from "react-router-dom";

import { useDataContext } from '../../../../Contexts/DataContext';
import { UserAuth } from '../../../../Contexts/AuthContext';


export default function SummaryBoxItem(props) {



    const data = useDataContext()
    const userData = UserAuth()
    const userMetaData = userData.userData
    const [buttonAuthStatus, setButtonAuthStatus] = useState(false)

    useEffect(() => {
        const currentUserId = userData.user.uid
        const userWriteList = userMetaData

        setButtonAuthStatus(userData.user.writer)

    }, [])




    const buttonDisplay = props.buttonDisplay

    let navigate = useNavigate()

    const btnRedirect = () => {
        navigate(`/app/projectinfoform/${data.ProjectDetails.id}`)
    }
    return (
        <div className="summary-container-white-box">
            <div className="summary-inner-container">
                <div className='pie-icon-no-text '>
                    {props.children}
                </div>

                {buttonDisplay && buttonAuthStatus ? <button className='edit-button' onClick={btnRedirect}></button> : <></>}

                {/* <img className='summary-box-icon' src={props.summaryIcon} alt="icon" /> */}
                <div className="summary-info-right-container">
                    <div className="summary-value">{props.value}{props.units}</div>
                    <div className="summary-title">{props.title}</div>
                    {/* <div className="summary-comparison-container">
                        <img className="summary-comparison-arrow" src={props.arrow} alt="arrow" />
                        <div className="summary-comparison-value"> {props.differenceValue} </div>
                        <div className="summary-comparison-value-text">{props.differenceText}</div>
                    </div> */}
                </div>
            </div>
        </div>



    )
}
