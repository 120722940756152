import React from 'react'

const CivilsGeneralIcon = () => {
    return (
        <g id="Icons">
            <g>
                <path d="M34.1144,35.6498c2.0989,0,3.7998,1.5062,3.7998,3.3647s-1.7008,3.3647-3.7998,3.3647H14.349c-2.1006,0-3.8015-1.5065-3.8015-3.3647,0-.93,.4238-1.7698,1.1137-2.3798,.6857-.6082,1.6374-.9849,2.6878-.9849h19.7654Zm2.12,3.3647c0-1.4481-1.4924-2.6223-3.333-2.6223H15.562c-.9218,0-1.7543,.2936-2.359,.7677-.6029,.4755-.9757,1.1296-.9757,1.8546,0,1.4498,1.4918,2.6238,3.3348,2.6238h17.3395c1.8406,0,3.333-1.174,3.333-2.6238Z" fill="#ffffff" />
                <path d="M29.9331,10.8007c2.6801,0,2.3549,2.7142,2.3549,2.7142v3.9092h-5.1648v5.9233l-9.659,3.414v-4.5633l4.9916-11.3974h7.4773Zm-2.1535,5.3629v-3.2199h-4.887l-4.1179,8.8124v2.9722l6.791-2.5301v-6.0345h2.214Z" fill="#ffffff" />
                <path d="M33.5432,39.0145c0,.3482-.2795,.6294-.6276,.6294-.3464,0-.6294-.2812-.6294-.6294,0-.3485,.283-.6311,.6294-.6311,.3482,0,.6276,.2827,.6276,.6311Z" fill="#ffffff" />
                <path d="M32.9156,40.954c-1.0697,0-1.9416-.8681-1.9416-1.9395s.8719-1.9416,1.9416-1.9416c1.0715,0,1.9433,.8701,1.9433,1.9416s-.8718,1.9395-1.9433,1.9395Zm1.1812-1.9395c0-.6525-.5289-1.1795-1.1812-1.1795-.6505,0-1.1795,.5269-1.1795,1.1795,0,.6506,.529,1.1792,1.1795,1.1792,.6523,0,1.1812-.5286,1.1812-1.1792Z" fill="#ffffff" />
                <path d="M35.3944,22.9053h-4.8712v.8839h4.8712v-.8839Zm.0464,1.8229h-4.8747v.8839h4.8747v-.8839Zm-20.4394,4.1887l13.1634-4.4611v-6.038h6.5192s2.447-.1236,2.447,2.9775v10.3506H15.0013v-2.829Zm20.4816-6.7188v-.8842h-4.8712v.8842h4.8712Zm-.1291,5.0054v-.8839h-4.8712v.8839h4.8712Z" fill="#ffffff" />
                <rect x="26.2003" y="37.5577" width="1.2376" height="2.9138" fill="#ffffff" />
                <rect x="23.6881" y="37.5577" width="1.2376" height="2.9138" fill="#ffffff" />
                <path d="M27.7796,33.9382v-.6717c0-.1805-.1485-.3291-.3293-.3291-.1802,0-.327,.1486-.327,.3291v.6717c0,.1805,.1468,.3273,.327,.3273,.1808,0,.3293-.1468,.3293-.3273Zm-3.0412-1.6618h5.1947v2.6519h-5.1947v-2.6519Zm.8924,.9902v.6717c0,.1805,.1468,.3273,.3294,.3273,.1784,0,.3287-.1468,.3287-.3273v-.6717c0-.1805-.1503-.3291-.3287-.3291-.1826,0-.3294,.1486-.3294,.3291Zm3.4956,.6717v-.6717c0-.1805-.1468-.3273-.3288-.3273-.1802,0-.3288,.1468-.3288,.3273v.6717c0,.1805,.1486,.329,.3288,.329,.182,0,.3288-.1485,.3288-.329Z" fill="#ffffff" />
                <rect x="21.2111" y="37.5577" width="1.2376" height="2.9138" fill="#ffffff" />
                <path d="M14.5064,4.6707l1.9785-1.0662c.465-.2492,.7298-.723,.7351-1.2147h.7944l2.1359,11.125-3.3153,6.9149-2.3285-15.759Z" fill="#ffffff" />
                <path d="M16.8349,39.6457c-.3482,0-.6311-.283-.6311-.6311,0-.3485,.283-.6294,.6311-.6294,.3446,0,.6293,.2809,.6293,.6294,0,.3482-.2847,.6311-.6293,.6311Z" fill="#ffffff" />
                <path d="M15.8039,2.3899c0-.4439-.3587-.8043-.8025-.8043-.4457,0-.8043,.3605-.8043,.8043s.3587,.8046,.8043,.8046c.4438,0,.8025-.3608,.8025-.8046ZM5.1544,7.5264c-.4456,0-.8043,.3587-.8043,.8026,0,.4456,.3587,.8064,.8043,.8064,.4439,0,.8049-.3608,.8049-.8064,0-.4439-.3611-.8026-.8049-.8026Zm4.5495,6.6639h-1.9286l-4.2559-2.8803v-3.7431h.007c.0423-.4491,.3024-.868,.7262-1.0978L14.8052,.7879c.6804-.3658,1.5294-.1112,1.8934,.5678,.3675,.6807,.1133,1.5294-.5677,1.8954L6.7443,8.3061l1.0309,.5569v3.6459l1.9286,1.6815Z" fill="#ffffff" />
                <path d="M16.8349,37.8351c-.6523,0-1.183,.5287-1.183,1.1795,0,.6523,.5307,1.181,1.183,1.181,.6487,0,1.1795-.5287,1.1795-1.181,0-.6508-.5307-1.1795-1.1795-1.1795Zm0,3.1207c-1.0715,0-1.9434-.8698-1.9434-1.9413s.8719-1.9398,1.9434-1.9398,1.9398,.8683,1.9398,1.9398-.8683,1.9413-1.9398,1.9413Z" fill="#ffffff" />
                <path d="M15.4311,2.3899c0,.2369-.1932,.4297-.4297,.4297-.239,0-.4298-.1929-.4298-.4297s.1908-.4295,.4298-.4295c.2366,0,.4297,.1926,.4297,.4295Z" fill="#ffffff" />
                <path d="M5.5824,8.329c0,.2387-.1908,.4315-.428,.4315-.2366,0-.4309-.1929-.4309-.4315,0-.2369,.1943-.4297,.4309-.4297,.2372,0,.428,.1929,.428,.4297Z" fill="#ffffff" />
            </g>
        </g>
    )
}
export default CivilsGeneralIcon