import React from "react";
import { useState, useRef, useEffect } from 'react';

import { addTableData } from '../../constants/functions/addTableData'

import '../../pageStyles/Forms/form.css'

// MAPBOX LIBRARY
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from '@turf/turf';



import { UtxNewComponent } from "../../pageStyles/MapBox/MapComponents/CivilsComponents/UTXNewComponent";
import { useDataContext } from "../../Contexts/DataContext";
import { routesStructure } from "../../constants/appSetup/routesStructure";
import { fibreCableDisplayStyles, powerCableDisplayStyles } from "../../constants/styles/mapStyles";
import { EquipCubiclesCivilsComps } from "../../pageStyles/MapBox/MapComponents/CivilsComponents/EquipCubiclesCivilsComps";
import { EquipCivilsDots } from "../../pageStyles/MapBox/MapComponents/Final/EquipCivilDots";

let marker = null;

const FormLineMap = (props) => {
    const data = useDataContext()

    const pathname = '/app/civils-works/signals'
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(props.lng);
    const [lat, setLat] = useState(props.lat);
    const coordinates = [lng, lat];
    const [zoom, setZoom] = useState(props.zoom);
    const [loading, setLoading] = useState(true);
    const { geoJsonData, setGeoJsonData } = props;


    const createPopUp = () => { }

    const undoAddLinePoint = () => {
        let updatedGeojsonData = { ...geoJsonData }

        let coords = updatedGeojsonData.features[0].geometry.coordinates


        coords.pop();
        setGeoJsonData(updatedGeojsonData)

        if (marker != null) {
            marker.remove();
        }
        if (coords.length == 0) {
            return;
        }
        const [lng, lat] = coords[coords.length - 1]
        marker = new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(map.current)
    }
    const clearLinePoints = () => {
        let updatedGeojsonData = { ...geoJsonData }
        let coords = updatedGeojsonData.features[0].geometry.coordinates
        coords.splice(0, coords.length);
        setGeoJsonData(updatedGeojsonData)
        if (marker != null) {
            marker.remove();
        }
    }

    const getTotalDistanceOfCoords = (coords) => {
        let totalDistance = 0
        for (let i = 0; i < coords.length - 1; i++) {
            if (coords[i + 1] === undefined) {
                return
            }
            totalDistance += turf.distance(coords[i], coords[i + 1], 'kilometres');
        }
        return totalDistance
    }
    const addLinePoint = (coordinate) => {
        let updatedGeojsonData = { ...geoJsonData }
        let coords = updatedGeojsonData.features[0].geometry.coordinates
        coords.push(coordinate);


        setGeoJsonData(updatedGeojsonData)
    }

    useEffect(() => {

        if (!loading) {
            map.current.getSource("line").setData(geoJsonData)
        }
    }, [geoJsonData, loading])

    useEffect(() => {
        if (map.current)
            map.current.on("load", () => {
                // Heads up: if this for some reason doesnt run, the computer ran it too quickly and idle was ran before the condition was met. 

                setLoading(false)
                map.current.getSource("line").setData(geoJsonData)
                if (marker != null) {
                    marker.remove();
                }


                let lastItem = geoJsonData.features[0].geometry.coordinates[geoJsonData.features[0].geometry.coordinates.length - 1]

                if (lastItem === undefined) {
                    return
                }
                map.current.off("click")
                map.current.on("click", ({ lngLat }) => {
                    const { lng, lat } = lngLat;
                    if (marker != null) {
                        marker.remove();
                    }
                    marker = new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(map.current)
                    addLinePoint([lng, lat])
                    setLoading(false)

                })
                marker = new mapboxgl.Marker().setLngLat(lastItem).addTo(map.current)


            })
        props.setTotalDistance(getTotalDistanceOfCoords(geoJsonData.features[0].geometry.coordinates).toFixed(3))
    }, [geoJsonData])
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: process.env.REACT_APP_MAPBOX_STYLE_PLAIN,
            center: coordinates,
            zoom: zoom,
            preserveDrawingBuffer: true

        });

        map.current.on("load", () => {
            map.current.addSource("line", {
                "type": "geojson",
                "data": geoJsonData
            })
            map.current.addLayer({
                id: "line",
                type: "line",
                source: "line",
                paint: powerCableDisplayStyles[0].paint
            })


            setLoading(false)

        })




        map.current.on("click", ({ lngLat }) => {
            const { lng, lat } = lngLat;
            if (marker != null) {
                marker.remove();
            }
            marker = new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(map.current)
            addLinePoint([lng, lat])
            setLoading(false)

        })

        const displayMapElements = () => {
            EquipCivilsDots(map.current, true, () => { }, false, data.EquipmentCubicleSignallingData, routesStructure)

        }

        displayMapElements()
    }, []);


    return (
        <div className="map-main-small-line" >
            <div ref={mapContainer} className="map-container-small-line" />
            <button onClick={undoAddLinePoint}>Undo</button>
            <button onClick={clearLinePoints}>Clear</button>
        </div>
    );

}

export default FormLineMap;