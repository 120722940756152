import { addTableData } from "../functions/addTableData"
import { filterReleventPieDataBySubCategory } from "../functions/filterReleventPieDataBySubCategory"
import { sideNavIcons } from "../icons/sideNavIcons"
import { routesStructure } from "./routesStructure"
import {
    signalCivilsHeaderData,
    EquipmentCivilsHeaderData,
    UTXCivilsHeaderData,
    signallingHeaderData,
    EquipmentHeaderData,
    CableHeaderData
} from "./tableFilters"
import PieChartData from './PieChartData'
import { graphBoxData } from "../dataContextComponents/graphBoxData"
import {
    civilsSchematicGroupDisplay,
    signallingSchematicGroupDisplay,
    allCivilsSchematicDisplay,
    signalCivilsSchematicDisplay,
    civilsBasesSchematicDisplay,
    civilsUtxSchematicDisplay,
    allSignallSchematicDisplay,
    signallAssetSchematicDisplay,
    pspREBCabinetSchematicDisplay,
} from "../displayControl/DisplayToggles"

function getDropDownMenuTitles({ data }) {

    const dropDownMenuTitles = [

        {
            title: "Civils",
            img: sideNavIcons.ContractButton,
            path: routesStructure.civils_all_route,
            subMenu: [{
                listItemtitle: "All Civils Works",
                path: routesStructure.civils_all_route,
                data: [addTableData(data.SignalCivilsData, signalCivilsHeaderData), addTableData(data.EquipmentCubicleCivilsData, EquipmentCivilsHeaderData), addTableData(data.UTXProposedCentresData, UTXCivilsHeaderData)],
                pieData: (PieChartData.Civils),
                schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                schematicSubMenuToggleDisplay: allCivilsSchematicDisplay,
                alt: "civils-works-all",
                graphHeaderIcon: sideNavIcons.CivilsIcon,
                dashboardGraphData: graphBoxData,

            },
            {
                listItemtitle: "Signal Civils",
                path: routesStructure.civils_signals_route,
                data: [addTableData(data.SignalCivilsData, signalCivilsHeaderData)],
                pieData: filterReleventPieDataBySubCategory(PieChartData.Civils, "Signal Civils"),
                schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                schematicSubMenuToggleDisplay: signalCivilsSchematicDisplay,
                alt: "civils-signalling",
                graphHeaderIcon: sideNavIcons.CivilsIcon,
                dashboardGraphData: graphBoxData,

            },

            {
                listItemtitle: "Structures",
                path: routesStructure.civils_structures_route,
                data: [addTableData(data.StructuresData, signalCivilsHeaderData)],
                pieData: filterReleventPieDataBySubCategory(PieChartData.Civils, "Structures"),
                schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                schematicSubMenuToggleDisplay: signalCivilsSchematicDisplay,
                alt: "civils-structures",
                graphHeaderIcon: sideNavIcons.CivilsIcon,
                dashboardGraphData: graphBoxData,

            },
            {
                listItemtitle: "Equipment Bases",
                path: routesStructure.civils_equipment_route,
                data: [addTableData(data.EquipmentCubicleCivilsData, EquipmentCivilsHeaderData)],
                pieData: filterReleventPieDataBySubCategory(PieChartData.Civils, "Equipment Bases"),
                schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                schematicSubMenuToggleDisplay: civilsBasesSchematicDisplay,
                alt: "civils-equipment-bases",
                graphHeaderIcon: sideNavIcons.CivilsIcon,
                dashboardGraphData: graphBoxData,

            },
            {
                listItemtitle: "UTX's & Ducts",
                path: routesStructure.civils_route_works_route,
                data: [addTableData(data.UTXProposedCentresData, UTXCivilsHeaderData)],
                pieData: filterReleventPieDataBySubCategory(PieChartData.Civils, "UTX's & Ducts"),
                schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                schematicSubMenuToggleDisplay: civilsUtxSchematicDisplay,
                alt: "civils-route-works",
                graphHeaderIcon: sideNavIcons.CivilsIcon,
                dashboardGraphData: graphBoxData,

            },
            ]

        },

        // **************SIGNALLING*******************************
        {
            title: "Signalling",
            img: sideNavIcons.SignalIcon,
            path: routesStructure.signalling_all_route,

            subMenu: [

                {
                    listItemtitle: "All Signalling Works",
                    path: routesStructure.signalling_all_route,
                    data: [addTableData(data.SignallingData, signallingHeaderData), addTableData(data.EquipmentCubicleSignallingData, EquipmentHeaderData)],
                    pieData: (PieChartData.Signalling),
                    schematicGroupToggleDisplay: signallingSchematicGroupDisplay,
                    schematicSubMenuToggleDisplay: allSignallSchematicDisplay,
                    alt: "signalling-works-all",
                    graphHeaderIcon: sideNavIcons.SignalsIcon,
                    dashboardGraphData: graphBoxData,

                },

                {
                    listItemtitle: "Signal Assets",
                    path: routesStructure.signalling_heads_route,
                    data: [addTableData(data.SignallingData, signallingHeaderData)],
                    pieData: filterReleventPieDataBySubCategory(PieChartData.Signalling, "Signal Assets"),
                    schematicGroupToggleDisplay: signallingSchematicGroupDisplay,
                    schematicSubMenuToggleDisplay: signallAssetSchematicDisplay,
                    alt: "signalling-assets",
                    graphHeaderIcon: sideNavIcons.SignalsIcon,
                    dashboardGraphData: graphBoxData,


                },
                {
                    listItemtitle: "Equipment Cubicles",
                    path: routesStructure.signalling_cubicles_route,
                    data: [addTableData(data.EquipmentCubicleSignallingData, EquipmentHeaderData)],
                    pieData: filterReleventPieDataBySubCategory(PieChartData.Signalling, "Power Cubicles"),
                    schematicGroupToggleDisplay: signallingSchematicGroupDisplay,
                    schematicSubMenuToggleDisplay: pspREBCabinetSchematicDisplay,
                    alt: "signalling-equipment",
                    graphHeaderIcon: sideNavIcons.SignalsIcon,
                    dashboardGraphData: graphBoxData,

                }

            ]
        },

        // **************CABLING*******************************


        {
            title: "Cabling",
            img: sideNavIcons.CableIcon,
            path: routesStructure.cabling_all_route,
            subMenu: [
                {
                    listItemtitle: "All Cabling Works",
                    path: routesStructure.cabling_all_route,
                    data: [addTableData(data.PowerCablesData, CableHeaderData), addTableData(data.FibreCablesData, CableHeaderData)],
                    pieData: PieChartData.Cabling,
                    graphHeaderIcon: sideNavIcons.CablesIcon,
                    dashboardGraphData: graphBoxData,

                    alt: "cabling-works-power",
                    schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                    schematicSubMenuToggleDisplay: allCivilsSchematicDisplay,
                },
                {
                    listItemtitle: "Power Cabling",
                    path: routesStructure.cabling_power,
                    data: [addTableData(data.PowerCablesData, CableHeaderData)],
                    pieData: filterReleventPieDataBySubCategory(PieChartData.Cabling, "Power"),
                    graphHeaderIcon: sideNavIcons.CablesIcon,
                    dashboardGraphData: graphBoxData,

                    alt: "cabling-works-power",
                    schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                    schematicSubMenuToggleDisplay: allCivilsSchematicDisplay,
                },
                {
                    listItemtitle: "Fibre Cables",
                    path: routesStructure.cabling_fibre,
                    data: [addTableData(data.FibreCablesData, CableHeaderData)],
                    pieData: filterReleventPieDataBySubCategory(PieChartData.Cabling, "Fibre"),
                    graphHeaderIcon: sideNavIcons.CablesIcon,
                    dashboardGraphData: graphBoxData,
                    alt: "cabling-works-power",
                    schematicGroupToggleDisplay: civilsSchematicGroupDisplay,
                    schematicSubMenuToggleDisplay: allCivilsSchematicDisplay,
                }
            ]
        },


    ]


    return dropDownMenuTitles

}


export default getDropDownMenuTitles;