import React from 'react'


import './loading.css'

const Loading = () => {

    return (
        <div className='loader-container'>

            <div className="loader">
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__ball"></div>
            </div>


        </div>
    )
}

export default Loading