
import StationPlatformData from '../../../../data/FixedLocationsGeoData/StationPlatforms.json'

export const StationPlatforms = (map) => {

    map.on('load', () => {
        map.addSource('national-park', {
            'type': 'geojson',
            'data': StationPlatformData,
        });

        map.addLayer({
            'id': 'park-boundary',
            'type': 'fill',
            'source': 'national-park',
            'paint': {
                'fill-color': '#888888',
                'fill-opacity': 0.4
            }

        });



    })

}