import React from 'react'
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../Contexts/AuthContext'

import LogOutIcon from '../assets/UIcons/__Btn-LogOut-white.svg'
import GoBackIcon from '../assets/UIcons/__Btn-Goback-white.svg'

import './pages.css'
// import '../App.css'

const LogOutReview = () => {

    const { user, logout } = UserAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');

        } catch (e) {
            console.log(e.message);
        }
    };

    const handleBackButton = () => {
        navigate(-1)

    }

    return (

        <div className='log-page-main-container'>

            <div className="log-title"><span>Are You sure you want to Logout? <br></br></span><span className="log-title log-color">{user && user.email}</span></div>


            <div className='logout-btn-container'>

                <button className="logout-btn-main" onClick={handleLogout}>
                    <div className='btn-contents'>
                        <img className="click-btn-icon" src={LogOutIcon} alt="create-button" />
                        <p className='log-out-btn-text'>Yes, Logout</p>
                    </div>
                </button>



                <button className="goback-btn" onClick={handleBackButton}>
                    <div className='btn-contents'>
                        <img className="click-btn-icon" src={GoBackIcon} alt="create-button" />
                        <p className='log-out-btn-text'>No, go back</p>
                    </div>

                </button>


            </div>

        </div>
    )
}

export default LogOutReview