import React from 'react'

const TrialHoleIcon = () => {
    return (
        <g id="Icons">
            <path d="M35.7234,15.2252v3.873l-1.7116,3.2454h-1.2201l1.2201-3.1406v-3.4551h-.6961v3.5595l-1.3631,3.5014h1.3631v1.42l1.2563,13.5041h-1.6747l-4.7107-14.8984v-6.4576c0-3.6638,2.3032-3.9788,2.3032-3.9788h1.8114s3.4222-.1048,3.4222,2.8271Z" fill="#e1e5f2" />
            <path d="M28.1865,9.0364h5.3383c0,1.6544-1.1946,2.9967-2.6691,2.9967s-2.6692-1.3423-2.6692-2.9967Z" fill="#e1e5f2" />
            <path d="M33.5039,8.6609h-5.2966c.1651-1.4781,1.2875-2.6227,2.6484-2.6227s2.4833,1.1446,2.6482,2.6227Z" fill="#e1e5f2" />
            <polygon points="30.4898 31.7656 30.4898 37.7329 28.7093 37.7329 28.1865 24.2288 30.4898 31.7656" fill="#e1e5f2" />
            <polygon points="27.767 17.2961 27.767 18.9939 22.7422 18.9939 22.7422 17.7607 27.767 17.2961" fill="#e1e5f2" />
            <rect x="12.1904" y="23.8594" width="16.133" height=".8328" transform="translate(-8.6872 36.9996) rotate(-73.8868)" fill="#373d40" />
            <path d="M18.4566,32.5514l1.5096,.4355s-1.6897,4.9099-3.1993,4.4739l-.5868-.1697c-1.5093-.4355-.3219-5.4903-.3219-5.4903l1.5096,.437,1.0888,.3136Z" fill="#373d40" />
            <path d="M15.4144,37.4889H7.2766s2.1986-13.6795,3.4409-10.6432c1.2422,3.0363,2.1938,2.3029,2.5031,4.1356,.309,1.8317,2.1938,6.5076,2.1938,6.5076Z" fill="#373d40" />
        </g>
    )
}
export default TrialHoleIcon