// MAPBOX  GENERIC COMPONENT

import { MapSymbol } from '../ComponentGeneral/MapSymbol'

import { JSONDataFilterByProperty, JSONDataFilterByDualProperty } from '../../../../constants/functions/jsonDataFilterFunctions'

import { signalMapSymbols } from '../../../../constants/MapSymbols/SignalMapSymbols'

// IMAGES

export const SignallingComps = (map, isVisible, createPopUp, pathname, SignalCivilsData, routesStructure, tableContent) => {

    const signalOutstandingData = JSONDataFilterByProperty(SignalCivilsData, { "Base": "Outstanding" }, "Outstanding", "Base")

    const signalBaseCompleteData = JSONDataFilterByDualProperty(SignalCivilsData, { "Base": "Complete" }, "BaseComplete", "Base", { "Structure": "Outstanding" }, "Outstanding", "Structure")

    const signalStructureCompleteData = JSONDataFilterByDualProperty(SignalCivilsData, { "Structure": "Complete" }, "Structure", "Structure", { "Signal Head": "Outstanding" }, "Outstanding", "Signal Head")

    const signalCompleteData = JSONDataFilterByProperty(SignalCivilsData, { "Signal Head": "Complete" }, "Signal Complete", "Signal Head")

    const signalStructureRetainedData = JSONDataFilterByProperty(SignalCivilsData, { "structure type": "Post Existing" }, "Reheads", "structure type")

    const noSignalStructureData = JSONDataFilterByProperty(SignalCivilsData, { "Structure": "N/A" }, "Reheads", "Structure")


    const signalOnStructureData = JSONDataFilterByProperty(SignalCivilsData, { "structure type": "Signal Structure Existing" }, "SignalOnStructure", "structure type")

    const signalHeadRetainedData = JSONDataFilterByProperty(SignalCivilsData, { "signal head type": "Signal Head Existing" }, "Signal Head Existing", "signal head type")

    const reheadSignalsData = JSONDataFilterByProperty(SignalCivilsData, { "structure type": "Post Existing" }, "Reheads", "structure type")

    const existingHeadReStructure = JSONDataFilterByProperty(SignalCivilsData, { "signal head type": "Signal Head Existing" }, "Signal Head Existing", "signal head type")

    const signalTypes = [
        // MAP DOTS FOR ZOOM OUT
        {
            data: JSONDataFilterByProperty(SignalCivilsData, { "Base": "Outstanding" }, "Signals Outstanding", "Base"),
            source: 'SignalCivils-Dot-OutstandingSG',
            imageSource: signalMapSymbols.SignalDotOutstanding,
            imageName: 'SignalCivils-Dot-Outstanding-imageSG',
            iconSize: 0.05,
            minZoom: 10,
            maxZoom: 16,
            text: "",
        },

        {
            data: JSONDataFilterByDualProperty(SignalCivilsData, { "Base": "Complete" }, "BaseComplete", "Base", { "Structure": "Outstanding" }, "Outstanding", "Structure"),
            source: 'SignalCivils-Dot-Str-outstanding-CompleteSG',
            imageSource: signalMapSymbols.SignalDotOutstanding,
            imageName: 'SignalCivils-Dot-Str-outstanding-Complete-imageSG',
            iconSize: 0.05,
            minZoom: 10,
            maxZoom: 16,
            text: "",
        },

        {
            data: JSONDataFilterByProperty(SignalCivilsData, { "Structure": "Complete" }, "Signals Complete", "Structure"),
            source: 'SignalCivils-Dot-CompleteSG',
            imageSource: signalMapSymbols.SignalDotComplete,
            imageName: 'SignalCivils-Dot-Complete-imageSG',
            iconSize: 0.05,
            minZoom: 10,
            maxZoom: 16,
            text: "",
        },
        {
            data: JSONDataFilterByProperty(SignalCivilsData, { "Structure": "Retained" }, "Signals Retained", "Structure"),
            source: 'SignalCivils-Dot-RetainedSG',
            imageSource: signalMapSymbols.SignalDotRetained,
            imageName: 'SignalCivils-Dot-Retained-imageSG',
            iconSize: 0.05,
            minZoom: 10,
            maxZoom: 16,
            text: "",
        },

        {
            data: JSONDataFilterByProperty(SignalCivilsData, { "Structure": "Existing" }, "Signals Existing", "Structure"),
            source: 'SignalCivils-Dot-existingSG',
            imageSource: signalMapSymbols.SignalDotRetained,
            imageName: 'SignalCivils-Dot-Existing-imageSG',
            iconSize: 0.05,
            minZoom: 10,
            maxZoom: 16,
            text: "",
        },

        // MAIN ICONS FOR ZOOM IN
        // MAIN ICONS FOR ZOOM IN

        // FOUR ASPECT
        {
            data: JSONDataFilterByProperty(signalOutstandingData, { "aspect no": 4, }, "Signal Post 4 Aspects", "aspect no"),
            source: 'SignalCivils-Image4A-OutstandingSG',
            imageSource: signalMapSymbols.Signal4AOutstanding,
            imageName: 'SignalCivils-Image4A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalOnStructureData, { "aspect no": 4, }, "Signal Post 4 Aspects", "aspect no"),
            source: 'SignalonStructureCivils-Image4A-OutstandingSG',
            imageSource: signalMapSymbols.Signal4AStructureExisting,
            imageName: 'SignalonStructureCivils-Image4A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalBaseCompleteData, { "aspect no": 4, }, "Signal Post 4 Aspects", "aspect no"),
            source: 'SignalCivils-Image4A-BaseCompleteSG',
            imageSource: signalMapSymbols.Signal4ABaseComplete,
            imageName: 'SignalCivils-Image4A-BaseComplete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalStructureCompleteData, { "aspect no": 4, }, "Signal Post 4 Aspects", "aspect no"),

            source: 'SignalCivils-Image4A-StructureCompleteSG',
            imageSource: signalMapSymbols.Signal4AStructureComplete,
            imageName: 'SignalCivils-Image4A-StructureComplete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalCompleteData, { "aspect no": 4, }, "Signal Post 4 Aspects", "aspect no"),

            source: 'SignalCivils-Image4A-CompleteSG',
            imageSource: signalMapSymbols.Signal4AComplete,
            imageName: 'SignalCivils-Image4A-Complete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalStructureRetainedData, { "aspect no": 4, }, "Signal Post 4 Aspects", "aspect no"),
            source: 'SignalCivilsRehead-Image4A-OutstandingSG',
            imageSource: signalMapSymbols.Signal4AStructureExisting,
            imageName: 'SignalCivilsRehead-Image4A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalHeadRetainedData, { "aspect no": 4, }, "Signal Post 4 Aspects", "aspect no"),
            source: 'SignalCivilsRebody-Image4A-OutstandingSG',
            imageSource: signalMapSymbols.Signal4AHeadExisting,
            imageName: 'SignalCivilsRebody-Image4A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],

        },
        // THREE ASPECT
        {
            data: JSONDataFilterByProperty(signalOutstandingData, { "aspect no": 3, }, "Signal Post 3 Aspects", "aspect no"),
            source: 'SignalCivils-Image3A-OutstandingSG',
            imageSource: signalMapSymbols.Signal3AOutstanding,
            imageName: 'SignalCivils-Image3A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalOnStructureData, { "aspect no": 3, }, "Signal Post 3 Aspects", "aspect no"),
            source: 'SignalonStructureCivils-Image3A-OutstandingSG',
            imageSource: signalMapSymbols.Signal3AStructureExisting,
            imageName: 'SignalonStructureCivils-Image3A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalBaseCompleteData, { "aspect no": 3, }, "Signal Post 3 Aspects", "aspect no"),
            source: 'SignalCivils-Image3A-BaseCompleteSG',
            imageSource: signalMapSymbols.Signal3ABaseComplete,
            imageName: 'SignalCivils-Image3A-BaseComplete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalStructureCompleteData, { "aspect no": 3, }, "Signal Post 3 Aspects", "aspect no"),

            source: 'SignalCivils-Image3A-StructureCompleteSG',
            imageSource: signalMapSymbols.Signal3AStructureComplete,
            imageName: 'SignalCivils-Image3A-StructureComplete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalCompleteData, { "aspect no": 3, }, "Signal Post 3 Aspects", "aspect no"),

            source: 'SignalCivils-Image3A-CompleteSG',
            imageSource: signalMapSymbols.Signal3AComplete,
            imageName: 'SignalCivils-Image3A-Complete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalStructureRetainedData, { "aspect no": 3, }, "Signal Post 3 Aspects", "aspect no"),
            source: 'SignalCivilsRehead-Image3A-OutstandingSG',
            imageSource: signalMapSymbols.Signal3AStructureExisting,
            imageName: 'SignalCivilsRehead-Image3A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalHeadRetainedData, { "aspect no": 3, }, "Signal Post 3 Aspects", "aspect no"),
            source: 'SignalCivilsRebody-Image3A-OutstandingSG',
            imageSource: signalMapSymbols.Signal3AHeadExisting,
            imageName: 'SignalCivilsRebody-Image3A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],

        },
        // TWO ASPECT
        {
            data: JSONDataFilterByProperty(signalOutstandingData, { "aspect no": 2, }, "Signal Post 2 Aspects", "aspect no"),
            source: 'SignalCivils-Image2A-OutstandingSG',
            imageSource: signalMapSymbols.Signal2AOutstanding,
            imageName: 'SignalCivils-Image2A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalOnStructureData, { "aspect no": 2, }, "Signal Post 2 Aspects", "aspect no"),
            source: 'SignalonStructureCivils-Image2A-OutstandingSG',
            imageSource: signalMapSymbols.Signal2AStructureExisting,
            imageName: 'SignalonStructureCivils-Image2A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalBaseCompleteData, { "aspect no": 2, }, "Signal Post 2 Aspects", "aspect no"),
            source: 'SignalCivils-Image2A-BaseCompleteSG',
            imageSource: signalMapSymbols.Signal2ABaseComplete,
            imageName: 'SignalCivils-Image2A-BaseComplete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalStructureCompleteData, { "aspect no": 2, }, "Signal Post 2 Aspects", "aspect no"),

            source: 'SignalCivils-Image2A-StructureCompleteSG',
            imageSource: signalMapSymbols.Signal2AStructureComplete,
            imageName: 'SignalCivils-Image2A-StructureComplete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalCompleteData, { "aspect no": 2, }, "Signal Post 2 Aspects", "aspect no"),

            source: 'SignalCivils-Image2A-CompleteSG',
            imageSource: signalMapSymbols.Signal2AComplete,
            imageName: 'SignalCivils-Image2A-Complete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalStructureRetainedData, { "aspect no": 2, }, "Signal Post 2 Aspects", "aspect no"),
            source: 'SignalCivilsRehead-Image2A-OutstandingSG',
            imageSource: signalMapSymbols.Signal2AStructureExisting,
            imageName: 'SignalCivilsRehead-Image2A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },



        {
            data: JSONDataFilterByProperty(signalHeadRetainedData, { "aspect no": 2, }, "Signal Post 2 Aspects", "aspect no"),
            source: 'SignalCivilsRebody-Image2A-OutstandingSG',
            imageSource: signalMapSymbols.Signal2AHeadExisting,
            imageName: 'SignalCivilsRebody-Image2A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],

        },



        // BANNER REPEATERS
        // TODO UPDATE THESE AS ABOVE
        {
            data: JSONDataFilterByProperty(signalOutstandingData, { "signal head type": "Banner Repeater", }, "Banner Outstanding", "signal head type"),
            source: 'SignalCivils-BR-Image-OutstandingSG',
            imageSource: signalMapSymbols.SignalBannerOutstanding,
            imageName: 'SignalCivilsBR-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalBaseCompleteData, { "signal head type": "Banner Repeater", }, "Banner Outstanding", "signal head type"),
            source: 'SignalCivilsBR-base-installed-Image-OutstandingSG',
            imageSource: signalMapSymbols.SignalBannerBannerBaseComplete,
            imageName: 'SignalCivilsBR-base-installed-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalStructureCompleteData, { "signal head type": "Banner Repeater", }, "Banner Outstanding", "signal head type"),
            source: 'SignalCivilsBR-civils-installedSG',
            imageSource: signalMapSymbols.SignalBannerStructureComplete,
            imageName: 'SignalCivilsBR-civils-installed-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalCompleteData, { "signal head type": "Banner Repeater", }, "Banner Outstanding", "signal head type",),
            source: 'SignalCivils-BR-Image-CompleteSG',
            imageSource: signalMapSymbols.SignalBannerComplete,
            imageName: 'SignalCivilsBR-Image-Complete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },


        {
            data: JSONDataFilterByProperty(signalStructureRetainedData, { "signal head type": "Banner Repeater", }, "Banner Outstanding", "signal head type"),
            source: 'SignalCivilsBR-Rehead-Image-OutstandingSG',
            imageSource: signalMapSymbols.SignalBannerStructureExisting,
            imageName: 'SignalCivilsBR-Rehead-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalOnStructureData, { "signal head type": "Banner Repeater", }, "Banner Outstanding", "signal head type"),
            source: 'SignalonStructureCivils-ImageBanner-OutstandingSG',
            imageSource: signalMapSymbols.SignalBannerStructureExisting,
            imageName: 'SignalonStructureCivils-Image4A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        // GROUND SIGNALs
        // TODO UPDATE THESE AS ABOVE
        {
            data: JSONDataFilterByProperty(signalOutstandingData, { "signal head type": "Ground Signal", }, "Ground Signal Outstanding", "signal head type"),
            source: 'SignalCivils-Ground Signal-Image-OutstandingSG',
            imageSource: signalMapSymbols.SignalGroundOutstanding,
            imageName: 'SignalCivilsGround Signal-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalBaseCompleteData, { "signal head type": "Ground Signal", }, "Ground Signal Outstanding", "signal head type"),
            source: 'SignalCivilsGround Signal-base-installed-Image-OutstandingSG',
            imageSource: signalMapSymbols.SignalGroundBaseComplete,
            imageName: 'SignalCivilsGround Signal-base-installed-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalStructureCompleteData, { "signal head type": "Ground Signal", }, "Ground Signal Outstanding", "signal head type"),
            source: 'SignalCivilsGround Signal-civils-installedSG',
            imageSource: signalMapSymbols.SignalGroundStructureComplete,
            imageName: 'SignalCivilsGround Signal-civils-installed-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalCompleteData, { "signal head type": "Ground Signal", }, "Ground Signal Outstanding", "signal head type"),
            source: 'SignalCivils-Ground Signal-Image-CompleteSG',
            imageSource: signalMapSymbols.SignalGroundComplete,
            imageName: 'SignalCivilsGround Signal-Image-Complete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },


        {
            data: JSONDataFilterByProperty(signalStructureRetainedData, { "signal head type": "Ground Signal", }, "Ground Signal Outstanding", "signal head type"),
            source: 'SignalCivilsGround Signal-Rehead-Image-Outstanding-imageSG',
            imageSource: signalMapSymbols.SignalGroundStructureExisting,
            imageName: 'SignalCivilsGround Signal-Rehead-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalOnStructureData, { "signal head type": "Ground Signal", }, "Ground Signal Outstanding", "signal head type"),
            source: 'SignalonStructureCivils-ImageGround Signal-OutstandingSG',
            imageSource: signalMapSymbols.SignalGroundStructureExisting,
            imageName: 'SignalonStructureCivils-Image4A-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalOutstandingData, { "signal head type": "Off Indicator", }, "Off Indicator Outstanding", "signal head type"),
            source: 'SignalCivils-off-Signal-Image-OutstandingSG',
            imageSource: signalMapSymbols.SignalOffOutstanding,
            imageName: 'SignalCivils-off-Signal-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalBaseCompleteData, { "signal head type": "Off Indicator", }, "Off Outstanding", "signal head type"),
            source: 'SignalCivils-off-Signal-base-installed-Image-OutstandingSG',
            imageSource: signalMapSymbols.SignalOffOutstanding,
            imageName: 'SignalCivils-off-Signal-base-installed-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },
        {
            data: JSONDataFilterByProperty(signalStructureCompleteData, { "signal head type": "Off Indicator", }, "Off Indicator Outstanding", "signal head type"),
            source: 'SignalCivils-off-Signal-civils-installedSG',
            imageSource: signalMapSymbols.SignalOffComplete,
            imageName: 'SignalCivils-off-Signal-civils-installed-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },

        {
            data: JSONDataFilterByProperty(signalCompleteData, { "signal head type": "Off Indicator", }, "Off Indicator Outstanding", "signal head type"),
            source: 'SignalCivils-off-Signal-Image-CompleteSG',
            imageSource: signalMapSymbols.SignalOffComplete,
            imageName: 'SignalCivils-off-Signal-Image-Complete-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },


        {
            data: JSONDataFilterByProperty(signalStructureRetainedData, { "signal head type": "Off Indicator", }, "Off Indicator Outstanding", "signal head type"),
            source: 'SignalCivils-off-Signal-Rehead-Image-Outstanding-imageSG',
            imageSource: signalMapSymbols.SignalOffExisting,
            imageName: 'SignalCivils-off-Signal-Rehead-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        },



        {
            data: JSONDataFilterByProperty(noSignalStructureData, { "signal head type": "Off Indicator", }, "Off Indicator Outstanding", "signal head type"),
            source: 'SignalCivils-off-Signal-Nostructure-Image-Outstanding-imageSG',
            imageSource: signalMapSymbols.SignalOffExisting,
            imageName: 'SignalCivils-off-Signal-Nostructure-Image-Outstanding-imageSG',
            iconSize: 0.3,
            minZoom: 16,
            maxZoom: 19,
            text: ['get', 'Name'],
        }






    ]

    const relatedPathNamesSet = [routesStructure.signalling_all_route, routesStructure.signalling_heads_route,];

    const symbolsData = signalTypes.map((item) => {
        return ({
            data: item.data,
            source: item.source,
            imageSource: item.imageSource,
            imageName: item.imageName,
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: item.minZoom,
            maxZoom: item.maxZoom,
            iconSize: item.iconSize,
            rotation: ['get', 'rotation'],
            relatedPathNames: relatedPathNamesSet,
            tableValues: tableContent,
            text: item.text,
            textOffset: [0, 0],
            paint: { 'text-color': '#ffffff' },
            type: "symbol"
        })

    })

    symbolsData.forEach((symbolData) => MapSymbol(symbolData));


}