// MAP COMPONENT
import { MapSymbol } from '../ComponentGeneral/MapSymbol'


import { utxMapSymbols } from '../../../../constants/MapSymbols/UtxMapSymbols'


// FUNCTION
import { JSONDataFilterByProperty, JSONDataFilterByDualProperty } from '../../../../constants/functions/jsonDataFilterFunctions'

export const UtxNewComponent = (map, isVisible, createPopUp, pathname, routesStructure, addTableData, UTXProposedCentresData, tableContent) => {

    const JSONDataFilter = (data) => {

        const { tableData, sideBarRightData } = data;
        return (
            {
                "type": "FeatureCollection",
                "name": "data",
                "features": data,
                "tableData": tableData ? tableData : [],
                "sideBarRightData": sideBarRightData ? sideBarRightData : [],
            })
    }




    let data = []

    data = UTXProposedCentresData.features;

    const outstandingData = JSONDataFilter(data.filter((item) => item.properties["Base"] === "Outstanding" || item.properties["Structure"] === "Outstanding"));

    const completeData = JSONDataFilter(UTXProposedCentresData.features.filter((item) => item.properties["Base"] === "Complete" && item.properties["Structure"] === "Complete"));

    const existingData = JSONDataFilter(UTXProposedCentresData.features.filter((item) => item.properties["Base"] === "Existing" || item.properties["Structure"] === "Existing"));


    const symbolsData = [

        // UTX
        {
            data: JSONDataFilterByProperty(outstandingData, { "type": "Under Track Crossing" }, "Under Track Crossing", "type"),
            source: 'UTXImageCentreOutstanding',
            imageSource: utxMapSymbols.UTXImageCentreOutstanding,
            imageName: 'UTXImageCentreOutstanding',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route,
            ],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: JSONDataFilterByProperty(completeData, { "type": "Under Track Crossing" }, "Under Track Crossing", "type"),
            source: 'UTXImageCentreComplete',
            imageSource: utxMapSymbols.UTXImageCentreComplete,
            imageName: 'UTXImageCentreComplete',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route,
            ],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: JSONDataFilterByProperty(existingData, { "type": "Under Track Crossing" }, "Under Track Crossing", "type"),
            source: 'UTXImageCentreExisting',
            imageSource: utxMapSymbols.UTXImageCentreExisting,
            imageName: 'UTXImageCentreExisting',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route,
            ],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        // URX
        {
            data: JSONDataFilterByProperty(outstandingData, { "type": "Under Road Crossing" }, "Under Road Crossing", "type"),
            source: 'URXImageCentreOutstanding',
            imageSource: utxMapSymbols.URXImageCentreOutstanding,
            imageName: 'URXImageCentreOutstanding',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },

        {
            data: JSONDataFilterByProperty(completeData, { "type": "Under Road Crossing" }, "Under Road Crossing", "type"),
            source: 'URXImageCentreComplete',
            imageSource: utxMapSymbols.URXImageCentreComplete,
            imageName: 'URXImageCentreComplete',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: JSONDataFilterByProperty(existingData, { "type": "Under Road Crossing" }, "Under Road Crossing", "type"),
            source: 'URXImageCentreExisting',
            imageSource: utxMapSymbols.URXImageCentreExisting,
            imageName: 'URXImageCentreExisting',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },

        // OTX

        {
            data: JSONDataFilterByProperty(outstandingData, { "type": "Over Track Crossing" }, "Over Track Crossing", "type"),
            source: 'OTXImageCentreOutstanding',
            imageSource: utxMapSymbols.OTXImageCentreOutstanding,
            imageName: 'OTXImageCentreOutstanding',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },

        {
            data: JSONDataFilterByProperty(completeData, { "type": "Over Track Crossing" }, "Over Track Crossing", "type"),
            source: 'OTXImageCentreComplete',
            imageSource: utxMapSymbols.OTXImageCentreComplete,
            imageName: 'OTXImageCentreComplete',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },
        {
            data: JSONDataFilterByProperty(existingData, { "type": "Over Track Crossing" }, "Over Track Crossing", "type"),
            source: 'OTXImageCentreExisting',
            imageSource: utxMapSymbols.OTXImageCentreExisting,
            imageName: 'OTXImageCentreExisting',
            map: map,
            isVisible,
            createPopUp: createPopUp,
            minZoom: 10,
            maxZoom: 19,
            iconSize: 0.25,
            rotation: 0,
            relatedPathNames: [routesStructure.civils_all_route, routesStructure.civils_route_works_route],
            tableValues: tableContent,
            text: "",
            textOffset: ['get', 'text-offset'],
            paint: {},
            type: "symbol"
        },


    ]

    // CIVILS
    symbolsData.forEach((symbolData) => MapSymbol(symbolData));

}


