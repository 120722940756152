
// These display what is shown on the mapbox pop ups and the map side bar right

export const signalCivilsHeaderData = [

    {
        Title: 'Signal No.',
        property: "Name"
    },
    {
        Title: 'Structure Type',
        property: "structure type",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },


    {
        Title: 'Base',
        property: "Base",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        Title: 'Structure',
        property: "Structure",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    // {
    //     Title: 'H/O to Signalling',
    //     property: "Signal Head",

    // }
]
export const EquipmentCivilsHeaderData = [

    {
        Title: 'Name',
        property: "Name"
    },
    // {
    //     Title: 'Design',
    //     property: "Base",
    //     sideBarRightDisplay: true
    // },

    {
        Title: 'Base',
        property: "Base",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    {
        Title: 'Cubicle',
        property: "Structure",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    // {
    //     Title: 'H/O to Signalling',
    //     property: "Structure"
    // }
]

export const UTXCivilsHeaderData = [
    {
        Title: 'ID',
        property: "Name",
    },
    {
        Title: 'Location',
        property: "location",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    {
        Title: 'Type',
        property: "type",

    },
    // {
    //     Title: 'Design',
    //     property: "Base",

    // },

    {
        Title: 'Chambers',
        property: "Base",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        Title: 'Crossing',
        property: "Structure",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    // {
    //     Title: 'H/O to Signalling',
    //     property: "Structure"
    // }
]

export const levelCrossingHeaderData = [
    {
        Title: 'Name',
        property: "Name",

    },
    {
        Title: 'Description',
        property: "description",

    },
    {
        Title: 'Status',
        property: "type",

    }
]

export const AccessHeaderData = [

    // {
    //     Title: 'Type',
    //     property: "Type",
    //     popUpDisplay: true

    // },
    {
        Title: 'ELR',
        property: "ELR",
        popUpDisplay: true

    },
    {
        Title: 'Mileage',
        property: "Mileage",
        popUpDisplay: true

    }
]

// SIGNALLING HEADERS
export const signallingHeaderData = [
    {
        Title: 'Signal No.',
        property: "Name"
    },
    {
        Title: 'Signal Type',
        property: "signal head type",
        sideBarRightDisplay: true,
        // popUpDisplay: true
    },
    {
        Title: 'Structure',
        property: "Structure",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        Title: 'Head',
        property: "Signal Head",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    // {
    //     Title: 'H/O to Test',
    //     property: "Signal Head",
    //     sideBarRightDisplay: true,
    //     popUpDisplay: true
    // }
]

export const EquipmentHeaderData = [
    {
        Title: 'Name',
        property: "Name"
    },

    {
        Title: 'Cubicle',
        property: "Structure",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        Title: 'H/O to Test',
        property: "Structure",
        sideBarRightDisplay: true,
        popUpDisplay: true
    }
]


export const CableHeaderData = [

    {
        Title: 'Name',
        property: "Name"
    },

    {
        Title: 'Area',
        property: "Area",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        Title: 'total(m)',
        property: "length total",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        Title: 'Complete(m)',
        property: "length complete",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        Title: 'Completion due',
        property: "planned_completion",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    {
        Title: 'Status',
        property: "status",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    {
        Title: 'HOTT',
        property: "HOTT",
        sideBarRightDisplay: true,
        popUpDisplay: true
    }
]