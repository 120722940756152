import React from 'react'

const FBIconDashboard = ({ fill, onClick }) => {
    return (
        <div className='float-icon-container' onClick={onClick}>
            <svg id="new_icons" data-name="new icons" xmlns="http://www.w3.org/2000/svg" width="22mm" height="22mm" viewBox="0 0 62.3622 62.3622">
                <rect x="3.9653" y="3.7496" width="31.0685" height="8.3836" fill={fill} />
                <rect x="3.9653" y="15.3695" width="17.6918" height="8.3836" fill={fill} />
                <rect x="3.9653" y="26.9893" width="54.4315" height="8.3836" fill={fill} />
                <rect x="3.9653" y="38.6092" width="42.8425" height="8.3836" fill={fill} />
                <rect x="3.9653" y="50.229" width="26.3836" height="8.3836" fill={fill} />
            </svg>
        </div>
    )
}

export default FBIconDashboard