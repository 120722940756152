import React from 'react';
import PieChart from './PieChart';

const PieChartList = ({ pieList }) => {

    return (
        <div className='pie-list-container'>
            {pieList.map((item, index) => {
                if (item.qtyTotal > 0) {
                    return (
                        <PieChart
                            key={index}
                            itemNameText={item.itemNameText}
                            qtyComplete={item.qtyComplete}
                            qtyTotal={item.qtyTotal}
                            PieIcon={item.PieIcon}
                            percentageValue={item.qtyComplete / item.qtyTotal * 100}
                            styleChange={""}
                        />
                    );
                }
            })}
        </div>
    );
};

export default PieChartList;

