import React from "react";
import { useState, useRef, useEffect } from 'react';

import '../../pageStyles/Forms/form.css'

// MAPBOX LIBRARY
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
const elementContainer = document.createElement('div');

const element = document.createElement('img');
elementContainer.appendChild(element);
const FormMap = (props) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(props.lng);
    const [lat, setLat] = useState(props.lat);
    const coordinates = [lng, lat];
    const [zoom, setZoom] = useState(props.zoom);

    let marker = null;
    const handleAddMarker = ({ lngLat }) => {
        const { lng, lat } = lngLat;
        if (marker != null) {
            marker.remove();
        }
        if (props.mapIcon || props.mapIcon === null) {
            element.className = 'marker';
            element.style.height = `${props.iconSize ? props.iconSize : "25"}px`;
            element.backgroundSize = "cover"
            element.src = `${props.mapIcon}`

            marker = new mapboxgl.Marker(elementContainer).setLngLat({ lng, lat }).addTo(map.current)
        } else {
            marker = new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(map.current)
        }
        props.handleLngLatMapBox(lngLat)
    }
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: process.env.REACT_APP_MAPBOX_STYLE_PLAIN,
            center: coordinates,
            zoom: zoom,
            preserveDrawingBuffer: true

        });
        if (props.mapIcon || props.mapIcon === null) {
            element.className = 'marker';

            element.style.height = `${props.iconSize ? props.iconSize : "25"}px`
            element.backgroundSize = "cover"
            element.src = `${props.mapIcon}`

            marker = new mapboxgl.Marker(elementContainer).setLngLat({ lng, lat }).addTo(map.current)
        } else {
            marker = new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(map.current)
        }


        map.current.on("click", handleAddMarker)
    }, []);

    useEffect(() => {

        element.src = `${props.mapIcon}`

        map.current.off("click", handleAddMarker)
        map.current.on("click", handleAddMarker)
    }, [props.mapIcon, props.formData])

    useEffect(() => {
        element.style.transform = `rotate(${props.rotation}deg)`
    }, [props.rotation])


    return (
        <div className="map-main-small" >
            <div ref={mapContainer} className="map-container-small" />
        </div>
    );

}

export default FormMap;