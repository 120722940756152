import React, { useState } from 'react';


import './GraphBox.css'

import { useLocation } from "react-router-dom";

import BarChart from '../../GraphPageComponents/Graph/bar-chart'

import { useDataContext } from '../../../../Contexts/DataContext';

import { routesStructure } from '../../../../constants/appSetup/routesStructure';

export default function GraphBoxNew(props) {


    const selectDispline = (dataSet, selector) => {
        let output = [];
        dataSet.forEach((item) => {

            if (item.title === selector) {
                output.push(item)
            }
        })
        return output
    }

    const data = useDataContext()

    const { findPathnameData, dropDownMenuTitles } = useDataContext()

    const [graphDisplay, setGraphDisplay] = useState(true)
    const { pathname } = useLocation();
    const dataForPathname = findPathnameData(pathname)

    const datagraphData = dataForPathname.pieData;
    const graphHeaderIcon = dataForPathname.graphHeaderIcon
    const graphTitle = dataForPathname.listItemtitle

    const civilsData = selectDispline(dropDownMenuTitles, "Civils")[0].subMenu[0].pieData
    const civilsIcon = selectDispline(dropDownMenuTitles, "Civils")[0].subMenu[0].graphHeaderIcon
    const civilsTitle = selectDispline(dropDownMenuTitles, "Civils")[0].subMenu[0].listItemtitle

    const signallingData = selectDispline(dropDownMenuTitles, "Signalling")[0].subMenu[0].pieData
    const signallingIcon = selectDispline(dropDownMenuTitles, "Signalling")[0].subMenu[0].graphHeaderIcon
    const signallingTitle = selectDispline(dropDownMenuTitles, "Signalling")[0].subMenu[0].listItemtitle

    const cableData = selectDispline(dropDownMenuTitles, "Cabling")[0].subMenu[0].pieData
    const cableIcon = selectDispline(dropDownMenuTitles, "Cabling")[0].subMenu[0].graphHeaderIcon
    const cableTitle = selectDispline(dropDownMenuTitles, "Cabling")[0].subMenu[0].listItemtitle



    const graphRefresher = (dataForPathname) => {
        switch (dataForPathname) {
            case routesStructure.civils_all_route:
                setGraphDisplay(graphDisplay + 1);
                break;
            case routesStructure.civils_signals_route:
                setGraphDisplay(graphDisplay + 1);
                break;
        }
    }

    return (
        <>
            {pathname === '/app/project-summary' ?
                <>
                    <div className="whitebox-container-general">
                        <div className={props.classNameAdditional}>
                            {/* TOP ROW HEADER */}
                            <div className="graph-header-container">
                                <div className="graph-box-header-row">
                                    <img className="graph-box-header-icon" src={civilsIcon} alt="icon" />

                                    <h1 className="graph-box-header-title">{civilsTitle}</h1>

                                </div>
                            </div>

                            {/* BRINGS IN THE GRAPH */}

                            <div className="graph ">
                                <BarChart
                                    data={civilsData.filter((item) => item.qtyTotal > 0)}
                                    graphRefresh={civilsData}
                                >
                                </BarChart>
                            </div>

                        </div>
                    </div>
                    <div className="whitebox-container-general">
                        <div className={props.classNameAdditional}>
                            {/* TOP ROW HEADER */}
                            <div className="graph-header-container">
                                <div className="graph-box-header-row">
                                    <img className="graph-box-header-icon" src={signallingIcon} alt="icon" />

                                    <h1 className="graph-box-header-title">{signallingTitle}</h1>

                                </div>
                            </div>

                            {/* BRINGS IN THE GRAPH */}

                            <div className="graph ">
                                <BarChart
                                    data={signallingData.filter((item) => item.qtyTotal > 0)}
                                    graphRefresh={signallingData}
                                >
                                </BarChart>
                            </div>

                        </div>
                    </div>
                    <div className="whitebox-container-general">
                        <div className={props.classNameAdditional}>
                            {/* TOP ROW HEADER */}
                            <div className="graph-header-container">
                                <div className="graph-box-header-row">
                                    <img className="graph-box-header-icon" src={cableIcon} alt="icon" />

                                    <h1 className="graph-box-header-title">{cableTitle}</h1>

                                </div>
                            </div>

                            {/* BRINGS IN THE GRAPH */}

                            <div className="graph ">
                                <BarChart
                                    data={cableData.filter((item) => item.qtyTotal > 0)}
                                    graphRefresh={signallingData}
                                >
                                </BarChart>
                            </div>

                        </div>
                    </div>
                </>
                :
                <div className="whitebox-container-general">
                    <div className={props.classNameAdditional}>
                        {/* TOP ROW HEADER */}
                        <div className="graph-header-container">
                            <div className="graph-box-header-row">
                                <img className="graph-box-header-icon" src={graphHeaderIcon} alt="icon" />

                                <h1 className="graph-box-header-title">{graphTitle}</h1>

                            </div>
                        </div>

                        {/* BRINGS IN THE GRAPH */}

                        <div className="graph ">
                            <BarChart
                                data={datagraphData.filter((item) => item.qtyTotal > 0)}
                                graphRefresh={datagraphData}
                            >
                            </BarChart>
                        </div>

                    </div>
                </div>
            }

        </>


    )
}
