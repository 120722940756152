import React from "react"
import './GraphBoxNew.css'

export default function GraphBoxNew(props) {

    return (

        <div className={props.classNameAdditional}>
            {/* TOP ROW HEADER */}
            <div className="graph-header-container">
                <div className="graph-box-header-row">
                    {/* <img className="graph-box-header-icon" src={props.graphHeaderIcon} alt="icon" /> */}
                    <div className="graph-box-header-start-title">{props.startTitle} </div>
                    <div className="graph-box-header-title">{props.title}</div>
                </div>
            </div>

            {/* BRINGS IN THE GRAPH */}
            <div className="graph ">
                {props.children}
            </div>

            {/* LEGEND AT THE BOTTOM _ NEEDS SPLITTING OUT INTO SPERATE IMAGES AND SVG */}
            {/* <div className={props.displayLegendClass}>
                {props.legendType}

            </div> */}

        </div>


    )
}
