export const uniqueEquipmentTypeIdentifier = (obj) => {
    const keys = Object.keys(obj);
    const uniqueKeys = [];

    keys.forEach(key => {
        const truncatedKey = key.substring(0, 3);
        if (!uniqueKeys.includes(truncatedKey)) {
            uniqueKeys.push(truncatedKey);
        }
    });
    return uniqueKeys
}
