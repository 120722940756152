import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../Contexts/AuthContext'

import { DataContext } from '../../Contexts/DataContext';
import { useNavigate, useParams } from 'react-router-dom'

import { db } from '../../firebase'

import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'

import { addUpdateLog } from '../../constants/databaseFunctions/UpdateLog'

import DbCard from '../../components/cards/DbCard'

import './form.css'

import FormMap from '../../components/FormMap/FormMap';
import { equipmentMapSymbols } from '../../constants/MapSymbols/EquipmentMapSymbols';

import { defaultFlyToCoord } from '../../constants/styles/mapStyles'

const EquipmentForm = () => {

    const params = useParams();
    const navigate = useNavigate()
    const selectedItemId = params.id
    const userData = useContext(UserContext)
    const data = useContext(DataContext)

    const [formDirect, setFormDirect] = useState("/app/project-summary")

    useEffect(() => {
        const latestURL = window.location.href
        const urlArray = latestURL.split(/[/]/);
        const item = urlArray.length
        if (urlArray[item - 1] === "civils" || urlArray[item - 2] === "civils") {
            setFormDirect('/app/civils-works/equipment?type=table')
        } else if (urlArray[item - 1] === "signalling" || urlArray[item - 2] === "signalling") {
            setFormDirect('/app/signalling-works/equipment?type=table')
        }
    }, [])

    const selectCurrentItem = (dataSet) => {
        let output = [];
        dataSet.features.forEach((item, index) => {

            if (item.id === selectedItemId) {
                output.push(item)
            }
        })
        return output
    }

    const currentItemData = selectCurrentItem(data.EquipmentCubicleCivilsData)[0]
    const itemName = currentItemData ? currentItemData.properties.Name : ""

    const lng = selectedItemId ? currentItemData.geometry.coordinates[0] : defaultFlyToCoord[0]
    const lat = selectedItemId ? currentItemData.geometry.coordinates[1] : defaultFlyToCoord[1]
    const zoom = selectedItemId ? 16 : 10


    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`

    const statusOptions = ["Outstanding", "Complete", "Existing"]
    const type = ["ASP", "PSP", "LOC", "REB", "DNO"]

    let defaultFormData = { properties: { textOffset: [0, 0] }, geometry: { coordinates: [0, 0] }, type: "Point" }

    const sentenceCaseString = (string) => {
        return string.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    const getMapIcon = (base, structure, description, imageList) => {
        const lastPartOfName = () => {
            if (base === "Existing" || structure === "Existing") {
                return "Existing";
            } else if (base === "Outstanding" && structure === "Outstanding") {
                return "Outstanding"
            } else if (base === "Complete" && structure === "Outstanding") {
                return "CivilsComplete"
            } else if (base === "Complete" && structure === "Complete") {
                return "Complete";
            }
        }

        const sentenceCasedType = sentenceCaseString(description);
        return imageList[`${sentenceCasedType}Rect${lastPartOfName()}`]
    }

    // IE CREATE 
    if (!selectedItemId || selectedItemId === undefined) {
        defaultFormData["dateCreated"] = new Date()
        defaultFormData["createdBy"] = logInUser
        defaultFormData["geometry"]["coordinates"] = defaultFlyToCoord

        defaultFormData["properties"]["rotation"] = 0
        defaultFormData["properties"]["textOffset"][0] = 0
        defaultFormData["properties"]["textOffset"][1] = 0
        defaultFormData["type"] = "Feature"
        defaultFormData["geometry"]["type"] = "Point"
        defaultFormData["properties"]["Name"] = ""
        defaultFormData["properties"]["Base"] = "Outstanding"
        defaultFormData["properties"]["Structure"] = "Outstanding"
        defaultFormData["properties"]["description"] = "ASP"
        defaultFormData["properties"]["type"] = "ASP"

    }
    // IE UPDATE
    if (selectedItemId) {
        defaultFormData["dateUpdated"] = new Date()
        defaultFormData["updatedBy"] = logInUser
        defaultFormData["properties"] = {}

    }

    const [formData, setFormData] = useState(defaultFormData);
    const mapIcon = formData["properties"]["description"] ? getMapIcon(formData["properties"]["Base"], formData["properties"]["Structure"], formData["properties"]["description"], equipmentMapSymbols) : null

    const handleFormPropertiesInputChange = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            properties: { ...formData.properties, [name]: value }
        }))

    }

    const handleDualFormPropertiesInputChange = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            properties: { ...formData.properties, [name]: value, ["type"]: value }
        }))


    }

    const handleRotationNumberInputChange = (e) => {
        const { name, value } = e.target
        let updatedRotation = Number(value)
        setFormData((curr) => ({
            ...curr,
            properties: {
                ...curr.properties,
                "rotation": updatedRotation
            }
        }))
    }

    const handleLongLatInputChange = ({ target }) => {
        const { name, value } = target;
        const indexForLongLat = {
            "long": 0,
            "lat": 1
        }
        let updatedLongLat = formData.geometry.coordinates;
        updatedLongLat[indexForLongLat[name]] = Number(value);
        setFormData((curr) => ({
            ...curr,
            geometry: {
                ...curr.geometry,
                coordinates: updatedLongLat
            }
        }))
    }


    const handleLngLatMapBox = (lngLat) => {
        setFormData((curr) => ({
            ...curr,
            geometry: {
                ...curr.geometry,
                coordinates: [lngLat.lng, lngLat.lat]
            }
        }))
    }

    const installInputChange = (e) => {
        const { name, value } = e.target
        let updatedProperties = { ...formData.properties, [name]: value };
        let orderedKeyNames = ["Base", "Structure"]
        const selectedKeyIndex = orderedKeyNames.indexOf(name)
        if (value == "Complete") {
            orderedKeyNames.splice(selectedKeyIndex + 1, orderedKeyNames.length - 1);
            if (orderedKeyNames.some((keyName) => updatedProperties[keyName] === "Outstanding")) {
                orderedKeyNames.forEach((keyName) => {
                    updatedProperties[keyName] = value;
                })
            }
        }
        setFormData((curr) => ({
            ...curr,
            properties: updatedProperties
        }))
    }

    useEffect(() => {
        if (params.id) {
            const docRef = doc(db, 'EquipmentCubicles', selectedItemId)
            getDoc(docRef).then(snapshot => {
                setFormData({ ...snapshot.data(), id: snapshot.id, dateUpdated: defaultFormData.dateUpdated, updatedBy: logInUser }, () => {

                })
                setLoadingData(false)
            })
        } else {
        }
    }, [])

    const [loadingData, setLoadingData] = useState(selectedItemId ? true : false)

    const updateDataOnFireBase = async () => {
        setLoadingData(true)
        const collectionRef = doc(db, "EquipmentCubicles", selectedItemId)
        await setDoc(collectionRef, formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "Equipment updated",
            update: `${userName} updated ${formData.properties.Name}`
        })
        localStorage.removeItem("ProjectDetails")
        setLoadingData(false)
        navigate('/app/civils-works/equipment?type=table')
    }

    const CreateDataEntryToFireBase = async () => {
        setLoadingData(true)
        await addDoc(collection(db, "EquipmentCubicles"), formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "New Equipment Asset",
            update: `${userName} added ${formData.properties.Name}`,
        })
        setLoadingData(false)
        navigate(formDirect)
    }

    const cardTitle = selectedItemId ? `Edit ${itemName}` : "Add Asset"
    const mainBtnTitle = selectedItemId ? "Update" : "Add"
    const btnFunction = selectedItemId ? updateDataOnFireBase : CreateDataEntryToFireBase

    return (
        <>

            <div className="dashboard-container">
                <DbCard
                    cardType={'graph-card-wide card-height-min card-static'}
                    icon={""}
                    title={`${cardTitle}`}
                    cardContent={""}
                    btnText={"Go back"}
                    mainBtnText={`${mainBtnTitle} Asset`}
                    btnDisplayBool={true}
                    loadingData={loadingData}
                    btnMainDisplayBool={true}
                    OnClickFuncMainBtn={btnFunction}
                    OnClickFunc={() => navigate(-1)}>

                    <>
                        <div className='form-sub-title'>Asset Details</div>
                        <div className='form-boxes-container'>
                            {/* GENERAL SECTION */}
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Equipment Name</p>
                                    <input className='input-box' type="text" value={formData["properties"]["Name"]} name={'Name'} placeholder={"Add Asset ID ..."} onChange={handleFormPropertiesInputChange} /><br />
                                </label>
                            </div>

                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Type</p>
                                    <select className='input-box' name={"description"} value={formData["properties"]["description"]} onChange={handleDualFormPropertiesInputChange}>
                                        {type.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                        </div>
                        <div className='form-sub-title'>Installation status</div>
                        <div className='form-boxes-container'>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Base Status</p>
                                    <select className='input-box' name={"Base"} value={formData["properties"]["Base"]} onChange={installInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>



                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Equipment Cubicle Status</p>
                                    <select className='input-box' name={"Structure"} value={formData["properties"]["Structure"]} onChange={installInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>


                        </div>

                        <div className='form-sub-title'>Asset Location</div>
                        <div className='form-boxes-container'>
                            <div class="input-container">
                                <FormMap lng={lng} lat={lat} rotation={formData["properties"]["rotation"]} zoom={zoom} handleLngLatMapBox={handleLngLatMapBox} mapIcon={mapIcon} formData={formData}></FormMap>
                                <div className='form-group-column-container'>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Longitude</p>
                                        <input className='input-box' type="number" value={formData["geometry"]["coordinates"][0]} name={'long'} placeholder={"Edit Longitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Latitude</p>
                                        <input className='input-box' type="number" value={formData["geometry"]["coordinates"][1]} name={'lat'} placeholder={"Enter latitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Equipment degrees rotation on map</p>
                                        <input className='input-box' type="number" value={parseInt(formData["properties"]["rotation"])} name={'rotation'} placeholder={"Input a rotation..."} onChange={handleRotationNumberInputChange} /><br />
                                    </label>
                                </div>
                            </div>


                        </div>
                    </>
                </DbCard>
            </div >
        </>
    )
}

export default EquipmentForm