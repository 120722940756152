import React, { useState } from "react";
import DropdownItem from "./DropdownItem";
import { useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";


import LogOutButton from "../assets/GeneralIcons/UIcon-Logout.svg";

export const navigationPathFactory = (path, typeParam) => {
  let navigationObject = {
    pathname: path
  }


  navigationObject["search"] = `?type=${typeParam ? typeParam : "map"}`;
  return navigationObject;
}

export default function SideNav({ dropDownMenuTitles, isHamburgerNavCollapsed, setIsHamburgerNavCollapsed }) {

  const urlParams = new URLSearchParams(window.location.search);
  const typeParam = urlParams.get("type");
  const { width, height, ref } = useResizeDetector()
  const isMobileLayout = () => {
    if (width < 639)
      return true
  }
  const classSwitcher = (isHamburgerNavCollapsed) => {
    if (!isHamburgerNavCollapsed) {
      return "mobilenavon"
    }
    return "mobilenavoff"
  }

  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(null);
  let navigate = useNavigate()
  const onMenuItemSelected = (index, menuItem) => {

    if (menuItem.forceExpand && isHamburgerNavCollapsed)
      setIsHamburgerNavCollapsed(false)
    if (index === selectedMenuItemIndex) {
      setSelectedMenuItemIndex(null)
      return;
    }
    if (menuItem.hasOwnProperty("path"))
      navigate(navigationPathFactory(menuItem.path, typeParam));
    setSelectedMenuItemIndex(index)


  }

  const isMenuCategorySelected = (menuItem, index, currentpath) => {
    if (!menuItem.hasOwnProperty("path")) {
      return index == selectedMenuItemIndex;
    }
    const test1 = menuItem.path.split("/")[2];
    const test2 = currentpath.split("/")[2];
    return menuItem.path.split("/")[2] == currentpath.split("/")[2]
  }
  const handleLogoClass = () => {
    if (isHamburgerNavCollapsed) {
      return ("dropdownmenu-logo dropdownCenter noSubMenu");
    } else if (!isHamburgerNavCollapsed) {
      return ("dropdownmenu-logo");
    }
  };
  const logOutFunction = () => { navigate('/logoutreview') }

  return (
    <div className={`sidenav ${classSwitcher(isHamburgerNavCollapsed)}`}>
      <div className="sidenav-menu">
        <div className="sidenav-menu-title">
          <p >Filters</p>
          <div className="sidenav-menu-title-underline"></div>
        </div>
        <div className="sidenav-menu-dropdown-container">
          {dropDownMenuTitles.map((item, index) => {
            if (item.hideOnRoutes && item.hideOnRoutes.includes(useLocation().pathname))
              return;
            return (<DropdownItem onClick={(() => onMenuItemSelected(index, item))} img={item.img} title={item.title}
              pathname={item.path}
              isHamburgerNavCollapsed={isHamburgerNavCollapsed} setIsHamburgerNavCollapsed={setIsHamburgerNavCollapsed} isSelected={isMenuCategorySelected(item, index, useLocation().pathname)} key={index + "drop-menu-titles"} subMenu={item.subMenu} isMobileLayout={isMobileLayout()} />
            )
          })
          }


          <div className="dropdownmenu logout" onClick={logOutFunction}>
            <div className={handleLogoClass()}>
              <img src={LogOutButton} alt="" />
              <p
                className={
                  isHamburgerNavCollapsed
                    ? "dropdownmenu-logo-titleHidden"
                    : "dropdownmenu-logo-title"
                }
              >
                Logout
              </p>
            </div>

          </div>


        </div>

      </div>
    </div>
  );
}


