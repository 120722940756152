import React, { useState } from 'react'

import BarButton from './../../Buttons/BarButton/BarButton';

import ButtonGrey from './../../../assets/ComponentIcons/ButtonIcon01_Button_grey.svg'

import { useLocation } from "react-router-dom";

import './GraphBar.css'



const GraphBar = props => {
    const { pathname } = useLocation();
    if (!props.graphData)
        return <></>
    const graphData = props.graphData;

    const graphbarDisplayToggle = props.graphbarDisplayToggle

    const [graphbarContentDisplayToggle, setgraphbarContentDisplayToggle] = useState(true);

    const [isCollapsed, setCollapse] = useState(false)
        ;
    let classNameToggle = "graphbar-background";

    if (isCollapsed) {
        classNameToggle += " graphbar-collapsed";

    }

    const ButtonFunction = () => {
        setCollapse(!isCollapsed);
        setgraphbarContentDisplayToggle(!graphbarContentDisplayToggle)
    }

    return (
        <>
            {true && (
                <div className={`${isCollapsed ? " graphbar-collapsed" : ""} graphbar-background`}>
                    <div className="bar-container-title-container text-grey">
                        <h2 className='side-bar-title'>{graphData.listItemtitle}</h2>


                        <BarButton
                            className={'button-grey-img'}
                            image={ButtonGrey}
                            buttonFunction={ButtonFunction}
                            id={'button-graphbar'}
                        />

                    </div>
                    <div className="side-bar-title-underline"></div>
                    {graphbarContentDisplayToggle && (<div className='graph-container'>
                        {props.children}
                    </div>)}

                </div>
            )
            }

            {isCollapsed && <div className="graphbar-button text-grey">

                <BarButton
                    className={'button-grey-img'}
                    image={ButtonGrey}
                    buttonFunction={ButtonFunction}
                    id={'button-graphbar'}
                />
            </div>}
        </>
    )
}

export default GraphBar