// MAP COMPONENT
import { MapSymbol } from '../ComponentGeneral/MapSymbol'
import { JSONDataFilterByProperty, JSONDataFilterByDualProperty } from '../../../../constants/functions/jsonDataFilterFunctions'
// IMAGES
import { equipmentMapSymbols } from '../../../../constants/MapSymbols/EquipmentMapSymbols'
import { uniqueEquipmentTypeIdentifier } from './functions/uniqueEquipmentTypeIdentifier'

import { equipmentDotDisplayStyles } from '../../../../constants/styles/mapStyles'

export const EquipSignalsDots = (map, isVisible, createPopUp, pathname, EquipmentCubicleSignallingData, routesStructure, tableContent) => {

    // Base data for these asset types
    const data = EquipmentCubicleSignallingData

    // Filter by status
    const existingData = JSONDataFilterByProperty(data, { "Structure": "Existing" }, "Existing", "Structure")
    const outstandingData = JSONDataFilterByProperty(data, { "Structure": "Outstanding" }, "Outstanding", "Structure")

    const completeData = JSONDataFilterByProperty(data, { "Structure": "Complete" }, "Complete", "Structure")

    const routePath = [routesStructure.signalling_all_route, routesStructure.signalling_cubicles_route, routesStructure.cabling_all_route, routesStructure.cabling_power, routesStructure.cabling_fibre]

    const equipmentTypes = uniqueEquipmentTypeIdentifier(equipmentMapSymbols)
    const disciplineTypes = ["civils", "signals"]

    const baseInfo = {
        map: map,
        isVisible,
        createPopUp: createPopUp,
        relatedPathNames: routePath,
        tableValues: tableContent,
        currentPathName: pathname,
        ...equipmentDotDisplayStyles
    }

    const symbolsData = equipmentTypes.map(equipmentType => {
        const objects = [];
        objects.push(
            {
                // Dots
                data: JSONDataFilterByProperty(existingData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
                source: `${equipmentType}-Dot-${disciplineTypes[1]}-base-existing`,
                imageSource: equipmentMapSymbols[`${equipmentType}DotExisting`],
                imageName: `${equipmentType}-Dot-${disciplineTypes[1]}-base-existing-image`,
                ...baseInfo
            },

            {
                // Dots
                data: JSONDataFilterByProperty(outstandingData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
                source: `${equipmentType}-Dot-${disciplineTypes[1]}-Civils-Outstanding`,
                imageSource: equipmentMapSymbols[`${equipmentType}DotOutstanding`],
                imageName: `${equipmentType}-Dot-${disciplineTypes[1]}Civils-Outstanding-image`,
                ...baseInfo
            },

            {
                // Dots
                data: JSONDataFilterByProperty(completeData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
                source: `${equipmentType}-Dot-${disciplineTypes[1]}-complete`,
                imageSource: equipmentMapSymbols[`${equipmentType}DotComplete`],
                imageName: `${equipmentType}-Dot-${disciplineTypes[1]}-complete-image`,
                ...baseInfo
            }
        )
        return objects
    }).flat()

    symbolsData.forEach((symbolData) => MapSymbol(symbolData));




}
