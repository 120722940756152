import React from 'react'

import { useLocation } from "react-router-dom";

import GraphBox from './GraphPageComponents/GraphBoxesWhite/GraphBox'
import SummaryBox from './GraphPageComponents/SummaryBox/SummaryBox'



import './GraphPage.css'

const GraphPage = (props) => {

    const { pathname } = useLocation();


    return (
        <div className='graph-page-container'>

            {pathname === '/app/project-summary' ? <div className="summarybox-container">

                <SummaryBox />

            </div> : <></>}

            <div className='graphbox-sub-container'>


                <GraphBox

                >

                </GraphBox>

            </div>



        </div>


    )
}

export default GraphPage