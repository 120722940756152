import React, { useState } from 'react'
import './Schematic.css'

const SchematicSVGContent = props => {

    const groupDisplayToggles = {
        civilsGroupDisplayToggle: props.civilsGroupDisplayToggle,
        signallingGroupDisplayToggle: props.signallingGroupDisplayToggle,
        cableGroupDisplayToggle: props.cableGroupDisplayToggle,
        templateGroupDisplayToggle: props.templateGroupDisplayToggle,
    }

    const subMenuDisplayToggles = {
        signalCivilsDisplayToggle: props.signalCivilsDisplayToggle,
        equipmentBasesDisplayToggle: props.equipmentBasesDisplayToggle,
        utxDisplayToggle: props.utxDisplayToggle,

        civilsTableDisplayToggle: props.civilsTableDisplayToggle,

        utxTableDisplayToggle: props.utxTableDisplayToggle,
        equipmentBasesTableDisplayToggle: props.equipmentBasesTableDisplayToggle,
        signalCivilsTableDisplayToggle: props.signalCivilsTableDisplayToggle,

        signalAssetsDisplayToggle: props.signalAssetsDisplayToggle,
        locRebPSPDisplayToggle: props.locRebPSPDisplayToggle,
        pointsDisplayToggle: props.pointsDisplayToggle,

        signallingEquipmentTableDisplayToggle: props.signallingEquipmentTableDisplayToggle,
        pointsTableDisplayToggle: props.pointsTableDisplayToggle,

        signallingTableDisplayToggle: props.signallingTableDisplayToggle,
    }

    // viewBox="0 0 1214.4331 1667.2274"
    return (
        <div className='schematic-container '>

            <div className='main-svg-map-container'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1160 1460">
                    <g id="feltham-map-container">
                        <defs>
                            <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
                                <feOffset dx="0" dy="0" />
                                <feGaussianBlur result="blur" stdDeviation="5" />
                                <feFlood flood-color="#dddbdb" flood-opacity=".75" />
                                <feComposite in2="blur" operator="in" />
                                <feComposite in="SourceGraphic" />
                            </filter>
                        </defs>
                        <g id="feltham-track-layout-container">
                            <g id="track-layout">
                                <path d="M129.8946,4.3425V47.1856c0,6.5215,2.3516,12.8281,6.6211,17.7568L654.0011,662.3954c5.845,6.7482,9.0623,15.3768,9.0623,24.3044v756.8675" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                                <path d="M643.0614,1443.5673V687.2363c0-4.4638-1.6087-8.7781-4.5311-12.1522L121.3975,78.0382c-7.4185-8.5645-11.5039-19.5215-11.5039-30.8525V4.3425" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                                <path d="M1154.4861,844.1554h-447.043c-13.6807,0-26.6641-5.9316-35.6191-16.2725l-19.6782-22.6776c-5.8587-6.7517-9.0843-15.3907-9.0843-24.3299v-.3675" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                                <path d="M1154.4861,824.1535h-447.043c-7.874,0-15.3457-3.4131-20.5-9.3652l-19.3485-22.3382c-2.9225-3.3741-4.5312-7.6885-4.5312-12.1523v-2.9775" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                                <path d="M643.0614,738.6675v10.2234c0,9.8455-3.9111,19.2878-10.8729,26.2496h0c-5.8452,5.8452-9.129,13.773-9.129,22.0394v48.6047" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                                <path d="M663.0357,796.0154v-5.398c0-8.2667-3.2839-16.1949-9.1294-22.0404l-1.9243-1.9243c-5.7117-5.7117-8.9206-13.4585-8.9206-21.5362h0" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                                <path d="M663.0357,1283.4258v-5.398c0-8.2667-3.2839-16.1949-9.1294-22.0404l-1.9243-1.9243c-5.7117-5.7117-8.9206-13.4585-8.9206-21.5362h0" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                            </g>
                            <g id="station-platforms">
                                <rect x="196.8173" y="136.5396" width="45.3699" height="10.7257" transform="translate(256.2068 400.6887) rotate(-130.9543)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="177.8981" y="182.9431" width="45.3699" height="10.7257" transform="translate(189.8415 463.2192) rotate(-130.9543)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="307.485" y="331.7956" width="45.3699" height="10.7257" transform="translate(369.3608 -133.1096) rotate(49.1639)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="340.5577" y="302.715" width="45.3699" height="10.7257" transform="translate(358.8055 -168.1967) rotate(49.1639)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="432.5098" y="476.5736" width="45.3699" height="10.7257" transform="translate(522.1687 -177.5933) rotate(49.1639)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="465.5825" y="447.493" width="45.3699" height="10.7257" transform="translate(511.6134 -212.6804) rotate(49.1639)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="1040.0034" y="852.0676" width="45.3699" height="10.7257" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="1056.5284" y="803.5624" width="45.3699" height="10.7257" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="606.1248" y="963.8239" width="45.3699" height="10.7257" transform="translate(1597.9965 340.377) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="654.6301" y="963.8239" width="45.3699" height="10.7257" transform="translate(1646.5018 291.8717) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="609.0186" y="688.549" width="39.5823" height="10.7257" transform="translate(1322.7216 65.1021) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="661.5681" y="690.974" width="31.4938" height="10.7257" transform="translate(1373.6518 19.0218) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="606.1248" y="1127.8264" width="45.3699" height="10.7257" transform="translate(1761.999 504.3795) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="654.6301" y="1127.8264" width="45.3699" height="10.7257" transform="translate(1810.5043 455.8742) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="606.1248" y="1291.8289" width="45.3699" height="10.7257" transform="translate(1926.0015 668.382) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="654.6301" y="1291.8289" width="45.3699" height="10.7257" transform="translate(1974.5068 619.8767) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="606.1248" y="1414.93" width="45.3699" height="10.7257" transform="translate(2049.1026 791.4831) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                                <rect x="654.6301" y="1414.93" width="45.3699" height="10.7257" transform="translate(2097.6079 742.9778) rotate(90)" fill="#9d9d9c" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4631" />
                            </g>
                            <g id="station-platform-number">
                                <g>
                                    <path d="M219.3799,137.5775c.2427,.2012,.543,.4795,.8828,.8701,.417,.4805,.6108,.9297,.6035,1.3281,.001,.3623-.166,.7227-.5015,1.0137-.3408,.2969-.6968,.4287-1.0571,.4082-.4971-.0195-1.0005-.3164-1.4131-.793-.126-.1445-.2368-.2832-.3062-.4199l-1.5088,1.3096-.4219-.4863,3.7222-3.2305Zm-1.3945,2.0635c.064,.1289,.1694,.2734,.3101,.4355,.5093,.5869,1.105,.6973,1.625,.2461,.4976-.4316,.4316-.9922-.0342-1.5283-.1841-.2119-.3413-.3604-.4365-.4248l-1.4644,1.2715Z" fill="#fff" />
                                    <path d="M218.0894,143.6097l.3018-.2617,.7095,.0605c1.6885,.1621,2.521,.1738,3.0742-.2959,.3687-.3203,.5547-.7949,.084-1.3369-.2861-.3301-.6919-.459-.9771-.5049l.2109-.458c.4307,.0684,.8975,.29,1.2612,.709,.6792,.7832,.4287,1.5781-.0908,2.0293-.6709,.582-1.6348,.5664-3.0376,.4414l-.52-.0586-.0117,.0098,1.4165,1.6318-.4077,.3545-2.0132-2.3203Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M200.4668,183.9808c.2427,.2012,.543,.4795,.8828,.8701,.417,.4805,.6108,.9297,.6035,1.3281,.001,.3623-.166,.7227-.5015,1.0137-.3408,.2969-.6968,.4287-1.0571,.4082-.4971-.0195-1.0005-.3164-1.4131-.793-.126-.1445-.2368-.2832-.3062-.4199l-1.5088,1.3096-.4219-.4863,3.7222-3.2305Zm-1.3945,2.0635c.064,.1289,.1694,.2734,.3101,.4355,.5093,.5869,1.105,.6973,1.625,.2461,.4976-.4316,.4316-.9922-.0342-1.5283-.1841-.2119-.3413-.3604-.4365-.4248l-1.4644,1.2715Z" fill="#fff" />
                                    <path d="M203.2779,188.3255l-.0098-.0107-.8896-.335,.2925-.4209,1.1138,.4248,.3638,.4199-3.6333,3.1523-.4121-.4746,3.1748-2.7559Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M330.0601,332.9261c.2422,.2021,.542,.4805,.8809,.8721,.416,.4814,.6089,.9316,.6011,1.3291,0,.3623-.168,.7227-.5039,1.0127-.3413,.2949-.6978,.4277-1.0576,.4062-.4976-.0215-1.0005-.3193-1.4116-.7959-.126-.1455-.2368-.2842-.3052-.4209l-1.5117,1.3066-.4209-.4863,3.729-3.2236Zm-1.3989,2.0596c.064,.1309,.1689,.2754,.3096,.4375,.5078,.5879,1.103,.6992,1.6235,.249,.4985-.4307,.4346-.9912-.0298-1.5283-.1841-.2129-.3408-.3613-.4365-.4258l-1.4668,1.2676Z" fill="#fff" />
                                    <path d="M328.7569,338.9564l.3022-.2617,.7095,.0625c1.688,.1641,2.521,.1777,3.0747-.29,.3696-.3193,.5566-.7939,.0869-1.3369-.2856-.3311-.6909-.46-.9761-.5068l.2119-.4561c.4307,.0674,.8965,.291,1.2593,.71,.6777,.7842,.4258,1.5791-.0947,2.0283-.6724,.5811-1.6362,.5635-3.0386,.4355l-.52-.0596-.0112,.0098,1.4131,1.6357-.4087,.3535-2.0083-2.3242Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M363.1324,303.845c.2422,.2021,.542,.4805,.8809,.8721,.416,.4814,.6089,.9316,.6011,1.3291,0,.3623-.168,.7227-.5039,1.0127-.3413,.2949-.6978,.4277-1.0576,.4062-.4976-.0215-1.0005-.3193-1.4116-.7959-.126-.1455-.2368-.2842-.3052-.4209l-1.5117,1.3066-.4209-.4863,3.729-3.2236Zm-1.3989,2.0596c.064,.1309,.1689,.2754,.3096,.4375,.5078,.5879,1.103,.6992,1.6235,.249,.4985-.4307,.4346-.9912-.0298-1.5283-.1841-.2129-.3408-.3613-.4365-.4258l-1.4668,1.2676Z" fill="#fff" />
                                    <path d="M365.9336,308.1956l-.0093-.0107-.8887-.3369,.293-.4199,1.1128,.4268,.3628,.4199-3.6396,3.1455-.4111-.4756,3.1802-2.749Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M455.0845,477.7034c.2422,.2021,.542,.4805,.8809,.8721,.416,.4814,.6089,.9316,.6011,1.3291,0,.3623-.168,.7227-.5039,1.0127-.3413,.2949-.6978,.4277-1.0576,.4062-.4976-.0215-1.0005-.3193-1.4116-.7959-.126-.1455-.2368-.2842-.3052-.4209l-1.5117,1.3066-.4209-.4863,3.729-3.2236Zm-1.3989,2.0596c.064,.1309,.1689,.2754,.3096,.4375,.5078,.5879,1.103,.6992,1.6235,.249,.4985-.4307,.4346-.9912-.0298-1.5283-.1841-.2129-.3408-.3613-.4365-.4258l-1.4668,1.2676Z" fill="#fff" />
                                    <path d="M453.7813,483.7337l.3022-.2617,.7095,.0625c1.688,.1641,2.521,.1777,3.0747-.29,.3696-.3193,.5566-.7939,.0869-1.3369-.2856-.3311-.6909-.46-.9761-.5068l.2119-.4561c.4307,.0674,.8965,.291,1.2593,.71,.6777,.7842,.4258,1.5791-.0947,2.0283-.6724,.5811-1.6362,.5635-3.0386,.4355l-.52-.0596-.0112,.0098,1.4131,1.6357-.4087,.3535-2.0083-2.3242Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M488.1568,448.6234c.2422,.2021,.542,.4805,.8809,.8721,.416,.4814,.6089,.9316,.6011,1.3291,0,.3623-.168,.7227-.5039,1.0127-.3413,.2949-.6978,.4277-1.0576,.4062-.4976-.0215-1.0005-.3193-1.4116-.7959-.126-.1455-.2368-.2842-.3052-.4209l-1.5117,1.3066-.4209-.4863,3.729-3.2236Zm-1.3989,2.0596c.064,.1309,.1689,.2754,.3096,.4375,.5078,.5879,1.103,.6992,1.6235,.249,.4985-.4307,.4346-.9912-.0298-1.5283-.1841-.2129-.3408-.3613-.4365-.4258l-1.4668,1.2676Z" fill="#fff" />
                                    <path d="M490.958,452.9739l-.0093-.0107-.8887-.3369,.293-.4199,1.1128,.4268,.3628,.4199-3.6396,3.1455-.4111-.4756,3.1802-2.749Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M1075.9073,806.1634c.3105-.0518,.7168-.0967,1.2354-.0967,.6357,0,1.1025,.1484,1.3984,.415,.2744,.2363,.4365,.5986,.4365,1.043,0,.4512-.1328,.8066-.3848,1.0654-.3398,.3633-.8945,.5479-1.5244,.5479-.1924,0-.3701-.0068-.5186-.0439v1.998h-.6426v-4.9287Zm.6426,2.4053c.1406,.0361,.3184,.0518,.5332,.0518,.7773,0,1.251-.3779,1.251-1.0664,0-.6582-.4658-.9766-1.1768-.9766-.2812,0-.4961,.0225-.6074,.0518v1.9395Z" fill="#fff" />
                                    <path d="M1081.0303,806.888h-.0146l-.8369,.4521-.125-.4961,1.0508-.5625h.5547v4.8105h-.6289v-4.2041Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M1059.3819,854.5775c.3105-.0518,.7178-.0967,1.2354-.0967,.6367,0,1.1025,.1484,1.3984,.415,.2744,.2363,.4375,.5986,.4375,1.043,0,.4512-.1338,.8066-.3848,1.0654-.3408,.3633-.8955,.5479-1.5254,.5479-.1924,0-.3691-.0068-.5176-.0439v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0361,.3184,.0518,.5332,.0518,.7764,0,1.25-.3779,1.25-1.0664,0-.6582-.4658-.9766-1.1768-.9766-.2812,0-.4951,.0225-.6064,.0518v1.9395Z" fill="#fff" />
                                    <path d="M1063.0918,859.5062v-.3994l.5107-.4961c1.2285-1.1699,1.7832-1.791,1.791-2.5166,0-.4883-.2373-.9395-.9551-.9395-.4365,0-.7988,.2217-1.0215,.4072l-.207-.459c.333-.2812,.8066-.4883,1.3613-.4883,1.0361,0,1.4727,.71,1.4727,1.3984,0,.8877-.6436,1.6055-1.6572,2.583l-.3848,.3545v.0156h2.1611v.54h-3.0713Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M674.0176,966.431c.3105-.0518,.7178-.0957,1.2363-.0957,.6357,0,1.1025,.1475,1.3984,.4141,.2734,.2373,.4365,.5996,.4365,1.0439,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3623-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0078-.5186-.0449v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0371,.3184,.0518,.5332,.0518,.7773,0,1.251-.377,1.251-1.0654,0-.6592-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0215-.6074,.0518v1.9385Z" fill="#fff" />
                                    <path d="M679.1416,967.1566h-.0146l-.8369,.4512-.126-.4961,1.0508-.5625h.5557v4.8105h-.6289v-4.2031Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M625.6045,966.43c.3105-.0518,.7178-.0957,1.2363-.0957,.6357,0,1.1025,.1475,1.3984,.4141,.2734,.2373,.4365,.5996,.4365,1.0439,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3623-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0078-.5186-.0449v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0371,.3184,.0518,.5332,.0518,.7773,0,1.251-.377,1.251-1.0654,0-.6592-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0215-.6074,.0518v1.9385Z" fill="#fff" />
                                    <path d="M629.3145,971.3587v-.3994l.5107-.4961c1.2285-1.1689,1.7832-1.791,1.791-2.5156,0-.4883-.2363-.9404-.9551-.9404-.4365,0-.7988,.2227-1.0205,.4072l-.207-.459c.333-.2812,.8066-.4883,1.3613-.4883,1.0361,0,1.4727,.7109,1.4727,1.3984,0,.8887-.6436,1.6064-1.6582,2.583l-.3848,.3555v.0146h2.1611v.54h-3.0713Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M674.0176,683.1302c.3105-.0518,.7178-.0957,1.2363-.0957,.6357,0,1.1025,.1475,1.3984,.4141,.2734,.2363,.4365,.5996,.4365,1.043,0,.4512-.1328,.8066-.3848,1.0664-.3408,.3623-.8955,.5469-1.5244,.5469-.1924,0-.3701-.0068-.5186-.0439v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0361,.3184,.0518,.5332,.0518,.7773,0,1.251-.377,1.251-1.0664,0-.6582-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0225-.6074,.0527v1.9385Z" fill="#fff" />
                                    <path d="M679.1416,683.8548h-.0146l-.8369,.4521-.126-.4961,1.0508-.5625h.5557v4.8105h-.6289v-4.2041Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M625.6045,682.1273c.3105-.0518,.7178-.0957,1.2363-.0957,.6357,0,1.1025,.1475,1.3984,.4141,.2734,.2373,.4365,.5996,.4365,1.0439,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3623-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0078-.5186-.0449v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0371,.3184,.0518,.5332,.0518,.7773,0,1.251-.377,1.251-1.0654,0-.6592-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0215-.6074,.0518v1.9385Z" fill="#fff" />
                                    <path d="M629.3145,687.056v-.3994l.5107-.4961c1.2285-1.1689,1.7832-1.791,1.791-2.5156,0-.4883-.2363-.9404-.9551-.9404-.4365,0-.7988,.2227-1.0205,.4072l-.207-.459c.333-.2812,.8066-.4883,1.3613-.4883,1.0361,0,1.4727,.7109,1.4727,1.3984,0,.8887-.6436,1.6064-1.6582,2.583l-.3848,.3555v.0146h2.1611v.54h-3.0713Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M674.0176,1130.4339c.3105-.0518,.7178-.0967,1.2363-.0967,.6357,0,1.1025,.1484,1.3984,.415,.2734,.2363,.4365,.5986,.4365,1.043,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3633-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0068-.5186-.0439v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0361,.3184,.0518,.5332,.0518,.7773,0,1.251-.3779,1.251-1.0664,0-.6582-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0225-.6074,.0518v1.9395Z" fill="#fff" />
                                    <path d="M679.1416,1131.1585h-.0146l-.8369,.4521-.126-.4961,1.0508-.5625h.5557v4.8105h-.6289v-4.2041Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M625.6045,1130.4329c.3105-.0518,.7178-.0967,1.2363-.0967,.6357,0,1.1025,.1484,1.3984,.415,.2734,.2363,.4365,.5986,.4365,1.043,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3633-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0068-.5186-.0439v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0361,.3184,.0518,.5332,.0518,.7773,0,1.251-.3779,1.251-1.0664,0-.6582-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0225-.6074,.0518v1.9395Z" fill="#fff" />
                                    <path d="M629.3145,1135.3616v-.3994l.5107-.4961c1.2285-1.1699,1.7832-1.791,1.791-2.5166,0-.4883-.2363-.9395-.9551-.9395-.4365,0-.7988,.2217-1.0205,.4072l-.207-.459c.333-.2812,.8066-.4883,1.3613-.4883,1.0361,0,1.4727,.71,1.4727,1.3984,0,.8877-.6436,1.6055-1.6582,2.583l-.3848,.3545v.0156h2.1611v.54h-3.0713Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M674.0176,1294.4359c.3105-.0518,.7178-.0957,1.2363-.0957,.6357,0,1.1025,.1475,1.3984,.4141,.2734,.2373,.4365,.5996,.4365,1.0439,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3623-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0078-.5186-.0449v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0371,.3184,.0518,.5332,.0518,.7773,0,1.251-.377,1.251-1.0654,0-.6592-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0215-.6074,.0518v1.9385Z" fill="#fff" />
                                    <path d="M679.1416,1295.1614h-.0146l-.8369,.4512-.126-.4961,1.0508-.5625h.5557v4.8105h-.6289v-4.2031Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M625.6045,1294.4349c.3105-.0518,.7178-.0957,1.2363-.0957,.6357,0,1.1025,.1475,1.3984,.4141,.2734,.2373,.4365,.5996,.4365,1.0439,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3623-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0078-.5186-.0449v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0371,.3184,.0518,.5332,.0518,.7773,0,1.251-.377,1.251-1.0654,0-.6592-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0215-.6074,.0518v1.9385Z" fill="#fff" />
                                    <path d="M629.3145,1299.3636v-.3994l.5107-.4961c1.2285-1.1689,1.7832-1.791,1.791-2.5156,0-.4883-.2363-.9404-.9551-.9404-.4365,0-.7988,.2227-1.0205,.4072l-.207-.459c.333-.2812,.8066-.4883,1.3613-.4883,1.0361,0,1.4727,.7109,1.4727,1.3984,0,.8887-.6436,1.6064-1.6582,2.583l-.3848,.3555v.0146h2.1611v.54h-3.0713Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M674.0176,1417.5374c.3105-.0518,.7178-.0967,1.2363-.0967,.6357,0,1.1025,.1484,1.3984,.415,.2734,.2363,.4365,.5986,.4365,1.043,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3633-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0068-.5186-.0439v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0361,.3184,.0518,.5332,.0518,.7773,0,1.251-.3779,1.251-1.0664,0-.6582-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0225-.6074,.0518v1.9395Z" fill="#fff" />
                                    <path d="M679.1416,1418.262h-.0146l-.8369,.4521-.126-.4961,1.0508-.5625h.5557v4.8105h-.6289v-4.2041Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M625.6045,1417.5364c.3105-.0518,.7178-.0967,1.2363-.0967,.6357,0,1.1025,.1484,1.3984,.415,.2734,.2363,.4365,.5986,.4365,1.043,0,.4512-.1328,.8066-.3848,1.0654-.3408,.3633-.8955,.5479-1.5244,.5479-.1924,0-.3701-.0068-.5186-.0439v1.998h-.6436v-4.9287Zm.6436,2.4053c.1406,.0361,.3184,.0518,.5332,.0518,.7773,0,1.251-.3779,1.251-1.0664,0-.6582-.4668-.9766-1.1768-.9766-.2812,0-.4961,.0225-.6074,.0518v1.9395Z" fill="#fff" />
                                    <path d="M629.3145,1422.4652v-.3994l.5107-.4961c1.2285-1.1699,1.7832-1.791,1.791-2.5166,0-.4883-.2363-.9395-.9551-.9395-.4365,0-.7988,.2217-1.0205,.4072l-.207-.459c.333-.2812,.8066-.4883,1.3613-.4883,1.0361,0,1.4727,.71,1.4727,1.3984,0,.8877-.6436,1.6055-1.6582,2.583l-.3848,.3545v.0156h2.1611v.54h-3.0713Z" fill="#fff" />
                                </g>
                            </g>
                            <g id="station-names">
                                <g>
                                    <path d="M239.4522,122.3284h-3.0615v1.9756h3.4199v1.543h-5.3086v-8.3203h5.1357v1.543h-3.2471v1.7285h3.0615v1.5303Z" fill="#fff" />
                                    <path d="M244.4629,125.847l-.1113-.6055h-.0371c-.3945,.4814-1.0117,.7412-1.7275,.7412-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5684,1.4072-2.3213,3.542-2.3086v-.0869c0-.3203-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.21-1.666,.457l-.3457-1.21c.4199-.2344,1.2471-.5312,2.3457-.5312,2.0117,0,2.6533,1.1855,2.6533,2.6055v2.0986c0,.5801,.0254,1.1348,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0127-1.7529,.2217-1.7529,.9502,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3203v-.6543Z" fill="#fff" />
                                    <path d="M247.5479,121.7982c0-.8896-.0244-1.4697-.0488-1.9883h1.6162l.0625,1.1113h.0488c.3086-.876,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0254v1.7646c-.1357-.0244-.2959-.0488-.5059-.0488-.6914,0-1.1602,.3701-1.2842,.9502-.0244,.123-.0371,.2715-.0371,.4199v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M252.2002,117.0823h1.876v8.7646h-1.876v-8.7646Z" fill="#fff" />
                                    <path d="M257.0391,123.4271c.0615,.7783,.8271,1.1484,1.7031,1.1484,.6426,0,1.1611-.0869,1.667-.2471l.2471,1.2715c-.6172,.2471-1.3701,.3701-2.1855,.3701-2.0488,0-3.2217-1.1846-3.2217-3.0732,0-1.5312,.9502-3.2227,3.0488-3.2227,1.9512,0,2.6914,1.5186,2.6914,3.0127,0,.3203-.0371,.6045-.0615,.7402h-3.8887Zm2.21-1.2832c0-.457-.1982-1.2227-1.0615-1.2227-.79,0-1.1113,.7158-1.1611,1.2227h2.2227Z" fill="#fff" />
                                    <path d="M263.4444,119.8099l.9014,2.9629c.0986,.3584,.2217,.8027,.2959,1.124h.0371c.0869-.3213,.1855-.7783,.2715-1.124l.7412-2.9629h2.0117l-1.4072,3.9756c-.8643,2.3945-1.4443,3.3574-2.123,3.9619-.6543,.5684-1.3457,.7656-1.8145,.8271l-.3955-1.5918c.2344-.0371,.5312-.1484,.8154-.3213,.2832-.1484,.5918-.4443,.7773-.7529,.0615-.0869,.0986-.1855,.0986-.2715,0-.0615-.0127-.1602-.0859-.3086l-2.21-5.5186h2.0859Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M502.5869,440.136l-1.9746-8.3203h2.0117l.6299,3.4326c.1846,.9873,.3574,2.0615,.4932,2.9004h.0254c.1357-.9014,.333-1.9014,.543-2.9258l.7031-3.4072h2l.667,3.5059c.1846,.9756,.3203,1.8643,.4443,2.79h.0244c.123-.9258,.3086-1.9004,.4814-2.8887l.6787-3.4072h1.9141l-2.1484,8.3203h-2.0371l-.7031-3.5801c-.1602-.8389-.2969-1.6162-.3955-2.5674h-.0244c-.1484,.9385-.2842,1.7285-.4814,2.5674l-.79,3.5801h-2.0615Z" fill="#fff" />
                                    <path d="M513.7442,432.4212c0,.5186-.3955,.9385-1.0127,.9385-.5928,0-.9873-.4199-.9746-.9385-.0127-.543,.3818-.9502,.9873-.9502,.6045,0,.9873,.4072,1,.9502Zm-1.9385,7.7148v-6.0361h1.876v6.0361h-1.876Z" fill="#fff" />
                                    <path d="M515.1875,436.0257c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3828,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8145,2.1602,2.5918v3.5801h-1.876v-3.3447c0-.7783-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3574-.9512,.7031-.0488,.1113-.0742,.2969-.0742,.4697v3.4805h-1.876v-4.1104Z" fill="#fff" />
                                    <path d="M522.42,436.0257c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3828,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8145,2.1602,2.5918v3.5801h-1.876v-3.3447c0-.7783-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3574-.9512,.7031-.0488,.1113-.0742,.2969-.0742,.4697v3.4805h-1.876v-4.1104Z" fill="#fff" />
                                    <path d="M531.0967,437.7171c.0615,.7773,.8271,1.1475,1.7031,1.1475,.6426,0,1.1611-.0859,1.667-.2471l.2471,1.2715c-.6172,.2471-1.3701,.3711-2.1855,.3711-2.0488,0-3.2217-1.1855-3.2217-3.0742,0-1.5303,.9502-3.2217,3.0488-3.2217,1.9512,0,2.6914,1.5186,2.6914,3.0117,0,.3213-.0371,.6055-.0615,.7412h-3.8887Zm2.21-1.2842c0-.457-.1982-1.2217-1.0615-1.2217-.79,0-1.1113,.7158-1.1611,1.2217h2.2227Z" fill="#fff" />
                                    <path d="M536.169,436.0872c0-.8887-.0244-1.4688-.0488-1.9873h1.6162l.0625,1.1113h.0488c.3086-.877,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0244v1.7656c-.1357-.0244-.2959-.0498-.5059-.0498-.6914,0-1.1602,.3701-1.2842,.9512-.0244,.123-.0371,.2715-.0371,.4189v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M540.8467,438.4944c.3457,.21,1.0625,.457,1.6172,.457,.5684,0,.8027-.1973,.8027-.5068,0-.3086-.1855-.4561-.8887-.6904-1.2471-.4199-1.7285-1.0986-1.7158-1.8154,0-1.123,.9629-1.9746,2.4561-1.9746,.7041,0,1.333,.1602,1.7041,.3457l-.334,1.2959c-.2715-.1484-.79-.3457-1.3076-.3457-.457,0-.7168,.1855-.7168,.4941,0,.2842,.2354,.4316,.9756,.6914,1.1484,.3945,1.6299,.9746,1.6416,1.8633,0,1.124-.8887,1.9512-2.6172,1.9512-.79,0-1.4932-.1729-1.9502-.4199l.333-1.3457Z" fill="#fff" />
                                    <path d="M546.2159,431.3714h1.876v3.4443h.0254c.1973-.2588,.4443-.457,.7402-.6172,.2715-.1484,.6299-.2344,.9756-.2344,1.1973,0,2.1104,.8271,2.1104,2.6416v3.5303h-1.876v-3.3203c0-.79-.2715-1.333-.9756-1.333-.4932,0-.8018,.3203-.9375,.6543-.0498,.123-.0625,.2832-.0625,.4189v3.5801h-1.876v-8.7646Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M544.7696,702.0218l-1.9746-8.3203h2.0117l.6299,3.4326c.1846,.9873,.3574,2.0615,.4932,2.9004h.0254c.1357-.9014,.333-1.9014,.543-2.9258l.7031-3.4072h2l.667,3.5059c.1846,.9756,.3203,1.8643,.4443,2.79h.0244c.123-.9258,.3086-1.9004,.4814-2.8887l.6787-3.4072h1.9141l-2.1484,8.3203h-2.0371l-.7031-3.5801c-.1602-.8389-.2969-1.6162-.3955-2.5674h-.0244c-.1484,.9385-.2842,1.7285-.4814,2.5674l-.79,3.5801h-2.0615Z" fill="#fff" />
                                    <path d="M559.7666,698.9359c0,2.21-1.5684,3.2217-3.1855,3.2217-1.7646,0-3.123-1.1602-3.123-3.1104s1.2842-3.1973,3.2217-3.1973c1.8516,0,3.0869,1.2715,3.0869,3.0859Zm-4.3701,.0615c0,1.0371,.4316,1.8145,1.2344,1.8145,.7285,0,1.1973-.7275,1.1973-1.8145,0-.9004-.3457-1.8145-1.1973-1.8145-.9014,0-1.2344,.9258-1.2344,1.8145Z" fill="#fff" />
                                    <path d="M562.8008,698.5286h.0254c.1357-.2471,.2832-.4941,.4316-.7285l1.2227-1.8145h2.2588l-2.1602,2.4443,2.4688,3.5918h-2.3086l-1.457-2.4814-.4814,.5928v1.8887h-1.876v-8.7646h1.876v5.2715Z" fill="#fff" />
                                    <path d="M569.6758,694.3069c0,.5186-.3955,.9385-1.0127,.9385-.5928,0-.9873-.4199-.9746-.9385-.0127-.543,.3818-.9502,.9873-.9502,.6045,0,.9873,.4072,1,.9502Zm-1.9385,7.7148v-6.0361h1.876v6.0361h-1.876Z" fill="#fff" />
                                    <path d="M571.1192,697.9114c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3828,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8145,2.1602,2.5918v3.5801h-1.876v-3.3447c0-.7783-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3574-.9512,.7031-.0488,.1113-.0742,.2969-.0742,.4697v3.4805h-1.876v-4.1104Z" fill="#fff" />
                                    <path d="M584.1162,695.9857c-.0244,.3828-.0488,.8887-.0488,1.79v3.3701c0,1.1602-.2344,2.1104-.9141,2.7158-.666,.5674-1.5674,.7402-2.4561,.7402-.79,0-1.6299-.1602-2.1729-.4688l.3701-1.4199c.3828,.2227,1.0498,.457,1.7529,.457,.8887,0,1.5684-.4814,1.5684-1.5928v-.3945h-.0254c-.3574,.5059-.9375,.79-1.6289,.79-1.4941,0-2.5557-1.21-2.5557-2.9502,0-1.9385,1.2588-3.1729,2.7402-3.1729,.8271,0,1.3457,.3584,1.6543,.8516h.0244l.0625-.7158h1.6289Zm-1.9258,2.4561c0-.123-.0117-.2461-.0371-.3447-.1357-.4941-.4932-.8271-1.0117-.8271-.6787,0-1.2344,.6172-1.2344,1.7158,0,.9004,.4443,1.6045,1.2344,1.6045,.4814,0,.8643-.3213,.9873-.7656,.0498-.1357,.0615-.333,.0615-.4932v-.8896Z" fill="#fff" />
                                    <path d="M585.5713,693.2571h1.876v3.4443h.0254c.1973-.2588,.4443-.457,.7402-.6172,.2715-.1484,.6299-.2344,.9756-.2344,1.1973,0,2.1104,.8271,2.1104,2.6416v3.5303h-1.876v-3.3203c0-.79-.2715-1.333-.9756-1.333-.4932,0-.8018,.3203-.9375,.6543-.0498,.123-.0625,.2832-.0625,.4189v3.5801h-1.876v-8.7646Z" fill="#fff" />
                                    <path d="M596.2354,702.0218l-.1113-.6045h-.0371c-.3945,.4814-1.0117,.7402-1.7275,.7402-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5674,1.4072-2.3203,3.542-2.3086v-.0859c0-.3213-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.209-1.666,.4561l-.3457-1.21c.4199-.2344,1.2471-.5303,2.3457-.5303,2.0117,0,2.6533,1.1846,2.6533,2.6045v2.0986c0,.5801,.0254,1.1357,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0117-1.7529,.2227-1.7529,.9512,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3213v-.6543Z" fill="#fff" />
                                    <path d="M599.3203,697.9114c0-.7529-.0244-1.3945-.0488-1.9258h1.5801l.0859,.8145h.0371c.2588-.3828,.79-.9502,1.8271-.9502,.7773,0,1.3945,.3945,1.6543,1.0244h.0244c.2217-.3086,.4932-.5557,.7773-.7285,.334-.1973,.7041-.2959,1.1484-.2959,1.1602,0,2.0361,.8145,2.0361,2.6172v3.5547h-1.8262v-3.2832c0-.877-.2842-1.3828-.8887-1.3828-.4326,0-.7412,.2959-.8643,.6543-.0498,.1357-.0742,.333-.0742,.4814v3.5303h-1.8271v-3.3818c0-.7656-.2715-1.2842-.8643-1.2842-.4814,0-.7646,.3701-.876,.6787-.0615,.1484-.0742,.3213-.0742,.4697v3.5176h-1.8271v-4.1104Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M368.9766,274.5892l-1.9746-8.3203h2.0117l.6299,3.4326c.1846,.9873,.3574,2.0615,.4932,2.9004h.0254c.1357-.9014,.333-1.9014,.543-2.9258l.7031-3.4072h2l.667,3.5059c.1846,.9756,.3203,1.8643,.4443,2.79h.0244c.123-.9258,.3086-1.9004,.4814-2.8887l.6787-3.4072h1.9141l-2.1484,8.3203h-2.0371l-.7031-3.5801c-.1602-.8389-.2969-1.6162-.3955-2.5674h-.0244c-.1484,.9385-.2842,1.7285-.4814,2.5674l-.79,3.5801h-2.0615Z" fill="#fff" />
                                    <path d="M380.1338,266.8743c0,.5186-.3955,.9385-1.0127,.9385-.5928,0-.9873-.4199-.9746-.9385-.0127-.543,.3818-.9502,.9873-.9502,.6045,0,.9873,.4072,1,.9502Zm-1.9385,7.7148v-6.0361h1.876v6.0361h-1.876Z" fill="#fff" />
                                    <path d="M381.5772,270.4788c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3828,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8145,2.1602,2.5918v3.5801h-1.876v-3.3447c0-.7783-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3574-.9512,.7031-.0488,.1113-.0742,.2969-.0742,.4697v3.4805h-1.876v-4.1104Z" fill="#fff" />
                                    <path d="M388.8096,270.4788c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3828,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8145,2.1602,2.5918v3.5801h-1.876v-3.3447c0-.7783-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3574-.9512,.7031-.0488,.1113-.0742,.2969-.0742,.4697v3.4805h-1.876v-4.1104Z" fill="#fff" />
                                    <path d="M397.4864,272.1702c.0615,.7773,.8271,1.1475,1.7031,1.1475,.6426,0,1.1611-.0859,1.667-.2471l.2471,1.2715c-.6172,.2471-1.3701,.3711-2.1855,.3711-2.0488,0-3.2217-1.1855-3.2217-3.0742,0-1.5303,.9502-3.2217,3.0488-3.2217,1.9512,0,2.6914,1.5186,2.6914,3.0117,0,.3213-.0371,.6055-.0615,.7412h-3.8887Zm2.21-1.2842c0-.457-.1982-1.2217-1.0615-1.2217-.79,0-1.1113,.7158-1.1611,1.2217h2.2227Z" fill="#fff" />
                                    <path d="M402.5586,270.5403c0-.8887-.0244-1.4688-.0488-1.9873h1.6162l.0625,1.1113h.0488c.3086-.877,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0244v1.7656c-.1357-.0244-.2959-.0498-.5059-.0498-.6914,0-1.1602,.3701-1.2842,.9512-.0244,.123-.0371,.2715-.0371,.4189v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M407.2364,272.9476c.3457,.21,1.0625,.457,1.6172,.457,.5684,0,.8027-.1973,.8027-.5068,0-.3086-.1855-.4561-.8887-.6904-1.2471-.4199-1.7285-1.0986-1.7158-1.8154,0-1.123,.9629-1.9746,2.4561-1.9746,.7041,0,1.334,.1602,1.7041,.3457l-.334,1.2959c-.2715-.1484-.79-.3457-1.3076-.3457-.457,0-.7168,.1855-.7168,.4941,0,.2842,.2354,.4316,.9756,.6914,1.1484,.3945,1.6299,.9746,1.6416,1.8633,0,1.124-.8887,1.9512-2.6172,1.9512-.79,0-1.4932-.1729-1.9502-.4199l.333-1.3457Z" fill="#fff" />
                                    <path d="M412.6055,265.8245h1.876v3.4443h.0254c.1973-.2588,.4443-.457,.7402-.6172,.2715-.1484,.6299-.2344,.9756-.2344,1.1973,0,2.1104,.8271,2.1104,2.6416v3.5303h-1.876v-3.3203c0-.79-.2715-1.333-.9756-1.333-.4932,0-.8018,.3203-.9375,.6543-.0498,.123-.0625,.2832-.0625,.4189v3.5801h-1.876v-8.7646Z" fill="#fff" />
                                    <path d="M374.0928,280.1917h-2.2344v-1.5801h6.3936v1.5801h-2.2705v6.7402h-1.8887v-6.7402Z" fill="#fff" />
                                    <path d="M378.5225,282.8831c0-.8887-.0244-1.4697-.0488-1.9883h1.6162l.0625,1.1113h.0488c.3086-.876,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0254v1.7646c-.1357-.0244-.2959-.0488-.5059-.0488-.6914,0-1.1602,.3701-1.2842,.9502-.0244,.124-.0371,.2715-.0371,.4199v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M385.1514,279.2161c0,.5186-.3955,.9385-1.0127,.9385-.5928,0-.9873-.4199-.9746-.9385-.0127-.543,.3818-.9502,.9873-.9502,.6045,0,.9873,.4072,1,.9502Zm-1.9385,7.7158v-6.0371h1.876v6.0371h-1.876Z" fill="#fff" />
                                    <path d="M390.0264,286.9319l-.1113-.6055h-.0371c-.3945,.4814-1.0117,.7412-1.7275,.7412-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5684,1.4072-2.3213,3.542-2.3086v-.0869c0-.3203-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.21-1.666,.457l-.3457-1.21c.4199-.2344,1.2471-.5312,2.3457-.5312,2.0117,0,2.6533,1.1855,2.6533,2.6055v2.0986c0,.5801,.0254,1.1348,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0127-1.7529,.2217-1.7529,.9502,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3203v-.6543Z" fill="#fff" />
                                    <path d="M393.1114,282.8206c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3818,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8154,2.1602,2.5928v3.5801h-1.876v-3.3457c0-.7773-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3584-.9512,.7041-.0488,.1113-.0742,.2959-.0742,.4688v3.4814h-1.876v-4.1113Z" fill="#fff" />
                                    <path d="M406.1084,280.8948c-.0244,.3828-.0488,.8896-.0488,1.79v3.3701c0,1.1602-.2344,2.1113-.9141,2.7158-.666,.5684-1.5674,.7412-2.4561,.7412-.79,0-1.6299-.1611-2.1729-.4697l.3701-1.4189c.3828,.2217,1.0498,.4561,1.7529,.4561,.8887,0,1.5684-.4814,1.5684-1.5918v-.3955h-.0254c-.3574,.5059-.9375,.79-1.6289,.79-1.4941,0-2.5557-1.21-2.5557-2.9502,0-1.9375,1.2588-3.1729,2.7402-3.1729,.8271,0,1.3457,.3584,1.6543,.8525h.0244l.0625-.7168h1.6289Zm-1.9258,2.457c0-.123-.0117-.2471-.0371-.3457-.1357-.4941-.4932-.8271-1.0117-.8271-.6787,0-1.2344,.6172-1.2344,1.7158,0,.9014,.4443,1.6045,1.2344,1.6045,.4814,0,.8643-.3203,.9873-.7646,.0498-.1357,.0615-.334,.0615-.4941v-.8887Z" fill="#fff" />
                                    <path d="M407.5635,278.1673h1.876v8.7646h-1.876v-8.7646Z" fill="#fff" />
                                    <path d="M412.4014,284.512c.0615,.7783,.8271,1.1484,1.7031,1.1484,.6426,0,1.1611-.0869,1.667-.2471l.2471,1.2715c-.6172,.2471-1.3701,.3701-2.1855,.3701-2.0488,0-3.2217-1.1846-3.2217-3.0732,0-1.5312,.9502-3.2227,3.0488-3.2227,1.9512,0,2.6914,1.5186,2.6914,3.0127,0,.3203-.0371,.6045-.0615,.7402h-3.8887Zm2.21-1.2832c0-.457-.1982-1.2227-1.0615-1.2227-.79,0-1.1113,.7158-1.1611,1.2227h2.2227Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M545.9707,1403.2767h5.0859v1.543h-3.1973v1.9004h2.9873v1.5312h-2.9873v3.3457h-1.8887v-8.3203Z" fill="#fff" />
                                    <path d="M555.3643,1411.597l-.1113-.6055h-.0371c-.3945,.4814-1.0117,.7402-1.7275,.7402-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5674,1.4072-2.3203,3.542-2.3086v-.0859c0-.3203-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.209-1.666,.457l-.3457-1.2109c.4199-.2344,1.2471-.5303,2.3457-.5303,2.0117,0,2.6533,1.1846,2.6533,2.6045v2.0996c0,.5801,.0254,1.1348,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0127-1.7529,.2217-1.7529,.9502,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3203v-.6543Z" fill="#fff" />
                                    <path d="M558.4493,1407.5482c0-.8896-.0244-1.4697-.0488-1.9883h1.6162l.0625,1.1113h.0488c.3086-.877,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0244v1.7656c-.1357-.0244-.2959-.0488-.5059-.0488-.6914,0-1.1602,.3691-1.2842,.9502-.0244,.123-.0371,.2715-.0371,.4189v3.0625h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M563.1387,1407.4857c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3818,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8154,2.1602,2.5928v3.5801h-1.876v-3.3457c0-.7773-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3574-.9512,.7031-.0488,.1113-.0742,.2969-.0742,.4697v3.4814h-1.876v-4.1113Z" fill="#fff" />
                                    <path d="M570.3223,1411.597c.0244-.3955,.0488-1.124,.0488-1.8027v-6.9629h1.876v3.4453h.0254c.3574-.5195,.9873-.8525,1.8271-.8525,1.4443,0,2.5059,1.1982,2.4932,3.0498,0,2.1719-1.3828,3.2578-2.7656,3.2578-.7031,0-1.3818-.2588-1.8145-.9746h-.0244l-.0742,.8398h-1.5918Zm1.9248-2.5684c0,.123,.0127,.2344,.0371,.334,.124,.5059,.5557,.8887,1.0986,.8887,.8027,0,1.2969-.6172,1.2969-1.7031,0-.9395-.4199-1.6797-1.2969-1.6797-.5059,0-.9746,.3828-1.0986,.9258-.0244,.1113-.0371,.2227-.0371,.3457v.8887Z" fill="#fff" />
                                    <path d="M583.751,1408.511c0,2.209-1.5684,3.2207-3.1855,3.2207-1.7646,0-3.123-1.1602-3.123-3.1104s1.2842-3.1973,3.2217-3.1973c1.8516,0,3.0869,1.2715,3.0869,3.0869Zm-4.3701,.0605c0,1.0371,.4316,1.8145,1.2344,1.8145,.7285,0,1.1973-.7275,1.1973-1.8145,0-.9004-.3457-1.8145-1.1973-1.8145-.9014,0-1.2344,.9258-1.2344,1.8145Z" fill="#fff" />
                                    <path d="M584.9102,1407.5482c0-.8896-.0244-1.4697-.0488-1.9883h1.6162l.0625,1.1113h.0488c.3086-.877,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0244v1.7656c-.1357-.0244-.2959-.0488-.5059-.0488-.6914,0-1.1602,.3691-1.2842,.9502-.0244,.123-.0371,.2715-.0371,.4189v3.0625h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M595.4639,1408.511c0,2.209-1.5684,3.2207-3.1855,3.2207-1.7646,0-3.123-1.1602-3.123-3.1104s1.2842-3.1973,3.2217-3.1973c1.8516,0,3.0869,1.2715,3.0869,3.0869Zm-4.3701,.0605c0,1.0371,.4316,1.8145,1.2344,1.8145,.7285,0,1.1973-.7275,1.1973-1.8145,0-.9004-.3457-1.8145-1.1973-1.8145-.9014,0-1.2344,.9258-1.2344,1.8145Z" fill="#fff" />
                                    <path d="M602.3125,1409.6585c0,.79,.0254,1.4316,.0498,1.9385h-1.6299l-.0859-.8516h-.0371c-.2344,.3691-.8027,.9863-1.8887,.9863-1.2217,0-2.123-.7646-2.123-2.6289v-3.543h1.8887v3.2461c0,.877,.2842,1.4082,.9385,1.4082,.5176,0,.8145-.3584,.9375-.6543,.0498-.1113,.0615-.2598,.0615-.4082v-3.5918h1.8887v4.0986Z" fill="#fff" />
                                    <path d="M609.5821,1405.5599c-.0244,.3828-.0488,.8887-.0488,1.79v3.3701c0,1.1602-.2344,2.1113-.9141,2.7158-.666,.5674-1.5674,.7412-2.4561,.7412-.79,0-1.6299-.1611-2.1729-.4697l.3701-1.4189c.3828,.2217,1.0498,.4561,1.7529,.4561,.8887,0,1.5684-.4814,1.5684-1.5928v-.3945h-.0254c-.3574,.5059-.9375,.79-1.6289,.79-1.4941,0-2.5557-1.21-2.5557-2.9502,0-1.9385,1.2588-3.1729,2.7402-3.1729,.8271,0,1.3457,.3584,1.6543,.8525h.0244l.0625-.7168h1.6289Zm-1.9258,2.457c0-.123-.0117-.2471-.0371-.3457-.1357-.4941-.4932-.8271-1.0117-.8271-.6787,0-1.2344,.6172-1.2344,1.7158,0,.9004,.4443,1.6045,1.2344,1.6045,.4814,0,.8643-.3213,.9873-.7646,.0498-.1367,.0615-.334,.0615-.4941v-.8887Z" fill="#fff" />
                                    <path d="M611.0381,1402.8314h1.876v3.4453h.0254c.1973-.2598,.4443-.457,.7402-.6172,.2715-.1484,.6299-.2354,.9756-.2354,1.1973,0,2.1104,.8271,2.1104,2.6416v3.5312h-1.876v-3.3213c0-.79-.2715-1.333-.9756-1.333-.4932,0-.8018,.3203-.9375,.6543-.0498,.123-.0625,.2832-.0625,.4199v3.5801h-1.876v-8.7656Z" fill="#fff" />
                                    <path d="M567.2422,1423.9388v-8.3203h2.1973l1.7285,3.0488c.4941,.877,.9873,1.9141,1.3574,2.8516h.0371c-.123-1.0977-.1602-2.2217-.1602-3.4688v-2.4316h1.7285v8.3203h-1.9756l-1.7773-3.209c-.4941-.8887-1.0371-1.9629-1.4443-2.9385l-.0371,.0127c.0498,1.0977,.0742,2.2715,.0742,3.6289v2.5059h-1.7285Z" fill="#fff" />
                                    <path d="M581.6221,1420.8528c0,2.209-1.5684,3.2217-3.1855,3.2217-1.7646,0-3.123-1.1602-3.123-3.1104,0-1.9512,1.2842-3.1973,3.2217-3.1973,1.8516,0,3.0869,1.2715,3.0869,3.0859Zm-4.3701,.0615c0,1.0371,.4316,1.8145,1.2344,1.8145,.7285,0,1.1973-.7275,1.1973-1.8145,0-.9014-.3457-1.8145-1.1973-1.8145-.9014,0-1.2344,.9258-1.2344,1.8145Z" fill="#fff" />
                                    <path d="M582.7813,1419.89c0-.8887-.0244-1.4688-.0488-1.9883h1.6162l.0625,1.1113h.0488c.3086-.876,1.0498-1.2461,1.6299-1.2461,.1729,0,.2588,0,.3945,.0244v1.7646c-.1357-.0234-.2959-.0488-.5059-.0488-.6914,0-1.1602,.3701-1.2842,.9512-.0244,.123-.0371,.2715-.0371,.4189v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M589.8282,1416.1741v1.7275h1.3457v1.3828h-1.3457v2.1855c0,.7285,.1729,1.0615,.7402,1.0615,.2354,0,.4199-.0244,.5557-.0498l.0127,1.4199c-.2471,.0986-.6914,.1602-1.2227,.1602-.6045,0-1.1104-.209-1.4072-.5176-.3457-.3584-.5176-.9385-.5176-1.791v-2.4688h-.8027v-1.3828h.8027v-1.3076l1.8389-.4199Z" fill="#fff" />
                                    <path d="M592.2598,1415.1741h1.876v3.4443h.0254c.1973-.2598,.4443-.457,.7402-.6172,.2715-.1484,.6299-.2344,.9756-.2344,1.1973,0,2.1104,.8262,2.1104,2.6416v3.5303h-1.876v-3.3203c0-.791-.2715-1.334-.9756-1.334-.4932,0-.8018,.3213-.9375,.6543-.0498,.124-.0625,.2842-.0625,.4199v3.5801h-1.876v-8.7646Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M1054.3067,782.1556c.4941-.0986,1.4941-.1729,2.4316-.1729,1.1484,0,1.8525,.1113,2.457,.4688,.5801,.3086,1,.877,1,1.6299,0,.7402-.4326,1.4316-1.3701,1.7773v.0244c.9502,.2598,1.6543,.9756,1.6543,2.0498,0,.7529-.3457,1.3447-.8643,1.7646-.6055,.4814-1.6172,.7529-3.2715,.7529-.9258,0-1.6172-.0615-2.0371-.123v-8.1719Zm1.8643,3.1846h.6172c.9873,0,1.5186-.4072,1.5186-1.0244,0-.6299-.4814-.9629-1.333-.9629-.4072,0-.6426,.0244-.8027,.0488v1.9385Zm0,3.6914c.1855,.0244,.4072,.0244,.7285,.0244,.8516,0,1.6045-.3213,1.6045-1.1973,0-.8398-.7529-1.1729-1.6914-1.1729h-.6416v2.3457Z" fill="#fff" />
                                    <path d="M1061.6993,786.3157c0-.8896-.0244-1.4697-.0488-1.9883h1.6162l.0625,1.1113h.0488c.3086-.876,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0254v1.7646c-.1357-.0244-.2959-.0488-.5059-.0488-.6914,0-1.1602,.3701-1.2842,.9502-.0244,.123-.0371,.2715-.0371,.4199v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M1069.6856,790.3646l-.1113-.6055h-.0371c-.3945,.4814-1.0117,.7412-1.7275,.7412-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5684,1.4072-2.3213,3.542-2.3086v-.0869c0-.3203-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.21-1.666,.457l-.3457-1.21c.4199-.2344,1.2471-.5312,2.3457-.5312,2.0117,0,2.6533,1.1855,2.6533,2.6055v2.0986c0,.5801,.0254,1.1348,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0127-1.7529,.2217-1.7529,.9502,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3203v-.6543Z" fill="#fff" />
                                    <path d="M1077.3008,790.2161c-.333,.1484-.9629,.2715-1.6787,.2715-1.9502,0-3.1973-1.1846-3.1973-3.0859,0-1.7656,1.21-3.21,3.457-3.21,.4932,0,1.0361,.0869,1.4316,.2354l-.2969,1.3945c-.2217-.0986-.5547-.1855-1.0488-.1855-.9873,0-1.6299,.7041-1.6172,1.6914,0,1.1113,.7412,1.6914,1.6543,1.6914,.4443,0,.79-.0742,1.0742-.1855l.2217,1.3828Z" fill="#fff" />
                                    <path d="M1080.2129,786.8704h.0254c.1357-.2461,.2832-.4932,.4316-.7275l1.2227-1.8154h2.2588l-2.1602,2.4443,2.4688,3.5928h-2.3086l-1.457-2.4814-.4814,.5928v1.8887h-1.876v-8.7646h1.876v5.2705Z" fill="#fff" />
                                    <path d="M1085.1494,786.2532c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3818,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8154,2.1602,2.5928v3.5801h-1.876v-3.3457c0-.7773-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3584-.9512,.7041-.0488,.1113-.0742,.2959-.0742,.4688v3.4814h-1.876v-4.1113Z" fill="#fff" />
                                    <path d="M1093.8262,787.9446c.0615,.7783,.8271,1.1484,1.7031,1.1484,.6426,0,1.1611-.0869,1.667-.2471l.2471,1.2715c-.6172,.2471-1.3701,.3701-2.1855,.3701-2.0488,0-3.2217-1.1846-3.2217-3.0732,0-1.5312,.9502-3.2227,3.0488-3.2227,1.9512,0,2.6914,1.5186,2.6914,3.0127,0,.3203-.0371,.6045-.0615,.7402h-3.8887Zm2.21-1.2832c0-.457-.1982-1.2227-1.0615-1.2227-.79,0-1.1113,.7158-1.1611,1.2227h2.2227Z" fill="#fff" />
                                    <path d="M1098.8985,781.5999h1.876v8.7646h-1.876v-8.7646Z" fill="#fff" />
                                    <path d="M1102.292,781.5999h1.876v8.7646h-1.876v-8.7646Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M556.7149,968.1771c-.3457,.1738-1.123,.3584-2.1348,.3584-2.877,0-4.3584-1.79-4.3584-4.1602,0-2.8389,2.0244-4.4189,4.543-4.4189,.9756,0,1.7158,.1973,2.0498,.3701l-.3828,1.4932c-.3828-.1602-.9141-.3086-1.5801-.3086-1.4941,0-2.6543,.9014-2.6543,2.7529,0,1.667,.9873,2.7158,2.666,2.7158,.5684,0,1.1973-.123,1.5684-.2715l.2832,1.4688Z" fill="#fff" />
                                    <path d="M557.8369,964.3626c0-.8887-.0244-1.4688-.0488-1.9873h1.6162l.0625,1.1113h.0488c.3086-.876,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0254v1.7646c-.1357-.0244-.2959-.0498-.5059-.0498-.6914,0-1.1602,.3711-1.2842,.9512-.0244,.123-.0371,.2715-.0371,.4199v3.0605h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M568.3897,965.3255c0,2.21-1.5674,3.2227-3.1846,3.2227-1.7646,0-3.123-1.1602-3.123-3.1113,0-1.9502,1.2842-3.1973,3.2217-3.1973,1.8516,0,3.0859,1.2715,3.0859,3.0859Zm-4.3691,.0625c0,1.0371,.4316,1.8145,1.2344,1.8145,.7285,0,1.1973-.7285,1.1973-1.8145,0-.9014-.3457-1.8145-1.1973-1.8145-.9014,0-1.2344,.9258-1.2344,1.8145Z" fill="#fff" />
                                    <path d="M570.7344,962.3753l.4814,2.4697c.124,.6289,.2471,1.2949,.3457,1.9863h.0254c.123-.6914,.2959-1.3818,.4443-1.9746l.6416-2.4814h1.4814l.6045,2.4072c.1611,.6797,.3213,1.3574,.4443,2.0488h.0254c.0859-.6914,.209-1.3691,.3447-2.0605l.5186-2.3955h1.8398l-1.8516,6.0361h-1.7656l-.5674-2.123c-.1484-.5918-.2598-1.1348-.3828-1.876h-.0244c-.1113,.7529-.2354,1.3086-.3955,1.876l-.6045,2.123h-1.7656l-1.7529-6.0361h1.9131Z" fill="#fff" />
                                    <path d="M580.9043,960.6478v1.7275h1.3457v1.3828h-1.3457v2.1846c0,.7285,.1729,1.0625,.7402,1.0625,.2354,0,.4199-.0254,.5557-.0498l.0127,1.4189c-.2471,.0996-.6914,.1611-1.2227,.1611-.6045,0-1.1104-.21-1.4072-.5186-.3457-.3574-.5176-.9375-.5176-1.79v-2.4688h-.8027v-1.3828h.8027v-1.3076l1.8389-.4199Z" fill="#fff" />
                                    <path d="M583.336,959.6478h1.876v3.4434h.0254c.1973-.2588,.4443-.457,.7402-.6172,.2715-.1484,.6299-.2344,.9756-.2344,1.1973,0,2.1104,.8271,2.1104,2.6426v3.5293h-1.876v-3.3203c0-.79-.2715-1.333-.9756-1.333-.4932,0-.8018,.3213-.9375,.6543-.0498,.124-.0625,.2842-.0625,.4189v3.5801h-1.876v-8.7637Z" fill="#fff" />
                                    <path d="M596.5303,965.3255c0,2.21-1.5674,3.2227-3.1846,3.2227-1.7646,0-3.123-1.1602-3.123-3.1113,0-1.9502,1.2842-3.1973,3.2217-3.1973,1.8516,0,3.0859,1.2715,3.0859,3.0859Zm-4.3691,.0625c0,1.0371,.4316,1.8145,1.2344,1.8145,.7285,0,1.1973-.7285,1.1973-1.8145,0-.9014-.3457-1.8145-1.1973-1.8145-.9014,0-1.2344,.9258-1.2344,1.8145Z" fill="#fff" />
                                    <path d="M597.6895,964.3626c0-.8887-.0244-1.4688-.0488-1.9873h1.6162l.0625,1.1113h.0488c.3086-.876,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0254v1.7646c-.1357-.0244-.2959-.0498-.5059-.0498-.6914,0-1.1602,.3711-1.2842,.9512-.0244,.123-.0371,.2715-.0371,.4199v3.0605h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M602.3789,964.3011c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3828,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8145,2.1602,2.5918v3.5801h-1.876v-3.3447c0-.7783-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3584-.9512,.7041-.0488,.1113-.0742,.2959-.0742,.4688v3.4805h-1.876v-4.1104Z" fill="#fff" />
                                    <path d="M611.0557,965.9925c.0615,.7783,.8271,1.1475,1.7031,1.1475,.6426,0,1.1611-.0859,1.667-.2461l.2471,1.2715c-.6172,.2461-1.3701,.3701-2.1855,.3701-2.0488,0-3.2217-1.1846-3.2217-3.0732,0-1.5312,.9502-3.2227,3.0488-3.2227,1.9512,0,2.6914,1.5186,2.6914,3.0117,0,.3213-.0371,.6055-.0615,.7412h-3.8887Zm2.21-1.2842c0-.457-.1982-1.2217-1.0615-1.2217-.79,0-1.1113,.7158-1.1611,1.2217h2.2227Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M555.0235,1132.8089c.5068,.2588,1.2842,.5186,2.0869,.5186,.8643,0,1.3203-.3584,1.3203-.9014,0-.5186-.3945-.8145-1.3945-1.1729-1.3828-.4814-2.2842-1.2471-2.2842-2.457,0-1.4189,1.1855-2.5059,3.1484-2.5059,.9375,0,1.6289,.1982,2.123,.4199l-.4199,1.5186c-.333-.1602-.9258-.3945-1.7402-.3945s-1.21,.3701-1.21,.8018c0,.5312,.4688,.7656,1.543,1.1729,1.4688,.543,2.1602,1.3086,2.1602,2.4814,0,1.3945-1.0742,2.5801-3.3574,2.5801-.9502,0-1.8887-.2471-2.3574-.5068l.3818-1.5547Z" fill="#fff" />
                                    <path d="M565.0957,1134.7464l-.1113-.6045h-.0371c-.3945,.4814-1.0117,.7402-1.7275,.7402-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5674,1.4072-2.3203,3.542-2.3086v-.0859c0-.3213-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.209-1.666,.4561l-.3457-1.21c.4199-.2344,1.2471-.5303,2.3457-.5303,2.0117,0,2.6533,1.1846,2.6533,2.6045v2.0986c0,.5801,.0254,1.1357,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0117-1.7529,.2227-1.7529,.9512,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3213v-.6543Z" fill="#fff" />
                                    <path d="M568.1807,1130.636c0-.7529-.0244-1.3945-.0488-1.9258h1.6289l.0869,.8271h.0361c.2471-.3828,.8643-.9629,1.8643-.9629,1.2344,0,2.1602,.8145,2.1602,2.5918v3.5801h-1.876v-3.3447c0-.7783-.2715-1.3086-.9502-1.3086-.5186,0-.8271,.3574-.9512,.7031-.0488,.1113-.0742,.2969-.0742,.4697v3.4805h-1.876v-4.1104Z" fill="#fff" />
                                    <path d="M581.2647,1125.9818v6.9629c0,.6787,.0244,1.3945,.0488,1.8018h-1.666l-.0869-.8887h-.0244c-.3828,.6787-1.1113,1.0244-1.8887,1.0244-1.4316,0-2.5801-1.2217-2.5801-3.0977-.0117-2.0371,1.2588-3.21,2.7031-3.21,.7412,0,1.3213,.2588,1.5928,.6787h.0244v-3.2715h1.877Zm-1.877,5.2842c0-.0996-.0117-.2354-.0244-.334-.1113-.5059-.5186-.9258-1.0986-.9258-.8516,0-1.2959,.7656-1.2959,1.7158,0,1.0244,.5059,1.667,1.2832,1.667,.5439,0,.9756-.3701,1.0869-.9014,.0371-.1357,.0488-.2715,.0488-.4316v-.79Z" fill="#fff" />
                                    <path d="M582.7686,1125.9818h1.876v3.4443h.0254c.1973-.2588,.4443-.457,.7402-.6172,.2715-.1484,.6299-.2344,.9756-.2344,1.1973,0,2.1104,.8271,2.1104,2.6416v3.5303h-1.876v-3.3203c0-.79-.2715-1.333-.9756-1.333-.4932,0-.8018,.3203-.9375,.6543-.0498,.123-.0625,.2832-.0625,.4189v3.5801h-1.876v-8.7646Z" fill="#fff" />
                                    <path d="M595.6914,1132.8089c0,.79,.0254,1.4316,.0498,1.9375h-1.6299l-.0859-.8516h-.0371c-.2344,.3701-.8027,.9873-1.8887,.9873-1.2217,0-2.123-.7646-2.123-2.6289v-3.543h1.8887v3.2461c0,.877,.2842,1.4082,.9385,1.4082,.5176,0,.8145-.3584,.9375-.6543,.0498-.1113,.0615-.2598,.0615-.4082v-3.5918h1.8887v4.0986Z" fill="#fff" />
                                    <path d="M597.1963,1130.6976c0-.8887-.0244-1.4688-.0488-1.9873h1.6162l.0625,1.1113h.0488c.3086-.877,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0244v1.7656c-.1357-.0244-.2959-.0498-.5059-.0498-.6914,0-1.1602,.3701-1.2842,.9512-.0244,.123-.0371,.2715-.0371,.4189v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                    <path d="M601.8731,1133.1048c.3457,.21,1.0625,.457,1.6172,.457,.5684,0,.8027-.1973,.8027-.5068,0-.3086-.1855-.4561-.8887-.6904-1.2471-.4199-1.7285-1.0986-1.7158-1.8154,0-1.123,.9629-1.9746,2.4561-1.9746,.7041,0,1.333,.1602,1.7041,.3457l-.334,1.2959c-.2715-.1484-.79-.3457-1.3076-.3457-.457,0-.7168,.1855-.7168,.4941,0,.2842,.2354,.4316,.9756,.6914,1.1484,.3945,1.6299,.9746,1.6416,1.8633,0,1.124-.8887,1.9512-2.6172,1.9512-.79,0-1.4932-.1729-1.9502-.4199l.333-1.3457Z" fill="#fff" />
                                    <path d="M609.3409,1126.9818v1.7285h1.3457v1.3828h-1.3457v2.1846c0,.7285,.1729,1.0615,.7402,1.0615,.2354,0,.4199-.0244,.5557-.0488l.0127,1.4189c-.2471,.0986-.6914,.1611-1.2227,.1611-.6045,0-1.1104-.21-1.4072-.5186-.3447-.3584-.5176-.9385-.5176-1.79v-2.4688h-.8027v-1.3828h.8027v-1.3086l1.8389-.4199Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M552.8819,1289.8714c.4941-.0986,1.4941-.1729,2.4316-.1729,1.1484,0,1.8525,.1113,2.457,.4697,.5801,.3086,1,.876,1,1.6289,0,.7412-.4326,1.4316-1.3701,1.7773v.0254c.9502,.2588,1.6543,.9746,1.6543,2.0488,0,.7529-.3457,1.3457-.8643,1.7656-.6055,.4814-1.6172,.7529-3.2715,.7529-.9258,0-1.6172-.0615-2.0371-.124v-8.1719Zm1.8643,3.1855h.6172c.9873,0,1.5186-.4082,1.5186-1.0254,0-.6289-.4814-.9629-1.333-.9629-.4072,0-.6426,.0254-.8027,.0498v1.9385Zm0,3.6904c.1855,.0244,.4072,.0244,.7285,.0244,.8516,0,1.6045-.3203,1.6045-1.1973,0-.8389-.7529-1.1729-1.6914-1.1729h-.6416v2.3457Z" fill="#fff" />
                                    <path d="M560.2744,1289.3157h1.876v8.7646h-1.876v-8.7646Z" fill="#fff" />
                                    <path d="M567.1006,1298.0804l-.1113-.6045h-.0371c-.3945,.4814-1.0117,.7402-1.7275,.7402-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5674,1.4072-2.3203,3.542-2.3086v-.0859c0-.3213-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.209-1.666,.4561l-.3457-1.21c.4199-.2344,1.2471-.5303,2.3457-.5303,2.0117,0,2.6533,1.1846,2.6533,2.6045v2.0986c0,.5801,.0254,1.1357,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0117-1.7529,.2227-1.7529,.9512,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3213v-.6543Z" fill="#fff" />
                                    <path d="M574.7159,1297.9329c-.333,.1475-.9629,.2715-1.6787,.2715-1.9502,0-3.1973-1.1855-3.1973-3.0859,0-1.7656,1.21-3.21,3.457-3.21,.4932,0,1.0361,.0859,1.4316,.2344l-.2969,1.3955c-.2217-.0996-.5547-.1855-1.0488-.1855-.9873,0-1.6299,.7031-1.6172,1.6914,0,1.1104,.7412,1.6904,1.6543,1.6904,.4443,0,.79-.0732,1.0742-.1846l.2217,1.3828Z" fill="#fff" />
                                    <path d="M577.628,1294.5872h.0254c.1357-.2471,.2832-.4941,.4316-.7285l1.2227-1.8145h2.2588l-2.1602,2.4443,2.4688,3.5918h-2.3086l-1.457-2.4814-.4814,.5928v1.8887h-1.876v-8.7646h1.876v5.2715Z" fill="#fff" />
                                    <path d="M583.7735,1292.0443l.4814,2.4688c.124,.6299,.2471,1.2959,.3457,1.9873h.0254c.123-.6914,.2959-1.3818,.4443-1.9746l.6416-2.4814h1.4814l.6045,2.4072c.1611,.6787,.3213,1.3574,.4443,2.0488h.0254c.0859-.6914,.209-1.3701,.3447-2.0615l.5186-2.3945h1.8398l-1.8516,6.0361h-1.7656l-.5674-2.123c-.1484-.5928-.2598-1.1357-.3828-1.876h-.0244c-.1113,.7529-.2354,1.3086-.3955,1.876l-.6045,2.123h-1.7656l-1.7529-6.0361h1.9131Z" fill="#fff" />
                                    <path d="M595.1905,1298.0804l-.1113-.6045h-.0371c-.3945,.4814-1.0117,.7402-1.7275,.7402-1.2227,0-1.9502-.8887-1.9502-1.8516,0-1.5674,1.4072-2.3203,3.542-2.3086v-.0859c0-.3213-.1729-.7773-1.0986-.7773-.6172,0-1.2715,.209-1.666,.4561l-.3457-1.21c.4199-.2344,1.2471-.5303,2.3457-.5303,2.0117,0,2.6533,1.1846,2.6533,2.6045v2.0986c0,.5801,.0254,1.1357,.0869,1.4688h-1.6914Zm-.2217-2.8516c-.9883-.0117-1.7529,.2227-1.7529,.9512,0,.4814,.3203,.7158,.7402,.7158,.4688,0,.8516-.3086,.9756-.6914,.0244-.0986,.0371-.21,.0371-.3213v-.6543Z" fill="#fff" />
                                    <path d="M600.2754,1290.3157v1.7285h1.3457v1.3828h-1.3457v2.1846c0,.7285,.1729,1.0615,.7402,1.0615,.2354,0,.4199-.0244,.5557-.0488l.0127,1.4189c-.2471,.0986-.6914,.1611-1.2227,.1611-.6045,0-1.1104-.21-1.4072-.5186-.3457-.3584-.5176-.9385-.5176-1.79v-2.4688h-.8027v-1.3828h.8027v-1.3086l1.8389-.4199Z" fill="#fff" />
                                    <path d="M604.0889,1295.6614c.0615,.7773,.8271,1.1475,1.7031,1.1475,.6426,0,1.1611-.0859,1.667-.2471l.2471,1.2715c-.6172,.2471-1.3701,.3711-2.1855,.3711-2.0488,0-3.2217-1.1855-3.2217-3.0742,0-1.5303,.9502-3.2217,3.0488-3.2217,1.9512,0,2.6914,1.5186,2.6914,3.0117,0,.3213-.0371,.6055-.0615,.7412h-3.8887Zm2.21-1.2842c0-.457-.1982-1.2217-1.0615-1.2217-.79,0-1.1113,.7158-1.1611,1.2217h2.2227Z" fill="#fff" />
                                    <path d="M609.1612,1294.0316c0-.8887-.0244-1.4688-.0488-1.9873h1.6162l.0625,1.1113h.0488c.3086-.877,1.0498-1.2471,1.6299-1.2471,.1729,0,.2588,0,.3945,.0244v1.7656c-.1357-.0244-.2959-.0498-.5059-.0498-.6914,0-1.1602,.3701-1.2842,.9512-.0244,.123-.0371,.2715-.0371,.4189v3.0615h-1.876v-4.0488Z" fill="#fff" />
                                </g>
                            </g>
                            <g id="station-mileage">
                                <g>
                                    <path d="M239.1368,130.5706c-.1484-.0088-.3379,0-.543,.0322-1.1357,.1895-1.7354,1.0205-1.8594,1.9004h.0254c.2549-.3369,.6982-.6172,1.291-.6172,.9453,0,1.6113,.6826,1.6113,1.7275,0,.9785-.666,1.8828-1.7764,1.8828-1.1426,0-1.8916-.8877-1.8916-2.2773,0-1.0527,.3789-1.8838,.9053-2.4102,.4443-.4355,1.0361-.707,1.71-.79,.2139-.0322,.3955-.041,.5273-.041v.5928Zm-.2061,3.084c0-.7646-.4355-1.2256-1.1025-1.2256-.4355,0-.8389,.2715-1.0361,.6582-.0488,.082-.082,.1885-.082,.3203,.0166,.8799,.4199,1.5303,1.1758,1.5303,.625,0,1.0449-.5186,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M243.3575,130.5706c-.1484-.0088-.3379,0-.543,.0322-1.1357,.1895-1.7354,1.0205-1.8594,1.9004h.0254c.2549-.3369,.6982-.6172,1.291-.6172,.9453,0,1.6113,.6826,1.6113,1.7275,0,.9785-.666,1.8828-1.7764,1.8828-1.1426,0-1.8916-.8877-1.8916-2.2773,0-1.0527,.3789-1.8838,.9053-2.4102,.4443-.4355,1.0361-.707,1.71-.79,.2139-.0322,.3955-.041,.5273-.041v.5928Zm-.2061,3.084c0-.7646-.4355-1.2256-1.1025-1.2256-.4355,0-.8389,.2715-1.0361,.6582-.0488,.082-.082,.1885-.082,.3203,.0166,.8799,.4199,1.5303,1.1758,1.5303,.625,0,1.0449-.5186,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M244.7569,132.5032c0-.4111-.0088-.749-.0332-1.0771h.6338l.0322,.6406h.0254c.2217-.3779,.5918-.7314,1.25-.7314,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2227,.2803-.3945,.4443-.5186,.2383-.1807,.502-.2793,.8799-.2793,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2539c0-.7646-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3047-.8555,.6582-.0332,.0986-.0576,.2305-.0576,.3623v2.459h-.707v-2.3857c0-.6328-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3623-.8965,.7236-.041,.1074-.0576,.2305-.0576,.3545v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M254.7041,130.7347h-.0166l-.9297,.502-.1396-.5508,1.168-.625h.6162v5.3457h-.6982v-4.6719Z" fill="#fff" />
                                    <path d="M260.4219,135.2581c-.1885,.0986-.6084,.2305-1.1426,.2305-1.2012,0-1.9824-.8145-1.9824-2.0312,0-1.2256,.8389-2.1143,2.1387-2.1143,.4277,0,.8057,.1074,1.0029,.2061l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6738-1.4062,1.5049,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1064,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M261.2705,129.5667h.7236v2.4844h.0166c.1152-.2061,.2959-.3867,.5176-.5107,.2139-.123,.4688-.2051,.7402-.2051,.5352,0,1.3906,.3291,1.3906,1.7021v2.3691h-.7246v-2.2871c0-.6406-.2383-1.1836-.9209-1.1836-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2051-.0488,.3457v2.4014h-.7236v-5.8398Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M514.1192,444.8597c-.1475-.0088-.3369,0-.542,.0322-1.1357,.1895-1.7354,1.0205-1.8594,1.9004h.0254c.2549-.3369,.6982-.6172,1.291-.6172,.9453,0,1.6123,.6826,1.6123,1.7275,0,.9785-.667,1.8828-1.7773,1.8828-1.1426,0-1.8916-.8877-1.8916-2.2773,0-1.0527,.3789-1.8838,.9053-2.4102,.4443-.4355,1.0361-.707,1.7109-.79,.2129-.0322,.3945-.041,.5254-.041v.5928Zm-.2051,3.084c0-.7646-.4355-1.2256-1.1025-1.2256-.4355,0-.8389,.2715-1.0361,.6582-.0488,.082-.082,.1885-.082,.3203,.0166,.8799,.4199,1.5303,1.1758,1.5303,.625,0,1.0449-.5186,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M517.5264,449.6956v-1.4561h-2.4844v-.4766l2.3857-3.4131h.7812v3.3223h.748v.5674h-.748v1.4561h-.6826Zm0-2.0234v-1.7852c0-.2793,.0078-.5586,.0244-.8389h-.0244c-.165,.3125-.2959,.543-.4443,.79l-1.3076,1.8174v.0166h1.752Z" fill="#fff" />
                                    <path d="M519.7403,446.7923c0-.4111-.0088-.749-.0332-1.0771h.6338l.0322,.6406h.0254c.2217-.3779,.5918-.7314,1.25-.7314,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2227,.2803-.3945,.4443-.5186,.2383-.1807,.502-.2793,.8799-.2793,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2539c0-.7646-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3047-.8555,.6582-.0332,.0986-.0576,.2305-.0576,.3623v2.459h-.707v-2.3857c0-.6328-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3623-.8965,.7236-.041,.1074-.0576,.2305-.0576,.3545v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M529.6875,445.0237h-.0166l-.9297,.502-.1396-.5508,1.168-.625h.6162v5.3457h-.6982v-4.6719Z" fill="#fff" />
                                    <path d="M535.8907,446.9652c0,1.8174-.6738,2.8203-1.8584,2.8203-1.0449,0-1.752-.9785-1.7686-2.7471,0-1.792,.7734-2.7793,1.8594-2.7793,1.126,0,1.7676,1.0029,1.7676,2.7061Zm-2.9033,.082c0,1.3896,.4277,2.1797,1.0859,2.1797,.7402,0,1.0938-.8643,1.0938-2.2295,0-1.3154-.3369-2.1787-1.0859-2.1787-.6328,0-1.0938,.7725-1.0938,2.2285Z" fill="#fff" />
                                    <path d="M539.626,449.5472c-.1885,.0986-.6084,.2305-1.1426,.2305-1.2012,0-1.9824-.8145-1.9824-2.0312,0-1.2256,.8389-2.1143,2.1387-2.1143,.4277,0,.8057,.1074,1.0029,.2061l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6738-1.4062,1.5049,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1064,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M540.4746,443.8558h.7236v2.4844h.0166c.1152-.2061,.2959-.3867,.5176-.5107,.2139-.123,.4688-.2051,.7402-.2051,.5352,0,1.3906,.3291,1.3906,1.7021v2.3691h-.7246v-2.2871c0-.6406-.2383-1.1836-.9209-1.1836-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2051-.0488,.3457v2.4014h-.7236v-5.8398Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M565.5655,706.7454c-.1484-.0088-.3379,0-.543,.0322-1.1357,.1895-1.7354,1.0205-1.8594,1.9004h.0254c.2549-.3369,.6982-.6172,1.291-.6172,.9453,0,1.6113,.6826,1.6113,1.7275,0,.9785-.666,1.8828-1.7764,1.8828-1.1426,0-1.8916-.8877-1.8916-2.2773,0-1.0527,.3789-1.8838,.9053-2.4102,.4443-.4355,1.0361-.707,1.71-.79,.2139-.0322,.3955-.041,.5273-.041v.5928Zm-.2061,3.084c0-.7646-.4355-1.2256-1.1025-1.2256-.4355,0-.8389,.2715-1.0361,.6582-.0488,.082-.082,.1885-.082,.3203,.0166,.8799,.4199,1.5303,1.1758,1.5303,.625,0,1.0449-.5186,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M566.7344,711.5814v-.4443l.5674-.5508c1.3652-1.2998,1.9824-1.9902,1.9902-2.7969,0-.543-.2627-1.0439-1.0605-1.0439-.4854,0-.8887,.2461-1.1348,.4521l-.2305-.5098c.3701-.3125,.8965-.543,1.5127-.543,1.1514,0,1.6367,.7891,1.6367,1.5547,0,.9863-.7148,1.7842-1.8418,2.8701l-.4277,.3945v.0166h2.4014v.6006h-3.4131Z" fill="#fff" />
                                    <path d="M571.1856,708.678c0-.4111-.0088-.749-.0332-1.0771h.6338l.0322,.6406h.0254c.2217-.3779,.5918-.7314,1.25-.7314,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2227,.2803-.3945,.4443-.5186,.2383-.1807,.502-.2793,.8799-.2793,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2539c0-.7646-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3047-.8555,.6582-.0332,.0986-.0576,.2305-.0576,.3623v2.459h-.707v-2.3857c0-.6328-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3623-.8965,.7236-.041,.1074-.0576,.2305-.0576,.3545v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M579.4961,710.2239c0-.6738,.4023-1.1514,1.0605-1.4307l-.0078-.0254c-.5928-.2793-.8477-.7402-.8477-1.2002,0-.8477,.7158-1.4229,1.6533-1.4229,1.0361,0,1.5547,.6494,1.5547,1.3154,0,.4531-.2227,.9375-.8799,1.251v.0244c.666,.2627,1.0771,.7314,1.0771,1.3818,0,.9287-.7979,1.5537-1.8174,1.5537-1.1191,0-1.793-.666-1.793-1.4473Zm2.8779-.0332c0-.6494-.4521-.9619-1.1758-1.167-.625,.1807-.9619,.5918-.9619,1.1016-.0254,.543,.3857,1.0195,1.0684,1.0195,.6504,0,1.0693-.4023,1.0693-.9541Zm-1.9902-2.6729c0,.5352,.4033,.8232,1.0195,.9873,.4609-.1562,.8145-.4854,.8145-.9707,0-.4277-.2549-.8721-.9043-.8721-.6006,0-.9297,.3955-.9297,.8555Z" fill="#fff" />
                                    <path d="M586.8506,711.4329c-.1885,.0986-.6084,.2305-1.1426,.2305-1.2012,0-1.9824-.8145-1.9824-2.0312,0-1.2256,.8389-2.1143,2.1387-2.1143,.4277,0,.8057,.1074,1.0029,.2061l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6738-1.4062,1.5049,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1064,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M587.6993,705.7415h.7236v2.4844h.0166c.1152-.2061,.2959-.3867,.5176-.5107,.2139-.123,.4688-.2051,.7402-.2051,.5352,0,1.3906,.3291,1.3906,1.7021v2.3691h-.7246v-2.2871c0-.6406-.2383-1.1836-.9209-1.1836-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2051-.0488,.3457v2.4014h-.7236v-5.8398Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M380.7471,292.6566c-.1475-.0088-.3369,0-.542,.0322-1.1357,.1895-1.7354,1.0205-1.8594,1.9004h.0254c.2549-.3369,.6982-.6172,1.291-.6172,.9453,0,1.6123,.6836,1.6123,1.7275,0,.9785-.667,1.8838-1.7773,1.8838-1.1426,0-1.8916-.8887-1.8916-2.2783,0-1.0527,.3789-1.8838,.9053-2.4102,.4443-.4355,1.0361-.707,1.7109-.79,.2129-.0322,.3945-.041,.5254-.041v.5928Zm-.2051,3.084c0-.7646-.4355-1.2256-1.1025-1.2256-.4355,0-.8389,.2715-1.0361,.6582-.0488,.082-.082,.1895-.082,.3203,.0166,.8809,.4199,1.5303,1.1758,1.5303,.625,0,1.0449-.5186,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M384.1543,297.4925v-1.4561h-2.4844v-.4766l2.3857-3.4131h.7812v3.3223h.748v.5674h-.748v1.4561h-.6826Zm0-2.0234v-1.7842c0-.2793,.0078-.5596,.0244-.8398h-.0244c-.165,.3125-.2959,.543-.4443,.79l-1.3076,1.8174v.0166h1.752Z" fill="#fff" />
                                    <path d="M386.3682,294.5892c0-.4111-.0088-.748-.0332-1.0771h.6338l.0322,.6416h.0254c.2217-.3789,.5918-.7324,1.25-.7324,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2217,.2803-.3945,.4443-.5186,.2383-.1807,.502-.2793,.8799-.2793,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2529c0-.7656-.2793-1.2266-.8633-1.2266-.4111,0-.7324,.3047-.8555,.6582-.0332,.0996-.0576,.2305-.0576,.3623v2.459h-.707v-2.3857c0-.6328-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3623-.8965,.7246-.041,.1064-.0576,.2305-.0576,.3535v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M398.2149,292.1468v.4775l-2.3271,4.8682h-.749l2.3193-4.7295v-.0156h-2.6152v-.6006h3.3721Z" fill="#fff" />
                                    <path d="M398.9659,297.4925v-.4443l.5674-.5508c1.3652-1.2988,1.9824-1.9902,1.9902-2.7969,0-.543-.2627-1.0439-1.0605-1.0439-.4854,0-.8887,.2471-1.1348,.4521l-.2305-.5098c.3701-.3125,.8965-.543,1.5127-.543,1.1514,0,1.6367,.7891,1.6367,1.5547,0,.9863-.7148,1.7852-1.8418,2.8701l-.4277,.3955v.0156h2.4014v.6006h-3.4131Z" fill="#fff" />
                                    <path d="M406.2539,297.345c-.1885,.0977-.6084,.2295-1.1426,.2295-1.2012,0-1.9824-.8135-1.9824-2.0312,0-1.2256,.8389-2.1143,2.1387-2.1143,.4277,0,.8057,.1074,1.0029,.2061l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6738-1.4062,1.5059,0,.9199,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1074,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M407.1026,291.6536h.7236v2.4834h.0166c.1152-.2061,.2959-.3867,.5176-.5107,.2139-.123,.4688-.2051,.7402-.2051,.5352,0,1.3906,.3291,1.3906,1.7031v2.3682h-.7246v-2.2861c0-.6416-.2383-1.1846-.9209-1.1846-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2051-.0488,.3457v2.4014h-.7236v-5.8389Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M569.2735,1429.762h-2.04l-.2061,1.374c.124-.0166,.2393-.0332,.4365-.0332,.4111,0,.8223,.0908,1.1514,.2881,.4189,.2383,.7646,.6992,.7646,1.373,0,1.0449-.8301,1.8262-1.9902,1.8262-.584,0-1.0771-.165-1.332-.3291l.1807-.5508c.2217,.1318,.6582,.2959,1.1436,.2959,.6826,0,1.2666-.4443,1.2666-1.1602-.0088-.6904-.4688-1.1836-1.5381-1.1836-.3047,0-.543,.0322-.7402,.0576l.3447-2.5664h2.5586v.6084Z" fill="#fff" />
                                    <path d="M570.4756,1433.6693c.2051,.1309,.6826,.3369,1.1846,.3369,.9287,0,1.2168-.5918,1.209-1.0361-.0088-.749-.6826-1.0693-1.3818-1.0693h-.4033v-.543h.4033c.5264,0,1.1924-.2715,1.1924-.9043,0-.4277-.2715-.8066-.9375-.8066-.4277,0-.8389,.1895-1.0693,.3535l-.1895-.5264c.2803-.2051,.8223-.4111,1.3984-.4111,1.0527,0,1.5293,.625,1.5293,1.2754,0,.5508-.3281,1.0195-.9863,1.2578v.0166c.6582,.1318,1.1924,.625,1.1924,1.374,0,.8555-.666,1.6035-1.9492,1.6035-.6006,0-1.127-.1895-1.3896-.3623l.1973-.5586Z" fill="#fff" />
                                    <path d="M574.7539,1431.596c0-.4111-.0088-.748-.0332-1.0771h.6338l.0322,.6416h.0254c.2217-.3779,.5918-.7324,1.25-.7324,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2217,.2803-.3945,.4443-.5176,.2383-.1816,.502-.2803,.8799-.2803,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2529c0-.7656-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3037-.8555,.6582-.0332,.0986-.0576,.2295-.0576,.3613v2.459h-.707v-2.3848c0-.6338-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3613-.8965,.7236-.041,.1064-.0576,.2305-.0576,.3535v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M584.7012,1429.8284h-.0166l-.9297,.501-.1396-.5508,1.168-.625h.6162v5.3457h-.6982v-4.6709Z" fill="#fff" />
                                    <path d="M590.4024,1429.6634c-.1475-.0078-.3369,0-.542,.0332-1.1357,.1895-1.7354,1.0195-1.8594,1.8994h.0254c.2549-.3369,.6982-.6162,1.291-.6162,.9453,0,1.6123,.6826,1.6123,1.7266,0,.9785-.667,1.8838-1.7773,1.8838-1.1426,0-1.8916-.8887-1.8916-2.2783,0-1.0527,.3789-1.8838,.9053-2.4102,.4443-.4355,1.0361-.707,1.7109-.7891,.2129-.0332,.3945-.041,.5254-.041v.5918Zm-.2051,3.084c0-.7646-.4355-1.2246-1.1025-1.2246-.4355,0-.8389,.2705-1.0361,.6572-.0488,.083-.082,.1895-.082,.3213,.0166,.8799,.4199,1.5293,1.1758,1.5293,.625,0,1.0449-.5176,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M594.6397,1434.3519c-.1885,.0986-.6084,.2295-1.1426,.2295-1.2012,0-1.9824-.8135-1.9824-2.0312,0-1.2256,.8389-2.1133,2.1387-2.1133,.4277,0,.8057,.1064,1.0029,.2051l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6748-1.4062,1.5059,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1074,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M595.4883,1428.6605h.7236v2.4834h.0166c.1152-.2051,.2959-.3867,.5176-.5098,.2139-.123,.4688-.2061,.7402-.2061,.5352,0,1.3906,.3291,1.3906,1.7031v2.3682h-.7246v-2.2861c0-.6416-.2383-1.1846-.9209-1.1846-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2061-.0488,.3457v2.4014h-.7236v-5.8389Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M1062.8858,798.6097c.2051,.1318,.6826,.3369,1.1846,.3369,.9287,0,1.2168-.5918,1.209-1.0361-.0088-.748-.6826-1.0693-1.3818-1.0693h-.4033v-.542h.4033c.5264,0,1.1924-.2715,1.1924-.9053,0-.4277-.2715-.8057-.9375-.8057-.4277,0-.8389,.1895-1.0693,.3535l-.1895-.5264c.2803-.2061,.8223-.4111,1.3984-.4111,1.0527,0,1.5293,.625,1.5293,1.2744,0,.5518-.3281,1.0205-.9863,1.2588v.0166c.6582,.1309,1.1924,.625,1.1924,1.373,0,.8555-.666,1.6035-1.9492,1.6035-.6006,0-1.127-.1885-1.3896-.3613l.1973-.5596Z" fill="#fff" />
                                    <path d="M1066.9336,799.4407v-.4443l.5674-.5508c1.3652-1.2998,1.9824-1.9902,1.9902-2.7969,0-.543-.2627-1.0439-1.0605-1.0439-.4854,0-.8887,.2461-1.1348,.4521l-.2305-.5098c.3701-.3125,.8965-.543,1.5127-.543,1.1514,0,1.6367,.7891,1.6367,1.5547,0,.9863-.7148,1.7842-1.8418,2.8701l-.4277,.3945v.0166h2.4014v.6006h-3.4131Z" fill="#fff" />
                                    <path d="M1071.3848,796.5374c0-.4111-.0088-.749-.0332-1.0771h.6338l.0322,.6406h.0254c.2217-.3779,.5918-.7314,1.25-.7314,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2227,.2803-.3945,.4443-.5186,.2383-.1807,.502-.2793,.8799-.2793,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2539c0-.7646-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3047-.8555,.6582-.0332,.0986-.0576,.2305-.0576,.3623v2.459h-.707v-2.3857c0-.6328-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3623-.8965,.7236-.041,.1074-.0576,.2305-.0576,.3545v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M1079.7608,799.4407v-.4443l.5674-.5508c1.3652-1.2998,1.9824-1.9902,1.9902-2.7969,0-.543-.2627-1.0439-1.0605-1.0439-.4854,0-.8887,.2461-1.1348,.4521l-.2305-.5098c.3701-.3125,.8965-.543,1.5127-.543,1.1514,0,1.6367,.7891,1.6367,1.5547,0,.9863-.7148,1.7842-1.8418,2.8701l-.4277,.3945v.0166h2.4014v.6006h-3.4131Z" fill="#fff" />
                                    <path d="M1086.2198,799.4407v-1.4561h-2.4844v-.4766l2.3857-3.4131h.7812v3.3223h.748v.5674h-.748v1.4561h-.6826Zm0-2.0234v-1.7852c0-.2793,.0078-.5586,.0244-.8389h-.0244c-.165,.3125-.2959,.543-.4443,.79l-1.3076,1.8174v.0166h1.752Z" fill="#fff" />
                                    <path d="M1091.2705,799.2923c-.1885,.0986-.6084,.2305-1.1426,.2305-1.2012,0-1.9824-.8145-1.9824-2.0312,0-1.2256,.8389-2.1143,2.1387-2.1143,.4277,0,.8057,.1074,1.0029,.2061l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6738-1.4062,1.5049,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1064,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M1092.1192,793.6009h.7236v2.4844h.0166c.1152-.2061,.2959-.3867,.5176-.5107,.2139-.123,.4688-.2051,.7402-.2051,.5352,0,1.3906,.3291,1.3906,1.7021v2.3691h-.7246v-2.2871c0-.6406-.2383-1.1836-.9209-1.1836-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2051-.0488,.3457v2.4014h-.7236v-5.8398Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M569.2735,972.7513h-2.04l-.2061,1.373c.124-.0166,.2393-.0332,.4365-.0332,.4111,0,.8223,.0908,1.1514,.2881,.4189,.2393,.7646,.6992,.7646,1.374,0,1.0439-.8301,1.8252-1.9902,1.8252-.584,0-1.0771-.165-1.332-.3291l.1807-.5508c.2217,.1318,.6582,.2959,1.1436,.2959,.6826,0,1.2666-.4443,1.2666-1.1592-.0088-.6914-.4688-1.1846-1.5381-1.1846-.3047,0-.543,.0322-.7402,.0576l.3447-2.5664h2.5586v.6094Z" fill="#fff" />
                                    <path d="M570.2373,976.1312c0-.6748,.4023-1.1514,1.0605-1.4307l-.0078-.0254c-.5928-.2793-.8477-.7402-.8477-1.2012,0-.8467,.7158-1.4219,1.6533-1.4219,1.0361,0,1.5547,.6494,1.5547,1.3154,0,.4521-.2227,.9375-.8799,1.251v.0234c.666,.2637,1.0771,.7324,1.0771,1.3828,0,.9287-.7979,1.5537-1.8174,1.5537-1.1191,0-1.793-.666-1.793-1.4473Zm2.8779-.0332c0-.6494-.4521-.9619-1.1758-1.167-.625,.1797-.9619,.5918-.9619,1.1016-.0254,.543,.3857,1.0195,1.0684,1.0195,.6504,0,1.0693-.4023,1.0693-.9541Zm-1.9902-2.6729c0,.5352,.4033,.8223,1.0195,.9863,.4609-.1562,.8145-.4844,.8145-.9697,0-.4277-.2549-.8721-.9043-.8721-.6006,0-.9297,.3945-.9297,.8555Z" fill="#fff" />
                                    <path d="M574.7539,974.5853c0-.4121-.0088-.749-.0332-1.0781h.6338l.0322,.6416h.0254c.2217-.3779,.5918-.7314,1.25-.7314,.543,0,.9541,.3281,1.126,.7969h.0166c.124-.2217,.2803-.3945,.4443-.5176,.2383-.1816,.502-.2793,.8799-.2793,.5264,0,1.3076,.3457,1.3076,1.7266v2.3438h-.707v-2.2529c0-.7646-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3047-.8555,.6582-.0332,.0986-.0576,.2305-.0576,.3613v2.459h-.707v-2.3848c0-.6328-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3613-.8965,.7236-.041,.1064-.0576,.2305-.0576,.3545v2.4004h-.707v-2.9023Z" fill="#fff" />
                                    <path d="M586.1817,972.6517c-.1484-.0078-.3379,0-.543,.0332-1.1357,.1895-1.7354,1.0195-1.8594,1.9004h.0254c.2549-.3379,.6982-.6172,1.291-.6172,.9453,0,1.6113,.6826,1.6113,1.7266,0,.9785-.666,1.8838-1.7764,1.8838-1.1426,0-1.8916-.8877-1.8916-2.2783,0-1.0527,.3789-1.8828,.9053-2.4102,.4443-.4355,1.0361-.707,1.71-.7891,.2139-.0332,.3955-.041,.5273-.041v.5918Zm-.2061,3.084c0-.7637-.4355-1.2246-1.1025-1.2246-.4355,0-.8389,.2715-1.0361,.6582-.0488,.082-.082,.1885-.082,.3203,.0166,.8799,.4199,1.5293,1.1758,1.5293,.625,0,1.0449-.5176,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M590.4024,972.6517c-.1475-.0078-.3369,0-.542,.0332-1.1357,.1895-1.7354,1.0195-1.8594,1.9004h.0254c.2549-.3379,.6982-.6172,1.291-.6172,.9453,0,1.6123,.6826,1.6123,1.7266,0,.9785-.667,1.8838-1.7773,1.8838-1.1426,0-1.8916-.8877-1.8916-2.2783,0-1.0527,.3789-1.8828,.9053-2.4102,.4443-.4355,1.0361-.707,1.7109-.7891,.2129-.0332,.3945-.041,.5254-.041v.5918Zm-.2051,3.084c0-.7637-.4355-1.2246-1.1025-1.2246-.4355,0-.8389,.2715-1.0361,.6582-.0488,.082-.082,.1885-.082,.3203,.0166,.8799,.4199,1.5293,1.1758,1.5293,.625,0,1.0449-.5176,1.0449-1.2832Z" fill="#fff" />
                                    <path d="M594.6397,977.3402c-.1885,.0986-.6084,.2295-1.1426,.2295-1.2012,0-1.9824-.8135-1.9824-2.0312,0-1.2246,.8389-2.1133,2.1387-2.1133,.4277,0,.8057,.1074,1.0029,.2051l-.1641,.5605c-.1729-.0996-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6738-1.4062,1.5049,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1064,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M595.4883,971.6487h.7236v2.4834h.0166c.1152-.2051,.2959-.3867,.5176-.5098,.2139-.123,.4688-.2051,.7402-.2051,.5352,0,1.3906,.3281,1.3906,1.7021v2.3682h-.7246v-2.2861c0-.6416-.2383-1.1846-.9209-1.1846-.4688,0-.8389,.3301-.9707,.7246-.041,.0977-.0488,.2051-.0488,.3457v2.4004h-.7236v-5.8389Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M569.2735,1139.0853h-2.04l-.2061,1.374c.124-.0166,.2393-.0332,.4365-.0332,.4111,0,.8223,.0908,1.1514,.2881,.4189,.2383,.7646,.6992,.7646,1.373,0,1.0449-.8301,1.8262-1.9902,1.8262-.584,0-1.0771-.165-1.332-.3291l.1807-.5508c.2217,.1318,.6582,.2959,1.1436,.2959,.6826,0,1.2666-.4443,1.2666-1.1602-.0088-.6904-.4688-1.1836-1.5381-1.1836-.3047,0-.543,.0322-.7402,.0576l.3447-2.5664h2.5586v.6084Z" fill="#fff" />
                                    <path d="M573.7735,1138.4769v.4775l-2.3271,4.8682h-.749l2.3193-4.7285v-.0166h-2.6152v-.6006h3.3721Z" fill="#fff" />
                                    <path d="M574.7539,1140.9193c0-.4111-.0088-.748-.0332-1.0771h.6338l.0322,.6416h.0254c.2217-.3779,.5918-.7324,1.25-.7324,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2217,.2803-.3945,.4443-.5176,.2383-.1816,.502-.2803,.8799-.2803,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2529c0-.7656-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3037-.8555,.6582-.0332,.0986-.0576,.2295-.0576,.3613v2.459h-.707v-2.3848c0-.6338-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3613-.8965,.7236-.041,.1064-.0576,.2305-.0576,.3535v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M583.1299,1143.8226v-.4443l.5674-.5508c1.3652-1.2988,1.9824-1.9902,1.9902-2.7959,0-.543-.2627-1.0449-1.0605-1.0449-.4854,0-.8887,.2471-1.1348,.4521l-.2305-.5098c.3701-.3125,.8965-.543,1.5127-.543,1.1514,0,1.6367,.79,1.6367,1.5547,0,.9873-.7148,1.7852-1.8418,2.8701l-.4277,.3955v.0156h2.4014v.6006h-3.4131Z" fill="#fff" />
                                    <path d="M587.3516,1143.8226v-.4443l.5674-.5508c1.3652-1.2988,1.9824-1.9902,1.9902-2.7959,0-.543-.2627-1.0449-1.0605-1.0449-.4854,0-.8887,.2471-1.1348,.4521l-.2305-.5098c.3701-.3125,.8965-.543,1.5127-.543,1.1514,0,1.6367,.79,1.6367,1.5547,0,.9873-.7148,1.7852-1.8418,2.8701l-.4277,.3955v.0156h2.4014v.6006h-3.4131Z" fill="#fff" />
                                    <path d="M594.6397,1143.6751c-.1885,.0986-.6084,.2295-1.1426,.2295-1.2012,0-1.9824-.8135-1.9824-2.0312,0-1.2256,.8389-2.1133,2.1387-2.1133,.4277,0,.8057,.1064,1.0029,.2051l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6748-1.4062,1.5059,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1074,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M595.4883,1137.9837h.7236v2.4834h.0166c.1152-.2051,.2959-.3867,.5176-.5098,.2139-.123,.4688-.2061,.7402-.2061,.5352,0,1.3906,.3291,1.3906,1.7031v2.3682h-.7246v-2.2861c0-.6416-.2383-1.1846-.9209-1.1846-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2061-.0488,.3457v2.4014h-.7236v-5.8389Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M569.2735,1302.4193h-2.04l-.2061,1.374c.124-.0166,.2393-.0332,.4365-.0332,.4111,0,.8223,.0908,1.1514,.2881,.4189,.2383,.7646,.6992,.7646,1.373,0,1.0449-.8301,1.8262-1.9902,1.8262-.584,0-1.0771-.165-1.332-.3291l.1807-.5508c.2217,.1318,.6582,.2959,1.1436,.2959,.6826,0,1.2666-.4443,1.2666-1.1602-.0088-.6904-.4688-1.1836-1.5381-1.1836-.3047,0-.543,.0322-.7402,.0576l.3447-2.5664h2.5586v.6084Z" fill="#fff" />
                                    <path d="M573.4942,1302.4193h-2.04l-.2061,1.374c.124-.0166,.2393-.0332,.4365-.0332,.4111,0,.8223,.0908,1.1514,.2881,.4189,.2383,.7646,.6992,.7646,1.373,0,1.0449-.8301,1.8262-1.9902,1.8262-.584,0-1.0771-.165-1.332-.3291l.1807-.5508c.2217,.1318,.6582,.2959,1.1436,.2959,.6826,0,1.2666-.4443,1.2666-1.1602-.0088-.6904-.4688-1.1836-1.5381-1.1836-.3047,0-.543,.0322-.7402,.0576l.3447-2.5664h2.5586v.6084Z" fill="#fff" />
                                    <path d="M574.7539,1304.2532c0-.4111-.0088-.748-.0332-1.0771h.6338l.0322,.6416h.0254c.2217-.3779,.5918-.7324,1.25-.7324,.543,0,.9541,.3291,1.126,.7979h.0166c.124-.2217,.2803-.3945,.4443-.5176,.2383-.1816,.502-.2803,.8799-.2803,.5264,0,1.3076,.3457,1.3076,1.7275v2.3438h-.707v-2.2529c0-.7656-.2793-1.2256-.8633-1.2256-.4111,0-.7324,.3037-.8555,.6582-.0332,.0986-.0576,.2295-.0576,.3613v2.459h-.707v-2.3848c0-.6338-.2803-1.0938-.8311-1.0938-.4521,0-.7812,.3613-.8965,.7236-.041,.1064-.0576,.2305-.0576,.3535v2.4014h-.707v-2.9033Z" fill="#fff" />
                                    <path d="M586.3213,1302.4193h-2.04l-.2051,1.374c.123-.0166,.2383-.0332,.4355-.0332,.4111,0,.8223,.0908,1.1514,.2881,.4189,.2383,.7646,.6992,.7646,1.373,0,1.0449-.8301,1.8262-1.9902,1.8262-.584,0-1.0771-.165-1.332-.3291l.1807-.5508c.2217,.1318,.6582,.2959,1.1436,.2959,.6826,0,1.2666-.4443,1.2666-1.1602-.0088-.6904-.4688-1.1836-1.5381-1.1836-.3047,0-.543,.0322-.7402,.0576l.3447-2.5664h2.5586v.6084Z" fill="#fff" />
                                    <path d="M587.2862,1305.8001c0-.6748,.4023-1.1514,1.0605-1.4316l-.0078-.0244c-.5928-.2793-.8477-.7402-.8477-1.2012,0-.8467,.7158-1.4229,1.6533-1.4229,1.0361,0,1.5547,.6504,1.5547,1.3164,0,.4521-.2227,.9375-.8799,1.25v.0244c.666,.2637,1.0771,.7324,1.0771,1.3818,0,.9297-.7979,1.5547-1.8174,1.5547-1.1191,0-1.793-.666-1.793-1.4473Zm2.8779-.0332c0-.6494-.4521-.9619-1.1758-1.168-.625,.1807-.9619,.5918-.9619,1.1025-.0254,.542,.3857,1.0195,1.0684,1.0195,.6504,0,1.0693-.4033,1.0693-.9541Zm-1.9902-2.6729c0,.5342,.4033,.8223,1.0195,.9863,.4609-.1562,.8145-.4844,.8145-.9697,0-.4277-.2549-.8721-.9043-.8721-.6006,0-.9297,.3945-.9297,.8555Z" fill="#fff" />
                                    <path d="M594.6397,1307.0091c-.1885,.0986-.6084,.2295-1.1426,.2295-1.2012,0-1.9824-.8135-1.9824-2.0312,0-1.2256,.8389-2.1133,2.1387-2.1133,.4277,0,.8057,.1064,1.0029,.2051l-.1641,.5596c-.1729-.0986-.4443-.1895-.8389-.1895-.9131,0-1.4062,.6748-1.4062,1.5059,0,.9209,.5918,1.4883,1.3809,1.4883,.4121,0,.6826-.1074,.8887-.1973l.123,.543Z" fill="#fff" />
                                    <path d="M595.4883,1301.3177h.7236v2.4834h.0166c.1152-.2051,.2959-.3867,.5176-.5098,.2139-.123,.4688-.2061,.7402-.2061,.5352,0,1.3906,.3291,1.3906,1.7031v2.3682h-.7246v-2.2861c0-.6416-.2383-1.1846-.9209-1.1846-.4688,0-.8389,.3291-.9707,.7236-.041,.0986-.0488,.2061-.0488,.3457v2.4014h-.7236v-5.8389Z" fill="#fff" />
                                </g>
                            </g>
                            <g id="junction-name">
                                <g>
                                    <path d="M684.752,792.3216l-.2637-5.3887h.6719l.1123,3.2305c.0234,.6953,.0078,1.0635,0,1.4072h.0156c.1279-.3525,.2559-.7041,.5596-1.3672l1.4873-3.2705h.7119l.1113,3.1025c.0244,.6953,.0244,1.167,.0166,1.5186h.0156c.1602-.4629,.3193-.8945,.5273-1.3828l1.4473-3.2383h.6963l-2.5029,5.3887h-.6953l-.1445-3.0625c-.0234-.6309-.0156-.9346,.0166-1.5508h-.0244c-.1758,.5117-.3193,.9277-.5439,1.416l-1.5029,3.1973h-.7119Z" fill="#5f5c5d" />
                                    <path d="M694.0381,789.9798c0,1.1914-.8477,2.4297-2.1426,2.4297-.9678,0-1.5107-.7188-1.5107-1.5908,0-1.2705,.8867-2.4541,2.1426-2.4541,1.0469,0,1.5107,.791,1.5107,1.6152Zm-2.9658,.8145c0,.6328,.3438,1.0879,.9111,1.0879,.7832,0,1.375-1.0078,1.375-1.9102,0-.4482-.208-1.0801-.9033-1.0801-.8311,0-1.3906,1.0156-1.3828,1.9023Z" fill="#5f5c5d" />
                                    <path d="M694.5127,792.3216l1.0723-5.6758h.6631l-.6797,3.5576h.0166c.1191-.1357,.2637-.2803,.3594-.3916l1.3672-1.3594h.8154l-1.6865,1.5908,1.1592,2.2783h-.7354l-.9277-1.9102-.4883,.4395-.2793,1.4707h-.6562Z" fill="#5f5c5d" />
                                    <path d="M698.208,792.3216l.7441-3.8691h.6631l-.7432,3.8691h-.6641Zm.8643-4.9492c0-.2559,.1992-.4473,.4395-.4473,.2393,0,.3916,.1758,.3916,.416-.0078,.2637-.1924,.4473-.4482,.4473-.2314,0-.3828-.1758-.3828-.416Z" fill="#5f5c5d" />
                                    <path d="M700.0401,792.3216l.5195-2.7422c.0889-.4316,.1367-.8076,.1846-1.127h.5918l-.0967,.7598h.0166c.3438-.5439,.8633-.8477,1.4307-.8477,.4639,0,.9834,.2637,.9834,1.0791,0,.168-.0312,.416-.0713,.624l-.4316,2.2539h-.6641l.4238-2.2305c.0322-.1523,.0557-.3359,.0557-.4961,0-.3906-.1514-.6797-.6074-.6797-.4717,0-1.1436,.5684-1.3193,1.5273l-.3516,1.8789h-.6641Z" fill="#5f5c5d" />
                                    <path d="M704.3526,793.2015c.2324,.1436,.6162,.2559,1.0234,.2559,.6475,0,1.1592-.3359,1.3916-1.3838l.1279-.5752h-.0244c-.3359,.5518-.8076,.8232-1.3428,.8232-.7754,0-1.1758-.6553-1.1758-1.3428-.0078-1.3115,.96-2.6143,2.4551-2.6143,.4473,0,.9355,.127,1.2393,.2715l-.5918,3.1982c-.1602,.8154-.376,1.3838-.7832,1.7275-.3838,.3359-.9043,.4316-1.3516,.4316-.4717,0-.9121-.1123-1.1592-.2715l.1914-.5205Zm2.9668-4.1738c-.1201-.0557-.3604-.1279-.6396-.1279-.9678,0-1.6309,1.0078-1.6396,1.9668,0,.4238,.1523,.9277,.7441,.9277,.5674,0,1.1436-.7197,1.2871-1.4785l.248-1.2881Z" fill="#5f5c5d" />
                                    <path d="M708.5039,792.3216l1.0801-5.6758h.6631l-.4795,2.4941h.0156c.3281-.4717,.7998-.7754,1.3838-.7754,.4395,0,.959,.2715,.959,1.0391,0,.208-.0234,.4561-.0635,.6562l-.4316,2.2617h-.6641l.4316-2.2305c.0244-.1436,.0479-.3203,.0479-.5352,0-.3604-.168-.6406-.5996-.6406-.5117,0-1.1592,.5996-1.3193,1.4717l-.3594,1.9346h-.6641Z" fill="#5f5c5d" />
                                    <path d="M715.3428,792.3216c0-.2314,.04-.5996,.0889-.999h-.0166c-.4238,.7998-.9512,1.0869-1.5029,1.0869-.6797,0-1.1113-.543-1.1113-1.2793,0-1.3428,.9912-2.7656,2.6787-2.7656,.3672,0,.7754,.0635,1.0391,.1514l-.3916,2.0146c-.1279,.6719-.1836,1.3594-.168,1.791h-.6162Zm.4326-3.373c-.0967-.0244-.2402-.0566-.4639-.0566-1,0-1.8154,1.0469-1.8232,2.1191,0,.4316,.1523,.8555,.6641,.8555,.5508,0,1.207-.7197,1.3984-1.751l.2246-1.167Z" fill="#5f5c5d" />
                                    <path d="M716.9522,792.3216l.5195-2.7422c.0801-.4316,.1367-.8076,.1768-1.127h.583l-.1035,.7275h.0234c.3604-.5361,.8477-.8154,1.3594-.8154,.5918,0,.8721,.375,.9033,.8633,.3438-.5596,.8398-.8555,1.4238-.8633,.4473,0,.9355,.2871,.9355,1.0156,0,.1836-.0244,.4395-.0645,.6553l-.4316,2.2861h-.6475l.416-2.2305c.0312-.1436,.0557-.3438,.0557-.5039,0-.4072-.1523-.6719-.584-.6719-.4795,0-1.0469,.5605-1.207,1.4238l-.376,1.9824h-.6396l.4316-2.2705c.0322-.167,.0479-.3193,.0479-.4717,0-.3281-.0879-.6641-.5752-.6641-.4883,0-1.0879,.6406-1.2393,1.4639l-.3604,1.9424h-.6475Z" fill="#5f5c5d" />
                                    <path d="M689.545,801.306c.1758,.0723,.3916,.1123,.6797,.1123,.5674,0,.9189-.2959,1.1025-1.2471l.6885-3.6387h.6631l-.7031,3.7109c-.2402,1.2627-.7676,1.7666-1.7754,1.7666-.3281,0-.6641-.0713-.7998-.1357l.1445-.5684Z" fill="#5f5c5d" />
                                    <path d="M696.5586,798.0521l-.5195,2.7422c-.0801,.4316-.1367,.8086-.1768,1.1279h-.5996l.1045-.7275h-.0166c-.3438,.5039-.8555,.8154-1.3672,.8154-.4951,0-1.0146-.2393-1.0146-1.1191,0-.1846,.0234-.3916,.0713-.6475l.416-2.1914h.6641l-.4082,2.1592c-.0479,.2158-.0723,.3994-.0723,.5674,0,.4004,.168,.6719,.624,.6719,.4795,0,1.0957-.5596,1.2549-1.415l.376-1.9834h.6641Z" fill="#5f5c5d" />
                                    <path d="M696.9922,801.9222l.5195-2.7432c.0879-.4316,.1367-.8066,.1846-1.127h.5918l-.0967,.7598h.0166c.3438-.543,.8633-.8477,1.4307-.8477,.4639,0,.9834,.2637,.9834,1.0801,0,.168-.0312,.415-.0713,.623l-.4316,2.2549h-.6641l.4238-2.2314c.0322-.1514,.0557-.335,.0557-.4951,0-.3916-.1514-.6797-.6074-.6797-.4717,0-1.1436,.5674-1.3193,1.5273l-.3516,1.8789h-.6641Z" fill="#5f5c5d" />
                                    <path d="M703.9268,801.7708c-.2002,.0957-.5996,.2314-1.1201,.2314-.999,0-1.4863-.6719-1.4863-1.4951,0-1.3193,.9746-2.5352,2.3662-2.5352,.3604,0,.6875,.1045,.8076,.168l-.1914,.5273c-.1523-.0713-.3682-.1426-.6562-.1426-1.0078,0-1.6387,.9902-1.6387,1.9023,0,.623,.3271,1.0312,.959,1.0312,.3916,0,.6953-.1191,.8955-.2158l.0645,.5283Z" fill="#5f5c5d" />
                                    <path d="M706.4317,797.013l-.2002,1.0391h.9277l-.1035,.5195h-.9277l-.3604,1.8789c-.04,.2168-.0713,.416-.0713,.624,0,.2314,.1113,.3682,.3672,.3682,.1123,0,.2246-.0078,.3125-.0244l-.0322,.5205c-.1201,.0469-.3203,.0713-.5117,.0713-.5918,0-.8154-.3604-.8154-.751,0-.2246,.0234-.4482,.0801-.7285l.3838-1.959h-.5605l.1045-.5195h.5518l.168-.8711,.6875-.168Z" fill="#5f5c5d" />
                                    <path d="M707.2002,801.9222l.7441-3.8701h.6631l-.7432,3.8701h-.6641Zm.8643-4.9492c0-.2559,.1992-.4482,.4395-.4482,.2393,0,.3916,.1758,.3916,.416-.0078,.2637-.1924,.4473-.4482,.4473-.2314,0-.3828-.1758-.3828-.415Z" fill="#5f5c5d" />
                                    <path d="M712.7412,799.5794c0,1.1914-.8477,2.4307-2.1426,2.4307-.9678,0-1.5107-.7197-1.5107-1.5908,0-1.2715,.8867-2.4551,2.1426-2.4551,1.0469,0,1.5107,.792,1.5107,1.6152Zm-2.9658,.8154c0,.6318,.3438,1.0869,.9111,1.0869,.7832,0,1.375-1.0068,1.375-1.9102,0-.4473-.208-1.0801-.9033-1.0801-.8311,0-1.3906,1.0156-1.3828,1.9033Z" fill="#5f5c5d" />
                                    <path d="M713.208,801.9222l.5195-2.7432c.0889-.4316,.1367-.8066,.1846-1.127h.5918l-.0967,.7598h.0166c.3438-.543,.8633-.8477,1.4307-.8477,.4639,0,.9834,.2637,.9834,1.0801,0,.168-.0312,.415-.0713,.623l-.4316,2.2549h-.6641l.4238-2.2314c.0322-.1514,.0557-.335,.0557-.4951,0-.3916-.1514-.6797-.6074-.6797-.4717,0-1.1436,.5674-1.3193,1.5273l-.3516,1.8789h-.6641Z" fill="#5f5c5d" />
                                </g>
                            </g>
                            <g id="route-elr">
                                <g>
                                    <path d="M646.0596,982.388h2.7939c1.0576,0,1.5059-.4697,1.5059-1.0996,0-.7002-.4619-1.1484-1.5059-1.1484h-2.7939v-.6162h2.752c1.4502,0,2.0459,.7627,2.0459,1.7861,0,.9658-.5537,1.6943-2.0176,1.6943h-2.7803v-.6162Z" fill="#fff" />
                                    <path d="M648.4971,978.4886c-.4346,0-.7852,.0146-1.1064,.0283v-.5527l.5811-.0283v-.0137c-.4131-.2529-.6582-.6514-.6582-1.2051,0-.8193,.6934-1.4355,1.7227-1.4355,1.2188,0,1.8213,.7422,1.8213,1.541,0,.4482-.1963,.8398-.5322,1.043v.0146h1.8418v.6084h-3.6699Zm.9033-.6084c.0908,0,.1748-.0146,.252-.0283,.4277-.1123,.7217-.4834,.7217-.9248,0-.6514-.5322-1.0293-1.3096-1.0293-.6797,0-1.2607,.3574-1.2607,1.0088,0,.4199,.3008,.8125,.7627,.9307,.0771,.0215,.1689,.043,.2529,.043h.5811Z" fill="#fff" />
                                    <path d="M646.1221,973.0013c-.0557-.2666-.0977-.6865-.0977-1.1133,0-.6094,.1055-1.002,.3428-1.2959,.1826-.2451,.4629-.3926,.834-.3926,.4551,0,.8545,.3018,1.0361,.7988h.0146c.1113-.4482,.4834-.9736,1.1836-.9736,.4062,0,.7139,.1611,.9453,.3994,.3008,.3291,.4414,.8613,.4414,1.6318,0,.4199-.0283,.7422-.0566,.9453h-4.6436Zm1.9336-.6094v-.5537c0-.6436-.3359-1.0225-.792-1.0225-.5527,0-.7695,.4209-.7695,1.0371,0,.2803,.0205,.4414,.042,.5391h1.5195Zm2.2617,0c.0215-.1191,.0283-.2939,.0283-.5117,0-.6299-.2314-1.2109-.917-1.2109-.6445,0-.9111,.5527-.9111,1.2188v.5039h1.7998Z" fill="#fff" />
                                    <path d="M645.8076,969.2278v-.6162h4.9727v.6162h-4.9727Z" fill="#fff" />
                                    <path d="M650.7803,965.6995l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0703c-.2803,0-.7842,.0771-.7842,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2939,0,.5811-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0635-.0205-.1338-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M650.6543,961.7864c.084,.1611,.1953,.5186,.1953,.9736,0,1.0225-.6934,1.6875-1.7295,1.6875-1.0439,0-1.7998-.7139-1.7998-1.8203,0-.3643,.0908-.6865,.1748-.8545l.4766,.1396c-.084,.1475-.1611,.3789-.1611,.7148,0,.7773,.5742,1.1973,1.2812,1.1973,.7852,0,1.2676-.5039,1.2676-1.1768,0-.3496-.0908-.5811-.168-.7559l.4629-.1055Z" fill="#fff" />
                                    <path d="M648.9453,960.4583v-.0146c-.1191-.084-.2666-.2031-.3857-.3008l-1.1689-.9951v-.7422l1.3936,1.3096,1.9961-1.4912v.749l-1.625,1.1699,.3496,.3154h1.2754v.6084h-4.9727v-.6084h3.1377Z" fill="#fff" />
                                    <path d="M647.3907,957.4476l1.7227-.4482c.3779-.0977,.7285-.1885,1.0781-.252v-.0205c-.3428-.0771-.707-.1895-1.0713-.3018l-1.7295-.5527v-.5186l1.6943-.5254c.4062-.126,.7637-.2236,1.1064-.3008v-.0215c-.3428-.0557-.7002-.1465-1.0996-.2588l-1.7012-.4834v-.6094l3.3896,1.0928v.5605l-1.6182,.5176c-.3779,.1191-.7139,.2178-1.1133,.3018v.0137c.4062,.084,.7559,.1895,1.1201,.3086l1.6113,.5459v.5605l-3.3896,1.0225v-.6309Z" fill="#fff" />
                                    <path d="M650.7803,950.6917l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0703c-.2803,0-.7842,.0771-.7842,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2939,0,.5811-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0635-.0205-.1338-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M646.417,948.4808h.9736v-.8818h.4688v.8818h1.8281c.4199,0,.6582-.1191,.6582-.4619,0-.1611-.0205-.2803-.042-.3574l.4619-.0283c.0498,.1191,.084,.3086,.084,.5469,0,.2871-.0908,.5186-.2588,.665-.1816,.1748-.4834,.2383-.8828,.2383h-1.8486v.5254h-.4688v-.5254h-.8125l-.1611-.6025Z" fill="#fff" />
                                    <path d="M649.1973,946.6048c.833-.0137,1.1768-.5459,1.1768-1.1621,0-.4414-.0771-.708-.1758-.9395l.4414-.1045c.0986,.2168,.21,.5879,.21,1.1279,0,1.043-.6855,1.666-1.708,1.666-1.0234,0-1.8281-.6016-1.8281-1.5898,0-1.1064,.9736-1.4004,1.5967-1.4004,.126,0,.2236,.0137,.2871,.0215v2.3809Zm-.4414-1.8066c-.3926-.0068-1.002,.1611-1.002,.8545,0,.623,.5752,.8965,1.002,.9453v-1.7998Z" fill="#fff" />
                                    <path d="M648.4483,943.4407c-.3994,0-.7432,.0078-1.0576,.0283v-.5391l.665-.0215v-.0273c-.4551-.1543-.7422-.5254-.7422-.9385,0-.0703,.0068-.1191,.0205-.1758h.5811c-.0137,.0635-.0205,.126-.0205,.2109,0,.4336,.3291,.7422,.791,.8262,.084,.0137,.1826,.0273,.2871,.0273h1.8076v.6094h-2.332Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M654.6573,978.4202c-.0547-.3711-.0977-.8125-.0977-1.2959,0-.875,.2031-1.499,.5879-1.9121,.3857-.4199,.9316-.665,1.6953-.665,.7705,0,1.4004,.2383,1.8359,.6797,.4404,.4404,.6787,1.1689,.6787,2.0869,0,.4336-.0205,.7979-.0557,1.1064h-4.6445Zm4.1748-.6094c.0283-.1543,.0352-.3779,.0352-.6162,0-1.3027-.7285-2.0098-2.0029-2.0098-1.1133-.0078-1.8213,.623-1.8213,1.9111,0,.3154,.0283,.5537,.0635,.7148h3.7256Z" fill="#fff" />
                                    <path d="M656.8428,973.7786c-.3496,0-.6367,.0078-.917,.0283v-.5459l.5596-.0352v-.0137c-.3223-.1689-.6367-.5605-.6367-1.1211,0-.4688,.2803-1.1973,1.4434-1.1973h2.0234v.6162h-1.9541c-.5459,0-1.001,.2031-1.001,.7842,0,.4062,.2871,.7217,.6299,.8262,.0771,.0283,.1826,.042,.2871,.042h2.0381v.6162h-2.4727Z" fill="#fff" />
                                    <path d="M654.6573,968.389c-.0547-.2666-.0977-.6865-.0977-1.1133,0-.6094,.1055-1.002,.3438-1.2959,.1816-.2451,.4619-.3926,.833-.3926,.4561,0,.8545,.3018,1.0361,.7988h.0146c.1123-.4482,.4834-.9736,1.1836-.9736,.4062,0,.7139,.1611,.9453,.3994,.3018,.3291,.4414,.8613,.4414,1.6318,0,.4199-.0283,.7422-.0557,.9453h-4.6445Zm1.9336-.6094v-.5537c0-.6436-.3359-1.0225-.791-1.0225-.5537,0-.7705,.4209-.7705,1.0371,0,.2803,.0205,.4414,.042,.5391h1.5195Zm2.2617,0c.0215-.1191,.0293-.2939,.0293-.5117,0-.6299-.2324-1.2109-.918-1.2109-.6445,0-.9102,.5527-.9102,1.2188v.5039h1.7988Z" fill="#fff" />
                                    <path d="M654.3428,964.6155v-.6162h4.9727v.6162h-4.9727Z" fill="#fff" />
                                    <path d="M659.3155,961.0872l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0693c-.2812,0-.7852,.0771-.7852,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2949,0,.582-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0625-.0205-.1328-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M659.1895,957.1741c.084,.1611,.1963,.5186,.1963,.9736,0,1.0225-.6934,1.6875-1.7305,1.6875-1.043,0-1.7998-.7139-1.7998-1.8203,0-.3643,.0908-.6865,.1748-.8545l.4766,.1396c-.084,.1475-.1611,.3789-.1611,.7148,0,.7773,.5742,1.1973,1.2822,1.1973,.7842,0,1.2676-.5039,1.2676-1.1768,0-.3496-.0918-.5811-.168-.7559l.4619-.1055Z" fill="#fff" />
                                    <path d="M657.4805,955.845v-.0137c-.1191-.084-.2666-.2031-.3857-.3008l-1.1689-.9951v-.7422l1.3936,1.3096,1.9961-1.4912v.749l-1.625,1.1699,.3496,.3145h1.2754v.6094h-4.9727v-.6094h3.1377Z" fill="#fff" />
                                    <path d="M655.9258,952.8353l1.7227-.4482c.3779-.0977,.7285-.1885,1.0791-.252v-.0205c-.3438-.0771-.708-.1895-1.0723-.3018l-1.7295-.5527v-.5186l1.6943-.5254c.4062-.126,.7637-.2236,1.1074-.3008v-.0215c-.3438-.0557-.7012-.1465-1.0996-.2588l-1.7021-.4834v-.6094l3.3896,1.0928v.5605l-1.6172,.5176c-.3789,.1191-.7148,.2178-1.1143,.3018v.0137c.4062,.084,.7568,.1895,1.1201,.3086l1.6113,.5459v.5605l-3.3896,1.0225v-.6309Z" fill="#fff" />
                                    <path d="M659.3155,946.0794l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0693c-.2812,0-.7852,.0771-.7852,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2949,0,.582-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0625-.0205-.1328-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M654.9522,943.8685h.9736v-.8818h.4697v.8818h1.8271c.4209,0,.6592-.1191,.6592-.4619,0-.1611-.0215-.2803-.043-.3574l.4629-.0283c.0488,.1191,.084,.3086,.084,.5469,0,.2871-.0918,.5186-.2598,.665-.1816,.1748-.4824,.2383-.8828,.2383h-1.8477v.5254h-.4697v-.5254h-.8125l-.1611-.6025Z" fill="#fff" />
                                    <path d="M657.7325,941.9925c.833-.0137,1.1768-.5459,1.1768-1.1631,0-.4404-.0771-.707-.1758-.9385l.4414-.1045c.0986,.2168,.2109,.5879,.2109,1.1279,0,1.043-.6865,1.666-1.709,1.666-1.0234,0-1.8281-.6016-1.8281-1.5898,0-1.1064,.9736-1.4004,1.5967-1.4004,.126,0,.2236,.0137,.2871,.0215v2.3809Zm-.4404-1.8066c-.3926-.0068-1.002,.1611-1.002,.8545,0,.623,.5742,.8965,1.002,.9453v-1.7998Z" fill="#fff" />
                                    <path d="M656.9834,938.8284c-.3994,0-.7422,.0078-1.0576,.0283v-.5391l.665-.0215v-.0273c-.4551-.1543-.7422-.5254-.7422-.9385,0-.0703,.0068-.1191,.0215-.1758h.5801c-.0137,.0635-.0205,.126-.0205,.2109,0,.4336,.3291,.7422,.792,.8262,.084,.0137,.1816,.0283,.2871,.0283h1.8066v.6084h-2.332Z" fill="#fff" />
                                </g>
                                <polygon points="649.053 935.5581 646.9067 939.2757 651.1994 939.2757 649.053 935.5581" fill="#fff" />
                                <polygon points="657.2612 985.7223 659.4076 982.0047 655.1148 982.0047 657.2612 985.7223" fill="#fff" />
                                <g>
                                    <path d="M646.0596,1211.4603h2.7939c1.0576,0,1.5059-.4697,1.5059-1.0996,0-.7002-.4619-1.1484-1.5059-1.1484h-2.7939v-.6162h2.752c1.4502,0,2.0459,.7627,2.0459,1.7861,0,.9658-.5537,1.6943-2.0176,1.6943h-2.7803v-.6162Z" fill="#fff" />
                                    <path d="M648.4971,1207.5609c-.4346,0-.7852,.0146-1.1064,.0283v-.5527l.5811-.0283v-.0137c-.4131-.2529-.6582-.6514-.6582-1.2051,0-.8193,.6934-1.4355,1.7227-1.4355,1.2188,0,1.8213,.7422,1.8213,1.541,0,.4482-.1963,.8398-.5322,1.043v.0146h1.8418v.6084h-3.6699Zm.9033-.6084c.0908,0,.1748-.0146,.252-.0283,.4277-.1123,.7217-.4834,.7217-.9248,0-.6514-.5322-1.0293-1.3096-1.0293-.6797,0-1.2607,.3574-1.2607,1.0088,0,.4199,.3008,.8125,.7627,.9307,.0771,.0215,.1689,.043,.2529,.043h.5811Z" fill="#fff" />
                                    <path d="M646.1221,1202.0735c-.0557-.2666-.0977-.6865-.0977-1.1133,0-.6094,.1055-1.002,.3428-1.2959,.1826-.2451,.4629-.3926,.834-.3926,.4551,0,.8545,.3018,1.0361,.7988h.0146c.1113-.4482,.4834-.9736,1.1836-.9736,.4062,0,.7139,.1611,.9453,.3994,.3008,.3291,.4414,.8613,.4414,1.6318,0,.4199-.0283,.7422-.0566,.9453h-4.6436Zm1.9336-.6094v-.5537c0-.6436-.3359-1.0225-.792-1.0225-.5527,0-.7695,.4209-.7695,1.0371,0,.2803,.0205,.4414,.042,.5391h1.5195Zm2.2617,0c.0215-.1191,.0283-.2939,.0283-.5117,0-.6299-.2314-1.2109-.917-1.2109-.6445,0-.9111,.5527-.9111,1.2188v.5039h1.7998Z" fill="#fff" />
                                    <path d="M645.8076,1198.3001v-.6162h4.9727v.6162h-4.9727Z" fill="#fff" />
                                    <path d="M650.7803,1194.7718l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0703c-.2803,0-.7842,.0771-.7842,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2939,0,.5811-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0635-.0205-.1338-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M650.6543,1190.8587c.084,.1611,.1953,.5186,.1953,.9736,0,1.0225-.6934,1.6875-1.7295,1.6875-1.0439,0-1.7998-.7139-1.7998-1.8203,0-.3643,.0908-.6865,.1748-.8545l.4766,.1396c-.084,.1475-.1611,.3789-.1611,.7148,0,.7773,.5742,1.1973,1.2812,1.1973,.7852,0,1.2676-.5039,1.2676-1.1768,0-.3496-.0908-.5811-.168-.7559l.4629-.1055Z" fill="#fff" />
                                    <path d="M648.9453,1189.5306v-.0146c-.1191-.084-.2666-.2031-.3857-.3008l-1.1689-.9951v-.7422l1.3936,1.3096,1.9961-1.4912v.749l-1.625,1.1699,.3496,.3154h1.2754v.6084h-4.9727v-.6084h3.1377Z" fill="#fff" />
                                    <path d="M647.3907,1186.5198l1.7227-.4482c.3779-.0977,.7285-.1885,1.0781-.252v-.0205c-.3428-.0771-.707-.1895-1.0713-.3018l-1.7295-.5527v-.5186l1.6943-.5254c.4062-.126,.7637-.2236,1.1064-.3008v-.0215c-.3428-.0557-.7002-.1465-1.0996-.2588l-1.7012-.4834v-.6094l3.3896,1.0928v.5605l-1.6182,.5176c-.3779,.1191-.7139,.2178-1.1133,.3018v.0137c.4062,.084,.7559,.1895,1.1201,.3086l1.6113,.5459v.5605l-3.3896,1.0225v-.6309Z" fill="#fff" />
                                    <path d="M650.7803,1179.764l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0703c-.2803,0-.7842,.0771-.7842,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2939,0,.5811-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0635-.0205-.1338-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M646.417,1177.553h.9736v-.8818h.4688v.8818h1.8281c.4199,0,.6582-.1191,.6582-.4619,0-.1611-.0205-.2803-.042-.3574l.4619-.0283c.0498,.1191,.084,.3086,.084,.5469,0,.2871-.0908,.5186-.2588,.665-.1816,.1748-.4834,.2383-.8828,.2383h-1.8486v.5254h-.4688v-.5254h-.8125l-.1611-.6025Z" fill="#fff" />
                                    <path d="M649.1973,1175.6771c.833-.0137,1.1768-.5459,1.1768-1.1621,0-.4414-.0771-.708-.1758-.9395l.4414-.1045c.0986,.2168,.21,.5879,.21,1.1279,0,1.043-.6855,1.666-1.708,1.666-1.0234,0-1.8281-.6016-1.8281-1.5898,0-1.1064,.9736-1.4004,1.5967-1.4004,.126,0,.2236,.0137,.2871,.0215v2.3809Zm-.4414-1.8066c-.3926-.0068-1.002,.1611-1.002,.8545,0,.623,.5752,.8965,1.002,.9453v-1.7998Z" fill="#fff" />
                                    <path d="M648.4483,1172.513c-.3994,0-.7432,.0078-1.0576,.0283v-.5391l.665-.0215v-.0273c-.4551-.1543-.7422-.5254-.7422-.9385,0-.0703,.0068-.1191,.0205-.1758h.5811c-.0137,.0635-.0205,.126-.0205,.2109,0,.4336,.3291,.7422,.791,.8262,.084,.0137,.1826,.0273,.2871,.0273h1.8076v.6094h-2.332Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M654.6573,1207.4925c-.0547-.3711-.0977-.8125-.0977-1.2959,0-.875,.2031-1.499,.5879-1.9121,.3857-.4199,.9316-.665,1.6953-.665,.7705,0,1.4004,.2383,1.8359,.6797,.4404,.4404,.6787,1.1689,.6787,2.0869,0,.4336-.0205,.7979-.0557,1.1064h-4.6445Zm4.1748-.6094c.0283-.1543,.0352-.3779,.0352-.6162,0-1.3027-.7285-2.0098-2.0029-2.0098-1.1133-.0078-1.8213,.623-1.8213,1.9111,0,.3154,.0283,.5537,.0635,.7148h3.7256Z" fill="#fff" />
                                    <path d="M656.8428,1202.8509c-.3496,0-.6367,.0078-.917,.0283v-.5459l.5596-.0352v-.0137c-.3223-.1689-.6367-.5605-.6367-1.1211,0-.4688,.2803-1.1973,1.4434-1.1973h2.0234v.6162h-1.9541c-.5459,0-1.001,.2031-1.001,.7842,0,.4062,.2871,.7217,.6299,.8262,.0771,.0283,.1826,.042,.2871,.042h2.0381v.6162h-2.4727Z" fill="#fff" />
                                    <path d="M654.6573,1197.4612c-.0547-.2666-.0977-.6865-.0977-1.1133,0-.6094,.1055-1.002,.3438-1.2959,.1816-.2451,.4619-.3926,.833-.3926,.4561,0,.8545,.3018,1.0361,.7988h.0146c.1123-.4482,.4834-.9736,1.1836-.9736,.4062,0,.7139,.1611,.9453,.3994,.3018,.3291,.4414,.8613,.4414,1.6318,0,.4199-.0283,.7422-.0557,.9453h-4.6445Zm1.9336-.6094v-.5537c0-.6436-.3359-1.0225-.791-1.0225-.5537,0-.7705,.4209-.7705,1.0371,0,.2803,.0205,.4414,.042,.5391h1.5195Zm2.2617,0c.0215-.1191,.0293-.2939,.0293-.5117,0-.6299-.2324-1.2109-.918-1.2109-.6445,0-.9102,.5527-.9102,1.2188v.5039h1.7988Z" fill="#fff" />
                                    <path d="M654.3428,1193.6878v-.6162h4.9727v.6162h-4.9727Z" fill="#fff" />
                                    <path d="M659.3155,1190.1595l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0693c-.2812,0-.7852,.0771-.7852,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2949,0,.582-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0625-.0205-.1328-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M659.1895,1186.2464c.084,.1611,.1963,.5186,.1963,.9736,0,1.0225-.6934,1.6875-1.7305,1.6875-1.043,0-1.7998-.7139-1.7998-1.8203,0-.3643,.0908-.6865,.1748-.8545l.4766,.1396c-.084,.1475-.1611,.3789-.1611,.7148,0,.7773,.5742,1.1973,1.2822,1.1973,.7842,0,1.2676-.5039,1.2676-1.1768,0-.3496-.0918-.5811-.168-.7559l.4619-.1055Z" fill="#fff" />
                                    <path d="M657.4805,1184.9173v-.0137c-.1191-.084-.2666-.2031-.3857-.3008l-1.1689-.9951v-.7422l1.3936,1.3096,1.9961-1.4912v.749l-1.625,1.1699,.3496,.3145h1.2754v.6094h-4.9727v-.6094h3.1377Z" fill="#fff" />
                                    <path d="M655.9258,1181.9075l1.7227-.4482c.3779-.0977,.7285-.1885,1.0791-.252v-.0205c-.3438-.0771-.708-.1895-1.0723-.3018l-1.7295-.5527v-.5186l1.6943-.5254c.4062-.126,.7637-.2236,1.1074-.3008v-.0215c-.3438-.0557-.7012-.1465-1.0996-.2588l-1.7021-.4834v-.6094l3.3896,1.0928v.5605l-1.6172,.5176c-.3789,.1191-.7148,.2178-1.1143,.3018v.0137c.4062,.084,.7568,.1895,1.1201,.3086l1.6113,.5459v.5605l-3.3896,1.0225v-.6309Z" fill="#fff" />
                                    <path d="M659.3155,1175.1517l-.4277,.0498v.0205c.2666,.1895,.5049,.5537,.5049,1.0371,0,.6855-.4834,1.0361-.9736,1.0361-.8193,0-1.2676-.7285-1.2607-2.0381h-.0693c-.2812,0-.7852,.0771-.7852,.7705,0,.3154,.0977,.6445,.252,.8828l-.4062,.1396c-.1816-.2803-.3008-.6865-.3008-1.1133,0-1.0371,.707-1.2891,1.3867-1.2891h1.2676c.2949,0,.582-.0137,.8125-.0557v.5596Zm-1.7305,.0918c-.0137,.6719,.1055,1.4355,.7637,1.4355,.3994,0,.5879-.2666,.5879-.5811,0-.4414-.2793-.7217-.5664-.8203-.0625-.0205-.1328-.0342-.1963-.0342h-.5889Z" fill="#fff" />
                                    <path d="M654.9522,1172.9407h.9736v-.8818h.4697v.8818h1.8271c.4209,0,.6592-.1191,.6592-.4619,0-.1611-.0215-.2803-.043-.3574l.4629-.0283c.0488,.1191,.084,.3086,.084,.5469,0,.2871-.0918,.5186-.2598,.665-.1816,.1748-.4824,.2383-.8828,.2383h-1.8477v.5254h-.4697v-.5254h-.8125l-.1611-.6025Z" fill="#fff" />
                                    <path d="M657.7325,1171.0648c.833-.0137,1.1768-.5459,1.1768-1.1631,0-.4404-.0771-.707-.1758-.9385l.4414-.1045c.0986,.2168,.2109,.5879,.2109,1.1279,0,1.043-.6865,1.666-1.709,1.666-1.0234,0-1.8281-.6016-1.8281-1.5898,0-1.1064,.9736-1.4004,1.5967-1.4004,.126,0,.2236,.0137,.2871,.0215v2.3809Zm-.4404-1.8066c-.3926-.0068-1.002,.1611-1.002,.8545,0,.623,.5742,.8965,1.002,.9453v-1.7998Z" fill="#fff" />
                                    <path d="M656.9834,1167.9007c-.3994,0-.7422,.0078-1.0576,.0283v-.5391l.665-.0215v-.0273c-.4551-.1543-.7422-.5254-.7422-.9385,0-.0703,.0068-.1191,.0215-.1758h.5801c-.0137,.0635-.0205,.126-.0205,.2109,0,.4336,.3291,.7422,.792,.8262,.084,.0137,.1816,.0283,.2871,.0283h1.8066v.6084h-2.332Z" fill="#fff" />
                                </g>
                                <polygon points="649.053 1164.6299 646.9067 1168.3475 651.1994 1168.3475 649.053 1164.6299" fill="#fff" />
                                <polygon points="657.2612 1214.7941 659.4076 1211.0765 655.1148 1211.0765 657.2612 1214.7941" fill="#fff" />
                                <g>
                                    <path d="M1061.2012,827.3519v2.7949c0,1.0576,.4697,1.5059,1.0996,1.5059,.7002,0,1.1484-.4619,1.1484-1.5059v-2.7949h.6162v2.7529c0,1.4502-.7627,2.0449-1.7861,2.0449-.9658,0-1.6943-.5527-1.6943-2.0166v-2.7812h.6162Z" fill="#fff" />
                                    <path d="M1065.1006,829.7894c0-.4336-.0146-.7842-.0283-1.1064h.5527l.0283,.5811h.0137c.2529-.4131,.6514-.6582,1.2051-.6582,.8193,0,1.4355,.6934,1.4355,1.7227,0,1.2188-.7422,1.8213-1.541,1.8213-.4482,0-.8398-.1963-1.043-.5322h-.0146v1.8418h-.6084v-3.6699Zm.6084,.9033c0,.0918,.0146,.1758,.0283,.2529,.1123,.4268,.4834,.7207,.9248,.7207,.6514,0,1.0293-.5322,1.0293-1.3096,0-.6797-.3574-1.2607-1.0088-1.2607-.4199,0-.8125,.3018-.9307,.7637-.0215,.0771-.043,.168-.043,.252v.5811Z" fill="#fff" />
                                    <path d="M1074.5664,829.9993c-.0352-.6582-.0771-1.4492-.0703-2.0381h-.0215c-.1602,.5537-.3564,1.1416-.5947,1.793l-.834,2.291h-.4619l-.7637-2.249c-.2236-.665-.4131-1.2744-.5459-1.835h-.0137c-.0146,.5889-.0498,1.3799-.0918,2.0879l-.126,2.0234h-.5811l.3291-4.7207h.7773l.8057,2.2832c.1963,.582,.3574,1.0996,.4766,1.5898h.0205c.1191-.4756,.2871-.9941,.4971-1.5898l.8408-2.2832h.7773l.2939,4.7207h-.5947l-.1191-2.0732Z" fill="#fff" />
                                    <path d="M1078.1143,832.0726l-.0498-.4268h-.0205c-.1895,.2656-.5537,.5039-1.0371,.5039-.6855,0-1.0361-.4834-1.0361-.9736,0-.8193,.7285-1.2676,2.0381-1.2607v-.0693c0-.2803-.0771-.7852-.7705-.7852-.3154,0-.6445,.0986-.8828,.2529l-.1396-.4062c.2803-.1826,.6865-.3018,1.1133-.3018,1.0371,0,1.2891,.708,1.2891,1.3867v1.2676c0,.2949,.0137,.582,.0557,.8125h-.5596Zm-.0918-1.7295c-.6719-.0146-1.4355,.1045-1.4355,.7637,0,.3984,.2666,.5879,.5811,.5879,.4414,0,.7217-.2803,.8203-.5674,.0205-.0625,.0342-.1328,.0342-.1963v-.5879Z" fill="#fff" />
                                    <path d="M1080.2969,827.7308c.0078,.21-.1465,.3779-.3916,.3779-.2178,0-.3711-.168-.3711-.3779,0-.2178,.1611-.3857,.3848-.3857,.2314,0,.3779,.168,.3779,.3857Zm-.6855,4.3418v-3.3896h.6162v3.3896h-.6162Z" fill="#fff" />
                                    <path d="M1081.2491,829.6009c0-.3506-.0078-.6377-.0283-.918h.5459l.0352,.5605h.0137c.1689-.3223,.5605-.6377,1.1211-.6377,.4688,0,1.1973,.2803,1.1973,1.4434v2.0234h-.6162v-1.9541c0-.5459-.2031-1.001-.7842-1.001-.4062,0-.7217,.2871-.8262,.6299-.0283,.0771-.042,.1826-.042,.2871v2.0381h-.6162v-2.4717Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M1060.5743,835.9505c.3711-.0557,.8125-.0977,1.2959-.0977,.875,0,1.499,.2031,1.9121,.5879,.4199,.3848,.665,.9316,.665,1.6953,0,.7695-.2383,1.4004-.6797,1.835-.4404,.4404-1.1689,.6787-2.0869,.6787-.4336,0-.7979-.0205-1.1064-.0557v-4.6436Zm.6094,4.1738c.1543,.0293,.3779,.0352,.6162,.0352,1.3027,0,2.0098-.7285,2.0098-2.002,.0078-1.1143-.623-1.8223-1.9111-1.8223-.3154,0-.5537,.0293-.7148,.0635v3.7256Z" fill="#fff" />
                                    <path d="M1065.2159,838.136c0-.3506-.0078-.6377-.0283-.918h.5459l.0352,.5605h.0137c.1689-.3223,.5605-.6367,1.1211-.6367,.4688,0,1.1973,.2793,1.1973,1.4424v2.0244h-.6162v-1.9551c0-.5459-.2031-1.001-.7842-1.001-.4062,0-.7217,.2871-.8262,.6299-.0283,.0781-.042,.1826-.042,.2871v2.0391h-.6162v-2.4727Z" fill="#fff" />
                                    <path d="M1074.584,838.5345c-.0352-.6582-.0771-1.4492-.0703-2.0371h-.0215c-.1602,.5527-.3564,1.1406-.5947,1.793l-.834,2.29h-.4619l-.7637-2.249c-.2236-.6641-.4131-1.2734-.5459-1.834h-.0137c-.0146,.5879-.0498,1.3789-.0918,2.0869l-.126,2.0244h-.5811l.3291-4.7207h.7773l.8057,2.2832c.1963,.5811,.3574,1.0996,.4766,1.5898h.0205c.1191-.4766,.2871-.9941,.4971-1.5898l.8408-2.2832h.7773l.2939,4.7207h-.5947l-.1191-2.0742Z" fill="#fff" />
                                    <path d="M1078.1309,840.6087l-.0498-.4277h-.0205c-.1895,.2656-.5537,.5039-1.0371,.5039-.6855,0-1.0361-.4824-1.0361-.9727,0-.8203,.7285-1.2676,2.0381-1.2617v-.0693c0-.2803-.0771-.7842-.7705-.7842-.3154,0-.6445,.0977-.8828,.252l-.1396-.4062c.2803-.1816,.6865-.3008,1.1133-.3008,1.0371,0,1.2891,.707,1.2891,1.3867v1.2676c0,.2939,.0137,.5811,.0557,.8125h-.5596Zm-.0918-1.7305c-.6719-.0137-1.4355,.1055-1.4355,.7637,0,.3984,.2666,.5879,.5811,.5879,.4414,0,.7217-.2793,.8203-.5664,.0205-.0635,.0342-.1338,.0342-.1973v-.5879Z" fill="#fff" />
                                    <path d="M1080.3135,836.2659c.0078,.21-.1465,.3779-.3916,.3779-.2178,0-.3711-.168-.3711-.3779,0-.2178,.1611-.3857,.3848-.3857,.2314,0,.3779,.168,.3779,.3857Zm-.6855,4.3428v-3.3906h.6162v3.3906h-.6162Z" fill="#fff" />
                                    <path d="M1081.2666,838.136c0-.3506-.0078-.6377-.0283-.918h.5459l.0352,.5605h.0137c.1689-.3223,.5605-.6367,1.1211-.6367,.4688,0,1.1973,.2793,1.1973,1.4424v2.0244h-.6162v-1.9551c0-.5459-.2031-1.001-.7842-1.001-.4062,0-.7217,.2871-.8262,.6299-.0283,.0781-.042,.1826-.042,.2871v2.0391h-.6162v-2.4727Z" fill="#fff" />
                                </g>
                                <polygon points="1090.2294 830.3457 1086.5118 828.1993 1086.5118 832.4921 1090.2294 830.3457" fill="#fff" />
                                <polygon points="1054.3069 838.5539 1058.0245 840.7002 1058.0245 836.4075 1054.3069 838.5539" fill="#fff" />
                                <g>
                                    <path d="M872.3575,827.3519v2.7949c0,1.0576,.4697,1.5059,1.0996,1.5059,.7002,0,1.1484-.4619,1.1484-1.5059v-2.7949h.6162v2.7529c0,1.4502-.7627,2.0449-1.7861,2.0449-.9658,0-1.6943-.5527-1.6943-2.0166v-2.7812h.6162Z" fill="#fff" />
                                    <path d="M876.2569,829.7894c0-.4336-.0146-.7842-.0283-1.1064h.5527l.0283,.5811h.0137c.2529-.4131,.6514-.6582,1.2051-.6582,.8193,0,1.4355,.6934,1.4355,1.7227,0,1.2188-.7422,1.8213-1.541,1.8213-.4482,0-.8398-.1963-1.043-.5322h-.0146v1.8418h-.6084v-3.6699Zm.6084,.9033c0,.0918,.0146,.1758,.0283,.2529,.1123,.4268,.4834,.7207,.9248,.7207,.6514,0,1.0293-.5322,1.0293-1.3096,0-.6797-.3574-1.2607-1.0088-1.2607-.4199,0-.8125,.3018-.9307,.7637-.0215,.0771-.043,.168-.043,.252v.5811Z" fill="#fff" />
                                    <path d="M885.7227,829.9993c-.0352-.6582-.0771-1.4492-.0703-2.0381h-.0215c-.1602,.5537-.3564,1.1416-.5947,1.793l-.834,2.291h-.4619l-.7637-2.249c-.2236-.665-.4131-1.2744-.5459-1.835h-.0137c-.0146,.5889-.0498,1.3799-.0918,2.0879l-.126,2.0234h-.5811l.3291-4.7207h.7773l.8057,2.2832c.1963,.582,.3574,1.0996,.4766,1.5898h.0205c.1191-.4756,.2871-.9941,.4971-1.5898l.8408-2.2832h.7773l.2939,4.7207h-.5947l-.1191-2.0732Z" fill="#fff" />
                                    <path d="M889.2705,832.0726l-.0498-.4268h-.0205c-.1895,.2656-.5537,.5039-1.0371,.5039-.6855,0-1.0361-.4834-1.0361-.9736,0-.8193,.7285-1.2676,2.0381-1.2607v-.0693c0-.2803-.0771-.7852-.7705-.7852-.3154,0-.6445,.0986-.8828,.2529l-.1396-.4062c.2803-.1826,.6865-.3018,1.1133-.3018,1.0371,0,1.2891,.708,1.2891,1.3867v1.2676c0,.2949,.0137,.582,.0557,.8125h-.5596Zm-.0918-1.7295c-.6719-.0146-1.4355,.1045-1.4355,.7637,0,.3984,.2666,.5879,.5811,.5879,.4414,0,.7217-.2803,.8203-.5674,.0205-.0625,.0342-.1328,.0342-.1963v-.5879Z" fill="#fff" />
                                    <path d="M891.4532,827.7308c.0078,.21-.1465,.3779-.3916,.3779-.2178,0-.3711-.168-.3711-.3779,0-.2178,.1611-.3857,.3848-.3857,.2314,0,.3779,.168,.3779,.3857Zm-.6855,4.3418v-3.3896h.6162v3.3896h-.6162Z" fill="#fff" />
                                    <path d="M892.4053,829.6009c0-.3506-.0078-.6377-.0283-.918h.5459l.0352,.5605h.0137c.1689-.3223,.5605-.6377,1.1211-.6377,.4688,0,1.1973,.2803,1.1973,1.4434v2.0234h-.6162v-1.9541c0-.5459-.2031-1.001-.7842-1.001-.4062,0-.7217,.2871-.8262,.6299-.0283,.0771-.042,.1826-.042,.2871v2.0381h-.6162v-2.4717Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M871.7305,835.9505c.3711-.0557,.8125-.0977,1.2959-.0977,.875,0,1.499,.2031,1.9121,.5879,.4199,.3848,.665,.9316,.665,1.6953,0,.7695-.2383,1.4004-.6797,1.835-.4404,.4404-1.1689,.6787-2.0869,.6787-.4336,0-.7979-.0205-1.1064-.0557v-4.6436Zm.6094,4.1738c.1543,.0293,.3779,.0352,.6162,.0352,1.3027,0,2.0098-.7285,2.0098-2.002,.0078-1.1143-.623-1.8223-1.9111-1.8223-.3154,0-.5537,.0293-.7148,.0635v3.7256Z" fill="#fff" />
                                    <path d="M876.3721,838.136c0-.3506-.0078-.6377-.0283-.918h.5459l.0352,.5605h.0137c.1689-.3223,.5605-.6367,1.1211-.6367,.4688,0,1.1973,.2793,1.1973,1.4424v2.0244h-.6162v-1.9551c0-.5459-.2031-1.001-.7842-1.001-.4062,0-.7217,.2871-.8262,.6299-.0283,.0781-.042,.1826-.042,.2871v2.0391h-.6162v-2.4727Z" fill="#fff" />
                                    <path d="M885.7403,838.5345c-.0352-.6582-.0771-1.4492-.0703-2.0371h-.0215c-.1602,.5527-.3564,1.1406-.5947,1.793l-.834,2.29h-.4619l-.7637-2.249c-.2236-.6641-.4131-1.2734-.5459-1.834h-.0137c-.0146,.5879-.0498,1.3789-.0918,2.0869l-.126,2.0244h-.5811l.3291-4.7207h.7773l.8057,2.2832c.1963,.5811,.3574,1.0996,.4766,1.5898h.0205c.1191-.4766,.2871-.9941,.4971-1.5898l.8408-2.2832h.7773l.2939,4.7207h-.5947l-.1191-2.0742Z" fill="#fff" />
                                    <path d="M889.2871,840.6087l-.0498-.4277h-.0205c-.1895,.2656-.5537,.5039-1.0371,.5039-.6855,0-1.0361-.4824-1.0361-.9727,0-.8203,.7285-1.2676,2.0381-1.2617v-.0693c0-.2803-.0771-.7842-.7705-.7842-.3154,0-.6445,.0977-.8828,.252l-.1396-.4062c.2803-.1816,.6865-.3008,1.1133-.3008,1.0371,0,1.2891,.707,1.2891,1.3867v1.2676c0,.2939,.0137,.5811,.0557,.8125h-.5596Zm-.0918-1.7305c-.6719-.0137-1.4355,.1055-1.4355,.7637,0,.3984,.2666,.5879,.5811,.5879,.4414,0,.7217-.2793,.8203-.5664,.0205-.0635,.0342-.1338,.0342-.1973v-.5879Z" fill="#fff" />
                                    <path d="M891.4698,836.2659c.0078,.21-.1465,.3779-.3916,.3779-.2178,0-.3711-.168-.3711-.3779,0-.2178,.1611-.3857,.3848-.3857,.2314,0,.3779,.168,.3779,.3857Zm-.6855,4.3428v-3.3906h.6162v3.3906h-.6162Z" fill="#fff" />
                                    <path d="M892.4229,838.136c0-.3506-.0078-.6377-.0283-.918h.5459l.0352,.5605h.0137c.1689-.3223,.5605-.6367,1.1211-.6367,.4688,0,1.1973,.2793,1.1973,1.4424v2.0244h-.6162v-1.9551c0-.5459-.2031-1.001-.7842-1.001-.4062,0-.7217,.2871-.8262,.6299-.0283,.0781-.042,.1826-.042,.2871v2.0391h-.6162v-2.4727Z" fill="#fff" />
                                </g>
                                <polygon points="901.3854 830.3457 897.6678 828.1993 897.6678 832.4921 901.3854 830.3457" fill="#fff" />
                                <polygon points="865.4629 838.5539 869.1806 840.7002 869.1806 836.4075 865.4629 838.5539" fill="#fff" />
                                <g>
                                    <path d="M547.4346,544.2191l-2.1143,1.8271c-.7998,.6914-.832,1.3398-.4199,1.8164,.458,.5303,1.1006,.5674,1.8896-.1162l2.1152-1.8271,.4023,.4658-2.082,1.8008c-1.0977,.9482-2.0469,.7598-2.7158-.0137-.6318-.7305-.6895-1.6445,.418-2.6016l2.1045-1.8184,.4023,.4668Z" fill="#fff" />
                                    <path d="M548.1397,548.763c.3291-.2842,.585-.5244,.8193-.7451l.3613,.418-.4209,.4014,.0088,.0107c.4775-.0791,.9238,.0625,1.2861,.4814,.5352,.6201,.4141,1.5391-.3652,2.2119-.9219,.7969-1.8633,.6299-2.3848,.0254-.293-.3389-.4004-.7637-.2793-1.1367l-.0098-.0117-1.3936,1.2051-.3975-.4609,2.7754-2.3994Zm-.2852,1.0508c-.0684,.0596-.123,.126-.1719,.1865-.25,.3643-.2305,.8369,.0586,1.1709,.4258,.4932,1.0762,.4307,1.6641-.0771,.5137-.4453,.7207-1.0957,.2949-1.5879-.2754-.3174-.7598-.418-1.1875-.2051-.0713,.0342-.1543,.0781-.2188,.1328l-.4395,.3799Z" fill="#fff" />
                                    <path d="M554.1709,556.0618c.4756-.458,1.0469-1.0068,1.4961-1.3857l-.0137-.0166c-.5234,.2412-1.0977,.4766-1.7461,.7227l-2.2773,.8662-.3027-.3496,1.2021-2.0469c.3564-.6055,.6943-1.1465,1.0312-1.6133l-.0088-.0107c-.4551,.374-1.0762,.8643-1.6387,1.2959l-1.6143,1.2275-.3799-.4395,3.7871-2.8379,.5078,.5889-1.2012,2.1016c-.3105,.5293-.5977,.9902-.8906,1.4004l.0137,.0156c.4375-.2207,.9395-.4326,1.5273-.6641l2.2773-.8564,.5078,.5889-3.3789,3.3086-.3887-.4492,1.4902-1.4463Z" fill="#fff" />
                                    <path d="M554.9229,560.1019l.291-.3174-.0137-.0156c-.3262,.0312-.7441-.0889-1.0605-.4541-.4473-.5195-.3115-1.1006,.0596-1.4209,.6201-.5361,1.4355-.2783,2.2861,.7178l.0527-.0459c.2129-.1826,.543-.5713,.0898-1.0967-.2061-.2383-.4951-.4238-.7676-.502l.2168-.3711c.3203,.0918,.6758,.3223,.9551,.6445,.6777,.7852,.3076,1.4375-.2061,1.8828l-.959,.8281c-.2236,.1934-.4316,.3906-.5791,.5732l-.3652-.4229Zm1.248-1.2012c-.4277-.5176-1.0176-1.0176-1.5156-.5859-.3027,.2598-.2705,.5859-.0645,.8242,.2881,.334,.6826,.3623,.9648,.249,.0605-.0254,.123-.0615,.1699-.1025l.4453-.3848Z" fill="#fff" />
                                    <path d="M555.9014,561.2347l2.5645-2.2168,.4033,.4668-2.5645,2.2158-.4033-.4658Zm3.7344-2.3213c-.1543,.1436-.3828,.1357-.543-.0498-.1426-.1641-.1152-.3906,.043-.5273,.165-.1426,.3975-.1299,.5439,.0391,.1514,.1758,.1201,.3965-.0439,.5381Z" fill="#fff" />
                                    <path d="M558.8428,560.8577c.2656-.2305,.4766-.4238,.6758-.6221l.3574,.4131-.4014,.3926,.0088,.0098c.3545-.082,.8486,.0078,1.2148,.4316,.3066,.3555,.5713,1.0898-.3086,1.8496l-1.5312,1.3232-.4023-.4658,1.4785-1.2773c.4131-.3574,.625-.8086,.2441-1.248-.2656-.3086-.6895-.3594-1.0176-.2139-.0762,.0293-.1641,.0869-.2441,.1562l-1.541,1.333-.4033-.4668,1.8701-1.6152Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M540.5196,549.3665c.2842,.2441,.6045,.5508,.9209,.916,.5723,.6621,.8262,1.2676,.8057,1.832-.0166,.5693-.2705,1.1123-.8486,1.6113-.582,.5039-1.2148,.7354-1.832,.6855-.6211-.0449-1.2773-.4395-1.8789-1.1348-.2832-.3281-.5059-.6172-.6797-.874l3.5127-3.0361Zm-2.7598,3.1914c.0791,.1348,.2197,.3086,.376,.4883,.8516,.9863,1.8652,1.0439,2.8301,.2109,.8477-.7227,.9707-1.6621,.1279-2.6367-.2061-.2383-.3838-.4004-.5146-.5l-2.8193,2.4375Z" fill="#fff" />
                                    <path d="M541.9004,554.3079c.2646-.2305,.4775-.4238,.6768-.6211l.3564,.4121-.4014,.3926,.0098,.0107c.3535-.083,.8477,.0078,1.2148,.4316,.3066,.3545,.5703,1.0889-.3086,1.8486l-1.5312,1.3242-.4033-.4668,1.4775-1.2773c.4141-.3574,.625-.8086,.2461-1.248-.2656-.3076-.6895-.3584-1.0176-.2129-.0762,.0293-.165,.0869-.2441,.1562l-1.542,1.332-.4033-.4668,1.8701-1.6152Z" fill="#fff" />
                                    <path d="M547.7246,561.6556c.4756-.457,1.0459-1.0059,1.4961-1.3857l-.0146-.0166c-.5225,.2412-1.0957,.4775-1.7441,.7227l-2.2793,.8672-.3018-.3496,1.2021-2.0479c.3564-.6045,.6934-1.1455,1.0312-1.6133l-.0098-.0107c-.4541,.374-1.0762,.8652-1.6387,1.2969l-1.6133,1.2275-.3799-.4404,3.7861-2.8379,.5078,.5889-1.2002,2.1025c-.3115,.5293-.5977,.9902-.8916,1.4004l.0137,.0156c.4385-.2207,.9395-.4336,1.5283-.6641l2.2773-.8574,.5088,.5889-3.3799,3.3096-.3887-.4502,1.4902-1.4463Z" fill="#fff" />
                                    <path d="M548.4756,565.6946l.291-.3164-.0137-.0156c-.3262,.0312-.7441-.0889-1.0605-.4551-.4473-.5176-.3105-1.0996,.0605-1.4199,.6191-.5352,1.4346-.2783,2.2852,.7168l.0527-.0449c.2129-.1836,.5439-.5723,.0898-1.0957-.2051-.2393-.4951-.4238-.7676-.5039l.2168-.3711c.3203,.0938,.6758,.3223,.9551,.6455,.6777,.7842,.3076,1.4385-.2061,1.8818l-.959,.8291c-.2236,.1924-.4316,.3906-.5781,.5732l-.3662-.4238Zm1.248-1.1992c-.4277-.5176-1.0176-1.0186-1.5156-.5879-.3018,.2607-.2705,.5859-.0645,.8242,.2881,.334,.6826,.3633,.9648,.25,.0605-.0254,.123-.0605,.1699-.1025l.4453-.3838Z" fill="#fff" />
                                    <path d="M549.4541,566.8275l2.5645-2.2158,.4033,.4658-2.5645,2.2168-.4033-.4668Zm3.7344-2.3203c-.1543,.1426-.3828,.1367-.543-.0488-.1426-.165-.1152-.3906,.0439-.5283,.165-.1416,.3965-.1299,.543,.0391,.1514,.1748,.1201,.3955-.0439,.5381Z" fill="#fff" />
                                    <path d="M552.3955,566.4515c.2656-.2295,.4785-.4229,.6768-.6221l.3564,.4141-.4004,.3926,.0088,.0098c.3545-.083,.8486,.0078,1.2148,.4316,.3066,.3555,.5713,1.0898-.3076,1.8496l-1.5312,1.3232-.4043-.4658,1.4785-1.2783c.4141-.3564,.625-.8086,.2451-1.248-.2656-.3076-.6885-.3584-1.0166-.2129-.0771,.0293-.166,.0869-.2441,.1562l-1.543,1.333-.4023-.4668,1.8691-1.6162Z" fill="#fff" />
                                </g>
                                <polygon points="564.1104 568.1452 563.3034 563.929 560.0556 566.736 564.1104 568.1452" fill="#fff" />
                                <polygon points="534.4106 546.3342 535.2177 550.5504 538.4655 547.7434 534.4106 546.3342" fill="#fff" />
                                <g>
                                    <path d="M261.8174,214.8616l-2.1143,1.8271c-.7998,.6914-.832,1.3398-.4199,1.8164,.4575,.5303,1.1006,.5674,1.8901-.1162l2.1143-1.8271,.4028,.4658-2.0825,1.8008c-1.0972,.9482-2.0464,.7598-2.7153-.0137-.6313-.7305-.6895-1.6445,.418-2.6016l2.104-1.8184,.4028,.4668Z" fill="#fff" />
                                    <path d="M262.523,219.4056c.3286-.2842,.5845-.5244,.8188-.7451l.3613,.418-.4214,.4014,.0093,.0107c.478-.0791,.9238,.0625,1.2861,.4814,.5356,.6201,.4141,1.5391-.3647,2.2119-.9219,.7969-1.8633,.6299-2.3857,.0254-.293-.3389-.4004-.7637-.2788-1.1367l-.0098-.0117-1.3936,1.2051-.3979-.4609,2.7764-2.3994Zm-.2852,1.0508c-.0693,.0596-.123,.126-.1724,.1865-.25,.3643-.23,.8369,.0586,1.1709,.4263,.4932,1.0762,.4307,1.6641-.0771,.5142-.4453,.7202-1.0957,.2944-1.5879-.2749-.3174-.7593-.418-1.1865-.2051-.0718,.0342-.1553,.0781-.2188,.1328l-.4395,.3799Z" fill="#fff" />
                                    <path d="M268.5542,226.7044c.4751-.458,1.0459-1.0068,1.4956-1.3857l-.0137-.0166c-.5234,.2412-1.0972,.4766-1.7456,.7227l-2.2783,.8662-.3018-.3496,1.2017-2.0469c.3569-.6055,.6938-1.1465,1.0312-1.6133l-.0088-.0107c-.4551,.374-1.0767,.8643-1.6392,1.2959l-1.6138,1.2275-.3799-.4395,3.7866-2.8379,.5083,.5889-1.2007,2.1016c-.3115,.5293-.5981,.9902-.8911,1.4004l.0132,.0156c.4385-.2207,.9399-.4326,1.5278-.6641l2.2773-.8564,.5083,.5889-3.3789,3.3086-.3892-.4492,1.4907-1.4463Z" fill="#fff" />
                                    <path d="M269.3057,230.7444l.2905-.3174-.0132-.0156c-.3257,.0312-.7441-.0889-1.0601-.4541-.4482-.5195-.312-1.1006,.0591-1.4209,.6196-.5361,1.4355-.2783,2.2866,.7178l.0527-.0459c.2124-.1826,.543-.5713,.0898-1.0967-.2065-.2383-.4956-.4238-.7681-.502l.2163-.3711c.3208,.0918,.6763,.3223,.9551,.6445,.6782,.7852,.3081,1.4375-.2056,1.8828l-.959,.8281c-.2231,.1934-.4316,.3906-.5786,.5732l-.3657-.4229Zm1.2485-1.2012c-.4287-.5176-1.0181-1.0176-1.5166-.5859-.3018,.2598-.27,.5859-.0645,.8242,.2886,.334,.6831,.3623,.9648,.249,.0615-.0254,.1235-.0615,.1709-.1025l.4453-.3848Z" fill="#fff" />
                                    <path d="M270.2847,231.8773l2.5645-2.2168,.4028,.4668-2.5645,2.2158-.4028-.4658Zm3.7334-2.3213c-.1538,.1436-.3818,.1357-.5425-.0498-.1421-.1641-.1152-.3906,.0435-.5273,.1646-.1426,.397-.1299,.5435,.0391,.1514,.1758,.1201,.3965-.0444,.5381Z" fill="#fff" />
                                    <path d="M273.2256,231.5003c.2651-.2305,.4771-.4238,.6758-.6221l.3569,.4131-.4009,.3926,.0088,.0098c.3545-.082,.8486,.0078,1.2153,.4316,.3066,.3555,.5708,1.0898-.3086,1.8496l-1.5312,1.3232-.4028-.4658,1.478-1.2773c.4136-.3574,.625-.8086,.2451-1.248-.2656-.3086-.6895-.3594-1.0176-.2139-.0767,.0293-.165,.0869-.2441,.1562l-1.542,1.333-.4028-.4668,1.8701-1.6152Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M656.2403,1039.0306c.1631,.4707,.3779,1.2578,.3779,2.1064,0,.9502-.2656,1.7275-.8076,2.2695-.5215,.542-1.2773,.7871-2.0645,.7871-1.2275,0-2.3828-.4902-3.1494-1.3799-.6436-.7568-1.0117-1.7891-1.0117-2.9443,0-.8691,.1836-1.4932,.3164-1.7383l1.002,.3682c-.1631,.3271-.2861,.8379-.2861,1.4619,0,.7363,.2354,1.4111,.6748,1.9219,.5312,.624,1.4209,1.0127,2.3613,1.0127,1.2373,0,1.9531-.6748,1.9531-1.9629,0-.3682-.0713-.6748-.1533-.8799l-1.7275-.3271v1.2168l-.9609-.1943v-2.3721l3.4756,.6543Z" fill="#9d9d9c" />
                                    <path d="M650.6993,1035.5325v1.9121l-1.043-.2041v-5.0195l1.043,.2041v1.9219l5.8477,1.1045v1.1963l-5.8477-1.1152Z" fill="#9d9d9c" />
                                    <path d="M656.5469,1031.3099l-6.8906,.2959v-1.1953l3.5479-.0723c.8584-.0098,1.4922,.0107,2.0342,.0312v-.0205c-.5723-.2148-1.165-.4189-2.0039-.7666l-3.5781-1.5439v-1.2988l3.4756-.0508c.8594-.0098,1.5029,0,2.0762,.0303v-.0205c-.6543-.2246-1.3086-.4492-2.0039-.7256l-3.5479-1.4824v-1.2568l6.8906,3.1074v1.2881l-3.3223,.1025c-.7979,.0205-1.3604,.0098-2.1475-.041v.0303c.7061,.2354,1.3496,.4502,2.0957,.7666l3.374,1.5137v1.3086Z" fill="#9d9d9c" />
                                    <path d="M656.5469,1023.9261l-.7773-.1436-.9814-1.2363c-1.2676-1.5342-2.1572-2.4639-3.0977-2.4639-.4697,0-.8994,.2959-.8994,.9609,0,.5928,.2148,1.0635,.4395,1.3896l-.8584,.2666c-.3379-.501-.583-1.2373-.583-1.9326,0-1.2979,.8076-1.9219,1.7275-1.9219,1.3809,0,2.4541,1.1855,3.5381,2.5352l.46,.6133h.0205v-2.5654l1.0117,.1943v4.3037Z" fill="#9d9d9c" />
                                </g>
                                <g>
                                    <path d="M656.2403,1429.8489c.1631,.4707,.3779,1.2578,.3779,2.1064,0,.9502-.2656,1.7275-.8076,2.2695-.5215,.542-1.2773,.7871-2.0645,.7871-1.2275,0-2.3828-.4902-3.1494-1.3799-.6436-.7568-1.0117-1.7891-1.0117-2.9443,0-.8691,.1836-1.4932,.3164-1.7383l1.002,.3682c-.1631,.3271-.2861,.8379-.2861,1.4619,0,.7363,.2354,1.4111,.6748,1.9219,.5312,.624,1.4209,1.0127,2.3613,1.0127,1.2373,0,1.9531-.6748,1.9531-1.9629,0-.3682-.0713-.6748-.1533-.8799l-1.7275-.3271v1.2168l-.9609-.1943v-2.3721l3.4756,.6543Z" fill="#9d9d9c" />
                                    <path d="M650.6993,1426.3509v1.9121l-1.043-.2041v-5.0195l1.043,.2041v1.9219l5.8477,1.1045v1.1963l-5.8477-1.1152Z" fill="#9d9d9c" />
                                    <path d="M656.5469,1422.1282l-6.8906,.2959v-1.1953l3.5479-.0723c.8584-.0098,1.4922,.0107,2.0342,.0312v-.0205c-.5723-.2148-1.165-.4189-2.0039-.7666l-3.5781-1.5439v-1.2988l3.4756-.0508c.8594-.0098,1.5029,0,2.0762,.0303v-.0205c-.6543-.2246-1.3086-.4492-2.0039-.7256l-3.5479-1.4824v-1.2568l6.8906,3.1074v1.2881l-3.3223,.1025c-.7979,.0205-1.3604,.0098-2.1475-.041v.0303c.7061,.2354,1.3496,.4502,2.0957,.7666l3.374,1.5137v1.3086Z" fill="#9d9d9c" />
                                    <path d="M656.5469,1414.7444l-.7773-.1436-.9814-1.2363c-1.2676-1.5342-2.1572-2.4639-3.0977-2.4639-.4697,0-.8994,.2959-.8994,.9609,0,.5928,.2148,1.0635,.4395,1.3896l-.8584,.2666c-.3379-.501-.583-1.2373-.583-1.9326,0-1.2979,.8076-1.9219,1.7275-1.9219,1.3809,0,2.4541,1.1855,3.5381,2.5352l.46,.6133h.0205v-2.5654l1.0117,.1943v4.3037Z" fill="#9d9d9c" />
                                </g>
                                <g>
                                    <path d="M934.7725,829.6292c.4395-.1016,1.082-.1631,1.6973-.1631,.6641,0,1.2969,.1328,1.7266,.4492,.3887,.2666,.6543,.6855,.6543,1.248,0,1.0117-.7148,1.707-1.5938,1.9727l-.0117,.0303c.4512,.1738,.6445,.6543,.707,1.3086,.0801,.8594,.1328,1.6562,.2441,1.9326h-1.2266c-.0508-.1641-.123-.8184-.1836-1.6455-.0508-.8799-.3691-1.1963-1.002-1.1963h-.584l-.541,2.8418h-1.1758l1.2891-6.7783Zm.6016,3.0264h.6758c.9297,0,1.5547-.5723,1.5547-1.3184,0-.6348-.502-.9209-1.1758-.9209-.2969,0-.4922,.0205-.6348,.0615l-.4199,2.1777Z" fill="#9d9d9c" />
                                    <path d="M940.458,829.6194c.5625-.1025,1.1758-.1533,1.8184-.1533,1.1055,0,1.9746,.2861,2.5254,.8281,.4707,.4287,.7871,1.0732,.7871,2.0234,0,1.2988-.5312,2.4844-1.4004,3.2002-.7969,.6543-1.8086,.9307-3.2207,.9307-.7246,0-1.4004-.0508-1.7891-.1025l1.2793-6.7266Zm.0918,5.8164c.2344,.0312,.4902,.041,.7559,.041,.9297,0,1.6777-.2959,2.1777-.8486,.541-.5723,.8184-1.3896,.8184-2.29,0-1.1445-.6035-1.9111-2.0449-1.9111-.2754,0-.5723,.0205-.7676,.0615l-.9395,4.9473Z" fill="#9d9d9c" />
                                    <path d="M951.3877,836.1009c-.4707,.1631-1.2578,.3779-2.1055,.3779-.9512,0-1.7285-.2656-2.2695-.8076-.543-.5215-.7871-1.2773-.7871-2.0645,0-1.2275,.4902-2.3828,1.3789-3.1494,.7578-.6436,1.7891-1.0117,2.9453-1.0117,.8691,0,1.4922,.1836,1.7383,.3164l-.3691,1.002c-.3262-.1631-.8379-.2861-1.4609-.2861-.7363,0-1.4121,.2354-1.9219,.6748-.625,.5312-1.0117,1.4209-1.0117,2.3613,0,1.2373,.6738,1.9531,1.9629,1.9531,.3672,0,.6738-.0713,.8789-.1533l.3262-1.7275h-1.2168l.1953-.9609h2.3711l-.6543,3.4756Z" fill="#9d9d9c" />
                                    <path d="M955.2842,830.9075h-.0195l-1.2266,.5723-.0723-.9307,1.6973-.7871h.9922l-1.2578,6.6455h-1.1562l1.043-5.5Z" fill="#9d9d9c" />
                                </g>
                                <g>
                                    <path d="M718.7178,829.6292c.4395-.1016,1.083-.1631,1.6973-.1631,.6641,0,1.2979,.1328,1.7275,.4492,.3887,.2666,.6543,.6855,.6543,1.248,0,1.0117-.7158,1.707-1.5947,1.9727l-.0107,.0303c.4502,.1738,.6445,.6543,.7061,1.3086,.0811,.8594,.1328,1.6562,.2451,1.9326h-1.2266c-.0518-.1641-.123-.8184-.1846-1.6455-.0508-.8799-.3682-1.1963-1.002-1.1963h-.583l-.541,2.8418h-1.1758l1.2881-6.7783Zm.6025,3.0264h.6748c.9307,0,1.5547-.5723,1.5547-1.3184,0-.6348-.501-.9209-1.1758-.9209-.2969,0-.4912,.0205-.6338,.0615l-.4199,2.1777Z" fill="#9d9d9c" />
                                    <path d="M724.4034,829.6194c.5625-.1025,1.1758-.1533,1.8193-.1533,1.1045,0,1.9736,.2861,2.5254,.8281,.4707,.4287,.7871,1.0732,.7871,2.0234,0,1.2988-.5312,2.4844-1.4004,3.2002-.7979,.6543-1.8096,.9307-3.2207,.9307-.7256,0-1.4004-.0508-1.7891-.1025l1.2783-6.7266Zm.0918,5.8164c.2354,.0312,.4902,.041,.7568,.041,.9297,0,1.6768-.2959,2.1777-.8486,.541-.5723,.8174-1.3896,.8174-2.29,0-1.1445-.6035-1.9111-2.0449-1.9111-.2754,0-.5723,.0205-.7666,.0615l-.9404,4.9473Z" fill="#9d9d9c" />
                                    <path d="M735.334,836.1009c-.4707,.1631-1.2578,.3779-2.1064,.3779-.9502,0-1.7275-.2656-2.2695-.8076-.542-.5215-.7871-1.2773-.7871-2.0645,0-1.2275,.4902-2.3828,1.3799-3.1494,.7568-.6436,1.7891-1.0117,2.9443-1.0117,.8691,0,1.4932,.1836,1.7383,.3164l-.3682,1.002c-.3271-.1631-.8379-.2861-1.4619-.2861-.7363,0-1.4111,.2354-1.9219,.6748-.624,.5312-1.0117,1.4209-1.0117,2.3613,0,1.2373,.6738,1.9531,1.9629,1.9531,.3672,0,.6738-.0713,.8789-.1533l.3271-1.7275h-1.2168l.1943-.9609h2.3721l-.6543,3.4756Z" fill="#9d9d9c" />
                                    <path d="M739.2305,830.9075h-.0205l-1.2266,.5723-.0713-.9307,1.6963-.7871h.9922l-1.2578,6.6455h-1.1553l1.043-5.5Z" fill="#9d9d9c" />
                                </g>
                                <g>
                                    <path d="M385.2784,361.8323c.3652,.2656,.833,.7119,1.2344,1.1768,.4346,.5029,.748,1.0693,.79,1.6006,.0527,.4678-.0908,.9434-.5166,1.3105-.7656,.6621-1.7588,.5762-2.5352,.085l-.0303,.0117c.1631,.4541-.0732,.916-.5273,1.3906-.5977,.6221-1.166,1.1826-1.3018,1.4482l-.8018-.9277c.0898-.1465,.5371-.6279,1.124-1.2158,.6318-.6133,.6631-1.0615,.249-1.541l-.3818-.4404-2.5029,1.4502-.7695-.8896,5.9697-3.459Zm-1.8945,2.4346l.4414,.5107c.6084,.7041,1.4502,.8018,2.0146,.3135,.4795-.415,.3672-.9814-.0732-1.4912-.1943-.2246-.3359-.3584-.4609-.4395l-1.9219,1.1064Z" fill="#9d9d9c" />
                                    <path d="M389.0049,366.1263c.4453,.3584,.8857,.7891,1.3066,1.2764,.7217,.835,1.0742,1.6797,1.0254,2.4512-.0176,.6377-.2979,1.2979-1.0166,1.9199-.9824,.8496-2.2266,1.2227-3.3369,1.0332-1.0166-.1758-1.8877-.7607-2.8105-1.8281-.4746-.5488-.877-1.0928-1.0928-1.4199l5.9248-3.4326Zm-4.3398,3.874c.1309,.1982,.29,.3975,.4648,.5996,.6074,.7031,1.3203,1.0742,2.0654,1.0918,.7871,.0352,1.5869-.291,2.2676-.8789,.8662-.749,1.0508-1.708,.1084-2.7979-.1797-.208-.3896-.4189-.5479-.54l-4.3584,2.5254Z" fill="#9d9d9c" />
                                    <path d="M391.252,378.6341c-.4316-.249-1.1084-.7041-1.6641-1.3457-.6211-.7188-.9297-1.4805-.874-2.2451,.04-.751,.4521-1.4316,1.0479-1.9463,.9277-.8027,2.1221-1.1875,3.2842-1.0156,.9824,.1504,1.9365,.6914,2.6914,1.5654,.5684,.6572,.8369,1.249,.8975,1.5215l-.999,.377c-.0898-.3535-.3311-.8203-.7393-1.293-.4814-.5566-1.1006-.9141-1.7676-1.0127-.8096-.125-1.7363,.1641-2.4482,.7793-.9355,.8096-1.0371,1.7871-.1934,2.7627,.2402,.2773,.4951,.4629,.6904,.5635l1.5225-.8818-.7959-.9209,.8535-.4814,1.5508,1.7939-3.0566,1.7793Z" fill="#9d9d9c" />
                                    <path d="M391.6563,379.5706l.6816-.3994,1.5508,.293c1.9619,.3311,3.2432,.4521,3.9541-.1631,.3564-.3076,.4873-.8115,.0518-1.3145-.3877-.4482-.8574-.6641-1.2402-.7646l.4746-.7627c.583,.1582,1.25,.5547,1.7051,1.0811,.8486,.9814,.6465,1.9824-.0498,2.584-1.0439,.9023-2.6309,.707-4.334,.3955l-.749-.1631-.0156,.0137,1.6777,1.9404-.8926,.5146-2.8145-3.2549Z" fill="#9d9d9c" />
                                </g>
                                <g>
                                    <path d="M123.7671,17.6829c.1021,.4395,.1636,1.083,.1636,1.6973,0,.6641-.1333,1.2979-.4502,1.7275-.2656,.3887-.6846,.6543-1.2471,.6543-1.0122,0-1.7075-.7158-1.9731-1.5947l-.0308-.0107c-.1738,.4502-.6543,.6445-1.3086,.7061-.8589,.0811-1.6562,.1328-1.9321,.2451v-1.2266c.1636-.0518,.8179-.123,1.646-.1846,.8794-.0508,1.1963-.3682,1.1963-1.002v-.583l-2.8423-.541v-1.1758l6.7783,1.2881Zm-3.0264,.6025v.6748c0,.9307,.5728,1.5547,1.3188,1.5547,.6338,0,.9199-.501,.9199-1.1758,0-.2969-.0205-.4912-.061-.6338l-2.1777-.4199Z" fill="#9d9d9c" />
                                    <path d="M123.7774,23.3685c.1021,.5625,.1533,1.1758,.1533,1.8193,0,1.1045-.2866,1.9736-.8281,2.5254-.4297,.4707-1.0737,.7871-2.0244,.7871-1.2983,0-2.4844-.5312-3.1997-1.4004-.6543-.7979-.9307-1.8096-.9307-3.2207,0-.7256,.0513-1.4004,.1025-1.7891l6.7271,1.2783Zm-5.8174,.0918c-.0308,.2354-.041,.4902-.041,.7568,0,.9297,.2969,1.6768,.8486,2.1777,.5728,.541,1.3906,.8174,2.29,.8174,1.145,0,1.9116-.6035,1.9116-2.0449,0-.2754-.02-.5723-.061-.7666l-4.9482-.9404Z" fill="#9d9d9c" />
                                    <path d="M117.2954,34.2991c-.1636-.4707-.3784-1.2578-.3784-2.1064,0-.9502,.2661-1.7275,.8076-2.2695,.5215-.542,1.2783-.7871,2.0654-.7871,1.2266,0,2.3818,.4902,3.1489,1.3799,.644,.7568,1.0117,1.7891,1.0117,2.9443,0,.8691-.1836,1.4932-.3169,1.7383l-1.002-.3682c.1641-.3271,.2866-.8379,.2866-1.4619,0-.7363-.2354-1.4111-.6748-1.9219-.5317-.624-1.4209-1.0117-2.3618-1.0117-1.2368,0-1.9526,.6738-1.9526,1.9629,0,.3672,.0718,.6738,.1533,.8789l1.728,.3271v-1.2168l.9609,.1943v2.3721l-3.4761-.6543Z" fill="#9d9d9c" />
                                    <path d="M116.9888,35.2718l.7769,.1436,.9814,1.2363c1.2676,1.5342,2.1572,2.4639,3.0977,2.4639,.4702,0,.8999-.2959,.8999-.9609,0-.5928-.2148-1.0635-.4399-1.3896l.8589-.2666c.3374,.501,.583,1.2373,.583,1.9326,0,1.2979-.8076,1.9219-1.728,1.9219-1.3799,0-2.4536-1.1855-3.5371-2.5352l-.46-.6133h-.0205v2.5654l-1.0122-.1943v-4.3037Z" fill="#9d9d9c" />
                                </g>
                                <g>
                                    <path d="M649.0938,719.3646c-.1016-.4395-.1631-1.083-.1631-1.6973,0-.6641,.1328-1.2979,.4492-1.7275,.2666-.3887,.6855-.6543,1.248-.6543,1.0117,0,1.707,.7158,1.9727,1.5947l.0303,.0107c.1738-.4502,.6543-.6445,1.3086-.7061,.8594-.0811,1.6562-.1328,1.9326-.2451v1.2266c-.1641,.0518-.8184,.123-1.6455,.1846-.8799,.0508-1.1963,.3682-1.1963,1.002v.583l2.8418,.541v1.1758l-6.7783-1.2881Zm3.0264-.6025v-.6748c0-.9307-.5723-1.5547-1.3184-1.5547-.6348,0-.9209,.501-.9209,1.1758,0,.2969,.0205,.4912,.0615,.6338l2.1777,.4199Z" fill="#9d9d9c" />
                                    <path d="M649.084,713.679c-.1025-.5625-.1533-1.1758-.1533-1.8193,0-1.1045,.2861-1.9736,.8281-2.5254,.4287-.4707,1.0732-.7871,2.0234-.7871,1.2988,0,2.4844,.5312,3.2002,1.4004,.6543,.7979,.9307,1.8096,.9307,3.2207,0,.7256-.0508,1.4004-.1025,1.7891l-6.7266-1.2783Zm5.8164-.0918c.0312-.2354,.041-.4902,.041-.7568,0-.9297-.2959-1.6768-.8486-2.1777-.5723-.541-1.3896-.8174-2.29-.8174-1.1445,0-1.9111,.6035-1.9111,2.0449,0,.2754,.0205,.5723,.0615,.7666l4.9473,.9404Z" fill="#9d9d9c" />
                                    <path d="M655.5655,702.7484c.1631,.4707,.3779,1.2578,.3779,2.1064,0,.9502-.2656,1.7275-.8076,2.2695-.5215,.542-1.2773,.7871-2.0645,.7871-1.2275,0-2.3828-.4902-3.1494-1.3799-.6436-.7568-1.0117-1.7891-1.0117-2.9443,0-.8691,.1836-1.4932,.3164-1.7383l1.002,.3682c-.1631,.3271-.2861,.8379-.2861,1.4619,0,.7363,.2354,1.4111,.6748,1.9219,.5312,.624,1.4209,1.0117,2.3613,1.0117,1.2373,0,1.9531-.6738,1.9531-1.9629,0-.3672-.0713-.6738-.1533-.8789l-1.7275-.3271v1.2168l-.9609-.1943v-2.3721l3.4756,.6543Z" fill="#9d9d9c" />
                                    <path d="M655.8721,701.7757l-.7773-.1436-.9814-1.2363c-1.2676-1.5342-2.1572-2.4639-3.0977-2.4639-.4697,0-.8994,.2959-.8994,.9609,0,.5928,.2148,1.0635,.4395,1.3896l-.8584,.2666c-.3379-.501-.583-1.2373-.583-1.9326,0-1.2979,.8076-1.9219,1.7275-1.9219,1.3809,0,2.4541,1.1855,3.5381,2.5352l.46,.6133h.0205v-2.5654l1.0117,.1943v4.3037Z" fill="#9d9d9c" />
                                </g>
                                <g>
                                    <path d="M254.9024,220.0091c.2842,.2441,.605,.5508,.9209,.916,.5723,.6621,.8262,1.2676,.8057,1.832-.0171,.5693-.2705,1.1123-.8481,1.6113-.5825,.5039-1.2153,.7354-1.8325,.6855-.6216-.0449-1.2778-.4395-1.8784-1.1348-.2832-.3281-.5059-.6172-.6807-.874l3.5132-3.0361Zm-2.7598,3.1914c.0791,.1348,.2202,.3086,.376,.4883,.8516,.9863,1.8652,1.0439,2.8296,.2109,.8477-.7227,.9707-1.6621,.1284-2.6367-.2065-.2383-.3838-.4004-.5151-.5l-2.8188,2.4375Z" fill="#fff" />
                                    <path d="M256.2832,224.9505c.2651-.2305,.4775-.4238,.6763-.6211l.3569,.4121-.4009,.3926,.0088,.0107c.354-.083,.8486,.0078,1.2148,.4316,.3066,.3545,.5713,1.0889-.3081,1.8486l-1.5312,1.3242-.4033-.4668,1.478-1.2773c.4136-.3574,.625-.8086,.2451-1.248-.2656-.3076-.689-.3584-1.0171-.2129-.0767,.0293-.165,.0869-.2441,.1562l-1.542,1.332-.4033-.4668,1.8701-1.6152Z" fill="#fff" />
                                    <path d="M262.1075,232.2982c.4751-.457,1.0464-1.0059,1.4961-1.3857l-.0142-.0166c-.5229,.2412-1.0967,.4775-1.7451,.7227l-2.2783,.8672-.3022-.3496,1.2021-2.0479c.3564-.6045,.6934-1.1455,1.0312-1.6133l-.0093-.0107c-.4546,.374-1.0762,.8652-1.6392,1.2969l-1.6133,1.2275-.3799-.4404,3.7861-2.8379,.5083,.5889-1.2007,2.1025c-.3115,.5293-.5977,.9902-.8911,1.4004l.0137,.0156c.438-.2207,.9395-.4336,1.5278-.6641l2.2773-.8574,.5083,.5889-3.3794,3.3096-.3887-.4502,1.4902-1.4463Z" fill="#fff" />
                                    <path d="M262.8584,236.3372l.2905-.3164-.0132-.0156c-.3257,.0312-.7441-.0889-1.0601-.4551-.4482-.5176-.3115-1.0996,.0596-1.4199,.6196-.5352,1.4351-.2783,2.2861,.7168l.0527-.0449c.2124-.1836,.5435-.5723,.0898-1.0957-.2061-.2393-.4956-.4238-.7681-.5039l.2163-.3711c.3208,.0938,.6763,.3223,.9556,.6455,.6782,.7842,.3076,1.4385-.2061,1.8818l-.959,.8291c-.2231,.1924-.4316,.3906-.5781,.5732l-.3662-.4238Zm1.2485-1.1992c-.4287-.5176-1.0176-1.0186-1.5161-.5879-.3018,.2607-.2705,.5859-.0649,.8242,.2886,.334,.6831,.3633,.9648,.25,.0615-.0254,.1235-.0605,.1709-.1025l.4453-.3838Z" fill="#fff" />
                                    <path d="M263.8374,237.47l2.5645-2.2158,.4028,.4658-2.5645,2.2168-.4028-.4668Zm3.7334-2.3203c-.1533,.1426-.3818,.1367-.542-.0488-.1426-.165-.1152-.3906,.0435-.5283,.1646-.1416,.3965-.1299,.543,.0391,.1514,.1748,.1201,.3955-.0444,.5381Z" fill="#fff" />
                                    <path d="M266.7788,237.0941c.2651-.2295,.4775-.4229,.6763-.6221l.3569,.4141-.4009,.3926,.0088,.0098c.354-.083,.8486,.0078,1.2148,.4316,.3066,.3555,.5713,1.0898-.3081,1.8496l-1.5312,1.3232-.4033-.4658,1.478-1.2783c.4136-.3564,.625-.8086,.2451-1.248-.2656-.3076-.689-.3584-1.0171-.2129-.0767,.0293-.165,.0869-.2441,.1562l-1.542,1.333-.4033-.4668,1.8701-1.6162Z" fill="#fff" />
                                </g>
                                <polygon points="278.4937 238.7875 277.6867 234.5713 274.4389 237.3783 278.4937 238.7875" fill="#fff" />
                                <polygon points="248.7939 216.9765 249.601 221.1927 252.8488 218.3857 248.7939 216.9765" fill="#fff" />
                            </g>
                            <g id="points-number">
                                <g>
                                    <path d="M649.5059,783.3206l.0107-1.0615-1.8115-.0176,.0029-.3477,1.7627-2.4717,.5693,.0049-.0225,2.4219,.5459,.0059-.0039,.4141-.5459-.0059-.0107,1.0615-.4971-.0039Zm.0146-1.4756l.0117-1.3008c.002-.2041,.0098-.4082,.0234-.6113h-.0176c-.1221,.2256-.2197,.3926-.3291,.5713l-.9658,1.3164v.0117l1.2773,.0127Z" fill="#fff" />
                                    <path d="M651.084,782.7298c.1494,.0967,.4961,.251,.8623,.2539,.6768,.0068,.8906-.4238,.8877-.7471,0-.5459-.4893-.7842-.999-.7891l-.2939-.0029,.0039-.3965,.2939,.0029c.3838,.0039,.8711-.1885,.875-.6504,.0029-.3115-.1924-.5898-.6777-.5947-.3115-.0029-.6133,.1318-.7822,.25l-.1348-.3848c.2051-.1475,.6025-.2939,1.0225-.29,.7676,.0068,1.1113,.4668,1.1064,.9404-.0039,.4014-.2461,.7402-.7275,.9102v.0127c.4785,.0996,.8643,.4629,.8594,1.0088-.0059,.624-.4961,1.165-1.4316,1.1562-.4385-.0039-.8213-.1465-1.0117-.2744l.1475-.4053Z" fill="#fff" />
                                    <path d="M654.3409,782.9944c.1133,.0137,.2451,.002,.4248-.0137,.3076-.0391,.5957-.1621,.8193-.3701,.2598-.2314,.4482-.5723,.5254-1.0322h-.0186c-.2188,.2607-.5312,.4141-.9199,.4111-.7021-.0068-1.1465-.54-1.1406-1.2051,.0078-.7383,.5469-1.3799,1.3447-1.373,.7969,.0078,1.2822,.6602,1.2725,1.6562-.0078,.8574-.3018,1.4541-.6895,1.8213-.3027,.291-.7158,.4678-1.1367,.5117-.1924,.0273-.3604,.0332-.4854,.0254l.0039-.4316Zm.2129-2.2412c-.0049,.4854,.2861,.8301,.7422,.835,.3525,.0029,.6289-.168,.7705-.4004,.0293-.0479,.0488-.1074,.0498-.1914,.0059-.666-.2354-1.1787-.7861-1.1836-.4502-.0039-.7715,.3887-.7764,.9404Z" fill="#fff" />
                                    <path d="M659.7168,781.4261c-.0127,1.3252-.5107,2.0527-1.375,2.0449-.7617-.0078-1.2705-.7266-1.2705-2.0156,.0127-1.3076,.583-2.0215,1.375-2.0146,.8213,.0078,1.2822,.7441,1.2705,1.9854Zm-2.1182,.04c-.0098,1.0127,.2979,1.5918,.7773,1.5967,.54,.0049,.8037-.6221,.8135-1.6172,.0088-.96-.2314-1.5918-.7773-1.5967-.4619-.0049-.8027,.5557-.8135,1.6172Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M646.9639,1281.4896l.0107-1.0615-1.8115-.0176,.0029-.3477,1.7627-2.4717,.5693,.0049-.0225,2.4219,.5459,.0059-.0039,.4141-.5459-.0059-.0107,1.0615-.4971-.0039Zm.0146-1.4756l.0117-1.3008c.002-.2041,.0098-.4082,.0234-.6113h-.0176c-.1221,.2256-.2197,.3926-.3291,.5713l-.9658,1.3164v.0117l1.2773,.0127Z" fill="#fff" />
                                    <path d="M650.669,1277.9974c-.1074-.0068-.2451-.002-.3965,.0205-.8281,.1299-1.2715,.7314-1.3682,1.3721h.0186c.1875-.2432,.5137-.4443,.9453-.4395,.6895,.0059,1.1719,.5078,1.1641,1.2695-.0068,.7139-.499,1.3691-1.3086,1.3613-.833-.0078-1.373-.6611-1.3633-1.6738,.0068-.7676,.2881-1.3711,.6758-1.752,.3271-.3135,.7607-.5078,1.2539-.5625,.1553-.0234,.2871-.0283,.3828-.0273l-.0039,.4316Zm-.1709,2.248c.0049-.5576-.3096-.8965-.7959-.9014-.3164-.0029-.6123,.1924-.7598,.4727-.0361,.0596-.0615,.1377-.0615,.2334,.0049,.6416,.2949,1.1182,.8467,1.123,.4561,.0049,.7646-.3701,.7705-.9277Z" fill="#fff" />
                                    <path d="M651.7989,1281.1634c.1133,.0137,.2451,.002,.4248-.0137,.3076-.0391,.5957-.1621,.8193-.3701,.2598-.2314,.4482-.5723,.5254-1.0322h-.0186c-.2188,.2607-.5312,.4141-.9199,.4111-.7021-.0068-1.1465-.54-1.1406-1.2051,.0078-.7383,.5469-1.3799,1.3447-1.373,.7969,.0078,1.2822,.6602,1.2725,1.6562-.0078,.8574-.3018,1.4541-.6895,1.8213-.3027,.291-.7158,.4678-1.1367,.5117-.1924,.0273-.3604,.0332-.4854,.0254l.0039-.4316Zm.2129-2.2412c-.0049,.4854,.2861,.8301,.7422,.835,.3525,.0029,.6289-.168,.7705-.4004,.0293-.0479,.0488-.1074,.0498-.1914,.0059-.666-.2354-1.1787-.7861-1.1836-.4502-.0039-.7715,.3887-.7764,.9404Z" fill="#fff" />
                                    <path d="M657.1748,1279.595c-.0127,1.3252-.5107,2.0527-1.375,2.0449-.7617-.0078-1.2705-.7266-1.2705-2.0156,.0127-1.3076,.583-2.0215,1.375-2.0146,.8213,.0078,1.2822,.7441,1.2705,1.9854Zm-2.1182,.04c-.0098,1.0127,.2979,1.5918,.7773,1.5967,.54,.0049,.8037-.6221,.8135-1.6172,.0088-.96-.2314-1.5918-.7773-1.5967-.4619-.0049-.8027,.5557-.8135,1.6172Z" fill="#fff" />
                                    <path d="M658.4951,1280.3275l-.4326,1.2676-.5391-.0059,1.4121-4.0283,.6289,.0059,1.3408,4.0547-.5576-.0049-.4189-1.2754-1.4336-.0137Zm1.3291-.3945l-.3848-1.168c-.0869-.2646-.1455-.5049-.2031-.7393h-.0117c-.0625,.2393-.1299,.4834-.2109,.7295l-.4062,1.166,1.2168,.0117Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M647.7119,1238.4603l.0098-1.0605-1.8105-.0176,.002-.3477,1.7637-2.4727,.5684,.0059-.0215,2.4219,.5449,.0059-.0039,.4141-.5449-.0059-.0117,1.0605-.4961-.0039Zm.0137-1.4746l.0117-1.3008c.002-.2051,.0098-.4082,.0234-.6113h-.0176c-.1211,.2246-.2188,.3926-.3281,.5703l-.9668,1.3164v.0117l1.2773,.0137Z" fill="#fff" />
                                    <path d="M651.417,1234.9681c-.1074-.0059-.2461-.002-.3965,.0215-.8281,.1289-1.2715,.7305-1.3691,1.3711h.0195c.1875-.2422,.5137-.4434,.9453-.4395,.6895,.0059,1.1719,.5078,1.1641,1.2695-.0078,.7148-.5,1.3691-1.3086,1.3613-.834-.0078-1.373-.6602-1.3633-1.6738,.0059-.7676,.2871-1.3711,.6758-1.752,.3262-.3125,.7598-.5078,1.2539-.5625,.1543-.0234,.2871-.0273,.3828-.0273l-.0039,.4316Zm-.1719,2.248c.0059-.5566-.3086-.8965-.7949-.9004-.3164-.0039-.6133,.1914-.7598,.4727-.0371,.0586-.0625,.1367-.0625,.2324,.0059,.6426,.2949,1.1191,.8477,1.123,.4551,.0059,.7637-.3691,.7695-.9277Z" fill="#fff" />
                                    <path d="M652.5459,1238.1341c.1133,.0137,.2461,.002,.4258-.0137,.3066-.0391,.5957-.1621,.8184-.3691,.2598-.2324,.4492-.5723,.5254-1.0332h-.0176c-.2188,.2617-.5312,.4141-.9199,.4121-.7031-.0078-1.1465-.541-1.1406-1.2051,.0078-.7383,.5469-1.3809,1.3438-1.373s1.2832,.6602,1.2734,1.6562c-.0078,.8574-.3027,1.4531-.6895,1.8203-.3027,.291-.7168,.4688-1.1367,.5117-.1934,.0273-.3613,.0332-.4863,.0254l.0039-.4316Zm.2129-2.2402c-.0039,.4844,.2871,.8301,.7422,.834,.3535,.0039,.6289-.168,.7715-.4004,.0293-.0469,.0488-.1074,.0488-.1914,.0059-.666-.2344-1.1777-.7852-1.1836-.4512-.0039-.7715,.3887-.7773,.9414Z" fill="#fff" />
                                    <path d="M657.9229,1236.5657c-.0137,1.3262-.5117,2.0527-1.375,2.0449-.7617-.0078-1.2715-.7266-1.2715-2.0156,.0137-1.3066,.584-2.0215,1.375-2.0137,.8223,.0078,1.2832,.7441,1.2715,1.9844Zm-2.1191,.041c-.0098,1.0117,.2988,1.5918,.7773,1.5957,.541,.0059,.8047-.6211,.8145-1.6172,.0078-.959-.2324-1.5918-.7773-1.5957-.4629-.0059-.8027,.5547-.8145,1.6172Z" fill="#fff" />
                                    <path d="M658.6143,1234.5755c.2285-.0449,.5879-.0781,.9551-.0742,.5215,.0059,.8555,.0977,1.1055,.3027,.209,.1602,.332,.4004,.3301,.7188-.0039,.3887-.2656,.7285-.6914,.8809l-.002,.0117c.3848,.0996,.8301,.4219,.8242,1.0215-.0039,.3477-.1426,.6094-.3496,.8066-.2832,.2539-.7402,.3711-1.4004,.3633-.3594-.002-.6348-.0293-.8086-.0547l.0371-3.9766Zm.4883,3.5977c.1016,.0176,.252,.0254,.4375,.0273,.5391,.0059,1.0391-.1875,1.0449-.7754,.0059-.5508-.4668-.7832-1.0371-.7891l-.4316-.0039-.0137,1.541Zm.0176-1.9375l.4746,.0039c.5508,.0059,.8789-.2773,.8828-.668,.0039-.4746-.3555-.6641-.8828-.668-.2402-.002-.377,.0137-.4609,.0312l-.0137,1.3008Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M648.7793,793.3187l.0107-1.0615-1.8115-.0176,.0029-.3477,1.7627-2.4717,.5693,.0049-.0225,2.4219,.5459,.0059-.0039,.4141-.5459-.0059-.0107,1.0615-.4971-.0039Zm.0146-1.4756l.0117-1.3008c.002-.2041,.0098-.4082,.0234-.6113h-.0176c-.1221,.2256-.2197,.3926-.3291,.5713l-.9658,1.3164v.0117l1.2773,.0127Z" fill="#fff" />
                                    <path d="M650.3575,792.7278c.1494,.0967,.4961,.251,.8623,.2539,.6768,.0068,.8906-.4238,.8877-.7471,0-.5459-.4893-.7842-.999-.7891l-.2939-.0029,.0039-.3965,.2939,.0029c.3838,.0039,.8711-.1885,.875-.6504,.0029-.3115-.1924-.5898-.6777-.5947-.3115-.0029-.6133,.1318-.7822,.25l-.1348-.3848c.2051-.1475,.6025-.2939,1.0225-.29,.7676,.0068,1.1113,.4668,1.1064,.9404-.0039,.4014-.2461,.7402-.7275,.9102v.0127c.4785,.0996,.8643,.4629,.8594,1.0088-.0059,.624-.4961,1.165-1.4316,1.1562-.4385-.0039-.8213-.1465-1.0117-.2744l.1475-.4053Z" fill="#fff" />
                                    <path d="M653.2657,792.3714c.0049-.4922,.3018-.8369,.7832-1.0361l-.0059-.0176c-.4297-.209-.6113-.5459-.6084-.8818,.0059-.6172,.5312-1.0322,1.2148-1.0264,.7549,.0078,1.1279,.4844,1.124,.9707-.0039,.3291-.1689,.6826-.6504,.9053v.0176c.4834,.1973,.7793,.542,.7754,1.0156-.0068,.6777-.5928,1.1279-1.335,1.1211-.8154-.0078-1.3037-.499-1.2979-1.0684Zm2.0977-.0039c.0049-.4736-.3223-.7051-.8477-.8604-.457,.1279-.7061,.4248-.709,.7969-.0225,.3955,.2734,.7471,.7715,.752,.4727,.0049,.7812-.2861,.7852-.6885Zm-1.4316-1.9629c-.0039,.3896,.2881,.6025,.7373,.7266,.3359-.1104,.5967-.3477,.5996-.7012,.0029-.3125-.1807-.6377-.6533-.6426-.4375-.0039-.6807,.2812-.6836,.6172Z" fill="#fff" />
                                    <path d="M656.6895,793.0218c.1143,.0137,.2461,.002,.4258-.0137,.3076-.0391,.5957-.1621,.8203-.3701,.2598-.2314,.4482-.5723,.5254-1.0322h-.0186c-.2188,.2607-.5312,.4141-.9209,.4111-.7021-.0068-1.1465-.54-1.1406-1.2051,.0078-.7383,.5469-1.3799,1.3447-1.373,.7979,.0078,1.2832,.6602,1.2734,1.6562-.0078,.8574-.3018,1.4541-.6895,1.8213-.3027,.291-.7168,.4678-1.1377,.5117-.1924,.0273-.3604,.0332-.4863,.0254l.0039-.4316Zm.2139-2.2412c-.0049,.4854,.2861,.8301,.7422,.835,.3535,.0029,.6299-.168,.7715-.4004,.0293-.0479,.0488-.1074,.0498-.1914,.0059-.666-.2354-1.1787-.7871-1.1836-.4502-.0039-.7715,.3887-.7764,.9404Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M647.6231,758.1712l.7979-.7012-1.1934-1.3613,.2617-.2285,3.0176-.332,.375,.4277-1.8223,1.5957,.3604,.4102-.3115,.2734-.3594-.4102-.7988,.6992-.3271-.373Zm1.1094-.9727l.9785-.8574c.1533-.1348,.3105-.2656,.4717-.3906l-.0117-.0137c-.25,.0605-.4395,.0996-.6465,.1367l-1.625,.1562-.0088,.0078,.8418,.9609Z" fill="#fff" />
                                    <path d="M649.1143,758.9544c.0273,.1758,.1436,.5371,.3848,.8125,.4473,.5078,.9102,.3809,1.1494,.1641,.4072-.3652,.2588-.8887-.0771-1.2715l-.1934-.2207,.2969-.2617,.1943,.2207c.2529,.2891,.7217,.5234,1.0684,.2188,.2344-.2051,.3115-.5352-.0088-.9004-.2051-.2344-.5059-.3691-.707-.416l.1973-.3574c.2471,.0547,.6201,.252,.8965,.5684,.5068,.5781,.3926,1.1387,.0371,1.4512-.3027,.2656-.7168,.3105-1.1641,.0645l-.0088,.0078c.2432,.4238,.2305,.9551-.1807,1.3145-.4688,.4102-1.1992,.4062-1.8154-.2969-.2891-.3301-.4385-.7109-.4697-.9375l.4004-.1602Z" fill="#fff" />
                                    <path d="M651.0879,761.5579c.0654,.0938,.1611,.1855,.293,.3086,.2334,.2031,.5186,.3359,.8232,.3633,.3447,.041,.7246-.0449,1.1191-.2949l-.0117-.0137c-.3418,.0117-.6641-.1191-.9199-.4121-.4629-.5293-.3633-1.2148,.1367-1.6523,.5557-.4883,1.3945-.5137,1.9199,.0859s.3633,1.3965-.3857,2.0527c-.6445,.5664-1.2861,.7441-1.8174,.7012-.4189-.0332-.8271-.2246-1.1406-.5078-.1484-.125-.2637-.248-.3418-.3457l.3252-.2852Zm1.8115-1.334c-.3652,.3203-.4277,.7656-.127,1.1094,.2324,.2656,.5449,.3574,.8125,.3086,.0557-.0098,.1133-.0352,.1758-.0898,.501-.4395,.7217-.9609,.3584-1.375-.2969-.3398-.8037-.3164-1.2197,.0469Z" fill="#fff" />
                                    <path d="M655.9512,762.5032l-.0078-.0098-.7217-.2676,.2344-.3418,.9043,.3398,.2959,.3379-2.9307,2.5703-.3359-.3828,2.5615-2.2461Z" fill="#fff" />
                                    <path d="M656.1729,765.9954l-1.2324,.5215-.3555-.4043,3.9453-1.6328,.4141,.4727-2.1299,3.7031-.3682-.4199,.6719-1.1621-.9453-1.0781Zm1.1797,.7266l.6152-1.0645c.1387-.2402,.2793-.4453,.416-.6445l-.0078-.0078c-.2207,.1133-.4473,.2246-.6846,.3281l-1.1406,.4746,.8018,.9141Z" fill="#fff" />
                                    <path d="M660.2061,766.4739c.1855,.1387,.4492,.3867,.6914,.6621,.3438,.3906,.4971,.7031,.5098,1.0273,.0215,.2598-.0762,.5137-.3145,.7227-.293,.2559-.7197,.2891-1.1182,.0703l-.0088,.0078c.1816,.3516,.2373,.9004-.2129,1.2949-.2617,.2285-.5508,.2988-.834,.2773-.3799-.041-.7715-.3066-1.2051-.8008-.2383-.2715-.4004-.4941-.498-.6406l2.9902-2.6211Zm-2.3574,2.7598c.0537,.0879,.1484,.2051,.2705,.3438,.3564,.4062,.833,.6523,1.2744,.2637,.416-.3633,.2734-.8711-.1016-1.2988l-.2852-.3242-1.1582,1.0156Zm1.457-1.2773l.3125,.3574c.3633,.4141,.793,.4688,1.0859,.2109,.3574-.3125,.2588-.7051-.0889-1.1016-.1582-.1816-.2627-.2734-.3311-.3242l-.9785,.8574Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M624.6065,781.2415l-.8398-.6484-1.1055,1.4355-.2754-.2129-.9102-2.8965,.3477-.4512,1.918,1.4785,.334-.4326,.3281,.2529-.334,.4326,.8418,.6475-.3047,.3945Zm-1.168-.9004l-1.0312-.7939c-.1621-.125-.3184-.2549-.4727-.3877l-.0117,.0137c.1074,.2344,.1816,.4131,.2578,.6084l.4688,1.5635,.0098,.0078,.7793-1.0117Z" fill="#fff" />
                                    <path d="M625.0869,779.6263c.168-.0605,.5-.2441,.7227-.5342,.4141-.5361,.1992-.9668-.0605-1.1592-.4375-.3281-.9219-.082-1.2324,.3223l-.1797,.2324-.3145-.2422,.1797-.2324c.2344-.3047,.375-.8086,.0078-1.0918-.2461-.1895-.5859-.2012-.8828,.1836-.1895,.2461-.2637,.5684-.2715,.7744l-.3887-.124c.0078-.2539,.1289-.6582,.3848-.9912,.4688-.6084,1.043-.6045,1.418-.3154,.3184,.2441,.4434,.6426,.2871,1.1289l.0117,.0078c.3672-.3223,.8906-.4111,1.3223-.0781,.4941,.3809,.6309,1.0986,.0605,1.8398-.2676,.3477-.6133,.5664-.8301,.6416l-.2344-.3623Z" fill="#fff" />
                                    <path d="M627.2608,777.1868c.0801-.082,.1504-.1934,.2461-.3467,.1523-.2686,.2285-.5732,.1973-.8779-.0273-.3457-.1855-.7031-.5059-1.041l-.0117,.0146c.0781,.332,.0117,.6738-.2266,.9824-.4277,.5566-1.1211,.5898-1.6484,.1836-.584-.4502-.7715-1.2686-.2852-1.8994,.4863-.6328,1.2988-.626,2.0879-.0176,.6797,.5225,.9785,1.1172,1.0391,1.6475,.0508,.417-.0586,.8545-.2773,1.2158-.0938,.1699-.1914,.3076-.2734,.4023l-.3418-.2637Zm-1.6602-1.5195c.3848,.2959,.8359,.2715,1.1133-.0898,.2168-.2793,.2461-.6035,.1465-.8564-.0215-.0527-.0566-.1035-.123-.1553-.5273-.4062-1.082-.5225-1.418-.0859-.2754,.3574-.1543,.8506,.2812,1.1875Z" fill="#fff" />
                                    <path d="M628.8428,774.7503c.168-.0605,.5-.2441,.7227-.5342,.4141-.5361,.1992-.9668-.0605-1.1602-.4355-.3281-.9199-.0811-1.2324,.3223l-.1797,.2334-.3125-.2422,.1797-.2334c.2324-.3037,.373-.8086,.0078-1.0898-.248-.1904-.5879-.2021-.8828,.1826-.1914,.2471-.2637,.5684-.2715,.7744l-.3887-.125c.0059-.252,.1289-.6582,.3848-.9902,.4688-.6074,1.041-.6055,1.416-.3164,.3184,.2461,.4434,.6436,.2891,1.1289l.0098,.0078c.3691-.3203,.8906-.4102,1.3223-.0771,.4961,.3809,.6309,1.0986,.0605,1.8398-.2676,.3467-.6113,.5664-.8281,.6416l-.2363-.3623Z" fill="#fff" />
                                    <path d="M630.6299,771.3402l.752,1.1084-.3301,.4268-2.3633-3.5537,.3828-.498,4.0449,1.373-.3398,.4414-1.2715-.4336-.875,1.1357Zm.4863-1.2979l-1.1621-.3984c-.2656-.0898-.4902-.1875-.7129-.2832l-.0078,.0098c.1543,.1934,.3086,.3955,.4551,.6074l.6855,1.0273,.7422-.9629Z" fill="#fff" />
                                    <path d="M630.3194,767.2903c.1016-.209,.293-.5156,.5176-.8057,.3184-.4131,.5938-.624,.9082-.7002,.252-.0703,.5195-.0234,.7715,.1699,.3086,.2383,.4219,.6504,.2852,1.083l.0098,.0068c.3105-.2451,.8359-.4062,1.3105-.041,.2754,.2129,.4004,.4824,.4336,.7656,.0332,.3799-.1504,.8145-.5527,1.3359-.2207,.2861-.4082,.4883-.5332,.6123l-3.1504-2.4268Zm1.6309,.5977l.2891-.375c.3359-.4375,.3066-.8691-.002-1.1074-.375-.2891-.7422-.1172-1.0645,.3008-.1465,.1895-.2168,.3096-.2539,.3867l1.0312,.7949Zm1.5352,1.1816c.0762-.0693,.1719-.1846,.2852-.332,.3301-.4277,.4766-.9424,.0117-1.3008-.4375-.3369-.9082-.1006-1.2539,.3506l-.2637,.3418,1.2207,.9404Z" fill="#fff" />
                                </g>
                            </g>
                            <g id="feltham-lx-group">
                                <g id="feltham-lx-group-2" data-name="feltham-lx-group">
                                    <g id="lx-001">
                                        <g>
                                            <g id="LWPOLYLINE">
                                                <polygon points="667.6672 722.9644 652.4468 728.0605 637.2264 722.9645 633.0604 724.9199 635.2091 728.9896 642.4404 731.4108 635.2091 733.832 633.0605 737.9017 637.2264 739.8571 652.4468 734.7611 667.6672 739.8571 671.8332 737.9016 669.6845 733.8319 662.4532 731.4108 669.6845 728.9896 671.8332 724.9199 667.6672 722.9644" fill="#9d9d9c" />
                                            </g>
                                            <g id="LWPOLYLINE-2" data-name="LWPOLYLINE">
                                                <polygon points="652.4468 729.9684 637.4565 724.9495 636.588 727.5434 648.1389 731.4108 636.588 735.2782 637.4565 737.8721 652.4468 732.8531 667.4371 737.8721 668.3056 735.2782 656.7547 731.4108 668.3056 727.5433 667.4371 724.9494 652.4468 729.9684" fill="#fff" />
                                            </g>
                                        </g>
                                        <g>
                                            <path d="M596.2178,732.3509l-1.1973-4.7207h.6436l.5605,2.3887c.1406,.5879,.2666,1.1758,.3506,1.6318h.0137c.0771-.4697,.2246-1.0303,.3857-1.6396l.6299-2.3809h.6377l.5742,2.3955c.1328,.5596,.2588,1.1201,.3291,1.6172h.0137c.0986-.5176,.2314-1.043,.3779-1.6318l.624-2.3809h.623l-1.3379,4.7207h-.6367l-.5957-2.459c-.1475-.6016-.2451-1.0645-.3086-1.54h-.0137c-.084,.4688-.1895,.9307-.3643,1.54l-.6719,2.459h-.6377Z" fill="#fff" />
                                            <path d="M604.1856,730.6273c0,1.2539-.8682,1.8008-1.6885,1.8008-.917,0-1.624-.6729-1.624-1.7441,0-1.1348,.7422-1.7998,1.6807-1.7998,.9736,0,1.6318,.707,1.6318,1.7432Zm-2.6895,.0352c0,.7432,.4268,1.3027,1.0293,1.3027,.5889,0,1.0293-.5527,1.0293-1.3164,0-.5742-.2871-1.3027-1.0156-1.3027-.7275,0-1.043,.6729-1.043,1.3164Z" fill="#fff" />
                                            <path d="M605.5703,730.5159h.0137c.084-.1191,.2031-.2666,.3008-.3857l.9951-1.1689h.7422l-1.3096,1.3936,1.4912,1.9961h-.749l-1.1699-1.625-.3145,.3496v1.2754h-.6094v-4.9727h.6094v3.1377Z" fill="#fff" />
                                            <path d="M609.0479,728.0081c.0078,.21-.1465,.3779-.3916,.3779-.2178,0-.3711-.168-.3711-.3779,0-.2168,.1611-.3848,.3848-.3848,.2314,0,.3779,.168,.3779,.3848Zm-.6855,4.3428v-3.3896h.6162v3.3896h-.6162Z" fill="#fff" />
                                            <path d="M610.001,729.8782c0-.3506-.0078-.6377-.0283-.917h.5459l.0352,.5596h.0137c.1689-.3223,.5605-.6367,1.1211-.6367,.4688,0,1.1973,.2803,1.1973,1.4424v2.0244h-.6162v-1.9541c0-.5469-.2031-1.002-.7842-1.002-.4062,0-.7217,.2871-.8262,.6309-.0283,.0771-.042,.1816-.042,.2871v2.0381h-.6162v-2.4727Z" fill="#fff" />
                                            <path d="M616.8057,728.9612c-.0137,.2451-.0273,.5176-.0273,.9307v1.9688c0,.7773-.1543,1.2529-.4834,1.5479-.3291,.3076-.8057,.4062-1.2334,.4062-.4062,0-.8545-.0986-1.127-.2803l.1543-.4697c.2236,.1406,.5742,.2666,.9941,.2666,.6299,0,1.0928-.3291,1.0928-1.1836v-.3789h-.0146c-.1885,.3154-.5527,.5674-1.0781,.5674-.8408,0-1.4424-.7139-1.4424-1.6523,0-1.1484,.749-1.7998,1.5264-1.7998,.5879,0,.9102,.3076,1.0576,.5879h.0137l.0283-.5107h.5391Zm-.6367,1.3369c0-.1045-.0078-.1953-.0352-.2793-.1123-.3574-.4131-.6514-.8613-.6514-.5889,0-1.0088,.4971-1.0088,1.2812,0,.665,.3359,1.2188,1.001,1.2188,.3789,0,.7217-.2383,.8545-.6309,.0352-.1045,.0498-.2236,.0498-.3291v-.6094Z" fill="#fff" />
                                            <path d="M617.7989,727.3782h.6162v2.1152h.0137c.0977-.1758,.252-.3291,.4414-.4346,.1816-.1055,.3994-.1748,.6299-.1748,.4551,0,1.1836,.2803,1.1836,1.4492v2.0176h-.6162v-1.9473c0-.5459-.2031-1.0088-.7842-1.0088-.3994,0-.7148,.2803-.8262,.6162-.0352,.084-.042,.1758-.042,.2949v2.0449h-.6162v-4.9727Z" fill="#fff" />
                                            <path d="M623.5606,732.3509l-.0498-.4277h-.0205c-.1895,.2666-.5537,.5049-1.0371,.5049-.6855,0-1.0361-.4834-1.0361-.9736,0-.8193,.7285-1.2676,2.0381-1.2607v-.0703c0-.2803-.0771-.7842-.7705-.7842-.3154,0-.6445,.0977-.8828,.252l-.1396-.4062c.2803-.1816,.6865-.3008,1.1133-.3008,1.0371,0,1.2891,.707,1.2891,1.3867v1.2676c0,.2939,.0137,.5811,.0557,.8125h-.5596Zm-.0918-1.7305c-.6719-.0137-1.4355,.1055-1.4355,.7637,0,.3994,.2666,.5879,.5811,.5879,.4414,0,.7217-.2793,.8203-.5664,.0205-.0635,.0342-.1338,.0342-.1963v-.5889Z" fill="#fff" />
                                            <path d="M625.0576,729.8782c0-.3506-.0078-.6377-.0283-.917h.5391l.0283,.5459h.0205c.1895-.3223,.5049-.623,1.0645-.623,.4629,0,.8125,.2803,.96,.6787h.0137c.1055-.1885,.2383-.3359,.3789-.4404,.2031-.1543,.4268-.2383,.749-.2383,.4482,0,1.1133,.2939,1.1133,1.4707v1.9961h-.6016v-1.9189c0-.6514-.2383-1.0439-.7363-1.0439-.3496,0-.623,.2588-.7275,.5605-.0283,.084-.0498,.1963-.0498,.3076v2.0947h-.6016v-2.0312c0-.5391-.2383-.9316-.708-.9316-.3848,0-.665,.3086-.7627,.6162-.0352,.0908-.0498,.1963-.0498,.3018v2.0449h-.6016v-2.4727Z" fill="#fff" />
                                            <path d="M609.5313,734.6302h.6094v4.209h2.0176v.5117h-2.627v-4.7207Z" fill="#fff" />
                                            <path d="M615.419,739.3509l-.6016-1.0439c-.2451-.3994-.3994-.6582-.5469-.9316h-.0137c-.1328,.2734-.2666,.5254-.5117,.9385l-.5674,1.0371h-.7002l1.4434-2.3887-1.3867-2.332h.707l.623,1.1064c.1758,.3086,.3086,.5459,.4346,.7988h.0215c.1328-.2803,.252-.498,.4268-.7988l.6445-1.1064h.7002l-1.4355,2.2969,1.4707,2.4238h-.708Z" fill="#fff" />
                                        </g>
                                    </g>
                                    <g id="lx-002">
                                        <g>
                                            <g id="LWPOLYLINE-3" data-name="LWPOLYLINE">
                                                <polygon points="823.0974 850.5386 818.0014 835.3183 823.0974 820.0979 821.1419 815.9319 817.0722 818.0806 814.651 825.3119 812.2299 818.0806 808.1602 815.9319 806.2047 820.0979 811.3008 835.3183 806.2047 850.5387 808.1602 854.7046 812.2299 852.556 814.6511 845.3247 817.0722 852.556 821.1419 854.7046 823.0974 850.5386" fill="#9d9d9c" />
                                            </g>
                                            <g id="LWPOLYLINE-4" data-name="LWPOLYLINE">
                                                <polygon points="816.0934 835.3183 821.1124 820.328 818.5185 819.4595 814.651 831.0104 810.7836 819.4595 808.1897 820.328 813.2087 835.3183 808.1897 850.3086 810.7836 851.1771 814.6511 839.6261 818.5185 851.1771 821.1124 850.3086 816.0934 835.3183" fill="#fff" />
                                            </g>
                                        </g>
                                        <g>
                                            <g id="LWPOLYLINE-5" data-name="LWPOLYLINE">
                                                <polygon points="770.3678 850.5386 765.2718 835.3183 770.3678 820.0979 768.4123 815.9319 764.3426 818.0806 761.9215 825.3119 759.5003 818.0806 755.4306 815.9319 753.4751 820.0979 758.5712 835.3183 753.4752 850.5387 755.4306 854.7046 759.5003 852.556 761.9215 845.3247 764.3427 852.556 768.4124 854.7046 770.3678 850.5386" fill="#9d9d9c" />
                                            </g>
                                            <g id="LWPOLYLINE-6" data-name="LWPOLYLINE">
                                                <polygon points="763.3638 835.3183 768.3828 820.328 765.7889 819.4595 761.9215 831.0104 758.054 819.4595 755.4601 820.328 760.4791 835.3183 755.4602 850.3086 758.0541 851.1771 761.9215 839.6261 765.7889 851.1771 768.3828 850.3086 763.3638 835.3183" fill="#fff" />
                                            </g>
                                        </g>
                                        <g>
                                            <path d="M806.128,861.3538c.2734,.168,.6719,.3086,1.0928,.3086,.623,0,.9873-.3291,.9873-.8057,0-.4414-.252-.6934-.8896-.9385-.7705-.2734-1.2461-.6729-1.2461-1.3379,0-.7354,.6094-1.2812,1.5264-1.2812,.4834,0,.834,.1113,1.0439,.2305l-.168,.4971c-.1543-.084-.4697-.2236-.8965-.2236-.6445,0-.8896,.3848-.8896,.707,0,.4414,.2871,.6582,.9385,.9111,.7979,.3076,1.2041,.6934,1.2041,1.3867,0,.7285-.5391,1.3584-1.6523,1.3584-.4551,0-.9531-.1328-1.2051-.3008l.1543-.5117Z" fill="#fff" />
                                            <path d="M810.3633,857.7327v.9736h.8818v.4697h-.8818v1.8271c0,.4209,.1191,.6592,.4619,.6592,.1611,0,.2803-.0215,.3574-.042l.0283,.4619c-.1191,.0488-.3086,.084-.5469,.084-.2871,0-.5186-.0908-.665-.2588-.1748-.1826-.2383-.4834-.2383-.8828v-1.8486h-.5254v-.4697h.5254v-.8125l.6025-.1611Z" fill="#fff" />
                                            <path d="M813.8155,862.096l-.0498-.4268h-.0205c-.1895,.2656-.5537,.5039-1.0371,.5039-.6855,0-1.0361-.4834-1.0361-.9736,0-.8193,.7285-1.2676,2.0381-1.2607v-.0693c0-.2803-.0771-.7852-.7705-.7852-.3154,0-.6445,.0986-.8828,.2529l-.1396-.4062c.2803-.1826,.6865-.3018,1.1133-.3018,1.0371,0,1.2891,.708,1.2891,1.3867v1.2676c0,.2949,.0137,.582,.0557,.8125h-.5596Zm-.0918-1.7295c-.6719-.0146-1.4355,.1045-1.4355,.7637,0,.3984,.2666,.5879,.5811,.5879,.4414,0,.7217-.2803,.8203-.5674,.0205-.0625,.0342-.1328,.0342-.1963v-.5879Z" fill="#fff" />
                                            <path d="M815.3125,859.764c0-.3994-.0078-.7422-.0283-1.0576h.5391l.0215,.665h.0273c.1543-.4551,.5254-.7422,.9385-.7422,.0703,0,.1191,.0068,.1758,.0215v.5811c-.0635-.0137-.126-.0215-.2109-.0215-.4336,0-.7422,.3301-.8262,.792-.0137,.084-.0283,.1816-.0283,.2871v1.8066h-.6084v-2.332Z" fill="#fff" />
                                            <path d="M819.1055,857.3753h.6094v4.21h2.0176v.5107h-2.627v-4.7207Z" fill="#fff" />
                                            <path d="M822.3897,859.6243c0-.3506-.0078-.6377-.0283-.918h.5459l.0352,.5605h.0137c.1689-.3223,.5605-.6377,1.1211-.6377,.4688,0,1.1973,.2803,1.1973,1.4434v2.0234h-.6162v-1.9541c0-.5459-.2031-1.001-.7842-1.001-.4062,0-.7217,.2871-.8262,.6299-.0283,.0771-.042,.1826-.042,.2871v2.0381h-.6162v-2.4717Z" fill="#fff" />
                                            <path d="M812.6026,864.3753h.6094v4.21h2.0176v.5107h-2.627v-4.7207Z" fill="#fff" />
                                            <path d="M818.4903,869.096l-.6016-1.043c-.2451-.3994-.3994-.6592-.5469-.9316h-.0137c-.1328,.2725-.2666,.5254-.5117,.9385l-.5674,1.0361h-.7002l1.4434-2.3877-1.3867-2.333h.707l.623,1.1074c.1758,.3076,.3086,.5459,.4346,.7979h.0215c.1328-.2803,.252-.4971,.4268-.7979l.6445-1.1074h.7002l-1.4355,2.2979,1.4707,2.4229h-.708Z" fill="#fff" />
                                        </g>
                                        <g>
                                            <path d="M753.1162,805.8548c.2734,.168,.6729,.3076,1.0928,.3076,.623,0,.9873-.3291,.9873-.8057,0-.4414-.252-.6934-.8896-.9385-.7705-.2725-1.2461-.6719-1.2461-1.3379,0-.7354,.6094-1.2812,1.5264-1.2812,.4834,0,.834,.1123,1.0439,.2314l-.168,.4971c-.1543-.084-.4697-.2246-.8965-.2246-.6445,0-.8896,.3857-.8896,.708,0,.4414,.2871,.6582,.9385,.9102,.7979,.3086,1.2041,.6934,1.2041,1.3867,0,.7285-.5391,1.3584-1.6523,1.3584-.4551,0-.9531-.1328-1.2051-.3008l.1543-.5107Z" fill="#fff" />
                                            <path d="M756.6309,804.1243c0-.3506-.0078-.6377-.0283-.917h.5391l.0283,.5459h.0205c.1895-.3223,.5049-.623,1.0645-.623,.4629,0,.8125,.2803,.96,.6787h.0137c.1055-.1885,.2383-.3359,.3789-.4404,.2031-.1543,.4268-.2383,.749-.2383,.4482,0,1.1133,.2939,1.1133,1.4707v1.9961h-.6016v-1.9189c0-.6514-.2383-1.0439-.7363-1.0439-.3496,0-.623,.2588-.7275,.5605-.0283,.084-.0498,.1963-.0498,.3076v2.0947h-.6016v-2.0312c0-.5391-.2383-.9316-.708-.9316-.3848,0-.665,.3086-.7627,.6162-.0352,.0908-.0498,.1963-.0498,.3018v2.0449h-.6016v-2.4727Z" fill="#fff" />
                                            <path d="M763.1543,802.2542c.0078,.21-.1465,.3779-.3916,.3779-.2178,0-.3711-.168-.3711-.3779,0-.2168,.1611-.3848,.3848-.3848,.2314,0,.3779,.168,.3779,.3848Zm-.6855,4.3428v-3.3896h.6162v3.3896h-.6162Z" fill="#fff" />
                                            <path d="M764.8487,802.2337v.9736h.8818v.4688h-.8818v1.8281c0,.4199,.1191,.6582,.4619,.6582,.1611,0,.2803-.0205,.3574-.042l.0283,.4619c-.1191,.0498-.3086,.084-.5469,.084-.2871,0-.5186-.0908-.665-.2588-.1748-.1816-.2383-.4834-.2383-.8828v-1.8486h-.5254v-.4688h.5254v-.8125l.6025-.1611Z" fill="#fff" />
                                            <path d="M766.4239,801.6243h.6162v2.1152h.0137c.0977-.1758,.252-.3291,.4414-.4346,.1816-.1055,.3994-.1748,.6299-.1748,.4551,0,1.1836,.2803,1.1836,1.4492v2.0176h-.6162v-1.9473c0-.5459-.2031-1.0088-.7842-1.0088-.3994,0-.7148,.2803-.8262,.6162-.0352,.084-.042,.1758-.042,.2949v2.0449h-.6162v-4.9727Z" fill="#fff" />
                                            <path d="M770.2237,805.9661c.1826,.1191,.5049,.2451,.8125,.2451,.4482,0,.6592-.2236,.6592-.5039,0-.2939-.1758-.4551-.6309-.623-.6094-.2178-.8965-.5537-.8965-.96,0-.5459,.4414-.9941,1.1699-.9941,.3428,0,.6445,.0977,.833,.21l-.1543,.4482c-.1328-.084-.3779-.1963-.6934-.1963-.3633,0-.5664,.21-.5664,.4619,0,.2803,.2031,.4062,.6436,.5742,.5889,.2246,.8896,.5186,.8896,1.0234,0,.5947-.4619,1.0146-1.2676,1.0146-.3711,0-.7139-.0908-.9521-.2305l.1533-.4697Z" fill="#fff" />
                                            <path d="M760.9375,811.5237c-.0352-.6582-.0771-1.4502-.0703-2.0381h-.0215c-.1602,.5527-.3564,1.1416-.5947,1.793l-.834,2.29h-.4619l-.7637-2.248c-.2236-.665-.4131-1.2744-.5459-1.835h-.0137c-.0146,.5879-.0498,1.3799-.0918,2.0869l-.126,2.0244h-.5811l.3291-4.7207h.7773l.8057,2.2832c.1963,.5811,.3574,1.0996,.4766,1.5898h.0205c.1191-.4766,.2871-.9941,.4971-1.5898l.8408-2.2832h.7773l.2939,4.7207h-.5947l-.1191-2.0732Z" fill="#fff" />
                                            <path d="M762.503,812.8548c.2734,.168,.6729,.3076,1.0928,.3076,.623,0,.9873-.3291,.9873-.8057,0-.4414-.252-.6934-.8896-.9385-.7705-.2725-1.2461-.6719-1.2461-1.3379,0-.7354,.6094-1.2812,1.5264-1.2812,.4834,0,.834,.1123,1.0439,.2314l-.168,.4971c-.1543-.084-.4697-.2246-.8965-.2246-.6445,0-.8896,.3857-.8896,.708,0,.4414,.2871,.6582,.9385,.9102,.7979,.3086,1.2041,.6934,1.2041,1.3867,0,.7285-.5391,1.3584-1.6523,1.3584-.4551,0-.9531-.1328-1.2051-.3008l.1543-.5107Z" fill="#fff" />
                                            <path d="M766.0381,808.8763h.6094v4.209h2.0176v.5117h-2.627v-4.7207Z" fill="#fff" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="feltham-civils-works-group" className={props.civilsGroupDisplayToggle}>
                            <g id="feltham-cable-work-group" className={props.utxDisplayToggle}>
                                <g id="existing-equipment">
                                    <g id="power_cable" data-name="power cable">
                                        <line x1="670.4091" y1="839.166" x2="696.9176" y2="813.4028" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="670.7168" y1="1086.8784" x2="670.7168" y2="1437.4599" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="670.7168" y1="838.8445" x2="670.7168" y2="879.0762" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="670.7168" y1="746.4617" x2="670.7168" y2="777.3202" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <path d="M500.2686,473.2172l159.5175,184.1678c7.0488,8.1387,10.9307,18.5488,10.9307,29.3145v34.4402" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="377.7785" y1="331.8018" x2="469.9033" y2="438.1595" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="233.9486" y1="165.7426" x2="341.8259" y2="290.2934" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <path d="M137.5479,4.3421V47.1859c0,4.6816,1.688,9.208,4.7524,12.7461l55.6956,64.3022" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="635.4082" y1="1086.8784" x2="635.4082" y2="850.6098" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="635.4082" y1="1383.243" x2="635.4082" y2="1327.04" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="1154.4864" y1="851.8085" x2="1021.1825" y2="851.8085" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <line x1="833.8955" y1="816.4999" x2="1025.1943" y2="816.4999" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <path d="M670.7168,777.3202v2.9775c0,2.623,.9463,5.1592,2.6631,7.1406l19.3486,22.3389c3.7002,4.2725,9.0625,6.7227,14.7148,6.7227h86.7305" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <polyline points="197.9959 124.2342 206.5685 116.7942 242.5634 158.2659 233.9486 165.7426" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1.1846 3.5539" strokeLinecap="square" stroke-linejoin="round" strokeWidth="1.1846" />
                                        <polyline points="341.8259 290.2934 350.3984 282.8534 386.3934 324.3251 377.7785 331.8018" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1.1846 3.5539" strokeLinecap="square" stroke-linejoin="round" strokeWidth="1.1846" />
                                        <path d="M635.4082,850.6093h-20.002v-53.4293c0-10.2949,4.0088-19.9727,11.2881-27.251,4.7448-4.7448,7.6897-10.8105,8.4912-17.368" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        <path d="M643.0614,738.6675v10.2234c0,9.8455-3.9111,19.2878-10.8729,26.2496h0c-5.8452,5.8452-9.129,13.773-9.129,22.0394v48.6047" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
                                    </g>
                                    <g id="utx-existing">
                                        <g id="utx-crossing">
                                            <line x1="635.3943" y1="1340.9775" x2="670.7029" y2="1340.9775" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                            <line x1="635.3943" y1="1102.0658" x2="670.7029" y2="1102.0658" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                            <line x1="635.3943" y1="1374.6876" x2="670.7029" y2="1374.6876" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                            <line x1="635.3943" y1="1388.3683" x2="670.7029" y2="1388.3683" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                            <line x1="1024.1343" y1="816.4766" x2="1024.1343" y2="851.7852" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                            <line x1="1010.4536" y1="816.4766" x2="1010.4536" y2="851.7852" fill="none" stroke="#3c3c3b" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber">
                                            <circle cx="670.7168" cy="839.166" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="696.9176" cy="812.8245" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="670.7029" cy="1340.9775" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="635.3943" cy="1340.9775" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="670.7029" cy="1102.0658" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="635.3943" cy="1102.0658" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="670.7029" cy="1374.6876" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="635.3943" cy="1374.6876" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="670.7029" cy="1388.3683" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="635.3943" cy="1388.3683" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="1024.1343" cy="851.7852" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="1024.1343" cy="816.4766" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="1010.4536" cy="851.7852" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                            <circle cx="1010.4536" cy="816.4766" r="4.4411" fill="#fff" stroke="#3c3c3b" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                </g>
                                <g id="feltham-utx-group">
                                    <g id="utx-001">
                                        <g id="utx-crossing-2" data-name="utx-crossing">
                                            <line x1="164.4756" y1="139.3496" x2="189.9026" y2="114.8513" fill="none" stroke="#e9427d" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber-01">
                                            <circle cx="189.9026" cy="114.8513" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                        <g id="utx-chamber-02">
                                            <circle cx="164.4756" cy="139.3496" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                    <g id="utx-002">
                                        <g id="utx-crossing-3" data-name="utx-crossing">
                                            <line x1="635.3943" y1="754.3602" x2="670.7168" y2="754.7426" fill="none" stroke="#e9427d" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber-01-2" data-name="utx-chamber-01">
                                            <circle cx="670.7029" cy="754.252" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                        <g id="utx-chamber-02-2" data-name="utx-chamber-02">
                                            <circle cx="635.3943" cy="754.252" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                    <g id="utx-003">
                                        <g id="utx-crossing-4" data-name="utx-crossing">
                                            <line x1="635.3943" y1="879.0762" x2="670.7029" y2="879.0762" fill="none" stroke="#e9427d" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber-01-3" data-name="utx-chamber-01">
                                            <circle cx="670.7029" cy="879.0762" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                        <g id="utx-chamber-02-3" data-name="utx-chamber-02">
                                            <circle cx="635.3943" cy="879.0762" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                    <g id="utx-004">
                                        <g id="utx-crossing-5" data-name="utx-crossing">
                                            <line x1="635.3943" y1="1089.1862" x2="670.7029" y2="1089.1862" fill="none" stroke="#e9427d" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber-01-4" data-name="utx-chamber-01">
                                            <circle cx="670.7029" cy="1089.1862" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                        <g id="utx-chamber-02-4" data-name="utx-chamber-02">
                                            <circle cx="635.3943" cy="1089.1862" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                    <g id="utx-005">
                                        <g id="utx-crossing-6" data-name="utx-crossing">
                                            <line x1="635.3943" y1="1328.7282" x2="670.7029" y2="1328.7282" fill="none" stroke="#e9427d" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber-01-5" data-name="utx-chamber-01">
                                            <circle cx="670.7029" cy="1328.7282" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                        <g id="utx-chamber-02-5" data-name="utx-chamber-02">
                                            <circle cx="635.3943" cy="1328.7282" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                </g>
                                <g id="feltham-urx-group">
                                    <g id="urx-001">
                                        <g id="utx-crossing-7" data-name="utx-crossing">
                                            <line x1="670.7168" y1="744.4615" x2="670.7168" y2="716.2402" fill="none" stroke="#e9427d" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber-01-6" data-name="utx-chamber-01">
                                            <circle cx="670.7168" cy="744.4615" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                        <g id="utx-chamber-02-6" data-name="utx-chamber-02">
                                            <circle cx="670.7168" cy="717.3802" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                    <g id="urx-002">
                                        <g id="utx-crossing-8" data-name="utx-crossing">
                                            <line x1="831.4565" y1="816.4766" x2="796.1479" y2="816.4766" fill="none" stroke="#e9427d" stroke-dasharray="0 0 1 3" strokeLinecap="square" stroke-linejoin="round" />
                                        </g>
                                        <g id="utx-chamber-01-7" data-name="utx-chamber-01">
                                            <circle cx="796.1479" cy="816.4766" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                        <g id="utx-chamber-02-7" data-name="utx-chamber-02">
                                            <circle cx="831.4565" cy="816.4766" r="4.4411" fill="#fff" stroke="#e9427d" strokeMiterlimit="10" strokeWidth=".75" />
                                        </g>
                                    </g>
                                </g>
                                <g id="feltham-map-container-2" data-name="feltham-map-container">
                                    <g id="feltham-utx-table">
                                        <g id="bubbles-group">
                                            <g id="utx-001-bubble">
                                                <g id="body">
                                                    <rect x="132.255" y="115.911" width="2.0011" height="29.6397" transform="translate(-29.6061 221.9652) rotate(-73.7094)" fill="#e9427d" />
                                                    <circle cx="119.2871" cy="125.7943" r="12.3333" fill="#e9427d" />
                                                    <polygon points="158.6285 138.939 145.4959 127.9339 141.5182 139.8438 158.6285 138.939" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id">
                                                    <g>
                                                        <path d="M111.6968,121.3528v4.7852c0,1.8115,.8037,2.5791,1.8828,2.5791,1.1992,0,1.9668-.792,1.9668-2.5791v-4.7852h1.0557v4.7139c0,2.4824-1.3076,3.502-3.0586,3.502-1.6553,0-2.9023-.9473-2.9023-3.4541v-4.7617h1.0557Z" fill="#fff" />
                                                        <path d="M123.2261,125.4544c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M126.4917,122.6243h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9111h.8994v7.7959h-1.0195v-6.8125Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-002-bubble">
                                                <g id="body-2" data-name="body">
                                                    <rect x="591.5264" y="738.1184" width="2.0011" height="29.6397" transform="translate(-219.695 1261.1704) rotate(-83.8344)" fill="#e9427d" />
                                                    <circle cx="567.2074" cy="749.7875" r="12.3333" fill="#e9427d" />
                                                    <polygon points="618.9477 756.5581 604.085 748.033 602.263 760.4568 618.9477 756.5581" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-2" data-name="id">
                                                    <g>
                                                        <path d="M559.6172,745.346v4.7852c0,1.8115,.8037,2.5791,1.8828,2.5791,1.1992,0,1.9668-.792,1.9668-2.5791v-4.7852h1.0557v4.7139c0,2.4824-1.3076,3.502-3.0586,3.502-1.6553,0-2.9023-.9473-2.9023-3.4541v-4.7617h1.0557Z" fill="#fff" />
                                                        <path d="M571.1465,749.4476c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M572.1221,753.43v-.6475l.8271-.8037c1.9912-1.8955,2.8906-2.9023,2.9023-4.0781,0-.791-.3838-1.5234-1.5469-1.5234-.708,0-1.2959,.3604-1.6553,.6602l-.3359-.7441c.54-.4551,1.3076-.791,2.207-.791,1.6787,0,2.3867,1.1514,2.3867,2.2666,0,1.4395-1.0439,2.6025-2.6865,4.1855l-.624,.5762v.0234h3.502v.876h-4.9766Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-003-bubble">
                                                <g id="body-3" data-name="body">
                                                    <rect x="589.4782" y="884.9836" width="29.6397" height="2.0011" transform="translate(-212.7276 191.2805) rotate(-15.3295)" fill="#e9427d" />
                                                    <circle cx="587.4058" cy="888.5219" r="12.3333" fill="#e9427d" />
                                                    <polygon points="630.2076 879.671 613.2548 877.1849 616.1119 889.4122 630.2076 879.671" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-3" data-name="id">
                                                    <g>
                                                        <path d="M579.8155,884.0804v4.7852c0,1.8115,.8037,2.5791,1.8828,2.5791,1.1992,0,1.9668-.792,1.9668-2.5791v-4.7852h1.0557v4.7139c0,2.4824-1.3076,3.502-3.0586,3.502-1.6553,0-2.9023-.9473-2.9023-3.4541v-4.7617h1.0557Z" fill="#fff" />
                                                        <path d="M591.3448,888.1819c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M592.5723,890.9525c.2998,.1924,.9951,.4922,1.7266,.4922,1.3555,0,1.7754-.8633,1.7627-1.5117-.0117-1.0908-.9951-1.5586-2.0146-1.5586h-.5879v-.792h.5879c.7676,0,1.7393-.3955,1.7393-1.3193,0-.623-.3955-1.1748-1.3672-1.1748-.624,0-1.2236,.2754-1.5596,.5156l-.2754-.7676c.4072-.2998,1.1992-.5996,2.0391-.5996,1.5352,0,2.2305,.9111,2.2305,1.8584,0,.8037-.4795,1.4873-1.4395,1.835v.0244c.96,.1924,1.7393,.9111,1.7393,2.0029,0,1.2471-.9717,2.3389-2.8428,2.3389-.875,0-1.6426-.2764-2.0264-.5283l.2881-.8154Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-004-bubble">
                                                <g id="body-4" data-name="body">
                                                    <rect x="588.1712" y="1090.2189" width="29.6397" height="2.0011" transform="translate(-86.6327 52.5775) rotate(-4.6585)" fill="#e9427d" />
                                                    <circle cx="585.1382" cy="1091.4636" r="12.3333" fill="#e9427d" />
                                                    <polygon points="629.6215 1089.8132 613.4223 1084.2309 613.9658 1096.7758 629.6215 1089.8132" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-4" data-name="id">
                                                    <g>
                                                        <path d="M577.5479,1087.0228v4.7852c0,1.8105,.8037,2.5781,1.8828,2.5781,1.1992,0,1.9668-.791,1.9668-2.5781v-4.7852h1.0557v4.7129c0,2.4824-1.3076,3.502-3.0586,3.502-1.6553,0-2.9023-.9473-2.9023-3.4541v-4.7607h1.0557Z" fill="#fff" />
                                                        <path d="M589.0772,1091.1243c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0264,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                        <path d="M593.3145,1095.1058v-2.123h-3.6221v-.6953l3.4785-4.9775h1.1396v4.8457h1.0908v.8271h-1.0908v2.123h-.9961Zm0-2.9502v-2.6025c0-.4082,.0117-.8154,.0361-1.2236h-.0361c-.2393,.4561-.4316,.792-.6475,1.1514l-1.9072,2.6514v.0234h2.5547Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-005-bubble">
                                                <g id="body-5" data-name="body">
                                                    <rect x="686.0673" y="1317.0085" width="29.6397" height="2.0011" transform="translate(-516.8385 454.5654) rotate(-26.6579)" fill="#e9427d" />
                                                    <circle cx="713.9037" cy="1312.3594" r="12.3333" fill="#e9427d" />
                                                    <polygon points="676.7225 1329.2887 693.8334 1328.3963 688.6301 1316.9685 676.7225 1329.2887" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-5" data-name="id">
                                                    <g>
                                                        <path d="M706.3135,1307.9183v4.7852c0,1.8115,.8037,2.5791,1.8828,2.5791,1.1992,0,1.9668-.792,1.9668-2.5791v-4.7852h1.0557v4.7139c0,2.4824-1.3076,3.502-3.0586,3.502-1.6553,0-2.9023-.9473-2.9023-3.4551v-4.7607h1.0557Z" fill="#fff" />
                                                        <path d="M717.8428,1312.0198c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6152,1.1279-4.0547,2.7109-4.0547,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0273,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M723.4717,1309.0931h-2.9746l-.2998,2.0039c.1797-.0244,.3477-.0488,.6357-.0488,.5996,0,1.1992,.1328,1.6787,.4199,.6123,.3477,1.1152,1.0195,1.1152,2.0029,0,1.5234-1.2109,2.6631-2.9023,2.6631-.8516,0-1.5703-.2402-1.9424-.4805l.2637-.8027c.3242,.1914,.96,.4316,1.667,.4316,.9951,0,1.8467-.6484,1.8467-1.6914-.0117-1.0078-.6836-1.7266-2.2422-1.7266-.4443,0-.792,.0469-1.0791,.084l.5029-3.7422h3.7305v.8867Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="table-group">
                                            <g id="utx-001-item-row">
                                                <g id="item-base-box">
                                                    <g id="base-box">
                                                        <rect x="915.0157" y="313.8661" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1003.2751" y="313.8661" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text">
                                                        <g>
                                                            <path d="M951.1983,323.0413h-2.6191v2.4287h2.9189v.7295h-3.7881v-6.7363h3.6377v.7295h-2.7686v2.1289h2.6191v.7197Z" fill="#fff" />
                                                            <path d="M955.2784,326.1995l-.0693-.6104h-.0303c-.2695,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M957.42,322.8714c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0303v.8291c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4707-1.1797,1.1299-.0195,.1201-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M960.7295,319.1028h.8799v7.0967h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M963.5791,323.9407c.0205,1.1895,.7803,1.6797,1.6592,1.6797,.6299,0,1.0098-.1104,1.3398-.25l.1494,.6289c-.3096,.1406-.8398,.3008-1.6094,.3008-1.4883,0-2.3779-.9805-2.3779-2.4395s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3896,1.999,2.2783,0,.1797-.0205,.3203-.0303,.4102h-3.3984Zm2.5791-.6289c.0098-.5605-.2305-1.4297-1.2197-1.4297-.8896,0-1.2793,.8193-1.3486,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M968.419,321.3626l1.0596,2.8574c.1104,.3203,.2305,.7002,.3096,.9902h.0205c.0898-.29,.1895-.6602,.3096-1.0098l.96-2.8379h.9297l-1.3193,3.4473c-.6299,1.6602-1.0596,2.5088-1.6592,3.0293-.4297,.3789-.8594,.5293-1.0801,.5693l-.2197-.7393c.2197-.0703,.5098-.2109,.7695-.4297,.2402-.1904,.54-.5303,.7402-.9805,.04-.0898,.0693-.1602,.0693-.209,0-.0508-.0195-.1211-.0596-.2305l-1.7891-4.457h.959Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1029.2325,322.761c0,2.3193-1.4092,3.5488-3.1279,3.5488-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5391,3.1279-3.5391,1.8389,0,3.0283,1.4102,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1034.4131,324.8802c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3906-.7393,.9004-1.5986,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6777c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M1036.9346,319.973v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1039.0655,325.3001c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4189-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.3008-.8096,.6602,0,.4004,.29,.5801,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1309-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M1044.2051,319.973v1.3896h1.2588v.6699h-1.2588v2.6074c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1211-.7793,.1211-.4102,0-.7402-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1049.1338,326.1995l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M1051.2754,322.6712c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1061.003,319.1028v5.8477c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9902-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2285c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1191,.04-.2402,.04-.3789v-.8398Z" fill="#fff" />
                                                            <path d="M1063.4444,320.0032c.0107,.2988-.21,.5391-.5596,.5391-.3096,0-.5293-.2402-.5293-.5391,0-.3105,.2295-.5498,.5498-.5498,.3291,0,.5391,.2393,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M1064.8047,322.6712c0-.5-.0098-.9092-.04-1.3086h.7803l.0498,.7988h.0195c.2402-.459,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1074.5225,321.3626c-.0195,.3496-.04,.7393-.04,1.3281v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8086-1.54,.8086-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8389h.0195l.04-.7285h.7695Zm-.9092,1.9082c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4707v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box">
                                                    <g id="type-box">
                                                        <rect x="799.143" y="313.8661" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text">
                                                        <g>
                                                            <path d="M812.875,319.4632v3.9873c0,1.5098,.6699,2.1494,1.5693,2.1494,1,0,1.6396-.6592,1.6396-2.1494v-3.9873h.8789v3.9277c0,2.0693-1.0889,2.9189-2.5479,2.9189-1.3799,0-2.4189-.79-2.4189-2.8789v-3.9678h.8789Z" fill="#fff" />
                                                            <path d="M818.4453,322.9407c0-.6191-.0195-1.1191-.04-1.5781h.79l.04,.8291h.0195c.3604-.5898,.9297-.9395,1.7197-.9395,1.1689,0,2.0488,.9893,2.0488,2.458,0,1.7402-1.0596,2.5996-2.1992,2.5996-.6396,0-1.1992-.2803-1.4893-.7598h-.0195v2.6289h-.8701v-5.2383Zm.8701,1.29c0,.1299,.0195,.249,.04,.3604,.1592,.6094,.6895,1.0293,1.3193,1.0293,.9287,0,1.4688-.7598,1.4688-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0596,.2393-.0596,.3604v.8291Z" fill="#fff" />
                                                            <path d="M823.3965,326.5989l2.7881-7.2461h.6797l-2.7988,7.2461h-.6689Z" fill="#fff" />
                                                            <path d="M827.5957,319.553c.5293-.0791,1.1592-.1396,1.8486-.1396,1.25,0,2.1396,.2891,2.7285,.8398,.5996,.5488,.9502,1.3281,.9502,2.418,0,1.0996-.3398,1.999-.9697,2.6191-.6299,.6289-1.6689,.9688-2.9785,.9688-.6191,0-1.1396-.0293-1.5791-.0801v-6.626Zm.8691,5.957c.2207,.04,.54,.0498,.8799,.0498,1.8594,0,2.8682-1.0391,2.8682-2.8584,.0107-1.5889-.8896-2.5986-2.7285-2.5986-.4492,0-.7891,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M834.2256,322.6712c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M847.6026,323.2415c-.0498-.9395-.1104-2.0693-.1006-2.9082h-.0293c-.2305,.7891-.5098,1.6289-.8496,2.5586l-1.1895,3.2676h-.6602l-1.0889-3.208c-.3203-.9502-.5898-1.8193-.7793-2.6182h-.0205c-.0195,.8389-.0703,1.9688-.1299,2.9785l-.1797,2.8877h-.8301l.4697-6.7363h1.1094l1.1494,3.2588c.2803,.8291,.5098,1.5684,.6797,2.2676h.0303c.1699-.6797,.4102-1.418,.71-2.2676l1.1992-3.2588h1.1094l.4199,6.7363h-.8496l-.1699-2.958Z" fill="#fff" />
                                                            <path d="M852.6739,326.1995l-.0703-.6104h-.0303c-.2695,.3809-.7891,.7207-1.4785,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1406-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9795v1.8086c0,.4199,.0195,.8301,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0508-.1904,.0508-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M855.794,320.0032c.0107,.2988-.21,.5391-.5596,.5391-.3096,0-.5293-.2402-.5293-.5391,0-.3105,.2295-.5498,.5498-.5498,.3291,0,.5391,.2393,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M857.1553,322.6712c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box">
                                                    <g id="id-box">
                                                        <rect x="740.7457" y="313.8661" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text">
                                                        <g>
                                                            <path d="M763.1631,319.4944v3.8779c0,1.1592,.4395,1.749,1.2188,1.749,.7998,0,1.2402-.5596,1.2402-1.749v-3.8779h1.5186v3.7783c0,2.0781-1.0488,3.0684-2.8086,3.0684-1.6992,0-2.6982-.9395-2.6982-3.0889v-3.7578h1.5293Z" fill="#fff" />
                                                            <path d="M773.0215,322.9525c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7383,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M775.7129,321.0833h-.0205l-1.2393,.5898-.25-1.1387,1.7188-.8008h1.2598v6.4971h-1.4688v-5.1475Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-002-item-row">
                                                <g id="item-base-box-2" data-name="item-base-box">
                                                    <g id="base-box-2" data-name="base-box">
                                                        <rect x="915.0157" y="331.9911" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1003.2751" y="331.9911" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-2" data-name="base-text">
                                                        <g>
                                                            <path d="M931.7852,344.3245l-1.709-6.7363h.9189l.7998,3.4082c.2002,.8389,.3799,1.6787,.5,2.3291h.0195c.1104-.6699,.3203-1.4697,.5498-2.3398l.8994-3.3975h.9102l.8193,3.418c.1895,.7998,.3691,1.5986,.4697,2.3096h.0195c.1406-.7402,.3301-1.4902,.54-2.3301l.8896-3.3975h.8896l-1.9092,6.7363h-.9092l-.8496-3.5088c-.21-.8594-.3506-1.5186-.4404-2.1982h-.0195c-.1201,.6699-.2695,1.3291-.5195,2.1982l-.96,3.5088h-.9092Z" fill="#fff" />
                                                            <path d="M943.1631,341.8665c0,1.7891-1.2393,2.5684-2.4082,2.5684-1.3096,0-2.3193-.96-2.3193-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3896,0,2.3291,1.0088,2.3291,2.4893Zm-3.8379,.0488c0,1.0605,.6094,1.8594,1.4688,1.8594,.8398,0,1.4697-.7891,1.4697-1.8789,0-.8193-.4102-1.8594-1.4492-1.8594s-1.4893,.96-1.4893,1.8789Z" fill="#fff" />
                                                            <path d="M945.1455,341.7064h.0195c.1201-.1699,.29-.3809,.4297-.5508l1.4199-1.668h1.0586l-1.8682,1.9883,2.1289,2.8486h-1.0703l-1.6689-2.3193-.4492,.5v1.8193h-.8701v-7.0967h.8701v4.4785Z" fill="#fff" />
                                                            <path d="M950.1153,338.1282c.0098,.2988-.21,.5391-.5596,.5391-.3105,0-.5303-.2402-.5303-.5391,0-.3105,.2305-.5498,.5498-.5498,.3301,0,.54,.2393,.54,.5498Zm-.9795,6.1963v-4.8369h.8789v4.8369h-.8789Z" fill="#fff" />
                                                            <path d="M951.4756,340.7962c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M961.1934,339.4876c-.0205,.3496-.04,.7393-.04,1.3281v2.8086c0,1.1094-.2207,1.7891-.6904,2.209-.4697,.4395-1.1484,.5801-1.7588,.5801-.5791,0-1.2188-.1406-1.6094-.4004l.2207-.6689c.3193,.2002,.8193,.3799,1.4189,.3799,.8994,0,1.5586-.4697,1.5586-1.6895v-.5391h-.0195c-.2695,.4492-.7891,.8086-1.5391,.8086-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4404,1.5088,.8389h.0205l.04-.7285h.7695Zm-.9102,1.9082c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9492,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.8994,.0498-.1504,.0693-.3203,.0693-.4707v-.8691Z" fill="#fff" />
                                                            <path d="M962.6153,337.2278h.8789v3.0195h.0205c.1396-.25,.3594-.4707,.6299-.6201,.2598-.1494,.5693-.25,.8994-.25,.6494,0,1.6885,.3994,1.6885,2.0693v2.8779h-.8789v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5703,0-1.0195,.4004-1.1797,.8789-.0498,.1211-.0605,.25-.0605,.4199v2.9189h-.8789v-7.0967Z" fill="#fff" />
                                                            <path d="M970.8438,344.3245l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M972.9854,340.7962c0-.5-.0098-.9092-.04-1.3086h.7695l.04,.7793h.0303c.2695-.46,.7188-.8896,1.5186-.8896,.6602,0,1.1592,.3994,1.3691,.9697h.0205c.1494-.2695,.3398-.4805,.5391-.6299,.29-.2197,.6104-.3398,1.0703-.3398,.6396,0,1.5889,.4199,1.5889,2.0986v2.8486h-.8594v-2.7383c0-.9297-.3398-1.4893-1.0498-1.4893-.5,0-.8896,.3691-1.0391,.7988-.04,.1211-.0703,.2803-.0703,.4404v2.9883h-.8594v-2.8984c0-.7695-.3398-1.3291-1.0098-1.3291-.5498,0-.9492,.4395-1.0889,.8789-.0508,.1309-.0703,.2803-.0703,.4297v2.9189h-.8594v-3.5283Z" fill="#fff" />
                                                            <path d="M984.8545,337.5882h.8691v4.5068c0,1.7891-.8789,2.3398-2.0391,2.3398-.3193,0-.709-.0703-.9287-.1602l.1299-.71c.1797,.0703,.4395,.1299,.7295,.1299,.7793,0,1.2393-.3496,1.2393-1.6689v-4.4375Z" fill="#fff" />
                                                            <path d="M987.1446,340.7962c0-.5-.0098-.9092-.04-1.3086h.7803l.0498,.7988h.0195c.2402-.459,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1029.2325,340.886c0,2.3193-1.4092,3.5488-3.1279,3.5488-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5391,3.1279-3.5391,1.8389,0,3.0283,1.4102,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1034.4131,343.0052c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3906-.7393,.9004-1.5986,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6777c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M1036.9346,338.098v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1039.0655,343.4251c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4189-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.3008-.8096,.6602,0,.4004,.29,.5801,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1309-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M1044.2051,338.098v1.3896h1.2588v.6699h-1.2588v2.6074c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1211-.7793,.1211-.4102,0-.7402-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1049.1338,344.3245l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M1051.2754,340.7962c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1061.003,337.2278v5.8477c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9902-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2285c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1191,.04-.2402,.04-.3789v-.8398Z" fill="#fff" />
                                                            <path d="M1063.4444,338.1282c.0107,.2988-.21,.5391-.5596,.5391-.3096,0-.5293-.2402-.5293-.5391,0-.3105,.2295-.5498,.5498-.5498,.3291,0,.5391,.2393,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M1064.8047,340.7962c0-.5-.0098-.9092-.04-1.3086h.7803l.0498,.7988h.0195c.2402-.459,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1074.5225,339.4876c-.0195,.3496-.04,.7393-.04,1.3281v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8086-1.54,.8086-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8389h.0195l.04-.7285h.7695Zm-.9092,1.9082c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4707v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-2" data-name="item-type-box">
                                                    <g id="type-box-2" data-name="type-box">
                                                        <rect x="799.143" y="331.9911" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-2" data-name="type-text">
                                                        <g>
                                                            <path d="M812.875,337.5882v3.9873c0,1.5098,.6699,2.1494,1.5693,2.1494,1,0,1.6396-.6592,1.6396-2.1494v-3.9873h.8789v3.9277c0,2.0693-1.0889,2.9189-2.5479,2.9189-1.3799,0-2.4189-.79-2.4189-2.8789v-3.9678h.8789Z" fill="#fff" />
                                                            <path d="M818.4453,341.0657c0-.6191-.0195-1.1191-.04-1.5781h.79l.04,.8291h.0195c.3604-.5898,.9297-.9395,1.7197-.9395,1.1689,0,2.0488,.9893,2.0488,2.458,0,1.7402-1.0596,2.5996-2.1992,2.5996-.6396,0-1.1992-.2803-1.4893-.7598h-.0195v2.6289h-.8701v-5.2383Zm.8701,1.29c0,.1299,.0195,.249,.04,.3604,.1592,.6094,.6895,1.0293,1.3193,1.0293,.9287,0,1.4688-.7598,1.4688-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0596,.2393-.0596,.3604v.8291Z" fill="#fff" />
                                                            <path d="M823.3965,344.7239l2.7881-7.2461h.6797l-2.7988,7.2461h-.6689Z" fill="#fff" />
                                                            <path d="M827.5957,337.678c.5293-.0791,1.1592-.1396,1.8486-.1396,1.25,0,2.1396,.2891,2.7285,.8398,.5996,.5488,.9502,1.3281,.9502,2.418,0,1.0996-.3398,1.999-.9697,2.6191-.6299,.6289-1.6689,.9688-2.9785,.9688-.6191,0-1.1396-.0293-1.5791-.0801v-6.626Zm.8691,5.957c.2207,.04,.54,.0498,.8799,.0498,1.8594,0,2.8682-1.0391,2.8682-2.8584,.0107-1.5889-.8896-2.5986-2.7285-2.5986-.4492,0-.7891,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M834.2256,340.7962c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M841.9258,337.678c.3789-.0791,.9795-.1396,1.5889-.1396,.8691,0,1.4287,.1504,1.8486,.4902,.3496,.2598,.5596,.6592,.5596,1.1885,0,.6494-.4297,1.2197-1.1387,1.4795v.0195c.6396,.1602,1.3887,.6895,1.3887,1.6895,0,.5801-.2295,1.0195-.5693,1.3496-.4697,.4297-1.2295,.6289-2.3291,.6289-.5996,0-1.0596-.0391-1.3486-.0801v-6.626Zm.8691,2.7588h.7891c.9199,0,1.46-.4805,1.46-1.1289,0-.79-.5996-1.0996-1.4795-1.0996-.3994,0-.6299,.0293-.7695,.0596v2.1689Zm0,3.2285c.1699,.0293,.4199,.0391,.7295,.0391,.8994,0,1.7295-.3301,1.7295-1.3086,0-.9199-.79-1.2988-1.7393-1.2988h-.7197v2.5684Z" fill="#fff" />
                                                            <path d="M847.3155,337.2278h.8789v7.0967h-.8789v-7.0967Z" fill="#fff" />
                                                            <path d="M852.3535,344.3245l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M857.9434,344.1448c-.2305,.1201-.7402,.2803-1.3896,.2803-1.459,0-2.4082-.9902-2.4082-2.4688,0-1.4902,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1299,1.2188,.25l-.2002,.6797c-.209-.1201-.5391-.2295-1.0186-.2295-1.1094,0-1.709,.8193-1.709,1.8281,0,1.1201,.7188,1.8096,1.6787,1.8096,.5,0,.8291-.1299,1.0791-.2393l.1504,.6592Z" fill="#fff" />
                                                            <path d="M859.8448,341.7064h.0195c.1201-.1699,.29-.3809,.4297-.5508l1.4199-1.668h1.0586l-1.8682,1.9883,2.1289,2.8486h-1.0703l-1.6689-2.3193-.4492,.5v1.8193h-.8701v-7.0967h.8701v4.4785Z" fill="#fff" />
                                                            <path d="M864.1446,339.4876l.6396,2.458c.1396,.54,.2695,1.04,.3594,1.54h.0303c.1104-.4902,.2705-1.0098,.4297-1.5293l.79-2.4688h.7393l.75,2.418c.1797,.5801,.3193,1.0898,.4297,1.5801h.0293c.0801-.4902,.21-1,.3701-1.5703l.6895-2.4277h.8701l-1.5596,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3096-1.0195-.4297-1.5889h-.0195c-.1201,.5791-.2705,1.0791-.4404,1.5986l-.7793,2.2988h-.7998l-1.459-4.8369h.8994Z" fill="#fff" />
                                                            <path d="M873.793,344.3245l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M876.9541,338.098v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M879.6348,342.0657c.0195,1.1895,.7793,1.6797,1.6592,1.6797,.6299,0,1.0088-.1104,1.3389-.25l.1504,.6289c-.3105,.1406-.8398,.3008-1.6094,.3008-1.4893,0-2.3789-.9805-2.3789-2.4395s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3896,1.999,2.2783,0,.1797-.0195,.3203-.0293,.4102h-3.3984Zm2.5781-.6289c.0107-.5605-.2295-1.4297-1.2188-1.4297-.8896,0-1.2793,.8193-1.3496,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M884.1543,340.9964c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6504,.75-1.0596,1.3398-1.0596,.0996,0,.1689,.0098,.249,.0303v.8291c-.0898-.0205-.1797-.0303-.2998-.0303-.6191,0-1.0596,.4707-1.1787,1.1299-.0205,.1201-.04,.2598-.04,.4092v2.5791h-.8701v-3.3281Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-2" data-name="item-id-box">
                                                    <g id="id-box-2" data-name="id-box">
                                                        <rect x="740.7457" y="331.9911" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-2" data-name="id-text">
                                                        <g>
                                                            <path d="M763.1631,337.6194v3.8779c0,1.1592,.4395,1.749,1.2188,1.749,.7998,0,1.2402-.5596,1.2402-1.749v-3.8779h1.5186v3.7783c0,2.0781-1.0488,3.0684-2.8086,3.0684-1.6992,0-2.6982-.9395-2.6982-3.0889v-3.7578h1.5293Z" fill="#fff" />
                                                            <path d="M773.0215,341.0775c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7383,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M773.7735,344.3558v-.9189l.8398-.7598c1.4189-1.2695,2.1084-1.999,2.1289-2.7588,0-.5303-.3203-.9502-1.0693-.9502-.5596,0-1.0498,.2803-1.3896,.541l-.4297-1.0898c.4893-.3701,1.249-.6699,2.1289-.6699,1.4688,0,2.2783,.8594,2.2783,2.0391,0,1.0898-.7891,1.959-1.7285,2.7988l-.5996,.499v.0205h2.4482v1.249h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-003-item-row">
                                                <g id="item-base-box-3" data-name="item-base-box">
                                                    <g id="base-box-3" data-name="base-box">
                                                        <rect x="915.0157" y="350.1161" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1003.2751" y="350.1161" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-3" data-name="base-text">
                                                        <g>
                                                            <path d="M930.6856,355.7132h.8691v6.0068h2.8789v.7295h-3.748v-6.7363Z" fill="#fff" />
                                                            <path d="M939.2735,361.1302c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.79h-.0195c-.2305,.3906-.7402,.9004-1.5996,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8799v2.6777c0,.9199,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8799v3.5176Z" fill="#fff" />
                                                            <path d="M944.1836,362.2698c-.2305,.1201-.7402,.2803-1.3896,.2803-1.459,0-2.4082-.9902-2.4082-2.4688,0-1.4902,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1299,1.2188,.25l-.2002,.6797c-.209-.1201-.5391-.2295-1.0186-.2295-1.1094,0-1.709,.8193-1.709,1.8281,0,1.1201,.7188,1.8096,1.6787,1.8096,.5,0,.8291-.1299,1.0791-.2393l.1504,.6592Z" fill="#fff" />
                                                            <path d="M946.085,359.8314h.0195c.1201-.1699,.29-.3809,.4297-.5508l1.4199-1.668h1.0586l-1.8682,1.9883,2.1289,2.8486h-1.0703l-1.6689-2.3193-.4492,.5v1.8193h-.8701v-7.0967h.8701v4.4785Z" fill="#fff" />
                                                            <path d="M950.0752,355.3528h.8799v7.0967h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M952.9248,360.1907c.0205,1.1895,.7803,1.6797,1.6592,1.6797,.6299,0,1.0098-.1104,1.3398-.25l.1494,.6289c-.3096,.1406-.8398,.3008-1.6094,.3008-1.4883,0-2.3779-.9805-2.3779-2.4395s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3896,1.999,2.2783,0,.1797-.0205,.3203-.0303,.4102h-3.3984Zm2.5791-.6289c.0098-.5605-.2305-1.4297-1.2197-1.4297-.8896,0-1.2793,.8193-1.3486,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M957.7647,357.6126l1.0596,2.8574c.1104,.3203,.2305,.7002,.3096,.9902h.0205c.0898-.29,.1895-.6602,.3096-1.0098l.96-2.8379h.9287l-1.3184,3.4473c-.6299,1.6602-1.0596,2.5088-1.6592,3.0293-.4297,.3789-.8604,.5293-1.0801,.5693l-.2197-.7393c.2197-.0703,.5098-.2109,.7695-.4297,.2402-.1904,.54-.5303,.7402-.9805,.04-.0898,.0693-.1602,.0693-.209,0-.0508-.0195-.1211-.0596-.2305l-1.7891-4.457h.959Z" fill="#fff" />
                                                            <path d="M964.3047,355.803c.4395-.0898,1.0693-.1396,1.6689-.1396,.9297,0,1.5293,.1699,1.9492,.5498,.3398,.2998,.5293,.7588,.5293,1.2793,0,.8896-.5596,1.4795-1.2686,1.7188v.0303c.5195,.1797,.8291,.6592,.9893,1.3594,.2197,.9395,.3799,1.5889,.5195,1.8486h-.8994c-.1104-.1904-.2598-.7695-.4502-1.6084-.1992-.9297-.5596-1.2793-1.3486-1.3105h-.8203v2.9189h-.8691v-6.6465Zm.8691,3.0684h.8896c.9297,0,1.5195-.5098,1.5195-1.2793,0-.8701-.6299-1.249-1.5488-1.2588-.4199,0-.7197,.0391-.8604,.0801v2.458Z" fill="#fff" />
                                                            <path d="M973.8721,355.3528v5.8477c0,.4297,.0107,.9199,.04,1.249h-.7891l-.04-.8389h-.0205c-.2695,.5391-.8594,.9492-1.6484,.9492-1.1699,0-2.0693-.9902-2.0693-2.459-.0098-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0205v-2.8887h.8789Zm-.8789,4.2285c0-.1104-.0107-.2598-.04-.3701-.1299-.5596-.6104-1.0195-1.2695-1.0195-.9092,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4297,1.7891,.5889,0,1.1289-.3896,1.2891-1.0498,.0293-.1191,.04-.2402,.04-.3789v-.8398Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1029.2325,359.011c0,2.3193-1.4092,3.5488-3.1279,3.5488-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5391,3.1279-3.5391,1.8389,0,3.0283,1.4102,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1034.4131,361.1302c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3906-.7393,.9004-1.5986,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6777c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M1036.9346,356.223v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1039.0655,361.5501c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4189-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.3008-.8096,.6602,0,.4004,.29,.5801,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1309-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M1044.2051,356.223v1.3896h1.2588v.6699h-1.2588v2.6074c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1211-.7793,.1211-.4102,0-.7402-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1049.1338,362.4495l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M1051.2754,358.9212c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1061.003,355.3528v5.8477c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9902-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2285c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1191,.04-.2402,.04-.3789v-.8398Z" fill="#fff" />
                                                            <path d="M1063.4444,356.2532c.0107,.2988-.21,.5391-.5596,.5391-.3096,0-.5293-.2402-.5293-.5391,0-.3105,.2295-.5498,.5498-.5498,.3291,0,.5391,.2393,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M1064.8047,358.9212c0-.5-.0098-.9092-.04-1.3086h.7803l.0498,.7988h.0195c.2402-.459,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1074.5225,357.6126c-.0195,.3496-.04,.7393-.04,1.3281v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8086-1.54,.8086-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8389h.0195l.04-.7285h.7695Zm-.9092,1.9082c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4707v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-3" data-name="item-type-box">
                                                    <g id="type-box-3" data-name="type-box">
                                                        <rect x="799.143" y="350.1161" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-3" data-name="type-text">
                                                        <g>
                                                            <path d="M812.875,355.7132v3.9873c0,1.5098,.6699,2.1494,1.5693,2.1494,1,0,1.6396-.6592,1.6396-2.1494v-3.9873h.8789v3.9277c0,2.0693-1.0889,2.9189-2.5479,2.9189-1.3799,0-2.4189-.79-2.4189-2.8789v-3.9678h.8789Z" fill="#fff" />
                                                            <path d="M818.4453,359.1907c0-.6191-.0195-1.1191-.04-1.5781h.79l.04,.8291h.0195c.3604-.5898,.9297-.9395,1.7197-.9395,1.1689,0,2.0488,.9893,2.0488,2.458,0,1.7402-1.0596,2.5996-2.1992,2.5996-.6396,0-1.1992-.2803-1.4893-.7598h-.0195v2.6289h-.8701v-5.2383Zm.8701,1.29c0,.1299,.0195,.249,.04,.3604,.1592,.6094,.6895,1.0293,1.3193,1.0293,.9287,0,1.4688-.7598,1.4688-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0596,.2393-.0596,.3604v.8291Z" fill="#fff" />
                                                            <path d="M823.3965,362.8489l2.7881-7.2461h.6797l-2.7988,7.2461h-.6689Z" fill="#fff" />
                                                            <path d="M827.5957,355.803c.5293-.0791,1.1592-.1396,1.8486-.1396,1.25,0,2.1396,.2891,2.7285,.8398,.5996,.5488,.9502,1.3281,.9502,2.418,0,1.0996-.3398,1.999-.9697,2.6191-.6299,.6289-1.6689,.9688-2.9785,.9688-.6191,0-1.1396-.0293-1.5791-.0801v-6.626Zm.8691,5.957c.2207,.04,.54,.0498,.8799,.0498,1.8594,0,2.8682-1.0391,2.8682-2.8584,.0107-1.5889-.8896-2.5986-2.7285-2.5986-.4492,0-.7891,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M834.2256,358.9212c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M841.9258,355.803c.3789-.0791,.9795-.1396,1.5889-.1396,.8691,0,1.4287,.1504,1.8486,.4902,.3496,.2598,.5596,.6592,.5596,1.1885,0,.6494-.4297,1.2197-1.1387,1.4795v.0195c.6396,.1602,1.3887,.6895,1.3887,1.6895,0,.5801-.2295,1.0195-.5693,1.3496-.4697,.4297-1.2295,.6289-2.3291,.6289-.5996,0-1.0596-.0391-1.3486-.0801v-6.626Zm.8691,2.7588h.7891c.9199,0,1.46-.4805,1.46-1.1289,0-.79-.5996-1.0996-1.4795-1.0996-.3994,0-.6299,.0293-.7695,.0596v2.1689Zm0,3.2285c.1699,.0293,.4199,.0391,.7295,.0391,.8994,0,1.7295-.3301,1.7295-1.3086,0-.9199-.79-1.2988-1.7393-1.2988h-.7197v2.5684Z" fill="#fff" />
                                                            <path d="M847.3155,355.3528h.8789v7.0967h-.8789v-7.0967Z" fill="#fff" />
                                                            <path d="M852.3535,362.4495l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M857.9434,362.2698c-.2305,.1201-.7402,.2803-1.3896,.2803-1.459,0-2.4082-.9902-2.4082-2.4688,0-1.4902,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1299,1.2188,.25l-.2002,.6797c-.209-.1201-.5391-.2295-1.0186-.2295-1.1094,0-1.709,.8193-1.709,1.8281,0,1.1201,.7188,1.8096,1.6787,1.8096,.5,0,.8291-.1299,1.0791-.2393l.1504,.6592Z" fill="#fff" />
                                                            <path d="M859.8448,359.8314h.0195c.1201-.1699,.29-.3809,.4297-.5508l1.4199-1.668h1.0586l-1.8682,1.9883,2.1289,2.8486h-1.0703l-1.6689-2.3193-.4492,.5v1.8193h-.8701v-7.0967h.8701v4.4785Z" fill="#fff" />
                                                            <path d="M864.1446,357.6126l.6396,2.458c.1396,.54,.2695,1.04,.3594,1.54h.0303c.1104-.4902,.2705-1.0098,.4297-1.5293l.79-2.4688h.7393l.75,2.418c.1797,.5801,.3193,1.0898,.4297,1.5801h.0293c.0801-.4902,.21-1,.3701-1.5703l.6895-2.4277h.8701l-1.5596,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3096-1.0195-.4297-1.5889h-.0195c-.1201,.5791-.2705,1.0791-.4404,1.5986l-.7793,2.2988h-.7998l-1.459-4.8369h.8994Z" fill="#fff" />
                                                            <path d="M873.793,362.4495l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M876.9541,356.223v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M879.6348,360.1907c.0195,1.1895,.7793,1.6797,1.6592,1.6797,.6299,0,1.0088-.1104,1.3389-.25l.1504,.6289c-.3105,.1406-.8398,.3008-1.6094,.3008-1.4893,0-2.3789-.9805-2.3789-2.4395s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3896,1.999,2.2783,0,.1797-.0195,.3203-.0293,.4102h-3.3984Zm2.5781-.6289c.0107-.5605-.2295-1.4297-1.2188-1.4297-.8896,0-1.2793,.8193-1.3496,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M884.1543,359.1214c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6504,.75-1.0596,1.3398-1.0596,.0996,0,.1689,.0098,.249,.0303v.8291c-.0898-.0205-.1797-.0303-.2998-.0303-.6191,0-1.0596,.4707-1.1787,1.1299-.0205,.1201-.04,.2598-.04,.4092v2.5791h-.8701v-3.3281Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-3" data-name="item-id-box">
                                                    <g id="id-box-3" data-name="id-box">
                                                        <rect x="740.7457" y="350.1161" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-3" data-name="id-text">
                                                        <g>
                                                            <path d="M763.1631,355.7444v3.8779c0,1.1592,.4395,1.749,1.2188,1.749,.7998,0,1.2402-.5596,1.2402-1.749v-3.8779h1.5186v3.7783c0,2.0781-1.0488,3.0684-2.8086,3.0684-1.6992,0-2.6982-.9395-2.6982-3.0889v-3.7578h1.5293Z" fill="#fff" />
                                                            <path d="M773.0215,359.2025c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7383,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M774.043,360.9915c.2705,.1406,.8896,.4004,1.5098,.4004,.7891,0,1.1895-.3809,1.1895-.8701,0-.6396-.6396-.9287-1.3096-.9287h-.6201v-1.0898h.5898c.5098-.0098,1.1592-.2002,1.1592-.75,0-.3896-.3193-.6797-.959-.6797-.5293,0-1.0898,.2305-1.3594,.3906l-.3096-1.0996c.3896-.25,1.1689-.4902,2.0088-.4902,1.3896,0,2.1592,.7295,2.1592,1.6191,0,.6895-.3906,1.2295-1.1895,1.5098v.0195c.7793,.1396,1.4092,.7295,1.4092,1.5791,0,1.1494-1.0098,1.9893-2.6592,1.9893-.8389,0-1.5488-.2207-1.9287-.46l.3096-1.1396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-004-item-row">
                                                <g id="item-base-box-4" data-name="item-base-box">
                                                    <g id="base-box-4" data-name="base-box">
                                                        <rect x="915.0157" y="368.2411" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1003.2751" y="368.2411" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-4" data-name="base-text">
                                                        <g>
                                                            <path d="M930.5655,379.515c.3896,.2402,.96,.4395,1.5596,.4395,.8896,0,1.4092-.4688,1.4092-1.1484,0-.6299-.3604-.9902-1.2695-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0508,.8701-1.8301,2.1787-1.8301,.6904,0,1.1895,.1602,1.4893,.3301l-.2393,.71c-.2197-.1201-.6699-.3193-1.2793-.3193-.9199,0-1.2695,.5488-1.2695,1.0088,0,.6299,.4102,.9395,1.3389,1.2998,1.1396,.4395,1.7197,.9883,1.7197,1.9785,0,1.0391-.7695,1.9395-2.3594,1.9395-.6494,0-1.3584-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M938.294,380.5745l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M940.4356,377.0462c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M950.1631,373.4778v5.8477c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9902-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2285c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1191,.04-.2402,.04-.3789v-.8398Z" fill="#fff" />
                                                            <path d="M951.625,373.4778h.8799v3.0195h.0195c.1406-.25,.3604-.4707,.6299-.6201,.2598-.1494,.5703-.25,.8994-.25,.6504,0,1.6895,.3994,1.6895,2.0693v2.8779h-.8799v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5693,0-1.0195,.4004-1.1797,.8789-.0498,.1211-.0596,.25-.0596,.4199v2.9189h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M961.2227,379.2552c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3906-.7393,.9004-1.5986,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6777c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M962.6856,377.2464c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0303v.8291c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4707-1.1797,1.1299-.0195,.1201-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M965.835,379.6751c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4189-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.3008-.8096,.6602,0,.4004,.29,.5801,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1309-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M970.9746,374.348v1.3896h1.2588v.6699h-1.2588v2.6074c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1211-.7793,.1211-.4102,0-.7402-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1029.2325,377.136c0,2.3193-1.4092,3.5488-3.1279,3.5488-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5391,3.1279-3.5391,1.8389,0,3.0283,1.4102,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1034.4131,379.2552c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3906-.7393,.9004-1.5986,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6777c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M1036.9346,374.348v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1039.0655,379.6751c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4189-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.3008-.8096,.6602,0,.4004,.29,.5801,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1309-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M1044.2051,374.348v1.3896h1.2588v.6699h-1.2588v2.6074c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1211-.7793,.1211-.4102,0-.7402-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1049.1338,380.5745l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M1051.2754,377.0462c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1061.003,373.4778v5.8477c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9902-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2285c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1191,.04-.2402,.04-.3789v-.8398Z" fill="#fff" />
                                                            <path d="M1063.4444,374.3782c.0107,.2988-.21,.5391-.5596,.5391-.3096,0-.5293-.2402-.5293-.5391,0-.3105,.2295-.5498,.5498-.5498,.3291,0,.5391,.2393,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M1064.8047,377.0462c0-.5-.0098-.9092-.04-1.3086h.7803l.0498,.7988h.0195c.2402-.459,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1074.5225,375.7376c-.0195,.3496-.04,.7393-.04,1.3281v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8086-1.54,.8086-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8389h.0195l.04-.7285h.7695Zm-.9092,1.9082c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4707v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-4" data-name="item-type-box">
                                                    <g id="type-box-4" data-name="type-box">
                                                        <rect x="799.143" y="368.2411" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-4" data-name="type-text">
                                                        <g>
                                                            <path d="M812.875,373.8382v3.9873c0,1.5098,.6699,2.1494,1.5693,2.1494,1,0,1.6396-.6592,1.6396-2.1494v-3.9873h.8789v3.9277c0,2.0693-1.0889,2.9189-2.5479,2.9189-1.3799,0-2.4189-.79-2.4189-2.8789v-3.9678h.8789Z" fill="#fff" />
                                                            <path d="M818.4453,377.3157c0-.6191-.0195-1.1191-.04-1.5781h.79l.04,.8291h.0195c.3604-.5898,.9297-.9395,1.7197-.9395,1.1689,0,2.0488,.9893,2.0488,2.458,0,1.7402-1.0596,2.5996-2.1992,2.5996-.6396,0-1.1992-.2803-1.4893-.7598h-.0195v2.6289h-.8701v-5.2383Zm.8701,1.29c0,.1299,.0195,.249,.04,.3604,.1592,.6094,.6895,1.0293,1.3193,1.0293,.9287,0,1.4688-.7598,1.4688-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0596,.2393-.0596,.3604v.8291Z" fill="#fff" />
                                                            <path d="M823.3965,380.9739l2.7881-7.2461h.6797l-2.7988,7.2461h-.6689Z" fill="#fff" />
                                                            <path d="M827.5957,373.928c.5293-.0791,1.1592-.1396,1.8486-.1396,1.25,0,2.1396,.2891,2.7285,.8398,.5996,.5488,.9502,1.3281,.9502,2.418,0,1.0996-.3398,1.999-.9697,2.6191-.6299,.6289-1.6689,.9688-2.9785,.9688-.6191,0-1.1396-.0293-1.5791-.0801v-6.626Zm.8691,5.957c.2207,.04,.54,.0498,.8799,.0498,1.8594,0,2.8682-1.0391,2.8682-2.8584,.0107-1.5889-.8896-2.5986-2.7285-2.5986-.4492,0-.7891,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M834.2256,377.0462c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M841.9258,373.928c.3789-.0791,.9795-.1396,1.5889-.1396,.8691,0,1.4287,.1504,1.8486,.4902,.3496,.2598,.5596,.6592,.5596,1.1885,0,.6494-.4297,1.2197-1.1387,1.4795v.0195c.6396,.1602,1.3887,.6895,1.3887,1.6895,0,.5801-.2295,1.0195-.5693,1.3496-.4697,.4297-1.2295,.6289-2.3291,.6289-.5996,0-1.0596-.0391-1.3486-.0801v-6.626Zm.8691,2.7588h.7891c.9199,0,1.46-.4805,1.46-1.1289,0-.79-.5996-1.0996-1.4795-1.0996-.3994,0-.6299,.0293-.7695,.0596v2.1689Zm0,3.2285c.1699,.0293,.4199,.0391,.7295,.0391,.8994,0,1.7295-.3301,1.7295-1.3086,0-.9199-.79-1.2988-1.7393-1.2988h-.7197v2.5684Z" fill="#fff" />
                                                            <path d="M847.3155,373.4778h.8789v7.0967h-.8789v-7.0967Z" fill="#fff" />
                                                            <path d="M852.3535,380.5745l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M857.9434,380.3948c-.2305,.1201-.7402,.2803-1.3896,.2803-1.459,0-2.4082-.9902-2.4082-2.4688,0-1.4902,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1299,1.2188,.25l-.2002,.6797c-.209-.1201-.5391-.2295-1.0186-.2295-1.1094,0-1.709,.8193-1.709,1.8281,0,1.1201,.7188,1.8096,1.6787,1.8096,.5,0,.8291-.1299,1.0791-.2393l.1504,.6592Z" fill="#fff" />
                                                            <path d="M859.8448,377.9564h.0195c.1201-.1699,.29-.3809,.4297-.5508l1.4199-1.668h1.0586l-1.8682,1.9883,2.1289,2.8486h-1.0703l-1.6689-2.3193-.4492,.5v1.8193h-.8701v-7.0967h.8701v4.4785Z" fill="#fff" />
                                                            <path d="M864.1446,375.7376l.6396,2.458c.1396,.54,.2695,1.04,.3594,1.54h.0303c.1104-.4902,.2705-1.0098,.4297-1.5293l.79-2.4688h.7393l.75,2.418c.1797,.5801,.3193,1.0898,.4297,1.5801h.0293c.0801-.4902,.21-1,.3701-1.5703l.6895-2.4277h.8701l-1.5596,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3096-1.0195-.4297-1.5889h-.0195c-.1201,.5791-.2705,1.0791-.4404,1.5986l-.7793,2.2988h-.7998l-1.459-4.8369h.8994Z" fill="#fff" />
                                                            <path d="M873.793,380.5745l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M876.9541,374.348v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M879.6348,378.3157c.0195,1.1895,.7793,1.6797,1.6592,1.6797,.6299,0,1.0088-.1104,1.3389-.25l.1504,.6289c-.3105,.1406-.8398,.3008-1.6094,.3008-1.4893,0-2.3789-.9805-2.3789-2.4395s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3896,1.999,2.2783,0,.1797-.0195,.3203-.0293,.4102h-3.3984Zm2.5781-.6289c.0107-.5605-.2295-1.4297-1.2188-1.4297-.8896,0-1.2793,.8193-1.3496,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M884.1543,377.2464c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6504,.75-1.0596,1.3398-1.0596,.0996,0,.1689,.0098,.249,.0303v.8291c-.0898-.0205-.1797-.0303-.2998-.0303-.6191,0-1.0596,.4707-1.1787,1.1299-.0205,.1201-.04,.2598-.04,.4092v2.5791h-.8701v-3.3281Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-4" data-name="item-id-box">
                                                    <g id="id-box-4" data-name="id-box">
                                                        <rect x="740.7457" y="368.2411" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-4" data-name="id-text">
                                                        <g>
                                                            <path d="M763.1631,373.8694v3.8779c0,1.1592,.4395,1.749,1.2188,1.749,.7998,0,1.2402-.5596,1.2402-1.749v-3.8779h1.5186v3.7783c0,2.0781-1.0488,3.0684-2.8086,3.0684-1.6992,0-2.6982-.9395-2.6982-3.0889v-3.7578h1.5293Z" fill="#fff" />
                                                            <path d="M773.0215,377.3275c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7383,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M776.4619,380.6058v-1.5498h-2.8779v-.9883l2.458-3.959h1.8594v3.8086h.7793v1.1387h-.7793v1.5498h-1.4395Zm0-2.6885v-1.4395c0-.3896,.0205-.7891,.0498-1.209h-.04c-.21,.4199-.3799,.7988-.5996,1.209l-.8691,1.4199v.0195h1.459Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="utx-005-item-row">
                                                <g id="item-base-box-5" data-name="item-base-box">
                                                    <g id="base-box-5" data-name="base-box">
                                                        <rect x="915.0157" y="386.3661" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1003.2751" y="386.3661" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-5" data-name="base-text">
                                                        <g>
                                                            <path d="M930.6856,392.053c.3799-.0791,.9795-.1396,1.5889-.1396,.8701,0,1.4297,.1504,1.8496,.4902,.3496,.2598,.5596,.6592,.5596,1.1885,0,.6494-.4297,1.2197-1.1396,1.4795v.0195c.6396,.1602,1.3896,.6895,1.3896,1.6895,0,.5801-.2305,1.0195-.5703,1.3496-.4697,.4297-1.2295,.6289-2.3281,.6289-.5996,0-1.0596-.0391-1.3496-.0801v-6.626Zm.8691,2.7588h.79c.9199,0,1.459-.4805,1.459-1.1289,0-.79-.5996-1.0996-1.4795-1.0996-.3994,0-.6289,.0293-.7695,.0596v2.1689Zm0,3.2285c.1699,.0293,.4199,.0391,.7305,.0391,.8994,0,1.7285-.3301,1.7285-1.3086,0-.9199-.7891-1.2988-1.7393-1.2988h-.7197v2.5684Z" fill="#fff" />
                                                            <path d="M936.0752,391.6028h.8799v7.0967h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M941.1143,398.6995l-.0703-.6104h-.0303c-.2695,.3809-.7891,.7207-1.4785,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1406-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9795v1.8086c0,.4199,.0195,.8301,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0508-.1904,.0508-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M946.7032,398.5198c-.2295,.1201-.7393,.2803-1.3887,.2803-1.46,0-2.4092-.9902-2.4092-2.4688,0-1.4902,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1299,1.2197,.25l-.2002,.6797c-.21-.1201-.54-.2295-1.0195-.2295-1.1094,0-1.709,.8193-1.709,1.8281,0,1.1201,.7197,1.8096,1.6787,1.8096,.5,0,.8301-.1299,1.0801-.2393l.1494,.6592Z" fill="#fff" />
                                                            <path d="M948.6045,396.0814h.0205c.1201-.1699,.29-.3809,.4297-.5508l1.4189-1.668h1.0596l-1.8691,1.9883,2.1289,2.8486h-1.0693l-1.6689-2.3193-.4502,.5v1.8193h-.8691v-7.0967h.8691v4.4785Z" fill="#fff" />
                                                            <path d="M952.5948,391.6028h.8799v3.0195h.0195c.1406-.25,.3604-.4707,.6299-.6201,.2598-.1494,.5703-.25,.8994-.25,.6504,0,1.6895,.3994,1.6895,2.0693v2.8779h-.8799v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5693,0-1.0195,.4004-1.1797,.8789-.0498,.1211-.0596,.25-.0596,.4199v2.9189h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M962.1934,397.3802c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.79h-.0195c-.2305,.3906-.7402,.9004-1.5996,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8799v2.6777c0,.9199,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8799v3.5176Z" fill="#fff" />
                                                            <path d="M963.6553,395.3714c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0303v.8291c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4707-1.1797,1.1299-.0195,.1201-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M966.8057,397.8001c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4189-.25-.6494-.8994-.8896-.8701-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6289-1.4189,1.6689-1.4189,.4893,0,.9189,.1396,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2803-.9902-.2803-.5195,0-.8096,.3008-.8096,.6602,0,.4004,.29,.5801,.9199,.8193,.8398,.3193,1.2695,.7393,1.2695,1.46,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1309-1.3594-.3301l.2207-.6699Z" fill="#fff" />
                                                            <path d="M971.9444,392.473v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1029.2325,395.261c0,2.3193-1.4092,3.5488-3.1279,3.5488-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5391,3.1279-3.5391,1.8389,0,3.0283,1.4102,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1034.4131,397.3802c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3906-.7393,.9004-1.5986,.9004-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6777c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7988,.0498-.1309,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M1036.9346,392.473v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1039.0655,397.8001c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4189-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.3008-.8096,.6602,0,.4004,.29,.5801,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1309-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M1044.2051,392.473v1.3896h1.2588v.6699h-1.2588v2.6074c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1211-.7793,.1211-.4102,0-.7402-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1049.1338,398.6995l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M1051.2754,395.1712c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1061.003,391.6028v5.8477c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9902-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2285c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1191,.04-.2402,.04-.3789v-.8398Z" fill="#fff" />
                                                            <path d="M1063.4444,392.5032c.0107,.2988-.21,.5391-.5596,.5391-.3096,0-.5293-.2402-.5293-.5391,0-.3105,.2295-.5498,.5498-.5498,.3291,0,.5391,.2393,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M1064.8047,395.1712c0-.5-.0098-.9092-.04-1.3086h.7803l.0498,.7988h.0195c.2402-.459,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1074.5225,393.8626c-.0195,.3496-.04,.7393-.04,1.3281v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8086-1.54,.8086-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8389h.0195l.04-.7285h.7695Zm-.9092,1.9082c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4707v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-5" data-name="item-type-box">
                                                    <g id="type-box-5" data-name="type-box">
                                                        <rect x="799.143" y="386.3661" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-5" data-name="type-text">
                                                        <g>
                                                            <path d="M812.875,391.9632v3.9873c0,1.5098,.6699,2.1494,1.5693,2.1494,1,0,1.6396-.6592,1.6396-2.1494v-3.9873h.8789v3.9277c0,2.0693-1.0889,2.9189-2.5479,2.9189-1.3799,0-2.4189-.79-2.4189-2.8789v-3.9678h.8789Z" fill="#fff" />
                                                            <path d="M818.4453,395.4407c0-.6191-.0195-1.1191-.04-1.5781h.79l.04,.8291h.0195c.3604-.5898,.9297-.9395,1.7197-.9395,1.1689,0,2.0488,.9893,2.0488,2.458,0,1.7402-1.0596,2.5996-2.1992,2.5996-.6396,0-1.1992-.2803-1.4893-.7598h-.0195v2.6289h-.8701v-5.2383Zm.8701,1.29c0,.1299,.0195,.249,.04,.3604,.1592,.6094,.6895,1.0293,1.3193,1.0293,.9287,0,1.4688-.7598,1.4688-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0596,.2393-.0596,.3604v.8291Z" fill="#fff" />
                                                            <path d="M823.3965,399.0989l2.7881-7.2461h.6797l-2.7988,7.2461h-.6689Z" fill="#fff" />
                                                            <path d="M827.5957,392.053c.5293-.0791,1.1592-.1396,1.8486-.1396,1.25,0,2.1396,.2891,2.7285,.8398,.5996,.5488,.9502,1.3281,.9502,2.418,0,1.0996-.3398,1.999-.9697,2.6191-.6299,.6289-1.6689,.9688-2.9785,.9688-.6191,0-1.1396-.0293-1.5791-.0801v-6.626Zm.8691,5.957c.2207,.04,.54,.0498,.8799,.0498,1.8594,0,2.8682-1.0391,2.8682-2.8584,.0107-1.5889-.8896-2.5986-2.7285-2.5986-.4492,0-.7891,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M834.2256,395.1712c0-.5-.0098-.9092-.04-1.3086h.7793l.0498,.7988h.0205c.2393-.459,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M841.9258,392.053c.3789-.0791,.9795-.1396,1.5889-.1396,.8691,0,1.4287,.1504,1.8486,.4902,.3496,.2598,.5596,.6592,.5596,1.1885,0,.6494-.4297,1.2197-1.1387,1.4795v.0195c.6396,.1602,1.3887,.6895,1.3887,1.6895,0,.5801-.2295,1.0195-.5693,1.3496-.4697,.4297-1.2295,.6289-2.3291,.6289-.5996,0-1.0596-.0391-1.3486-.0801v-6.626Zm.8691,2.7588h.7891c.9199,0,1.46-.4805,1.46-1.1289,0-.79-.5996-1.0996-1.4795-1.0996-.3994,0-.6299,.0293-.7695,.0596v2.1689Zm0,3.2285c.1699,.0293,.4199,.0391,.7295,.0391,.8994,0,1.7295-.3301,1.7295-1.3086,0-.9199-.79-1.2988-1.7393-1.2988h-.7197v2.5684Z" fill="#fff" />
                                                            <path d="M847.3155,391.6028h.8789v7.0967h-.8789v-7.0967Z" fill="#fff" />
                                                            <path d="M852.3535,398.6995l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M857.9434,398.5198c-.2305,.1201-.7402,.2803-1.3896,.2803-1.459,0-2.4082-.9902-2.4082-2.4688,0-1.4902,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1299,1.2188,.25l-.2002,.6797c-.209-.1201-.5391-.2295-1.0186-.2295-1.1094,0-1.709,.8193-1.709,1.8281,0,1.1201,.7188,1.8096,1.6787,1.8096,.5,0,.8291-.1299,1.0791-.2393l.1504,.6592Z" fill="#fff" />
                                                            <path d="M859.8448,396.0814h.0195c.1201-.1699,.29-.3809,.4297-.5508l1.4199-1.668h1.0586l-1.8682,1.9883,2.1289,2.8486h-1.0703l-1.6689-2.3193-.4492,.5v1.8193h-.8701v-7.0967h.8701v4.4785Z" fill="#fff" />
                                                            <path d="M864.1446,393.8626l.6396,2.458c.1396,.54,.2695,1.04,.3594,1.54h.0303c.1104-.4902,.2705-1.0098,.4297-1.5293l.79-2.4688h.7393l.75,2.418c.1797,.5801,.3193,1.0898,.4297,1.5801h.0293c.0801-.4902,.21-1,.3701-1.5703l.6895-2.4277h.8701l-1.5596,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3096-1.0195-.4297-1.5889h-.0195c-.1201,.5791-.2705,1.0791-.4404,1.5986l-.7793,2.2988h-.7998l-1.459-4.8369h.8994Z" fill="#fff" />
                                                            <path d="M873.793,398.6995l-.0693-.6104h-.0303c-.2705,.3809-.79,.7207-1.4795,.7207-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1406-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8086,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M876.9541,392.473v1.3896h1.2598v.6699h-1.2598v2.6074c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1211-.7803,.1211-.4092,0-.7393-.1309-.9492-.3711-.25-.2598-.3398-.6895-.3398-1.2588v-2.6377h-.75v-.6699h.75v-1.1602l.8594-.2295Z" fill="#fff" />
                                                            <path d="M879.6348,396.4407c.0195,1.1895,.7793,1.6797,1.6592,1.6797,.6299,0,1.0088-.1104,1.3389-.25l.1504,.6289c-.3105,.1406-.8398,.3008-1.6094,.3008-1.4893,0-2.3789-.9805-2.3789-2.4395s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3896,1.999,2.2783,0,.1797-.0195,.3203-.0293,.4102h-3.3984Zm2.5781-.6289c.0107-.5605-.2295-1.4297-1.2188-1.4297-.8896,0-1.2793,.8193-1.3496,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M884.1543,395.3714c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6504,.75-1.0596,1.3398-1.0596,.0996,0,.1689,.0098,.249,.0303v.8291c-.0898-.0205-.1797-.0303-.2998-.0303-.6191,0-1.0596,.4707-1.1787,1.1299-.0205,.1201-.04,.2598-.04,.4092v2.5791h-.8701v-3.3281Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-5" data-name="item-id-box">
                                                    <g id="id-box-5" data-name="id-box">
                                                        <rect x="740.7457" y="386.3661" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-5" data-name="id-text">
                                                        <g>
                                                            <path d="M763.1631,391.9944v3.8779c0,1.1592,.4395,1.749,1.2188,1.749,.7998,0,1.2402-.5596,1.2402-1.749v-3.8779h1.5186v3.7783c0,2.0781-1.0488,3.0684-2.8086,3.0684-1.6992,0-2.6982-.9395-2.6982-3.0889v-3.7578h1.5293Z" fill="#fff" />
                                                            <path d="M773.0215,395.4525c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7383,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M778.1114,393.4837h-2.5186l-.1406,1c.1406-.0205,.2607-.0205,.4199-.0205,.6201,0,1.25,.1396,1.709,.4697,.4902,.3301,.79,.8691,.79,1.6289,0,1.209-1.0391,2.2793-2.7881,2.2793-.79,0-1.4492-.1797-1.8096-.3701l.2695-1.1396c.29,.1406,.8799,.3203,1.4697,.3203,.6299,0,1.2988-.3008,1.2988-.9902,0-.6689-.5293-1.0791-1.8291-1.0791-.3594,0-.6094,.0205-.8789,.0596l.4297-3.4082h3.5781v1.25Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="title-row">
                                                <g id="status-box">
                                                    <g id="base-box-6" data-name="base-box">
                                                        <rect x="915.0157" y="295.6859" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1003.2751" y="295.6859" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-6" data-name="base-text">
                                                        <g>
                                                            <path d="M942.6856,301.2825h1.5293v5.457h2.6787v1.2793h-4.208v-6.7363Z" fill="#fff" />
                                                            <path d="M952.4834,305.5208c0,1.7891-1.2695,2.6084-2.5791,2.6084-1.4287,0-2.5283-.9395-2.5283-2.5186s1.0391-2.5879,2.6084-2.5879c1.499,0,2.499,1.0293,2.499,2.498Zm-3.5381,.0498c0,.8398,.3496,1.4697,.999,1.4697,.5898,0,.9697-.5898,.9697-1.4697,0-.7295-.2803-1.4697-.9697-1.4697-.7295,0-.999,.75-.999,1.4697Z" fill="#fff" />
                                                            <path d="M957.1241,307.8997c-.2705,.1191-.7803,.2197-1.3594,.2197-1.5791,0-2.5889-.96-2.5889-2.499,0-1.4297,.9795-2.5977,2.7988-2.5977,.3994,0,.8389,.0693,1.1592,.1895l-.2402,1.1289c-.1797-.0801-.4492-.1504-.8496-.1504-.7988,0-1.3193,.5703-1.3086,1.3701,0,.8994,.5996,1.3691,1.3389,1.3691,.3594,0,.6396-.0596,.8691-.1494l.1807,1.1191Z" fill="#fff" />
                                                            <path d="M960.7842,308.0189l-.0898-.4893h-.0303c-.3203,.3896-.8193,.5996-1.3994,.5996-.9893,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1396-1.8789,2.8682-1.8691v-.0703c0-.2598-.1396-.6289-.8896-.6289-.499,0-1.0293,.1699-1.3486,.3691l-.2803-.9785c.3398-.1904,1.0098-.4297,1.8994-.4297,1.6289,0,2.1484,.959,2.1484,2.1084v1.6982c0,.4707,.0205,.9199,.0703,1.1895h-1.3691Zm-.1807-2.3086c-.7988-.0098-1.4189,.1797-1.4189,.7695,0,.3906,.2598,.5801,.5996,.5801,.3799,0,.6895-.25,.79-.5596,.0195-.0801,.0293-.1699,.0293-.2598v-.5303Z" fill="#fff" />
                                                            <path d="M964.9043,301.7327v1.3994h1.0898v1.1191h-1.0898v1.7695c0,.5898,.1406,.8594,.5996,.8594,.1904,0,.3398-.0195,.4502-.04l.0098,1.1494c-.1992,.0801-.5596,.1299-.9893,.1299-.4893,0-.8994-.1699-1.1396-.4199-.2793-.29-.4199-.7598-.4199-1.4492v-1.999h-.6494v-1.1191h.6494v-1.0596l1.4893-.3398Z" fill="#fff" />
                                                            <path d="M968.4444,301.7728c0,.4199-.3193,.7598-.8193,.7598-.4795,0-.7998-.3398-.7891-.7598-.0107-.4395,.3096-.7695,.7988-.7695,.4902,0,.7998,.3301,.8096,.7695Zm-1.5684,6.2461v-4.8867h1.5186v4.8867h-1.5186Z" fill="#fff" />
                                                            <path d="M974.4424,305.5208c0,1.7891-1.2695,2.6084-2.5781,2.6084-1.4297,0-2.5293-.9395-2.5293-2.5186s1.04-2.5879,2.6094-2.5879c1.499,0,2.498,1.0293,2.498,2.498Zm-3.5381,.0498c0,.8398,.3496,1.4697,1,1.4697,.5898,0,.9688-.5898,.9688-1.4697,0-.7295-.2793-1.4697-.9688-1.4697-.7295,0-1,.75-1,1.4697Z" fill="#fff" />
                                                            <path d="M975.3858,304.6907c0-.6094-.0205-1.1289-.04-1.5586h1.3193l.0693,.6699h.0303c.2002-.3105,.6992-.7793,1.5088-.7793,1,0,1.749,.6592,1.749,2.0977v2.8984h-1.5186v-2.708c0-.6299-.2197-1.0596-.7695-1.0596-.4199,0-.6699,.29-.7695,.5703-.04,.0898-.0605,.2393-.0605,.3789v2.8184h-1.5186v-3.3281Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1036.2705,306.4505c.4102,.209,1.0391,.4199,1.6895,.4199,.6992,0,1.0684-.291,1.0684-.7305,0-.4199-.3193-.6592-1.1289-.9492-1.1191-.3896-1.8496-1.0098-1.8496-1.9883,0-1.1504,.96-2.0293,2.5488-2.0293,.7598,0,1.3193,.1602,1.7188,.3398l-.3398,1.2285c-.2695-.1289-.749-.3184-1.4082-.3184-.6602,0-.9805,.2988-.9805,.6484,0,.4297,.3809,.6201,1.25,.9492,1.1895,.4404,1.749,1.0605,1.749,2.0098,0,1.1289-.8701,2.0889-2.7188,2.0889-.7695,0-1.5283-.2002-1.9092-.4102l.3105-1.2588Z" fill="#fff" />
                                                            <path d="M1043.2198,301.7327v1.3994h1.0889v1.1191h-1.0889v1.7695c0,.5898,.1396,.8594,.5996,.8594,.1895,0,.3398-.0195,.4492-.04l.0098,1.1494c-.1992,.0801-.5586,.1299-.9883,.1299-.4902,0-.9004-.1699-1.1406-.4199-.2793-.29-.4199-.7598-.4199-1.4492v-1.999h-.6484v-1.1191h.6484v-1.0596l1.4902-.3398Z" fill="#fff" />
                                                            <path d="M1047.9688,308.0189l-.0898-.4893h-.0303c-.3203,.3896-.8193,.5996-1.3984,.5996-.9902,0-1.5801-.7197-1.5801-1.499,0-1.2695,1.1396-1.8789,2.8691-1.8691v-.0703c0-.2598-.1406-.6289-.8906-.6289-.499,0-1.0293,.1699-1.3486,.3691l-.2803-.9785c.3398-.1904,1.0098-.4297,1.8994-.4297,1.6289,0,2.1494,.959,2.1494,2.1084v1.6982c0,.4707,.0195,.9199,.0693,1.1895h-1.3691Zm-.1807-2.3086c-.7988-.0098-1.418,.1797-1.418,.7695,0,.3906,.2598,.5801,.5996,.5801,.3789,0,.6895-.25,.7891-.5596,.0195-.0801,.0293-.1699,.0293-.2598v-.5303Z" fill="#fff" />
                                                            <path d="M1052.0889,301.7327v1.3994h1.0898v1.1191h-1.0898v1.7695c0,.5898,.1406,.8594,.5996,.8594,.1904,0,.3398-.0195,.4512-.04l.0098,1.1494c-.2002,.0801-.5605,.1299-.9902,.1299-.4893,0-.8994-.1699-1.1387-.4199-.2803-.29-.4199-.7598-.4199-1.4492v-1.999h-.6504v-1.1191h.6504v-1.0596l1.4883-.3398Z" fill="#fff" />
                                                            <path d="M1058.667,306.4505c0,.6387,.0205,1.1592,.041,1.5684h-1.3203l-.0703-.6895h-.0293c-.1895,.3008-.6494,.7998-1.5293,.7998-.9893,0-1.7188-.6201-1.7188-2.1289v-2.8682h1.5293v2.6289c0,.709,.2295,1.1387,.7598,1.1387,.4199,0,.6592-.2891,.7598-.5293,.0391-.0898,.0488-.21,.0488-.3301v-2.9082h1.5293v3.3184Z" fill="#fff" />
                                                            <path d="M1059.8799,306.6907c.2793,.1699,.8594,.3691,1.3096,.3691,.46,0,.6494-.1602,.6494-.4102s-.1504-.3691-.7197-.5586c-1.0088-.3398-1.3994-.8906-1.3896-1.4697,0-.9092,.7803-1.5986,1.9893-1.5986,.5693,0,1.0791,.1289,1.3799,.2793l-.2705,1.0488c-.2197-.1191-.6396-.2793-1.0596-.2793-.3691,0-.5791,.1504-.5791,.4004,0,.2285,.1895,.3496,.79,.5586,.9287,.3203,1.3184,.79,1.3281,1.5098,0,.9092-.7188,1.5791-2.1182,1.5791-.6396,0-1.21-.1396-1.5791-.3398l.2695-1.0889Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="type-box-6" data-name="type-box">
                                                    <g id="type-box-7" data-name="type-box">
                                                        <rect x="799.143" y="295.6859" width="115.8727" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-6" data-name="type-text">
                                                        <g>
                                                            <path d="M822.3838,301.2825h1.5293v5.457h2.6787v1.2793h-4.208v-6.7363Z" fill="#fff" />
                                                            <path d="M829.0127,301.7728c0,.4199-.3193,.7598-.8193,.7598-.4795,0-.7998-.3398-.7891-.7598-.0107-.4395,.3096-.7695,.7988-.7695,.4902,0,.7998,.3301,.8096,.7695Zm-1.5684,6.2461v-4.8867h1.5186v4.8867h-1.5186Z" fill="#fff" />
                                                            <path d="M830.1836,304.6907c0-.6094-.0195-1.1289-.04-1.5586h1.3193l.0703,.6699h.0293c.2002-.3105,.7002-.7793,1.5098-.7793,.999,0,1.749,.6592,1.749,2.0977v2.8984h-1.5195v-2.708c0-.6299-.2197-1.0596-.7695-1.0596-.4199,0-.6699,.29-.7695,.5703-.04,.0898-.0596,.2393-.0596,.3789v2.8184h-1.5195v-3.3281Z" fill="#fff" />
                                                            <path d="M837.2129,306.0599c.0498,.6309,.6699,.9297,1.3789,.9297,.5195,0,.9395-.0703,1.3496-.1992l.2002,1.0293c-.5,.1992-1.1094,.2998-1.7695,.2998-1.6592,0-2.6084-.96-2.6084-2.4893,0-1.2383,.7695-2.6074,2.4688-2.6074,1.5791,0,2.1787,1.2285,2.1787,2.4375,0,.2598-.0303,.4902-.0498,.5996h-3.1484Zm1.7891-1.0391c0-.3691-.1602-.9893-.8594-.9893-.6396,0-.8994,.5791-.9395,.9893h1.7988Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="id-box-6" data-name="id-box">
                                                    <g id="id-box-7" data-name="id-box">
                                                        <rect x="740.7457" y="295.6859" width="58.3973" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-6" data-name="id-text">
                                                        <g>
                                                            <path d="M767.2285,301.3138v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M768.5489,301.4036c.5596-.0898,1.2891-.1387,2.0596-.1387,1.2793,0,2.1084,.2285,2.7578,.7188,.7002,.5195,1.1396,1.3496,1.1396,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7285-.71,.5898-1.7891,.8691-3.1084,.8691-.79,0-1.3496-.0498-1.7295-.0996v-6.6172Zm1.5293,5.498c.1299,.0293,.3398,.0293,.5303,.0293,1.3789,.0098,2.2783-.75,2.2783-2.3584,.0098-1.3994-.8096-2.1387-2.1191-2.1387-.3398,0-.5596,.0303-.6895,.0596v4.4082Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="main-title">
                                                <g>
                                                    <path d="M742.6328,282.2991h4.9414v1.499h-3.1064v1.8477h2.9023v1.4873h-2.9023v3.25h-1.835v-8.084Z" fill="#e9427d" />
                                                    <path d="M749.9883,288.0325c.0596,.7549,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2393,1.2354c-.5996,.2402-1.3311,.3604-2.123,.3604-1.9902,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0605,.7197h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1865-1.0312-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1582Z" fill="#e9427d" />
                                                    <path d="M754.9209,281.8675h1.8232v8.5156h-1.8232v-8.5156Z" fill="#e9427d" />
                                                    <path d="M760.2598,282.8392v1.6787h1.3066v1.3438h-1.3066v2.1221c0,.708,.168,1.0322,.7197,1.0322,.2275,0,.4072-.0244,.5391-.0479l.0127,1.3789c-.2402,.0957-.6719,.1562-1.1875,.1562-.5879,0-1.0801-.2041-1.3672-.5039-.3359-.3477-.5039-.9121-.5039-1.7393v-2.3984h-.7793v-1.3438h.7793v-1.2715l1.7871-.4072Z" fill="#e9427d" />
                                                    <path d="M762.6241,281.8675h1.8232v3.3467h.0244c.1914-.252,.4316-.4443,.7197-.6006,.2637-.1436,.6113-.2275,.9473-.2275,1.1631,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8232v-3.2266c0-.7676-.2637-1.2949-.9473-1.2949-.4795,0-.7793,.3115-.9111,.6357-.0488,.1191-.0605,.2754-.0605,.4072v3.4785h-1.8232v-8.5156Z" fill="#e9427d" />
                                                    <path d="M772.9912,290.3831l-.1084-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6787,.7197-1.1875,0-1.8955-.8633-1.8955-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2432v-.084c0-.3115-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4443l-.3359-1.1758c.4082-.2275,1.2119-.5156,2.2793-.5156,1.9551,0,2.5781,1.1514,2.5781,2.5303v2.0391c0,.5635,.0244,1.1035,.084,1.4277h-1.6426Zm-.2158-2.7705c-.96-.0127-1.7031,.2158-1.7031,.9229,0,.4678,.3115,.6963,.7188,.6963,.4561,0,.8281-.2998,.9482-.6719,.0234-.0957,.0361-.2041,.0361-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M775.9922,286.389c0-.7314-.0234-1.3555-.0479-1.8711h1.5352l.084,.792h.0361c.252-.3721,.7676-.9238,1.7754-.9238,.7549,0,1.3555,.3838,1.6064,.9951h.0244c.2158-.2998,.4795-.5391,.7559-.707,.3232-.1924,.6836-.2881,1.1152-.2881,1.127,0,1.9785,.792,1.9785,2.543v3.4541h-1.7744v-3.1904c0-.8516-.2764-1.3438-.8643-1.3438-.4189,0-.7188,.2881-.8389,.6357-.0479,.1318-.0723,.3242-.0723,.4678v3.4307h-1.7754v-3.2861c0-.7441-.2637-1.248-.8389-1.248-.4678,0-.7441,.3604-.8516,.6602-.0605,.1436-.0723,.3115-.0723,.4561v3.418h-1.7754v-3.9941Z" fill="#e9427d" />
                                                    <path d="M788.7959,282.4075c.5645-.0967,1.3555-.168,2.4707-.168,1.1279,0,1.9316,.2158,2.4707,.6475,.5156,.4082,.8633,1.0791,.8633,1.8711,0,.791-.2637,1.4629-.7432,1.9189-.624,.5879-1.5469,.8516-2.627,.8516-.2393,0-.4551-.0117-.623-.0361v2.8906h-1.8115v-7.9756Zm1.8115,3.6699c.1553,.0361,.3477,.0479,.6113,.0479,.9717,0,1.5713-.4922,1.5713-1.3193,0-.7432-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0361-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M795.708,281.8675h1.8232v3.3467h.0244c.1914-.252,.4316-.4443,.7197-.6006,.2637-.1436,.6113-.2275,.9473-.2275,1.1631,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8232v-3.2266c0-.7676-.2637-1.2949-.9473-1.2949-.4795,0-.7793,.3115-.9111,.6357-.0488,.1191-.0605,.2754-.0605,.4072v3.4785h-1.8232v-8.5156Z" fill="#e9427d" />
                                                    <path d="M806.0743,290.3831l-.1074-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6797,.7197-1.1865,0-1.8945-.8633-1.8945-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2432v-.084c0-.3115-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4443l-.3359-1.1758c.4072-.2275,1.2109-.5156,2.2783-.5156,1.9551,0,2.5791,1.1514,2.5791,2.5303v2.0391c0,.5635,.0234,1.1035,.084,1.4277h-1.6436Zm-.2158-2.7705c-.959-.0127-1.7031,.2158-1.7031,.9229,0,.4678,.3115,.6963,.7197,.6963,.4561,0,.8271-.2998,.9473-.6719,.0244-.0957,.0361-.2041,.0361-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M809.0645,288.7874c.3359,.2041,1.0312,.4443,1.5703,.4443,.5518,0,.7803-.1924,.7803-.4922s-.1797-.4434-.8633-.6719c-1.2119-.4072-1.6797-1.0674-1.668-1.7627,0-1.0908,.9355-1.9189,2.3867-1.9189,.6836,0,1.2959,.1562,1.6553,.3359l-.3232,1.2598c-.2646-.1445-.7676-.3359-1.2715-.3359-.4443,0-.6963,.1797-.6963,.4795,0,.2754,.2285,.4199,.9482,.6719,1.1152,.3838,1.583,.9473,1.5947,1.8105,0,1.0918-.8633,1.8955-2.543,1.8955-.7676,0-1.4512-.168-1.8945-.4082l.3242-1.3076Z" fill="#e9427d" />
                                                    <path d="M815.6875,288.0325c.0596,.7549,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2393,1.2354c-.5996,.2402-1.3311,.3604-2.123,.3604-1.9902,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0605,.7197h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1865-1.0312-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1582Z" fill="#e9427d" />
                                                    <path d="M828.0215,284.0862h-3.0225l-.168,1.1992c.168-.0234,.3115-.0234,.5039-.0234,.7432,0,1.499,.168,2.0508,.5635,.5879,.3955,.9473,1.043,.9473,1.9551,0,1.4512-1.2471,2.7344-3.3467,2.7344-.9473,0-1.7383-.2158-2.1699-.4443l.3232-1.3672c.3477,.168,1.0557,.3838,1.7637,.3838,.7549,0,1.5586-.3594,1.5586-1.1865,0-.8037-.6357-1.2959-2.1943-1.2959-.4326,0-.7324,.0244-1.0557,.0723l.5156-4.0898h4.2939v1.499Z" fill="#e9427d" />
                                                    <path d="M834.8985,286.6292v1.2109h-3.1426v-1.2109h3.1426Z" fill="#e9427d" />
                                                    <path d="M840.2989,282.2991v4.6533c0,1.3916,.5273,2.0996,1.4629,2.0996,.96,0,1.4873-.6719,1.4873-2.0996v-4.6533h1.8232v4.5332c0,2.4951-1.2598,3.6826-3.3701,3.6826-2.0391,0-3.2383-1.1279-3.2383-3.7061v-4.5098h1.835Z" fill="#ea4b84" />
                                                    <path d="M848.2188,283.8343h-2.1709v-1.5352h6.2129v1.5352h-2.207v6.5488h-1.835v-6.5488Z" fill="#ea4b84" />
                                                    <path d="M857.709,290.3831l-.7314-1.4639c-.2998-.5635-.4922-.9834-.7197-1.4512h-.0234c-.168,.4678-.3721,.8877-.624,1.4512l-.6719,1.4639h-2.0869l2.3389-4.0898-2.2549-3.9941h2.0986l.708,1.4756c.2402,.4912,.4199,.8877,.6113,1.3428h.0244c.1914-.5156,.3477-.875,.5518-1.3428l.6836-1.4756h2.0869l-2.2793,3.9463,2.3994,4.1377h-2.1113Z" fill="#ea4b84" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="feltham-urx-table">
                                        <g id="bubbles-group-2" data-name="bubbles-group">
                                            <g id="urx-055-bubble">
                                                <g id="body-6" data-name="body">
                                                    <rect x="685.0474" y="719.5346" width="29.6397" height="2.0011" transform="translate(-270.2903 460.1903) rotate(-30.812)" fill="#e9427d" />
                                                    <circle cx="715.4446" cy="712.3651" r="12.3333" fill="#e9427d" />
                                                    <polygon points="676.5831 733.5355 693.5844 731.406 687.567 720.3851 676.5831 733.5355" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-6" data-name="id">
                                                    <g>
                                                        <path d="M707.5713,710.0218v3.543c0,1.3418,.5957,1.9102,1.3945,1.9102,.8877,0,1.4561-.5869,1.4561-1.9102v-3.543h.7822v3.4902c0,1.8379-.9688,2.5928-2.2646,2.5928-1.2256,0-2.1494-.7012-2.1494-2.5576v-3.5254h.7812Z" fill="#fff" />
                                                        <path d="M712.542,710.1019c.3906-.0801,.9502-.124,1.4834-.124,.8262,0,1.3584,.1504,1.7314,.4883,.3018,.2666,.4707,.6748,.4707,1.1367,0,.79-.4971,1.3145-1.1279,1.5273v.0264c.4619,.1602,.7373,.5859,.8789,1.208,.1953,.835,.3379,1.4121,.4619,1.6426h-.7988c-.0977-.1689-.2314-.6836-.3994-1.4297-.1777-.8262-.498-1.1367-1.1992-1.1631h-.7285v2.5928h-.7725v-5.9053Zm.7725,2.7266h.791c.8252,0,1.3496-.4531,1.3496-1.1367,0-.7725-.5596-1.1104-1.377-1.1191-.373,0-.6387,.0352-.7637,.0713v2.1846Z" fill="#fff" />
                                                        <path d="M720.8799,713.0589c0,1.9629-.7285,3.0459-2.0068,3.0459-1.1279,0-1.8916-1.0566-1.9102-2.9658,0-1.9355,.835-3.002,2.0078-3.002,1.2158,0,1.9092,1.084,1.9092,2.9219Zm-3.1348,.0889c0,1.501,.4619,2.3535,1.1719,2.3535,.7988,0,1.1807-.9326,1.1807-2.4062,0-1.4209-.3633-2.3535-1.1719-2.3535-.6836,0-1.1807,.834-1.1807,2.4062Z" fill="#fff" />
                                                        <path d="M723.293,710.9632h-.0176l-1.0039,.542-.1504-.5957,1.2607-.6748h.666v5.7725h-.7549v-5.0439Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="urx-002-bubble">
                                                <g id="body-7" data-name="body">
                                                    <rect x="840.5442" y="795.1675" width="29.6397" height="2.0011" transform="translate(-305.9756 666.4702) rotate(-36.5749)" fill="#e9427d" />
                                                    <circle cx="868.1073" cy="789.9342" r="12.3333" fill="#e9427d" />
                                                    <polygon points="833.503 811.4407 850.2046 807.6149 843.1109 797.2539 833.503 811.4407" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-7" data-name="id">
                                                    <g>
                                                        <path d="M859.7412,786.6751v3.543c0,1.3408,.5957,1.9092,1.3945,1.9092,.8877,0,1.4561-.5859,1.4561-1.9092v-3.543h.7822v3.4902c0,1.8379-.9688,2.5928-2.2646,2.5928-1.2256,0-2.1494-.7012-2.1494-2.5576v-3.5254h.7812Z" fill="#fff" />
                                                        <path d="M864.7119,786.7552c.3906-.0801,.9502-.125,1.4834-.125,.8252,0,1.3584,.1514,1.7314,.4883,.3018,.2666,.4707,.6758,.4707,1.1367,0,.791-.4971,1.3145-1.1279,1.5283v.0264c.4619,.1602,.7373,.5859,.8789,1.208,.1953,.834,.3379,1.4121,.4619,1.6426h-.7988c-.0977-.1689-.2314-.6836-.3994-1.4297-.1777-.8262-.498-1.1367-1.1992-1.1631h-.7285v2.5928h-.7725v-5.9053Zm.7725,2.7256h.791c.8252,0,1.3496-.4521,1.3496-1.1367,0-.7725-.5596-1.1094-1.377-1.1182-.373,0-.6387,.0352-.7637,.0703v2.1846Z" fill="#fff" />
                                                        <path d="M873.0498,789.7122c0,1.9629-.7285,3.0459-2.0068,3.0459-1.1279,0-1.8916-1.0566-1.9102-2.9658,0-1.9365,.835-3.002,2.0078-3.002,1.2158,0,1.9092,1.083,1.9092,2.9219Zm-3.1348,.0889c0,1.501,.4609,2.3535,1.1719,2.3535,.7988,0,1.1807-.9326,1.1807-2.4072,0-1.4209-.3633-2.3535-1.1719-2.3535-.6836,0-1.1807,.835-1.1807,2.4072Z" fill="#fff" />
                                                        <path d="M873.7666,792.6605v-.4795l.6133-.5947c1.4736-1.4033,2.1396-2.1494,2.1484-3.0195,0-.5859-.2842-1.1279-1.1455-1.1279-.5234,0-.959,.2666-1.2256,.4883l-.248-.5508c.3994-.3369,.9678-.5859,1.6338-.5859,1.2432,0,1.7676,.8525,1.7676,1.6787,0,1.0654-.7734,1.9268-1.9893,3.0986l-.4619,.4268v.0176h2.5928v.6484h-3.6855Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="table-group-2" data-name="table-group">
                                            <g id="urx-001-item-row">
                                                <g id="item-base-box-6" data-name="item-base-box">
                                                    <g id="base-box-7" data-name="base-box">
                                                        <rect x="1005.0903" y="458.199" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-7" data-name="base-text">
                                                        <g>
                                                            <path d="M1035.6094,467.0941c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1040.7901,469.2132c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M1043.3116,464.306v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1045.4424,469.6331c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M1050.5821,464.306v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1055.5108,470.5325l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M1057.6524,467.0042c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1067.3799,463.4359v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M1069.8213,464.3353c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M1071.1817,467.0042c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1080.8994,465.6946c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-6" data-name="item-type-box">
                                                    <g id="type-box-8" data-name="type-box">
                                                        <rect x="799.143" y="458.199" width="94.6498" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="893.7929" y="458.199" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-7" data-name="type-text">
                                                        <g>
                                                            <path d="M812.875,463.7962v3.9873c0,1.5098,.6699,2.1494,1.5693,2.1494,1,0,1.6396-.6602,1.6396-2.1494v-3.9873h.8789v3.9277c0,2.0684-1.0889,2.918-2.5479,2.918-1.3799,0-2.4189-.7891-2.4189-2.8779v-3.9678h.8789Z" fill="#fff" />
                                                            <path d="M818.4453,467.2737c0-.6191-.0195-1.1191-.04-1.5791h.79l.04,.8301h.0195c.3604-.5898,.9297-.9395,1.7197-.9395,1.1689,0,2.0488,.9893,2.0488,2.458,0,1.7393-1.0596,2.5986-2.1992,2.5986-.6396,0-1.1992-.2793-1.4893-.7588h-.0195v2.6279h-.8701v-5.2373Zm.8701,1.29c0,.1299,.0195,.249,.04,.3594,.1592,.6094,.6895,1.0293,1.3193,1.0293,.9287,0,1.4688-.7598,1.4688-1.8691,0-.9688-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0898-.0303,.1094-.0596,.2393-.0596,.3594v.8301Z" fill="#fff" />
                                                            <path d="M823.3965,470.9319l2.7881-7.2461h.6797l-2.7988,7.2461h-.6689Z" fill="#fff" />
                                                            <path d="M827.5957,463.886c.5293-.0801,1.1592-.1396,1.8486-.1396,1.25,0,2.1396,.2891,2.7285,.8389,.5996,.5498,.9502,1.3291,.9502,2.4189,0,1.0996-.3398,1.999-.9697,2.6182-.6299,.6299-1.6689,.9697-2.9785,.9697-.6191,0-1.1396-.0303-1.5791-.0801v-6.626Zm.8691,5.957c.2207,.04,.54,.0498,.8799,.0498,1.8594,0,2.8682-1.04,2.8682-2.8584,.0107-1.5898-.8896-2.5986-2.7285-2.5986-.4492,0-.7891,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M834.2256,467.0042c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M847.6026,467.5735c-.0498-.9395-.1104-2.0684-.1006-2.9082h-.0293c-.2305,.79-.5098,1.6289-.8496,2.5586l-1.1895,3.2686h-.6602l-1.0889-3.208c-.3203-.9502-.5898-1.8193-.7793-2.6191h-.0205c-.0195,.8398-.0703,1.9688-.1299,2.9785l-.1797,2.8887h-.8301l.4697-6.7363h1.1094l1.1494,3.2578c.2803,.8301,.5098,1.5693,.6797,2.2686h.0303c.1699-.6797,.4102-1.4189,.71-2.2686l1.1992-3.2578h1.1094l.4199,6.7363h-.8496l-.1699-2.959Z" fill="#fff" />
                                                            <path d="M852.6739,470.5325l-.0703-.6104h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1396-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9785v1.8096c0,.4189,.0195,.8291,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0508-.1895,.0508-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M855.794,464.3353c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M857.1553,467.0042c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M919.2188,470.5325l-1.709-6.7363h.9189l.7998,3.4082c.2002,.8389,.3799,1.6787,.5,2.3281h.0195c.1104-.6689,.3203-1.4688,.5498-2.3389l.8994-3.3975h.9102l.8193,3.418c.1895,.7998,.3691,1.5986,.4697,2.3086h.0195c.1406-.7393,.3301-1.4893,.54-2.3291l.8896-3.3975h.8896l-1.9092,6.7363h-.9092l-.8496-3.5088c-.21-.8594-.3506-1.5186-.4404-2.1982h-.0195c-.1201,.6699-.2695,1.3291-.5195,2.1982l-.96,3.5088h-.9092Z" fill="#fff" />
                                                            <path d="M930.5967,468.0735c0,1.7891-1.2393,2.5684-2.4082,2.5684-1.3096,0-2.3193-.959-2.3193-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3896,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4697-.79,1.4697-1.8789,0-.8193-.4102-1.8594-1.4492-1.8594s-1.4893,.96-1.4893,1.8789Z" fill="#fff" />
                                                            <path d="M932.5791,467.9134h.0195c.1201-.1699,.29-.3799,.4297-.5498l1.4199-1.6689h1.0586l-1.8682,1.9893,2.1289,2.8486h-1.0703l-1.6689-2.3193-.4492,.5v1.8193h-.8701v-7.0967h.8701v4.4775Z" fill="#fff" />
                                                            <path d="M937.5489,464.3353c.0098,.2998-.21,.54-.5596,.54-.3105,0-.5303-.2402-.5303-.54,0-.3096,.2305-.5488,.5498-.5488,.3301,0,.54,.2393,.54,.5488Zm-.9795,6.1973v-4.8379h.8789v4.8379h-.8789Z" fill="#fff" />
                                                            <path d="M938.9092,467.0042c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M948.627,465.6946c-.0205,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2207,1.7891-.6904,2.209-.4697,.4395-1.1484,.5801-1.7588,.5801-.5791,0-1.2188-.1406-1.6094-.4004l.2207-.6689c.3193,.1992,.8193,.3799,1.4189,.3799,.8994,0,1.5586-.4697,1.5586-1.6895v-.54h-.0195c-.2695,.4502-.7891,.8096-1.5391,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4395,1.5088,.8389h.0205l.04-.7295h.7695Zm-.9102,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9492,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.9004,.0498-.1494,.0693-.3193,.0693-.4697v-.8691Z" fill="#fff" />
                                                            <path d="M950.0489,463.4359h.8789v3.0186h.0205c.1396-.25,.3594-.4697,.6299-.6191,.2598-.1504,.5693-.25,.8994-.25,.6494,0,1.6885,.3994,1.6885,2.0684v2.8789h-.8789v-2.7783c0-.7803-.29-1.4395-1.1191-1.4395-.5703,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0605,.25-.0605,.4199v2.9189h-.8789v-7.0967Z" fill="#fff" />
                                                            <path d="M958.2774,470.5325l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M960.419,467.0042c0-.5-.0098-.9092-.04-1.3096h.7695l.04,.7803h.0303c.2695-.46,.7188-.8896,1.5186-.8896,.6602,0,1.1592,.3994,1.3691,.9688h.0205c.1494-.2695,.3398-.4795,.5391-.6289,.29-.2197,.6104-.3398,1.0703-.3398,.6396,0,1.5889,.4199,1.5889,2.0986v2.8486h-.8594v-2.7383c0-.9297-.3398-1.4893-1.0498-1.4893-.5,0-.8896,.3691-1.0391,.7988-.04,.1201-.0703,.2803-.0703,.4404v2.9883h-.8594v-2.8984c0-.7695-.3398-1.3291-1.0098-1.3291-.5498,0-.9492,.4395-1.0889,.8789-.0508,.1299-.0703,.2803-.0703,.4297v2.9189h-.8594v-3.5283Z" fill="#fff" />
                                                            <path d="M970.9092,463.7962h.8691v6.0068h2.8779v.7295h-3.7471v-6.7363Z" fill="#fff" />
                                                            <path d="M979.3164,470.5325l-.8594-1.4893c-.3496-.5703-.5693-.9395-.7793-1.3291h-.0205c-.1895,.3896-.3799,.749-.7295,1.3389l-.8096,1.4795h-.999l2.0586-3.4082-1.9795-3.3281h1.0098l.8896,1.5791c.25,.4395,.4395,.7793,.6201,1.1396h.0293c.1904-.4004,.3604-.71,.6104-1.1396l.9189-1.5791h1l-2.0498,3.2783,2.0996,3.458h-1.0098Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-6" data-name="item-id-box">
                                                    <g id="id-box-8" data-name="id-box">
                                                        <rect x="740.7457" y="458.199" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-7" data-name="id-text">
                                                        <g>
                                                            <path d="M760.1983,463.8275v3.8779c0,1.1592,.4395,1.749,1.2188,1.749,.7998,0,1.2402-.5596,1.2402-1.749v-3.8779h1.5186v3.7773c0,2.0791-1.0488,3.0684-2.8086,3.0684-1.6992,0-2.6982-.9395-2.6982-3.0879v-3.7578h1.5293Z" fill="#fff" />
                                                            <path d="M765.4991,463.9173c.4893-.0801,1.2188-.1396,2.0283-.1396,1,0,1.6992,.1494,2.1787,.5293,.4004,.3203,.6201,.79,.6201,1.4092,0,.8594-.6094,1.4492-1.1895,1.6592v.0303c.4697,.1895,.7295,.6396,.8994,1.2588,.21,.7598,.4199,1.6396,.5498,1.8994h-1.5596c-.1094-.1904-.2695-.7402-.4697-1.5693-.1797-.8398-.4697-1.0693-1.0889-1.0791h-.46v2.6484h-1.5088v-6.6465Zm1.5088,2.8984h.5996c.7598,0,1.21-.3799,1.21-.9697,0-.6191-.4199-.9297-1.1201-.9395-.3691,0-.5791,.0303-.6895,.0498v1.8594Z" fill="#fff" />
                                                            <path d="M775.9864,467.2855c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6787,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0098,1.4893,.3496,2.1982,.9395,2.1982s.9092-.7393,.9092-2.2188c0-1.4385-.3096-2.1982-.9189-2.1982-.5596,0-.9395,.709-.9297,2.2188Z" fill="#fff" />
                                                            <path d="M778.6778,465.4163h-.0195l-1.2393,.5898-.25-1.1396,1.7188-.7998h1.2598v6.4971h-1.4697v-5.1475Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="urx-002-item-row">
                                                <g id="item-base-box-7" data-name="item-base-box">
                                                    <g id="base-box-8" data-name="base-box">
                                                        <rect x="1005.0903" y="476.324" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-8" data-name="base-text">
                                                        <g>
                                                            <path d="M1035.6094,485.2191c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1040.7901,487.3382c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M1043.3116,482.431v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1045.4424,487.7581c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M1050.5821,482.431v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1055.5108,488.6575l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M1057.6524,485.1292c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1067.3799,481.5609v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M1069.8213,482.4603c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M1071.1817,485.1292c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1080.8994,483.8196c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-7" data-name="item-type-box">
                                                    <g id="type-box-9" data-name="type-box">
                                                        <rect x="799.143" y="476.324" width="94.6498" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="893.7929" y="476.324" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-8" data-name="type-text">
                                                        <g>
                                                            <path d="M812.875,481.9212v3.9873c0,1.5098,.6699,2.1494,1.5693,2.1494,1,0,1.6396-.6602,1.6396-2.1494v-3.9873h.8789v3.9277c0,2.0684-1.0889,2.918-2.5479,2.918-1.3799,0-2.4189-.7891-2.4189-2.8779v-3.9678h.8789Z" fill="#fff" />
                                                            <path d="M818.4453,485.3987c0-.6191-.0195-1.1191-.04-1.5791h.79l.04,.8301h.0195c.3604-.5898,.9297-.9395,1.7197-.9395,1.1689,0,2.0488,.9893,2.0488,2.458,0,1.7393-1.0596,2.5986-2.1992,2.5986-.6396,0-1.1992-.2793-1.4893-.7588h-.0195v2.6279h-.8701v-5.2373Zm.8701,1.29c0,.1299,.0195,.249,.04,.3594,.1592,.6094,.6895,1.0293,1.3193,1.0293,.9287,0,1.4688-.7598,1.4688-1.8691,0-.9688-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0898-.0303,.1094-.0596,.2393-.0596,.3594v.8301Z" fill="#fff" />
                                                            <path d="M823.3965,489.0569l2.7881-7.2461h.6797l-2.7988,7.2461h-.6689Z" fill="#fff" />
                                                            <path d="M827.5957,482.011c.5293-.0801,1.1592-.1396,1.8486-.1396,1.25,0,2.1396,.2891,2.7285,.8389,.5996,.5498,.9502,1.3291,.9502,2.4189,0,1.0996-.3398,1.999-.9697,2.6182-.6299,.6299-1.6689,.9697-2.9785,.9697-.6191,0-1.1396-.0303-1.5791-.0801v-6.626Zm.8691,5.957c.2207,.04,.54,.0498,.8799,.0498,1.8594,0,2.8682-1.04,2.8682-2.8584,.0107-1.5898-.8896-2.5986-2.7285-2.5986-.4492,0-.7891,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M834.2256,485.1292c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M847.6026,485.6985c-.0498-.9395-.1104-2.0684-.1006-2.9082h-.0293c-.2305,.79-.5098,1.6289-.8496,2.5586l-1.1895,3.2686h-.6602l-1.0889-3.208c-.3203-.9502-.5898-1.8193-.7793-2.6191h-.0205c-.0195,.8398-.0703,1.9688-.1299,2.9785l-.1797,2.8887h-.8301l.4697-6.7363h1.1094l1.1494,3.2578c.2803,.8301,.5098,1.5693,.6797,2.2686h.0303c.1699-.6797,.4102-1.4189,.71-2.2686l1.1992-3.2578h1.1094l.4199,6.7363h-.8496l-.1699-2.959Z" fill="#fff" />
                                                            <path d="M852.6739,488.6575l-.0703-.6104h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1396-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9785v1.8096c0,.4189,.0195,.8291,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0508-.1895,.0508-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M855.794,482.4603c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M857.1553,485.1292c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M917.9991,487.598c.3896,.2402,.96,.4395,1.5596,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3604-.9893-1.2695-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8701-1.8291,2.1787-1.8291,.6904,0,1.1895,.1602,1.4893,.3301l-.2393,.71c-.2197-.1201-.6699-.3203-1.2793-.3203-.9199,0-1.2695,.5498-1.2695,1.0098,0,.6299,.4102,.9395,1.3389,1.2988,1.1396,.4404,1.7197,.9893,1.7197,1.9795,0,1.0391-.7695,1.9385-2.3594,1.9385-.6494,0-1.3584-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M924.0489,482.431v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M928.9776,488.6575l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M931.1192,485.3294c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4697-1.1797,1.1299-.0195,.1191-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M936.5391,481.9212h.8691v6.0068h2.8789v.7295h-3.748v-6.7363Z" fill="#fff" />
                                                            <path d="M943.9073,488.6575l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M946.0489,485.1292c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M952.0889,486.3987c.0195,1.1895,.7793,1.6787,1.6592,1.6787,.6299,0,1.0088-.1094,1.3389-.25l.1504,.6299c-.3105,.1396-.8398,.2998-1.6094,.2998-1.4893,0-2.3789-.9795-2.3789-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.0195,.3203-.0293,.4102h-3.3984Zm2.5781-.6299c.0107-.5596-.2295-1.4287-1.2188-1.4287-.8896,0-1.2793,.8193-1.3496,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M958.7588,481.9212h.8691v6.0068h2.8779v.7295h-3.7471v-6.7363Z" fill="#fff" />
                                                            <path d="M967.167,488.6575l-.8604-1.4893c-.3496-.5703-.5693-.9395-.7793-1.3291h-.0195c-.1904,.3896-.3799,.749-.7295,1.3389l-.8096,1.4795h-1l2.0586-3.4082-1.9785-3.3281h1.0098l.8896,1.5791c.249,.4395,.4395,.7793,.6191,1.1396h.0303c.1895-.4004,.3594-.71,.6094-1.1396l.9199-1.5791h.999l-2.0488,3.2783,2.0986,3.458h-1.0088Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-7" data-name="item-id-box">
                                                    <g id="id-box-9" data-name="id-box">
                                                        <rect x="740.7457" y="476.324" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-8" data-name="id-text">
                                                        <g>
                                                            <path d="M760.1983,481.9525v3.8779c0,1.1592,.4395,1.749,1.2188,1.749,.7998,0,1.2402-.5596,1.2402-1.749v-3.8779h1.5186v3.7773c0,2.0791-1.0488,3.0684-2.8086,3.0684-1.6992,0-2.6982-.9395-2.6982-3.0879v-3.7578h1.5293Z" fill="#fff" />
                                                            <path d="M765.4991,482.0423c.4893-.0801,1.2188-.1396,2.0283-.1396,1,0,1.6992,.1494,2.1787,.5293,.4004,.3203,.6201,.79,.6201,1.4092,0,.8594-.6094,1.4492-1.1895,1.6592v.0303c.4697,.1895,.7295,.6396,.8994,1.2588,.21,.7598,.4199,1.6396,.5498,1.8994h-1.5596c-.1094-.1904-.2695-.7402-.4697-1.5693-.1797-.8398-.4697-1.0693-1.0889-1.0791h-.46v2.6484h-1.5088v-6.6465Zm1.5088,2.8984h.5996c.7598,0,1.21-.3799,1.21-.9697,0-.6191-.4199-.9297-1.1201-.9395-.3691,0-.5791,.0303-.6895,.0498v1.8594Z" fill="#fff" />
                                                            <path d="M775.9864,485.4105c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6787,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0098,1.4893,.3496,2.1982,.9395,2.1982s.9092-.7393,.9092-2.2188c0-1.4385-.3096-2.1982-.9189-2.1982-.5596,0-.9395,.709-.9297,2.2188Z" fill="#fff" />
                                                            <path d="M776.7393,488.6888v-.9199l.8389-.7598c1.4199-1.2686,2.1094-1.998,2.1289-2.7578,0-.5303-.3193-.9502-1.0693-.9502-.5596,0-1.0488,.2803-1.3887,.54l-.4307-1.0889c.4902-.3701,1.25-.6699,2.1289-.6699,1.4697,0,2.2793,.8594,2.2793,2.0391,0,1.0889-.79,1.959-1.7295,2.7979l-.5996,.5v.0195h2.4492v1.25h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="title-row-2" data-name="title-row">
                                                <g id="status-box-2" data-name="status-box">
                                                    <g id="base-box-9" data-name="base-box">
                                                        <rect x="1005.0903" y="440.0188" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-9" data-name="base-text">
                                                        <g>
                                                            <path d="M1038.085,450.7825c.4102,.21,1.04,.4199,1.6895,.4199,.6992,0,1.0693-.29,1.0693-.7295,0-.4199-.3193-.6592-1.1289-.9492-1.1201-.3896-1.8496-1.0098-1.8496-1.9893,0-1.1494,.96-2.0283,2.5488-2.0283,.7598,0,1.3193,.1592,1.7188,.3398l-.3398,1.2285c-.2695-.1299-.749-.3193-1.4092-.3193-.6592,0-.9795,.2998-.9795,.6494,0,.4297,.3799,.6201,1.25,.9492,1.1895,.4404,1.749,1.0596,1.749,2.0098,0,1.1289-.8701,2.0889-2.7188,2.0889-.7695,0-1.5293-.2002-1.9092-.4102l.3096-1.2598Z" fill="#fff" />
                                                            <path d="M1045.0342,446.0657v1.3994h1.0898v1.1191h-1.0898v1.7686c0,.5898,.1406,.8604,.5996,.8604,.1904,0,.3398-.0205,.4502-.04l.0098,1.1494c-.1992,.0791-.5596,.1299-.9893,.1299-.4893,0-.8994-.1699-1.1396-.4199-.2793-.29-.4199-.7598-.4199-1.4492v-1.999h-.6494v-1.1191h.6494v-1.0596l1.4893-.3398Z" fill="#fff" />
                                                            <path d="M1049.7842,452.3519l-.0898-.4893h-.0303c-.3203,.3896-.8193,.5996-1.3994,.5996-.9893,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1396-1.8789,2.8682-1.8691v-.0703c0-.2598-.1396-.6299-.8896-.6299-.499,0-1.0293,.1699-1.3486,.3701l-.2803-.9795c.3398-.1895,1.0098-.4297,1.8994-.4297,1.6289,0,2.1484,.96,2.1484,2.1094v1.6982c0,.4697,.0205,.9199,.0703,1.1895h-1.3691Zm-.1807-2.3086c-.7988-.0098-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5801,.5996,.5801,.3799,0,.6895-.25,.79-.5596,.0195-.0801,.0293-.1699,.0293-.2598v-.5303Z" fill="#fff" />
                                                            <path d="M1053.9043,446.0657v1.3994h1.0898v1.1191h-1.0898v1.7686c0,.5898,.1406,.8604,.5996,.8604,.1904,0,.3398-.0205,.4502-.04l.0098,1.1494c-.1992,.0791-.5596,.1299-.9893,.1299-.4893,0-.8994-.1699-1.1396-.4199-.2793-.29-.4199-.7598-.4199-1.4492v-1.999h-.6494v-1.1191h.6494v-1.0596l1.4893-.3398Z" fill="#fff" />
                                                            <path d="M1060.4825,450.7825c0,.6396,.0205,1.1602,.04,1.5693h-1.3193l-.0703-.6895h-.0293c-.1904,.2998-.6494,.7998-1.5293,.7998-.9893,0-1.7188-.6201-1.7188-2.1289v-2.8682h1.5283v2.6279c0,.71,.2305,1.1396,.7598,1.1396,.4199,0,.6602-.29,.7598-.5293,.04-.0908,.0498-.21,.0498-.3301v-2.9082h1.5293v3.3174Z" fill="#fff" />
                                                            <path d="M1061.6953,451.0228c.2793,.1699,.8594,.3701,1.3096,.3701,.459,0,.6494-.1602,.6494-.4102s-.1504-.3701-.7197-.5596c-1.0098-.3398-1.3994-.8896-1.3896-1.4688,0-.9102,.7803-1.5996,1.9893-1.5996,.5693,0,1.0791,.1299,1.3789,.2803l-.2695,1.0488c-.2197-.1201-.6396-.2793-1.0596-.2793-.3701,0-.5791,.1494-.5791,.3994,0,.2295,.1895,.3496,.7891,.5596,.9297,.3203,1.3193,.79,1.3291,1.5098,0,.9092-.7197,1.5791-2.1182,1.5791-.6396,0-1.21-.1406-1.5801-.3398l.2705-1.0898Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="type-box-10" data-name="type-box">
                                                    <g id="type-box-11" data-name="type-box">
                                                        <rect x="799.143" y="440.0188" width="94.6498" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="893.7929" y="440.0188" width="115.8727" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-9" data-name="type-text">
                                                        <g>
                                                            <path d="M812.0274,445.6155h1.5293v5.457h2.6787v1.2793h-4.208v-6.7363Z" fill="#fff" />
                                                            <path d="M818.6563,446.1058c0,.4199-.3193,.7598-.8193,.7598-.4795,0-.7998-.3398-.7891-.7598-.0107-.4404,.3096-.7695,.7988-.7695,.4902,0,.7998,.3291,.8096,.7695Zm-1.5684,6.2461v-4.8867h1.5186v4.8867h-1.5186Z" fill="#fff" />
                                                            <path d="M819.8272,449.0237c0-.6094-.0195-1.1289-.04-1.5586h1.3193l.0703,.6689h.0293c.2002-.3096,.7002-.7793,1.5098-.7793,.999,0,1.749,.6602,1.749,2.0986v2.8984h-1.5195v-2.708c0-.6299-.2197-1.0596-.7695-1.0596-.4199,0-.6699,.29-.7695,.5693-.04,.0898-.0596,.2402-.0596,.3799v2.8184h-1.5195v-3.3281Z" fill="#fff" />
                                                            <path d="M826.8565,450.3929c.0498,.6299,.6699,.9297,1.3789,.9297,.5195,0,.9395-.0703,1.3496-.2002l.2002,1.0303c-.5,.1992-1.1094,.2998-1.7695,.2998-1.6592,0-2.6084-.96-2.6084-2.4893,0-1.2393,.7695-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2295,2.1787,2.4385,0,.2598-.0303,.4902-.0498,.5996h-3.1484Zm1.7891-1.0391c0-.3701-.1602-.9893-.8594-.9893-.6396,0-.8994,.5791-.9395,.9893h1.7988Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M917.0342,445.6155h1.5293v5.457h2.6777v1.2793h-4.207v-6.7363Z" fill="#fff" />
                                                            <path d="M923.6631,446.1058c0,.4199-.3203,.7598-.8193,.7598-.4805,0-.7998-.3398-.79-.7598-.0098-.4404,.3096-.7695,.7998-.7695,.4893,0,.7998,.3291,.8096,.7695Zm-1.5693,6.2461v-4.8867h1.5195v4.8867h-1.5195Z" fill="#fff" />
                                                            <path d="M924.834,449.0237c0-.6094-.0205-1.1289-.04-1.5586h1.3193l.0693,.6689h.0303c.2002-.3096,.6992-.7793,1.5088-.7793,1,0,1.749,.6602,1.749,2.0986v2.8984h-1.5186v-2.708c0-.6299-.2197-1.0596-.7695-1.0596-.4199,0-.6699,.29-.7695,.5693-.04,.0898-.0605,.2402-.0605,.3799v2.8184h-1.5186v-3.3281Z" fill="#fff" />
                                                            <path d="M931.8623,450.3929c.0508,.6299,.6699,.9297,1.3799,.9297,.5195,0,.9395-.0703,1.3486-.2002l.2002,1.0303c-.5,.1992-1.1094,.2998-1.7686,.2998-1.6592,0-2.6094-.96-2.6094-2.4893,0-1.2393,.7705-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2295,2.1787,2.4385,0,.2598-.0293,.4902-.0498,.5996h-3.1484Zm1.7891-1.0391c0-.3701-.1592-.9893-.8594-.9893-.6396,0-.8994,.5791-.9395,.9893h1.7988Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="id-box-10" data-name="id-box">
                                                    <g id="id-box-11" data-name="id-box">
                                                        <rect x="740.7457" y="440.0188" width="58.3973" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-9" data-name="id-text">
                                                        <g>
                                                            <path d="M767.2285,445.6468v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M768.5489,445.7366c.5596-.0898,1.2891-.1396,2.0596-.1396,1.2793,0,2.1084,.2295,2.7578,.7197,.7002,.5195,1.1396,1.3496,1.1396,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7275-.71,.5898-1.7891,.8701-3.1084,.8701-.79,0-1.3496-.0498-1.7295-.0996v-6.6172Zm1.5293,5.4971c.1299,.0303,.3398,.0303,.5303,.0303,1.3789,.0098,2.2783-.75,2.2783-2.3584,.0098-1.3994-.8096-2.1387-2.1191-2.1387-.3398,0-.5596,.0293-.6895,.0596v4.4072Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="main-title-2" data-name="main-title">
                                                <g>
                                                    <path d="M742.6328,426.6321h4.9414v1.499h-3.1064v1.8477h2.9023v1.4873h-2.9023v3.25h-1.835v-8.084Z" fill="#e9427d" />
                                                    <path d="M749.9883,432.3655c.0596,.7549,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2393,1.2354c-.5996,.2402-1.3311,.3604-2.123,.3604-1.9902,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0605,.7197h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1865-1.0312-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1582Z" fill="#e9427d" />
                                                    <path d="M754.9209,426.2005h1.8232v8.5156h-1.8232v-8.5156Z" fill="#e9427d" />
                                                    <path d="M760.2598,427.1722v1.6787h1.3066v1.3438h-1.3066v2.1221c0,.708,.168,1.0322,.7197,1.0322,.2275,0,.4072-.0244,.5391-.0479l.0127,1.3789c-.2402,.0957-.6719,.1562-1.1875,.1562-.5879,0-1.0801-.2041-1.3672-.5039-.3359-.3477-.5039-.9121-.5039-1.7393v-2.3984h-.7793v-1.3438h.7793v-1.2715l1.7871-.4072Z" fill="#e9427d" />
                                                    <path d="M762.6241,426.2005h1.8232v3.3467h.0244c.1914-.252,.4316-.4443,.7197-.6006,.2637-.1436,.6113-.2275,.9473-.2275,1.1631,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8232v-3.2266c0-.7676-.2637-1.2949-.9473-1.2949-.4795,0-.7793,.3115-.9111,.6357-.0488,.1191-.0605,.2754-.0605,.4072v3.4785h-1.8232v-8.5156Z" fill="#e9427d" />
                                                    <path d="M772.9912,434.7161l-.1084-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6787,.7197-1.1875,0-1.8955-.8633-1.8955-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2432v-.084c0-.3115-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4443l-.3359-1.1758c.4082-.2275,1.2119-.5156,2.2793-.5156,1.9551,0,2.5781,1.1514,2.5781,2.5303v2.0391c0,.5635,.0244,1.1035,.084,1.4277h-1.6426Zm-.2158-2.7705c-.96-.0127-1.7031,.2158-1.7031,.9229,0,.4678,.3115,.6963,.7188,.6963,.4561,0,.8281-.2998,.9482-.6719,.0234-.0957,.0361-.2041,.0361-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M775.9922,430.722c0-.7314-.0234-1.3555-.0479-1.8711h1.5352l.084,.792h.0361c.252-.3721,.7676-.9238,1.7754-.9238,.7549,0,1.3555,.3838,1.6064,.9951h.0244c.2158-.2998,.4795-.5391,.7559-.707,.3232-.1924,.6836-.2881,1.1152-.2881,1.127,0,1.9785,.792,1.9785,2.543v3.4541h-1.7744v-3.1904c0-.8516-.2764-1.3438-.8643-1.3438-.4189,0-.7188,.2881-.8389,.6357-.0479,.1318-.0723,.3242-.0723,.4678v3.4307h-1.7754v-3.2861c0-.7441-.2637-1.248-.8389-1.248-.4678,0-.7441,.3604-.8516,.6602-.0605,.1436-.0723,.3115-.0723,.4561v3.418h-1.7754v-3.9941Z" fill="#e9427d" />
                                                    <path d="M788.7959,426.7405c.5645-.0967,1.3555-.168,2.4707-.168,1.1279,0,1.9316,.2158,2.4707,.6475,.5156,.4082,.8633,1.0791,.8633,1.8711,0,.791-.2637,1.4629-.7432,1.9189-.624,.5879-1.5469,.8516-2.627,.8516-.2393,0-.4551-.0117-.623-.0361v2.8906h-1.8115v-7.9756Zm1.8115,3.6699c.1553,.0361,.3477,.0479,.6113,.0479,.9717,0,1.5713-.4922,1.5713-1.3193,0-.7432-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0361-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M795.708,426.2005h1.8232v3.3467h.0244c.1914-.252,.4316-.4443,.7197-.6006,.2637-.1436,.6113-.2275,.9473-.2275,1.1631,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8232v-3.2266c0-.7676-.2637-1.2949-.9473-1.2949-.4795,0-.7793,.3115-.9111,.6357-.0488,.1191-.0605,.2754-.0605,.4072v3.4785h-1.8232v-8.5156Z" fill="#e9427d" />
                                                    <path d="M806.0743,434.7161l-.1074-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6797,.7197-1.1865,0-1.8945-.8633-1.8945-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2432v-.084c0-.3115-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4443l-.3359-1.1758c.4072-.2275,1.2109-.5156,2.2783-.5156,1.9551,0,2.5791,1.1514,2.5791,2.5303v2.0391c0,.5635,.0234,1.1035,.084,1.4277h-1.6436Zm-.2158-2.7705c-.959-.0127-1.7031,.2158-1.7031,.9229,0,.4678,.3115,.6963,.7197,.6963,.4561,0,.8271-.2998,.9473-.6719,.0244-.0957,.0361-.2041,.0361-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M809.0645,433.1204c.3359,.2041,1.0312,.4443,1.5703,.4443,.5518,0,.7803-.1924,.7803-.4922s-.1797-.4434-.8633-.6719c-1.2119-.4072-1.6797-1.0674-1.668-1.7627,0-1.0908,.9355-1.9189,2.3867-1.9189,.6836,0,1.2959,.1562,1.6553,.3359l-.3232,1.2598c-.2646-.1445-.7676-.3359-1.2715-.3359-.4443,0-.6963,.1797-.6963,.4795,0,.2754,.2285,.4199,.9482,.6719,1.1152,.3838,1.583,.9473,1.5947,1.8105,0,1.0918-.8633,1.8955-2.543,1.8955-.7676,0-1.4512-.168-1.8945-.4082l.3242-1.3076Z" fill="#e9427d" />
                                                    <path d="M815.6875,432.3655c.0596,.7549,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2393,1.2354c-.5996,.2402-1.3311,.3604-2.123,.3604-1.9902,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0605,.7197h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1865-1.0312-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1582Z" fill="#e9427d" />
                                                    <path d="M828.0215,428.4193h-3.0225l-.168,1.1992c.168-.0234,.3115-.0234,.5039-.0234,.7432,0,1.499,.168,2.0508,.5635,.5879,.3955,.9473,1.043,.9473,1.9551,0,1.4512-1.2471,2.7344-3.3467,2.7344-.9473,0-1.7383-.2158-2.1699-.4443l.3232-1.3672c.3477,.168,1.0557,.3838,1.7637,.3838,.7549,0,1.5586-.3594,1.5586-1.1865,0-.8037-.6357-1.2959-2.1943-1.2959-.4326,0-.7324,.0244-1.0557,.0723l.5156-4.0898h4.2939v1.499Z" fill="#e9427d" />
                                                    <path d="M834.8985,430.9622v1.2109h-3.1426v-1.2109h3.1426Z" fill="#e9427d" />
                                                    <path d="M840.2989,426.6321v4.6533c0,1.3916,.5273,2.0996,1.4629,2.0996,.96,0,1.4873-.6719,1.4873-2.0996v-4.6533h1.8232v4.5332c0,2.4951-1.2598,3.6826-3.3701,3.6826-2.0391,0-3.2383-1.1279-3.2383-3.7061v-4.5098h1.835Z" fill="#ea4b84" />
                                                    <path d="M846.6592,426.7405c.5879-.0967,1.4639-.168,2.4346-.168,1.2002,0,2.0391,.1797,2.6152,.6357,.4795,.3838,.7432,.9473,.7432,1.6904,0,1.0322-.7314,1.7393-1.4268,1.9912v.0361c.5635,.2275,.875,.7676,1.0791,1.5107,.252,.9121,.5039,1.9668,.6592,2.2793h-1.8701c-.1318-.2285-.3242-.8877-.5645-1.8828-.2158-1.0078-.5635-1.2842-1.3066-1.2959h-.5518v3.1787h-1.8115v-7.9756Zm1.8115,3.4775h.7197c.9111,0,1.4512-.4551,1.4512-1.1631,0-.7432-.5039-1.1152-1.3438-1.127-.4434,0-.6953,.0352-.8271,.0596v2.2305Z" fill="#ea4b84" />
                                                    <path d="M858.1289,434.7161l-.7314-1.4639c-.2998-.5635-.4922-.9834-.7197-1.4512h-.0234c-.168,.4678-.3721,.8877-.624,1.4512l-.6719,1.4639h-2.0869l2.3389-4.0898-2.2549-3.9941h2.0986l.708,1.4756c.2402,.4912,.4199,.8877,.6113,1.3428h.0244c.1914-.5156,.3477-.875,.5518-1.3428l.6836-1.4756h2.0869l-2.2793,3.9463,2.3994,4.1377h-2.1113Z" fill="#ea4b84" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="feltham-equipment-bases-group" className={props.equipmentBasesDisplayToggle}>
                                <g id="feltham-psp-group">
                                    <g id="asp-001">
                                        <g id="base">
                                            <rect x="201.0842" y="113.2589" width="26.6563" height="7.3337" transform="translate(162.6726 -121.7525) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="unit">
                                            <rect x="206.6327" y="108.4634" width="26.6563" height="7.3337" transform="translate(160.9648 -127.6101) rotate(49.1639)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name">
                                            <g>
                                                <path d="M211.2305,109.8411l-2.7061,1.125-.772-.8945,8.6567-3.5098,.9014,1.043-4.7183,8.0664-.7983-.9219,1.4868-2.5352-2.0503-2.373Zm2.5718,1.6113l1.3594-2.3203c.3086-.5254,.6196-.9688,.9204-1.4023l-.0166-.0195c-.4829,.2441-.9844,.4863-1.5024,.709l-2.5024,1.0176,1.7417,2.0156Z" fill="#fff" />
                                                <path d="M214.3892,115.6243c.0967,.5938,.3872,1.3301,.9019,1.9258,.7637,.8848,1.6768,.998,2.3516,.4141,.6255-.541,.6743-1.207,.2407-2.4121-.5566-1.4258-.5742-2.5898,.3691-3.4062,1.0425-.9004,2.564-.7051,3.688,.5957,.5918,.6836,.8623,1.3184,.9502,1.7617l-.9102,.3711c-.0703-.3203-.2583-.9395-.7812-1.5449-.7896-.9141-1.6357-.7891-2.0923-.3945-.626,.541-.5815,1.2129-.1406,2.4473,.5415,1.5078,.4932,2.5566-.4893,3.4043-1.0332,.8945-2.5874,.9004-3.9512-.6777-.5581-.6445-.979-1.5137-1.0503-2.0762l.9136-.4082Z" fill="#fff" />
                                                <path d="M224.2813,115.8314c.4302,.3574,.9619,.8516,1.5625,1.5469,.7383,.8535,1.0796,1.6504,1.0664,2.3574-.0005,.6406-.2983,1.2812-.894,1.7949-.606,.5234-1.2373,.7578-1.876,.7188-.8818-.0352-1.7734-.5645-2.5024-1.4082-.2231-.2578-.4189-.5059-.5415-.748l-2.6807,2.3164-.7466-.8633,6.6123-5.7148Zm-2.48,3.6523c.1138,.2324,.2998,.4883,.5488,.7754,.9009,1.043,1.9561,1.2402,2.8794,.4414,.8838-.7637,.77-1.7578-.0537-2.7109-.3257-.377-.6045-.6387-.7729-.7539l-2.6016,2.248Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="psp-002">
                                        <g id="base-2" data-name="base">
                                            <rect x="322.3917" y="271.1181" width="26.6563" height="7.3337" transform="translate(324.091 -158.8963) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="unit-2" data-name="unit">
                                            <rect x="327.9403" y="266.3226" width="26.6563" height="7.3337" transform="translate(322.3831 -164.7539) rotate(49.1639)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-2" data-name="name">
                                            <g>
                                                <path d="M336.1099,262.7239c.4302,.3574,.9624,.8516,1.5625,1.5449,.7383,.8545,1.0796,1.6523,1.0664,2.3574-.0005,.6426-.2983,1.2812-.894,1.7969-.606,.5234-1.2373,.7559-1.876,.7188-.8818-.0361-1.7734-.5664-2.5024-1.4102-.2231-.2578-.4189-.5059-.5415-.7461l-2.6807,2.3164-.7466-.8643,6.6123-5.7139Zm-2.48,3.6523c.1138,.2314,.2998,.4863,.5488,.7744,.9009,1.042,1.9561,1.2412,2.8794,.4424,.8838-.7637,.77-1.7588-.0537-2.7119-.3257-.377-.6045-.6396-.7729-.7539l-2.6016,2.249Z" fill="#fff" />
                                                <path d="M335.0108,272.6907c.0972,.5918,.3872,1.3301,.9023,1.9258,.7637,.8828,1.6768,.9961,2.3516,.4131,.6255-.54,.6743-1.208,.2402-2.4111-.5562-1.4268-.5742-2.5918,.3691-3.4062,1.0425-.9014,2.564-.7061,3.688,.5938,.5918,.6855,.8623,1.3184,.9502,1.7637l-.9102,.3711c-.0703-.3223-.2578-.9404-.7808-1.5459-.79-.9141-1.6362-.7891-2.0928-.3945-.626,.54-.5811,1.2139-.1401,2.4463,.541,1.5098,.4927,2.5566-.4897,3.4062-1.0332,.8926-2.5874,.9004-3.9512-.6777-.5581-.6465-.979-1.5137-1.0503-2.0771l.9136-.4072Z" fill="#fff" />
                                                <path d="M344.9024,272.8958c.4302,.3574,.9624,.8525,1.5625,1.5469,.7383,.8535,1.0801,1.6523,1.0664,2.3574-.0005,.6406-.2983,1.2812-.894,1.7949-.606,.5234-1.2373,.7578-1.876,.7197-.8818-.0361-1.7734-.5654-2.5024-1.4092-.2231-.2578-.4189-.5059-.5415-.748l-2.6807,2.3164-.7466-.8633,6.6123-5.7148Zm-2.48,3.6523c.1138,.2324,.2998,.4883,.5488,.7754,.9009,1.043,1.9561,1.2402,2.8794,.4424,.8838-.7637,.77-1.7588-.0537-2.7119-.3257-.377-.6045-.6387-.7729-.7539l-2.6016,2.248Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="psp-003">
                                        <g id="base-3" data-name="base">
                                            <rect x="593.8884" y="987.5316" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="unit-3" data-name="unit">
                                            <rect x="593.8884" y="980.198" width="26.6563" height="7.3337" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-3" data-name="name">
                                            <g>
                                                <path d="M599.3457,989.0052l-.9189,2.7822h-1.1807l3.0049-8.8447h1.3779l3.0186,8.8447h-1.2207l-.9453-2.7822h-3.1357Zm2.9004-.8926l-.8662-2.5449c-.1973-.5781-.3281-1.1025-.46-1.6143h-.0254c-.1318,.5244-.2764,1.0625-.4463,1.6006l-.8662,2.5586h2.6641Z" fill="#fff" />
                                                <path d="M605.7862,990.3968c.5117,.3145,1.2598,.5771,2.0469,.5771,1.168,0,1.8506-.6172,1.8506-1.5088,0-.8271-.4727-1.2998-1.667-1.7588-1.4434-.5117-2.3359-1.2598-2.3359-2.5068,0-1.3779,1.1426-2.4014,2.8613-2.4014,.9053,0,1.5615,.21,1.9551,.4336l-.3145,.9316c-.2891-.1572-.8799-.4199-1.6797-.4199-1.208,0-1.667,.7217-1.667,1.3252,0,.8271,.5381,1.2334,1.7588,1.7061,1.4961,.5771,2.2568,1.2988,2.2568,2.5977,0,1.3652-1.0107,2.5459-3.0967,2.5459-.8535,0-1.7852-.249-2.2578-.5635l.2891-.958Z" fill="#fff" />
                                                <path d="M612.4112,983.0482c.5518-.0918,1.2734-.1709,2.1914-.1709,1.1289,0,1.9551,.2627,2.4805,.7344,.4854,.4199,.7744,1.0635,.7744,1.8506,0,.8008-.2363,1.4307-.6826,1.8896-.6035,.6436-1.5879,.9717-2.7031,.9717-.3408,0-.6562-.0137-.9189-.0791v3.543h-1.1416v-8.7393Zm1.1416,4.2646c.25,.0654,.5645,.0918,.9453,.0918,1.3779,0,2.2178-.6689,2.2178-1.8896,0-1.168-.8271-1.7324-2.0869-1.7324-.498,0-.8789,.0391-1.0762,.0918v3.4385Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="feltham-loc-group">
                                    <g id="loc-001_copy" data-name="loc-001 copy">
                                        <g id="base-4" data-name="base">
                                            <rect x="161.0867" y="79.2293" width="26.6563" height="7.3337" transform="translate(123.0825 -103.2686) rotate(49.1637)" fill="#e9427d" />
                                        </g>
                                        <g id="unit-4" data-name="unit">
                                            <rect x="166.6352" y="74.4338" width="26.6563" height="7.3337" transform="translate(121.3747 -109.1262) rotate(49.1637)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-4" data-name="name">
                                            <g>
                                                <path d="M174.0127,69.7982l.7466,.8643-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7236,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M179.3941,82.6458c-2.3037,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2295-4.1914,.7959-5.9424,2.124-1.8359,4.6558-1.7178,6.2007,.0703,1.5786,1.8262,1.1997,4.2178-.7856,5.9336Zm-4.5972-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6343-.2588,1.3301-1.1494,2.1196-3.0459,.8755-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M181.2056,91.2044c-.4331-.1807-1.1411-.6787-1.8447-1.4932-1.6304-1.8857-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0171-.2256-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-002">
                                        <g id="base-5" data-name="base">
                                            <rect x="239.2936" y="152.4024" width="26.6563" height="7.3337" transform="translate(205.5113 -137.1133) rotate(49.1637)" fill="#e9427d" />
                                        </g>
                                        <g id="unit-5" data-name="unit">
                                            <rect x="244.8421" y="147.6069" width="26.6563" height="7.3337" transform="translate(203.8035 -142.9709) rotate(49.1637)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-5" data-name="name">
                                            <g>
                                                <path d="M252.2198,142.972l.7466,.8633-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7227,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M257.6011,155.8196c-2.3037,1.9902-4.7344,1.6465-6.2109-.0625-1.5273-1.7656-1.2295-4.1914,.7959-5.9414,2.124-1.8359,4.6558-1.7188,6.2007,.0703,1.5786,1.8262,1.1997,4.2168-.7856,5.9336Zm-4.5972-5.0996c-1.4297,1.2363-2.041,3.1191-.8662,4.4785,1.1846,1.3711,3.1152,1.0547,4.6343-.2578,1.3301-1.1504,2.1196-3.0469,.8755-4.4863-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M259.4126,164.3782c-.4331-.1816-1.1411-.6797-1.8447-1.4941-1.6304-1.8848-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0332-.8887-1.5898-1.3555-1.5684-3.2598-1.7441-5.0171-.2266-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7715Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-003">
                                        <g id="base-6" data-name="base">
                                            <rect x="379.3076" y="311.8728" width="26.6563" height="7.3337" transform="translate(374.6239 -187.8526) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="unit-6" data-name="unit">
                                            <rect x="384.8561" y="307.0773" width="26.6563" height="7.3337" transform="translate(372.9161 -193.7102) rotate(49.1639)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-6" data-name="name">
                                            <g>
                                                <path d="M392.2744,302.4495l.7466,.8643-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7236,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M397.6558,315.2972c-2.3037,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2295-4.1914,.7959-5.9424,2.124-1.8359,4.6558-1.7178,6.2007,.0703,1.5786,1.8262,1.1997,4.2178-.7856,5.9336Zm-4.5972-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6343-.2588,1.3301-1.1494,2.1196-3.0459,.8755-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M399.4673,323.8558c-.4331-.1807-1.1411-.6787-1.8447-1.4932-1.6304-1.8857-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0171-.2256-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-004">
                                        <g id="base-7" data-name="base">
                                            <rect x="468.3945" y="421.5778" width="26.6563" height="7.3337" transform="translate(488.4583 -217.285) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="unit-7" data-name="unit">
                                            <rect x="473.9431" y="416.7823" width="26.6563" height="7.3337" transform="translate(486.7505 -223.1427) rotate(49.1639)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-7" data-name="name">
                                            <g>
                                                <path d="M481.3653,412.1517l.7466,.8643-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7236,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M486.7466,424.9993c-2.3037,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2295-4.1914,.7959-5.9424,2.124-1.8359,4.6558-1.7178,6.2007,.0703,1.5786,1.8262,1.1997,4.2178-.7856,5.9336Zm-4.5972-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6343-.2588,1.3301-1.1494,2.1196-3.0459,.8755-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M488.5581,433.5579c-.4331-.1807-1.1411-.6787-1.8447-1.4932-1.6304-1.8857-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0171-.2256-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-005">
                                        <g id="base-8" data-name="base">
                                            <rect x="594.586" y="582.2675" width="26.6563" height="7.3337" transform="translate(653.7086 -257.1443) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="unit-8" data-name="unit">
                                            <rect x="600.1346" y="577.472" width="26.6563" height="7.3337" transform="translate(652.0008 -263.002) rotate(49.1639)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-8" data-name="name">
                                            <g>
                                                <path d="M607.5557,572.8411l.7461,.8643-5.9668,5.1562,2.4717,2.8594-.7256,.627-3.2168-3.7236,6.6914-5.7832Z" fill="#fff" />
                                                <path d="M612.9366,585.6888c-2.3027,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2285-4.1914,.7969-5.9424,2.123-1.8359,4.6553-1.7178,6.2002,.0703,1.5791,1.8262,1.2002,4.2178-.7861,5.9336Zm-4.5967-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6348-.2588,1.3301-1.1494,2.1191-3.0459,.875-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M614.7491,594.2474c-.4336-.1807-1.1416-.6787-1.8457-1.4932-1.6299-1.8857-1.666-4.334,.5293-6.2305,2.0938-1.8105,4.7402-1.6191,6.5352,.457,.7207,.834,.9961,1.5137,1.0742,1.8457l-.8848,.4004c-.125-.4648-.4082-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0176-.2256-1.6387,1.416-1.875,3.2695-.4678,4.8965,.4551,.5273,1.0264,.9688,1.4961,1.1699l-.5303,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-006">
                                        <g id="base-9" data-name="base">
                                            <rect x="652.2032" y="649.5748" width="26.6563" height="7.3337" transform="translate(724.574 -277.4412) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="unit-9" data-name="unit">
                                            <rect x="657.7517" y="644.7794" width="26.6563" height="7.3337" transform="translate(722.8662 -283.2988) rotate(49.1639)" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-9" data-name="name">
                                            <g>
                                                <path d="M665.1787,640.1497l.7461,.8633-5.9668,5.1562,2.4717,2.8594-.7256,.627-3.2168-3.7227,6.6914-5.7832Z" fill="#fff" />
                                                <path d="M670.5596,652.9974c-2.3027,1.9902-4.7344,1.6465-6.2109-.0625-1.5273-1.7656-1.2285-4.1914,.7969-5.9414,2.123-1.8359,4.6553-1.7188,6.2002,.0703,1.5791,1.8262,1.2002,4.2168-.7861,5.9336Zm-4.5967-5.0996c-1.4297,1.2363-2.041,3.1191-.8662,4.4785,1.1846,1.3711,3.1152,1.0547,4.6348-.2578,1.3301-1.1504,2.1191-3.0469,.875-4.4863-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M672.3721,661.556c-.4336-.1816-1.1416-.6797-1.8457-1.4941-1.6299-1.8848-1.666-4.334,.5293-6.2305,2.0938-1.8105,4.7402-1.6191,6.5352,.457,.7207,.834,.9961,1.5137,1.0742,1.8457l-.8848,.4004c-.125-.4648-.4082-1.0332-.8887-1.5898-1.3555-1.5684-3.2598-1.7441-5.0176-.2266-1.6387,1.416-1.875,3.2695-.4678,4.8965,.4551,.5273,1.0264,.9688,1.4961,1.1699l-.5303,.7715Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-007">
                                        <g id="base-10" data-name="base">
                                            <rect x="682.1673" y="774.9779" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="unit-10" data-name="unit">
                                            <rect x="682.1673" y="767.6442" width="26.6563" height="7.3337" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-10" data-name="name">
                                            <g>
                                                <path d="M685.3252,770.3929h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M698.5645,774.723c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M706.2246,778.9485c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-008">
                                        <g id="base-11" data-name="base">
                                            <rect x="673.4552" y="866.2941" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="unit-11" data-name="unit">
                                            <rect x="673.4552" y="858.9604" width="26.6563" height="7.3337" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-11" data-name="name">
                                            <g>
                                                <path d="M676.6123,861.7093h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M689.8516,866.0394c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M697.5118,870.265c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-009">
                                        <g id="base-12" data-name="base">
                                            <rect x="686.7833" y="997.4063" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="unit-12" data-name="unit">
                                            <rect x="686.7833" y="990.0727" width="26.6563" height="7.3337" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-12" data-name="name">
                                            <g>
                                                <path d="M689.9414,992.8216h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M703.1807,997.1517c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M710.8409,1001.3773c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-010">
                                        <g id="base-13" data-name="base">
                                            <rect x="673.8661" y="1226.4499" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="unit-13" data-name="unit">
                                            <rect x="673.8661" y="1219.1162" width="26.6563" height="7.3337" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-13" data-name="name">
                                            <g>
                                                <path d="M677.0235,1221.8646h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M690.2627,1226.1946c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M697.9229,1230.4202c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-011">
                                        <g id="base-14" data-name="base">
                                            <rect x="684.4201" y="1266.9048" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="unit-14" data-name="unit">
                                            <rect x="684.4201" y="1259.5711" width="26.6563" height="7.3337" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-14" data-name="name">
                                            <g>
                                                <path d="M687.5782,1262.3196h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M700.8174,1266.6497c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M708.4776,1270.8753c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-012">
                                        <g id="base-15" data-name="base">
                                            <rect x="601.7349" y="1386.959" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="unit-15" data-name="unit">
                                            <rect x="601.7349" y="1379.6254" width="26.6563" height="7.3337" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-15" data-name="name">
                                            <g>
                                                <path d="M604.8926,1382.3743h1.1416v7.8867h3.7793v.957h-4.9209v-8.8438Z" fill="#fff" />
                                                <path d="M618.1319,1386.7044c0,3.0449-1.8496,4.6582-4.1074,4.6582-2.3359,0-3.9756-1.8105-3.9756-4.4883,0-2.8066,1.7451-4.6445,4.1074-4.6445,2.4141,0,3.9756,1.8496,3.9756,4.4746Zm-6.8633,.1445c0,1.8887,1.0244,3.582,2.8213,3.582,1.8115,0,2.835-1.666,2.835-3.6738,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M625.792,1390.929c-.4199,.2109-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7676,1.876-4.6445,4.6191-4.6445,1.1025,0,1.7979,.2363,2.0996,.3926l-.2754,.9336c-.4336-.2109-1.0498-.3691-1.7852-.3691-2.0732,0-3.4512,1.3262-3.4512,3.6484,0,2.166,1.2471,3.5566,3.3984,3.5566,.6963,0,1.4043-.1445,1.8633-.3672l.2363,.9043Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="feltham-civils-bases-table" className={props.civilsTableDisplayToggle}>
                                    <g id="feltham-psp-base-table">
                                        <g id="bubbles-group-3" data-name="bubbles-group">
                                            <g id="asp-001-bubble">
                                                <g id="body-8" data-name="body">
                                                    <rect x="232.8121" y="92.7975" width="29.6397" height="2.0011" transform="translate(14.8891 220.0931) rotate(-49.2316)" fill="#e9427d" />
                                                    <circle cx="255.4447" cy="84.9438" r="12.3333" fill="#e9427d" />
                                                    <polygon points="231.2169 112.1302 247.0537 105.5901 238.3414 96.5476 231.2169 112.1302" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-8" data-name="id">
                                                    <g>
                                                        <path d="M248.3282,86.0443l-.8398,2.542h-1.0791l2.7461-8.083h1.2598l2.7588,8.083h-1.1152l-.8643-2.542h-2.8662Zm2.6504-.8164l-.791-2.3262c-.1807-.5273-.2998-1.0078-.4199-1.4756h-.0244c-.1201,.4795-.252,.9717-.4072,1.4639l-.792,2.3379h2.4346Z" fill="#fff" />
                                                        <path d="M259.1739,84.6048c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1191c0,2.0273,.624,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2588,1.5947-3.251,0-1.918-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M262.4395,81.7747h-.0234l-1.3555,.7305-.2041-.8027,1.7031-.9121h.8994v7.7959h-1.0195v-6.8115Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="psp-002-bubble">
                                                <g id="body-9" data-name="body">
                                                    <rect x="350.3614" y="244.3817" width="29.6397" height="2.0011" transform="translate(-59.1248 361.719) rotate(-49.2316)" fill="#e9427d" />
                                                    <circle cx="372.994" cy="236.528" r="12.3333" fill="#e9427d" />
                                                    <polygon points="348.7662 263.7145 364.603 257.1743 355.8907 248.1318 348.7662 263.7145" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-9" data-name="id">
                                                    <g>
                                                        <path d="M365.8775,237.6282l-.8398,2.542h-1.0791l2.7461-8.083h1.2598l2.7588,8.083h-1.1152l-.8643-2.542h-2.8662Zm2.6504-.8154l-.791-2.3271c-.1807-.5273-.2998-1.0078-.4199-1.4756h-.0244c-.1201,.4805-.252,.9717-.4072,1.4639l-.792,2.3389h2.4346Z" fill="#fff" />
                                                        <path d="M376.7232,236.1888c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0264,.624,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                        <path d="M377.6988,240.1702v-.6475l.8271-.8037c1.9912-1.8945,2.8906-2.9023,2.9023-4.0771,0-.792-.3838-1.5234-1.5469-1.5234-.708,0-1.2959,.3594-1.6553,.6592l-.3359-.7432c.54-.4561,1.3076-.792,2.207-.792,1.6787,0,2.3867,1.1514,2.3867,2.2676,0,1.4385-1.0439,2.6025-2.6865,4.1855l-.624,.5752v.0244h3.502v.875h-4.9766Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="table-group-3" data-name="table-group">
                                            <g id="asp-001-item-row">
                                                <g id="item-base-box-8" data-name="item-base-box">
                                                    <g id="base-box-10" data-name="base-box">
                                                        <rect x="914.4983" y="198.4052" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-10" data-name="base-text">
                                                        <g>
                                                            <path d="M940.4151,207.3011c0,2.3184-1.4092,3.5479-3.1289,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1289-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7793,2.7285,2.1484,2.7285,1.3799,0,2.1592-1.2695,2.1592-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M945.5957,209.4193c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.7891h-.0195c-.2305,.3896-.7402,.8994-1.5996,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8799v2.6787c0,.9189,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8799v3.5176Z" fill="#fff" />
                                                            <path d="M948.1172,204.512v1.3896h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M950.2481,209.8392c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8701-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6289-1.4199,1.6689-1.4199,.4893,0,.9189,.1406,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2793-.9902-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9199,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6699Z" fill="#fff" />
                                                            <path d="M955.3868,204.512v1.3896h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M960.3164,210.7386l-.0703-.6094h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0391-1.8086,2.9082-1.7988v-.0996c0-.4004-.1094-1.1201-1.0996-1.1201-.4492,0-.9189,.1406-1.2588,.3604l-.2002-.5801c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0098,1.8398,1.9795v1.8086c0,.4199,.0195,.8301,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3789,.8398,.8291,.8398,.6299,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0508-.1904,.0508-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M962.4571,207.2112c0-.5-.0098-.9102-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9102,1.5996-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.2891-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5273Z" fill="#fff" />
                                                            <path d="M972.1846,203.6429v5.8467c0,.4297,.0107,.9199,.04,1.249h-.7891l-.04-.8389h-.0205c-.2695,.5391-.8594,.9492-1.6484,.9492-1.1699,0-2.0693-.9893-2.0693-2.459-.0098-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.0205v-2.8887h.8789Zm-.8789,4.2275c0-.1104-.0107-.2598-.04-.3701-.1299-.5596-.6104-1.0195-1.2695-1.0195-.9092,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4297,1.7891,.5889,0,1.1289-.3896,1.2891-1.0488,.0293-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M974.627,204.5423c.0098,.2998-.21,.54-.5596,.54-.3105,0-.5303-.2402-.5303-.54,0-.3096,.2305-.5498,.5498-.5498,.3301,0,.54,.2402,.54,.5498Zm-.9795,6.1963v-4.8369h.8789v4.8369h-.8789Z" fill="#fff" />
                                                            <path d="M975.9873,207.2112c0-.5-.0098-.9102-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9102,1.5986-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5273Z" fill="#fff" />
                                                            <path d="M985.7051,205.9017c-.0205,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6904,2.209-.4697,.4395-1.1484,.5791-1.7588,.5791-.5791,0-1.2188-.1396-1.6084-.3994l.2197-.6699c.3193,.2002,.8193,.3799,1.4189,.3799,.8994,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0205c-.2695,.4492-.7891,.8096-1.5391,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4404,1.5088,.8398h.0205l.04-.7295h.7695Zm-.9102,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9502,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.8994,.0498-.1504,.0693-.3203,.0693-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-8" data-name="item-type-box">
                                                    <g id="type-box-12" data-name="type-box">
                                                        <rect x="798.6256" y="198.4052" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-10" data-name="type-text">
                                                        <g>
                                                            <path d="M814.9766,207.5804h-2.6191v2.4287h2.9189v.7295h-3.7881v-6.7363h3.6377v.7295h-2.7686v2.1289h2.6191v.7197Z" fill="#fff" />
                                                            <path d="M819.0567,210.7386l-.0693-.6094h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8086,2.9092-1.7988v-.0996c0-.4004-.1104-1.1201-1.0996-1.1201-.4502,0-.9199,.1406-1.2598,.3604l-.1992-.5801c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0098,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3799,.8398,.8301,.8398,.6289,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M821.1983,207.4105c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6201,0-1.0596,.4697-1.1797,1.1289-.0195,.1201-.04,.2598-.04,.4102v2.5781h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M824.5078,203.6429h.8799v7.0957h-.8799v-7.0957Z" fill="#fff" />
                                                            <path d="M827.3575,208.4798c.0205,1.1895,.7803,1.6797,1.6592,1.6797,.6299,0,1.0098-.1104,1.3398-.25l.1494,.6299c-.3096,.1396-.8398,.2998-1.6094,.2998-1.4883,0-2.3779-.9795-2.3779-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.0205,.3193-.0303,.4092h-3.3984Zm2.5791-.6289c.0098-.5605-.2305-1.4297-1.2197-1.4297-.8896,0-1.2793,.8193-1.3486,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M832.1973,205.9017l1.0596,2.8584c.1104,.3193,.2305,.6992,.3096,.9893h.0205c.0898-.29,.1895-.6592,.3096-1.0098l.96-2.8379h.9287l-1.3184,3.4482c-.6299,1.6592-1.0596,2.5078-1.6592,3.0283-.4297,.3799-.8604,.5293-1.0801,.5693l-.2197-.7393c.2197-.0703,.5098-.21,.7695-.4297,.2402-.1904,.54-.5303,.7402-.9795,.04-.0898,.0693-.1602,.0693-.21s-.0195-.1201-.0596-.2305l-1.7891-4.457h.959Z" fill="#fff" />
                                                            <path d="M839.8272,208.6204l-.6992,2.1182h-.8994l2.2881-6.7363h1.0498l2.2988,6.7363h-.9297l-.7197-2.1182h-2.3887Zm2.209-.6797l-.6602-1.9395c-.1494-.4395-.249-.8398-.3496-1.2295h-.0195c-.1006,.4004-.21,.8096-.3398,1.2197l-.6602,1.9492h2.0293Z" fill="#fff" />
                                                            <path d="M844.7383,209.679c.3896,.2402,.959,.4404,1.5586,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3594-.9893-1.2686-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8691-1.8291,2.1787-1.8291,.6895,0,1.1895,.1592,1.4893,.3291l-.2402,.71c-.2197-.1201-.6689-.3193-1.2793-.3193-.9189,0-1.2695,.5488-1.2695,1.0088,0,.6299,.4102,.9395,1.3398,1.2998,1.1396,.4395,1.7188,.9893,1.7188,1.9785,0,1.0391-.7695,1.9395-2.3584,1.9395-.6494,0-1.3594-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M849.7881,204.0823c.4189-.0693,.9688-.1299,1.6689-.1299,.8594,0,1.4893,.2002,1.8887,.5596,.3701,.3203,.5898,.8096,.5898,1.4092,0,.6104-.1797,1.0898-.5195,1.4395-.46,.4902-1.21,.7393-2.0596,.7393-.2598,0-.499-.0098-.6992-.0596v2.6982h-.8691v-6.6562Zm.8691,3.248c.1895,.0498,.4297,.0703,.7197,.0703,1.0488,0,1.6885-.5098,1.6885-1.4395,0-.8896-.6289-1.3193-1.5889-1.3193-.3799,0-.6699,.0303-.8193,.0703v2.6182Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-8" data-name="item-id-box">
                                                    <g id="id-box-12" data-name="id-box">
                                                        <rect x="740.2282" y="198.4052" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-10" data-name="id-text">
                                                        <g>
                                                            <path d="M762.8252,209.0413l-.4795,1.7285h-1.5791l2.0586-6.7363h1.999l2.0889,6.7363h-1.6387l-.5195-1.7285h-1.9297Zm1.709-1.1396l-.4199-1.4297c-.1191-.3994-.2393-.8994-.3398-1.2988h-.0195c-.0996,.3994-.1992,.9092-.3096,1.2988l-.3994,1.4297h1.4883Z" fill="#fff" />
                                                            <path d="M772.3741,207.4915c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1992,.9395,2.1992,.5889,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9199-2.1992-.5586,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M775.0655,205.6234h-.0195l-1.2402,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4688v-5.1465Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="asp-002-item-row">
                                                <g id="item-base-box-9" data-name="item-base-box">
                                                    <g id="base-box-11" data-name="base-box">
                                                        <rect x="914.4983" y="216.5302" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-11" data-name="base-text">
                                                        <g>
                                                            <path d="M940.4151,225.4261c0,2.3184-1.4092,3.5479-3.1289,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1289-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7793,2.7285,2.1484,2.7285,1.3799,0,2.1592-1.2695,2.1592-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M945.5957,227.5443c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.7891h-.0195c-.2305,.3896-.7402,.8994-1.5996,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8799v2.6787c0,.9189,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8799v3.5176Z" fill="#fff" />
                                                            <path d="M948.1172,222.637v1.3896h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M950.2481,227.9642c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8701-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6289-1.4199,1.6689-1.4199,.4893,0,.9189,.1406,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2793-.9902-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9199,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6699Z" fill="#fff" />
                                                            <path d="M955.3868,222.637v1.3896h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M960.3164,228.8636l-.0703-.6094h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0391-1.8086,2.9082-1.7988v-.0996c0-.4004-.1094-1.1201-1.0996-1.1201-.4492,0-.9189,.1406-1.2588,.3604l-.2002-.5801c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0098,1.8398,1.9795v1.8086c0,.4199,.0195,.8301,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3789,.8398,.8291,.8398,.6299,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0508-.1904,.0508-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M962.4571,225.3362c0-.5-.0098-.9102-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9102,1.5996-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.2891-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5273Z" fill="#fff" />
                                                            <path d="M972.1846,221.7679v5.8467c0,.4297,.0107,.9199,.04,1.249h-.7891l-.04-.8389h-.0205c-.2695,.5391-.8594,.9492-1.6484,.9492-1.1699,0-2.0693-.9893-2.0693-2.459-.0098-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.0205v-2.8887h.8789Zm-.8789,4.2275c0-.1104-.0107-.2598-.04-.3701-.1299-.5596-.6104-1.0195-1.2695-1.0195-.9092,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4297,1.7891,.5889,0,1.1289-.3896,1.2891-1.0488,.0293-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M974.627,222.6673c.0098,.2998-.21,.54-.5596,.54-.3105,0-.5303-.2402-.5303-.54,0-.3096,.2305-.5498,.5498-.5498,.3301,0,.54,.2402,.54,.5498Zm-.9795,6.1963v-4.8369h.8789v4.8369h-.8789Z" fill="#fff" />
                                                            <path d="M975.9873,225.3362c0-.5-.0098-.9102-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9102,1.5986-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5273Z" fill="#fff" />
                                                            <path d="M985.7051,224.0267c-.0205,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6904,2.209-.4697,.4395-1.1484,.5791-1.7588,.5791-.5791,0-1.2188-.1396-1.6084-.3994l.2197-.6699c.3193,.2002,.8193,.3799,1.4189,.3799,.8994,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0205c-.2695,.4492-.7891,.8096-1.5391,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4404,1.5088,.8398h.0205l.04-.7295h.7695Zm-.9102,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9502,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.8994,.0498-.1504,.0693-.3203,.0693-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-9" data-name="item-type-box">
                                                    <g id="type-box-13" data-name="type-box">
                                                        <rect x="798.6256" y="216.5302" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-11" data-name="type-text">
                                                        <g>
                                                            <path d="M812.5879,228.8636l-1.709-6.7363h.9189l.7998,3.4082c.2002,.8398,.3799,1.6797,.5,2.3291h.0195c.1104-.6699,.3203-1.4697,.5498-2.3389l.8994-3.3984h.9102l.8193,3.418c.1895,.7998,.3691,1.5996,.4697,2.3096h.0195c.1406-.7402,.3301-1.4893,.54-2.3291l.8896-3.3984h.8896l-1.9092,6.7363h-.9092l-.8496-3.5078c-.21-.8594-.3506-1.5195-.4404-2.1992h-.0195c-.1201,.6699-.2695,1.3301-.5195,2.1992l-.96,3.5078h-.9092Z" fill="#fff" />
                                                            <path d="M820.7373,222.6673c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5498,.5498-.5498,.3291,0,.5391,.2402,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M822.0987,225.3362c0-.5-.0098-.9102-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9102,1.5986-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5273Z" fill="#fff" />
                                                            <path d="M827.6485,225.3362c0-.5-.0098-.9102-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9102,1.5986-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5273Z" fill="#fff" />
                                                            <path d="M833.6875,226.6048c.0205,1.1895,.7803,1.6797,1.6592,1.6797,.6299,0,1.0098-.1104,1.3398-.25l.1494,.6299c-.3096,.1396-.8398,.2998-1.6094,.2998-1.4883,0-2.3779-.9795-2.3779-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.0205,.3193-.0303,.4092h-3.3984Zm2.5791-.6289c.0098-.5605-.2305-1.4297-1.2197-1.4297-.8896,0-1.2793,.8193-1.3486,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M838.208,225.5355c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6201,0-1.0596,.4697-1.1797,1.1289-.0195,.1201-.04,.2598-.04,.4102v2.5781h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M841.3584,227.9642c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8701-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6289-1.4199,1.6689-1.4199,.4893,0,.9189,.1406,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2793-.9902-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9199,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6699Z" fill="#fff" />
                                                            <path d="M845.4375,221.7679h.8799v3.0186h.0195c.1406-.25,.3604-.4697,.6299-.6201,.2598-.1494,.5703-.25,.8994-.25,.6504,0,1.6895,.4004,1.6895,2.0693v2.8779h-.8799v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5693,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0596,.25-.0596,.4199v2.918h-.8799v-7.0957Z" fill="#fff" />
                                                            <path d="M854.0078,222.8675h-2.0488v-.7402h4.9863v.7402h-2.0586v5.9961h-.8789v-5.9961Z" fill="#fff" />
                                                            <path d="M857.2178,225.5355c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.75-1.0596,1.3398-1.0596,.0996,0,.1689,.0098,.249,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6191,0-1.0596,.4697-1.1787,1.1289-.0205,.1201-.04,.2598-.04,.4102v2.5781h-.8701v-3.3281Z" fill="#fff" />
                                                            <path d="M861.5069,222.6673c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5498,.5498-.5498,.3291,0,.5391,.2402,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M865.5469,228.8636l-.0703-.6094h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0391-1.8086,2.9082-1.7988v-.0996c0-.4004-.1094-1.1201-1.0996-1.1201-.4492,0-.9189,.1406-1.2588,.3604l-.2002-.5801c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0098,1.8398,1.9795v1.8086c0,.4199,.0195,.8301,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3789,.8398,.8291,.8398,.6299,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0508-.1904,.0508-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M867.6875,225.3362c0-.5-.0098-.9102-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9102,1.5996-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5273Z" fill="#fff" />
                                                            <path d="M877.4053,224.0267c-.0195,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5791-1.7588,.5791-.5801,0-1.2197-.1396-1.6094-.3994l.2197-.6699c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8398h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9502,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4697v-.8691Z" fill="#fff" />
                                                            <path d="M878.8272,221.7679h.8799v7.0957h-.8799v-7.0957Z" fill="#fff" />
                                                            <path d="M881.6768,226.6048c.0205,1.1895,.7803,1.6797,1.6592,1.6797,.6299,0,1.0098-.1104,1.3398-.25l.1494,.6299c-.3096,.1396-.8398,.2998-1.6094,.2998-1.4883,0-2.3779-.9795-2.3779-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.0205,.3193-.0303,.4092h-3.3984Zm2.5791-.6289c.0098-.5605-.2305-1.4297-1.2197-1.4297-.8896,0-1.2793,.8193-1.3486,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M888.3477,222.2073c.4189-.0693,.9688-.1299,1.6689-.1299,.8594,0,1.4893,.2002,1.8887,.5596,.3701,.3203,.5898,.8096,.5898,1.4092,0,.6104-.1797,1.0898-.5195,1.4395-.46,.4902-1.21,.7393-2.0596,.7393-.2598,0-.499-.0098-.6992-.0596v2.6982h-.8691v-6.6562Zm.8691,3.248c.1895,.0498,.4297,.0703,.7197,.0703,1.0488,0,1.6885-.5098,1.6885-1.4395,0-.8896-.6289-1.3193-1.5889-1.3193-.3799,0-.6699,.0303-.8193,.0703v2.6182Z" fill="#fff" />
                                                            <path d="M893.5469,227.804c.3896,.2402,.96,.4404,1.5596,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3604-.9893-1.2695-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8701-1.8291,2.1787-1.8291,.6904,0,1.1895,.1592,1.4893,.3291l-.2393,.71c-.2197-.1201-.6699-.3193-1.2793-.3193-.9199,0-1.2695,.5488-1.2695,1.0088,0,.6299,.4102,.9395,1.3389,1.2998,1.1396,.4395,1.7197,.9893,1.7197,1.9785,0,1.0391-.7695,1.9395-2.3594,1.9395-.6494,0-1.3584-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M898.5977,222.2073c.4189-.0693,.9688-.1299,1.6689-.1299,.8594,0,1.4893,.2002,1.8887,.5596,.3701,.3203,.5898,.8096,.5898,1.4092,0,.6104-.1797,1.0898-.5195,1.4395-.46,.4902-1.21,.7393-2.0596,.7393-.2598,0-.499-.0098-.6992-.0596v2.6982h-.8691v-6.6562Zm.8691,3.248c.1895,.0498,.4297,.0703,.7197,.0703,1.0488,0,1.6885-.5098,1.6885-1.4395,0-.8896-.6289-1.3193-1.5889-1.3193-.3799,0-.6699,.0303-.8193,.0703v2.6182Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-9" data-name="item-id-box">
                                                    <g id="id-box-13" data-name="id-box">
                                                        <rect x="740.2282" y="216.5302" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-11" data-name="id-text">
                                                        <g>
                                                            <path d="M762.8252,227.1663l-.4795,1.7285h-1.5791l2.0586-6.7363h1.999l2.0889,6.7363h-1.6387l-.5195-1.7285h-1.9297Zm1.709-1.1396l-.4199-1.4297c-.1191-.3994-.2393-.8994-.3398-1.2988h-.0195c-.0996,.3994-.1992,.9092-.3096,1.2988l-.3994,1.4297h1.4883Z" fill="#fff" />
                                                            <path d="M772.3741,225.6165c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1992,.9395,2.1992,.5889,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9199-2.1992-.5586,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M773.126,228.8948v-.9189l.8398-.7598c1.4199-1.2695,2.1094-1.999,2.1289-2.7588,0-.5293-.3203-.9492-1.0693-.9492-.5596,0-1.0498,.2793-1.3896,.54l-.4297-1.0898c.4902-.3701,1.25-.6699,2.1289-.6699,1.4688,0,2.2793,.8594,2.2793,2.0391,0,1.0898-.79,1.959-1.7295,2.7988l-.5996,.499v.0205h2.4482v1.249h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="psp-003-item-row">
                                                <g id="item-base-box-10" data-name="item-base-box">
                                                    <g id="base-box-12" data-name="base-box">
                                                        <rect x="914.4983" y="234.6552" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-12" data-name="base-text">
                                                        <g>
                                                            <path d="M940.4151,243.5511c0,2.3184-1.4092,3.5479-3.1289,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1289-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7793,2.7285,2.1484,2.7285,1.3799,0,2.1592-1.2695,2.1592-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M945.5957,245.6693c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.7891h-.0195c-.2305,.3896-.7402,.8994-1.5996,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8799v2.6787c0,.9189,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8799v3.5176Z" fill="#fff" />
                                                            <path d="M948.1172,240.762v1.3896h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M950.2481,246.0892c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8701-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6289-1.4199,1.6689-1.4199,.4893,0,.9189,.1406,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2793-.9902-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9199,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6699Z" fill="#fff" />
                                                            <path d="M955.3868,240.762v1.3896h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M960.3164,246.9886l-.0703-.6094h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0391-1.8086,2.9082-1.7988v-.0996c0-.4004-.1094-1.1201-1.0996-1.1201-.4492,0-.9189,.1406-1.2588,.3604l-.2002-.5801c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0098,1.8398,1.9795v1.8086c0,.4199,.0195,.8301,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3789,.8398,.8291,.8398,.6299,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0508-.1904,.0508-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M962.4571,243.4612c0-.5-.0098-.9102-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9102,1.5996-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.2891-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5273Z" fill="#fff" />
                                                            <path d="M972.1846,239.8929v5.8467c0,.4297,.0107,.9199,.04,1.249h-.7891l-.04-.8389h-.0205c-.2695,.5391-.8594,.9492-1.6484,.9492-1.1699,0-2.0693-.9893-2.0693-2.459-.0098-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.0205v-2.8887h.8789Zm-.8789,4.2275c0-.1104-.0107-.2598-.04-.3701-.1299-.5596-.6104-1.0195-1.2695-1.0195-.9092,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4297,1.7891,.5889,0,1.1289-.3896,1.2891-1.0488,.0293-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M974.627,240.7923c.0098,.2998-.21,.54-.5596,.54-.3105,0-.5303-.2402-.5303-.54,0-.3096,.2305-.5498,.5498-.5498,.3301,0,.54,.2402,.54,.5498Zm-.9795,6.1963v-4.8369h.8789v4.8369h-.8789Z" fill="#fff" />
                                                            <path d="M975.9873,243.4612c0-.5-.0098-.9102-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9102,1.5986-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5273Z" fill="#fff" />
                                                            <path d="M985.7051,242.1517c-.0205,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6904,2.209-.4697,.4395-1.1484,.5791-1.7588,.5791-.5791,0-1.2188-.1396-1.6084-.3994l.2197-.6699c.3193,.2002,.8193,.3799,1.4189,.3799,.8994,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0205c-.2695,.4492-.7891,.8096-1.5391,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4404,1.5088,.8398h.0205l.04-.7295h.7695Zm-.9102,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9502,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.8994,.0498-.1504,.0693-.3203,.0693-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-10" data-name="item-type-box">
                                                    <g id="type-box-14" data-name="type-box">
                                                        <rect x="798.6256" y="234.6552" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-12" data-name="type-text">
                                                        <g>
                                                            <path d="M816.1953,246.7689c-.3193,.1602-.959,.3203-1.7783,.3203-1.8994,0-3.3281-1.2002-3.3281-3.4082,0-2.1094,1.4287-3.5381,3.5176-3.5381,.8398,0,1.3691,.1797,1.5996,.2998l-.21,.709c-.3301-.1592-.7998-.2793-1.3594-.2793-1.5791,0-2.6289,1.0088-2.6289,2.7783,0,1.6494,.9502,2.708,2.5889,2.708,.5293,0,1.0693-.1094,1.4189-.2793l.1797,.6895Z" fill="#fff" />
                                                            <path d="M817.2285,243.6605c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6201,0-1.0596,.4697-1.1797,1.1289-.0195,.1201-.04,.2598-.04,.4102v2.5781h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M824.7764,244.5306c0,1.7891-1.2402,2.5684-2.4092,2.5684-1.3096,0-2.3184-.96-2.3184-2.4893,0-1.6191,1.0586-2.5684,2.3984-2.5684,1.3887,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4688-.7891,1.4688-1.8789,0-.8193-.4092-1.8584-1.4492-1.8584-1.0391,0-1.4883,.959-1.4883,1.8789Z" fill="#fff" />
                                                            <path d="M826.168,242.1517l.6396,2.458c.1396,.54,.2695,1.04,.3594,1.54h.0303c.1104-.4902,.2705-1.0098,.4297-1.5293l.79-2.4688h.7393l.75,2.4189c.1797,.5791,.3193,1.0889,.4297,1.5791h.0293c.0801-.4902,.21-1,.3701-1.5693l.6895-2.4287h.8701l-1.5596,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3096-1.0195-.4297-1.5889h-.0195c-.1201,.5791-.2705,1.0791-.4404,1.5986l-.7793,2.2988h-.7998l-1.459-4.8369h.8994Z" fill="#fff" />
                                                            <path d="M834.2373,240.762v1.3896h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M836.4883,239.8929h.8789v3.0186h.0205c.1396-.25,.3594-.4697,.6299-.6201,.2598-.1494,.5693-.25,.8994-.25,.6494,0,1.6885,.4004,1.6885,2.0693v2.8779h-.8789v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5703,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0605,.25-.0605,.4199v2.918h-.8789v-7.0957Z" fill="#fff" />
                                                            <path d="M846.416,244.5306c0,1.7891-1.2393,2.5684-2.4082,2.5684-1.3096,0-2.3193-.96-2.3193-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3896,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4697-.7891,1.4697-1.8789,0-.8193-.4102-1.8584-1.4492-1.8584s-1.4893,.959-1.4893,1.8789Z" fill="#fff" />
                                                            <path d="M847.5284,243.6605c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6201,0-1.0596,.4697-1.1797,1.1289-.0195,.1201-.04,.2598-.04,.4102v2.5781h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M850.8379,243.4612c0-.5-.0098-.9102-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9102,1.5996-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5273Z" fill="#fff" />
                                                            <path d="M856.878,244.7298c.0195,1.1895,.7793,1.6797,1.6592,1.6797,.6299,0,1.0088-.1104,1.3389-.25l.1504,.6299c-.3105,.1396-.8398,.2998-1.6094,.2998-1.4893,0-2.3789-.9795-2.3789-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.0195,.3193-.0293,.4092h-3.3984Zm2.5781-.6289c.0107-.5605-.2295-1.4297-1.2188-1.4297-.8896,0-1.2793,.8193-1.3496,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M864.6377,244.8704l-.7002,2.1182h-.8994l2.2891-6.7363h1.0488l2.2988,6.7363h-.9297l-.7188-2.1182h-2.3887Zm2.208-.6797l-.6592-1.9395c-.1504-.4395-.25-.8398-.3496-1.2295h-.0205c-.0996,.4004-.21,.8096-.3398,1.2197l-.6592,1.9492h2.0283Z" fill="#fff" />
                                                            <path d="M869.5479,245.929c.3896,.2402,.96,.4404,1.5596,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3604-.9893-1.2695-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8701-1.8291,2.1787-1.8291,.6904,0,1.1895,.1592,1.4893,.3291l-.2393,.71c-.2197-.1201-.6699-.3193-1.2793-.3193-.9199,0-1.2695,.5488-1.2695,1.0088,0,.6299,.4102,.9395,1.3389,1.2998,1.1396,.4395,1.7197,.9893,1.7197,1.9785,0,1.0391-.7695,1.9395-2.3594,1.9395-.6494,0-1.3584-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M874.5977,240.3323c.4199-.0693,.9697-.1299,1.6689-.1299,.8594,0,1.4893,.2002,1.8887,.5596,.3701,.3203,.5898,.8096,.5898,1.4092,0,.6104-.1797,1.0898-.5195,1.4395-.46,.4902-1.209,.7393-2.0586,.7393-.2598,0-.5-.0098-.7002-.0596v2.6982h-.8691v-6.6562Zm.8691,3.248c.1904,.0498,.4307,.0703,.7197,.0703,1.0498,0,1.6895-.5098,1.6895-1.4395,0-.8896-.6299-1.3193-1.5889-1.3193-.3799,0-.6699,.0303-.8203,.0703v2.6182Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-10" data-name="item-id-box">
                                                    <g id="id-box-14" data-name="id-box">
                                                        <rect x="740.2282" y="234.6552" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-12" data-name="id-text">
                                                        <g>
                                                            <path d="M762.8252,245.2913l-.4795,1.7285h-1.5791l2.0586-6.7363h1.999l2.0889,6.7363h-1.6387l-.5195-1.7285h-1.9297Zm1.709-1.1396l-.4199-1.4297c-.1191-.3994-.2393-.8994-.3398-1.2988h-.0195c-.0996,.3994-.1992,.9092-.3096,1.2988l-.3994,1.4297h1.4883Z" fill="#fff" />
                                                            <path d="M772.3741,243.7415c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6797,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1992,.9395,2.1992,.5889,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9199-2.1992-.5586,0-.9395,.71-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M773.3955,245.5306c.2705,.1406,.8906,.4004,1.5098,.4004,.7891,0,1.1895-.3799,1.1895-.8701,0-.6396-.6396-.9287-1.3096-.9287h-.6201v-1.0898h.5898c.5098-.0098,1.1602-.2002,1.1602-.75,0-.3896-.3203-.6797-.96-.6797-.5293,0-1.0889,.2305-1.3584,.3906l-.3105-1.0996c.3896-.25,1.1699-.4902,2.0088-.4902,1.3896,0,2.1592,.7295,2.1592,1.6191,0,.6895-.3906,1.2295-1.1895,1.5098v.0195c.7793,.1396,1.4092,.7295,1.4092,1.5791,0,1.1494-1.0088,1.9893-2.6592,1.9893-.8389,0-1.5488-.2197-1.9277-.46l.3086-1.1396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="title-row-3" data-name="title-row">
                                                <g id="foundation-box">
                                                    <g id="base-box-13" data-name="base-box">
                                                        <rect x="914.4983" y="180.2251" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.0447" />
                                                    </g>
                                                    <g id="base-text-13" data-name="base-text">
                                                        <g>
                                                            <path d="M930.5938,185.9124c.3994-.0801,1.21-.1396,1.9688-.1396,.9297,0,1.499,.0898,1.9893,.3799,.4697,.249,.8096,.709,.8096,1.3184,0,.6006-.3506,1.1602-1.1094,1.4395v.0205c.7695,.21,1.3389,.7891,1.3389,1.6592,0,.6094-.2793,1.0889-.6992,1.4287-.4902,.3896-1.3096,.6094-2.6484,.6094-.75,0-1.3096-.0498-1.6494-.0996v-6.6162Zm1.5088,2.5781h.5c.7998,0,1.2295-.3291,1.2295-.8291,0-.5098-.3896-.7793-1.0791-.7793-.3301,0-.5195,.0195-.6504,.04v1.5684Zm0,2.9883c.1504,.0205,.3301,.0205,.5898,.0205,.6895,0,1.2988-.2598,1.2988-.9697,0-.6797-.6094-.9492-1.3691-.9492h-.5195v1.8984Z" fill="#fff" />
                                                            <path d="M939.3916,192.5589l-.0898-.4902h-.0293c-.3203,.3896-.8203,.5996-1.3994,.5996-.9893,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1387-1.8789,2.8682-1.8691v-.0693c0-.2598-.1396-.6299-.8896-.6299-.5,0-1.0293,.1699-1.3496,.3701l-.2793-.9795c.3398-.1904,1.0098-.4297,1.8984-.4297,1.6289,0,2.1494,.959,2.1494,2.1084v1.6992c0,.4697,.0205,.9189,.0693,1.1895h-1.3691Zm-.1797-2.3086c-.7988-.0107-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5791,.5996,.5791,.3799,0,.6895-.25,.79-.5596,.0195-.0801,.0293-.1699,.0293-.2598v-.5293Z" fill="#fff" />
                                                            <path d="M941.8838,191.2298c.2793,.1699,.8594,.3691,1.3086,.3691,.46,0,.6504-.1592,.6504-.4092s-.1504-.3701-.7207-.5596c-1.0088-.3398-1.3984-.8896-1.3887-1.4697,0-.9092,.7793-1.5986,1.9883-1.5986,.5703,0,1.0801,.1299,1.3799,.2793l-.2705,1.0498c-.2188-.1201-.6387-.2803-1.0586-.2803-.3701,0-.5801,.1504-.5801,.4004,0,.2295,.1895,.3496,.79,.5596,.9287,.3193,1.3193,.7891,1.3291,1.5088,0,.9092-.7197,1.5791-2.1191,1.5791-.6387,0-1.209-.1396-1.5791-.3398l.2705-1.0889Z" fill="#fff" />
                                                            <path d="M947.4034,190.5999c.0498,.6299,.6699,.9297,1.3789,.9297,.5195,0,.9395-.0703,1.3496-.2002l.1992,1.0293c-.5,.2002-1.1094,.2998-1.7686,.2998-1.6592,0-2.6084-.959-2.6084-2.4883,0-1.2393,.7695-2.6084,2.4688-2.6084,1.5781,0,2.1777,1.2285,2.1777,2.4385,0,.2598-.0293,.4893-.0488,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1602-.9893-.8594-.9893-.6406,0-.9004,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M955.1123,185.8226v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M956.4131,189.2308c0-.6104-.0195-1.1299-.0391-1.5596h1.3184l.0703,.6699h.0293c.2012-.3096,.7002-.7793,1.5098-.7793,1,0,1.749,.6592,1.749,2.0986v2.8984h-1.5186v-2.709c0-.6299-.2207-1.0596-.7695-1.0596-.4199,0-.6699,.29-.7695,.5703-.041,.0898-.0605,.2393-.0605,.3799v2.8184h-1.5195v-3.3281Z" fill="#fff" />
                                                            <path d="M962.2627,191.2298c.2803,.1699,.8594,.3691,1.3105,.3691,.459,0,.6484-.1592,.6484-.4092s-.1494-.3701-.7188-.5596c-1.0098-.3398-1.3994-.8896-1.3896-1.4697,0-.9092,.7803-1.5986,1.9893-1.5986,.5693,0,1.0791,.1299,1.3789,.2793l-.2695,1.0498c-.2207-.1201-.6406-.2803-1.0596-.2803-.3701,0-.5791,.1504-.5791,.4004,0,.2295,.1895,.3496,.7891,.5596,.9297,.3193,1.3184,.7891,1.3291,1.5088,0,.9092-.7197,1.5791-2.1182,1.5791-.6406,0-1.21-.1396-1.5801-.3398l.2695-1.0889Z" fill="#fff" />
                                                            <path d="M968.3116,186.2718v1.3994h1.0898v1.1191h-1.0898v1.7695c0,.5898,.1406,.8594,.5996,.8594,.1904,0,.3398-.0195,.4502-.04l.0107,1.1494c-.2002,.0801-.5605,.1299-.9902,.1299-.4893,0-.8994-.1699-1.1387-.4199-.2803-.29-.4199-.7598-.4199-1.4492v-1.999h-.6504v-1.1191h.6504v-1.0596l1.4883-.3398Z" fill="#fff" />
                                                            <path d="M973.0616,192.5589l-.0898-.4902h-.0303c-.3193,.3896-.8193,.5996-1.3994,.5996-.9893,0-1.5781-.7197-1.5781-1.499,0-1.2695,1.1387-1.8789,2.8672-1.8691v-.0693c0-.2598-.1387-.6299-.8887-.6299-.5,0-1.0293,.1699-1.3496,.3701l-.2793-.9795c.3398-.1904,1.0088-.4297,1.8984-.4297,1.6289,0,2.1484,.959,2.1484,2.1084v1.6992c0,.4697,.0205,.9189,.0703,1.1895h-1.3691Zm-.1797-2.3086c-.7998-.0107-1.4199,.1797-1.4199,.7695,0,.3896,.2598,.5791,.5996,.5791,.3809,0,.6895-.25,.79-.5596,.0205-.0801,.0303-.1699,.0303-.2598v-.5293Z" fill="#fff" />
                                                            <path d="M975.5635,185.4622h1.5186v7.0967h-1.5186v-7.0967Z" fill="#fff" />
                                                            <path d="M978.3135,185.4622h1.5186v7.0967h-1.5186v-7.0967Z" fill="#fff" />
                                                            <path d="M982.2315,190.5999c.0508,.6299,.6699,.9297,1.3799,.9297,.5195,0,.9395-.0703,1.3486-.2002l.2012,1.0293c-.5,.2002-1.1094,.2998-1.7695,.2998-1.6592,0-2.6094-.959-2.6094-2.4883,0-1.2393,.7705-2.6084,2.4688-2.6084,1.5801,0,2.1797,1.2285,2.1797,2.4385,0,.2598-.0303,.4893-.0508,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1592-.9893-.8594-.9893-.6387,0-.8984,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M991.0811,185.4622v5.6377c0,.5488,.0195,1.1289,.0391,1.459h-1.3496l-.0693-.7197h-.0205c-.3086,.5498-.8984,.8291-1.5293,.8291-1.1582,0-2.0879-.9893-2.0879-2.5078-.0098-1.6494,1.0195-2.5986,2.1885-2.5986,.5996,0,1.0693,.209,1.29,.5488h.0195v-2.6484h1.5195Zm-1.5195,4.2783c0-.0801-.0098-.1904-.0195-.2705-.0908-.4092-.4199-.749-.8906-.749-.6895,0-1.0488,.6191-1.0488,1.3887,0,.8301,.4102,1.3496,1.0391,1.3496,.4395,0,.7891-.2998,.8799-.7295,.0303-.1104,.04-.2197,.04-.3496v-.6396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="type-box-15" data-name="type-box">
                                                    <g id="type-box-16" data-name="type-box">
                                                        <rect x="798.6256" y="180.2251" width="115.8727" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-13" data-name="type-text">
                                                        <g>
                                                            <path d="M818.3311,192.5589v-6.7363h1.7793l1.3994,2.4688c.3994,.709,.7988,1.5488,1.0996,2.3086h.0293c-.0996-.8896-.1299-1.7988-.1299-2.8086v-1.9688h1.3994v6.7363h-1.5996l-1.4385-2.5986c-.4004-.7197-.8398-1.5889-1.1699-2.3789l-.0293,.0098c.04,.8896,.0596,1.8389,.0596,2.9385v2.0293h-1.3994Z" fill="#fff" />
                                                            <path d="M827.96,192.5589l-.0898-.4902h-.0303c-.3203,.3896-.8193,.5996-1.3994,.5996-.9893,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1396-1.8789,2.8682-1.8691v-.0693c0-.2598-.1396-.6299-.8896-.6299-.499,0-1.0293,.1699-1.3486,.3701l-.2803-.9795c.3398-.1904,1.0098-.4297,1.8994-.4297,1.6289,0,2.1484,.959,2.1484,2.1084v1.6992c0,.4697,.0205,.9189,.0703,1.1895h-1.3691Zm-.1807-2.3086c-.7988-.0107-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5791,.5996,.5791,.3799,0,.6895-.25,.79-.5596,.0195-.0801,.0293-.1699,.0293-.2598v-.5293Z" fill="#fff" />
                                                            <path d="M830.461,189.2308c0-.6104-.0195-1.1299-.04-1.5596h1.2793l.0703,.6602h.0293c.21-.3105,.6396-.7695,1.4795-.7695,.6299,0,1.1299,.3193,1.3398,.8291h.0195c.1797-.25,.3994-.4502,.6299-.5898,.2695-.1602,.5693-.2393,.9297-.2393,.9395,0,1.6484,.6592,1.6484,2.1182v2.8789h-1.4785v-2.6592c0-.709-.2305-1.1191-.7197-1.1191-.3506,0-.5996,.2402-.7002,.5303-.04,.1094-.0596,.2695-.0596,.3896v2.8584h-1.4795v-2.7383c0-.6201-.2197-1.04-.6992-1.04-.3896,0-.6201,.2998-.71,.5498-.0498,.1201-.0596,.2598-.0596,.3799v2.8486h-1.4795v-3.3281Z" fill="#fff" />
                                                            <path d="M840.2305,190.5999c.0498,.6299,.6699,.9297,1.3789,.9297,.5195,0,.9395-.0703,1.3496-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.7695,.2998-1.6592,0-2.6084-.959-2.6084-2.4883,0-1.2393,.7695-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0303,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1602-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="id-box-15" data-name="id-box">
                                                    <g id="id-box_copy_7" data-name="id-box copy 7">
                                                        <rect x="740.2282" y="180.2251" width="58.3973" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text_copy_7" data-name="id-text copy 7">
                                                        <g>
                                                            <path d="M766.711,185.8538v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M768.0323,185.9437c.5586-.0898,1.2891-.1396,2.0586-.1396,1.2793,0,2.1084,.2295,2.7578,.7188,.7002,.5205,1.1396,1.3496,1.1396,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7285-.71,.5898-1.7891,.8691-3.1084,.8691-.7891,0-1.3496-.0498-1.7285-.0996v-6.6162Zm1.5293,5.4971c.1289,.0303,.3398,.0303,.5293,.0303,1.3789,.0098,2.2783-.75,2.2783-2.3594,.0107-1.3994-.8096-2.1387-2.1182-2.1387-.3398,0-.5605,.0303-.6895,.0596v4.4082Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="main-title-3" data-name="main-title">
                                                <g>
                                                    <path d="M742.1153,166.8392h4.9414v1.498h-3.1064v1.8477h2.9023v1.4873h-2.9023v3.25h-1.835v-8.083Z" fill="#e9427d" />
                                                    <path d="M749.4707,172.5716c.0596,.7559,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2402,1.2363c-.5996,.2393-1.332,.3594-2.123,.3594-1.9912,0-3.1309-1.1514-3.1309-2.9863,0-1.4873,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0596,.7197h-3.7783Zm2.1475-1.248c0-.4434-.1924-1.1865-1.0322-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1592Z" fill="#e9427d" />
                                                    <path d="M754.4034,166.4075h1.8232v8.5146h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M759.7422,167.3782v1.6797h1.3076v1.3428h-1.3076v2.1221c0,.709,.168,1.0322,.7197,1.0322,.2275,0,.4082-.0244,.5391-.0479l.0127,1.3789c-.2402,.0957-.6719,.1562-1.1875,.1562-.5879,0-1.0791-.2041-1.3672-.5039-.3359-.3477-.5039-.9121-.5039-1.7393v-2.3984h-.7793v-1.3428h.7793v-1.2715l1.7871-.4082Z" fill="#e9427d" />
                                                    <path d="M762.1065,166.4075h1.8242v3.3457h.0234c.1914-.252,.4316-.4434,.7197-.5996,.2637-.1445,.6113-.2285,.9473-.2285,1.1631,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8232v-3.2256c0-.7676-.2637-1.2959-.9473-1.2959-.4795,0-.7793,.3115-.9111,.6357-.0479,.1191-.0596,.2754-.0596,.4082v3.4775h-1.8242v-8.5146Z" fill="#e9427d" />
                                                    <path d="M772.4737,174.9222l-.1074-.5879h-.0371c-.3828,.4678-.9824,.7197-1.6787,.7197-1.1875,0-1.8955-.8633-1.8955-1.7988,0-1.5234,1.3672-2.2549,3.4434-2.2422v-.084c0-.3125-.168-.7559-1.0684-.7559-.5996,0-1.2344,.2031-1.6191,.4434l-.3359-1.1758c.4082-.2275,1.2119-.5156,2.2793-.5156,1.9551,0,2.5781,1.1523,2.5781,2.5312v2.0391c0,.5625,.0244,1.1035,.084,1.4268h-1.6426Zm-.2158-2.7705c-.96-.0117-1.7031,.2158-1.7031,.9238,0,.4668,.3115,.6953,.7197,.6953,.4551,0,.8271-.2998,.9473-.6719,.0234-.0957,.0361-.2031,.0361-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M775.4756,170.929c0-.7324-.0244-1.3555-.0488-1.8711h1.5352l.084,.791h.0371c.251-.3711,.7676-.9238,1.7744-.9238,.7549,0,1.3555,.3848,1.6064,.9961h.0244c.2158-.3008,.4795-.54,.7559-.708,.3232-.1924,.6836-.2881,1.1152-.2881,1.1279,0,1.9795,.792,1.9795,2.543v3.4541h-1.7754v-3.1904c0-.8516-.2754-1.3438-.8633-1.3438-.4199,0-.7197,.2891-.8398,.6367-.0479,.1309-.0723,.3232-.0723,.4668v3.4307h-1.7754v-3.2861c0-.7441-.2637-1.248-.8389-1.248-.4678,0-.7432,.3604-.8516,.6602-.0596,.1445-.0713,.3125-.0713,.4561v3.418h-1.7754v-3.9932Z" fill="#e9427d" />
                                                    <path d="M788.2784,166.9466c.5645-.0957,1.3555-.168,2.4707-.168,1.1279,0,1.9316,.2158,2.4707,.6475,.5156,.4082,.8633,1.0791,.8633,1.8711s-.2637,1.4639-.7432,1.9189c-.624,.5879-1.5469,.8516-2.626,.8516-.2402,0-.4561-.0117-.624-.0361v2.8906h-1.8115v-7.9756Zm1.8115,3.6699c.1553,.0361,.3477,.0479,.6123,.0479,.9707,0,1.5703-.4912,1.5703-1.3193,0-.7432-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0361-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M795.1905,166.4075h1.8242v3.3457h.0234c.1914-.252,.4316-.4434,.7197-.5996,.2637-.1445,.6113-.2285,.9473-.2285,1.1631,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8232v-3.2256c0-.7676-.2637-1.2959-.9473-1.2959-.4795,0-.7793,.3115-.9111,.6357-.0479,.1191-.0596,.2754-.0596,.4082v3.4775h-1.8242v-8.5146Z" fill="#e9427d" />
                                                    <path d="M805.5576,174.9222l-.1084-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6797,.7197-1.1865,0-1.8945-.8633-1.8945-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2422v-.084c0-.3125-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2031-1.6191,.4434l-.3359-1.1758c.4082-.2275,1.2109-.5156,2.2793-.5156,1.9551,0,2.5781,1.1523,2.5781,2.5312v2.0391c0,.5625,.0234,1.1035,.084,1.4268h-1.6426Zm-.2168-2.7705c-.959-.0117-1.7031,.2158-1.7031,.9238,0,.4668,.3125,.6953,.7197,.6953,.4561,0,.8271-.2998,.9482-.6719,.0234-.0957,.0352-.2031,.0352-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M808.5469,173.3275c.3359,.2031,1.0312,.4434,1.5713,.4434,.5508,0,.7793-.1914,.7793-.4922,0-.2988-.1797-.4434-.8633-.6719-1.2109-.4072-1.6797-1.0664-1.668-1.7617,0-1.0918,.9355-1.9199,2.3867-1.9199,.6836,0,1.2959,.1562,1.6562,.3359l-.3242,1.2598c-.2637-.1445-.7676-.3359-1.2715-.3359-.4434,0-.6953,.1797-.6953,.4795,0,.2764,.2275,.4199,.9473,.6719,1.1152,.3838,1.583,.9473,1.5957,1.8115,0,1.0908-.8643,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4082l.3232-1.3066Z" fill="#e9427d" />
                                                    <path d="M815.17,172.5716c.0596,.7559,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2402,1.2363c-.5996,.2393-1.332,.3594-2.123,.3594-1.9912,0-3.1309-1.1514-3.1309-2.9863,0-1.4873,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0596,.7197h-3.7783Zm2.1475-1.248c0-.4434-.1924-1.1865-1.0322-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1592Z" fill="#e9427d" />
                                                    <path d="M827.5039,168.6263h-3.0225l-.168,1.1992c.168-.0244,.3125-.0244,.5039-.0244,.7441,0,1.499,.168,2.0508,.5635,.5879,.3965,.9473,1.043,.9473,1.9551,0,1.4512-1.2471,2.7344-3.3457,2.7344-.9473,0-1.7393-.2158-2.1709-.4434l.3232-1.3672c.3477,.168,1.0557,.3828,1.7637,.3828,.7559,0,1.5586-.3594,1.5586-1.1865,0-.8037-.6348-1.2959-2.1943-1.2959-.4326,0-.7314,.0244-1.0557,.0723l.5156-4.0898h4.2939v1.5Z" fill="#e9427d" />
                                                    <path d="M834.3809,171.1683v1.2119h-3.1416v-1.2119h3.1416Z" fill="#e9427d" />
                                                    <path d="M837.958,166.9466c.5645-.0957,1.3555-.168,2.4707-.168,1.1279,0,1.9316,.2158,2.4707,.6475,.5156,.4082,.8633,1.0791,.8633,1.8711s-.2637,1.4639-.7432,1.9189c-.624,.5879-1.5469,.8516-2.626,.8516-.2402,0-.4561-.0117-.624-.0361v2.8906h-1.8115v-7.9756Zm1.8115,3.6699c.1553,.0361,.3477,.0479,.6123,.0479,.9707,0,1.5703-.4912,1.5703-1.3193,0-.7432-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0361-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M844.9903,173.0394c.4912,.251,1.2471,.5029,2.0264,.5029,.8398,0,1.2832-.3477,1.2832-.875,0-.5039-.3828-.791-1.3545-1.1387-1.3428-.4688-2.2188-1.2119-2.2188-2.3867,0-1.3799,1.1514-2.4355,3.0576-2.4355,.9121,0,1.584,.1924,2.0635,.4082l-.4072,1.4746c-.3242-.1553-.9004-.3828-1.6914-.3828-.792,0-1.1758,.3594-1.1758,.7793,0,.5156,.4561,.7432,1.499,1.1387,1.4268,.5283,2.0986,1.2715,2.0986,2.4111,0,1.3555-1.043,2.5068-3.2617,2.5068-.9238,0-1.835-.2402-2.291-.4922l.3721-1.5107Z" fill="#e9427d" />
                                                    <path d="M851.4102,166.9466c.5635-.0957,1.3555-.168,2.4707-.168,1.1279,0,1.9307,.2158,2.4707,.6475,.5156,.4082,.8633,1.0791,.8633,1.8711s-.2637,1.4639-.7432,1.9189c-.624,.5879-1.5469,.8516-2.627,.8516-.2402,0-.4551-.0117-.623-.0361v2.8906h-1.8115v-7.9756Zm1.8115,3.6699c.1553,.0361,.3477,.0479,.6113,.0479,.9707,0,1.5703-.4912,1.5703-1.3193,0-.7432-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0361-.7549,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M857.7823,175.4017l2.5664-8.6953h1.2119l-2.5664,8.6953h-1.2119Z" fill="#e9427d" />
                                                    <path d="M864.2373,172.847l-.5762,2.0752h-1.8945l2.4707-8.083h2.3984l2.5068,8.083h-1.9678l-.623-2.0752h-2.3145Zm2.0508-1.3672l-.5039-1.7148c-.1445-.4795-.2881-1.0801-.4082-1.5586h-.0234c-.1201,.4785-.2402,1.0908-.3721,1.5586l-.4795,1.7148h1.7871Z" fill="#e9427d" />
                                                    <path d="M870.2862,173.0394c.4922,.251,1.2471,.5029,2.0273,.5029,.8389,0,1.2832-.3477,1.2832-.875,0-.5039-.3848-.791-1.3555-1.1387-1.3438-.4688-2.2188-1.2119-2.2188-2.3867,0-1.3799,1.1514-2.4355,3.0586-2.4355,.9111,0,1.582,.1924,2.0625,.4082l-.4082,1.4746c-.3242-.1553-.8994-.3828-1.6914-.3828-.791,0-1.1748,.3594-1.1748,.7793,0,.5156,.4561,.7432,1.499,1.1387,1.4277,.5283,2.0996,1.2715,2.0996,2.4111,0,1.3555-1.0439,2.5068-3.2627,2.5068-.9229,0-1.835-.2402-2.291-.4922l.3721-1.5107Z" fill="#e9427d" />
                                                    <path d="M876.7061,166.9466c.5635-.0957,1.3555-.168,2.4707-.168,1.127,0,1.9307,.2158,2.4707,.6475,.5156,.4082,.8633,1.0791,.8633,1.8711s-.2637,1.4639-.7441,1.9189c-.623,.5879-1.5469,.8516-2.627,.8516-.2393,0-.4551-.0117-.623-.0361v2.8906h-1.8105v-7.9756Zm1.8105,3.6699c.1562,.0361,.3477,.0479,.6113,.0479,.9717,0,1.5713-.4912,1.5713-1.3193,0-.7432-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0361-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M886.1016,166.9466c.4795-.0957,1.4512-.168,2.3623-.168,1.1152,0,1.7988,.1074,2.3867,.4551,.5645,.3008,.9717,.8516,.9717,1.584,0,.7188-.4189,1.3906-1.3311,1.7266v.0244c.9238,.252,1.6074,.9473,1.6074,1.9912,0,.7305-.3359,1.3066-.8398,1.7148-.5879,.4678-1.5713,.7314-3.1777,.7314-.9004,0-1.5723-.0596-1.9795-.1201v-7.9395Zm1.8115,3.0938h.5996c.959,0,1.4746-.3945,1.4746-.9951,0-.6113-.4678-.9346-1.2949-.9346-.3965,0-.624,.0234-.7793,.0469v1.8828Zm0,3.5859c.1797,.0244,.3955,.0244,.707,.0244,.8281,0,1.5586-.3115,1.5586-1.1631,0-.8154-.7305-1.1396-1.6426-1.1396h-.623v2.2783Z" fill="#e9427d" />
                                                    <path d="M896.6592,174.9222l-.1074-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6787,.7197-1.1875,0-1.8955-.8633-1.8955-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2422v-.084c0-.3125-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2031-1.6191,.4434l-.3359-1.1758c.4072-.2275,1.2119-.5156,2.2783-.5156,1.9551,0,2.5791,1.1523,2.5791,2.5312v2.0391c0,.5625,.0244,1.1035,.084,1.4268h-1.6436Zm-.2148-2.7705c-.96-.0117-1.7031,.2158-1.7031,.9238,0,.4668,.3105,.6953,.7188,.6953,.4561,0,.8281-.2998,.9473-.6719,.0244-.0957,.0371-.2031,.0371-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M899.6494,173.3275c.3359,.2031,1.0312,.4434,1.5703,.4434,.5527,0,.7803-.1914,.7803-.4922,0-.2988-.1797-.4434-.8633-.6719-1.2119-.4072-1.6787-1.0664-1.667-1.7617,0-1.0918,.9355-1.9199,2.3867-1.9199,.6836,0,1.2949,.1562,1.6543,.3359l-.3232,1.2598c-.2646-.1445-.7676-.3359-1.2715-.3359-.4443,0-.6963,.1797-.6963,.4795,0,.2764,.2285,.4199,.9482,.6719,1.1152,.3838,1.583,.9473,1.5947,1.8115,0,1.0908-.8633,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4082l.3242-1.3066Z" fill="#e9427d" />
                                                    <path d="M906.2725,172.5716c.0605,.7559,.8037,1.1152,1.6553,1.1152,.624,0,1.1279-.084,1.6191-.2402l.2393,1.2363c-.5996,.2393-1.3311,.3594-2.123,.3594-1.9902,0-3.1289-1.1514-3.1289-2.9863,0-1.4873,.9229-3.1309,2.9619-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0605,.7197h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1865-1.0312-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1582Z" fill="#e9427d" />
                                                    <path d="M911.1934,173.3275c.3359,.2031,1.0312,.4434,1.5713,.4434,.5508,0,.7793-.1914,.7793-.4922,0-.2988-.1797-.4434-.8633-.6719-1.2109-.4072-1.6797-1.0664-1.668-1.7617,0-1.0918,.9355-1.9199,2.3867-1.9199,.6836,0,1.2959,.1562,1.6562,.3359l-.3242,1.2598c-.2637-.1445-.7676-.3359-1.2715-.3359-.4434,0-.6953,.1797-.6953,.4795,0,.2764,.2275,.4199,.9473,.6719,1.1152,.3838,1.583,.9473,1.5957,1.8115,0,1.0908-.8643,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4082l.3232-1.3066Z" fill="#e9427d" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="feltham-loc-base-table">
                                        <g id="bubbles-group-4" data-name="bubbles-group">
                                            <g id="loc-001-bubble">
                                                <g id="body-10" data-name="body">
                                                    <rect x="196.9076" y="75.6393" width="29.6397" height="2.0011" transform="translate(-.0907 .251) rotate(-.0679)" fill="#e9427d" />
                                                    <circle cx="228.587" cy="72.6105" r="12.3333" fill="#e9427d" />
                                                    <polygon points="187.1238 76.2079 202.4277 83.9131 203.5721 71.4088 187.1238 76.2079" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-10" data-name="id">
                                                    <g>
                                                        <path d="M221.0039,68.1693h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M231.4776,72.2708c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M234.7422,69.4407h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9111h.8994v7.7959h-1.0195v-6.8125Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-002-bubble">
                                                <g id="body-11" data-name="body">
                                                    <rect x="272.911" y="133.7982" width="29.6397" height="2.0011" transform="translate(-23.7196 197.7143) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="300.1943" cy="122.0177" r="12.3333" fill="#e9427d" />
                                                    <polygon points="267.9061 149.4443 284.4594 145.0212 276.9984 134.9216 267.9061 149.4443" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-11" data-name="id">
                                                    <g>
                                                        <path d="M292.6114,117.5765h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M303.085,121.678c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M304.0596,125.6605v-.6475l.8271-.8037c1.9912-1.8955,2.8906-2.9023,2.9023-4.0781,0-.791-.3838-1.5234-1.5469-1.5234-.708,0-1.2959,.3604-1.6553,.6602l-.3359-.7441c.54-.4551,1.3076-.791,2.207-.791,1.6787,0,2.3867,1.1514,2.3867,2.2666,0,1.4395-1.0439,2.6025-2.6865,4.1855l-.624,.5762v.0234h3.502v.876h-4.9766Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-003-bubble">
                                                <g id="body-12" data-name="body">
                                                    <rect x="409.9138" y="294.141" width="29.6397" height="2.0011" transform="translate(-92.2421 310.7397) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="437.197" cy="282.3605" r="12.3333" fill="#e9427d" />
                                                    <polygon points="404.9088 309.7871 421.4622 305.364 414.0011 295.2644 404.9088 309.7871" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-12" data-name="id">
                                                    <g>
                                                        <path d="M429.6138,277.9193h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M440.0874,282.0208c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M441.314,284.7913c.2998,.1924,.9951,.4922,1.7266,.4922,1.3555,0,1.7754-.8633,1.7627-1.5117-.0117-1.0908-.9951-1.5586-2.0146-1.5586h-.5879v-.792h.5879c.7676,0,1.7393-.3955,1.7393-1.3193,0-.623-.3955-1.1748-1.3672-1.1748-.624,0-1.2236,.2754-1.5596,.5156l-.2754-.7676c.4072-.2998,1.1992-.5996,2.0391-.5996,1.5352,0,2.2305,.9111,2.2305,1.8584,0,.8037-.4795,1.4873-1.4395,1.835v.0244c.96,.1924,1.7393,.9111,1.7393,2.0029,0,1.2471-.9717,2.3389-2.8428,2.3389-.875,0-1.6426-.2764-2.0264-.5283l.2881-.8154Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-004-bubble">
                                                <g id="body-13" data-name="body">
                                                    <rect x="498.3728" y="402.3194" width="29.6397" height="2.0011" transform="translate(-139.2523 384.6302) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="525.6561" cy="390.5389" r="12.3333" fill="#e9427d" />
                                                    <polygon points="493.3679 417.9655 509.9213 413.5425 502.4602 403.4429 493.3679 417.9655" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-13" data-name="id">
                                                    <g>
                                                        <path d="M518.0733,386.098h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M528.5469,390.1995c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6152,1.1279-4.0547,2.7109-4.0547,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0273,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M532.7832,394.181v-2.123h-3.6221v-.6953l3.4785-4.9766h1.1387v4.8457h1.0918v.8262h-1.0918v2.123h-.9951Zm0-2.9492v-2.6035c0-.4082,.0117-.8154,.0361-1.2227h-.0361c-.2393,.4551-.4316,.791-.6475,1.1504l-1.9072,2.6514v.0244h2.5547Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-005-bubble">
                                                <g id="body-14" data-name="body">
                                                    <rect x="628.9175" y="569.9796" width="29.6397" height="2.0011" transform="translate(-213.398 495.2491) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="656.2007" cy="558.1992" r="12.3333" fill="#e9427d" />
                                                    <polygon points="623.9125 585.6258 640.4659 581.2027 633.0049 571.1031 623.9125 585.6258" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-14" data-name="id">
                                                    <g>
                                                        <path d="M648.6172,553.7581h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M659.0909,557.8597c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M664.7188,554.9339h-2.9746l-.2998,2.0029c.1797-.0244,.3477-.0479,.6357-.0479,.5996,0,1.1992,.1318,1.6787,.4189,.6123,.3486,1.1152,1.0195,1.1152,2.0029,0,1.5234-1.2109,2.6631-2.9023,2.6631-.8516,0-1.5703-.2402-1.9424-.4795l.2637-.8037c.3242,.1914,.959,.4316,1.667,.4316,.9951,0,1.8467-.6475,1.8467-1.6914-.0117-1.0068-.6836-1.7266-2.2422-1.7266-.4443,0-.792,.0479-1.0801,.084l.5039-3.7422h3.7305v.8877Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-006-bubble">
                                                <g id="body-15" data-name="body">
                                                    <rect x="673.5122" y="621.8491" width="29.6397" height="2.0011" transform="translate(-227.6566 773.5399) rotate(-51.483)" fill="#e9427d" />
                                                    <circle cx="698.9568" cy="609.3238" r="12.3333" fill="#e9427d" />
                                                    <polygon points="672.9598 642.1165 687.8103 633.5702 677.995 625.7389 672.9598 642.1165" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-15" data-name="id">
                                                    <g>
                                                        <path d="M691.3741,604.8831h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M701.8477,608.9847c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0264,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                        <path d="M707.2715,605.9144c-.2158-.0117-.4922,0-.792,.0479-1.6543,.2754-2.5303,1.4873-2.71,2.7705h.0361c.3711-.4922,1.0195-.8994,1.8828-.8994,1.3789,0,2.3506,.9951,2.3506,2.5186,0,1.4268-.9717,2.7461-2.5908,2.7461-1.667,0-2.7578-1.2949-2.7578-3.3223,0-1.5352,.5508-2.7461,1.3184-3.5137,.6484-.6357,1.5117-1.0312,2.4951-1.1514,.3115-.0479,.5752-.0596,.7676-.0596v.8633Zm-.2998,4.4971c0-1.1152-.6357-1.7871-1.6074-1.7871-.6357,0-1.2227,.3965-1.5107,.96-.0723,.1201-.1201,.2754-.1201,.4678,.0244,1.2832,.6113,2.2305,1.7148,2.2305,.9121,0,1.5234-.7549,1.5234-1.8711Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-007-bubble">
                                                <g id="body-16" data-name="body">
                                                    <rect x="710.7915" y="745.6799" width="29.6397" height="2.0011" transform="translate(-310.481 849.4238) rotate(-51.483)" fill="#e9427d" />
                                                    <circle cx="736.2362" cy="733.1546" r="12.3333" fill="#e9427d" />
                                                    <polygon points="710.2392 765.9472 725.0897 757.4009 715.2744 749.5697 710.2392 765.9472" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-16" data-name="id">
                                                    <g>
                                                        <path d="M728.6534,728.7132h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M739.127,732.8148c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M745.1621,729.0013v.6953l-3.3936,7.1006h-1.0918l3.3818-6.8965v-.0244h-3.8135v-.875h4.917Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-008-bubble">
                                                <g id="body-17" data-name="body">
                                                    <rect x="713.2935" y="877.772" width="2.0011" height="29.6397" transform="translate(-428.915 895.6144) rotate(-51.483)" fill="#e9427d" />
                                                    <circle cx="735.3902" cy="907.1375" r="12.3333" fill="#e9427d" />
                                                    <polygon points="695.0272 877.2196 703.5735 892.0701 711.4048 882.2548 695.0272 877.2196" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-17" data-name="id">
                                                    <g>
                                                        <path d="M727.8067,902.6966h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M738.2803,906.7982c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0264,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                        <path d="M739.1582,908.8011c0-.9834,.5879-1.6797,1.5479-2.0869l-.0127-.0361c-.8633-.4072-1.2354-1.0791-1.2354-1.751,0-1.2354,1.0439-2.0752,2.4111-2.0752,1.5107,0,2.2666,.9482,2.2666,1.9189,0,.6602-.3232,1.3672-1.2832,1.8232v.0361c.9717,.3838,1.5713,1.0674,1.5713,2.0146,0,1.3555-1.1631,2.2666-2.6504,2.2666-1.6318,0-2.6152-.9707-2.6152-2.1104Zm4.1982-.0479c0-.9473-.6602-1.4033-1.7148-1.7031-.9121,.2637-1.4033,.8633-1.4033,1.6064-.0361,.792,.5635,1.4873,1.5586,1.4873,.9473,0,1.5596-.5869,1.5596-1.3906Zm-2.9023-3.8984c0,.7803,.5879,1.1992,1.4873,1.4395,.6709-.2275,1.1875-.708,1.1875-1.415,0-.624-.3721-1.2715-1.3193-1.2715-.876,0-1.3555,.5762-1.3555,1.2471Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-009-bubble">
                                                <g id="body-18" data-name="body">
                                                    <rect x="729.979" y="992.458" width="29.6397" height="2.0011" transform="translate(-151.7674 134.4742) rotate(-9.3674)" fill="#e9427d" />
                                                    <circle cx="765.5655" cy="990.0581" r="12.3333" fill="#e9427d" />
                                                    <polygon points="720.4749 997.4415 737.2223 1001.0611 735.1933 988.6695 720.4749 997.4415" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-18" data-name="id">
                                                    <g>
                                                        <path d="M757.9825,985.6165h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M768.4561,989.7181c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M770.042,992.9564c.2275,.0244,.4922,0,.8516-.0361,.6113-.084,1.1875-.335,1.6309-.7549,.5156-.4678,.8877-1.1514,1.0312-2.0752h-.0352c-.4326,.5273-1.0557,.8398-1.835,.8398-1.4033,0-2.3027-1.0557-2.3027-2.3867,0-1.4756,1.0674-2.7705,2.6621-2.7705s2.5781,1.2949,2.5781,3.2861c0,1.7148-.5752,2.9141-1.3428,3.6582-.5996,.5869-1.4277,.9473-2.2666,1.043-.3838,.0605-.7197,.0723-.9717,.0605v-.8643Zm.3838-4.4854c0,.9717,.5879,1.6553,1.499,1.6553,.708,0,1.2598-.3477,1.5352-.8154,.0605-.0957,.0957-.2158,.0957-.3838,0-1.3311-.4912-2.3506-1.5947-2.3506-.8994,0-1.5352,.791-1.5352,1.8945Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-010-bubble">
                                                <g id="body-19" data-name="body">
                                                    <rect x="710.0494" y="1208.6598" width="29.6397" height="2.0011" transform="translate(-443.2006 419.5171) rotate(-24.9921)" fill="#e9427d" />
                                                    <circle cx="743.6235" cy="1202.4912" r="12.3333" fill="#e9427d" />
                                                    <polygon points="702.5169 1220.0474 719.6204 1219.0226 714.3288 1207.6354 702.5169 1220.0474" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-19" data-name="id">
                                                    <g>
                                                        <path d="M736.0401,1198.0501h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M743.6231,1199.3216h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9121h.8994v7.7969h-1.0195v-6.8125Z" fill="#fff" />
                                                        <path d="M752.669,1202.1517c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6133,1.1279-4.0527,2.7109-4.0527,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0264,.624,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-011-bubble">
                                                <g id="body-20" data-name="body">
                                                    <rect x="735.486" y="1261.3913" width="2.0011" height="29.6397" transform="translate(-733.1062 1383.0966) rotate(-64.1711)" fill="#e9427d" />
                                                    <circle cx="755.3048" cy="1285.3475" r="12.3333" fill="#e9427d" />
                                                    <polygon points="714.3138 1265.4462 725.9132 1278.0569 731.3973 1266.7612 714.3138 1265.4462" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-20" data-name="id">
                                                    <g>
                                                        <path d="M747.7217,1280.9066h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M755.3047,1282.178h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9111h.8994v7.7949h-1.0195v-6.8115Z" fill="#fff" />
                                                        <path d="M761.46,1282.178h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9111h.8994v7.7949h-1.0195v-6.8115Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-012-bubble">
                                                <g id="body-21" data-name="body">
                                                    <polygon points="590.6871 1390.6392 590.8944 1392.6296 546.0745 1396.2831 545.8672 1394.2927 590.6871 1390.6392" fill="#e9427d" />
                                                    <circle cx="536.6801" cy="1393.5398" r="12.3333" fill="#e9427d" />
                                                    <polygon points="600.569 1390.6464 584.0674 1386.0339 585.3534 1398.5246 600.569 1390.6464" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-21" data-name="id">
                                                    <g>
                                                        <path d="M529.0967,1389.0989h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M536.6797,1390.3704h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9121h.8994v7.7969h-1.0195v-6.8125Z" fill="#fff" />
                                                        <path d="M540.545,1397.1829v-.6484l.8271-.8027c1.9912-1.8955,2.8906-2.9023,2.9023-4.0781,0-.791-.3838-1.5234-1.5469-1.5234-.708,0-1.2959,.3594-1.6553,.6602l-.3359-.7441c.54-.4551,1.3076-.791,2.207-.791,1.6787,0,2.3867,1.1504,2.3867,2.2666,0,1.4385-1.0439,2.6025-2.6865,4.1855l-.624,.5752v.0244h3.502v.876h-4.9766Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="table-group-4" data-name="table-group">
                                            <g id="loc-001-item-row">
                                                <g id="item-base-box-11" data-name="item-base-box">
                                                    <g id="base-box-14" data-name="base-box">
                                                        <rect x="217.6508" y="561.0304" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-14" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,569.9261c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,572.0443c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2696,567.137v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.4004,572.4642c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5396,567.137v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4688,573.3636l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,569.8362c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3374,566.2679v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,567.1673c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,569.8362c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8575,568.5267c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-11" data-name="item-type-box">
                                                    <g id="type-box-17" data-name="type-box">
                                                        <rect x="101.7781" y="561.0304" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-14" data-name="type-text">
                                                        <g>
                                                            <path d="M118.1289,570.2054h-2.6187v2.4287h2.9185v.7295h-3.7876v-6.7363h3.6377v.7295h-2.7686v2.1289h2.6187v.7197Z" fill="#fff" />
                                                            <path d="M122.2095,573.3636l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M124.3506,570.0355c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M127.6607,566.2679h.8794v7.0957h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M130.5103,571.1048c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M135.3501,568.5267l1.0596,2.8584c.1099,.3193,.23,.6992,.3096,.9893h.02c.0903-.29,.1899-.6592,.3101-1.0098l.9595-2.8379h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5078-1.6592,3.0283-.4297,.3799-.8599,.5293-1.0796,.5693l-.2197-.7393c.2197-.0703,.5098-.21,.7695-.4297,.2397-.1904,.5396-.5303,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2305l-1.7891-4.457h.9595Z" fill="#fff" />
                                                            <path d="M146.5977,573.1439c-.3198,.1602-.9595,.3203-1.7788,.3203-1.8989,0-3.3281-1.2002-3.3281-3.4082,0-2.1094,1.4292-3.5381,3.5181-3.5381,.8394,0,1.3691,.1797,1.5991,.2998l-.21,.709c-.3296-.1592-.7993-.2793-1.3594-.2793-1.5791,0-2.6284,1.0088-2.6284,2.7783,0,1.6494,.9497,2.708,2.5884,2.708,.5298,0,1.0698-.1094,1.4194-.2793l.1797,.6895Z" fill="#fff" />
                                                            <path d="M147.6602,566.2679h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M157.2578,572.0443c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M158.7202,570.0355c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0596,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M165.3384,573.1839c-.23,.1201-.7397,.2803-1.3892,.2803-1.4595,0-2.4087-.9902-2.4087-2.4688,0-1.4893,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1309,1.2192,.25l-.1997,.6797c-.21-.1201-.54-.2295-1.0195-.2295-1.1094,0-1.709,.8193-1.709,1.8291,0,1.1191,.7192,1.8086,1.6787,1.8086,.5,0,.8296-.1299,1.0796-.2393l.1499,.6592Z" fill="#fff" />
                                                            <path d="M166.3701,566.2679h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M174.0703,566.7171c.4395-.0898,1.0693-.1396,1.6689-.1396,.9292,0,1.5293,.1699,1.9487,.5498,.3398,.2998,.5298,.7598,.5298,1.2793,0,.8896-.5596,1.4795-1.269,1.7188v.0303c.5195,.1797,.8296,.6592,.9893,1.3594,.2197,.9395,.3799,1.5889,.5195,1.8486h-.8994c-.1099-.1895-.2598-.7695-.4497-1.6084-.1997-.9297-.5596-1.2793-1.3491-1.3096h-.8198v2.918h-.8691v-6.6465Zm.8691,3.0684h.8896c.9297,0,1.519-.5098,1.519-1.2793,0-.8691-.6294-1.249-1.5488-1.2588-.4199,0-.7197,.04-.8599,.0801v2.458Z" fill="#fff" />
                                                            <path d="M183.8379,570.9056c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M187.6285,573.3636l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M193.9473,566.2679v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-11" data-name="item-id-box">
                                                    <g id="id-box-16" data-name="id-box">
                                                        <rect x="43.3808" y="561.0304" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-13" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,566.6585h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,570.1165c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M77.4927,568.2484h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-002-item-row">
                                                <g id="item-base-box-12" data-name="item-base-box">
                                                    <g id="base-box-15" data-name="base-box">
                                                        <rect x="217.6508" y="579.1554" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-15" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,588.0511c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,590.1693c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2696,585.262v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.4004,590.5892c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5396,585.262v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4688,591.4886l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,587.9612c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3374,584.3929v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,585.2923c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,587.9612c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8575,586.6517c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-12" data-name="item-type-box">
                                                    <g id="type-box-18" data-name="type-box">
                                                        <rect x="101.7781" y="579.1554" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-15" data-name="type-text">
                                                        <g>
                                                            <path d="M118.1289,588.3304h-2.6187v2.4287h2.9185v.7295h-3.7876v-6.7363h3.6377v.7295h-2.7686v2.1289h2.6187v.7197Z" fill="#fff" />
                                                            <path d="M122.2095,591.4886l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M124.3506,588.1605c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M127.6607,584.3929h.8794v7.0957h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M130.5103,589.2298c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M135.3501,586.6517l1.0596,2.8584c.1099,.3193,.23,.6992,.3096,.9893h.02c.0903-.29,.1899-.6592,.3101-1.0098l.9595-2.8379h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5078-1.6592,3.0283-.4297,.3799-.8599,.5293-1.0796,.5693l-.2197-.7393c.2197-.0703,.5098-.21,.7695-.4297,.2397-.1904,.5396-.5303,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2305l-1.7891-4.457h.9595Z" fill="#fff" />
                                                            <path d="M141.7705,590.429c.3896,.2402,.9595,.4404,1.5591,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3599-.9893-1.269-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1592,1.4893,.3291l-.2397,.71c-.2197-.1201-.6694-.3193-1.2793-.3193-.9194,0-1.2695,.5488-1.2695,1.0088,0,.6299,.4102,.9395,1.3394,1.2998,1.1396,.4395,1.7192,.9893,1.7192,1.9785,0,1.0391-.7695,1.9395-2.3589,1.9395-.6494,0-1.3589-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M147.8199,585.262v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M152.7491,591.4886l-.0698-.6094h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M155.9097,585.262v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M159.1397,585.2923c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M164.878,589.0306c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M165.9903,587.9612c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-12" data-name="item-id-box">
                                                    <g id="id-box-17" data-name="id-box">
                                                        <rect x="43.3808" y="579.1554" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-14" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,584.7835h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,588.2415c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M75.5537,591.5198v-.9189l.8394-.7598c1.4194-1.2695,2.1089-1.999,2.1289-2.7588,0-.5293-.3198-.9492-1.0693-.9492-.5601,0-1.0493,.2793-1.3892,.54l-.4302-1.0898c.4897-.3701,1.2495-.6699,2.1289-.6699,1.4692,0,2.2788,.8594,2.2788,2.0391,0,1.0898-.7896,1.959-1.729,2.7988l-.5996,.499v.0205h2.4487v1.249h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-003-item-row">
                                                <g id="item-base-box-13" data-name="item-base-box">
                                                    <g id="base-box-16" data-name="base-box">
                                                        <rect x="217.6508" y="597.2804" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-16" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,606.1761c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,608.2943c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2696,603.387v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.4004,608.7142c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5396,603.387v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4688,609.6136l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,606.0862c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3374,602.5179v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,603.4173c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,606.0862c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8575,604.7767c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-13" data-name="item-type-box">
                                                    <g id="type-box-19" data-name="type-box">
                                                        <rect x="101.7781" y="597.2804" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-16" data-name="type-text">
                                                        <g>
                                                            <path d="M115.7403,609.6136l-1.709-6.7363h.9194l.7998,3.4082c.1997,.8398,.3794,1.6797,.4995,2.3291h.02c.1099-.6699,.3198-1.4697,.5498-2.3389l.8994-3.3984h.9097l.8193,3.418c.1899,.7998,.3696,1.5996,.4697,2.3096h.02c.1401-.7402,.3301-1.4893,.5396-2.3291l.8896-3.3984h.8896l-1.9092,6.7363h-.9092l-.8496-3.5078c-.21-.8594-.3501-1.5195-.4399-2.1992h-.02c-.1196,.6699-.2695,1.3301-.5195,2.1992l-.9595,3.5078h-.9097Z" fill="#fff" />
                                                            <path d="M123.8902,603.4173c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M125.251,606.0862c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M130.8008,606.0862c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M136.8404,607.3548c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M141.3604,606.2855c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M144.5108,608.7142c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M148.5904,602.5179h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M157.1602,603.6175h-2.0488v-.7402h4.9868v.7402h-2.0586v5.9961h-.8794v-5.9961Z" fill="#fff" />
                                                            <path d="M160.3706,606.2855c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0596,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M164.6597,603.4173c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M168.6993,609.6136l-.0698-.6094h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M170.8404,606.0862c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M180.5581,604.7767c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                            <path d="M181.98,602.5179h.8794v7.0957h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M184.8296,607.3548c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M191.3799,608.554c.3896,.2402,.9595,.4404,1.5591,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3599-.9893-1.269-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1592,1.4893,.3291l-.2397,.71c-.2197-.1201-.6694-.3193-1.2793-.3193-.9194,0-1.2695,.5488-1.2695,1.0088,0,.6299,.4102,.9395,1.3394,1.2998,1.1396,.4395,1.7192,.9893,1.7192,1.9785,0,1.0391-.7695,1.9395-2.3589,1.9395-.6494,0-1.3589-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M197.4292,603.387v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M199.6797,606.0862c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-13" data-name="item-id-box">
                                                    <g id="id-box-18" data-name="id-box">
                                                        <rect x="43.3808" y="597.2804" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-15" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,602.9085h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,606.3665c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M75.8233,608.1556c.27,.1406,.8896,.4004,1.5093,.4004,.7896,0,1.1895-.3799,1.1895-.8701,0-.6396-.6396-.9287-1.3091-.9287h-.6201v-1.0898h.5898c.5098-.0098,1.1592-.2002,1.1592-.75,0-.3896-.3198-.6797-.9595-.6797-.5293,0-1.0894,.2305-1.3589,.3906l-.3101-1.0996c.3896-.25,1.1694-.4902,2.0088-.4902,1.3896,0,2.1592,.7295,2.1592,1.6191,0,.6895-.3901,1.2295-1.1895,1.5098v.0195c.7793,.1396,1.4092,.7295,1.4092,1.5791,0,1.1494-1.0093,1.9893-2.6587,1.9893-.8394,0-1.5488-.2197-1.9287-.46l.3096-1.1396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-004-item-row">
                                                <g id="item-base-box-14" data-name="item-base-box">
                                                    <g id="base-box-17" data-name="base-box">
                                                        <rect x="217.6508" y="615.4054" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-17" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,624.3011c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,626.4193c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2696,621.512v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.4004,626.8392c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5396,621.512v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4688,627.7386l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,624.2112c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3374,620.6429v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,621.5423c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,624.2112c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8575,622.9017c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-14" data-name="item-type-box">
                                                    <g id="type-box-20" data-name="type-box">
                                                        <rect x="101.7781" y="615.4054" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-17" data-name="type-text">
                                                        <g>
                                                            <path d="M115.7403,627.7386l-1.709-6.7363h.9194l.7998,3.4082c.1997,.8398,.3794,1.6797,.4995,2.3291h.02c.1099-.6699,.3198-1.4697,.5498-2.3389l.8994-3.3984h.9097l.8193,3.418c.1899,.7998,.3696,1.5996,.4697,2.3096h.02c.1401-.7402,.3301-1.4893,.5396-2.3291l.8896-3.3984h.8896l-1.9092,6.7363h-.9092l-.8496-3.5078c-.21-.8594-.3501-1.5195-.4399-2.1992h-.02c-.1196,.6699-.2695,1.3301-.5195,2.1992l-.9595,3.5078h-.9097Z" fill="#fff" />
                                                            <path d="M123.8902,621.5423c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M125.251,624.2112c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M130.8008,624.2112c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M136.8404,625.4798c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M141.3604,624.4105c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M144.5108,626.8392c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M148.5904,620.6429h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M156.1704,626.679c.3896,.2402,.9595,.4404,1.5591,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3599-.9893-1.269-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1592,1.4893,.3291l-.2397,.71c-.2197-.1201-.6694-.3193-1.2793-.3193-.9194,0-1.269,.5488-1.269,1.0088,0,.6299,.4097,.9395,1.3389,1.2998,1.1396,.4395,1.7192,.9893,1.7192,1.9785,0,1.0391-.7695,1.9395-2.3589,1.9395-.6494,0-1.3589-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M162.2198,621.512v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M167.149,627.7386l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M170.3101,621.512v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M173.5396,621.5423c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M179.2779,625.2806c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M180.3902,624.2112c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-14" data-name="item-id-box">
                                                    <g id="id-box-19" data-name="id-box">
                                                        <rect x="43.3808" y="615.4054" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-16" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,621.0335h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,624.4915c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M78.2422,627.7698v-1.5488h-2.8784v-.9893l2.4585-3.958h1.8589v3.8076h.7798v1.1396h-.7798v1.5488h-1.439Zm0-2.6885v-1.4395c0-.3896,.02-.7891,.0498-1.209h-.04c-.21,.4199-.3799,.7998-.5996,1.209l-.8696,1.4199v.0195h1.4595Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-005-item-row">
                                                <g id="item-base-box-15" data-name="item-base-box">
                                                    <g id="base-box-18" data-name="base-box">
                                                        <rect x="217.6508" y="633.5057" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-18" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,642.4017c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,644.5198c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2696,639.6126v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.4004,644.9398c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5396,639.6126v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4688,645.8392l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,642.3118c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3374,638.7435v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,639.6429c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,642.3118c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8575,641.0023c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-15" data-name="item-type-box">
                                                    <g id="type-box-21" data-name="type-box">
                                                        <rect x="101.7781" y="633.5057" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-18" data-name="type-text">
                                                        <g>
                                                            <path d="M115.9204,639.8431h-2.0488v-.7402h4.9868v.7402h-2.0586v5.9961h-.8794v-5.9961Z" fill="#fff" />
                                                            <path d="M123.2486,643.3812c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M128.4087,644.5198c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M130.9302,639.6126v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M133.1807,638.7435h.8794v7.0957h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M136.0303,643.5804c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M140.8701,641.0023l1.0596,2.8584c.1099,.3193,.23,.6992,.3096,.9893h.02c.0903-.29,.1899-.6592,.3101-1.0098l.9595-2.8379h.9297l-1.3193,3.4482c-.6299,1.6592-1.0596,2.5078-1.6592,3.0283-.4297,.3799-.8594,.5293-1.0796,.5693l-.2197-.7393c.2197-.0703,.5098-.21,.7695-.4297,.2397-.1904,.5396-.5303,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2305l-1.7891-4.457h.9595Z" fill="#fff" />
                                                            <path d="M147.4107,639.1028h3.6279v.7295h-2.7588v2.2393h2.5488v.7197h-2.5488v3.0479h-.8691v-6.7363Z" fill="#fff" />
                                                            <path d="M156.3184,643.3812c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M157.4302,642.511c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0596,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M161.0904,643.5804c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M165.4908,644.9398c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M170.6299,639.6126v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M175.0303,639.1927c.4395-.0898,1.0693-.1396,1.6689-.1396,.9292,0,1.5293,.1699,1.9487,.5498,.3398,.2998,.5298,.7598,.5298,1.2793,0,.8896-.5596,1.4795-1.269,1.7188v.0303c.5195,.1797,.8296,.6592,.9893,1.3594,.2197,.9395,.3799,1.5889,.5195,1.8486h-.8994c-.1099-.1895-.2598-.7695-.4497-1.6084-.1997-.9297-.5596-1.2793-1.3491-1.3096h-.8198v2.918h-.8691v-6.6465Zm.8691,3.0684h.8896c.9297,0,1.519-.5098,1.519-1.2793,0-.8691-.6294-1.249-1.5488-1.2588-.4199,0-.7197,.04-.8599,.0801v2.458Z" fill="#fff" />
                                                            <path d="M184.7979,643.3812c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M188.5889,645.8392l-.0698-.6094h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M194.9077,638.7435v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.269-1.0195-.9097,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-15" data-name="item-id-box">
                                                    <g id="id-box-20" data-name="id-box">
                                                        <rect x="43.3808" y="633.5057" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-17" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,639.1341h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,642.5921c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M79.8911,640.6234h-2.5186l-.1401,1c.1401-.0205,.2603-.0205,.4199-.0205,.6196,0,1.2495,.1396,1.709,.4697,.4897,.3301,.7896,.8701,.7896,1.6289,0,1.21-1.0391,2.2793-2.7881,2.2793-.7896,0-1.4492-.1797-1.8091-.3701l.2695-1.1396c.29,.1406,.8799,.3203,1.4692,.3203,.6299,0,1.2993-.2998,1.2993-.9893,0-.6699-.5298-1.0801-1.8291-1.0801-.3594,0-.6094,.0205-.8794,.0605l.4297-3.4082h3.5781v1.249Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-006-item-row">
                                                <g id="item-base-box-16" data-name="item-base-box">
                                                    <g id="base-box-19" data-name="base-box">
                                                        <rect x="217.6508" y="651.6307" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-19" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,660.5267c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,662.6448c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2696,657.7376v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.4004,663.0648c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5396,657.7376v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4688,663.9642l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,660.4368c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3374,656.8685v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,657.7679c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,660.4368c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8575,659.1273c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-16" data-name="item-type-box">
                                                    <g id="type-box-22" data-name="type-box">
                                                        <rect x="101.7781" y="651.6307" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-19" data-name="type-text">
                                                        <g>
                                                            <path d="M114.6411,657.2278h.8691v6.0068h2.8784v.7295h-3.7476v-6.7363Z" fill="#fff" />
                                                            <path d="M122.0093,663.9642l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M124.1509,660.636c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0596,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M130.7691,663.7845c-.23,.1201-.7397,.2803-1.3892,.2803-1.4595,0-2.4087-.9902-2.4087-2.4688,0-1.4893,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1309,1.2192,.25l-.1997,.6797c-.21-.1201-.54-.2295-1.0195-.2295-1.1094,0-1.709,.8193-1.709,1.8291,0,1.1191,.7192,1.8086,1.6787,1.8086,.5,0,.8296-.1299,1.0796-.2393l.1499,.6592Z" fill="#fff" />
                                                            <path d="M131.8008,656.8685h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M140.5899,661.846l-.6997,2.1182h-.8994l2.2886-6.7363h1.0493l2.2988,6.7363h-.9297l-.7192-2.1182h-2.3887Zm2.2085-.6797l-.6597-1.9395c-.1499-.4395-.2495-.8398-.3496-1.2295h-.02c-.1001,.4004-.21,.8096-.3398,1.2197l-.6597,1.9492h2.0288Z" fill="#fff" />
                                                            <path d="M145.7202,659.1273l.9497,2.7188c.1597,.4395,.2896,.8389,.3896,1.2393h.0298c.1104-.4004,.25-.7998,.4102-1.2393l.9395-2.7188h.9194l-1.8989,4.8369h-.8398l-1.8389-4.8369h.9395Z" fill="#fff" />
                                                            <path d="M150.5801,661.7054c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M155.1001,660.4368c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M164.6983,662.6448c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M166.6499,661.7054c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-16" data-name="item-id-box">
                                                    <g id="id-box-21" data-name="id-box">
                                                        <rect x="43.3808" y="651.6307" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-18" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,657.2591h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,660.7171c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M79.7012,658.5784c-.1699-.0098-.3496,0-.5894,.0205-1.3496,.1094-1.9492,.7988-2.1191,1.5586h.0303c.3198-.3291,.7695-.5195,1.3789-.5195,1.0894,0,2.0093,.7695,2.0093,2.1191,0,1.2891-.9897,2.3486-2.3989,2.3486-1.729,0-2.5786-1.2891-2.5786-2.8389,0-1.2188,.4497-2.2383,1.1494-2.8877,.6494-.5898,1.4893-.9102,2.5088-.96,.2798-.0195,.4595-.0195,.6094-.0098v1.1689Zm-.8296,3.2588c0-.5996-.3198-1.1201-.9692-1.1201-.4097,0-.7495,.25-.8994,.5801-.04,.0801-.0601,.2002-.0601,.3799,.0298,.6895,.3599,1.3096,1.0493,1.3096,.5396,0,.8794-.4902,.8794-1.1494Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-007-item-row">
                                                <g id="item-base-box-17" data-name="item-base-box">
                                                    <g id="base-box-20" data-name="base-box">
                                                        <rect x="217.6508" y="669.7311" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-20" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,678.6263c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,680.7454c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M251.2696,675.8382v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M253.4004,681.1653c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M258.5396,675.8382v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M263.4688,682.0648l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,678.5364c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M275.3374,674.9681v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,675.8675c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,678.5364c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M288.8575,677.2269c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-17" data-name="item-type-box">
                                                    <g id="type-box-23" data-name="type-box">
                                                        <rect x="101.7781" y="669.7311" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-20" data-name="type-text">
                                                        <g>
                                                            <path d="M115.7403,682.0648l-1.709-6.7363h.9194l.7998,3.4082c.1997,.8389,.3794,1.6787,.4995,2.3281h.02c.1099-.6689,.3198-1.4688,.5498-2.3389l.8994-3.3975h.9097l.8193,3.418c.1899,.7998,.3696,1.5986,.4697,2.3086h.02c.1401-.7393,.3301-1.4893,.5396-2.3291l.8896-3.3975h.8896l-1.9092,6.7363h-.9092l-.8496-3.5088c-.21-.8594-.3501-1.5186-.4399-2.1982h-.02c-.1196,.6699-.2695,1.3291-.5195,2.1982l-.9595,3.5088h-.9097Z" fill="#fff" />
                                                            <path d="M127.1187,679.6058c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M129.1006,679.4456h.02c.1201-.1699,.29-.3799,.4297-.5498l1.4194-1.6689h1.0591l-1.8687,1.9893,2.1289,2.8486h-1.0698l-1.6689-2.3193-.4497,.5v1.8193h-.8696v-7.0967h.8696v4.4775Z" fill="#fff" />
                                                            <path d="M134.0703,675.8675c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M135.4307,678.5364c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M145.1485,677.2269c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2202,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6094-.4004l.2202-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5591-.4697,1.5591-1.6895v-.54h-.0195c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                            <path d="M146.5703,674.9681h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M154.7993,682.0648l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M156.9405,678.5364c0-.5-.0098-.9092-.04-1.3096h.7695l.04,.7803h.0303c.2695-.46,.7192-.8896,1.519-.8896,.6597,0,1.1592,.3994,1.3691,.9688h.02c.1499-.2695,.3398-.4795,.5396-.6289,.29-.2197,.6099-.3398,1.0698-.3398,.6396,0,1.5889,.4199,1.5889,2.0986v2.8486h-.8594v-2.7383c0-.9297-.3398-1.4893-1.0493-1.4893-.5,0-.8896,.3691-1.0396,.7988-.04,.1201-.0698,.2803-.0698,.4404v2.9883h-.8599v-2.8984c0-.7695-.3398-1.3291-1.0093-1.3291-.5498,0-.9497,.4395-1.0894,.8789-.0503,.1299-.0698,.2803-.0698,.4297v2.9189h-.8599v-3.5283Z" fill="#fff" />
                                                            <path d="M167.4307,675.3284h.8691v6.0068h2.8784v.7295h-3.7476v-6.7363Z" fill="#fff" />
                                                            <path d="M175.8384,682.0648l-.8599-1.4893c-.3496-.5703-.5693-.9395-.7793-1.3291h-.02c-.1899,.3896-.3799,.749-.7295,1.3389l-.8096,1.4795h-.9995l2.0586-3.4082-1.979-3.3281h1.0098l.8896,1.5791c.2495,.4395,.4395,.7793,.6196,1.1396h.0298c.1899-.4004,.3599-.71,.6099-1.1396l.9194-1.5791h.9995l-2.0493,3.2783,2.0991,3.458h-1.0093Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-17" data-name="item-id-box">
                                                    <g id="id-box-22" data-name="id-box">
                                                        <rect x="43.3808" y="669.7311" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-19" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,675.3597h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,678.8177c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6792,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1982,.9395,2.1982,.5894,0,.9092-.7393,.9092-2.2188,0-1.4385-.3096-2.1982-.9194-2.1982-.5596,0-.9395,.709-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M80.211,675.5989v.96l-2.6787,5.5371h-1.6089l2.6787-5.2275v-.0195h-2.9785v-1.25h4.5874Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-008-item-row">
                                                <g id="item-base-box-18" data-name="item-base-box">
                                                    <g id="base-box-21" data-name="base-box">
                                                        <rect x="217.6508" y="687.8314" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-21" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,696.7269c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,698.846c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M251.2696,693.9388v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M253.4004,699.2659c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M258.5396,693.9388v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M263.4688,700.1653l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,696.637c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M275.3374,693.0687v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,693.9681c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,696.637c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M288.8575,695.3275c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-18" data-name="item-type-box">
                                                    <g id="type-box-24" data-name="type-box">
                                                        <rect x="101.7781" y="687.8314" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-21" data-name="type-text">
                                                        <g>
                                                            <path d="M119.3482,699.9456c-.3198,.1592-.9595,.3193-1.7788,.3193-1.8989,0-3.3281-1.1992-3.3281-3.4082,0-2.1084,1.4292-3.5381,3.5181-3.5381,.8394,0,1.3691,.1797,1.5991,.2998l-.21,.71c-.3296-.1602-.7993-.2803-1.3594-.2803-1.5791,0-2.6284,1.0098-2.6284,2.7783,0,1.6494,.9497,2.709,2.5884,2.709,.5298,0,1.0698-.1104,1.4194-.2803l.1797,.6904Z" fill="#fff" />
                                                            <path d="M120.4107,693.0687h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.29-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M128.6392,700.1653l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M130.7808,696.9066c0-.6191-.02-1.1191-.04-1.5791h.7896l.04,.8301h.02c.3599-.5898,.9297-.9395,1.7192-.9395,1.1694,0,2.0488,.9893,2.0488,2.458,0,1.7393-1.0596,2.5986-2.1987,2.5986-.6396,0-1.1992-.2793-1.4893-.7588h-.02v2.6279h-.8696v-5.2373Zm.8696,1.29c0,.1299,.02,.249,.04,.3594,.1597,.6094,.6895,1.0293,1.3193,1.0293,.9292,0,1.4692-.7598,1.4692-1.8691,0-.9688-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0898-.0303,.1094-.0601,.2393-.0601,.3594v.8301Z" fill="#fff" />
                                                            <path d="M136.96,697.9066c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M141.4805,693.0687h.8794v7.0967h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M151.1172,699.8655c-.3896,.1396-1.1592,.3691-2.0688,.3691-1.0195,0-1.8589-.2598-2.5186-.8887-.5796-.5605-.9395-1.46-.9395-2.5088,.0098-2.0088,1.3892-3.4785,3.6479-3.4785,.7798,0,1.3892,.1699,1.6792,.3096l-.21,.71c-.3599-.1602-.8096-.29-1.4893-.29-1.6392,0-2.7085,1.0195-2.7085,2.709,0,1.709,1.0293,2.7188,2.5986,2.7188,.5698,0,.9595-.0801,1.1592-.1807v-2.0088h-1.3691v-.6992h2.2188v3.2383Z" fill="#fff" />
                                                            <path d="M152.42,696.8372c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M156.0801,697.9066c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M161.0899,697.9066c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M165.6099,696.637c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-18" data-name="item-id-box">
                                                    <g id="id-box-23" data-name="id-box">
                                                        <rect x="43.3808" y="687.8314" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-20" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,693.4603h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,696.9183c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6792,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1982,.9395,2.1982,.5894,0,.9092-.7393,.9092-2.2188,0-1.4385-.3096-2.1982-.9194-2.1982-.5596,0-.9395,.709-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M75.4536,698.4974c0-.7793,.4497-1.3193,1.1592-1.6289v-.0303c-.6294-.3193-.9395-.8496-.9395-1.4189,0-1.0996,.9897-1.8291,2.2891-1.8291,1.519,0,2.1484,.8789,2.1484,1.6689,0,.5596-.2998,1.1094-.9395,1.4287v.0303c.6299,.2402,1.1895,.7793,1.1895,1.6191,0,1.1797-.9893,1.9688-2.4985,1.9688-1.6489,0-2.4087-.9287-2.4087-1.8086Zm3.3579-.0498c0-.5703-.4194-.9102-1.019-1.0693-.5,.1396-.7896,.499-.7896,.9688-.0103,.4697,.3496,.9102,.9194,.9102,.5396,0,.8892-.3496,.8892-.8096Zm-1.709-3.0781c0,.4395,.3999,.7188,.9194,.8789,.3501-.0996,.6597-.4199,.6597-.8291,0-.4004-.2295-.7998-.7896-.7998-.5195,0-.7896,.3398-.7896,.75Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-009-item-row">
                                                <g id="item-base-box-19" data-name="item-base-box">
                                                    <g id="base-box-22" data-name="base-box">
                                                        <rect x="217.6508" y="705.9318" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-22" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5674,714.8275c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7481,716.9456c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2696,712.0384v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.4004,717.3655c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5396,712.0384v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4688,718.265l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.6099,714.7376c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3374,711.1693v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7793,712.0687c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.1397,714.7376c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8575,713.428c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-19" data-name="item-type-box">
                                                    <g id="type-box-25" data-name="type-box">
                                                        <rect x="101.7781" y="705.9318" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-22" data-name="type-text">
                                                        <g>
                                                            <path d="M119.3482,718.0452c-.3198,.1602-.9595,.3203-1.7788,.3203-1.8989,0-3.3281-1.2002-3.3281-3.4082,0-2.1094,1.4292-3.5381,3.5181-3.5381,.8394,0,1.3691,.1797,1.5991,.2998l-.21,.709c-.3296-.1592-.7993-.2793-1.3594-.2793-1.5791,0-2.6284,1.0088-2.6284,2.7783,0,1.6494,.9497,2.708,2.5884,2.708,.5298,0,1.0698-.1094,1.4194-.2793l.1797,.6895Z" fill="#fff" />
                                                            <path d="M120.3809,714.9368c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M127.9287,715.8069c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M129.3208,713.428l.6396,2.458c.1396,.54,.2695,1.04,.3594,1.54h.0303c.1099-.4902,.27-1.0098,.4297-1.5293l.7896-2.4688h.7397l.7495,2.4189c.1797,.5791,.3198,1.0889,.4297,1.5791h.0298c.0801-.4902,.21-1,.3701-1.5693l.6895-2.4287h.8696l-1.5591,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3101-1.0195-.4297-1.5889h-.02c-.1201,.5791-.27,1.0791-.4399,1.5986l-.7798,2.2988h-.7993l-1.4595-4.8369h.8999Z" fill="#fff" />
                                                            <path d="M137.3902,712.0384v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M139.6407,711.1693h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M149.5689,715.8069c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M150.6807,714.9368c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M153.9908,714.7376c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M160.0303,716.0062c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M166.5806,717.2054c.3896,.2402,.9595,.4404,1.5591,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3599-.9893-1.269-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1592,1.4893,.3291l-.2397,.71c-.2197-.1201-.6694-.3193-1.2793-.3193-.9194,0-1.269,.5488-1.269,1.0088,0,.6299,.4097,.9395,1.3389,1.2998,1.1396,.4395,1.7192,.9893,1.7192,1.9785,0,1.0391-.7695,1.9395-2.3589,1.9395-.6494,0-1.3589-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M172.6299,712.0384v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M177.5591,718.265l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M180.7198,712.0384v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M183.9497,712.0687c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M189.688,715.8069c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M190.8003,714.7376c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-19" data-name="item-id-box">
                                                    <g id="id-box-24" data-name="id-box">
                                                        <rect x="43.3808" y="705.9318" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-21" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1338,711.5599h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.8013,715.0179c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M76.0332,717.1966c.1899,.0205,.3599,.0205,.6597,0,.46-.0293,.9297-.1592,1.2793-.3994,.4199-.29,.6997-.71,.8198-1.1992l-.0303-.0098c-.2896,.2998-.7095,.4697-1.2993,.4697-1.0991,0-2.0288-.7695-2.0288-2.0293,0-1.2695,1.0195-2.3389,2.4487-2.3389,1.6689,0,2.4585,1.2793,2.4585,2.7988,0,1.3496-.4297,2.3389-1.1392,2.9883-.6196,.5596-1.4692,.8691-2.4785,.9092-.2603,.0205-.52,.0107-.6899,0v-1.1895Zm.9194-3.2578c0,.5596,.2998,1.0391,.9194,1.0391,.4102,0,.6997-.1992,.8398-.4297,.0498-.0898,.0801-.1895,.0801-.3799,0-.6895-.2603-1.3691-.9497-1.3691-.5195,0-.8994,.46-.8896,1.1396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-010-item-row">
                                                <g id="item-base-box-20" data-name="item-base-box">
                                                    <g id="base-box-23" data-name="base-box">
                                                        <rect x="217.637" y="724.0459" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-23" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5537,732.9417c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7344,735.0599c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2559,730.1527v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.3868,735.4798c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5259,730.1527v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4551,736.3792l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.5962,732.8519c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3238,729.2835v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7657,730.1829c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.126,732.8519c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8438,731.5423c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-20" data-name="item-type-box">
                                                    <g id="type-box-26" data-name="type-box">
                                                        <rect x="101.7643" y="724.0459" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-23" data-name="type-text">
                                                        <g>
                                                            <path d="M114.6275,729.7327c.5293-.0791,1.1592-.1396,1.8486-.1396,1.2495,0,2.1392,.29,2.7285,.8398,.5996,.5498,.9497,1.3291,.9497,2.4189,0,1.0986-.3398,1.998-.9697,2.6182-.6294,.6299-1.6689,.9697-2.978,.9697-.6196,0-1.1396-.0303-1.5791-.0801v-6.627Zm.8691,5.957c.2202,.04,.54,.0498,.8799,.0498,1.8589,0,2.8682-1.0391,2.8682-2.8584,.0103-1.5889-.8896-2.5986-2.7285-2.5986-.4497,0-.7896,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M123.9356,736.3792l-.0698-.6094h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M126.0772,733.0511c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M129.3467,736.3792c.02-.3291,.04-.8193,.04-1.249v-5.8467h.8696v3.0381h.02c.3096-.54,.8696-.8896,1.6489-.8896,1.1997,0,2.0488,1,2.0391,2.4688,0,1.7295-1.0894,2.5889-2.1689,2.5889-.6997,0-1.2593-.2705-1.6191-.9102h-.0298l-.04,.7998h-.7598Zm.9097-1.9385c0,.1094,.02,.2197,.04,.3193,.1699,.6104,.6797,1.0293,1.3193,1.0293,.9194,0,1.4692-.749,1.4692-1.8584,0-.9697-.5-1.7988-1.4395-1.7988-.5996,0-1.1592,.4092-1.3394,1.0791-.02,.0996-.0498,.2197-.0498,.3594v.8701Z" fill="#fff" />
                                                            <path d="M135.3267,731.5423l1.0596,2.8584c.1099,.3193,.23,.6992,.3096,.9893h.02c.0903-.29,.1899-.6592,.3101-1.0098l.9595-2.8379h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5078-1.6592,3.0283-.4297,.3799-.8599,.5293-1.0796,.5693l-.2197-.7393c.2197-.0703,.5098-.21,.7695-.4297,.2397-.1904,.5396-.5303,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2305l-1.7891-4.457h.9595Z" fill="#fff" />
                                                            <path d="M146.9942,736.0794c-.3896,.1396-1.1592,.3701-2.0688,.3701-1.0195,0-1.8589-.2598-2.5186-.8896-.5796-.5596-.9395-1.459-.9395-2.5088,.0098-2.0088,1.3892-3.4775,3.6479-3.4775,.7798,0,1.3892,.1699,1.6792,.3096l-.21,.71c-.3599-.1602-.8096-.29-1.4893-.29-1.6392,0-2.7085,1.0195-2.7085,2.708,0,1.709,1.0293,2.7188,2.5986,2.7188,.5698,0,.9595-.0801,1.1592-.1797v-2.0088h-1.3691v-.7002h2.2188v3.2383Z" fill="#fff" />
                                                            <path d="M148.2969,733.0511c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M151.9566,734.1204c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M156.9663,734.1204c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M161.4864,732.8519c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M169.1866,729.6429h3.6279v.7295h-2.7588v2.2393h2.5488v.7197h-2.5488v3.0479h-.8691v-6.7363Z" fill="#fff" />
                                                            <path d="M178.0943,733.9212c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M183.5845,733.9212c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M185.7559,730.1527v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M188.0064,733.1214c0-.6201-.02-1.1191-.04-1.5791h.7896l.04,.8291h.02c.3599-.5898,.9297-.9395,1.7192-.9395,1.1694,0,2.0488,.9893,2.0488,2.459,0,1.7393-1.0596,2.5986-2.1987,2.5986-.6396,0-1.1992-.2803-1.4893-.7598h-.02v2.6289h-.8696v-5.2373Zm.8696,1.2891c0,.1299,.02,.25,.04,.3604,.1597,.6094,.6895,1.0293,1.3193,1.0293,.9292,0,1.4692-.7598,1.4692-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0601,.2402-.0601,.3604v.8291Z" fill="#fff" />
                                                            <path d="M196.375,736.3792l-.0698-.6094h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M199.5357,730.1527v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M201.7862,729.2835h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-20" data-name="item-id-box">
                                                    <g id="id-box-25" data-name="id-box">
                                                        <rect x="43.367" y="724.0459" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-22" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,729.6741h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M71.9292,731.264h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                            <path d="M80.3374,733.1321c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-011-item-row">
                                                <g id="item-base-box-21" data-name="item-base-box">
                                                    <g id="base-box-24" data-name="base-box">
                                                        <rect x="217.637" y="742.1601" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-24" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5537,751.056c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7344,753.1741c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2559,748.2669v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.3868,753.5941c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5259,748.2669v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4551,754.4935l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.5962,750.9661c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3238,747.3978v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7657,748.2972c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.126,750.9661c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8438,749.6566c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-21" data-name="item-type-box">
                                                    <g id="type-box-27" data-name="type-box">
                                                        <rect x="101.7643" y="742.1601" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-24" data-name="type-text">
                                                        <g>
                                                            <path d="M114.6275,747.847c.3794-.0791,.9795-.1396,1.5889-.1396,.8696,0,1.4292,.1504,1.8491,.4902,.3496,.2598,.5596,.6592,.5596,1.1885,0,.6504-.4297,1.2197-1.1392,1.4795v.0205c.6396,.1592,1.3892,.6895,1.3892,1.6885,0,.5801-.23,1.0195-.5698,1.3496-.4697,.4297-1.2295,.6299-2.3286,.6299-.5996,0-1.0596-.04-1.3491-.0801v-6.627Zm.8691,2.7588h.7896c.9199,0,1.4595-.4795,1.4595-1.1289,0-.79-.5996-1.0996-1.4795-1.0996-.3994,0-.6294,.0303-.7695,.0596v2.1689Zm0,3.2285c.1699,.0293,.4199,.04,.73,.04,.8994,0,1.729-.3301,1.729-1.3096,0-.9199-.7896-1.2988-1.7393-1.2988h-.7197v2.5684Z" fill="#fff" />
                                                            <path d="M120.0171,747.3978h.8794v7.0957h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M125.0557,754.4935l-.0698-.6094h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M130.6451,754.3138c-.23,.1201-.7397,.2803-1.3892,.2803-1.4595,0-2.4087-.9902-2.4087-2.4688,0-1.4893,1.0195-2.5693,2.5986-2.5693,.5195,0,.9795,.1309,1.2192,.25l-.1997,.6797c-.21-.1201-.54-.2295-1.0195-.2295-1.1094,0-1.709,.8193-1.709,1.8291,0,1.1191,.7192,1.8086,1.6787,1.8086,.5,0,.8296-.1299,1.0796-.2393l.1499,.6592Z" fill="#fff" />
                                                            <path d="M132.5464,751.8753h.02c.1201-.1699,.29-.3799,.4297-.5498l1.4194-1.6689h1.0591l-1.8687,1.9883,2.1289,2.8486h-1.0698l-1.6689-2.3184-.4497,.5v1.8184h-.8696v-7.0957h.8696v4.4775Z" fill="#fff" />
                                                            <path d="M136.8467,749.6566l.6396,2.458c.1396,.54,.2695,1.04,.3599,1.54h.0298c.1099-.4902,.27-1.0098,.4297-1.5293l.7896-2.4688h.7397l.7495,2.4189c.1797,.5791,.3198,1.0889,.4297,1.5791h.0303c.0796-.4902,.2095-1,.3696-1.5693l.6895-2.4287h.8696l-1.5591,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3101-1.0195-.4302-1.5889h-.0195c-.1201,.5791-.27,1.0791-.4399,1.5986l-.7798,2.2988h-.7993l-1.4595-4.8369h.8999Z" fill="#fff" />
                                                            <path d="M146.4956,754.4935l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M149.6563,748.2669v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M152.3365,752.2347c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M156.8565,751.1653c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M162.1568,753.4339c.3896,.2402,.9595,.4404,1.5591,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3599-.9893-1.269-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1592,1.4893,.3291l-.2397,.71c-.2197-.1201-.6694-.3193-1.2793-.3193-.9194,0-1.269,.5488-1.269,1.0088,0,.6299,.4097,.9395,1.3389,1.2998,1.1396,.4395,1.7192,.9893,1.7192,1.9785,0,1.0391-.7695,1.9395-2.3589,1.9395-.6494,0-1.3589-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M168.2061,748.2669v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M173.1353,754.4935l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M176.2959,748.2669v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M179.5259,748.2972c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M185.2642,752.0355c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M186.376,750.9661c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-21" data-name="item-id-box">
                                                    <g id="id-box-26" data-name="id-box">
                                                        <rect x="43.367" y="742.1601" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-23" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,747.7884h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M71.9292,749.3782h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                            <path d="M77.479,749.3782h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-012-item-row">
                                                <g id="item-base-box-22" data-name="item-base-box">
                                                    <g id="base-box-25" data-name="base-box">
                                                        <rect x="217.637" y="760.2844" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-25" data-name="base-text">
                                                        <g>
                                                            <path d="M243.5537,769.18c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M248.7344,771.2982c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M251.2559,766.3909v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M253.3868,771.7181c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M258.5259,766.3909v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M263.4551,772.6175l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M265.5962,769.0902c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M275.3238,765.5218v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M277.7657,766.4212c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M279.126,769.0902c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M288.8438,767.7806c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-22" data-name="item-type-box">
                                                    <g id="type-box-28" data-name="type-box">
                                                        <rect x="101.7643" y="760.2844" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-25" data-name="type-text">
                                                        <g>
                                                            <path d="M114.5074,771.5579c.3896,.2402,.9595,.4404,1.5591,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3599-.9893-1.269-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1592,1.4893,.3291l-.2397,.71c-.2197-.1201-.6694-.3193-1.2793-.3193-.9194,0-1.269,.5488-1.269,1.0088,0,.6299,.4097,.9395,1.3389,1.2998,1.1396,.4395,1.7192,.9893,1.7192,1.9785,0,1.0391-.7695,1.9395-2.3589,1.9395-.6494,0-1.3589-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M119.7671,767.7806l1.0596,2.8584c.1099,.3193,.23,.6992,.3096,.9893h.02c.0903-.29,.1899-.6592,.3101-1.0098l.9595-2.8379h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5078-1.6592,3.0283-.4297,.3799-.8599,.5293-1.0796,.5693l-.2197-.7393c.2197-.0703,.5098-.21,.7695-.4297,.2397-.1904,.5396-.5303,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2305l-1.7891-4.457h.9595Z" fill="#fff" />
                                                            <path d="M124.1573,769.0902c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M130.1968,770.3587c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M134.7168,765.5218h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M144.315,771.2982c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M145.7769,769.2894c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0596,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M148.9273,771.7181c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M154.0664,766.3909v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M158.4668,765.8812h3.6279v.7295h-2.7588v2.2393h2.5488v.7197h-2.5488v3.0479h-.8691v-6.7363Z" fill="#fff" />
                                                            <path d="M167.3745,770.1595c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M172.8648,770.1595c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M175.0362,766.3909v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M177.2867,769.3597c0-.6201-.02-1.1191-.04-1.5791h.7896l.04,.8291h.02c.3599-.5898,.9297-.9395,1.7192-.9395,1.1694,0,2.0488,.9893,2.0488,2.459,0,1.7393-1.0596,2.5986-2.1987,2.5986-.6396,0-1.1992-.2803-1.4893-.7598h-.02v2.6289h-.8696v-5.2373Zm.8696,1.2891c0,.1299,.02,.25,.04,.3604,.1597,.6094,.6895,1.0293,1.3193,1.0293,.9292,0,1.4692-.7598,1.4692-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0601,.2402-.0601,.3604v.8291Z" fill="#fff" />
                                                            <path d="M185.6553,772.6175l-.0698-.6094h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M188.816,766.3909v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M191.0664,765.5218h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-22" data-name="item-id-box">
                                                    <g id="id-box-27" data-name="id-box">
                                                        <rect x="43.367" y="760.2844" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-24" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,765.9124h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M71.9292,767.5023h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                            <path d="M75.5401,772.6487v-.9189l.8394-.7598c1.4194-1.2695,2.1089-1.999,2.1289-2.7588,0-.5293-.3198-.9492-1.0693-.9492-.5601,0-1.0493,.2793-1.3892,.54l-.4302-1.0898c.4897-.3701,1.2495-.6699,2.1289-.6699,1.4692,0,2.2788,.8594,2.2788,2.0391,0,1.0898-.7896,1.959-1.729,2.7988l-.5996,.499v.0205h2.4487v1.249h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="title-row-4" data-name="title-row">
                                                <g id="foundation-box-2" data-name="foundation-box">
                                                    <g id="base-box-26" data-name="base-box">
                                                        <rect x="217.6508" y="542.8502" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.0447" />
                                                    </g>
                                                    <g id="base-text-26" data-name="base-text">
                                                        <g>
                                                            <path d="M233.7461,548.5374c.3999-.0801,1.2095-.1396,1.9688-.1396,.9297,0,1.4995,.0898,1.9893,.3799,.4697,.249,.8096,.709,.8096,1.3184,0,.6006-.3501,1.1602-1.1094,1.4395v.0205c.7695,.21,1.3389,.7891,1.3389,1.6592,0,.6094-.2798,1.0889-.6992,1.4287-.4897,.3896-1.3096,.6094-2.6489,.6094-.7495,0-1.3091-.0498-1.6489-.0996v-6.6162Zm1.5093,2.5781h.4995c.7998,0,1.2295-.3291,1.2295-.8291,0-.5098-.3896-.7793-1.0796-.7793-.3296,0-.5195,.0195-.6494,.04v1.5684Zm0,2.9883c.1499,.0205,.3296,.0205,.5894,.0205,.6899,0,1.2993-.2598,1.2993-.9697,0-.6797-.6094-.9492-1.3691-.9492h-.5195v1.8984Z" fill="#fff" />
                                                            <path d="M242.5445,555.1839l-.0898-.4902h-.0303c-.3198,.3896-.8193,.5996-1.3989,.5996-.9897,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1392-1.8789,2.8682-1.8691v-.0693c0-.2598-.1396-.6299-.8896-.6299-.4995,0-1.0293,.1699-1.3491,.3701l-.2798-.9795c.3398-.1904,1.0093-.4297,1.8989-.4297,1.6289,0,2.1489,.959,2.1489,2.1084v1.6992c0,.4697,.02,.9189,.0698,1.1895h-1.3691Zm-.1802-2.3086c-.7993-.0107-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5791,.5996,.5791,.3799,0,.6895-.25,.7896-.5596,.02-.0801,.0298-.1699,.0298-.2598v-.5293Z" fill="#fff" />
                                                            <path d="M245.0357,553.8548c.2798,.1699,.8594,.3691,1.3096,.3691,.4595,0,.6494-.1592,.6494-.4092s-.1499-.3701-.7197-.5596c-1.0093-.3398-1.3989-.8896-1.3892-1.4697,0-.9092,.7798-1.5986,1.9888-1.5986,.5698,0,1.0796,.1299,1.3794,.2793l-.27,1.0498c-.2197-.1201-.6396-.2803-1.0591-.2803-.3701,0-.5796,.1504-.5796,.4004,0,.2295,.1895,.3496,.7896,.5596,.9292,.3193,1.3188,.7891,1.3291,1.5088,0,.9092-.7197,1.5791-2.1187,1.5791-.6396,0-1.2095-.1396-1.5796-.3398l.27-1.0889Z" fill="#fff" />
                                                            <path d="M250.5552,553.2249c.0503,.6299,.6699,.9297,1.3794,.9297,.5195,0,.9395-.0703,1.3491-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.769,.2998-1.6592,0-2.6089-.959-2.6089-2.4883,0-1.2393,.77-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0298,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1597-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M258.2652,548.4476v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M259.566,551.8558c0-.6104-.02-1.1299-.04-1.5596h1.3193l.0698,.6699h.0298c.2002-.3096,.6997-.7793,1.5093-.7793,.9995,0,1.749,.6592,1.749,2.0986v2.8984h-1.519v-2.709c0-.6299-.2197-1.0596-.7695-1.0596-.4199,0-.6699,.29-.7695,.5703-.04,.0898-.0601,.2393-.0601,.3799v2.8184h-1.519v-3.3281Z" fill="#fff" />
                                                            <path d="M265.4156,553.8548c.2798,.1699,.8594,.3691,1.3096,.3691,.4595,0,.6494-.1592,.6494-.4092s-.1499-.3701-.7197-.5596c-1.0093-.3398-1.3989-.8896-1.3892-1.4697,0-.9092,.7798-1.5986,1.9888-1.5986,.5698,0,1.0796,.1299,1.3794,.2793l-.27,1.0498c-.2197-.1201-.6396-.2803-1.0591-.2803-.3701,0-.5796,.1504-.5796,.4004,0,.2295,.1895,.3496,.7896,.5596,.9292,.3193,1.3188,.7891,1.3291,1.5088,0,.9092-.7197,1.5791-2.1187,1.5791-.6396,0-1.2095-.1396-1.5796-.3398l.27-1.0889Z" fill="#fff" />
                                                            <path d="M271.4644,548.8968v1.3994h1.0894v1.1191h-1.0894v1.7695c0,.5898,.1401,.8594,.5996,.8594,.1899,0,.3398-.0195,.4497-.04l.0103,1.1494c-.1997,.0801-.5596,.1299-.9893,.1299-.4897,0-.8999-.1699-1.1396-.4199-.2798-.29-.4199-.7598-.4199-1.4492v-1.999h-.6494v-1.1191h.6494v-1.0596l1.4893-.3398Z" fill="#fff" />
                                                            <path d="M276.2139,555.1839l-.0898-.4902h-.0303c-.3198,.3896-.8193,.5996-1.3989,.5996-.9897,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1392-1.8789,2.8682-1.8691v-.0693c0-.2598-.1396-.6299-.8896-.6299-.4995,0-1.0293,.1699-1.3491,.3701l-.2798-.9795c.3398-.1904,1.0093-.4297,1.8989-.4297,1.6289,0,2.1489,.959,2.1489,2.1084v1.6992c0,.4697,.02,.9189,.0698,1.1895h-1.3691Zm-.1802-2.3086c-.7993-.0107-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5791,.5996,.5791,.3799,0,.6895-.25,.7896-.5596,.02-.0801,.0298-.1699,.0298-.2598v-.5293Z" fill="#fff" />
                                                            <path d="M278.7154,548.0872h1.519v7.0967h-1.519v-7.0967Z" fill="#fff" />
                                                            <path d="M281.4654,548.0872h1.519v7.0967h-1.519v-7.0967Z" fill="#fff" />
                                                            <path d="M285.3843,553.2249c.0503,.6299,.6699,.9297,1.3794,.9297,.5195,0,.9395-.0703,1.3491-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.769,.2998-1.6592,0-2.6089-.959-2.6089-2.4883,0-1.2393,.77-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0298,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1597-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M294.2329,548.0872v5.6377c0,.5488,.02,1.1289,.04,1.459h-1.3496l-.0698-.7197h-.02c-.3096,.5498-.8994,.8291-1.5293,.8291-1.1592,0-2.0889-.9893-2.0889-2.5078-.0098-1.6494,1.0195-2.5986,2.189-2.5986,.5996,0,1.0693,.209,1.2896,.5488h.02v-2.6484h1.519Zm-1.519,4.2783c0-.0801-.0103-.1904-.02-.2705-.0903-.4092-.4199-.749-.8896-.749-.6895,0-1.0493,.6191-1.0493,1.3887,0,.8301,.4097,1.3496,1.0391,1.3496,.4399,0,.7896-.2998,.8799-.7295,.0298-.1104,.04-.2197,.04-.3496v-.6396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="type-box-29" data-name="type-box">
                                                    <g id="type-box-30" data-name="type-box">
                                                        <rect x="101.7781" y="542.8502" width="115.8727" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-26" data-name="type-text">
                                                        <g>
                                                            <path d="M121.4839,555.1839v-6.7363h1.7788l1.3994,2.4688c.3999,.709,.7993,1.5488,1.0996,2.3086h.0298c-.1001-.8896-.1299-1.7988-.1299-2.8086v-1.9688h1.3994v6.7363h-1.5996l-1.439-2.5986c-.3999-.7197-.8394-1.5889-1.1694-2.3789l-.0298,.0098c.04,.8896,.0601,1.8389,.0601,2.9385v2.0293h-1.3994Z" fill="#fff" />
                                                            <path d="M131.1123,555.1839l-.0898-.4902h-.0303c-.3198,.3896-.8193,.5996-1.3989,.5996-.9897,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1392-1.8789,2.8682-1.8691v-.0693c0-.2598-.1396-.6299-.8896-.6299-.4995,0-1.0293,.1699-1.3491,.3701l-.2798-.9795c.3398-.1904,1.0093-.4297,1.8989-.4297,1.6289,0,2.1489,.959,2.1489,2.1084v1.6992c0,.4697,.02,.9189,.0698,1.1895h-1.3691Zm-.1802-2.3086c-.7993-.0107-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5791,.5996,.5791,.3799,0,.6895-.25,.7896-.5596,.02-.0801,.0298-.1699,.0298-.2598v-.5293Z" fill="#fff" />
                                                            <path d="M133.6138,551.8558c0-.6104-.02-1.1299-.04-1.5596h1.2793l.0698,.6602h.0298c.21-.3105,.6396-.7695,1.4795-.7695,.6294,0,1.1294,.3193,1.3394,.8291h.02c.1797-.25,.3994-.4502,.6294-.5898,.27-.1602,.5698-.2393,.9297-.2393,.9395,0,1.6489,.6592,1.6489,2.1182v2.8789h-1.479v-2.6592c0-.709-.23-1.1191-.7197-1.1191-.3501,0-.5996,.2402-.6997,.5303-.04,.1094-.0601,.2695-.0601,.3896v2.8584h-1.479v-2.7383c0-.6201-.2197-1.04-.6997-1.04-.3896,0-.6196,.2998-.7095,.5498-.0503,.1201-.0601,.2598-.0601,.3799v2.8486h-1.479v-3.3281Z" fill="#fff" />
                                                            <path d="M143.3828,553.2249c.0503,.6299,.6699,.9297,1.3794,.9297,.5195,0,.9395-.0703,1.3491-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.769,.2998-1.6592,0-2.6089-.959-2.6089-2.4883,0-1.2393,.77-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0298,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1597-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="id-box-28" data-name="id-box">
                                                    <g id="id-box-29" data-name="id-box">
                                                        <rect x="43.3808" y="542.8502" width="58.3973" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-25" data-name="id-text">
                                                        <g>
                                                            <path d="M69.8633,548.4788v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M71.1841,548.5687c.5596-.0898,1.2891-.1396,2.0591-.1396,1.2793,0,2.1084,.2295,2.7583,.7188,.6997,.5205,1.1392,1.3496,1.1392,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7285-.7095,.5898-1.7891,.8691-3.1084,.8691-.7896,0-1.3491-.0498-1.729-.0996v-6.6162Zm1.5293,5.4971c.1299,.0303,.3398,.0303,.5298,.0303,1.3789,.0098,2.2783-.75,2.2783-2.3594,.0103-1.3994-.8096-2.1387-2.1187-2.1387-.3398,0-.5596,.0303-.6895,.0596v4.4082Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="main-title-4" data-name="main-title">
                                                <g>
                                                    <path d="M45.2676,529.4642h4.9414v1.499h-3.1064v1.8467h2.9023v1.4873h-2.9023v3.25h-1.835v-8.083Z" fill="#e9427d" />
                                                    <path d="M52.6231,535.1966c.0605,.7559,.8037,1.1152,1.6553,1.1152,.624,0,1.1279-.084,1.6191-.2393l.2402,1.2354c-.5996,.2393-1.332,.3594-2.123,.3594-1.9912,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9229-3.1299,2.9619-3.1299,1.8945,0,2.6143,1.4746,2.6143,2.9258,0,.3125-.0361,.5879-.0596,.7197h-3.7783Zm2.1475-1.2471c0-.4443-.1924-1.1875-1.0322-1.1875-.7676,0-1.0791,.6953-1.127,1.1875h2.1592Z" fill="#e9427d" />
                                                    <path d="M57.5557,529.0325h1.8232v8.5146h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M62.8946,530.0032v1.6797h1.3076v1.3428h-1.3076v2.123c0,.708,.168,1.0312,.7197,1.0312,.2275,0,.4082-.0234,.54-.0479l.0117,1.3789c-.2402,.0967-.6719,.1562-1.1875,.1562-.5879,0-1.0791-.2041-1.3672-.5039-.3359-.3477-.5039-.9111-.5039-1.7393v-2.3984h-.7793v-1.3428h.7793v-1.2715l1.7871-.4082Z" fill="#e9427d" />
                                                    <path d="M65.2598,529.0325h1.8232v3.3457h.0234c.1924-.252,.4316-.4434,.7197-.5996,.2637-.1436,.6113-.2275,.9473-.2275,1.1641,0,2.0508,.8027,2.0508,2.5664v3.4297h-1.8223v-3.2256c0-.7676-.2646-1.2959-.9482-1.2959-.4795,0-.7793,.3125-.9111,.6357-.0479,.1201-.0596,.2764-.0596,.4082v3.4775h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M75.626,537.5472l-.1074-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6797,.7197-1.1875,0-1.8945-.8633-1.8945-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2422v-.084c0-.3125-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4434l-.3359-1.1748c.4072-.2285,1.2109-.5156,2.2783-.5156,1.9551,0,2.5791,1.1514,2.5791,2.5303v2.0391c0,.5635,.0234,1.1035,.084,1.4268h-1.6436Zm-.2158-2.7705c-.959-.0117-1.7031,.2158-1.7031,.9238,0,.4678,.3115,.6953,.7197,.6953,.4561,0,.8271-.2998,.9473-.6709,.0244-.0967,.0361-.2041,.0361-.3125v-.6357Z" fill="#e9427d" />
                                                    <path d="M78.628,533.554c0-.7324-.0244-1.3555-.0479-1.8711h1.5352l.084,.791h.0361c.251-.3711,.7676-.9229,1.7744-.9229,.7559,0,1.3555,.3838,1.6074,.9951h.0234c.2168-.2998,.4805-.54,.7559-.708,.3242-.1914,.6836-.2871,1.1152-.2871,1.1279,0,1.9795,.791,1.9795,2.542v3.4541h-1.7754v-3.1904c0-.8516-.2754-1.3428-.8633-1.3428-.4199,0-.7197,.2881-.8398,.6357-.0479,.1318-.0723,.3232-.0723,.4678v3.4297h-1.7744v-3.2861c0-.7432-.2637-1.2471-.8398-1.2471-.4678,0-.7432,.3594-.8516,.6592-.0596,.1445-.0713,.3125-.0713,.4561v3.418h-1.7754v-3.9932Z" fill="#e9427d" />
                                                    <path d="M91.4317,529.5716c.5635-.0957,1.3555-.168,2.4707-.168,1.127,0,1.9307,.2158,2.4707,.6475,.5156,.4082,.8633,1.0801,.8633,1.8711,0,.792-.2637,1.4639-.7441,1.9189-.623,.5879-1.5469,.8516-2.626,.8516-.2402,0-.4561-.0117-.624-.0361v2.8906h-1.8105v-7.9756Zm1.8105,3.6699c.1562,.0361,.3477,.0479,.6123,.0479,.9707,0,1.5703-.4912,1.5703-1.3193,0-.7432-.5156-1.1865-1.4268-1.1865-.3721,0-.624,.0352-.7559,.0713v2.3867Z" fill="#e9427d" />
                                                    <path d="M98.3438,529.0325h1.8232v3.3457h.0234c.1924-.252,.4316-.4434,.7197-.5996,.2637-.1436,.6113-.2275,.9473-.2275,1.1641,0,2.0508,.8027,2.0508,2.5664v3.4297h-1.8223v-3.2256c0-.7676-.2646-1.2959-.9482-1.2959-.4795,0-.7793,.3125-.9111,.6357-.0479,.1201-.0596,.2764-.0596,.4082v3.4775h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M108.71,537.5472l-.1084-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6787,.7197-1.1875,0-1.8955-.8633-1.8955-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2422v-.084c0-.3125-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4434l-.3359-1.1748c.4082-.2285,1.2119-.5156,2.2793-.5156,1.9551,0,2.5781,1.1514,2.5781,2.5303v2.0391c0,.5635,.0244,1.1035,.084,1.4268h-1.6426Zm-.2158-2.7705c-.96-.0117-1.7031,.2158-1.7031,.9238,0,.4678,.3115,.6953,.7188,.6953,.4561,0,.8281-.2998,.9482-.6709,.0234-.0967,.0361-.2041,.0361-.3125v-.6357Z" fill="#e9427d" />
                                                    <path d="M111.6993,535.9525c.3359,.2041,1.0312,.4434,1.5713,.4434,.5518,0,.7793-.1914,.7793-.4912s-.1797-.4443-.8633-.6719c-1.2109-.4082-1.6787-1.0674-1.667-1.7627,0-1.0918,.9355-1.9189,2.3867-1.9189,.6836,0,1.2949,.1553,1.6553,.3359l-.3242,1.2588c-.2637-.1436-.7676-.3359-1.2715-.3359-.4434,0-.6953,.1797-.6953,.4795,0,.2764,.2275,.4199,.9473,.6719,1.1152,.3838,1.583,.9473,1.5957,1.8115,0,1.0908-.8643,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4072l.3232-1.3076Z" fill="#e9427d" />
                                                    <path d="M118.3223,535.1966c.0605,.7559,.8037,1.1152,1.6553,1.1152,.624,0,1.1279-.084,1.6191-.2393l.2402,1.2354c-.5996,.2393-1.332,.3594-2.123,.3594-1.9912,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9229-3.1299,2.9619-3.1299,1.8945,0,2.6143,1.4746,2.6143,2.9258,0,.3125-.0361,.5879-.0596,.7197h-3.7783Zm2.1475-1.2471c0-.4443-.1924-1.1875-1.0322-1.1875-.7676,0-1.0791,.6953-1.127,1.1875h2.1592Z" fill="#e9427d" />
                                                    <path d="M130.6563,531.2513h-3.0225l-.168,1.1992c.168-.0244,.3125-.0244,.5039-.0244,.7441,0,1.499,.168,2.0508,.5635,.5879,.3965,.9473,1.0439,.9473,1.9551,0,1.4512-1.2471,2.7344-3.3457,2.7344-.9473,0-1.7393-.2158-2.1709-.4434l.3242-1.3672c.3477,.168,1.0547,.3838,1.7627,.3838,.7559,0,1.5596-.3604,1.5596-1.1875,0-.8037-.6357-1.2949-2.1953-1.2949-.4316,0-.7314,.0234-1.0557,.0713l.5156-4.0898h4.2939v1.5Z" fill="#e9427d" />
                                                    <path d="M137.5332,533.7933v1.2119h-3.1416v-1.2119h3.1416Z" fill="#e9427d" />
                                                    <path d="M141.1114,529.4642h1.835v6.5479h3.2139v1.5352h-5.0488v-8.083Z" fill="#e9427d" />
                                                    <path d="M154.1866,533.4222c0,2.6504-1.6064,4.2568-3.9697,4.2568-2.3984,0-3.8018-1.8105-3.8018-4.1133,0-2.4229,1.5469-4.2334,3.9336-4.2334,2.4834,0,3.8379,1.8584,3.8379,4.0898Zm-5.8408,.1074c0,1.583,.7441,2.6982,1.9668,2.6982,1.2354,0,1.9434-1.1748,1.9434-2.7461,0-1.4512-.6953-2.6982-1.9551-2.6982-1.2354,0-1.9551,1.1748-1.9551,2.7461Z" fill="#e9427d" />
                                                    <path d="M161.3282,537.3196c-.3359,.168-1.0918,.3477-2.0752,.3477-2.7949,0-4.2334-1.7393-4.2334-4.042,0-2.7578,1.9668-4.293,4.4131-4.293,.9473,0,1.667,.1914,1.9912,.3594l-.3721,1.4512c-.3721-.1562-.8877-.2998-1.5352-.2998-1.4512,0-2.5781,.876-2.5781,2.6748,0,1.6191,.959,2.6387,2.5898,2.6387,.5518,0,1.1641-.1201,1.5234-.2646l.2764,1.4277Z" fill="#e9427d" />
                                                    <path d="M164.9541,529.5716c.4805-.0957,1.4512-.168,2.3633-.168,1.1152,0,1.7988,.1084,2.3867,.4561,.5635,.2998,.9707,.8516,.9707,1.583,0,.7197-.4189,1.3916-1.3311,1.7275v.0234c.9238,.252,1.6074,.9473,1.6074,1.9912,0,.7314-.3359,1.3076-.8398,1.7148-.5879,.4678-1.5713,.7314-3.1777,.7314-.8994,0-1.5713-.0596-1.9795-.1201v-7.9395Zm1.8115,3.0947h.5996c.959,0,1.4746-.3955,1.4746-.9961,0-.6113-.4678-.9346-1.2949-.9346-.3955,0-.624,.0234-.7793,.0479v1.8828Zm0,3.5859c.1797,.0234,.3955,.0234,.707,.0234,.8281,0,1.5596-.3115,1.5596-1.1631,0-.8154-.7314-1.1396-1.6436-1.1396h-.623v2.2793Z" fill="#e9427d" />
                                                    <path d="M175.5127,537.5472l-.1084-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6787,.7197-1.1875,0-1.8955-.8633-1.8955-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2422v-.084c0-.3125-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4434l-.3359-1.1748c.4082-.2285,1.2119-.5156,2.2793-.5156,1.9551,0,2.5781,1.1514,2.5781,2.5303v2.0391c0,.5635,.0244,1.1035,.084,1.4268h-1.6426Zm-.2158-2.7705c-.96-.0117-1.7031,.2158-1.7031,.9238,0,.4678,.3115,.6953,.7188,.6953,.4561,0,.8281-.2998,.9482-.6709,.0234-.0967,.0361-.2041,.0361-.3125v-.6357Z" fill="#e9427d" />
                                                    <path d="M178.503,535.9525c.335,.2041,1.0312,.4434,1.5703,.4434,.5518,0,.7803-.1914,.7803-.4912s-.1797-.4443-.8643-.6719c-1.2109-.4082-1.6787-1.0674-1.667-1.7627,0-1.0918,.9355-1.9189,2.3867-1.9189,.6836,0,1.2959,.1553,1.6553,.3359l-.3232,1.2588c-.2646-.1436-.7676-.3359-1.2715-.3359-.4443,0-.6963,.1797-.6963,.4795,0,.2764,.2285,.4199,.9482,.6719,1.1152,.3838,1.583,.9473,1.5947,1.8115,0,1.0908-.8633,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4072l.3242-1.3076Z" fill="#e9427d" />
                                                    <path d="M185.125,535.1966c.0605,.7559,.8037,1.1152,1.6553,1.1152,.624,0,1.1279-.084,1.6191-.2393l.2402,1.2354c-.5996,.2393-1.332,.3594-2.123,.3594-1.9912,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9229-3.1299,2.9619-3.1299,1.8945,0,2.6143,1.4746,2.6143,2.9258,0,.3125-.0361,.5879-.0596,.7197h-3.7783Zm2.1475-1.2471c0-.4443-.1924-1.1875-1.0322-1.1875-.7676,0-1.0791,.6953-1.127,1.1875h2.1592Z" fill="#e9427d" />
                                                    <path d="M190.0459,535.9525c.3359,.2041,1.0312,.4434,1.5713,.4434,.5518,0,.7793-.1914,.7793-.4912s-.1797-.4443-.8633-.6719c-1.2109-.4082-1.6787-1.0674-1.667-1.7627,0-1.0918,.9355-1.9189,2.3867-1.9189,.6836,0,1.2949,.1553,1.6553,.3359l-.3242,1.2588c-.2637-.1436-.7676-.3359-1.2715-.3359-.4434,0-.6953,.1797-.6953,.4795,0,.2764,.2275,.4199,.9473,.6719,1.1152,.3838,1.583,.9473,1.5957,1.8115,0,1.0908-.8643,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4072l.3232-1.3076Z" fill="#e9427d" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="feltham-signal-civils-group" className={props.signalCivilsDisplayToggle}>
                                <g id="feltham-signals-group">
                                    <g id="signal-BEF2259BR">
                                        <g id="line">
                                            <line x1="1004.611" y1="888.9581" x2="988.6188" y2="854.359" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point">
                                            <circle cx="988.6188" cy="853.1357" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-16" data-name="base">
                                            <rect x="984.9552" y="910.9583" width="53.8767" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-16" data-name="name">
                                            <g id="BEF2259BR">
                                                <path d="M987.169,914.2874c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M996.9707,918.1273h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M998.543,914.1888h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M1003.5586,921.6038v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M1009.2022,921.6038v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M1019.1133,915.2669h-2.7285l-.2754,1.8369c.165-.0215,.3193-.0439,.584-.0439,.5498,0,1.0996,.1211,1.54,.3857,.5605,.3184,1.0225,.9346,1.0225,1.8369,0,1.3975-1.1104,2.4424-2.6621,2.4424-.7812,0-1.4414-.2197-1.7822-.4404l.2422-.7363c.2969,.1758,.8799,.3955,1.5293,.3955,.9131,0,1.6943-.5938,1.6943-1.5508-.0107-.9248-.627-1.585-2.0576-1.585-.4072,0-.7256,.0439-.9902,.0771l.4619-3.4326h3.4219v.8145Z" fill="#fff" />
                                                <path d="M1021.0489,920.9222c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0557,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M1026.4707,914.2874c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M1032.4326,914.2874c.4844-.0986,1.1777-.1533,1.8379-.1533,1.0225,0,1.6826,.1865,2.1445,.6045,.374,.3301,.583,.8359,.583,1.4082,0,.9795-.6152,1.6289-1.3965,1.8926v.0332c.5723,.1982,.9131,.7256,1.0889,1.4961,.2422,1.0342,.418,1.749,.5723,2.0352h-.9902c-.1211-.209-.2861-.8467-.4951-1.7715-.2197-1.0225-.6162-1.4082-1.4854-1.4414h-.9023v3.2129h-.957v-7.3164Zm.957,3.3779h.9795c1.0234,0,1.6719-.5615,1.6719-1.4082,0-.957-.6924-1.375-1.7051-1.3867-.4619,0-.792,.0449-.9463,.0889v2.7061Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure">
                                            <rect x="1010.4843" y="907.1138" width="3.8446" height="3.8446" transform="translate(2024.8132 1818.0721) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head">
                                            <path d="M1003.1546,888.6216v18.5159h18.504v-18.5159h-18.504Zm9.252,2.5799c3.3429,0,6.104,2.4589,6.5932,5.6651h-13.1864c.4892-3.2062,3.2503-5.6651,6.5932-5.6651Zm0,13.3562c-3.0193,0-5.568-2.0049-6.394-4.7552h12.7881c-.826,2.7502-3.3747,4.7552-6.394,4.7552Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2281BR">
                                        <g id="line-2" data-name="line">
                                            <line x1="257.988" y1="319.9711" x2="283.4989" y2="283.1585" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-2" data-name="point">
                                            <circle cx="283.4989" cy="283.1585" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-17" data-name="base">
                                            <rect x="222.5286" y="341.5166" width="53.8767" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-17" data-name="name">
                                            <g id="BEF2281BR">
                                                <path d="M224.7427,344.845c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M234.5445,348.6849h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M236.1167,344.7464h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M241.1324,352.1614v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M246.7759,352.1614v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M252.3306,350.346c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5723-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M260.1626,345.9124h-.0225l-1.2432,.6709-.1865-.7363,1.5615-.8369h.8252v7.1514h-.9346v-6.249Z" fill="#fff" />
                                                <path d="M264.0445,344.845c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M270.0064,344.845c.4844-.0986,1.1777-.1533,1.8379-.1533,1.0225,0,1.6826,.1865,2.1445,.6045,.374,.3301,.583,.8359,.583,1.4082,0,.9795-.6152,1.6289-1.3965,1.8926v.0332c.5723,.1982,.9131,.7256,1.0889,1.4961,.2422,1.0342,.418,1.749,.5723,2.0352h-.9902c-.1211-.209-.2861-.8467-.4951-1.7715-.2197-1.0225-.6162-1.4082-1.4854-1.4414h-.9023v3.2129h-.957v-7.3164Zm.957,3.3779h.9795c1.0234,0,1.6719-.5615,1.6719-1.4082,0-.957-.6924-1.375-1.7051-1.3867-.4619,0-.792,.0449-.9463,.0889v2.7061Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-2" data-name="structure">
                                            <rect x="248.0577" y="337.6721" width="3.8446" height="3.8446" transform="translate(499.9599 679.1887) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-2" data-name="head">
                                            <path d="M240.7279,319.1799v18.5159h18.504v-18.5159h-18.504Zm9.252,2.5799c3.3429,0,6.104,2.4589,6.5932,5.6651h-13.1864c.4892-3.2062,3.2503-5.6651,6.5932-5.6651Zm0,13.3562c-3.0193,0-5.568-2.0049-6.394-4.7552h12.7881c-.826,2.7502-3.3747,4.7552-6.394,4.7552Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2283BR">
                                        <g id="line-3" data-name="line">
                                            <line x1="177.8692" y1="231.9642" x2="206.273" y2="193.8053" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-3" data-name="point">
                                            <circle cx="206.273" cy="193.8053" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-18" data-name="base">
                                            <rect x="124.7739" y="230.2914" width="53.8767" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-18" data-name="name">
                                            <g id="BEF2281BR-2" data-name="BEF2281BR">
                                                <path d="M126.9878,233.6204c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M136.7896,237.4603h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M138.3618,233.5218h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M143.3775,240.9368v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M149.021,240.9368v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M154.5757,239.1214c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5723-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M160.5376,239.8255c.2744,.1758,.9131,.4512,1.584,.4512,1.2432,0,1.6279-.792,1.6172-1.3857-.0107-1.002-.9131-1.4307-1.8486-1.4307h-.5391v-.7256h.5391c.7041,0,1.5957-.3633,1.5957-1.2109,0-.5723-.3633-1.0781-1.2549-1.0781-.5713,0-1.1221,.2529-1.4297,.4736l-.2529-.7041c.374-.2754,1.0996-.5508,1.8701-.5508,1.4082,0,2.0459,.8369,2.0459,1.7061,0,.7363-.4395,1.3643-1.3203,1.6826v.0225c.8809,.1758,1.5957,.8359,1.5957,1.8369,0,1.1445-.8916,2.1455-2.6074,2.1455-.8027,0-1.5068-.2529-1.8594-.4844l.2646-.748Z" fill="#fff" />
                                                <path d="M166.2896,233.6204c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M172.2515,233.6204c.4844-.0986,1.1777-.1533,1.8379-.1533,1.0225,0,1.6826,.1865,2.1445,.6045,.374,.3301,.583,.8359,.583,1.4082,0,.9795-.6152,1.6289-1.3965,1.8926v.0332c.5723,.1982,.9131,.7256,1.0889,1.4961,.2422,1.0342,.418,1.749,.5723,2.0352h-.9902c-.1211-.209-.2861-.8467-.4951-1.7715-.2197-1.0225-.6162-1.4082-1.4854-1.4414h-.9023v3.2129h-.957v-7.3164Zm.957,3.3779h.9795c1.0234,0,1.6719-.5615,1.6719-1.4082,0-.957-.6924-1.375-1.7051-1.3867-.4619,0-.792,.0449-.9463,.0889v2.7061Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-3" data-name="structure">
                                            <rect x="150.303" y="226.4468" width="3.8446" height="3.8446" transform="translate(304.4505 456.7382) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-3" data-name="head">
                                            <path d="M142.9733,207.9389v18.5159h18.504v-18.5159h-18.504Zm9.252,2.58c1.392,0,2.688,.42,3.756,1.152l-9.288,9.288c-.72-1.068-1.152-2.364-1.152-3.756,0-3.6959,3-6.6839,6.684-6.6839Zm0,13.356c-1.236,0-2.388-.336-3.372-.924l9.132-9.132c.5881,.9959,.924,2.1479,.924,3.3839,0,3.6841-3,6.6721-6.684,6.6721Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2262BR">
                                        <g id="line-4" data-name="line">
                                            <line x1="814.6511" y1="812.3317" x2="838.3075" y2="776.2329" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-4" data-name="point">
                                            <circle cx="814.6511" cy="812.3317" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-19" data-name="base">
                                            <rect x="823.2307" y="762.5743" width="53.8767" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-19" data-name="name">
                                            <g id="BEF2262BR">
                                                <path d="M825.4444,765.9036c.418-.0879,1.0781-.1543,1.749-.1543,.958,0,1.5732,.166,2.0361,.5391,.3848,.2871,.6152,.7266,.6152,1.3086,0,.7168-.4727,1.3438-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7598,1.5293,1.8594,0,.6387-.2529,1.123-.627,1.4863-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0449-1.4854-.0898v-7.293Zm.957,3.0371h.8691c1.0127,0,1.6064-.5293,1.6064-1.2441,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3887Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0137-.8691-1.4316-1.915-1.4316h-.792v2.8281Z" fill="#fff" />
                                                <path d="M835.2461,769.7435h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.416h4.0049v.8027h-3.0479v2.3438h2.8828v.793Z" fill="#fff" />
                                                <path d="M836.8184,765.804h3.9941v.8027h-3.0371v2.4648h2.8057v.793h-2.8057v3.3555h-.957v-7.416Z" fill="#fff" />
                                                <path d="M841.834,773.22v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M847.4776,773.22v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M857.2012,766.7513c-.1973-.0117-.4502,0-.7256,.043-1.5186,.2539-2.3213,1.3652-2.4863,2.541h.0332c.3408-.4512,.9346-.8242,1.7266-.8242,1.2656,0,2.1572,.9121,2.1572,2.3105,0,1.3086-.8916,2.5195-2.377,2.5195-1.5293,0-2.5303-1.1895-2.5303-3.0488,0-1.4082,.5059-2.5176,1.21-3.2227,.5947-.584,1.3867-.9453,2.2891-1.0566,.2852-.043,.5273-.0547,.7031-.0547v.793Zm-.2744,4.125c0-1.0234-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8789-.0664,.1113-.1104,.2539-.1104,.4297,.0225,1.1777,.5615,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M858.7627,773.22v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M864.7461,765.9036c.418-.0879,1.0781-.1543,1.749-.1543,.958,0,1.5732,.166,2.0361,.5391,.3848,.2871,.6152,.7266,.6152,1.3086,0,.7168-.4727,1.3438-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7598,1.5293,1.8594,0,.6387-.2529,1.123-.627,1.4863-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0449-1.4854-.0898v-7.293Zm.957,3.0371h.8691c1.0127,0,1.6064-.5293,1.6064-1.2441,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3887Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0137-.8691-1.4316-1.915-1.4316h-.792v2.8281Z" fill="#fff" />
                                                <path d="M870.708,765.9036c.4844-.0996,1.1777-.1543,1.8379-.1543,1.0225,0,1.6826,.1875,2.1445,.6055,.374,.3301,.583,.8359,.583,1.4082,0,.9785-.6152,1.6289-1.3965,1.8926v.0332c.5723,.1973,.9131,.7246,1.0889,1.4961,.2422,1.0332,.418,1.748,.5723,2.0352h-.9902c-.1211-.209-.2861-.8477-.4951-1.7715-.2197-1.0234-.6162-1.4082-1.4854-1.4414h-.9023v3.2129h-.957v-7.3164Zm.957,3.377h.9795c1.0234,0,1.6719-.5605,1.6719-1.4082,0-.957-.6924-1.375-1.7051-1.3867-.4619,0-.792,.0449-.9463,.0898v2.7051Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-4" data-name="structure">
                                            <rect x="848.7598" y="758.7298" width="3.8446" height="3.8446" transform="translate(1701.3641 1521.3041) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-4" data-name="head">
                                            <path d="M841.4301,740.2376v18.5159h18.504v-18.5159h-18.504Zm9.252,2.5799c3.3429,0,6.104,2.4589,6.5932,5.6651h-13.1864c.4892-3.2062,3.2503-5.6651,6.5932-5.6651Zm0,13.3562c-3.0193,0-5.568-2.0049-6.394-4.7552h12.7881c-.826,2.7502-3.3747,4.7552-6.394,4.7552Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2259">
                                        <g id="line-5" data-name="line">
                                            <line x1="960.2903" y1="853.1357" x2="953.9372" y2="878.9845" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-5" data-name="point">
                                            <circle cx="960.2903" cy="853.1357" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-20" data-name="base">
                                            <rect x="929.778" y="911.1252" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-20" data-name="name">
                                            <g>
                                                <path d="M933.2325,914.4534c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M943.0342,918.2933h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M944.6065,914.3548h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M949.6221,921.7698v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M955.2657,921.7698v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M965.1768,915.4329h-2.7285l-.2754,1.8369c.165-.0215,.3193-.0439,.584-.0439,.5498,0,1.0996,.1211,1.54,.3857,.5605,.3184,1.0225,.9346,1.0225,1.8369,0,1.3975-1.1104,2.4424-2.6621,2.4424-.7812,0-1.4414-.2197-1.7822-.4404l.2422-.7373c.2969,.1768,.8799,.3965,1.5293,.3965,.9131,0,1.6943-.5938,1.6943-1.5508-.0107-.9248-.627-1.585-2.0576-1.585-.4072,0-.7256,.0439-.9902,.0771l.4619-3.4326h3.4219v.8145Z" fill="#fff" />
                                                <path d="M967.1123,921.0882c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-5" data-name="structure">
                                            <rect x="950.6087" y="907.2807" width="3.8446" height="3.8446" transform="translate(1905.062 1818.4059) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-5" data-name="head">
                                            <path d="M955.8817,878.5453h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2258">
                                        <g id="line-6" data-name="line">
                                            <line x1="964.8729" y1="780.6148" x2="940.4621" y2="814.288" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-6" data-name="point">
                                            <circle cx="941.7025" cy="812.0917" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-21" data-name="base">
                                            <rect x="942.6041" y="766.9563" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-21" data-name="name">
                                            <g id="BEF2261">
                                                <path d="M946.0586,770.2845c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M955.8604,774.1243h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M957.4326,770.1859h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M962.4483,777.6009v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M968.0918,777.6009v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M978.003,771.264h-2.7285l-.2754,1.8369c.165-.0215,.3193-.0439,.584-.0439,.5498,0,1.0996,.1211,1.54,.3857,.5605,.3184,1.0225,.9346,1.0225,1.8369,0,1.3975-1.1104,2.4424-2.6621,2.4424-.7812,0-1.4414-.2197-1.7822-.4404l.2422-.7373c.2969,.1768,.8799,.3965,1.5293,.3965,.9131,0,1.6943-.5938,1.6943-1.5508-.0107-.9248-.627-1.585-2.0576-1.585-.4072,0-.7256,.0439-.9902,.0771l.4619-3.4326h3.4219v.8145Z" fill="#fff" />
                                                <path d="M979.2891,775.7855c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-6" data-name="structure">
                                            <rect x="963.4349" y="763.1117" width="3.8446" height="3.8446" transform="translate(1930.7143 1530.068) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-6" data-name="head">
                                            <path d="M968.7079,734.3764h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2262">
                                        <g id="line-7" data-name="line">
                                            <line x1="877.9211" y1="812.8077" x2="900.4926" y2="779.5" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-7" data-name="point">
                                            <circle cx="877.9211" cy="812.3317" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-22" data-name="base">
                                            <rect x="886.6181" y="766.5044" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-22" data-name="name">
                                            <g id="BEF2262">
                                                <path d="M890.0723,769.8323c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M899.8741,773.6722h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M901.4463,769.7337h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M906.4619,777.1487v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M912.1055,777.1487v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M921.8291,770.68c-.1973-.0107-.4502,0-.7256,.0439-1.5186,.2529-2.3213,1.3643-2.4863,2.541h.0332c.3408-.4512,.9346-.8252,1.7266-.8252,1.2656,0,2.1572,.9131,2.1572,2.3105,0,1.3096-.8916,2.5195-2.377,2.5195-1.5293,0-2.5303-1.1885-2.5303-3.0479,0-1.4082,.5059-2.5186,1.21-3.2227,.5947-.584,1.3867-.9463,2.2891-1.0566,.2852-.0439,.5273-.0547,.7031-.0547v.792Zm-.2744,4.125c0-1.0225-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8799-.0664,.1104-.1104,.2529-.1104,.4287,.0225,1.1777,.5615,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M923.3907,777.1487v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-7" data-name="structure">
                                            <rect x="907.4488" y="762.6598" width="3.8446" height="3.8446" transform="translate(1818.7422 1529.1642) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-7" data-name="head">
                                            <path d="M912.7219,733.9245h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2264">
                                        <g id="line-8" data-name="line">
                                            <line x1="726.1939" y1="812.8077" x2="792.3823" y2="780.2368" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-8" data-name="point">
                                            <circle cx="725.7149" cy="812.3317" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-23" data-name="base">
                                            <rect x="770.1135" y="767.6067" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-23" data-name="name">
                                            <g id="BEF2264">
                                                <path d="M773.5684,770.9349c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M783.3701,774.7747h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M784.9424,770.8362h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M789.958,778.2513v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M795.6016,778.2513v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M805.3252,771.7825c-.1973-.0107-.4502,0-.7256,.0439-1.5186,.2529-2.3213,1.3643-2.4863,2.541h.0332c.3408-.4512,.9346-.8252,1.7266-.8252,1.2656,0,2.1572,.9131,2.1572,2.3105,0,1.3096-.8916,2.5195-2.377,2.5195-1.5293,0-2.5303-1.1885-2.5303-3.0479,0-1.4082,.5059-2.5186,1.21-3.2227,.5947-.584,1.3867-.9463,2.2891-1.0566,.2852-.0439,.5273-.0547,.7031-.0547v.792Zm-.2744,4.125c0-1.0225-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8799-.0664,.1104-.1104,.2529-.1104,.4287,.0225,1.1777,.5615,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M809.8789,778.2513v-1.9473h-3.3223v-.6377l3.1904-4.5664h1.0449v4.4453h1.001v.7588h-1.001v1.9473h-.9131Zm0-2.7061v-2.3877c0-.374,.0107-.748,.0332-1.1221h-.0332c-.2197,.418-.3955,.7256-.5938,1.0557l-1.749,2.4316v.0225h2.3428Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-8" data-name="structure">
                                            <rect x="790.9442" y="763.7621" width="3.8446" height="3.8446" transform="translate(1585.7331 1531.3688) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-8" data-name="head">
                                            <path d="M796.2173,735.0268h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2261">
                                        <g id="line-9" data-name="line">
                                            <line x1="903.22" y1="852.7124" x2="896.8668" y2="878.5612" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-9" data-name="point">
                                            <circle cx="903.22" cy="852.7124" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-24" data-name="base">
                                            <rect x="872.7076" y="910.7019" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-24" data-name="name">
                                            <g id="BEF2261-2" data-name="BEF2261">
                                                <path d="M876.1621,914.0296c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M885.9639,917.8694h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M887.5362,913.931h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M892.5518,921.346v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M898.1953,921.346v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M907.919,914.8773c-.1973-.0107-.4502,0-.7256,.0439-1.5186,.2529-2.3213,1.3643-2.4863,2.541h.0332c.3408-.4512,.9346-.8252,1.7266-.8252,1.2656,0,2.1572,.9131,2.1572,2.3105,0,1.3096-.8916,2.5195-2.377,2.5195-1.5293,0-2.5303-1.1885-2.5303-3.0479,0-1.4082,.5059-2.5186,1.21-3.2227,.5947-.584,1.3867-.9463,2.2891-1.0566,.2852-.0439,.5273-.0547,.7031-.0547v.792Zm-.2744,4.125c0-1.0225-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8799-.0664,.1104-.1104,.2529-.1104,.4287,.0225,1.1777,.5615,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M911.5821,915.097h-.0225l-1.2432,.6709-.1865-.7363,1.5615-.8369h.8252v7.1514h-.9346v-6.249Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-9" data-name="structure">
                                            <rect x="893.5383" y="906.8574" width="3.8446" height="3.8446" transform="translate(1790.9213 1817.5593) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-9" data-name="head">
                                            <path d="M898.8114,878.122h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2263">
                                        <g id="line-10" data-name="line">
                                            <line x1="838.3075" y1="853.1357" x2="847.9555" y2="880.4188" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-10" data-name="point">
                                            <circle cx="838.3075" cy="853.2275" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-25" data-name="base">
                                            <rect x="824.2683" y="912.1456" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-25" data-name="name">
                                            <g id="BEF2263">
                                                <path d="M827.7227,915.4739c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M837.5244,919.3138h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M839.0967,915.3753h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M844.1123,922.7903v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M849.7559,922.7903v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M859.4795,916.3216c-.1973-.0107-.4502,0-.7256,.0439-1.5186,.2529-2.3213,1.3643-2.4863,2.541h.0332c.3408-.4512,.9346-.8252,1.7266-.8252,1.2656,0,2.1572,.9131,2.1572,2.3105,0,1.3096-.8916,2.5195-2.377,2.5195-1.5293,0-2.5303-1.1885-2.5303-3.0479,0-1.4082,.5059-2.5186,1.21-3.2227,.5947-.584,1.3867-.9463,2.2891-1.0566,.2852-.0439,.5273-.0547,.7031-.0547v.792Zm-.2744,4.125c0-1.0225-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8799-.0664,.1104-.1104,.2529-.1104,.4287,.0225,1.1777,.5615,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M861.2725,921.679c.2744,.1758,.9131,.4512,1.584,.4512,1.2432,0,1.6279-.792,1.6172-1.3857-.0107-1.002-.9131-1.4307-1.8486-1.4307h-.5391v-.7256h.5391c.7041,0,1.5957-.3633,1.5957-1.2109,0-.5713-.3633-1.0781-1.2549-1.0781-.5713,0-1.1221,.2529-1.4297,.4736l-.2529-.7041c.374-.2754,1.0996-.5508,1.8701-.5508,1.4082,0,2.0459,.8369,2.0459,1.7061,0,.7363-.4395,1.3643-1.3203,1.6826v.0225c.8809,.1758,1.5957,.8359,1.5957,1.8369,0,1.1445-.8916,2.1455-2.6074,2.1455-.8027,0-1.5068-.2529-1.8594-.4844l.2646-.748Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-10" data-name="structure">
                                            <rect x="845.099" y="908.301" width="3.8446" height="3.8446" transform="translate(1694.0425 1820.4467) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-10" data-name="head">
                                            <path d="M850.372,879.5657h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2265">
                                        <g id="line-11" data-name="line">
                                            <line x1="725.7149" y1="850.6093" x2="790.2402" y2="862.7933" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-11" data-name="point">
                                            <circle cx="726.1939" cy="851.1771" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-26" data-name="base">
                                            <rect x="771.5011" y="893.7411" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-26" data-name="name">
                                            <g id="BEF2265">
                                                <path d="M774.9551,897.0696c.418-.0879,1.0781-.1543,1.749-.1543,.958,0,1.5732,.166,2.0361,.5391,.3848,.2871,.6152,.7266,.6152,1.3086,0,.7168-.4727,1.3438-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7598,1.5293,1.8594,0,.6387-.2529,1.123-.627,1.4863-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0449-1.4854-.0898v-7.293Zm.957,3.0371h.8691c1.0127,0,1.6064-.5293,1.6064-1.2441,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3887Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0137-.8691-1.4316-1.915-1.4316h-.792v2.8281Z" fill="#fff" />
                                                <path d="M784.7569,900.9095h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.416h4.0049v.8027h-3.0479v2.3438h2.8828v.793Z" fill="#fff" />
                                                <path d="M786.3291,896.97h3.9941v.8027h-3.0371v2.4648h2.8057v.793h-2.8057v3.3555h-.957v-7.416Z" fill="#fff" />
                                                <path d="M791.3448,904.386v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M796.9883,904.386v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M806.7119,897.9173c-.1973-.0117-.4502,0-.7256,.043-1.5186,.2539-2.3213,1.3652-2.4863,2.541h.0332c.3408-.4512,.9346-.8242,1.7266-.8242,1.2656,0,2.1572,.9121,2.1572,2.3105,0,1.3086-.8916,2.5195-2.377,2.5195-1.5293,0-2.5303-1.1895-2.5303-3.0488,0-1.4082,.5059-2.5176,1.21-3.2227,.5947-.584,1.3867-.9453,2.2891-1.0566,.2852-.043,.5273-.0547,.7031-.0547v.793Zm-.2744,4.125c0-1.0234-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8789-.0664,.1113-.1104,.2539-.1104,.4297,.0225,1.1777,.5615,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M812.542,898.0482h-2.7285l-.2754,1.8379c.165-.0215,.3193-.0449,.584-.0449,.5498,0,1.0996,.1211,1.54,.3867,.5605,.3184,1.0225,.9336,1.0225,1.8359,0,1.3984-1.1104,2.4434-2.6621,2.4434-.7812,0-1.4414-.2207-1.7822-.4414l.2422-.7363c.2969,.1758,.8799,.3965,1.5293,.3965,.9131,0,1.6943-.5938,1.6943-1.5508-.0107-.9258-.627-1.5859-2.0576-1.5859-.4072,0-.7256,.0449-.9902,.0781l.4619-3.4336h3.4219v.8145Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-11" data-name="structure">
                                            <rect x="792.3318" y="889.8965" width="3.8446" height="3.8446" transform="translate(1588.5082 1783.6376) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-11" data-name="head">
                                            <path d="M797.6049,861.1612h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2991">
                                        <g id="line-12" data-name="line">
                                            <line x1="630.9053" y1="857.7269" x2="546.8364" y2="876.1885" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-12" data-name="point">
                                            <circle cx="630.9053" cy="856.3747" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-27" data-name="base">
                                            <rect x="502.2989" y="868.9715" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-27" data-name="name">
                                            <g id="BEF2991">
                                                <path d="M505.7535,872.3001c.418-.0879,1.0781-.1543,1.749-.1543,.958,0,1.5732,.166,2.0361,.5391,.3848,.2871,.6152,.7266,.6152,1.3086,0,.7168-.4727,1.3438-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7598,1.5293,1.8594,0,.6387-.2529,1.123-.627,1.4863-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0449-1.4854-.0898v-7.293Zm.957,3.0371h.8691c1.0127,0,1.6064-.5293,1.6064-1.2441,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3887Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0137-.8691-1.4316-1.915-1.4316h-.792v2.8281Z" fill="#fff" />
                                                <path d="M515.5552,876.14h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.416h4.0049v.8027h-3.0479v2.3438h2.8828v.793Z" fill="#fff" />
                                                <path d="M517.1275,872.2005h3.9937v.8027h-3.0371v2.4648h2.8057v.793h-2.8057v3.3555h-.9565v-7.416Z" fill="#fff" />
                                                <path d="M522.1426,879.6165v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M528.3477,878.9349c.209,.0215,.4502,0,.7803-.0332,.5615-.0781,1.0898-.3086,1.4961-.6934,.4736-.4297,.8145-1.0566,.9463-1.9043h-.0322c-.3965,.4844-.9688,.7715-1.6836,.7715-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.543,2.4424-2.543s2.3652,1.1895,2.3652,3.0156c0,1.5723-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0547-.6602,.0664-.8906,.0547v-.791Zm.3516-4.1152c0,.8906,.5391,1.5176,1.375,1.5176,.6494,0,1.1553-.3184,1.4082-.748,.0557-.0879,.0879-.1973,.0879-.3516,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M533.9903,878.9349c.209,.0215,.4502,0,.7803-.0332,.5615-.0781,1.0898-.3086,1.4961-.6934,.4736-.4297,.8145-1.0566,.9463-1.9043h-.0322c-.3965,.4844-.9688,.7715-1.6836,.7715-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.543,2.4424-2.543s2.3652,1.1895,2.3652,3.0156c0,1.5723-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0547-.6602,.0664-.8906,.0547v-.791Zm.3516-4.1152c0,.8906,.5391,1.5176,1.375,1.5176,.6494,0,1.1553-.3184,1.4082-.748,.0557-.0879,.0879-.1973,.0879-.3516,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M541.1729,873.3665h-.0225l-1.2432,.6719-.1865-.7363,1.5615-.8379h.8252v7.1523h-.9346v-6.25Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-12" data-name="structure">
                                            <rect x="523.1296" y="865.1269" width="3.8446" height="3.8446" transform="translate(1050.1037 1734.0984) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-12" data-name="head">
                                            <path d="M528.4026,836.3916h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2982">
                                        <g id="line-13" data-name="line">
                                            <line x1="674.0177" y1="1177.0445" x2="730.9617" y2="1168.8947" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-13" data-name="point">
                                            <circle cx="676.7225" cy="1177.0445" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-28" data-name="base">
                                            <rect x="729.404" y="1162.0654" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-28" data-name="name">
                                            <g id="BEF2991-2" data-name="BEF2991">
                                                <path d="M732.8584,1165.3939c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M742.6602,1169.2337h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M744.2325,1165.2952h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M749.2481,1172.7103v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M755.4532,1172.0286c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M760.4463,1170.8948c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M766.1768,1172.7103v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-13" data-name="structure">
                                            <rect x="750.2347" y="1158.2208" width="3.8446" height="3.8446" transform="translate(1504.314 2320.2862) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-13" data-name="head">
                                            <path d="M755.5078,1129.4855h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2980">
                                        <g id="line-14" data-name="line">
                                            <line x1="627.0034" y1="1236.1804" x2="585.9337" y2="1231.598" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-14" data-name="point">
                                            <circle cx="627.0034" cy="1236.1804" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-29" data-name="base">
                                            <rect x="542.2385" y="1229.3511" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-29" data-name="name">
                                            <g id="BEF2991-3" data-name="BEF2991">
                                                <path d="M545.6924,1232.679c.418-.0879,1.0781-.1533,1.75-.1533,.957,0,1.5723,.165,2.0352,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7051,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.252,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5625,.6934-.6602,0-1.166-.0439-1.4863-.0889v-7.2939Zm.957,3.0371h.8691c1.0137,0,1.6074-.5283,1.6074-1.2432,0-.8691-.6602-1.2109-1.6289-1.2109-.4395,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4629,.0449,.8047,.0449,.9902,0,1.9023-.3633,1.9023-1.4414,0-1.0127-.8691-1.4307-1.9141-1.4307h-.793v2.8271Z" fill="#fff" />
                                                <path d="M555.4951,1236.5189h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0039v.8027h-3.0469v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M557.0674,1232.5804h3.9941v.8027h-3.0371v2.4648h2.8047v.792h-2.8047v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M562.083,1239.9954v-.5938l.7578-.7373c1.8262-1.7383,2.6523-2.6621,2.6641-3.7402,0-.7266-.3535-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4941-.418,1.1992-.7266,2.0234-.7266,1.541,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5703,.5283v.0225h3.2109v.8027h-4.5645Z" fill="#fff" />
                                                <path d="M568.2881,1239.3138c.209,.0215,.4492,0,.7793-.0332,.5625-.0771,1.0898-.3086,1.4961-.6934,.4746-.4287,.8145-1.0566,.9473-1.9033h-.0332c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1113-.9688-2.1113-2.1895,0-1.3535,.9785-2.542,2.4414-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5273,2.6738-1.2324,3.3555-.5488,.5391-1.3086,.8691-2.0781,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6484,0,1.1543-.3193,1.4082-.748,.0547-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8262,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M573.2803,1238.18c0-.9023,.5391-1.54,1.4199-1.9141l-.0117-.0332c-.791-.374-1.1328-.9902-1.1328-1.6064,0-1.1328,.957-1.9033,2.2109-1.9033,1.3867,0,2.0801,.8691,2.0801,1.7607,0,.6055-.2969,1.2539-1.1777,1.6719v.0332c.8906,.3525,1.4414,.9795,1.4414,1.8486,0,1.2432-1.0664,2.0791-2.4316,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8516-.0439c0-.8691-.6055-1.2871-1.5742-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5176,1.3643,1.4297,1.3643,.8691,0,1.4316-.5391,1.4316-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6152-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3418-1.166-1.2109-1.166-.8027,0-1.2422,.5283-1.2422,1.1445Z" fill="#fff" />
                                                <path d="M583.7647,1236.3431c0,2.4316-.9023,3.7734-2.4863,3.7734-1.3984,0-2.3438-1.3096-2.3652-3.6748,0-2.3984,1.0332-3.7188,2.4863-3.7188,1.5059,0,2.3652,1.3428,2.3652,3.6201Zm-3.8848,.1094c0,1.8594,.5723,2.916,1.4531,2.916,.9902,0,1.4629-1.1553,1.4629-2.9814,0-1.7607-.4512-2.916-1.4531-2.916-.8457,0-1.4629,1.0342-1.4629,2.9814Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-14" data-name="structure">
                                            <rect x="563.0692" y="1225.5065" width="3.8446" height="3.8446" transform="translate(1129.983 2454.8576) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-14" data-name="head">
                                            <path d="M568.3423,1196.7712h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2975">
                                        <g id="line-15" data-name="line">
                                            <line x1="627.0034" y1="1350.46" x2="564.5083" y2="1340.8784" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-15" data-name="point">
                                            <circle cx="627.0034" cy="1350.46" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-30" data-name="base">
                                            <rect x="520.8924" y="1336.8014" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-30" data-name="name">
                                            <g id="BEF2991-4" data-name="BEF2991">
                                                <path d="M524.3467,1340.1302c.418-.0879,1.0781-.1543,1.749-.1543,.958,0,1.5732,.166,2.0361,.5391,.3848,.2871,.6152,.7266,.6152,1.3086,0,.7168-.4727,1.3438-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7598,1.5293,1.8594,0,.6387-.2529,1.123-.627,1.4863-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0449-1.4854-.0898v-7.293Zm.957,3.0371h.8691c1.0127,0,1.6064-.5293,1.6064-1.2441,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3887Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0137-.8691-1.4316-1.915-1.4316h-.792v2.8281Z" fill="#fff" />
                                                <path d="M534.1485,1343.97h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.416h4.0049v.8027h-3.0479v2.3438h2.8828v.793Z" fill="#fff" />
                                                <path d="M535.7207,1340.0306h3.9941v.8027h-3.0371v2.4648h2.8057v.793h-2.8057v3.3555h-.957v-7.416Z" fill="#fff" />
                                                <path d="M540.7364,1347.4466v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M546.9414,1346.765c.209,.0215,.4502,0,.7803-.0332,.5615-.0781,1.0898-.3086,1.4961-.6934,.4736-.4297,.8145-1.0566,.9463-1.9043h-.0322c-.3965,.4844-.9688,.7715-1.6836,.7715-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.543,2.4424-2.543s2.3652,1.1895,2.3652,3.0156c0,1.5723-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0547-.6602,.0664-.8906,.0547v-.791Zm.3516-4.1152c0,.8906,.5391,1.5176,1.375,1.5176,.6494,0,1.1553-.3184,1.4082-.748,.0557-.0879,.0879-.1973,.0879-.3516,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M556.6651,1340.2943v.6387l-3.1133,6.5137h-1.001l3.1025-6.3262v-.0215h-3.499v-.8047h4.5107Z" fill="#fff" />
                                                <path d="M561.9336,1341.1087h-2.7285l-.2754,1.8379c.165-.0215,.3193-.0449,.584-.0449,.5498,0,1.0996,.1211,1.54,.3867,.5605,.3184,1.0225,.9336,1.0225,1.8359,0,1.3984-1.1104,2.4434-2.6621,2.4434-.7812,0-1.4414-.2207-1.7822-.4414l.2422-.7363c.2969,.1758,.8799,.3965,1.5293,.3965,.9131,0,1.6943-.5938,1.6943-1.5508-.0107-.9258-.627-1.5859-2.0576-1.5859-.4072,0-.7256,.0449-.9902,.0781l.4619-3.4336h3.4219v.8145Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-15" data-name="structure">
                                            <rect x="541.7231" y="1332.9569" width="3.8446" height="3.8446" transform="translate(1087.2908 2669.7583) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-15" data-name="head">
                                            <path d="M546.9962,1304.2215h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2994">
                                        <g id="line-16" data-name="line">
                                            <line x1="678.5915" y1="876.2938" x2="691.0609" y2="927.4957" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-16" data-name="point">
                                            <circle cx="678.727" cy="877.9482" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-31" data-name="base">
                                            <rect x="684.8665" y="926.2844" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-31" data-name="name">
                                            <g id="BEF2991-5" data-name="BEF2991">
                                                <path d="M688.3213,929.6126c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M698.1231,933.4525h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M699.6953,929.514h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M704.711,936.929v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M710.916,936.2474c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M716.5586,936.2474c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M724.6319,936.929v-1.9473h-3.3223v-.6377l3.1904-4.5664h1.0449v4.4453h1.001v.7588h-1.001v1.9473h-.9131Zm0-2.7061v-2.3877c0-.374,.0107-.748,.0332-1.1221h-.0332c-.2197,.418-.3955,.7256-.5938,1.0557l-1.749,2.4316v.0225h2.3428Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-16" data-name="structure">
                                            <rect x="705.6972" y="922.4398" width="3.8446" height="3.8446" transform="translate(1415.2389 1848.7243) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-16" data-name="head">
                                            <path d="M710.9732,902.69h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2984">
                                        <g id="line-17" data-name="line">
                                            <line x1="674.5285" y1="1064.6007" x2="723.6137" y2="1062.2338" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-17" data-name="point">
                                            <circle cx="674.5285" cy="1064.6007" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-32" data-name="base">
                                            <rect x="723.5153" y="1055.2507" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-32" data-name="name">
                                            <g id="BEF2991-6" data-name="BEF2991">
                                                <path d="M726.9698,1058.5794c.418-.0879,1.0781-.1543,1.749-.1543,.958,0,1.5732,.166,2.0361,.5391,.3848,.2871,.6152,.7266,.6152,1.3086,0,.7168-.4727,1.3438-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7598,1.5293,1.8594,0,.6387-.2529,1.123-.627,1.4863-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0449-1.4854-.0898v-7.293Zm.957,3.0371h.8691c1.0127,0,1.6064-.5293,1.6064-1.2441,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3887Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0137-.8691-1.4316-1.915-1.4316h-.792v2.8281Z" fill="#fff" />
                                                <path d="M736.7715,1062.4193h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.416h4.0049v.8027h-3.0479v2.3438h2.8828v.793Z" fill="#fff" />
                                                <path d="M738.3438,1058.4798h3.9941v.8027h-3.0371v2.4648h2.8057v.793h-2.8057v3.3555h-.957v-7.416Z" fill="#fff" />
                                                <path d="M743.3594,1065.8958v-.5938l.7588-.7383c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3965-1.4199-1.3965-.6484,0-1.1875,.3301-1.5176,.6035l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8398l-.5713,.5273v.0234h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M749.5645,1065.2142c.209,.0215,.4502,0,.7803-.0332,.5615-.0781,1.0898-.3086,1.4961-.6934,.4736-.4297,.8145-1.0566,.9463-1.9043h-.0322c-.3965,.4844-.9688,.7715-1.6836,.7715-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.543,2.4424-2.543s2.3652,1.1895,2.3652,3.0156c0,1.5723-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0547-.6602,.0664-.8906,.0547v-.791Zm.3516-4.1152c0,.8906,.5391,1.5176,1.375,1.5176,.6494,0,1.1553-.3184,1.4082-.748,.0557-.0879,.0879-.1973,.0879-.3516,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M754.5576,1064.0794c0-.9023,.5391-1.5391,1.4189-1.9141l-.0107-.0332c-.792-.373-1.1338-.9902-1.1338-1.6055,0-1.1328,.958-1.9043,2.2119-1.9043,1.3857,0,2.0791,.8691,2.0791,1.7617,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3516,1.4404,.9785,1.4404,1.8477,0,1.2441-1.0664,2.0801-2.4307,2.0801-1.4961,0-2.3984-.8926-2.3984-1.9375Zm3.8506-.043c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3633,1.4297,1.3633,.8691,0,1.4307-.5391,1.4307-1.2754Zm-2.6621-3.5762c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6484,1.0898-1.2988,0-.5703-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5293-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M763.2803,1065.8958v-1.9473h-3.3223v-.6387l3.1904-4.5664h1.0449v4.4453h1.001v.7598h-1.001v1.9473h-.9131Zm0-2.707v-2.3867c0-.375,.0107-.748,.0332-1.123h-.0332c-.2197,.418-.3955,.7266-.5938,1.0566l-1.749,2.4316v.0215h2.3428Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-17" data-name="structure">
                                            <rect x="744.346" y="1051.4062" width="3.8446" height="3.8446" transform="translate(1492.5366 2106.6569) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-17" data-name="head">
                                            <path d="M749.622,1031.6563h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2978">
                                        <g id="line-18" data-name="line">
                                            <line x1="675.7538" y1="1357.1356" x2="720.7552" y2="1354.9656" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-18" data-name="point">
                                            <circle cx="675.7538" cy="1357.3325" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-33" data-name="base">
                                            <rect x="720.1726" y="1353.5305" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-33" data-name="name">
                                            <g id="BEF2991-7" data-name="BEF2991">
                                                <path d="M723.627,1356.8587c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M733.4287,1360.6985h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M735.001,1356.7601h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M740.0166,1364.1751v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M746.2217,1363.4935c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M755.9453,1357.0237v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M756.8575,1362.3597c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-18" data-name="structure">
                                            <rect x="741.0033" y="1349.6859" width="3.8446" height="3.8446" transform="translate(1485.8511 2703.2163) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-18" data-name="head">
                                            <path d="M746.2793,1329.936h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2973">
                                        <g id="line-19" data-name="line">
                                            <line x1="627.0034" y1="1366.1987" x2="597.0338" y2="1372.5629" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-19" data-name="point">
                                            <circle cx="627.0034" cy="1366.1987" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-34" data-name="base">
                                            <rect x="552.4962" y="1367.8819" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-34" data-name="name">
                                            <g id="BEF2991-8" data-name="BEF2991">
                                                <path d="M555.9502,1371.2103c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M565.752,1375.0501h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M567.3243,1371.1116h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M572.3399,1378.5267v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M578.545,1377.845c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M588.2686,1371.3753v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M589.5,1377.4153c.2744,.1758,.9131,.4512,1.584,.4512,1.2432,0,1.6279-.792,1.6172-1.3857-.0107-1.002-.9131-1.4307-1.8486-1.4307h-.5391v-.7256h.5391c.7041,0,1.5957-.3633,1.5957-1.2109,0-.5713-.3633-1.0781-1.2549-1.0781-.5713,0-1.1221,.2529-1.4297,.4736l-.2529-.7041c.374-.2754,1.0996-.5508,1.8701-.5508,1.4082,0,2.0459,.8369,2.0459,1.7061,0,.7363-.4395,1.3643-1.3203,1.6826v.0225c.8809,.1758,1.5957,.8359,1.5957,1.8369,0,1.1445-.8916,2.1455-2.6074,2.1455-.8027,0-1.5068-.2529-1.8594-.4844l.2646-.748Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-19" data-name="structure">
                                            <rect x="573.327" y="1364.0373" width="3.8446" height="3.8446" transform="translate(1150.4985 2731.9191) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-19" data-name="head">
                                            <path d="M578.6029,1344.2874h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2987">
                                        <g id="line-20" data-name="line">
                                            <line x1="630.9053" y1="909.2947" x2="590.6401" y2="926.2844" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-20" data-name="point">
                                            <circle cx="630.9053" cy="909.2947" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-35" data-name="base">
                                            <rect x="548.3" y="925.773" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-35" data-name="name">
                                            <g id="BEF2987">
                                                <path d="M551.7549,929.1009c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M561.5567,932.9407h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M563.1289,929.0023h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M568.1446,936.4173v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M574.3496,935.7357c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M579.3428,934.6019c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M589.7159,929.2659v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-20" data-name="structure">
                                            <rect x="569.1308" y="921.9285" width="3.8446" height="3.8446" transform="translate(1142.1061 1847.7015) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-20" data-name="head">
                                            <path d="M574.4067,902.1786h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2979">
                                        <g id="line-21" data-name="line">
                                            <line x1="627.0034" y1="1192.6549" x2="587.8953" y2="1174.6859" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-21" data-name="point">
                                            <circle cx="627.0034" cy="1192.6549" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-36" data-name="base">
                                            <rect x="544.3981" y="1171.51" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-36" data-name="name">
                                            <g id="BEF2987-2" data-name="BEF2987">
                                                <path d="M547.8526,1174.8382c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M557.6543,1178.678h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M559.2266,1174.7396h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M564.2422,1182.1546v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M570.4473,1181.473c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M580.1709,1175.0032v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M581.7325,1181.473c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-21" data-name="structure">
                                            <rect x="565.2289" y="1167.6655" width="3.8446" height="3.8446" transform="translate(1134.3023 2339.1755) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-21" data-name="head">
                                            <path d="M570.5048,1147.9156h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2271">
                                        <g id="line-22" data-name="line">
                                            <line x1="626.7143" y1="670.0667" x2="540.3019" y2="643.7793" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-22" data-name="point">
                                            <circle cx="626.7143" cy="670.0667" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-37" data-name="base">
                                            <rect x="495.7644" y="640.8985" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-37" data-name="name">
                                            <g id="BEF2271">
                                                <path d="M499.2188,644.2269c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M509.0205,648.0667h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M510.5928,644.1282h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M515.6084,651.5433v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M521.252,651.5433v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M531.5371,644.3919v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M534.6387,645.2943h-.0225l-1.2432,.6709-.1865-.7363,1.5615-.8369h.8252v7.1514h-.9346v-6.249Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-22" data-name="structure">
                                            <rect x="516.5951" y="637.0539" width="3.8446" height="3.8446" transform="translate(1037.0347 1277.9523) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-22" data-name="head">
                                            <path d="M521.8681,608.3186h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2275">
                                        <g id="line-23" data-name="line">
                                            <line x1="588.9625" y1="635.4407" x2="518.0331" y2="598.6177" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-23" data-name="point">
                                            <circle cx="588.9625" cy="635.4407" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-38" data-name="base">
                                            <rect x="473.4956" y="589.9683" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-38" data-name="name">
                                            <g id="BEF2275">
                                                <path d="M476.9502,593.2962c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M486.752,597.136h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M488.3243,593.1976h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M493.3399,600.6126v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M498.9834,600.6126v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M509.2686,593.4612v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M514.5371,594.2757h-2.7285l-.2754,1.8369c.165-.0215,.3193-.0439,.584-.0439,.5498,0,1.0996,.1211,1.54,.3857,.5605,.3184,1.0225,.9346,1.0225,1.8369,0,1.3975-1.1104,2.4424-2.6621,2.4424-.7812,0-1.4414-.2197-1.7822-.4404l.2422-.7373c.2969,.1768,.8799,.3965,1.5293,.3965,.9131,0,1.6943-.5938,1.6943-1.5508-.0107-.9248-.627-1.585-2.0576-1.585-.4072,0-.7256,.0439-.9902,.0771l.4619-3.4326h3.4219v.8145Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-23" data-name="structure">
                                            <rect x="494.3263" y="586.1237" width="3.8446" height="3.8446" transform="translate(992.4972 1176.0919) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-23" data-name="head">
                                            <path d="M499.5994,557.3884h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2277">
                                        <g id="line-24" data-name="line">
                                            <line x1="504.7721" y1="539.694" x2="464.1742" y2="556.1424" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-24" data-name="point">
                                            <circle cx="504.7721" cy="539.694" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-39" data-name="base">
                                            <rect x="420.866" y="547.2558" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-39" data-name="name">
                                            <g id="BEF2277">
                                                <path d="M424.3203,550.5843c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M434.1221,554.4241h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M435.6944,550.4857h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M440.71,557.9007v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M446.3535,557.9007v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M456.6387,550.7493v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M462.2813,550.7493v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-24" data-name="structure">
                                            <rect x="441.6967" y="543.4112" width="3.8446" height="3.8446" transform="translate(887.238 1090.667) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-24" data-name="head">
                                            <path d="M446.9698,514.6759h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2279">
                                        <g id="line-25" data-name="line">
                                            <line x1="388.0098" y1="403.1711" x2="347.4119" y2="419.6195" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-25" data-name="point">
                                            <circle cx="388.0098" cy="403.1711" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-40" data-name="base">
                                            <rect x="304.1037" y="410.7329" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-40" data-name="name">
                                            <g id="BEF2279">
                                                <path d="M307.5581,414.0609c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M317.3599,417.9007h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M318.9322,413.9622h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M323.9478,421.3773v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M329.5913,421.3773v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M339.8765,414.2259v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M341.438,420.6956c.209,.0215,.4502,0,.7803-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-25" data-name="structure">
                                            <rect x="324.9344" y="406.8883" width="3.8446" height="3.8446" transform="translate(653.7134 817.6212) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-25" data-name="head">
                                            <path d="M330.2075,378.153h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2281">
                                        <g id="line-26" data-name="line">
                                            <line x1="256.374" y1="253.3486" x2="220.8272" y2="280.5559" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-26" data-name="point">
                                            <circle cx="255.6403" cy="253.3486" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-41" data-name="base">
                                            <rect x="177.8692" y="279.2626" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-41" data-name="name">
                                            <g id="BEF2281">
                                                <path d="M181.3238,282.5911c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M191.1255,286.431h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M192.6978,282.4925h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M197.7134,289.9075v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M203.357,289.9075v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M208.9117,288.0921c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M216.7437,283.6585h-.0225l-1.2432,.6709-.1865-.7363,1.5615-.8369h.8252v7.1514h-.9346v-6.249Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-26" data-name="structure">
                                            <rect x="198.6999" y="275.4181" width="3.8446" height="3.8446" transform="translate(401.2444 554.6807) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-26" data-name="head">
                                            <path d="M203.973,246.6827h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-T2779">
                                        <g id="line-27" data-name="line">
                                            <line x1="127.3609" y1="100.6358" x2="91.8141" y2="127.8432" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-27" data-name="point">
                                            <circle cx="126.6272" cy="100.6358" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-42" data-name="base">
                                            <rect x="48.8561" y="126.5499" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-42" data-name="name">
                                            <g id="T2779">
                                                <path d="M59.3506,130.5941h-2.2549v-.8145h5.4893v.8145h-2.2656v6.6006h-.9688v-6.6006Z" fill="#fff" />
                                                <path d="M63.0684,137.1946v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M73.3535,130.0433v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M78.9971,130.0433v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M80.5576,136.513c.21,.0215,.4512,0,.7812-.0332,.5615-.0771,1.0898-.3086,1.4961-.6934,.4736-.4287,.8145-1.0566,.9463-1.9033h-.0322c-.3965,.4844-.9688,.7705-1.6836,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9795-2.542,2.4424-2.542s2.3652,1.1885,2.3652,3.0146c0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3086,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8916,.0557v-.792Zm.3525-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0557-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-27" data-name="structure">
                                            <rect x="69.6868" y="122.7053" width="3.8446" height="3.8446" transform="translate(143.2182 249.2552) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-27" data-name="head">
                                            <path d="M74.9599,93.97h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-T2778">
                                        <g id="line-28" data-name="line">
                                            <line x1="155.8824" y1="36.1011" x2="141.01" y2="20.6381" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-28" data-name="point">
                                            <circle cx="142.6031" cy="22.6245" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-43" data-name="base">
                                            <rect x="155.8824" y="36.1011" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-43" data-name="name">
                                            <g id="T2779-2" data-name="T2779">
                                                <path d="M166.377,40.1448h-2.2549v-.8145h5.4893v.8145h-2.2656v6.6006h-.9688v-6.6006Z" fill="#fff" />
                                                <path d="M170.0948,46.7454v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M180.3799,39.5941v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M186.0235,39.5941v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M186.9356,44.93c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-28" data-name="structure">
                                            <rect x="176.7131" y="32.2566" width="3.8446" height="3.8446" transform="translate(357.2709 68.3577) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-28" data-name="head">
                                            <path d="M181.9862,3.5212h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798V29.0413c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01V6.4412c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2283">
                                        <g id="line-29" data-name="line">
                                            <line x1="169.7073" y1="155.4061" x2="134.1605" y2="182.6134" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-29" data-name="point">
                                            <circle cx="168.9737" cy="155.4061" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-44" data-name="base">
                                            <rect x="91.2026" y="181.3201" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-44" data-name="name">
                                            <g id="BEF2281-2" data-name="BEF2281">
                                                <path d="M94.6568,184.6478c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M104.4585,188.4876h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M106.0308,184.5491h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M111.0464,191.9642v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M116.69,191.9642v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M122.2447,190.1487c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M128.2066,190.8528c.2744,.1758,.9131,.4512,1.584,.4512,1.2432,0,1.6279-.792,1.6172-1.3857-.0107-1.002-.9131-1.4307-1.8486-1.4307h-.5391v-.7256h.5391c.7041,0,1.5957-.3633,1.5957-1.2109,0-.5713-.3633-1.0781-1.2549-1.0781-.5713,0-1.1221,.2529-1.4297,.4736l-.2529-.7041c.374-.2754,1.0996-.5508,1.8701-.5508,1.4082,0,2.0459,.8369,2.0459,1.7061,0,.7363-.4395,1.3643-1.3203,1.6826v.0225c.8809,.1758,1.5957,.8359,1.5957,1.8369,0,1.1445-.8916,2.1455-2.6074,2.1455-.8027,0-1.5068-.2529-1.8594-.4844l.2646-.748Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-29" data-name="structure">
                                            <rect x="112.0333" y="177.4755" width="3.8446" height="3.8446" transform="translate(227.9111 358.7956) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-29" data-name="head">
                                            <path d="M117.1915,140.3271h-2.5717v-.0127s-1.5798,0-1.5798,0v.0127h-2.1328c-1.211,0-2.1928,1.2418-2.1928,2.7738v31.1468c0,1.784,1.1433,3.2302,2.5535,3.2302h5.0566c1.7528,0,3.1737-1.7975,3.1737-4.0148v-30.2181c0-1.6115-1.0326-2.9179-2.3067-2.9179Zm-.2855,31.9318c-.2435,1.01-1.0718,1.8383-2.0817,2.0817-2.1488,.518-4.0355-1.369-3.5175-3.5176,.2435-1.0099,1.0717-1.8381,2.0816-2.0816,2.1486-.518,4.0356,1.3687,3.5176,3.5175Zm-.0001-8.9113c-.2436,1.0099-1.0719,1.838-2.0818,2.0812-2.1485,.5177-4.035-1.3688-3.5174-3.5173,.2434-1.0099,1.0714-1.8382,2.0813-2.0818,2.1491-.5185,4.0364,1.3689,3.518,3.5179Zm0-8.5827c-.2436,1.0099-1.0719,1.838-2.0818,2.0812-2.1485,.5177-4.035-1.3688-3.5174-3.5173,.2434-1.0099,1.0714-1.8382,2.0813-2.0818,2.1491-.5185,4.0364,1.3689,3.518,3.5179Zm0-8.2457c-.2436,1.0098-1.0719,1.8379-2.0818,2.0812-2.1485,.5176-4.035-1.3688-3.5174-3.5173,.2434-1.0099,1.0714-1.8383,2.0813-2.0819,2.1491-.5185,4.0364,1.3689,3.518,3.518Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2270">
                                        <g id="line-30" data-name="line">
                                            <line x1="650.6189" y1="642.7948" x2="680.0216" y2="592.9243" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-30" data-name="point">
                                            <circle cx="651.7904" cy="640.9846" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-45" data-name="base">
                                            <rect x="674.6477" y="580.3609" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-45" data-name="name">
                                            <g id="BEF2270">
                                                <path d="M678.1026,583.6888c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M687.9043,587.5286h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M689.4766,583.5902h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M694.4922,591.0052v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M700.1358,591.0052v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M710.4209,583.8538v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M716.1739,587.3528c0,2.4316-.9023,3.7734-2.4863,3.7734-1.3975,0-2.3438-1.3096-2.3652-3.6748,0-2.3984,1.0342-3.7188,2.4863-3.7188,1.5068,0,2.3652,1.3428,2.3652,3.6201Zm-3.8838,.1094c0,1.8594,.5723,2.916,1.4521,2.916,.9902,0,1.4629-1.1553,1.4629-2.9814,0-1.7607-.4512-2.916-1.4521-2.916-.8467,0-1.4629,1.0342-1.4629,2.9814Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-30" data-name="structure">
                                            <rect x="695.4785" y="576.5163" width="3.8446" height="3.8446" transform="translate(1394.8015 1156.8772) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-30" data-name="head">
                                            <path d="M700.7515,547.785h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2272">
                                        <g id="line-31" data-name="line">
                                            <line x1="593.0306" y1="570.6035" x2="628.2145" y2="539.0644" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-31" data-name="point">
                                            <circle cx="593.0306" cy="570.6035" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-46" data-name="base">
                                            <rect x="626.2531" y="527.1454" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-46" data-name="name">
                                            <g id="BEF2272">
                                                <path d="M629.708,530.4739c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M639.5098,534.3138h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M641.0821,530.3753h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M646.0977,537.7903v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M651.7412,537.7903v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M662.0264,530.639v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M663.0264,537.7903v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-31" data-name="structure">
                                            <rect x="647.0838" y="523.3009" width="3.8446" height="3.8446" transform="translate(1298.0122 1050.4463) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-31" data-name="head">
                                            <path d="M652.3569,494.5695h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2274">
                                        <g id="line-32" data-name="line">
                                            <line x1="511.02" y1="482.2114" x2="554.7706" y2="474.9798" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-32" data-name="point">
                                            <circle cx="513.5906" cy="481.3575" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-47" data-name="base">
                                            <rect x="553.6266" y="463.0207" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-47" data-name="name">
                                            <g id="BEF2274">
                                                <path d="M557.0811,466.3489c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M566.8828,470.1888h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M568.4551,466.2503h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M573.4707,473.6653v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M579.1143,473.6653v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M589.3994,466.514v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M593.3916,473.6653v-1.9473h-3.3223v-.6377l3.1904-4.5664h1.0449v4.4453h1.001v.7588h-1.001v1.9473h-.9131Zm0-2.7061v-2.3877c0-.374,.0107-.748,.0332-1.1221h-.0332c-.2197,.418-.3955,.7256-.5938,1.0557l-1.749,2.4316v.0225h2.3428Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-32" data-name="structure">
                                            <rect x="574.4573" y="459.1762" width="3.8446" height="3.8446" transform="translate(1152.7592 922.1969) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-32" data-name="head">
                                            <path d="M579.7304,430.4448h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2276">
                                        <g id="line-33" data-name="line">
                                            <line x1="384.613" y1="332.3325" x2="437.2379" y2="331.8018" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-33" data-name="point">
                                            <circle cx="384.613" cy="332.3325" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-48" data-name="base">
                                            <rect x="437.197" y="326.6441" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-48" data-name="name">
                                            <g id="BEF2274-2" data-name="BEF2274">
                                                <path d="M440.6514,329.972c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M450.4532,333.8118h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M452.0254,329.8734h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M457.041,337.2884v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M462.6846,337.2884v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M472.9698,330.137v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M478.0508,330.8196c-.1973-.0107-.4502,0-.7256,.0439-1.5186,.2529-2.3213,1.3643-2.4863,2.541h.0332c.3408-.4512,.9346-.8252,1.7266-.8252,1.2656,0,2.1572,.9131,2.1572,2.3105,0,1.3096-.8916,2.5195-2.377,2.5195-1.5293,0-2.5303-1.1885-2.5303-3.0479,0-1.4082,.5059-2.5186,1.21-3.2227,.5947-.584,1.3867-.9463,2.2891-1.0566,.2852-.0439,.5273-.0547,.7031-.0547v.792Zm-.2744,4.125c0-1.0225-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8799-.0664,.1104-.1104,.2529-.1104,.4287,.0225,1.1777,.5615,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-33" data-name="structure">
                                            <rect x="458.0277" y="322.7995" width="3.8446" height="3.8446" transform="translate(919.9 649.4436) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-33" data-name="head">
                                            <path d="M463.3008,294.0682h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2278">
                                        <g id="line-34" data-name="line">
                                            <line x1="256.6192" y1="183.4868" x2="300.3698" y2="176.2552" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-34" data-name="point">
                                            <circle cx="256.6192" cy="183.4868" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-49" data-name="base">
                                            <rect x="299.2257" y="164.2961" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-49" data-name="name">
                                            <g id="BEF2274-3" data-name="BEF2274">
                                                <path d="M302.6802,167.6243c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M312.482,171.4642h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M314.0542,167.5257h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M319.0699,174.9407v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M324.7134,174.9407v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M334.9986,167.7894v.6387l-3.1133,6.5127h-1.001l3.1025-6.3262v-.0215h-3.499v-.8037h4.5107Z" fill="#fff" />
                                                <path d="M335.9107,173.1253c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-34" data-name="structure">
                                            <rect x="320.0565" y="160.4516" width="3.8446" height="3.8446" transform="translate(643.9575 324.7477) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-34" data-name="head">
                                            <path d="M325.3295,131.7203h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2280">
                                        <g id="line-35" data-name="line">
                                            <line x1="154.4343" y1="66.9296" x2="205.6954" y2="55.3293" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-35" data-name="point">
                                            <circle cx="154.4343" cy="66.9296" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-50" data-name="base">
                                            <rect x="204.9599" y="42.7437" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-50" data-name="name">
                                            <g id="BEF2280">
                                                <path d="M208.4146,46.0716c.418-.0879,1.0781-.1533,1.749-.1533,.958,0,1.5732,.165,2.0361,.5391,.3848,.2861,.6152,.7256,.6152,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0127,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8037,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.915-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M218.2163,49.9114h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M219.7886,45.973h3.9941v.8027h-3.0371v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M224.8042,53.388v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M230.4478,53.388v-.5938l.7588-.7373c1.8262-1.7383,2.6514-2.6621,2.6631-3.7402,0-.7266-.3525-1.3975-1.4199-1.3975-.6484,0-1.1875,.3301-1.5176,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.54,0,2.1895,1.0566,2.1895,2.0801,0,1.3193-.957,2.3867-2.4648,3.8389l-.5713,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M236.0025,51.5726c0-.9023,.5391-1.54,1.4189-1.9141l-.0107-.0332c-.792-.374-1.1338-.9902-1.1338-1.6064,0-1.1328,.958-1.9033,2.2119-1.9033,1.3857,0,2.0791,.8691,2.0791,1.7607,0,.6055-.2969,1.2539-1.1768,1.6719v.0332c.8906,.3525,1.4404,.9795,1.4404,1.8486,0,1.2432-1.0664,2.0791-2.4307,2.0791-1.4961,0-2.3984-.8916-2.3984-1.9365Zm3.8506-.0439c0-.8691-.6055-1.2871-1.5732-1.5625-.8359,.2422-1.2871,.793-1.2871,1.4746-.0332,.7266,.5166,1.3643,1.4297,1.3643,.8691,0,1.4307-.5391,1.4307-1.2764Zm-2.6621-3.5752c0,.7148,.5391,1.0996,1.3633,1.3203,.6162-.209,1.0898-.6494,1.0898-1.2988,0-.5713-.3408-1.166-1.21-1.166-.8037,0-1.2432,.5283-1.2432,1.1445Z" fill="#fff" />
                                                <path d="M246.4859,49.7357c0,2.4316-.9023,3.7734-2.4863,3.7734-1.3975,0-2.3438-1.3096-2.3652-3.6748,0-2.3984,1.0342-3.7188,2.4863-3.7188,1.5068,0,2.3652,1.3428,2.3652,3.6201Zm-3.8838,.1094c0,1.8594,.5723,2.916,1.4521,2.916,.9902,0,1.4629-1.1553,1.4629-2.9814,0-1.7607-.4512-2.916-1.4521-2.916-.8467,0-1.4629,1.0342-1.4629,2.9814Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-35" data-name="structure">
                                            <rect x="225.7907" y="38.8992" width="3.8446" height="3.8446" transform="translate(455.4259 81.6429) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-35" data-name="head">
                                            <path d="M231.0637,10.1678h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01V13.0878c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2267">
                                        <g id="line-36" data-name="line">
                                            <line x1="679.1289" y1="786.6419" x2="724.6383" y2="781.7421" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-36" data-name="point">
                                            <circle cx="679.1289" cy="786.6419" r="3.6419" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-51" data-name="base">
                                            <rect x="722.9488" y="771.5459" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-51" data-name="name">
                                            <g>
                                                <path d="M726.4043,774.8743c.418-.0879,1.0781-.1533,1.749-.1533,.957,0,1.5732,.165,2.0352,.5391,.3857,.2861,.6162,.7256,.6162,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.957,3.0371h.8691c1.0117,0,1.6064-.5283,1.6064-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8027,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.9141-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M736.2061,778.7142h-2.8828v2.6738h3.2129v.8027h-4.1699v-7.415h4.0049v.8027h-3.0479v2.3438h2.8828v.792Z" fill="#fff" />
                                                <path d="M737.7784,774.7757h3.9932v.8027h-3.0361v2.4648h2.8057v.792h-2.8057v3.3555h-.957v-7.415Z" fill="#fff" />
                                                <path d="M742.794,782.1907v-.5938l.7598-.7373c1.8262-1.7383,2.6514-2.6621,2.6621-3.7402,0-.7266-.3525-1.3975-1.4189-1.3975-.6494,0-1.1885,.3301-1.5186,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.541,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4639,3.8389l-.5723,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M748.4366,782.1907v-.5938l.7598-.7373c1.8262-1.7383,2.6514-2.6621,2.6621-3.7402,0-.7266-.3525-1.3975-1.4189-1.3975-.6494,0-1.1885,.3301-1.5186,.6045l-.3086-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.541,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4639,3.8389l-.5723,.5283v.0225h3.2119v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M758.1612,775.722c-.1982-.0107-.4512,0-.7256,.0439-1.5186,.2529-2.3213,1.3643-2.4863,2.541h.0322c.3418-.4512,.9355-.8252,1.7275-.8252,1.2656,0,2.1562,.9131,2.1562,2.3105,0,1.3096-.8906,2.5195-2.376,2.5195-1.5293,0-2.5303-1.1885-2.5303-3.0479,0-1.4082,.5059-2.5186,1.21-3.2227,.5938-.584,1.3857-.9463,2.2881-1.0566,.2861-.0439,.5283-.0547,.7041-.0547v.792Zm-.2744,4.125c0-1.0225-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8799-.0664,.1104-.1104,.2529-.1104,.4287,.0215,1.1777,.5605,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M764.3653,775.0394v.6387l-3.1133,6.5127h-1.002l3.1025-6.3262v-.0215h-3.498v-.8037h4.5107Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-36" data-name="structure">
                                            <rect x="743.7795" y="767.7014" width="3.8446" height="3.8446" transform="translate(1491.4036 1539.2473) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-36" data-name="head">
                                            <path d="M752.456,762.2036l-6.2155-5.1367c-1.1716-.9682-2.8739-.9382-4.0106,.0708l-2.6432,2.3462c-.754,.6692-1.1854,1.6291-1.1854,2.6372v4.2186c0,.8514,.6902,1.5416,1.5416,1.5416h11.7976c.8564,0,1.5507-.6943,1.5507-1.5507v-2.3535c0-.6861-.3062-1.3364-.8351-1.7735Zm-10.7491,4.2067c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm2.3905-5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm6.0674,5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2269">
                                        <g id="line-37" data-name="line">
                                            <line x1="616.2751" y1="792.3766" x2="529.3633" y2="813.9891" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-37" data-name="point">
                                            <circle cx="616.2751" cy="792.4366" r="3.6419" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-52" data-name="base">
                                            <rect x="486.8464" y="803.314" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-52" data-name="name">
                                            <g>
                                                <path d="M490.3018,806.6429c.4185-.0879,1.0781-.1533,1.7495-.1533,.957,0,1.5732,.165,2.0352,.5391,.3853,.2861,.6162,.7256,.6162,1.3086,0,.7158-.4731,1.3428-1.2544,1.6289v.0215c.7041,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2529,1.1221-.627,1.4854-.5171,.4727-1.353,.6934-2.5635,.6934-.6602,0-1.166-.0439-1.4854-.0889v-7.2939Zm.9575,3.0371h.8691c1.0117,0,1.606-.5283,1.606-1.2432,0-.8691-.6602-1.2109-1.6279-1.2109-.4404,0-.6934,.0332-.8472,.0664v2.3877Zm0,3.5527c.187,.0332,.4619,.0449,.8027,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.9141-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M500.1035,810.4827h-2.8823v2.6738h3.2124v.8027h-4.1699v-7.415h4.0049v.8027h-3.0474v2.3438h2.8823v.792Z" fill="#fff" />
                                                <path d="M501.6758,806.5443h3.9937v.8027h-3.0361v2.4648h2.8052v.792h-2.8052v3.3555h-.9575v-7.415Z" fill="#fff" />
                                                <path d="M506.6919,813.9593v-.5938l.7593-.7373c1.8262-1.7383,2.6514-2.6621,2.6621-3.7402,0-.7266-.3521-1.3975-1.4189-1.3975-.6494,0-1.1885,.3301-1.5186,.6045l-.3081-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.5405,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4644,3.8389l-.5723,.5283v.0225h3.2124v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M512.3345,813.9593v-.5938l.7593-.7373c1.8262-1.7383,2.6514-2.6621,2.6621-3.7402,0-.7266-.3521-1.3975-1.4189-1.3975-.6494,0-1.1885,.3301-1.5186,.6045l-.3081-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.5405,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4644,3.8389l-.5723,.5283v.0225h3.2124v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M522.0586,807.4905c-.1982-.0107-.4512,0-.7256,.0439-1.5186,.2529-2.3213,1.3643-2.4863,2.541h.0322c.3418-.4512,.9355-.8252,1.7275-.8252,1.2656,0,2.1562,.9131,2.1562,2.3105,0,1.3096-.8906,2.5195-2.376,2.5195-1.5293,0-2.5303-1.1885-2.5303-3.0479,0-1.4082,.5059-2.5186,1.21-3.2227,.5938-.584,1.3857-.9463,2.2881-1.0566,.2861-.0439,.5283-.0547,.7041-.0547v.792Zm-.2744,4.125c0-1.0225-.583-1.6387-1.4746-1.6387-.583,0-1.1221,.3633-1.3857,.8799-.0664,.1104-.1104,.2529-.1104,.4287,.0215,1.1777,.5605,2.0469,1.5732,2.0469,.8359,0,1.3975-.6934,1.3975-1.7168Z" fill="#fff" />
                                                <path d="M524.1807,813.2777c.209,.0215,.4512,0,.7812-.0332,.5615-.0771,1.0889-.3086,1.4961-.6934,.4736-.4287,.8145-1.0557,.9463-1.9033h-.0332c-.3955,.4844-.9678,.7705-1.6826,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9785-2.542,2.4424-2.542,1.4629,0,2.3652,1.1885,2.3652,3.0146,0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3096,.8691-2.0791,.957-.3525,.0557-.6602,.0664-.8916,.0557v-.792Zm.3525-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0547-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-37" data-name="structure">
                                            <rect x="507.6771" y="799.4694" width="3.8446" height="3.8446" transform="translate(1019.1988 1602.7834) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-37" data-name="head">
                                            <path d="M516.3536,793.9716l-6.2155-5.1367c-1.1716-.9682-2.8739-.9382-4.0106,.0708l-2.6432,2.3462c-.754,.6692-1.1854,1.6291-1.1854,2.6372v4.2186c0,.8514,.6902,1.5416,1.5416,1.5416h11.7976c.8564,0,1.5507-.6943,1.5507-1.5507v-2.3535c0-.6861-.3062-1.3364-.8351-1.7735Zm-10.7491,4.2067c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm2.3905-5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm6.0674,5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2995">
                                        <g id="line-38" data-name="line">
                                            <line x1="729.0425" y1="870.3814" x2="676.474" y2="848.0072" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="point-38" data-name="point">
                                            <circle cx="675.9889" cy="848.0072" r="3.6419" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-53" data-name="base">
                                            <rect x="713.1215" y="870.3814" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="name-53" data-name="name">
                                            <g>
                                                <path d="M716.5772,873.7103c.418-.0879,1.0781-.1533,1.749-.1533,.957,0,1.5732,.165,2.0352,.5391,.3857,.2861,.6162,.7256,.6162,1.3086,0,.7158-.4727,1.3428-1.2539,1.6289v.0215c.7031,.1758,1.5293,.7588,1.5293,1.8594,0,.6377-.2539,1.1221-.627,1.4854-.5176,.4727-1.3535,.6934-2.5645,.6934-.6602,0-1.166-.0439-1.4844-.0889v-7.2939Zm.957,3.0371h.8691c1.0117,0,1.6055-.5283,1.6055-1.2432,0-.8691-.6602-1.2109-1.627-1.2109-.4414,0-.6934,.0332-.8477,.0664v2.3877Zm0,3.5527c.1875,.0332,.4619,.0449,.8027,.0449,.9902,0,1.9033-.3633,1.9033-1.4414,0-1.0127-.8691-1.4307-1.9141-1.4307h-.792v2.8271Z" fill="#fff" />
                                                <path d="M726.378,877.5501h-2.8818v2.6738h3.2119v.8027h-4.1699v-7.415h4.0059v.8027h-3.0479v2.3438h2.8818v.792Z" fill="#fff" />
                                                <path d="M727.9502,873.6116h3.9941v.8027h-3.0361v2.4648h2.8057v.792h-2.8057v3.3555h-.958v-7.415Z" fill="#fff" />
                                                <path d="M732.9668,881.0267v-.5938l.7588-.7373c1.8262-1.7383,2.6523-2.6621,2.6621-3.7402,0-.7266-.3516-1.3975-1.418-1.3975-.6504,0-1.1895,.3301-1.5195,.6045l-.3076-.6816c.4951-.418,1.1992-.7266,2.0244-.7266,1.541,0,2.1895,1.0566,2.1895,2.0801,0,1.3203-.957,2.3867-2.4648,3.8389l-.5723,.5283v.0225h3.2129v.8027h-4.5654Z" fill="#fff" />
                                                <path d="M739.1709,880.345c.209,.0215,.4512,0,.7812-.0332,.5605-.0771,1.0879-.3086,1.4961-.6934,.4727-.4287,.8135-1.0557,.9453-1.9033h-.0332c-.3955,.4844-.9678,.7705-1.6826,.7705-1.2871,0-2.1123-.9688-2.1123-2.1895,0-1.3535,.9785-2.542,2.4424-2.542,1.4629,0,2.3652,1.1885,2.3652,3.0146,0,1.5732-.5283,2.6738-1.2324,3.3555-.5498,.5391-1.3096,.8691-2.0791,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6494,0,1.1553-.3193,1.4082-.748,.0547-.0879,.0879-.1982,.0879-.3525,0-1.2207-.4512-2.1562-1.4629-2.1562-.8252,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M744.8135,880.345c.209,.0215,.4512,0,.7812-.0332,.5605-.0771,1.0889-.3086,1.4961-.6934,.4727-.4287,.8145-1.0557,.9463-1.9033h-.0332c-.3955,.4844-.9678,.7705-1.6826,.7705-1.2871,0-2.1133-.9688-2.1133-2.1895,0-1.3535,.9795-2.542,2.4434-2.542,1.4629,0,2.3652,1.1885,2.3652,3.0146,0,1.5732-.5293,2.6738-1.2324,3.3555-.5508,.5391-1.3096,.8691-2.0801,.957-.3516,.0557-.6602,.0664-.8906,.0557v-.792Zm.3516-4.1152c0,.8916,.5391,1.5186,1.375,1.5186,.6504,0,1.1562-.3193,1.4082-.748,.0557-.0879,.0889-.1982,.0889-.3525,0-1.2207-.4512-2.1562-1.4639-2.1562-.8242,0-1.4082,.7266-1.4082,1.7383Z" fill="#fff" />
                                                <path d="M754.1631,874.6898h-2.7285l-.2744,1.8369c.165-.0215,.3193-.0439,.583-.0439,.5508,0,1.0996,.1211,1.541,.3857,.5605,.3184,1.0225,.9346,1.0225,1.8369,0,1.3975-1.1113,2.4424-2.6631,2.4424-.7803,0-1.4404-.2197-1.7812-.4404l.2422-.7363c.2969,.1758,.8789,.3955,1.5293,.3955,.9121,0,1.6934-.5938,1.6934-1.5508-.0107-.9248-.627-1.585-2.0566-1.585-.4072,0-.7266,.0439-.9902,.0771l.4619-3.4326h3.4209v.8145Z" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="structure-38" data-name="structure">
                                            <rect x="733.9522" y="866.5369" width="3.8446" height="3.8446" transform="translate(1471.7489 1736.9183) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="head-38" data-name="head">
                                            <path d="M742.6287,861.0391l-6.2155-5.1367c-1.1716-.9682-2.8739-.9382-4.0106,.0708l-2.6432,2.3462c-.754,.6692-1.1854,1.6291-1.1854,2.6372v4.2186c0,.8514,.6902,1.5416,1.5416,1.5416h11.7976c.8564,0,1.5507-.6943,1.5507-1.5507v-2.3535c0-.6861-.3062-1.3364-.8351-1.7735Zm-10.7491,4.2067c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm2.3905-5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm6.0674,5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2268">
                                        <g id="signal-line">
                                            <line x1="677.3932" y1="708.6956" x2="733.4498" y2="667.3711" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="signal-point">
                                            <circle cx="677.3932" cy="708.3592" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-54" data-name="base">
                                            <rect x="732.496" y="658.0038" width="44.5375" height="13.6586" fill="#e9427d" />
                                        </g>
                                        <g id="head-39" data-name="head">
                                            <path d="M757.8548,625.4227h-2.1497v-3.8838l-4.31-4.3101-1.35,1.3599,3.74,3.74v3.084h-.0803v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm0-8.4302c-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103,.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="structure-39" data-name="structure">
                                            <rect x="752.8424" y="654.1592" width="3.8446" height="3.8446" transform="translate(1509.5295 1312.163) rotate(-180)" fill="#e9427d" />
                                        </g>
                                        <g id="name-54" data-name="name">
                                            <g id="BEF2268">
                                                <g>
                                                    <path d="M738.8155,661.6136c.375-.0791,.9678-.1377,1.5703-.1377,.8584,0,1.4111,.1475,1.8262,.4834,.3457,.2568,.5527,.6514,.5527,1.1748,0,.6416-.4248,1.2041-1.125,1.4609v.0205c.6318,.1572,1.3721,.6807,1.3721,1.668,0,.5723-.2275,1.0068-.5625,1.333-.4648,.4238-1.2148,.6221-2.3008,.6221-.5918,0-1.0469-.04-1.333-.0791v-6.5459Zm.8594,2.7246h.7793c.9092,0,1.4414-.4736,1.4414-1.1152,0-.7803-.5918-1.0859-1.4609-1.0859-.3945,0-.6221,.0293-.7598,.0596v2.1416Zm0,3.1895c.168,.0293,.4141,.0391,.7207,.0391,.8887,0,1.708-.3252,1.708-1.293,0-.9082-.7803-1.2832-1.7178-1.2832h-.7109v2.5371Z" fill="#fff" />
                                                    <path d="M747.6123,665.0589h-2.5859v2.3994h2.8828v.7207h-3.7422v-6.6543h3.5938v.7207h-2.7344v2.1035h2.5859v.71Z" fill="#fff" />
                                                    <path d="M749.0244,661.5247h3.584v.7207h-2.7246v2.2119h2.5176v.7109h-2.5176v3.0107h-.8594v-6.6543Z" fill="#fff" />
                                                    <path d="M753.5274,668.179v-.5332l.6807-.6611c1.6396-1.5605,2.3799-2.3896,2.3896-3.3564,0-.6523-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8652,0,1.1855-.8594,2.1426-2.2119,3.4463l-.5137,.4736v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                    <path d="M758.5928,668.179v-.5332l.6807-.6611c1.6396-1.5605,2.3799-2.3896,2.3896-3.3564,0-.6523-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8652,0,1.1855-.8594,2.1426-2.2119,3.4463l-.5137,.4736v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                    <path d="M767.3194,662.3743c-.1768-.0098-.4043,0-.6514,.0391-1.3623,.2275-2.083,1.2246-2.2305,2.2803h.0293c.3057-.4043,.8389-.7402,1.5498-.7402,1.1357,0,1.9355,.8193,1.9355,2.0732,0,1.1748-.7998,2.2607-2.1328,2.2607-1.3721,0-2.2705-1.0664-2.2705-2.7344,0-1.2637,.4541-2.2607,1.0859-2.8926,.5332-.5234,1.2441-.8496,2.0537-.9482,.2559-.0391,.4736-.0488,.6309-.0488v.7109Zm-.2461,3.7021c0-.918-.5234-1.4717-1.3232-1.4717-.5234,0-1.0068,.3262-1.2441,.79-.0586,.0986-.0986,.2275-.0986,.3848,.0195,1.0566,.5039,1.8369,1.4121,1.8369,.75,0,1.2539-.6221,1.2539-1.54Z" fill="#fff" />
                                                    <path d="M768.6436,666.5501c0-.8096,.4834-1.3818,1.2734-1.7178l-.0098-.0293c-.7109-.3359-1.0166-.8887-1.0166-1.4414,0-1.0176,.8584-1.708,1.9844-1.708,1.2441,0,1.8652,.7793,1.8652,1.5791,0,.543-.2666,1.126-1.0557,1.501v.0293c.7998,.3164,1.293,.8789,1.293,1.6592,0,1.1152-.958,1.8652-2.1816,1.8652-1.3428,0-2.1523-.7998-2.1523-1.7373Zm3.4551-.0391c0-.7803-.543-1.1553-1.4111-1.4023-.751,.2168-1.1553,.7109-1.1553,1.3232-.0293,.6514,.4639,1.2236,1.2832,1.2236,.7803,0,1.2832-.4834,1.2832-1.1445Zm-2.3887-3.209c0,.6416,.4834,.9873,1.2236,1.1846,.5537-.1875,.9775-.582,.9775-1.165,0-.5137-.3057-1.0459-1.0859-1.0459-.7207,0-1.1152,.4736-1.1152,1.0264Z" fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="signal-BEF2266">
                                        <g id="signal-line-2" data-name="signal-line">
                                            <line x1="628.8098" y1="709.1773" x2="526.5607" y2="736.4419" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="signal-point-2" data-name="signal-point">
                                            <circle cx="628.8098" cy="709.0457" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="base-55" data-name="base">
                                            <rect x="482.8901" y="729.6127" width="44.5375" height="13.6586" fill="#b2b2b2" />
                                        </g>
                                        <g id="head-40" data-name="head">
                                            <path d="M499.7688,699.971v6.6899s0,15.1301,0,15.1301c0,2.21,1.42,4.01,3.17,4.01h5.06c1.41,0,2.55-1.4399,2.55-3.23v-15.9102s0-6.8301,0-6.8301c0-1.5398-.98-2.7798-2.19-2.7798h-2.14v-.01h-1.57s0,.01,0,.01h-2.58c-1.27,0-2.3,1.3101-2.3,2.9199Zm6.11,17.1001c1.01,.24,1.83,1.0701,2.08,2.0801,.52,2.1499-1.37,4.0298-3.52,3.52-1.01-.25-1.84-1.0801-2.08-2.0901-.52-2.1501,1.37-4.03,3.52-3.51Zm0-8.9199c1.01,.25,1.83,1.0798,2.08,2.0898,.52,2.1401-1.37,4.03-3.52,3.51-1.01-.24-1.84-1.0701-2.08-2.0798-.52-2.1501,1.37-4.03,3.52-3.52Zm-1.4399-2.8301c-1.01-.2402-1.84-1.0701-2.08-2.0801-.52-2.1499,1.37-4.03,3.52-3.51,1.01,.24,1.83,1.0701,2.08,2.0798,.52,2.1501-1.37,4.0303-3.52,3.5103Z" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="structure-40" data-name="structure">
                                            <rect x="503.2365" y="725.7681" width="3.8446" height="3.8446" transform="translate(1010.3177 1455.3808) rotate(-180)" fill="#b2b2b2" />
                                        </g>
                                        <g id="name-55" data-name="name">
                                            <g id="BEF2266">
                                                <g>
                                                    <path d="M488.3692,733.6693c.375-.0791,.9678-.1377,1.5703-.1377,.8584,0,1.4111,.1475,1.8262,.4834,.3457,.2568,.5527,.6514,.5527,1.1748,0,.6416-.4248,1.2041-1.125,1.4609v.0205c.6318,.1572,1.3721,.6807,1.3721,1.668,0,.5723-.2275,1.0068-.5625,1.333-.4648,.4238-1.2148,.6221-2.3008,.6221-.5918,0-1.0469-.04-1.333-.0791v-6.5459Zm.8594,2.7246h.7793c.9092,0,1.4414-.4736,1.4414-1.1152,0-.7803-.5918-1.0859-1.4609-1.0859-.3945,0-.6221,.0293-.7598,.0596v2.1416Zm0,3.1895c.168,.0293,.4141,.0391,.7207,.0391,.8887,0,1.708-.3252,1.708-1.293,0-.9082-.7803-1.2832-1.7178-1.2832h-.7109v2.5371Z" fill="#fff" />
                                                    <path d="M497.166,737.1146h-2.5859v2.3994h2.8828v.7207h-3.7422v-6.6543h3.5938v.7207h-2.7344v2.1035h2.5859v.71Z" fill="#fff" />
                                                    <path d="M498.5782,733.5804h3.584v.7207h-2.7246v2.2119h2.5176v.7109h-2.5176v3.0107h-.8594v-6.6543Z" fill="#fff" />
                                                    <path d="M503.0811,740.2347v-.5332l.6807-.6611c1.6396-1.5605,2.3799-2.3896,2.3896-3.3564,0-.6523-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8652,0,1.1855-.8594,2.1426-2.2119,3.4463l-.5137,.4736v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                    <path d="M508.1465,740.2347v-.5332l.6807-.6611c1.6396-1.5605,2.3799-2.3896,2.3896-3.3564,0-.6523-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8652,0,1.1855-.8594,2.1426-2.2119,3.4463l-.5137,.4736v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                    <path d="M516.8731,734.43c-.1768-.0098-.4043,0-.6514,.0391-1.3623,.2275-2.083,1.2246-2.2305,2.2803h.0293c.3057-.4043,.8389-.7402,1.5498-.7402,1.1357,0,1.9355,.8193,1.9355,2.0732,0,1.1748-.7998,2.2607-2.1328,2.2607-1.3721,0-2.2705-1.0664-2.2705-2.7344,0-1.2637,.4541-2.2607,1.0859-2.8926,.5332-.5234,1.2441-.8496,2.0537-.9482,.2559-.0391,.4736-.0488,.6309-.0488v.7109Zm-.2461,3.7021c0-.918-.5234-1.4717-1.3232-1.4717-.5234,0-1.0068,.3262-1.2441,.79-.0586,.0986-.0986,.2275-.0986,.3848,.0195,1.0566,.5039,1.8369,1.4121,1.8369,.75,0,1.2539-.6221,1.2539-1.54Z" fill="#fff" />
                                                    <path d="M521.9395,734.43c-.1777-.0098-.4053,0-.6523,.0391-1.3623,.2275-2.083,1.2246-2.2305,2.2803h.0293c.3057-.4043,.8389-.7402,1.5498-.7402,1.1357,0,1.9346,.8193,1.9346,2.0732,0,1.1748-.7988,2.2607-2.1318,2.2607-1.3721,0-2.2705-1.0664-2.2705-2.7344,0-1.2637,.4541-2.2607,1.0859-2.8926,.5332-.5234,1.2441-.8496,2.0527-.9482,.2568-.0391,.4746-.0488,.6328-.0488v.7109Zm-.2471,3.7021c0-.918-.5234-1.4717-1.3232-1.4717-.5234,0-1.0068,.3262-1.2441,.79-.0586,.0986-.0986,.2275-.0986,.3848,.0195,1.0566,.5039,1.8369,1.4121,1.8369,.75,0,1.2539-.6221,1.2539-1.54Z" fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="signal-BEF2268OFF">
                                        <g id="point-39" data-name="point">
                                            <circle cx="677.315" cy="696.3368" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="line-39" data-name="line">
                                            <line x1="677.0279" y1="696.9638" x2="714.0289" y2="646.7432" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="structure-41" data-name="structure">
                                            <rect x="712.8194" y="635.6476" width="5.6201" height="4.888" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="base-56" data-name="base">
                                            <rect x="695.5284" y="624.2656" width="42.2109" height="11.382" fill="#e9427d" />
                                            <rect x="708.2394" y="640.5356" width="14.02" height="8.584" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-56" data-name="name">
                                            <g>
                                                <path d="M698.876,633.137v-.5332l.6807-.6611c1.6396-1.5596,2.3799-2.3887,2.3896-3.3564,0-.6514-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8662,0,1.1846-.8594,2.1416-2.2119,3.4453l-.5137,.4736v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                <path d="M703.9414,633.137v-.5332l.6807-.6611c1.6396-1.5596,2.3799-2.3887,2.3896-3.3564,0-.6514-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8662,0,1.1846-.8594,2.1416-2.2119,3.4453l-.5137,.4736v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                <path d="M712.669,627.3323c-.1777-.0098-.4053,0-.6523,.0391-1.3623,.2275-2.083,1.2246-2.2305,2.2812h.0293c.3057-.4053,.8389-.7412,1.5498-.7412,1.1357,0,1.9346,.8203,1.9346,2.0732,0,1.1748-.7988,2.2607-2.1318,2.2607-1.3721,0-2.2705-1.0654-2.2705-2.7344,0-1.2637,.4541-2.2607,1.0859-2.8926,.5332-.5234,1.2441-.8486,2.0527-.9473,.2568-.04,.4746-.0498,.6328-.0498v.7109Zm-.2471,3.7021c0-.918-.5234-1.4707-1.3232-1.4707-.5234,0-1.0068,.3252-1.2441,.7891-.0586,.0996-.0986,.2275-.0986,.3857,.0195,1.0557,.5039,1.8359,1.4121,1.8359,.75,0,1.2539-.6221,1.2539-1.54Z" fill="#fff" />
                                                <path d="M713.9922,631.5081c0-.8096,.4834-1.3818,1.2734-1.7178l-.0098-.0293c-.7109-.3359-1.0166-.8887-1.0166-1.4414,0-1.0166,.8584-1.708,1.9844-1.708,1.2441,0,1.8652,.7803,1.8652,1.5791,0,.543-.2666,1.126-1.0557,1.501v.0293c.7998,.3164,1.293,.8789,1.293,1.6592,0,1.1152-.958,1.8652-2.1816,1.8652-1.3428,0-2.1523-.7988-2.1523-1.7373Zm3.4551-.0391c0-.7803-.543-1.1553-1.4111-1.4023-.751,.2178-1.1553,.7109-1.1553,1.3232-.0293,.6514,.4639,1.2236,1.2832,1.2236,.7803,0,1.2832-.4834,1.2832-1.1445Zm-2.3887-3.209c0,.6416,.4834,.9873,1.2236,1.1846,.5537-.1875,.9775-.582,.9775-1.165,0-.5127-.3057-1.0459-1.0859-1.0459-.7207,0-1.1152,.4736-1.1152,1.0264Z" fill="#fff" />
                                                <path d="M725.1289,629.7415c0,2.29-1.3916,3.5039-3.0898,3.5039-1.7568,0-2.9912-1.3623-2.9912-3.376,0-2.1123,1.3125-3.4951,3.0898-3.4951,1.8164,0,2.9912,1.3926,2.9912,3.3672Zm-5.1631,.1084c0,1.4219,.7705,2.6953,2.123,2.6953,1.3623,0,2.1318-1.2539,2.1318-2.7646,0-1.3232-.6904-2.7051-2.1221-2.7051-1.4219,0-2.1328,1.3135-2.1328,2.7744Z" fill="#fff" />
                                                <path d="M726.2451,626.4837h3.584v.7207h-2.7246v2.2109h2.5176v.7109h-2.5176v3.0107h-.8594v-6.6533Z" fill="#fff" />
                                                <path d="M731.0537,626.4837h3.584v.7207h-2.7246v2.2109h2.5176v.7109h-2.5176v3.0107h-.8594v-6.6533Z" fill="#fff" />
                                            </g>
                                            <g>
                                                <path d="M713.5752,644.9114c0,1.7197-1.0459,2.6318-2.3213,2.6318-1.3193,0-2.2471-1.0234-2.2471-2.5361,0-1.5869,.9863-2.625,2.3213-2.625,1.3643,0,2.2471,1.0459,2.2471,2.5293Zm-3.8779,.0811c0,1.0684,.5781,2.0244,1.5938,2.0244,1.0234,0,1.6025-.9414,1.6025-2.0762,0-.9941-.5195-2.0322-1.5947-2.0322-1.0684,0-1.6016,.9863-1.6016,2.084Z" fill="#fff" />
                                                <path d="M714.4121,642.4642h2.6924v.541h-2.0469v1.6611h1.8906v.5342h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                                <path d="M718.0235,642.4642h2.6924v.541h-2.0469v1.6611h1.8906v.5342h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="signal-BEF2271OFF">
                                        <g id="point-40" data-name="point">
                                            <circle cx="628.8098" cy="693.4225" r="3.2673" fill="#e2e1e0" />
                                        </g>
                                        <g id="line-40" data-name="line">
                                            <line x1="628.8098" y1="693.4225" x2="564.7773" y2="685.935" fill="none" stroke="#e2e1e0" strokeMiterlimit="10" />
                                        </g>
                                        <g id="structure-42" data-name="structure">
                                            <rect x="555.3373" y="675.2423" width="5.6201" height="4.888" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="base-57" data-name="base">
                                            <rect x="538.0463" y="663.8603" width="42.2109" height="11.382" fill="#b2b2b2" opacity=".5" />
                                            <rect x="550.7573" y="680.1303" width="14.02" height="8.584" fill="#b2b2b2" opacity=".5" />
                                        </g>
                                        <g id="name-57" data-name="name">
                                            <g>
                                                <path d="M541.3936,672.7318v-.5332l.6807-.6611c1.6396-1.5596,2.3799-2.3896,2.3896-3.3564,0-.6523-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8652,0,1.1855-.8594,2.1426-2.2119,3.4453l-.5137,.4746v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                <path d="M546.459,672.7318v-.5332l.6807-.6611c1.6396-1.5596,2.3799-2.3896,2.3896-3.3564,0-.6523-.3164-1.2539-1.2734-1.2539-.583,0-1.0664,.2959-1.3623,.543l-.2764-.6123c.4443-.375,1.0762-.6514,1.8164-.6514,1.3818,0,1.9648,.9473,1.9648,1.8652,0,1.1855-.8594,2.1426-2.2119,3.4453l-.5137,.4746v.0195h2.8828v.7207h-4.0967Z" fill="#fff" />
                                                <path d="M555.6895,666.3148v.5732l-2.7939,5.8438h-.8984l2.7842-5.6768v-.0195h-3.1396v-.7207h4.0479Z" fill="#fff" />
                                                <path d="M558.4746,667.1243h-.0195l-1.1162,.6016-.168-.6611,1.4023-.75h.7402v6.417h-.8389v-5.6074Z" fill="#fff" />
                                                <path d="M567.6465,669.3353c0,2.291-1.3916,3.5049-3.0898,3.5049-1.7568,0-2.9912-1.3623-2.9912-3.376,0-2.1133,1.3125-3.4951,3.0898-3.4951,1.8164,0,2.9912,1.3916,2.9912,3.3662Zm-5.1631,.1094c0,1.4219,.7705,2.6953,2.123,2.6953,1.3623,0,2.1318-1.2539,2.1318-2.7646,0-1.3232-.6904-2.7051-2.1221-2.7051-1.4219,0-2.1328,1.3135-2.1328,2.7744Z" fill="#fff" />
                                                <path d="M568.7627,666.0775h3.584v.7207h-2.7246v2.2119h2.5176v.71h-2.5176v3.0117h-.8594v-6.6543Z" fill="#fff" />
                                                <path d="M573.5713,666.0775h3.584v.7207h-2.7246v2.2119h2.5176v.71h-2.5176v3.0117h-.8594v-6.6543Z" fill="#fff" />
                                            </g>
                                            <g>
                                                <path d="M556.0928,684.5052c0,1.7207-1.0459,2.6328-2.3213,2.6328-1.3193,0-2.2471-1.0234-2.2471-2.5361,0-1.5869,.9863-2.625,2.3213-2.625,1.3643,0,2.2471,1.0459,2.2471,2.5283Zm-3.8779,.082c0,1.0684,.5781,2.0244,1.5938,2.0244,1.0234,0,1.6025-.9424,1.6025-2.0771,0-.9932-.5195-2.0312-1.5947-2.0312-1.0684,0-1.6016,.9863-1.6016,2.084Z" fill="#fff" />
                                                <path d="M556.9297,682.0579h2.6924v.541h-2.0469v1.6621h1.8906v.5332h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                                <path d="M560.541,682.0579h2.6924v.541h-2.0469v1.6621h1.8906v.5332h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="feltham-signal-works-group" className={props.signallingGroupDisplayToggle}>
                            <g id="feltham-loc-psp-group" className={props.locRebPSPDisplayToggle}>
                                <g id="feltham-psp-group-2" data-name="feltham-psp-group">
                                    <g id="asp-001-2" data-name="asp-001">
                                        <g id="unit-16" data-name="unit">
                                            <rect x="206.6327" y="108.4634" width="26.6563" height="7.3337" transform="translate(160.9648 -127.6101) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="name-58" data-name="name">
                                            <g>
                                                <path d="M211.2305,109.8411l-2.7061,1.125-.772-.8945,8.6567-3.5098,.9014,1.043-4.7183,8.0664-.7983-.9219,1.4868-2.5352-2.0503-2.373Zm2.5718,1.6113l1.3594-2.3203c.3086-.5254,.6196-.9688,.9204-1.4023l-.0166-.0195c-.4829,.2441-.9844,.4863-1.5024,.709l-2.5024,1.0176,1.7417,2.0156Z" fill="#fff" />
                                                <path d="M214.3892,115.6243c.0967,.5938,.3872,1.3301,.9019,1.9258,.7637,.8848,1.6768,.998,2.3516,.4141,.6255-.541,.6743-1.207,.2407-2.4121-.5566-1.4258-.5742-2.5898,.3691-3.4062,1.0425-.9004,2.564-.7051,3.688,.5957,.5918,.6836,.8623,1.3184,.9502,1.7617l-.9102,.3711c-.0703-.3203-.2583-.9395-.7812-1.5449-.7896-.9141-1.6357-.7891-2.0923-.3945-.626,.541-.5815,1.2129-.1406,2.4473,.5415,1.5078,.4932,2.5566-.4893,3.4043-1.0332,.8945-2.5874,.9004-3.9512-.6777-.5581-.6445-.979-1.5137-1.0503-2.0762l.9136-.4082Z" fill="#fff" />
                                                <path d="M224.2813,115.8314c.4302,.3574,.9619,.8516,1.5625,1.5469,.7383,.8535,1.0796,1.6504,1.0664,2.3574-.0005,.6406-.2983,1.2812-.894,1.7949-.606,.5234-1.2373,.7578-1.876,.7188-.8818-.0352-1.7734-.5645-2.5024-1.4082-.2231-.2578-.4189-.5059-.5415-.748l-2.6807,2.3164-.7466-.8633,6.6123-5.7148Zm-2.48,3.6523c.1138,.2324,.2998,.4883,.5488,.7754,.9009,1.043,1.9561,1.2402,2.8794,.4414,.8838-.7637,.77-1.7578-.0537-2.7109-.3257-.377-.6045-.6387-.7729-.7539l-2.6016,2.248Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="psp-002-2" data-name="psp-002">
                                        <g id="unit-17" data-name="unit">
                                            <rect x="327.9403" y="266.3226" width="26.6563" height="7.3337" transform="translate(322.3831 -164.7539) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="name-59" data-name="name">
                                            <g>
                                                <path d="M336.1099,262.7239c.4302,.3574,.9624,.8516,1.5625,1.5449,.7383,.8545,1.0796,1.6523,1.0664,2.3574-.0005,.6426-.2983,1.2812-.894,1.7969-.606,.5234-1.2373,.7559-1.876,.7188-.8818-.0361-1.7734-.5664-2.5024-1.4102-.2231-.2578-.4189-.5059-.5415-.7461l-2.6807,2.3164-.7466-.8643,6.6123-5.7139Zm-2.48,3.6523c.1138,.2314,.2998,.4863,.5488,.7744,.9009,1.042,1.9561,1.2412,2.8794,.4424,.8838-.7637,.77-1.7588-.0537-2.7119-.3257-.377-.6045-.6396-.7729-.7539l-2.6016,2.249Z" fill="#fff" />
                                                <path d="M335.0108,272.6907c.0972,.5918,.3872,1.3301,.9023,1.9258,.7637,.8828,1.6768,.9961,2.3516,.4131,.6255-.54,.6743-1.208,.2402-2.4111-.5562-1.4268-.5742-2.5918,.3691-3.4062,1.0425-.9014,2.564-.7061,3.688,.5938,.5918,.6855,.8623,1.3184,.9502,1.7637l-.9102,.3711c-.0703-.3223-.2578-.9404-.7808-1.5459-.79-.9141-1.6362-.7891-2.0928-.3945-.626,.54-.5811,1.2139-.1401,2.4463,.541,1.5098,.4927,2.5566-.4897,3.4062-1.0332,.8926-2.5874,.9004-3.9512-.6777-.5581-.6465-.979-1.5137-1.0503-2.0771l.9136-.4072Z" fill="#fff" />
                                                <path d="M344.9024,272.8958c.4302,.3574,.9624,.8525,1.5625,1.5469,.7383,.8535,1.0801,1.6523,1.0664,2.3574-.0005,.6406-.2983,1.2812-.894,1.7949-.606,.5234-1.2373,.7578-1.876,.7197-.8818-.0361-1.7734-.5654-2.5024-1.4092-.2231-.2578-.4189-.5059-.5415-.748l-2.6807,2.3164-.7466-.8633,6.6123-5.7148Zm-2.48,3.6523c.1138,.2324,.2998,.4883,.5488,.7754,.9009,1.043,1.9561,1.2402,2.8794,.4424,.8838-.7637,.77-1.7588-.0537-2.7119-.3257-.377-.6045-.6387-.7729-.7539l-2.6016,2.248Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="psp-003-2" data-name="psp-003">
                                        <g id="unit-18" data-name="unit">
                                            <rect x="593.8884" y="980.198" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="name-60" data-name="name">
                                            <g>
                                                <path d="M598.9756,989.0052l-.9189,2.7822h-1.1807l3.0049-8.8447h1.3779l3.0186,8.8447h-1.2207l-.9453-2.7822h-3.1357Zm2.9004-.8926l-.8662-2.5449c-.1973-.5781-.3281-1.1025-.46-1.6143h-.0254c-.1318,.5244-.2764,1.0625-.4463,1.6006l-.8662,2.5586h2.6641Z" fill="#fff" />
                                                <path d="M605.416,990.3968c.5117,.3145,1.2598,.5771,2.0469,.5771,1.168,0,1.8506-.6172,1.8506-1.5088,0-.8271-.4727-1.2998-1.667-1.7588-1.4434-.5117-2.3359-1.2598-2.3359-2.5068,0-1.3779,1.1426-2.4014,2.8613-2.4014,.9053,0,1.5615,.21,1.9551,.4336l-.3145,.9316c-.2891-.1572-.8799-.4199-1.6797-.4199-1.208,0-1.667,.7217-1.667,1.3252,0,.8271,.5381,1.2334,1.7588,1.7061,1.4961,.5771,2.2568,1.2988,2.2568,2.5977,0,1.3652-1.0107,2.5459-3.0967,2.5459-.8535,0-1.7852-.249-2.2578-.5635l.2891-.958Z" fill="#fff" />
                                                <path d="M612.041,983.0482c.5518-.0918,1.2734-.1709,2.1914-.1709,1.1289,0,1.9551,.2627,2.4805,.7344,.4854,.4199,.7744,1.0635,.7744,1.8506,0,.8008-.2363,1.4307-.6826,1.8896-.6035,.6436-1.5879,.9717-2.7031,.9717-.3408,0-.6562-.0137-.9189-.0791v3.543h-1.1416v-8.7393Zm1.1416,4.2646c.25,.0654,.5645,.0918,.9453,.0918,1.3779,0,2.2178-.6689,2.2178-1.8896,0-1.168-.8271-1.7324-2.0869-1.7324-.498,0-.8789,.0391-1.0762,.0918v3.4385Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="feltham-loc-group-2" data-name="feltham-loc-group">
                                    <g id="loc-001">
                                        <g id="unit-19" data-name="unit">
                                            <rect x="166.6352" y="74.4338" width="26.6563" height="7.3337" transform="translate(121.3747 -109.1262) rotate(49.1637)" fill="#e9427d" />
                                        </g>
                                        <g id="name-61" data-name="name">
                                            <g>
                                                <path d="M174.0127,69.7982l.7466,.8643-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7236,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M179.3941,82.6458c-2.3037,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2295-4.1914,.7959-5.9424,2.124-1.8359,4.6558-1.7178,6.2007,.0703,1.5786,1.8262,1.1997,4.2178-.7856,5.9336Zm-4.5972-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6343-.2588,1.3301-1.1494,2.1196-3.0459,.8755-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M181.2056,91.2044c-.4331-.1807-1.1411-.6787-1.8447-1.4932-1.6304-1.8857-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0171-.2256-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-002-2" data-name="loc-002">
                                        <g id="unit-20" data-name="unit">
                                            <rect x="244.8421" y="147.6069" width="26.6563" height="7.3337" transform="translate(203.8035 -142.9709) rotate(49.1637)" fill="#e9427d" />
                                        </g>
                                        <g id="name-62" data-name="name">
                                            <g>
                                                <path d="M252.2198,142.972l.7466,.8633-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7227,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M257.6011,155.8196c-2.3037,1.9902-4.7344,1.6465-6.2109-.0625-1.5273-1.7656-1.2295-4.1914,.7959-5.9414,2.124-1.8359,4.6558-1.7188,6.2007,.0703,1.5786,1.8262,1.1997,4.2168-.7856,5.9336Zm-4.5972-5.0996c-1.4297,1.2363-2.041,3.1191-.8662,4.4785,1.1846,1.3711,3.1152,1.0547,4.6343-.2578,1.3301-1.1504,2.1196-3.0469,.8755-4.4863-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M259.4126,164.3782c-.4331-.1816-1.1411-.6797-1.8447-1.4941-1.6304-1.8848-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0332-.8887-1.5898-1.3555-1.5684-3.2598-1.7441-5.0171-.2266-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7715Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-003-2" data-name="loc-003">
                                        <g id="unit-21" data-name="unit">
                                            <rect x="384.8561" y="307.0773" width="26.6563" height="7.3337" transform="translate(372.9161 -193.7102) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="name-63" data-name="name">
                                            <g>
                                                <path d="M392.2744,302.4495l.7466,.8643-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7236,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M397.6558,315.2972c-2.3037,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2295-4.1914,.7959-5.9424,2.124-1.8359,4.6558-1.7178,6.2007,.0703,1.5786,1.8262,1.1997,4.2178-.7856,5.9336Zm-4.5972-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6343-.2588,1.3301-1.1494,2.1196-3.0459,.8755-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M399.4673,323.8558c-.4331-.1807-1.1411-.6787-1.8447-1.4932-1.6304-1.8857-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0171-.2256-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-004-2" data-name="loc-004">
                                        <g id="unit-22" data-name="unit">
                                            <rect x="473.9431" y="416.7823" width="26.6563" height="7.3337" transform="translate(486.7505 -223.1427) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="name-64" data-name="name">
                                            <g>
                                                <path d="M481.3653,412.1517l.7466,.8643-5.9668,5.1562,2.4712,2.8594-.7251,.627-3.2178-3.7236,6.6919-5.7832Z" fill="#fff" />
                                                <path d="M486.7466,424.9993c-2.3037,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2295-4.1914,.7959-5.9424,2.124-1.8359,4.6558-1.7178,6.2007,.0703,1.5786,1.8262,1.1997,4.2178-.7856,5.9336Zm-4.5972-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6343-.2588,1.3301-1.1494,2.1196-3.0459,.8755-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M488.5581,433.5579c-.4331-.1807-1.1411-.6787-1.8447-1.4932-1.6304-1.8857-1.666-4.334,.5283-6.2305,2.0947-1.8105,4.7412-1.6191,6.5352,.457,.7207,.834,.9971,1.5137,1.0752,1.8457l-.8857,.4004c-.124-.4648-.4077-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0171-.2256-1.6382,1.416-1.8745,3.2695-.4678,4.8965,.4551,.5273,1.0269,.9688,1.4961,1.1699l-.5308,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-005-2" data-name="loc-005">
                                        <g id="unit-23" data-name="unit">
                                            <rect x="600.1346" y="577.472" width="26.6563" height="7.3337" transform="translate(652.0008 -263.002) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="name-65" data-name="name">
                                            <g>
                                                <path d="M607.5557,572.8411l.7461,.8643-5.9668,5.1562,2.4717,2.8594-.7256,.627-3.2168-3.7236,6.6914-5.7832Z" fill="#fff" />
                                                <path d="M612.9366,585.6888c-2.3027,1.9912-4.7344,1.6475-6.2109-.0615-1.5273-1.7666-1.2285-4.1914,.7969-5.9424,2.123-1.8359,4.6553-1.7178,6.2002,.0703,1.5791,1.8262,1.2002,4.2178-.7861,5.9336Zm-4.5967-5.0986c-1.4297,1.2363-2.041,3.1182-.8662,4.4785,1.1846,1.3701,3.1152,1.0547,4.6348-.2588,1.3301-1.1494,2.1191-3.0459,.875-4.4854-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M614.7491,594.2474c-.4336-.1807-1.1416-.6787-1.8457-1.4932-1.6299-1.8857-1.666-4.334,.5293-6.2305,2.0938-1.8105,4.7402-1.6191,6.5352,.457,.7207,.834,.9961,1.5137,1.0742,1.8457l-.8848,.4004c-.125-.4648-.4082-1.0342-.8887-1.5908-1.3555-1.5684-3.2598-1.7441-5.0176-.2256-1.6387,1.416-1.875,3.2695-.4678,4.8965,.4551,.5273,1.0264,.9688,1.4961,1.1699l-.5303,.7705Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-006-2" data-name="loc-006">
                                        <g id="unit-24" data-name="unit">
                                            <rect x="657.7517" y="644.7794" width="26.6563" height="7.3337" transform="translate(722.8662 -283.2988) rotate(49.1639)" fill="#e9427d" />
                                        </g>
                                        <g id="name-66" data-name="name">
                                            <g>
                                                <path d="M665.1787,640.1497l.7461,.8633-5.9668,5.1562,2.4717,2.8594-.7256,.627-3.2168-3.7227,6.6914-5.7832Z" fill="#fff" />
                                                <path d="M670.5596,652.9974c-2.3027,1.9902-4.7344,1.6465-6.2109-.0625-1.5273-1.7656-1.2285-4.1914,.7969-5.9414,2.123-1.8359,4.6553-1.7188,6.2002,.0703,1.5791,1.8262,1.2002,4.2168-.7861,5.9336Zm-4.5967-5.0996c-1.4297,1.2363-2.041,3.1191-.8662,4.4785,1.1846,1.3711,3.1152,1.0547,4.6348-.2578,1.3301-1.1504,2.1191-3.0469,.875-4.4863-1.2354-1.4297-3.1748-1.0039-4.6436,.2656Z" fill="#fff" />
                                                <path d="M672.3721,661.556c-.4336-.1816-1.1416-.6797-1.8457-1.4941-1.6299-1.8848-1.666-4.334,.5293-6.2305,2.0938-1.8105,4.7402-1.6191,6.5352,.457,.7207,.834,.9961,1.5137,1.0742,1.8457l-.8848,.4004c-.125-.4648-.4082-1.0332-.8887-1.5898-1.3555-1.5684-3.2598-1.7441-5.0176-.2266-1.6387,1.416-1.875,3.2695-.4678,4.8965,.4551,.5273,1.0264,.9688,1.4961,1.1699l-.5303,.7715Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-007-2" data-name="loc-007">
                                        <g id="unit-25" data-name="unit">
                                            <rect x="682.1673" y="767.6442" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="name-67" data-name="name">
                                            <g>
                                                <path d="M685.3252,770.3929h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M698.5645,774.723c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M706.2246,778.9485c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-008-2" data-name="loc-008">
                                        <g id="unit-26" data-name="unit">
                                            <rect x="673.4552" y="858.9604" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="name-68" data-name="name">
                                            <g>
                                                <path d="M676.6123,861.7093h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M689.8516,866.0394c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M697.5118,870.265c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-009-2" data-name="loc-009">
                                        <g id="unit-27" data-name="unit">
                                            <rect x="686.7833" y="990.0727" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="name-69" data-name="name">
                                            <g>
                                                <path d="M689.9414,992.8216h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M703.1807,997.1517c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M710.8409,1001.3773c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-010-2" data-name="loc-010">
                                        <g id="unit-28" data-name="unit">
                                            <rect x="673.8661" y="1219.1162" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="name-70" data-name="name">
                                            <g>
                                                <path d="M677.0235,1221.8646h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M690.2627,1226.1946c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M697.9229,1230.4202c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-011-2" data-name="loc-011">
                                        <g id="unit-29" data-name="unit">
                                            <rect x="684.4201" y="1259.5711" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="name-71" data-name="name">
                                            <g>
                                                <path d="M687.5782,1262.3196h1.1416v7.8867h3.7793v.958h-4.9209v-8.8447Z" fill="#fff" />
                                                <path d="M700.8174,1266.6497c0,3.0449-1.8496,4.6592-4.1074,4.6592-2.3359,0-3.9756-1.8115-3.9756-4.4883,0-2.8076,1.7451-4.6455,4.1074-4.6455,2.4141,0,3.9756,1.8506,3.9756,4.4746Zm-6.8633,.1445c0,1.8896,1.0244,3.583,2.8213,3.583,1.8115,0,2.835-1.667,2.835-3.6748,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M708.4776,1270.8753c-.4199,.21-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7686,1.876-4.6455,4.6191-4.6455,1.1025,0,1.7979,.2363,2.0996,.3936l-.2754,.9326c-.4336-.2109-1.0498-.3682-1.7852-.3682-2.0732,0-3.4512,1.3252-3.4512,3.6484,0,2.165,1.2471,3.5557,3.3984,3.5557,.6963,0,1.4043-.1436,1.8633-.3672l.2363,.9053Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="loc-012-2" data-name="loc-012">
                                        <g id="unit-30" data-name="unit">
                                            <rect x="601.7349" y="1379.6254" width="26.6563" height="7.3337" fill="#e9427d" />
                                        </g>
                                        <g id="name-72" data-name="name">
                                            <g>
                                                <path d="M604.8926,1382.3743h1.1416v7.8867h3.7793v.957h-4.9209v-8.8438Z" fill="#fff" />
                                                <path d="M618.1319,1386.7044c0,3.0449-1.8496,4.6582-4.1074,4.6582-2.3359,0-3.9756-1.8105-3.9756-4.4883,0-2.8066,1.7451-4.6445,4.1074-4.6445,2.4141,0,3.9756,1.8496,3.9756,4.4746Zm-6.8633,.1445c0,1.8887,1.0244,3.582,2.8213,3.582,1.8115,0,2.835-1.666,2.835-3.6738,0-1.7578-.9189-3.5957-2.8213-3.5957-1.8896,0-2.835,1.7461-2.835,3.6875Z" fill="#fff" />
                                                <path d="M625.792,1390.929c-.4199,.2109-1.2598,.4199-2.3359,.4199-2.4932,0-4.3691-1.5742-4.3691-4.4746,0-2.7676,1.876-4.6445,4.6191-4.6445,1.1025,0,1.7979,.2363,2.0996,.3926l-.2754,.9336c-.4336-.2109-1.0498-.3691-1.7852-.3691-2.0732,0-3.4512,1.3262-3.4512,3.6484,0,2.166,1.2471,3.5566,3.3984,3.5566,.6963,0,1.4043-.1445,1.8633-.3672l.2363,.9043Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="feltham-signalling-equipment-table">
                                    <g id="feltham-psp-base-table-2" data-name="feltham-psp-base-table">
                                        <g id="table-group-5" data-name="table-group">
                                            <g id="asp-001-item-row-2" data-name="asp-001-item-row">
                                                <g id="item-base-box-23" data-name="item-base-box">
                                                    <g id="base-box-27" data-name="base-box">
                                                        <rect x="915.1562" y="200.481" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1004.4332" y="200.481" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-27" data-name="base-text">
                                                        <g>
                                                            <path d="M945.6758,209.3763c0,2.3193-1.4092,3.5479-3.1289,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1289-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7793,2.7285,2.1484,2.7285,1.3799,0,2.1592-1.2695,2.1592-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M950.8565,211.4954c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.79h-.0195c-.2305,.3896-.7402,.8994-1.5996,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8799v2.6787c0,.9199,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8799v3.5186Z" fill="#fff" />
                                                            <path d="M953.378,206.5882v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M955.5088,211.9153c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8701-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6289-1.4189,1.6689-1.4189,.4893,0,.9189,.1396,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2803-.9902-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9199,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6689Z" fill="#fff" />
                                                            <path d="M960.6475,206.5882v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M965.5772,212.8148l-.0703-.6104h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1396-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9785v1.8096c0,.4189,.0195,.8291,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0508-.1895,.0508-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M967.7178,209.2864c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.2891-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M977.4453,205.7181v5.8467c0,.4307,.0107,.9199,.04,1.25h-.7891l-.04-.8398h-.0205c-.2695,.54-.8594,.9492-1.6484,.9492-1.1699,0-2.0693-.9893-2.0693-2.458-.0098-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0205v-2.8887h.8789Zm-.8789,4.2275c0-.1094-.0107-.2598-.04-.3691-.1299-.5596-.6104-1.0195-1.2695-1.0195-.9092,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4297,1.7891,.5889,0,1.1289-.3896,1.2891-1.0498,.0293-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M979.8877,206.6175c.0098,.2998-.21,.54-.5596,.54-.3105,0-.5303-.2402-.5303-.54,0-.3096,.2305-.5488,.5498-.5488,.3301,0,.54,.2393,.54,.5488Zm-.9795,6.1973v-4.8379h.8789v4.8379h-.8789Z" fill="#fff" />
                                                            <path d="M981.2481,209.2864c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M990.9659,207.9769c-.0205,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6904,2.209-.4697,.4395-1.1484,.5801-1.7588,.5801-.5791,0-1.2188-.1406-1.6084-.4004l.2197-.6689c.3193,.1992,.8193,.3799,1.4189,.3799,.8994,0,1.5596-.4697,1.5596-1.6895v-.54h-.0205c-.2695,.4502-.7891,.8096-1.5391,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4395,1.5088,.8389h.0205l.04-.7295h.7695Zm-.9102,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9492,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.9004,.0498-.1494,.0693-.3193,.0693-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1028.1826,209.3763c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1033.3633,211.4954c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M1035.8848,206.5882v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1038.0157,211.9153c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M1043.1553,206.5882v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1048.084,212.8148l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M1050.2256,209.2864c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1059.9532,205.7181v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M1062.3946,206.6175c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M1063.7549,209.2864c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1073.4727,207.9769c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-23" data-name="item-type-box">
                                                    <g id="type-box-31" data-name="type-box">
                                                        <rect x="799.2835" y="200.481" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-27" data-name="type-text">
                                                        <g>
                                                            <path d="M815.6348,209.6566h-2.6191v2.4287h2.9189v.7295h-3.7881v-6.7363h3.6377v.7295h-2.7686v2.1289h2.6191v.7197Z" fill="#fff" />
                                                            <path d="M819.7149,212.8148l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M821.8565,209.4866c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4697-1.1797,1.1299-.0195,.1191-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M825.166,205.7181h.8799v7.0967h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M828.0157,210.556c.0205,1.1895,.7803,1.6787,1.6592,1.6787,.6299,0,1.0098-.1094,1.3398-.25l.1494,.6299c-.3096,.1396-.8398,.2998-1.6094,.2998-1.4883,0-2.3779-.9795-2.3779-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.0205,.3203-.0303,.4102h-3.3984Zm2.5791-.6299c.0098-.5596-.2305-1.4287-1.2197-1.4287-.8896,0-1.2793,.8193-1.3486,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M832.8555,207.9769l1.0596,2.8584c.1104,.3203,.2305,.7002,.3096,.9902h.0205c.0898-.29,.1895-.6602,.3096-1.0098l.96-2.8389h.9287l-1.3184,3.4482c-.6299,1.6592-1.0596,2.5088-1.6592,3.0283-.4297,.3799-.8604,.5303-1.0801,.5703l-.2197-.7402c.2197-.0693,.5098-.21,.7695-.4297,.2402-.1895,.54-.5293,.7402-.9795,.04-.0898,.0693-.1602,.0693-.21s-.0195-.1201-.0596-.2295l-1.7891-4.458h.959Z" fill="#fff" />
                                                            <path d="M840.4854,210.6956l-.6992,2.1191h-.8994l2.2881-6.7363h1.0498l2.2988,6.7363h-.9297l-.7197-2.1191h-2.3887Zm2.209-.6797l-.6602-1.9385c-.1494-.4404-.249-.8398-.3496-1.2295h-.0195c-.1006,.3994-.21,.8096-.3398,1.2188l-.6602,1.9492h2.0293Z" fill="#fff" />
                                                            <path d="M845.3965,211.7552c.3896,.2402,.959,.4395,1.5586,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3594-.9893-1.2686-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8691-1.8291,2.1787-1.8291,.6895,0,1.1895,.1602,1.4893,.3301l-.2402,.71c-.2197-.1201-.6689-.3203-1.2793-.3203-.9189,0-1.2695,.5498-1.2695,1.0098,0,.6299,.4102,.9395,1.3398,1.2988,1.1396,.4404,1.7188,.9893,1.7188,1.9795,0,1.0391-.7695,1.9385-2.3584,1.9385-.6494,0-1.3594-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M850.4463,206.1585c.4189-.0703,.9688-.1299,1.6689-.1299,.8594,0,1.4893,.1992,1.8887,.5596,.3701,.3193,.5898,.8096,.5898,1.4092,0,.6094-.1797,1.0889-.5195,1.4395-.46,.4893-1.21,.7393-2.0596,.7393-.2598,0-.499-.0098-.6992-.0605v2.6992h-.8691v-6.6562Zm.8691,3.248c.1895,.0498,.4297,.0693,.7197,.0693,1.0488,0,1.6885-.5088,1.6885-1.4385,0-.8896-.6289-1.3193-1.5889-1.3193-.3799,0-.6699,.0293-.8193,.0693v2.6191Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-23" data-name="item-id-box">
                                                    <g id="id-box-30" data-name="id-box">
                                                        <rect x="740.8862" y="200.481" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-26" data-name="id-text">
                                                        <g>
                                                            <path d="M763.4834,211.1165l-.4795,1.7295h-1.5791l2.0586-6.7363h1.999l2.0889,6.7363h-1.6396l-.5195-1.7295h-1.9287Zm1.709-1.1396l-.4199-1.4287c-.1201-.3994-.2393-.8994-.3398-1.2988h-.0195c-.1006,.3994-.2002,.9092-.3096,1.2988l-.4004,1.4287h1.4893Z" fill="#fff" />
                                                            <path d="M773.0323,209.5677c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6797,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7393,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.709-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M775.7237,207.6985h-.0205l-1.2393,.5898-.25-1.1396,1.7188-.7998h1.2598v6.4971h-1.4688v-5.1475Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="asp-002-item-row-2" data-name="asp-002-item-row">
                                                <g id="item-base-box-24" data-name="item-base-box">
                                                    <g id="base-box-28" data-name="base-box">
                                                        <rect x="915.1562" y="218.606" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1004.4332" y="218.606" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-28" data-name="base-text">
                                                        <g>
                                                            <path d="M945.6758,227.5013c0,2.3193-1.4092,3.5479-3.1289,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1289-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7793,2.7285,2.1484,2.7285,1.3799,0,2.1592-1.2695,2.1592-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M950.8565,229.6204c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.79h-.0195c-.2305,.3896-.7402,.8994-1.5996,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8799v2.6787c0,.9199,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8799v3.5186Z" fill="#fff" />
                                                            <path d="M953.378,224.7132v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M955.5088,230.0403c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8701-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6289-1.4189,1.6689-1.4189,.4893,0,.9189,.1396,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2803-.9902-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9199,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6689Z" fill="#fff" />
                                                            <path d="M960.6475,224.7132v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M965.5772,230.9398l-.0703-.6104h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1396-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9785v1.8096c0,.4189,.0195,.8291,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0508-.1895,.0508-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M967.7178,227.4114c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.2891-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M977.4453,223.8431v5.8467c0,.4307,.0107,.9199,.04,1.25h-.7891l-.04-.8398h-.0205c-.2695,.54-.8594,.9492-1.6484,.9492-1.1699,0-2.0693-.9893-2.0693-2.458-.0098-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0205v-2.8887h.8789Zm-.8789,4.2275c0-.1094-.0107-.2598-.04-.3691-.1299-.5596-.6104-1.0195-1.2695-1.0195-.9092,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4297,1.7891,.5889,0,1.1289-.3896,1.2891-1.0498,.0293-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M979.8877,224.7425c.0098,.2998-.21,.54-.5596,.54-.3105,0-.5303-.2402-.5303-.54,0-.3096,.2305-.5488,.5498-.5488,.3301,0,.54,.2393,.54,.5488Zm-.9795,6.1973v-4.8379h.8789v4.8379h-.8789Z" fill="#fff" />
                                                            <path d="M981.2481,227.4114c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M990.9659,226.1019c-.0205,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6904,2.209-.4697,.4395-1.1484,.5801-1.7588,.5801-.5791,0-1.2188-.1406-1.6084-.4004l.2197-.6689c.3193,.1992,.8193,.3799,1.4189,.3799,.8994,0,1.5596-.4697,1.5596-1.6895v-.54h-.0205c-.2695,.4502-.7891,.8096-1.5391,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4395,1.5088,.8389h.0205l.04-.7295h.7695Zm-.9102,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9492,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.9004,.0498-.1494,.0693-.3193,.0693-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1028.1826,227.5013c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1033.3633,229.6204c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M1035.8848,224.7132v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1038.0157,230.0403c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M1043.1553,224.7132v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1048.084,230.9398l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M1050.2256,227.4114c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1059.9532,223.8431v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M1062.3946,224.7425c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M1063.7549,227.4114c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1073.4727,226.1019c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-24" data-name="item-type-box">
                                                    <g id="type-box-32" data-name="type-box">
                                                        <rect x="799.2835" y="218.606" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-28" data-name="type-text">
                                                        <g>
                                                            <path d="M813.2461,230.9398l-1.709-6.7363h.9189l.7998,3.4082c.2002,.8389,.3799,1.6787,.5,2.3281h.0195c.1104-.6689,.3203-1.4688,.5498-2.3389l.8994-3.3975h.9102l.8193,3.418c.1895,.7998,.3691,1.5986,.4697,2.3086h.0195c.1406-.7393,.3301-1.4893,.54-2.3291l.8896-3.3975h.8896l-1.9092,6.7363h-.9092l-.8496-3.5088c-.21-.8594-.3506-1.5186-.4404-2.1982h-.0195c-.1201,.6699-.2695,1.3291-.5195,2.1982l-.96,3.5088h-.9092Z" fill="#fff" />
                                                            <path d="M821.3955,224.7425c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M822.7569,227.4114c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M828.3067,227.4114c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M834.3457,228.681c.0205,1.1895,.7803,1.6787,1.6592,1.6787,.6299,0,1.0098-.1094,1.3398-.25l.1494,.6299c-.3096,.1396-.8398,.2998-1.6094,.2998-1.4883,0-2.3779-.9795-2.3779-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.0205,.3203-.0303,.4102h-3.3984Zm2.5791-.6299c.0098-.5596-.2305-1.4287-1.2197-1.4287-.8896,0-1.2793,.8193-1.3486,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M838.8662,227.6116c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4697-1.1797,1.1299-.0195,.1191-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M842.0166,230.0403c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8701-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6289-1.4189,1.6689-1.4189,.4893,0,.9189,.1396,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2803-.9902-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9199,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6689Z" fill="#fff" />
                                                            <path d="M846.0957,223.8431h.8799v3.0186h.0195c.1406-.25,.3604-.4697,.6299-.6191,.2598-.1504,.5703-.25,.8994-.25,.6504,0,1.6895,.3994,1.6895,2.0684v2.8789h-.8799v-2.7783c0-.7803-.29-1.4395-1.1191-1.4395-.5693,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0596,.25-.0596,.4199v2.9189h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M854.666,224.9427h-2.0488v-.7393h4.9863v.7393h-2.0586v5.9971h-.8789v-5.9971Z" fill="#fff" />
                                                            <path d="M857.876,227.6116c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.75-1.0596,1.3398-1.0596,.0996,0,.1689,.0098,.249,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6191,0-1.0596,.4697-1.1787,1.1299-.0205,.1191-.04,.2598-.04,.4092v2.5791h-.8701v-3.3281Z" fill="#fff" />
                                                            <path d="M862.1651,224.7425c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M866.2051,230.9398l-.0703-.6104h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1396-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9785v1.8096c0,.4189,.0195,.8291,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0508-.1895,.0508-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M868.3457,227.4114c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M878.0635,226.1019c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                            <path d="M879.4854,223.8431h.8799v7.0967h-.8799v-7.0967Z" fill="#fff" />
                                                            <path d="M882.335,228.681c.0205,1.1895,.7803,1.6787,1.6592,1.6787,.6299,0,1.0098-.1094,1.3398-.25l.1494,.6299c-.3096,.1396-.8398,.2998-1.6094,.2998-1.4883,0-2.3779-.9795-2.3779-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.0205,.3203-.0303,.4102h-3.3984Zm2.5791-.6299c.0098-.5596-.2305-1.4287-1.2197-1.4287-.8896,0-1.2793,.8193-1.3486,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M889.0059,224.2835c.4189-.0703,.9688-.1299,1.6689-.1299,.8594,0,1.4893,.1992,1.8887,.5596,.3701,.3193,.5898,.8096,.5898,1.4092,0,.6094-.1797,1.0889-.5195,1.4395-.46,.4893-1.21,.7393-2.0596,.7393-.2598,0-.499-.0098-.6992-.0605v2.6992h-.8691v-6.6562Zm.8691,3.248c.1895,.0498,.4297,.0693,.7197,.0693,1.0488,0,1.6885-.5088,1.6885-1.4385,0-.8896-.6289-1.3193-1.5889-1.3193-.3799,0-.6699,.0293-.8193,.0693v2.6191Z" fill="#fff" />
                                                            <path d="M894.2051,229.8802c.3896,.2402,.96,.4395,1.5596,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3604-.9893-1.2695-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8701-1.8291,2.1787-1.8291,.6904,0,1.1895,.1602,1.4893,.3301l-.2393,.71c-.2197-.1201-.6699-.3203-1.2793-.3203-.9199,0-1.2695,.5498-1.2695,1.0098,0,.6299,.4102,.9395,1.3389,1.2988,1.1396,.4404,1.7197,.9893,1.7197,1.9795,0,1.0391-.7695,1.9385-2.3594,1.9385-.6494,0-1.3584-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M899.2559,224.2835c.4189-.0703,.9688-.1299,1.6689-.1299,.8594,0,1.4893,.1992,1.8887,.5596,.3701,.3193,.5898,.8096,.5898,1.4092,0,.6094-.1797,1.0889-.5195,1.4395-.46,.4893-1.21,.7393-2.0596,.7393-.2598,0-.499-.0098-.6992-.0605v2.6992h-.8691v-6.6562Zm.8691,3.248c.1895,.0498,.4297,.0693,.7197,.0693,1.0488,0,1.6885-.5088,1.6885-1.4385,0-.8896-.6289-1.3193-1.5889-1.3193-.3799,0-.6699,.0293-.8193,.0693v2.6191Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-24" data-name="item-id-box">
                                                    <g id="id-box-31" data-name="id-box">
                                                        <rect x="740.8862" y="218.606" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-27" data-name="id-text">
                                                        <g>
                                                            <path d="M763.4834,229.2415l-.4795,1.7295h-1.5791l2.0586-6.7363h1.999l2.0889,6.7363h-1.6396l-.5195-1.7295h-1.9287Zm1.709-1.1396l-.4199-1.4287c-.1201-.3994-.2393-.8994-.3398-1.2988h-.0195c-.1006,.3994-.2002,.9092-.3096,1.2988l-.4004,1.4287h1.4893Z" fill="#fff" />
                                                            <path d="M773.0323,227.6927c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6797,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7393,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.709-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M773.7842,230.971v-.9199l.8398-.7598c1.4189-1.2686,2.1084-1.998,2.1289-2.7578,0-.5303-.3203-.9502-1.0693-.9502-.5596,0-1.0498,.2803-1.3896,.54l-.4297-1.0889c.4893-.3701,1.249-.6699,2.1289-.6699,1.4688,0,2.2783,.8594,2.2783,2.0391,0,1.0889-.7891,1.959-1.7285,2.7979l-.5996,.5v.0195h2.4482v1.25h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="psp-003-item-row-2" data-name="psp-003-item-row">
                                                <g id="item-base-box-25" data-name="item-base-box">
                                                    <g id="base-box-29" data-name="base-box">
                                                        <rect x="915.1562" y="236.731" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="1004.4332" y="236.731" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-29" data-name="base-text">
                                                        <g>
                                                            <path d="M945.6758,245.6263c0,2.3193-1.4092,3.5479-3.1289,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1289-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7793,2.7285,2.1484,2.7285,1.3799,0,2.1592-1.2695,2.1592-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M950.8565,247.7454c0,.5,.0098,.9395,.04,1.3193h-.7803l-.0498-.79h-.0195c-.2305,.3896-.7402,.8994-1.5996,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8799v2.6787c0,.9199,.2793,1.5391,1.0791,1.5391,.5898,0,1-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8799v3.5186Z" fill="#fff" />
                                                            <path d="M953.378,242.8382v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M955.5088,248.1653c.2598,.1699,.7188,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8701-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6289-1.4189,1.6689-1.4189,.4893,0,.9189,.1396,1.1895,.2998l-.2197,.6396c-.1904-.1201-.54-.2803-.9902-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9199,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6602,1.4492-1.8096,1.4492-.5293,0-1.0195-.1299-1.3594-.3301l.2207-.6689Z" fill="#fff" />
                                                            <path d="M960.6475,242.8382v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M965.5772,249.0648l-.0703-.6104h-.0303c-.2695,.3799-.7891,.7197-1.4785,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0391-1.8096,2.9082-1.7988v-.1006c0-.3994-.1094-1.1191-1.0996-1.1191-.4492,0-.9189,.1396-1.2588,.3594l-.2002-.5791c.4004-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8398,1.0088,1.8398,1.9785v1.8096c0,.4189,.0195,.8291,.0791,1.1592h-.7988Zm-.1299-2.4688c-.96-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3789,.8398,.8291,.8398,.6299,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0508-.1895,.0508-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M967.7178,245.5364c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.2891-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M977.4453,241.9681v5.8467c0,.4307,.0107,.9199,.04,1.25h-.7891l-.04-.8398h-.0205c-.2695,.54-.8594,.9492-1.6484,.9492-1.1699,0-2.0693-.9893-2.0693-2.458-.0098-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0205v-2.8887h.8789Zm-.8789,4.2275c0-.1094-.0107-.2598-.04-.3691-.1299-.5596-.6104-1.0195-1.2695-1.0195-.9092,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4297,1.7891,.5889,0,1.1289-.3896,1.2891-1.0498,.0293-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M979.8877,242.8675c.0098,.2998-.21,.54-.5596,.54-.3105,0-.5303-.2402-.5303-.54,0-.3096,.2305-.5488,.5498-.5488,.3301,0,.54,.2393,.54,.5488Zm-.9795,6.1973v-4.8379h.8789v4.8379h-.8789Z" fill="#fff" />
                                                            <path d="M981.2481,245.5364c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M990.9659,244.2269c-.0205,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6904,2.209-.4697,.4395-1.1484,.5801-1.7588,.5801-.5791,0-1.2188-.1406-1.6084-.4004l.2197-.6689c.3193,.1992,.8193,.3799,1.4189,.3799,.8994,0,1.5596-.4697,1.5596-1.6895v-.54h-.0205c-.2695,.4502-.7891,.8096-1.5391,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8389,0,1.2988,.4395,1.5088,.8389h.0205l.04-.7295h.7695Zm-.9102,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8389,0-1.4385,.71-1.4385,1.8291,0,.9492,.4795,1.7393,1.4287,1.7393,.54,0,1.0293-.3398,1.2197-.9004,.0498-.1494,.0693-.3193,.0693-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1028.1826,245.6263c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M1033.3633,247.7454c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M1035.8848,242.8382v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1038.0157,248.1653c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M1043.1553,242.8382v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M1048.084,249.0648l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M1050.2256,245.5364c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M1059.9532,241.9681v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M1062.3946,242.8675c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M1063.7549,245.5364c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M1073.4727,244.2269c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-25" data-name="item-type-box">
                                                    <g id="type-box-33" data-name="type-box">
                                                        <rect x="799.2835" y="236.731" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-29" data-name="type-text">
                                                        <g>
                                                            <path d="M816.8535,248.845c-.3193,.1592-.959,.3193-1.7783,.3193-1.8994,0-3.3281-1.1992-3.3281-3.4082,0-2.1084,1.4287-3.5381,3.5176-3.5381,.8398,0,1.3691,.1797,1.5996,.2998l-.21,.71c-.3301-.1602-.7998-.2803-1.3594-.2803-1.5791,0-2.6289,1.0098-2.6289,2.7783,0,1.6494,.9502,2.709,2.5889,2.709,.5293,0,1.0693-.1104,1.4189-.2803l.1797,.6904Z" fill="#fff" />
                                                            <path d="M817.8868,245.7366c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4697-1.1797,1.1299-.0195,.1191-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M825.4346,246.6058c0,1.7891-1.2402,2.5684-2.4092,2.5684-1.3096,0-2.3184-.959-2.3184-2.4883,0-1.6191,1.0586-2.5684,2.3984-2.5684,1.3887,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4688-.79,1.4688-1.8789,0-.8193-.4092-1.8594-1.4492-1.8594-1.0391,0-1.4883,.96-1.4883,1.8789Z" fill="#fff" />
                                                            <path d="M826.8262,244.2269l.6396,2.459c.1396,.54,.2695,1.0391,.3594,1.5391h.0303c.1104-.4893,.2705-1.0098,.4297-1.5293l.79-2.4688h.7393l.75,2.4189c.1797,.5801,.3193,1.0898,.4297,1.5791h.0293c.0801-.4893,.21-.999,.3701-1.5693l.6895-2.4287h.8701l-1.5596,4.8379h-.7998l-.7393-2.3086c-.1699-.54-.3096-1.0195-.4297-1.5898h-.0195c-.1201,.5801-.2705,1.0801-.4404,1.5996l-.7793,2.2988h-.7998l-1.459-4.8379h.8994Z" fill="#fff" />
                                                            <path d="M834.8955,242.8382v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M837.1465,241.9681h.8789v3.0186h.0205c.1396-.25,.3594-.4697,.6299-.6191,.2598-.1504,.5693-.25,.8994-.25,.6494,0,1.6885,.3994,1.6885,2.0684v2.8789h-.8789v-2.7783c0-.7803-.29-1.4395-1.1191-1.4395-.5703,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0605,.25-.0605,.4199v2.9189h-.8789v-7.0967Z" fill="#fff" />
                                                            <path d="M847.0743,246.6058c0,1.7891-1.2393,2.5684-2.4082,2.5684-1.3096,0-2.3193-.959-2.3193-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3896,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4697-.79,1.4697-1.8789,0-.8193-.4102-1.8594-1.4492-1.8594s-1.4893,.96-1.4893,1.8789Z" fill="#fff" />
                                                            <path d="M848.1866,245.7366c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.749-1.0596,1.3389-1.0596,.0996,0,.1699,.0098,.25,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6201,0-1.0596,.4697-1.1797,1.1299-.0195,.1191-.04,.2598-.04,.4092v2.5791h-.8691v-3.3281Z" fill="#fff" />
                                                            <path d="M851.4961,245.5364c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M857.5362,246.806c.0195,1.1895,.7793,1.6787,1.6592,1.6787,.6299,0,1.0088-.1094,1.3389-.25l.1504,.6299c-.3105,.1396-.8398,.2998-1.6094,.2998-1.4893,0-2.3789-.9795-2.3789-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.0195,.3203-.0293,.4102h-3.3984Zm2.5781-.6299c.0107-.5596-.2295-1.4287-1.2188-1.4287-.8896,0-1.2793,.8193-1.3496,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M865.2959,246.9456l-.7002,2.1191h-.8994l2.2891-6.7363h1.0488l2.2988,6.7363h-.9297l-.7188-2.1191h-2.3887Zm2.208-.6797l-.6592-1.9385c-.1504-.4404-.25-.8398-.3496-1.2295h-.0205c-.0996,.3994-.21,.8096-.3398,1.2188l-.6592,1.9492h2.0283Z" fill="#fff" />
                                                            <path d="M870.2061,248.0052c.3896,.2402,.96,.4395,1.5596,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3604-.9893-1.2695-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8701-1.8291,2.1787-1.8291,.6904,0,1.1895,.1602,1.4893,.3301l-.2393,.71c-.2197-.1201-.6699-.3203-1.2793-.3203-.9199,0-1.2695,.5498-1.2695,1.0098,0,.6299,.4102,.9395,1.3389,1.2988,1.1396,.4404,1.7197,.9893,1.7197,1.9795,0,1.0391-.7695,1.9385-2.3594,1.9385-.6494,0-1.3584-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M875.2559,242.4085c.4199-.0703,.9697-.1299,1.6689-.1299,.8594,0,1.4893,.1992,1.8887,.5596,.3701,.3193,.5898,.8096,.5898,1.4092,0,.6094-.1797,1.0889-.5195,1.4395-.46,.4893-1.209,.7393-2.0586,.7393-.2598,0-.5-.0098-.7002-.0605v2.6992h-.8691v-6.6562Zm.8691,3.248c.1904,.0498,.4307,.0693,.7197,.0693,1.0498,0,1.6895-.5088,1.6895-1.4385,0-.8896-.6299-1.3193-1.5889-1.3193-.3799,0-.6699,.0293-.8203,.0693v2.6191Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-25" data-name="item-id-box">
                                                    <g id="id-box-32" data-name="id-box">
                                                        <rect x="740.8862" y="236.731" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-28" data-name="id-text">
                                                        <g>
                                                            <path d="M763.4834,247.3665l-.4795,1.7295h-1.5791l2.0586-6.7363h1.999l2.0889,6.7363h-1.6396l-.5195-1.7295h-1.9287Zm1.709-1.1396l-.4199-1.4287c-.1201-.3994-.2393-.8994-.3398-1.2988h-.0195c-.1006,.3994-.2002,.9092-.3096,1.2988l-.4004,1.4287h1.4893Z" fill="#fff" />
                                                            <path d="M773.0323,245.8177c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6797,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7998-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0107,1.4893,.3496,2.1982,.9395,2.1982,.5889,0,.9092-.7393,.9092-2.2188,0-1.4385-.3096-2.1982-.9199-2.1982-.5596,0-.9395,.709-.9287,2.2188Z" fill="#fff" />
                                                            <path d="M774.0537,247.6068c.2705,.1396,.8896,.3994,1.5098,.3994,.7891,0,1.1895-.3799,1.1895-.8691,0-.6396-.6396-.9297-1.3096-.9297h-.6201v-1.0898h.5898c.5098-.0098,1.1592-.1992,1.1592-.749,0-.3896-.3193-.6797-.959-.6797-.5293,0-1.0898,.2295-1.3594,.3896l-.3096-1.0996c.3896-.25,1.1689-.4893,2.0088-.4893,1.3896,0,2.1592,.7295,2.1592,1.6191,0,.6895-.3906,1.2295-1.1895,1.5088v.0205c.7793,.1396,1.4092,.7295,1.4092,1.5791,0,1.1494-1.0098,1.9883-2.6592,1.9883-.8389,0-1.5488-.2197-1.9287-.459l.3096-1.1396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="title-row-5" data-name="title-row">
                                                <g id="delivered-box">
                                                    <g id="base-box-30" data-name="base-box">
                                                        <rect x="915.1562" y="182.3008" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.0447" />
                                                        <rect x="1003.934" y="182.3008" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.0447" />
                                                    </g>
                                                    <g id="base-text-30" data-name="base-text">
                                                        <g>
                                                            <path d="M940.5713,187.9876c.5596-.0898,1.2891-.1396,2.0586-.1396,1.2793,0,2.1084,.2295,2.7588,.7197,.6992,.5195,1.1387,1.3496,1.1387,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7275-.709,.5898-1.7891,.8701-3.1084,.8701-.7891,0-1.3486-.0498-1.7285-.0996v-6.6172Zm1.5293,5.4971c.1299,.0303,.3398,.0303,.5293,.0303,1.3789,.0098,2.2783-.75,2.2783-2.3584,.0107-1.3994-.8096-2.1387-2.1182-2.1387-.3398,0-.5596,.0293-.6895,.0596v4.4072Z" fill="#fff" />
                                                            <path d="M948.6504,192.6751c.0498,.6299,.6699,.9297,1.3789,.9297,.5205,0,.9395-.0703,1.3496-.2002l.2002,1.0303c-.5,.1992-1.1094,.2998-1.7695,.2998-1.6592,0-2.6084-.96-2.6084-2.4893,0-1.2393,.7695-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2295,2.1787,2.4385,0,.2598-.0303,.4902-.0498,.5996h-3.1484Zm1.7891-1.0391c0-.3701-.1602-.9893-.8594-.9893-.6396,0-.8994,.5791-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M952.7608,187.5384h1.5195v7.0957h-1.5195v-7.0957Z" fill="#fff" />
                                                            <path d="M957.0801,188.388c0,.4199-.3203,.7598-.8193,.7598-.4805,0-.7998-.3398-.79-.7598-.0098-.4404,.3096-.7695,.7998-.7695,.4893,0,.7998,.3291,.8096,.7695Zm-1.5693,6.2461v-4.8867h1.5195v4.8867h-1.5195Z" fill="#fff" />
                                                            <path d="M959.3809,189.7474l.6592,2.2686c.1201,.4092,.21,.7998,.2803,1.1895h.0293c.0801-.4004,.1602-.7695,.2705-1.1895l.6289-2.2686h1.5996l-1.8193,4.8867h-1.5186l-1.7793-4.8867h1.6494Z" fill="#fff" />
                                                            <path d="M964.5703,192.6751c.0498,.6299,.6699,.9297,1.3789,.9297,.5205,0,.9395-.0703,1.3496-.2002l.2002,1.0303c-.5,.1992-1.1094,.2998-1.7695,.2998-1.6592,0-2.6084-.96-2.6084-2.4893,0-1.2393,.7695-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2295,2.1787,2.4385,0,.2598-.0303,.4902-.0498,.5996h-3.1484Zm1.7891-1.0391c0-.3701-.1602-.9893-.8594-.9893-.6396,0-.8994,.5791-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M968.6807,191.3558c0-.7197-.0195-1.1895-.04-1.6084h1.3096l.0498,.8994h.04c.25-.71,.8496-1.0098,1.3193-1.0098,.1396,0,.21,0,.3193,.0205v1.4287c-.1094-.0195-.2393-.04-.4092-.04-.5596,0-.9395,.2998-1.04,.7695-.0195,.0996-.0293,.2197-.0293,.3398v2.4785h-1.5195v-3.2783Z" fill="#fff" />
                                                            <path d="M973.5703,192.6751c.0498,.6299,.6699,.9297,1.3789,.9297,.5205,0,.9395-.0703,1.3496-.2002l.2002,1.0303c-.5,.1992-1.1094,.2998-1.7695,.2998-1.6592,0-2.6084-.96-2.6084-2.4893,0-1.2393,.7695-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2295,2.1787,2.4385,0,.2598-.0303,.4902-.0498,.5996h-3.1484Zm1.7891-1.0391c0-.3701-.1602-.9893-.8594-.9893-.6396,0-.8994,.5791-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M982.418,187.5384v5.6367c0,.5498,.0205,1.1299,.04,1.459h-1.3496l-.0693-.7197h-.0205c-.3096,.5498-.8994,.8301-1.5293,.8301-1.1592,0-2.0889-.9893-2.0889-2.5088-.0098-1.6494,1.0195-2.5986,2.1895-2.5986,.5996,0,1.0693,.21,1.2891,.5498h.0205v-2.6484h1.5186Zm-1.5186,4.2773c0-.0801-.0107-.1895-.0205-.2695-.0898-.4102-.4199-.75-.8896-.75-.6895,0-1.0488,.6201-1.0488,1.3896,0,.8291,.4092,1.3486,1.0391,1.3486,.4395,0,.7891-.2998,.8799-.7295,.0293-.1094,.04-.2197,.04-.3496v-.6396Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M1039.958,187.8978v2.5889h2.5088v-2.5889h1.5195v6.7363h-1.5195v-2.8184h-2.5088v2.8184h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M1051.4854,191.1966c0,2.208-1.3389,3.5479-3.3076,3.5479-1.999,0-3.1689-1.5098-3.1689-3.4287,0-2.0186,1.29-3.5273,3.2783-3.5273,2.0693,0,3.1982,1.5488,3.1982,3.4082Zm-4.8672,.0898c0,1.3193,.6201,2.248,1.6396,2.248,1.0293,0,1.6191-.9795,1.6191-2.2881,0-1.21-.5801-2.249-1.6299-2.249-1.0293,0-1.6289,.9795-1.6289,2.2891Z" fill="#fff" />
                                                            <path d="M1053.5078,189.1771h-1.8086v-1.2793h5.1768v1.2793h-1.8389v5.457h-1.5293v-5.457Z" fill="#fff" />
                                                            <path d="M1059.208,189.1771h-1.8096v-1.2793h5.1777v1.2793h-1.8389v5.457h-1.5293v-5.457Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="type-box-34" data-name="type-box">
                                                    <g id="type-box-35" data-name="type-box">
                                                        <rect x="799.2835" y="182.3008" width="115.8727" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-30" data-name="type-text">
                                                        <g>
                                                            <path d="M818.9893,194.6341v-6.7363h1.7783l1.3994,2.4688c.4004,.71,.7998,1.5488,1.0996,2.3086h.0303c-.1006-.8896-.1299-1.7988-.1299-2.8086v-1.9688h1.3994v6.7363h-1.5996l-1.4395-2.5986c-.3994-.7197-.8389-1.5889-1.1689-2.3779l-.0303,.0098c.04,.8896,.0605,1.8389,.0605,2.9385v2.0283h-1.3994Z" fill="#fff" />
                                                            <path d="M828.6172,194.6341l-.0898-.4893h-.0303c-.3193,.3896-.8193,.5996-1.3984,.5996-.9902,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1387-1.8789,2.8682-1.8691v-.0703c0-.2598-.1396-.6299-.8896-.6299-.5,0-1.0293,.1699-1.3496,.3701l-.2793-.9795c.3398-.1895,1.0088-.4297,1.8984-.4297,1.6289,0,2.1494,.96,2.1494,2.1094v1.6982c0,.4697,.0195,.9199,.0693,1.1895h-1.3691Zm-.1797-2.3086c-.7998-.0098-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5801,.5996,.5801,.3799,0,.6895-.25,.7891-.5596,.0205-.0801,.0303-.1699,.0303-.2598v-.5303Z" fill="#fff" />
                                                            <path d="M831.1192,191.306c0-.6094-.0205-1.1289-.04-1.5586h1.2793l.0693,.6592h.0303c.21-.3096,.6396-.7695,1.4795-.7695,.6289,0,1.1289,.3203,1.3389,.8301h.0205c.1797-.25,.3994-.4502,.6289-.5898,.2705-.1602,.5703-.2402,.9297-.2402,.9395,0,1.6494,.6602,1.6494,2.1191v2.8779h-1.4795v-2.6582c0-.71-.2295-1.1191-.7197-1.1191-.3496,0-.5996,.2393-.6992,.5293-.04,.1104-.0605,.2695-.0605,.3896v2.8584h-1.4785v-2.7383c0-.6201-.2197-1.0391-.7002-1.0391-.3896,0-.6191,.2998-.709,.5498-.0508,.1191-.0605,.2598-.0605,.3789v2.8486h-1.4785v-3.3281Z" fill="#fff" />
                                                            <path d="M840.8877,192.6751c.0508,.6299,.6699,.9297,1.3799,.9297,.5195,0,.9395-.0703,1.3486-.2002l.2002,1.0303c-.5,.1992-1.1094,.2998-1.7686,.2998-1.6592,0-2.6094-.96-2.6094-2.4893,0-1.2393,.7705-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2295,2.1787,2.4385,0,.2598-.0293,.4902-.0498,.5996h-3.1484Zm1.7891-1.0391c0-.3701-.1592-.9893-.8594-.9893-.6396,0-.8994,.5791-.9395,.9893h1.7988Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="id-box-33" data-name="id-box">
                                                    <g id="id-box_copy_12" data-name="id-box copy 12">
                                                        <rect x="740.8862" y="182.3008" width="58.3973" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text_copy_12" data-name="id-text copy 12">
                                                        <g>
                                                            <path d="M767.3692,187.929v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M768.6895,188.0189c.5596-.0898,1.2891-.1396,2.0596-.1396,1.2793,0,2.1084,.2295,2.7578,.7197,.7002,.5195,1.1396,1.3496,1.1396,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7275-.71,.5898-1.7891,.8701-3.1084,.8701-.79,0-1.3496-.0498-1.7295-.0996v-6.6172Zm1.5293,5.4971c.1299,.0303,.3398,.0303,.5303,.0303,1.3789,.0098,2.2783-.75,2.2783-2.3584,.0098-1.3994-.8096-2.1387-2.1191-2.1387-.3398,0-.5596,.0293-.6895,.0596v4.4072Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="main-title-5" data-name="main-title">
                                                <g id="feltham_ASP" data-name="feltham ASP">
                                                    <path d="M742.7735,168.9144h4.9414v1.499h-3.1064v1.8477h2.9023v1.4863h-2.9023v3.25h-1.835v-8.083Z" fill="#e9427d" />
                                                    <path d="M750.1289,174.6478c.0596,.7549,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2393,1.2354c-.5996,.2393-1.3311,.3604-2.123,.3604-1.9902,0-3.1299-1.1523-3.1299-2.9863,0-1.4883,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4746,2.6143,2.9258,0,.3125-.0361,.5879-.0605,.7207h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1875-1.0312-1.1875-.7676,0-1.0791,.6953-1.127,1.1875h2.1582Z" fill="#e9427d" />
                                                    <path d="M755.0616,168.4827h1.8232v8.5146h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M760.4004,169.4544v1.6787h1.3066v1.3428h-1.3066v2.123c0,.708,.168,1.0312,.7197,1.0312,.2275,0,.4072-.0234,.5391-.0469l.0127,1.3789c-.2402,.0957-.6719,.1562-1.1875,.1562-.5879,0-1.0801-.2051-1.3672-.5039-.3359-.3477-.5039-.9121-.5039-1.7402v-2.3984h-.7793v-1.3428h.7793v-1.2715l1.7871-.4072Z" fill="#e9427d" />
                                                    <path d="M762.7647,168.4827h1.8232v3.3467h.0244c.1914-.252,.4316-.4443,.7197-.6006,.2637-.1436,.6113-.2275,.9473-.2275,1.1631,0,2.0508,.8027,2.0508,2.5664v3.4297h-1.8232v-3.2256c0-.7676-.2637-1.2959-.9473-1.2959-.4795,0-.7793,.3125-.9111,.6367-.0488,.1191-.0605,.2754-.0605,.4072v3.4775h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M773.1319,176.9974l-.1084-.5879h-.0361c-.3838,.4688-.9834,.7207-1.6787,.7207-1.1875,0-1.8955-.8633-1.8955-1.7988,0-1.5234,1.3672-2.2559,3.4424-2.2432v-.084c0-.3115-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4443l-.3359-1.1758c.4082-.2285,1.2119-.5156,2.2793-.5156,1.9551,0,2.5781,1.1514,2.5781,2.5303v2.0391c0,.5635,.0244,1.1035,.084,1.4268h-1.6426Zm-.2158-2.7695c-.96-.0127-1.7031,.2148-1.7031,.9229,0,.4678,.3115,.6963,.7188,.6963,.4561,0,.8281-.3008,.9482-.6719,.0234-.0957,.0361-.2041,.0361-.3125v-.6348Z" fill="#e9427d" />
                                                    <path d="M776.1328,173.0042c0-.7314-.0234-1.3555-.0479-1.8711h1.5352l.084,.792h.0361c.252-.3721,.7676-.9238,1.7754-.9238,.7549,0,1.3555,.3838,1.6064,.9951h.0244c.2158-.2998,.4795-.54,.7559-.708,.3232-.1914,.6836-.2871,1.1152-.2871,1.127,0,1.9785,.791,1.9785,2.543v3.4531h-1.7744v-3.1895c0-.8516-.2764-1.3438-.8643-1.3438-.4189,0-.7188,.2881-.8389,.6357-.0479,.1318-.0723,.3232-.0723,.4678v3.4297h-1.7754v-3.2852c0-.7441-.2637-1.248-.8389-1.248-.4678,0-.7441,.3594-.8516,.6602-.0605,.1436-.0723,.3115-.0723,.4551v3.418h-1.7754v-3.9932Z" fill="#e9427d" />
                                                    <path d="M788.9366,169.0228c.5645-.0967,1.3555-.168,2.4707-.168,1.1279,0,1.9316,.2148,2.4707,.6465,.5156,.4082,.8633,1.0801,.8633,1.8711,0,.792-.2637,1.4639-.7432,1.9199-.624,.5879-1.5469,.8516-2.627,.8516-.2393,0-.4551-.0117-.623-.0371v2.8906h-1.8115v-7.9746Zm1.8115,3.6699c.1553,.0352,.3477,.0469,.6113,.0469,.9717,0,1.5713-.4912,1.5713-1.3184,0-.7441-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0352-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M795.8487,168.4827h1.8232v3.3467h.0244c.1914-.252,.4316-.4443,.7197-.6006,.2637-.1436,.6113-.2275,.9473-.2275,1.1631,0,2.0508,.8027,2.0508,2.5664v3.4297h-1.8232v-3.2256c0-.7676-.2637-1.2959-.9473-1.2959-.4795,0-.7793,.3125-.9111,.6367-.0488,.1191-.0605,.2754-.0605,.4072v3.4775h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M806.2149,176.9974l-.1074-.5879h-.0361c-.3838,.4688-.9834,.7207-1.6797,.7207-1.1865,0-1.8945-.8633-1.8945-1.7988,0-1.5234,1.3672-2.2559,3.4424-2.2432v-.084c0-.3115-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2041-1.6191,.4443l-.3359-1.1758c.4072-.2285,1.2109-.5156,2.2783-.5156,1.9551,0,2.5791,1.1514,2.5791,2.5303v2.0391c0,.5635,.0234,1.1035,.084,1.4268h-1.6436Zm-.2158-2.7695c-.959-.0127-1.7031,.2148-1.7031,.9229,0,.4678,.3115,.6963,.7197,.6963,.4561,0,.8271-.3008,.9473-.6719,.0244-.0957,.0361-.2041,.0361-.3125v-.6348Z" fill="#e9427d" />
                                                    <path d="M809.2051,175.4027c.3359,.2041,1.0312,.4443,1.5703,.4443,.5518,0,.7803-.1924,.7803-.4922s-.1797-.4434-.8633-.6719c-1.2119-.4082-1.6797-1.0674-1.668-1.7627,0-1.0908,.9355-1.9189,2.3867-1.9189,.6836,0,1.2959,.1562,1.6553,.3359l-.3232,1.2598c-.2646-.1445-.7676-.3359-1.2715-.3359-.4443,0-.6963,.1797-.6963,.4785,0,.2764,.2285,.4199,.9482,.6719,1.1152,.3848,1.583,.9473,1.5947,1.8115,0,1.0908-.8633,1.8955-2.543,1.8955-.7676,0-1.4512-.168-1.8945-.4082l.3242-1.3076Z" fill="#e9427d" />
                                                    <path d="M815.8282,174.6478c.0596,.7549,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2393,1.2354c-.5996,.2393-1.3311,.3604-2.123,.3604-1.9902,0-3.1299-1.1523-3.1299-2.9863,0-1.4883,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4746,2.6143,2.9258,0,.3125-.0361,.5879-.0605,.7207h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1875-1.0312-1.1875-.7676,0-1.0791,.6953-1.127,1.1875h2.1582Z" fill="#e9427d" />
                                                    <path d="M828.1621,170.7015h-3.0225l-.168,1.1992c.168-.0244,.3115-.0244,.5039-.0244,.7432,0,1.499,.168,2.0508,.5645,.5879,.3955,.9473,1.043,.9473,1.9551,0,1.4512-1.2471,2.7344-3.3467,2.7344-.9473,0-1.7383-.2168-2.1699-.4443l.3232-1.3672c.3477,.168,1.0557,.3838,1.7637,.3838,.7549,0,1.5586-.3594,1.5586-1.1875,0-.8027-.6357-1.2949-2.1943-1.2949-.4326,0-.7324,.0234-1.0557,.0723l.5156-4.0898h4.2939v1.499Z" fill="#e9427d" />
                                                    <path d="M835.0391,173.2435v1.2119h-3.1426v-1.2119h3.1426Z" fill="#e9427d" />
                                                    <path d="M838.6162,169.0228c.5645-.0967,1.3555-.168,2.4707-.168,1.1279,0,1.9316,.2148,2.4707,.6465,.5156,.4082,.8633,1.0801,.8633,1.8711,0,.792-.2637,1.4639-.7432,1.9199-.624,.5879-1.5469,.8516-2.627,.8516-.2393,0-.4551-.0117-.623-.0371v2.8906h-1.8115v-7.9746Zm1.8115,3.6699c.1553,.0352,.3477,.0469,.6113,.0469,.9717,0,1.5713-.4912,1.5713-1.3184,0-.7441-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0352-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M845.6485,175.1146c.4912,.252,1.2471,.5039,2.0264,.5039,.8398,0,1.2832-.3477,1.2832-.875,0-.5039-.3838-.792-1.3545-1.1396-1.3438-.4678-2.2188-1.2119-2.2188-2.3867,0-1.3799,1.1514-2.4346,3.0576-2.4346,.9121,0,1.583,.1914,2.0635,.4082l-.4082,1.4746c-.3242-.1562-.8994-.3838-1.6914-.3838-.791,0-1.1748,.3604-1.1748,.7803,0,.5156,.4561,.7422,1.499,1.1387,1.4268,.5273,2.0986,1.2715,2.0986,2.4102,0,1.3555-1.043,2.5078-3.2617,2.5078-.9238,0-1.835-.2402-2.291-.4922l.3721-1.5117Z" fill="#e9427d" />
                                                    <path d="M852.0684,169.0228c.5635-.0967,1.3555-.168,2.4707-.168,1.127,0,1.9307,.2148,2.4707,.6465,.5156,.4082,.8633,1.0801,.8633,1.8711,0,.792-.2637,1.4639-.7432,1.9199-.624,.5879-1.5479,.8516-2.627,.8516-.2402,0-.4561-.0117-.624-.0371v2.8906h-1.8105v-7.9746Zm1.8105,3.6699c.1562,.0352,.3477,.0469,.6123,.0469,.9707,0,1.5703-.4912,1.5703-1.3184,0-.7441-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0352-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M858.4405,177.4778l2.5664-8.6953h1.2119l-2.5664,8.6953h-1.2119Z" fill="#e9427d" />
                                                    <path d="M864.8946,174.9232l-.5752,2.0742h-1.8945l2.4697-8.083h2.3994l2.5068,8.083h-1.9678l-.623-2.0742h-2.3154Zm2.0518-1.3672l-.5039-1.7148c-.1445-.4805-.2881-1.0801-.4082-1.5596h-.0234c-.1201,.4795-.2402,1.0908-.3721,1.5596l-.4795,1.7148h1.7871Z" fill="#e9427d" />
                                                    <path d="M870.9444,175.1146c.4912,.252,1.2471,.5039,2.0264,.5039,.8398,0,1.2832-.3477,1.2832-.875,0-.5039-.3838-.792-1.3545-1.1396-1.3438-.4678-2.2188-1.2119-2.2188-2.3867,0-1.3799,1.1514-2.4346,3.0576-2.4346,.9121,0,1.583,.1914,2.0635,.4082l-.4082,1.4746c-.3242-.1562-.8994-.3838-1.6914-.3838-.791,0-1.1748,.3604-1.1748,.7803,0,.5156,.4561,.7422,1.499,1.1387,1.4268,.5273,2.0986,1.2715,2.0986,2.4102,0,1.3555-1.043,2.5078-3.2617,2.5078-.9238,0-1.835-.2402-2.291-.4922l.3721-1.5117Z" fill="#e9427d" />
                                                    <path d="M877.3633,169.0228c.5645-.0967,1.3555-.168,2.4707-.168,1.1279,0,1.9316,.2148,2.4707,.6465,.5156,.4082,.8633,1.0801,.8633,1.8711,0,.792-.2637,1.4639-.7432,1.9199-.624,.5879-1.5469,.8516-2.627,.8516-.2393,0-.4551-.0117-.623-.0371v2.8906h-1.8115v-7.9746Zm1.8115,3.6699c.1553,.0352,.3477,.0469,.6113,.0469,.9717,0,1.5713-.4912,1.5713-1.3184,0-.7441-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0352-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M892.6963,176.7698c-.3359,.168-1.0918,.3486-2.0752,.3486-2.7939,0-4.2334-1.7402-4.2334-4.043,0-2.7578,1.9668-4.293,4.4141-4.293,.9473,0,1.667,.1914,1.9902,.3594l-.3711,1.4512c-.3721-.1562-.8877-.2988-1.5352-.2988-1.4512,0-2.5791,.875-2.5791,2.6738,0,1.6191,.96,2.6387,2.5908,2.6387,.5518,0,1.1631-.1201,1.5234-.2637l.2754,1.4268Z" fill="#e9427d" />
                                                    <path d="M899.1289,175.1146c0,.7676,.0234,1.3916,.0479,1.8828h-1.583l-.084-.8271h-.0361c-.2285,.3604-.7803,.96-1.835,.96-1.1875,0-2.0635-.7441-2.0635-2.5547v-3.4424h1.835v3.1543c0,.8516,.2764,1.3672,.9121,1.3672,.5039,0,.791-.3477,.9111-.6357,.0479-.1074,.0605-.252,.0605-.3955v-3.4902h1.835v3.9814Z" fill="#e9427d" />
                                                    <path d="M900.5479,176.9974c.0234-.3828,.0479-1.0908,.0479-1.75v-6.7646h1.8232v3.3467h.0234c.3477-.5039,.96-.8281,1.7754-.8281,1.4033,0,2.4346,1.1631,2.4229,2.9629,0,2.1104-1.3438,3.166-2.6865,3.166-.6836,0-1.3438-.252-1.7637-.9473h-.0234l-.0723,.8145h-1.5469Zm1.8711-2.4941c0,.1201,.0117,.2285,.0361,.3242,.1191,.4912,.5391,.8633,1.0674,.8633,.7793,0,1.2588-.5996,1.2588-1.6553,0-.9111-.4072-1.6309-1.2588-1.6309-.4922,0-.9482,.3721-1.0674,.8994-.0244,.1074-.0361,.2158-.0361,.3359v.8633Z" fill="#e9427d" />
                                                    <path d="M909.6543,169.5013c0,.5039-.3838,.9121-.9834,.9121-.5752,0-.959-.4082-.9473-.9121-.0117-.5273,.3721-.9229,.959-.9229,.5879,0,.96,.3955,.9717,.9229Zm-1.8828,7.4961v-5.8643h1.8232v5.8643h-1.8232Z" fill="#e9427d" />
                                                    <path d="M915.461,176.8538c-.3232,.1436-.9355,.2646-1.6309,.2646-1.8955,0-3.1064-1.1523-3.1064-2.999,0-1.7148,1.1758-3.1182,3.3584-3.1182,.4795,0,1.0068,.084,1.3906,.2275l-.2871,1.3555c-.2158-.0957-.54-.1797-1.0195-.1797-.96,0-1.583,.6836-1.5713,1.6436,0,1.0781,.7197,1.6426,1.6074,1.6426,.4316,0,.7676-.0723,1.043-.1797l.2158,1.3428Z" fill="#e9427d" />
                                                    <path d="M916.4707,168.4827h1.8232v8.5146h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M921.1748,174.6478c.0596,.7549,.8037,1.1152,1.6553,1.1152,.623,0,1.127-.084,1.6191-.2402l.2393,1.2354c-.5996,.2393-1.3311,.3604-2.123,.3604-1.9902,0-3.1299-1.1523-3.1299-2.9863,0-1.4883,.9238-3.1309,2.9629-3.1309,1.8945,0,2.6143,1.4746,2.6143,2.9258,0,.3125-.0361,.5879-.0605,.7207h-3.7773Zm2.1465-1.248c0-.4434-.1914-1.1875-1.0312-1.1875-.7676,0-1.0791,.6953-1.127,1.1875h2.1582Z" fill="#e9427d" />
                                                    <path d="M926.0957,175.4027c.3359,.2041,1.0312,.4443,1.5703,.4443,.5518,0,.7803-.1924,.7803-.4922s-.1797-.4434-.8633-.6719c-1.2119-.4082-1.6797-1.0674-1.668-1.7627,0-1.0908,.9355-1.9189,2.3867-1.9189,.6836,0,1.2959,.1562,1.6553,.3359l-.3232,1.2598c-.2646-.1445-.7676-.3359-1.2715-.3359-.4443,0-.6963,.1797-.6963,.4785,0,.2764,.2285,.4199,.9482,.6719,1.1152,.3848,1.583,.9473,1.5947,1.8115,0,1.0908-.8633,1.8955-2.543,1.8955-.7676,0-1.4512-.168-1.8945-.4082l.3242-1.3076Z" fill="#e9427d" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="bubbles-group-5" data-name="bubbles-group">
                                            <g id="asp-001-bubble-2" data-name="asp-001-bubble">
                                                <g id="body-22" data-name="body">
                                                    <rect x="232.8121" y="92.7975" width="29.6397" height="2.0011" transform="translate(14.8891 220.0931) rotate(-49.2316)" fill="#e9427d" />
                                                    <circle cx="255.4447" cy="84.9438" r="12.3333" fill="#e9427d" />
                                                    <polygon points="231.2169 112.1302 247.0537 105.5901 238.3414 96.5476 231.2169 112.1302" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-22" data-name="id">
                                                    <g>
                                                        <path d="M248.3282,86.0443l-.8398,2.542h-1.0791l2.7461-8.083h1.2598l2.7588,8.083h-1.1152l-.8643-2.542h-2.8662Zm2.6504-.8164l-.791-2.3262c-.1807-.5273-.2998-1.0078-.4199-1.4756h-.0244c-.1201,.4795-.252,.9717-.4072,1.4639l-.792,2.3379h2.4346Z" fill="#fff" />
                                                        <path d="M259.1739,84.6048c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1191c0,2.0273,.624,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2588,1.5947-3.251,0-1.918-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M262.4395,81.7747h-.0234l-1.3555,.7305-.2041-.8027,1.7031-.9121h.8994v7.7959h-1.0195v-6.8115Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="psp-002-bubble-2" data-name="psp-002-bubble">
                                                <g id="body-23" data-name="body">
                                                    <rect x="350.3614" y="244.3817" width="29.6397" height="2.0011" transform="translate(-59.1248 361.719) rotate(-49.2316)" fill="#e9427d" />
                                                    <circle cx="372.994" cy="236.528" r="12.3333" fill="#e9427d" />
                                                    <polygon points="348.7662 263.7145 364.603 257.1743 355.8907 248.1318 348.7662 263.7145" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-23" data-name="id">
                                                    <g>
                                                        <path d="M365.8775,237.6282l-.8398,2.542h-1.0791l2.7461-8.083h1.2598l2.7588,8.083h-1.1152l-.8643-2.542h-2.8662Zm2.6504-.8154l-.791-2.3271c-.1807-.5273-.2998-1.0078-.4199-1.4756h-.0244c-.1201,.4805-.252,.9717-.4072,1.4639l-.792,2.3389h2.4346Z" fill="#fff" />
                                                        <path d="M376.7232,236.1888c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0264,.624,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                        <path d="M377.6988,240.1702v-.6475l.8271-.8037c1.9912-1.8945,2.8906-2.9023,2.9023-4.0771,0-.792-.3838-1.5234-1.5469-1.5234-.708,0-1.2959,.3594-1.6553,.6592l-.3359-.7432c.54-.4561,1.3076-.792,2.207-.792,1.6787,0,2.3867,1.1514,2.3867,2.2676,0,1.4385-1.0439,2.6025-2.6865,4.1855l-.624,.5752v.0244h3.502v.875h-4.9766Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="psp-003-bubble">
                                                <g id="body-24" data-name="body">
                                                    <circle cx="561.8146" cy="1009.5881" r="12.3333" fill="#e9427d" />
                                                    <polygon points="578.9393 1003.5603 573.4079 992.2877 590.4858 990.901 578.9393 1003.5603" fill="#e9427d" fillRule="evenodd" />
                                                    <rect x="554.4332" y="1002.3381" width="29.6397" height="2.0011" transform="translate(-441.0623 446.5618) rotate(-31.5724)" fill="#e9427d" />
                                                </g>
                                                <g id="id-24" data-name="id">
                                                    <g>
                                                        <path d="M554.6983,1010.6878l-.8398,2.543h-1.0791l2.7461-8.084h1.2598l2.7588,8.084h-1.1152l-.8643-2.543h-2.8662Zm2.6504-.8154l-.791-2.3271c-.1807-.5273-.2998-1.0068-.4199-1.4746h-.0244c-.1201,.4795-.252,.9717-.4072,1.4629l-.792,2.3389h2.4346Z" fill="#fff" />
                                                        <path d="M565.544,1009.2484c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.624,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M566.7715,1012.0189c.2998,.1924,.9951,.4922,1.7266,.4922,1.3555,0,1.7754-.8633,1.7627-1.5117-.0117-1.0908-.9951-1.5586-2.0146-1.5586h-.5879v-.792h.5879c.7676,0,1.7393-.3955,1.7393-1.3193,0-.623-.3955-1.1748-1.3672-1.1748-.624,0-1.2236,.2754-1.5596,.5156l-.2754-.7676c.4072-.2998,1.1992-.5996,2.0391-.5996,1.5352,0,2.2305,.9111,2.2305,1.8584,0,.8037-.4795,1.4873-1.4395,1.835v.0244c.96,.1924,1.7393,.9111,1.7393,2.0029,0,1.2471-.9717,2.3389-2.8428,2.3389-.875,0-1.6426-.2764-2.0264-.5283l.2881-.8154Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="feltham-loc-base-table-2" data-name="feltham-loc-base-table">
                                        <g id="table-group-6" data-name="table-group">
                                            <g id="loc-001-item-row-2" data-name="loc-001-item-row">
                                                <g id="item-base-box-26" data-name="item-base-box">
                                                    <g id="base-box-31" data-name="base-box">
                                                        <rect x="217.637" y="560.9752" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="560.9752" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-31" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,569.8704c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,571.9896c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,567.0823v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,572.4095c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,567.0823v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,573.3089l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,569.7806c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,566.2122v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,567.1116c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,569.7806c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,568.471c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,569.8704c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,571.9896c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,567.0823v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,572.4095c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,567.0823v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,573.3089l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,569.7806c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,566.2122v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,567.1116c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,569.7806c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,568.471c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-26" data-name="item-type-box">
                                                    <g id="type-box-36" data-name="type-box">
                                                        <rect x="101.7643" y="560.9752" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-31" data-name="type-text">
                                                        <g>
                                                            <path d="M118.1153,570.1507h-2.6187v2.4287h2.9185v.7295h-3.7876v-6.7363h3.6377v.7295h-2.7686v2.1289h2.6187v.7197Z" fill="#fff" />
                                                            <path d="M122.1958,573.3089l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M124.3369,569.9808c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M127.647,566.2122h.8794v7.0967h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M130.4966,571.0501c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M135.3365,568.471l1.0596,2.8584c.1099,.3203,.23,.7002,.3096,.9902h.02c.0903-.29,.1899-.6602,.3101-1.0098l.9595-2.8389h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5088-1.6592,3.0283-.4297,.3799-.8599,.5303-1.0796,.5703l-.2197-.7402c.2197-.0693,.5098-.21,.7695-.4297,.2397-.1895,.5396-.5293,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2295l-1.7891-4.458h.9595Z" fill="#fff" />
                                                            <path d="M146.584,573.0892c-.3198,.1592-.9595,.3193-1.7788,.3193-1.8989,0-3.3281-1.1992-3.3281-3.4082,0-2.1084,1.4292-3.5381,3.5181-3.5381,.8394,0,1.3691,.1797,1.5991,.2998l-.21,.71c-.3296-.1602-.7993-.2803-1.3594-.2803-1.5791,0-2.6284,1.0098-2.6284,2.7783,0,1.6494,.9497,2.709,2.5884,2.709,.5298,0,1.0698-.1104,1.4194-.2803l.1797,.6904Z" fill="#fff" />
                                                            <path d="M147.6465,566.2122h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M157.2442,571.9896c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M158.7066,569.9808c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0596,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M165.3247,573.1292c-.23,.1191-.7397,.2793-1.3892,.2793-1.4595,0-2.4087-.9893-2.4087-2.4688,0-1.4893,1.0195-2.5684,2.5986-2.5684,.5195,0,.9795,.1299,1.2192,.25l-.1997,.6797c-.21-.1201-.54-.2305-1.0195-.2305-1.1094,0-1.709,.8203-1.709,1.8291,0,1.1201,.7192,1.8096,1.6787,1.8096,.5,0,.8296-.1299,1.0796-.2402l.1499,.6602Z" fill="#fff" />
                                                            <path d="M166.3565,566.2122h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M174.0567,566.6624c.4395-.0898,1.0693-.1396,1.6689-.1396,.9292,0,1.5293,.1699,1.9487,.5498,.3398,.2998,.5298,.7588,.5298,1.2793,0,.8887-.5596,1.4785-1.269,1.7188v.0303c.5195,.1797,.8296,.6592,.9893,1.3584,.2197,.9395,.3799,1.5898,.5195,1.8496h-.8994c-.1099-.1904-.2598-.7695-.4497-1.6094-.1997-.9297-.5596-1.2793-1.3491-1.3096h-.8198v2.9189h-.8691v-6.6465Zm.8691,3.0684h.8896c.9297,0,1.519-.5098,1.519-1.2793,0-.8701-.6294-1.249-1.5488-1.2598-.4199,0-.7197,.04-.8599,.0801v2.459Z" fill="#fff" />
                                                            <path d="M183.8243,570.8499c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M187.6148,573.3089l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M193.9336,566.2122v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-26" data-name="item-id-box">
                                                    <g id="id-box-34" data-name="id-box">
                                                        <rect x="43.367" y="560.9752" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-29" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,566.6585h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,570.1165c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M77.479,568.2484h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-002-item-row-2" data-name="loc-002-item-row">
                                                <g id="item-base-box-27" data-name="item-base-box">
                                                    <g id="base-box-32" data-name="base-box">
                                                        <rect x="217.637" y="579.1002" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="579.1002" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-32" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,587.9954c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,590.1146c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,585.2073v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,590.5345c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,585.2073v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,591.4339l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,587.9056c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,584.3372v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,585.2366c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,587.9056c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,586.596c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,587.9954c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,590.1146c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,585.2073v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,590.5345c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,585.2073v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,591.4339l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,587.9056c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,584.3372v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,585.2366c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,587.9056c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,586.596c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-27" data-name="item-type-box">
                                                    <g id="type-box-37" data-name="type-box">
                                                        <rect x="101.7643" y="579.1002" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-32" data-name="type-text">
                                                        <g>
                                                            <path d="M118.1153,588.2757h-2.6187v2.4287h2.9185v.7295h-3.7876v-6.7363h3.6377v.7295h-2.7686v2.1289h2.6187v.7197Z" fill="#fff" />
                                                            <path d="M122.1958,591.4339l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M124.3369,588.1058c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M127.647,584.3372h.8794v7.0967h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M130.4966,589.1751c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M135.3365,586.596l1.0596,2.8584c.1099,.3203,.23,.7002,.3096,.9902h.02c.0903-.29,.1899-.6602,.3101-1.0098l.9595-2.8389h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5088-1.6592,3.0283-.4297,.3799-.8599,.5303-1.0796,.5703l-.2197-.7402c.2197-.0693,.5098-.21,.7695-.4297,.2397-.1895,.5396-.5293,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2295l-1.7891-4.458h.9595Z" fill="#fff" />
                                                            <path d="M141.7569,590.3743c.3896,.2402,.9595,.4395,1.5591,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3599-.9893-1.269-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1602,1.4893,.3301l-.2397,.71c-.2197-.1201-.6694-.3203-1.2793-.3203-.9194,0-1.2695,.5498-1.2695,1.0098,0,.6299,.4102,.9395,1.3394,1.2988,1.1396,.4404,1.7192,.9893,1.7192,1.9795,0,1.0391-.7695,1.9385-2.3589,1.9385-.6494,0-1.3589-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M147.8062,585.2073v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M152.7354,591.4339l-.0698-.6104h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M155.896,585.2073v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M159.126,585.2366c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M164.8643,588.9749c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M165.9766,587.9056c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-27" data-name="item-id-box">
                                                    <g id="id-box-35" data-name="id-box">
                                                        <rect x="43.367" y="579.1002" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-30" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,584.7835h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,588.2415c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M75.5401,591.5198v-.9189l.8394-.7598c1.4194-1.2695,2.1089-1.999,2.1289-2.7588,0-.5293-.3198-.9492-1.0693-.9492-.5601,0-1.0493,.2793-1.3892,.54l-.4302-1.0898c.4897-.3701,1.2495-.6699,2.1289-.6699,1.4692,0,2.2788,.8594,2.2788,2.0391,0,1.0898-.7896,1.959-1.729,2.7988l-.5996,.499v.0205h2.4487v1.249h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-003-item-row-2" data-name="loc-003-item-row">
                                                <g id="item-base-box-28" data-name="item-base-box">
                                                    <g id="base-box-33" data-name="base-box">
                                                        <rect x="217.637" y="597.2252" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="597.2252" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-33" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,606.1204c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,608.2396c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,603.3323v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,608.6595c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,603.3323v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,609.5589l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,606.0306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,602.4622v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,603.3616c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,606.0306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,604.721c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,606.1204c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,608.2396c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,603.3323v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,608.6595c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,603.3323v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,609.5589l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,606.0306c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,602.4622v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,603.3616c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,606.0306c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,604.721c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-28" data-name="item-type-box">
                                                    <g id="type-box-38" data-name="type-box">
                                                        <rect x="101.7643" y="597.2252" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-33" data-name="type-text">
                                                        <g>
                                                            <path d="M115.7266,609.5589l-1.709-6.7363h.9194l.7998,3.4082c.1997,.8389,.3794,1.6787,.4995,2.3281h.02c.1099-.6689,.3198-1.4688,.5498-2.3389l.8994-3.3975h.9097l.8193,3.418c.1899,.7998,.3696,1.5986,.4697,2.3086h.02c.1401-.7393,.3301-1.4893,.5396-2.3291l.8896-3.3975h.8896l-1.9092,6.7363h-.9092l-.8496-3.5088c-.21-.8594-.3501-1.5186-.4399-2.1982h-.02c-.1196,.6699-.2695,1.3291-.5195,2.1982l-.9595,3.5088h-.9097Z" fill="#fff" />
                                                            <path d="M123.8765,603.3616c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M125.2373,606.0306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M130.7871,606.0306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M136.8267,607.3001c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M141.3467,606.2308c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M144.4971,608.6595c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M148.5767,602.4622h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.29-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M157.1465,603.5618h-2.0488v-.7393h4.9868v.7393h-2.0586v5.9971h-.8794v-5.9971Z" fill="#fff" />
                                                            <path d="M160.357,606.2308c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0596,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M164.646,603.3616c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M168.6856,609.5589l-.0698-.6104h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M170.8267,606.0306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M180.5445,604.721c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                            <path d="M181.9663,602.4622h.8794v7.0967h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M184.816,607.3001c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M191.3662,608.4993c.3896,.2402,.9595,.4395,1.5591,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3599-.9893-1.269-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1602,1.4893,.3301l-.2397,.71c-.2197-.1201-.6694-.3203-1.2793-.3203-.9194,0-1.2695,.5498-1.2695,1.0098,0,.6299,.4102,.9395,1.3394,1.2988,1.1396,.4404,1.7192,.9893,1.7192,1.9795,0,1.0391-.7695,1.9385-2.3589,1.9385-.6494,0-1.3589-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M197.4156,603.3323v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M199.666,606.0306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-28" data-name="item-id-box">
                                                    <g id="id-box-36" data-name="id-box">
                                                        <rect x="43.367" y="597.2252" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-31" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,602.9085h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,606.3665c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M75.8096,608.1556c.27,.1406,.8896,.4004,1.5093,.4004,.7896,0,1.1895-.3799,1.1895-.8701,0-.6396-.6396-.9287-1.3091-.9287h-.6201v-1.0898h.5898c.5098-.0098,1.1592-.2002,1.1592-.75,0-.3896-.3198-.6797-.9595-.6797-.5293,0-1.0894,.2305-1.3589,.3906l-.3101-1.0996c.3896-.25,1.1694-.4902,2.0088-.4902,1.3896,0,2.1592,.7295,2.1592,1.6191,0,.6895-.3901,1.2295-1.1895,1.5098v.0195c.7793,.1396,1.4092,.7295,1.4092,1.5791,0,1.1494-1.0093,1.9893-2.6587,1.9893-.8394,0-1.5488-.2197-1.9287-.46l.3096-1.1396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-004-item-row-2" data-name="loc-004-item-row">
                                                <g id="item-base-box-29" data-name="item-base-box">
                                                    <g id="base-box-34" data-name="base-box">
                                                        <rect x="217.637" y="615.3502" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="615.3502" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-34" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,624.2454c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,626.3646c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,621.4573v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,626.7845c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,621.4573v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,627.6839l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,624.1556c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,620.5872v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,621.4866c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,624.1556c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,622.846c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,624.2454c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,626.3646c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,621.4573v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,626.7845c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,621.4573v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,627.6839l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,624.1556c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,620.5872v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,621.4866c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,624.1556c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,622.846c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-29" data-name="item-type-box">
                                                    <g id="type-box-39" data-name="type-box">
                                                        <rect x="101.7643" y="615.3502" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-34" data-name="type-text">
                                                        <g>
                                                            <path d="M115.7266,627.6839l-1.709-6.7363h.9194l.7998,3.4082c.1997,.8389,.3794,1.6787,.4995,2.3281h.02c.1099-.6689,.3198-1.4688,.5498-2.3389l.8994-3.3975h.9097l.8193,3.418c.1899,.7998,.3696,1.5986,.4697,2.3086h.02c.1401-.7393,.3301-1.4893,.5396-2.3291l.8896-3.3975h.8896l-1.9092,6.7363h-.9092l-.8496-3.5088c-.21-.8594-.3501-1.5186-.4399-2.1982h-.02c-.1196,.6699-.2695,1.3291-.5195,2.1982l-.9595,3.5088h-.9097Z" fill="#fff" />
                                                            <path d="M123.8765,621.4866c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M125.2373,624.1556c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M130.7871,624.1556c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M136.8267,625.4251c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M141.3467,624.3558c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M144.4971,626.7845c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M148.5767,620.5872h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.29-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M156.1568,626.6243c.3896,.2402,.9595,.4395,1.5591,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3599-.9893-1.269-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1602,1.4893,.3301l-.2397,.71c-.2197-.1201-.6694-.3203-1.2793-.3203-.9194,0-1.269,.5498-1.269,1.0098,0,.6299,.4097,.9395,1.3389,1.2988,1.1396,.4404,1.7192,.9893,1.7192,1.9795,0,1.0391-.7695,1.9385-2.3589,1.9385-.6494,0-1.3589-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M162.2061,621.4573v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M167.1353,627.6839l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M170.2964,621.4573v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M173.5259,621.4866c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M179.2642,625.2249c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M180.3765,624.1556c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-29" data-name="item-id-box">
                                                    <g id="id-box-37" data-name="id-box">
                                                        <rect x="43.367" y="615.3502" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-32" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,621.0335h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,624.4915c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M78.2285,627.7698v-1.5488h-2.8784v-.9893l2.4585-3.958h1.8589v3.8076h.7798v1.1396h-.7798v1.5488h-1.439Zm0-2.6885v-1.4395c0-.3896,.02-.7891,.0498-1.209h-.04c-.21,.4199-.3799,.7998-.5996,1.209l-.8696,1.4199v.0195h1.4595Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-005-item-row-2" data-name="loc-005-item-row">
                                                <g id="item-base-box-30" data-name="item-base-box">
                                                    <g id="base-box-35" data-name="base-box">
                                                        <rect x="217.637" y="633.4752" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="633.4752" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-35" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,642.3704c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,644.4896c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,639.5823v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,644.9095c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,639.5823v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,645.8089l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,642.2806c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,638.7122v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,639.6116c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,642.2806c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,640.971c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,642.3704c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,644.4896c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,639.5823v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,644.9095c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,639.5823v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,645.8089l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,642.2806c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,638.7122v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,639.6116c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,642.2806c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,640.971c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-30" data-name="item-type-box">
                                                    <g id="type-box-40" data-name="type-box">
                                                        <rect x="101.7643" y="633.4752" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-35" data-name="type-text">
                                                        <g>
                                                            <path d="M115.9068,639.8118h-2.0488v-.7393h4.9868v.7393h-2.0586v5.9971h-.8794v-5.9971Z" fill="#fff" />
                                                            <path d="M123.2349,643.3499c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M128.3951,644.4896c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M130.9165,639.5823v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M133.167,638.7122h.8794v7.0967h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M136.0166,643.5501c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M140.8565,640.971l1.0596,2.8584c.1099,.3203,.23,.7002,.3096,.9902h.02c.0903-.29,.1899-.6602,.3101-1.0098l.9595-2.8389h.9297l-1.3193,3.4482c-.6299,1.6592-1.0596,2.5088-1.6592,3.0283-.4297,.3799-.8594,.5303-1.0796,.5703l-.2197-.7402c.2197-.0693,.5098-.21,.7695-.4297,.2397-.1895,.5396-.5293,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2295l-1.7891-4.458h.9595Z" fill="#fff" />
                                                            <path d="M147.397,639.0726h3.6279v.7295h-2.7588v2.2383h2.5488v.7197h-2.5488v3.0488h-.8691v-6.7363Z" fill="#fff" />
                                                            <path d="M156.3047,643.3499c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M157.4165,642.4808c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0596,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M161.0767,643.5501c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M165.4771,644.9095c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M170.6162,639.5823v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M175.0166,639.1624c.4395-.0898,1.0693-.1396,1.6689-.1396,.9292,0,1.5293,.1699,1.9487,.5498,.3398,.2998,.5298,.7588,.5298,1.2793,0,.8887-.5596,1.4785-1.269,1.7188v.0303c.5195,.1797,.8296,.6592,.9893,1.3584,.2197,.9395,.3799,1.5898,.5195,1.8496h-.8994c-.1099-.1904-.2598-.7695-.4497-1.6094-.1997-.9297-.5596-1.2793-1.3491-1.3096h-.8198v2.9189h-.8691v-6.6465Zm.8691,3.0684h.8896c.9297,0,1.519-.5098,1.519-1.2793,0-.8701-.6294-1.249-1.5488-1.2598-.4199,0-.7197,.04-.8599,.0801v2.459Z" fill="#fff" />
                                                            <path d="M184.7842,643.3499c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M188.5752,645.8089l-.0698-.6104h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M194.8941,638.7122v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.269-1.0195-.9097,0-1.4492,.7998-1.4492,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-30" data-name="item-id-box">
                                                    <g id="id-box-38" data-name="id-box">
                                                        <rect x="43.367" y="633.4752" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-33" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,639.1585h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,642.6165c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M79.8775,640.6478h-2.5186l-.1401,1c.1401-.0205,.2603-.0205,.4199-.0205,.6196,0,1.2495,.1396,1.709,.4697,.4897,.3301,.7896,.8701,.7896,1.6289,0,1.21-1.0391,2.2793-2.7881,2.2793-.7896,0-1.4492-.1797-1.8091-.3701l.2695-1.1396c.29,.1406,.8799,.3203,1.4692,.3203,.6299,0,1.2993-.2998,1.2993-.9893,0-.6699-.5298-1.0801-1.8291-1.0801-.3594,0-.6094,.0205-.8794,.0605l.4297-3.4082h3.5781v1.249Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-006-item-row-2" data-name="loc-006-item-row">
                                                <g id="item-base-box-31" data-name="item-base-box">
                                                    <g id="base-box-36" data-name="base-box">
                                                        <rect x="217.637" y="651.6002" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="651.6002" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-36" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,660.4954c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,662.6146c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,657.7073v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,663.0345c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,657.7073v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,663.9339l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,660.4056c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,656.8372v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,657.7366c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,660.4056c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,659.096c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,660.4954c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,662.6146c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,657.7073v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,663.0345c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,657.7073v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,663.9339l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,660.4056c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,656.8372v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,657.7366c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,660.4056c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,659.096c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-31" data-name="item-type-box">
                                                    <g id="type-box-41" data-name="type-box">
                                                        <rect x="101.7643" y="651.6002" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-36" data-name="type-text">
                                                        <g>
                                                            <path d="M114.6275,657.1976h.8691v6.0068h2.8784v.7295h-3.7476v-6.7363Z" fill="#fff" />
                                                            <path d="M121.9956,663.9339l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M124.1372,660.6058c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0596,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M130.7554,663.7542c-.23,.1191-.7397,.2793-1.3892,.2793-1.4595,0-2.4087-.9893-2.4087-2.4688,0-1.4893,1.0195-2.5684,2.5986-2.5684,.5195,0,.9795,.1299,1.2192,.25l-.1997,.6797c-.21-.1201-.54-.2305-1.0195-.2305-1.1094,0-1.709,.8203-1.709,1.8291,0,1.1201,.7192,1.8096,1.6787,1.8096,.5,0,.8296-.1299,1.0796-.2402l.1499,.6602Z" fill="#fff" />
                                                            <path d="M131.7871,656.8372h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M140.5762,661.8148l-.6997,2.1191h-.8994l2.2886-6.7363h1.0493l2.2988,6.7363h-.9297l-.7192-2.1191h-2.3887Zm2.2085-.6797l-.6597-1.9385c-.1499-.4404-.2495-.8398-.3496-1.2295h-.02c-.1001,.3994-.21,.8096-.3398,1.2188l-.6597,1.9492h2.0288Z" fill="#fff" />
                                                            <path d="M145.7066,659.096l.9497,2.7188c.1597,.4395,.2896,.8398,.3896,1.2393h.0298c.1104-.3994,.25-.7998,.4102-1.2393l.9395-2.7188h.9194l-1.8989,4.8379h-.8398l-1.8389-4.8379h.9395Z" fill="#fff" />
                                                            <path d="M150.5664,661.6751c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M155.0865,660.4056c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M164.6846,662.6146c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M166.6363,661.6751c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-31" data-name="item-id-box">
                                                    <g id="id-box-39" data-name="id-box">
                                                        <rect x="43.367" y="651.6002" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-34" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,657.2835h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,660.7415c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M79.6875,658.6028c-.1699-.0098-.3496,0-.5894,.0205-1.3496,.1094-1.9492,.7988-2.1191,1.5586h.0303c.3198-.3291,.7695-.5195,1.3789-.5195,1.0894,0,2.0093,.7695,2.0093,2.1191,0,1.2891-.9897,2.3486-2.3989,2.3486-1.729,0-2.5786-1.2891-2.5786-2.8389,0-1.2188,.4497-2.2383,1.1494-2.8877,.6494-.5898,1.4893-.9102,2.5088-.96,.2798-.0195,.4595-.0195,.6094-.0098v1.1689Zm-.8296,3.2588c0-.5996-.3198-1.1201-.9692-1.1201-.4097,0-.7495,.25-.8994,.5801-.04,.0801-.0601,.2002-.0601,.3799,.0298,.6895,.3599,1.3096,1.0493,1.3096,.5396,0,.8794-.4902,.8794-1.1494Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-007-item-row-2" data-name="loc-007-item-row">
                                                <g id="item-base-box-32" data-name="item-base-box">
                                                    <g id="base-box-37" data-name="base-box">
                                                        <rect x="217.637" y="669.7252" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="669.7252" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-37" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,678.6204c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,680.7396c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,675.8323v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,681.1595c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,675.8323v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,682.0589l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,678.5306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,674.9622v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,675.8616c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,678.5306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,677.221c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,678.6204c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,680.7396c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,675.8323v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,681.1595c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,675.8323v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,682.0589l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,678.5306c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,674.9622v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,675.8616c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,678.5306c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,677.221c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-32" data-name="item-type-box">
                                                    <g id="type-box-42" data-name="type-box">
                                                        <rect x="101.7643" y="669.7252" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-37" data-name="type-text">
                                                        <g>
                                                            <path d="M115.7266,682.0589l-1.709-6.7363h.9194l.7998,3.4082c.1997,.8389,.3794,1.6787,.4995,2.3281h.02c.1099-.6689,.3198-1.4688,.5498-2.3389l.8994-3.3975h.9097l.8193,3.418c.1899,.7998,.3696,1.5986,.4697,2.3086h.02c.1401-.7393,.3301-1.4893,.5396-2.3291l.8896-3.3975h.8896l-1.9092,6.7363h-.9092l-.8496-3.5088c-.21-.8594-.3501-1.5186-.4399-2.1982h-.02c-.1196,.6699-.2695,1.3291-.5195,2.1982l-.9595,3.5088h-.9097Z" fill="#fff" />
                                                            <path d="M127.105,679.5999c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M129.0869,679.4398h.02c.1201-.1699,.29-.3799,.4297-.5498l1.4194-1.6689h1.0591l-1.8687,1.9893,2.1289,2.8486h-1.0698l-1.6689-2.3193-.4497,.5v1.8193h-.8696v-7.0967h.8696v4.4775Z" fill="#fff" />
                                                            <path d="M134.0567,675.8616c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M135.417,678.5306c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M145.1348,677.221c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2202,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6094-.4004l.2202-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5591-.4697,1.5591-1.6895v-.54h-.0195c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                            <path d="M146.5567,674.9622h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M154.7857,682.0589l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M156.9268,678.5306c0-.5-.0098-.9092-.04-1.3096h.7695l.04,.7803h.0303c.2695-.46,.7192-.8896,1.519-.8896,.6597,0,1.1592,.3994,1.3691,.9688h.02c.1499-.2695,.3398-.4795,.5396-.6289,.29-.2197,.6099-.3398,1.0698-.3398,.6396,0,1.5889,.4199,1.5889,2.0986v2.8486h-.8594v-2.7383c0-.9297-.3398-1.4893-1.0493-1.4893-.5,0-.8896,.3691-1.0396,.7988-.04,.1201-.0698,.2803-.0698,.4404v2.9883h-.8599v-2.8984c0-.7695-.3398-1.3291-1.0093-1.3291-.5498,0-.9497,.4395-1.0894,.8789-.0503,.1299-.0698,.2803-.0698,.4297v2.9189h-.8599v-3.5283Z" fill="#fff" />
                                                            <path d="M167.417,675.3226h.8691v6.0068h2.8784v.7295h-3.7476v-6.7363Z" fill="#fff" />
                                                            <path d="M175.8247,682.0589l-.8599-1.4893c-.3496-.5703-.5693-.9395-.7793-1.3291h-.02c-.1899,.3896-.3799,.749-.7295,1.3389l-.8096,1.4795h-.9995l2.0586-3.4082-1.979-3.3281h1.0098l.8896,1.5791c.2495,.4395,.4395,.7793,.6196,1.1396h.0298c.1899-.4004,.3599-.71,.6099-1.1396l.9194-1.5791h.9995l-2.0493,3.2783,2.0991,3.458h-1.0093Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-32" data-name="item-id-box">
                                                    <g id="id-box-40" data-name="id-box">
                                                        <rect x="43.367" y="669.7252" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-35" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,675.4085h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,678.8665c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M80.1973,675.6487v.959l-2.6787,5.5371h-1.6089l2.6787-5.2266v-.0205h-2.9785v-1.249h4.5874Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-008-item-row-2" data-name="loc-008-item-row">
                                                <g id="item-base-box-33" data-name="item-base-box">
                                                    <g id="base-box-38" data-name="base-box">
                                                        <rect x="217.637" y="687.8429" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="687.8429" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-38" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,696.7386c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,698.8568c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M255.8584,693.9495v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M257.9893,699.2767c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M263.1285,693.9495v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M268.0576,700.1761l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,696.6487c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M279.9263,693.0804v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,693.9798c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,696.6487c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M293.4463,695.3392c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,696.7386c0,2.3184-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,698.8568c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.7891h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6787c0,.9189,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M338.0889,693.9495v1.3896h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M340.2198,699.2767c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8691-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6299-1.4199,1.6689-1.4199,.4902,0,.9199,.1406,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2793-.9893-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9189,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M345.3594,693.9495v1.3896h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M350.2881,700.1761l-.0693-.6094h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8086,2.9092-1.7988v-.0996c0-.4004-.1104-1.1201-1.0996-1.1201-.4502,0-.9199,.1406-1.2598,.3604l-.1992-.5801c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0098,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3799,.8398,.8301,.8398,.6289,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,696.6487c0-.5-.0098-.9102-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9102,1.5986-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5273Z" fill="#fff" />
                                                            <path d="M362.1573,693.0804v5.8467c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0488,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,693.9798c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5498,.5498-.5498,.3291,0,.5391,.2402,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,696.6487c0-.5-.0098-.9102-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9102,1.5996-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5273Z" fill="#fff" />
                                                            <path d="M375.6768,695.3392c-.0195,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5791-1.7588,.5791-.5801,0-1.2197-.1396-1.6094-.3994l.2197-.6699c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8398h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9502,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-33" data-name="item-type-box">
                                                    <g id="type-box-43" data-name="type-box">
                                                        <rect x="101.7643" y="687.8429" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-38" data-name="type-text">
                                                        <g>
                                                            <path d="M119.3345,699.9564c-.3198,.1602-.9595,.3203-1.7788,.3203-1.8989,0-3.3281-1.2002-3.3281-3.4082,0-2.1094,1.4292-3.5381,3.5181-3.5381,.8394,0,1.3691,.1797,1.5991,.2998l-.21,.709c-.3296-.1592-.7993-.2793-1.3594-.2793-1.5791,0-2.6284,1.0088-2.6284,2.7783,0,1.6494,.9497,2.708,2.5884,2.708,.5298,0,1.0698-.1094,1.4194-.2793l.1797,.6895Z" fill="#fff" />
                                                            <path d="M120.397,693.0804h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.29-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M128.6255,700.1761l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M130.7671,696.9183c0-.6201-.02-1.1191-.04-1.5791h.7896l.04,.8291h.02c.3599-.5898,.9297-.9395,1.7192-.9395,1.1694,0,2.0488,.9893,2.0488,2.459,0,1.7393-1.0596,2.5986-2.1987,2.5986-.6396,0-1.1992-.2803-1.4893-.7598h-.02v2.6289h-.8696v-5.2373Zm.8696,1.2891c0,.1299,.02,.25,.04,.3604,.1597,.6094,.6895,1.0293,1.3193,1.0293,.9292,0,1.4692-.7598,1.4692-1.8691,0-.9697-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0889-.0303,.1104-.0601,.2402-.0601,.3604v.8291Z" fill="#fff" />
                                                            <path d="M136.9463,697.9173c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M141.4668,693.0804h.8794v7.0957h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M151.1035,699.8763c-.3896,.1396-1.1592,.3701-2.0688,.3701-1.0195,0-1.8589-.2598-2.5186-.8896-.5796-.5596-.9395-1.459-.9395-2.5088,.0098-2.0088,1.3892-3.4775,3.6479-3.4775,.7798,0,1.3892,.1699,1.6792,.3096l-.21,.71c-.3599-.1602-.8096-.29-1.4893-.29-1.6392,0-2.7085,1.0195-2.7085,2.708,0,1.709,1.0293,2.7188,2.5986,2.7188,.5698,0,.9595-.0801,1.1592-.1797v-2.0088h-1.3691v-.7002h2.2188v3.2383Z" fill="#fff" />
                                                            <path d="M152.4063,696.848c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M156.0664,697.9173c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M161.0762,697.9173c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M165.5962,696.6487c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-33" data-name="item-id-box">
                                                    <g id="id-box-41" data-name="id-box">
                                                        <rect x="43.367" y="687.8429" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-36" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,693.5267h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,696.9847c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6792,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1982,.9395,2.1982,.5894,0,.9092-.7393,.9092-2.2188,0-1.4385-.3096-2.1982-.9194-2.1982-.5596,0-.9395,.709-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M75.44,698.5638c0-.7793,.4497-1.3193,1.1592-1.6289v-.0303c-.6294-.3193-.9395-.8496-.9395-1.4189,0-1.0996,.9897-1.8291,2.2891-1.8291,1.519,0,2.1484,.8789,2.1484,1.6689,0,.5596-.2998,1.1094-.9395,1.4287v.0303c.6299,.2402,1.1895,.7793,1.1895,1.6191,0,1.1797-.9893,1.9688-2.4985,1.9688-1.6489,0-2.4087-.9287-2.4087-1.8086Zm3.3579-.0498c0-.5703-.4194-.9102-1.019-1.0693-.5,.1396-.7896,.499-.7896,.9688-.0103,.4697,.3496,.9102,.9194,.9102,.5396,0,.8892-.3496,.8892-.8096Zm-1.709-3.0781c0,.4395,.3999,.7188,.9194,.8789,.3501-.0996,.6597-.4199,.6597-.8291,0-.4004-.2295-.7998-.7896-.7998-.5195,0-.7896,.3398-.7896,.75Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-009-item-row-2" data-name="loc-009-item-row">
                                                <g id="item-base-box-34" data-name="item-base-box">
                                                    <g id="base-box-39" data-name="base-box">
                                                        <rect x="217.637" y="705.9679" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="705.9679" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-39" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,714.8636c0,2.3184-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,716.9818c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.7891h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8794v2.6787c0,.9189,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8794v3.5176Z" fill="#fff" />
                                                            <path d="M255.8584,712.0745v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M257.9893,717.4017c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8696-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6294-1.4199,1.6689-1.4199,.4897,0,.9194,.1406,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2793-.9897-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9194,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6699Z" fill="#fff" />
                                                            <path d="M263.1285,712.0745v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M268.0576,718.3011l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,714.7737c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.2896-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M279.9263,711.2054v5.8467c0,.4297,.0103,.9199,.04,1.249h-.7896l-.04-.8389h-.02c-.2695,.5391-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.459-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1104-.0103-.2598-.04-.3701-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0488,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,712.1048c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,714.7737c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M293.4463,713.4642c-.02,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5791-1.7588,.5791-.5796,0-1.2192-.1396-1.6089-.3994l.2197-.6699c.3198,.2002,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.5391h-.02c-.27,.4492-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4404,1.5093,.8398h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9502,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.8994,.0498-.1504,.0698-.3203,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,714.8636c0,2.3184-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3799-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1094c0,1.4395,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7979,0-1.3398-.6992-2.7393-2.1484-2.7393-1.4395,0-2.1592,1.3301-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,716.9818c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.7891h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1191v-2.8281h.8789v2.6787c0,.9189,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4102,1.1592-.7998,.0498-.1299,.0801-.29,.0801-.4502v-2.9678h.8789v3.5176Z" fill="#fff" />
                                                            <path d="M338.0889,712.0745v1.3896h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M340.2198,717.4017c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3193,.9395-.7197,0-.4189-.25-.6494-.8994-.8887-.8691-.3105-1.2793-.79-1.2793-1.3691,0-.7803,.6299-1.4199,1.6689-1.4199,.4902,0,.9199,.1406,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2793-.9893-.2793-.5195,0-.8096,.2998-.8096,.6592,0,.4004,.29,.5801,.9189,.8193,.8398,.3203,1.2695,.7402,1.2695,1.46,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6699Z" fill="#fff" />
                                                            <path d="M345.3594,712.0745v1.3896h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M350.2881,718.3011l-.0693-.6094h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.04-1.8086,2.9092-1.7988v-.0996c0-.4004-.1104-1.1201-1.0996-1.1201-.4502,0-.9199,.1406-1.2598,.3604l-.1992-.5801c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0098,1.8389,1.9795v1.8086c0,.4199,.0205,.8301,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3799,.8398,.8301,.8398,.6289,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0498-.1904,.0498-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,714.7737c0-.5-.0098-.9102-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9102,1.5986-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8789v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0303,.4102-1.1797,.8994-.04,.1104-.0605,.2607-.0605,.4102v2.9082h-.8789v-3.5273Z" fill="#fff" />
                                                            <path d="M362.1573,711.2054v5.8467c0,.4297,.0098,.9199,.04,1.249h-.79l-.04-.8389h-.0195c-.2695,.5391-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.459-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7402h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1104-.0098-.2598-.04-.3701-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0488,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,712.1048c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5498,.5498-.5498,.3291,0,.5391,.2402,.5391,.5498Zm-.9795,6.1963v-4.8369h.8799v4.8369h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,714.7737c0-.5-.0098-.9102-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9102,1.5996-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8799v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0293,.4102-1.1797,.8994-.04,.1104-.0596,.2607-.0596,.4102v2.9082h-.8799v-3.5273Z" fill="#fff" />
                                                            <path d="M375.6768,713.4642c-.0195,.3496-.04,.7393-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5791-1.7588,.5791-.5801,0-1.2197-.1396-1.6094-.3994l.2197-.6699c.3203,.2002,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.5391h-.0195c-.2705,.4492-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3594,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4404,1.5098,.8398h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1504-.0098-.2803-.0498-.4004-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9502,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.8994,.0498-.1504,.0703-.3203,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-34" data-name="item-type-box">
                                                    <g id="type-box-44" data-name="type-box">
                                                        <rect x="101.7643" y="705.9679" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-39" data-name="type-text">
                                                        <g>
                                                            <path d="M119.3345,718.0814c-.3198,.1602-.9595,.3203-1.7788,.3203-1.8989,0-3.3281-1.2002-3.3281-3.4082,0-2.1094,1.4292-3.5381,3.5181-3.5381,.8394,0,1.3691,.1797,1.5991,.2998l-.21,.709c-.3296-.1592-.7993-.2793-1.3594-.2793-1.5791,0-2.6284,1.0088-2.6284,2.7783,0,1.6494,.9497,2.708,2.5884,2.708,.5298,0,1.0698-.1094,1.4194-.2793l.1797,.6895Z" fill="#fff" />
                                                            <path d="M120.3672,714.973c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M127.9151,715.8431c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0591-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M129.3072,713.4642l.6396,2.458c.1396,.54,.2695,1.04,.3594,1.54h.0303c.1099-.4902,.27-1.0098,.4297-1.5293l.7896-2.4688h.7397l.7495,2.4189c.1797,.5791,.3198,1.0889,.4297,1.5791h.0298c.0801-.4902,.21-1,.3701-1.5693l.6895-2.4287h.8696l-1.5591,4.8369h-.7998l-.7393-2.3086c-.1699-.54-.3101-1.0195-.4297-1.5889h-.02c-.1201,.5791-.27,1.0791-.4399,1.5986l-.7798,2.2988h-.7993l-1.4595-4.8369h.8999Z" fill="#fff" />
                                                            <path d="M137.3765,712.0745v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M139.627,711.2054h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6201,.2598-.1494,.5698-.25,.8994-.25,.6499,0,1.689,.4004,1.689,2.0693v2.8779h-.8794v-2.7783c0-.7793-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.4004-1.1797,.8799-.0498,.1201-.0601,.25-.0601,.4199v2.918h-.8794v-7.0957Z" fill="#fff" />
                                                            <path d="M149.5552,715.8431c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M150.667,714.973c0-.5693-.0098-1.0596-.04-1.5088h.7695l.0303,.9492h.04c.2197-.6494,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0303v.8291c-.0898-.0195-.1797-.0293-.2998-.0293-.6196,0-1.0591,.4697-1.1792,1.1289-.02,.1201-.04,.2598-.04,.4102v2.5781h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M153.9771,714.7737c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                            <path d="M160.0166,716.0423c.02,1.1895,.7798,1.6797,1.6592,1.6797,.6299,0,1.0093-.1104,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4395,0-1.459,.8594-2.6084,2.2686-2.6084,1.5791,0,1.999,1.3896,1.999,2.2793,0,.1797-.02,.3193-.0298,.4092h-3.3984Zm2.5786-.6289c.0103-.5605-.23-1.4297-1.2192-1.4297-.8896,0-1.2793,.8193-1.3491,1.4297h2.5684Z" fill="#fff" />
                                                            <path d="M166.5669,717.2415c.3896,.2402,.9595,.4404,1.5591,.4404,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6299-.3599-.9893-1.269-1.3398-1.0996-.3896-1.7793-.959-1.7793-1.9082,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1592,1.4893,.3291l-.2397,.71c-.2197-.1201-.6694-.3193-1.2793-.3193-.9194,0-1.269,.5488-1.269,1.0088,0,.6299,.4097,.9395,1.3389,1.2998,1.1396,.4395,1.7192,.9893,1.7192,1.9785,0,1.0391-.7695,1.9395-2.3589,1.9395-.6494,0-1.3589-.1904-1.7188-.4297l.2197-.7305Z" fill="#fff" />
                                                            <path d="M172.6162,712.0745v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M177.5454,718.3011l-.0698-.6094h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3896,0-1.1689,1.0396-1.8086,2.9087-1.7988v-.0996c0-.4004-.1099-1.1201-1.0996-1.1201-.4497,0-.9194,.1406-1.2593,.3604l-.1997-.5801c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0098,1.8394,1.9795v1.8086c0,.4199,.02,.8301,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0195-2.0488,.1504-2.0488,1.0898,0,.5693,.3794,.8398,.8296,.8398,.6294,0,1.0293-.4004,1.1689-.8096,.0303-.0908,.0503-.1904,.0503-.2803v-.8398Z" fill="#fff" />
                                                            <path d="M180.7061,712.0745v1.3896h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0303,.5098-.0605l.04,.6602c-.1699,.0693-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2598v-2.6377h-.75v-.6699h.75v-1.1592l.8594-.2305Z" fill="#fff" />
                                                            <path d="M183.9361,712.1048c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5498,.5498-.5498,.3296,0,.5396,.2402,.5396,.5498Zm-.9795,6.1963v-4.8369h.8794v4.8369h-.8794Z" fill="#fff" />
                                                            <path d="M189.6743,715.8431c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.96-2.3188-2.4893,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0098,2.3291,2.4893Zm-3.8379,.0498c0,1.0596,.6094,1.8584,1.4688,1.8584,.8398,0,1.4692-.7891,1.4692-1.8789,0-.8193-.4097-1.8584-1.4492-1.8584s-1.4888,.959-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M190.7867,714.7737c0-.5-.0098-.9102-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9102,1.5991-.9102,.6699,0,1.709,.4004,1.709,2.0596v2.8877h-.8794v-2.7881c0-.7793-.29-1.4297-1.1191-1.4297-.5801,0-1.0298,.4102-1.1797,.8994-.04,.1104-.0601,.2607-.0601,.4102v2.9082h-.8794v-3.5273Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-34" data-name="item-id-box">
                                                    <g id="id-box-42" data-name="id-box">
                                                        <rect x="43.367" y="705.9679" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-37" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,711.6517h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M74.7876,715.1097c0,2.0088-.8096,3.3877-2.4688,3.3877-1.6792,0-2.4189-1.5088-2.4287-3.3477,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5488,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1982,.9395,2.1982,.5894,0,.9092-.7393,.9092-2.2188,0-1.4385-.3096-2.1982-.9194-2.1982-.5596,0-.9395,.709-.9292,2.2188Z" fill="#fff" />
                                                            <path d="M76.0196,717.2884c.1899,.0195,.3599,.0195,.6597,0,.46-.0303,.9297-.1602,1.2793-.3994,.4199-.29,.6997-.71,.8198-1.2002l-.0303-.0098c-.2896,.2998-.7095,.4697-1.2993,.4697-1.0991,0-2.0288-.7695-2.0288-2.0283,0-1.2695,1.0195-2.3389,2.4487-2.3389,1.6689,0,2.4585,1.2793,2.4585,2.7979,0,1.3496-.4297,2.3389-1.1392,2.9893-.6196,.5596-1.4692,.8691-2.4785,.9092-.2603,.0195-.52,.0098-.6899,0v-1.1895Zm.9194-3.2578c0,.5596,.2998,1.0391,.9194,1.0391,.4102,0,.6997-.2002,.8398-.4297,.0498-.0898,.0801-.1904,.0801-.3799,0-.6895-.2603-1.3691-.9497-1.3691-.5195,0-.8994,.46-.8896,1.1396Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-010-item-row-2" data-name="loc-010-item-row">
                                                <g id="item-base-box-35" data-name="item-base-box">
                                                    <g id="base-box-40" data-name="base-box">
                                                        <rect x="217.637" y="724.0856" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="724.0856" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-40" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,732.9808c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,735.0999c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,730.1927v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,735.5198c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,730.1927v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,736.4193l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,732.8909c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,729.3226v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,730.222c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,732.8909c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,731.5814c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,732.9808c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,735.0999c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,730.1927v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,735.5198c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,730.1927v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,736.4193l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,732.8909c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,729.3226v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,730.222c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,732.8909c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,731.5814c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-35" data-name="item-type-box">
                                                    <g id="type-box-45" data-name="type-box">
                                                        <rect x="101.7643" y="724.0856" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-40" data-name="type-text">
                                                        <g>
                                                            <path d="M114.6275,729.7728c.5293-.0801,1.1592-.1396,1.8486-.1396,1.2495,0,2.1392,.2891,2.7285,.8389,.5996,.5498,.9497,1.3291,.9497,2.4189,0,1.0996-.3398,1.999-.9697,2.6182-.6294,.6299-1.6689,.9697-2.978,.9697-.6196,0-1.1396-.0303-1.5791-.0801v-6.626Zm.8691,5.957c.2202,.04,.54,.0498,.8799,.0498,1.8589,0,2.8682-1.04,2.8682-2.8584,.0103-1.5898-.8896-2.5986-2.7285-2.5986-.4497,0-.7896,.04-1.0195,.0898v5.3174Z" fill="#fff" />
                                                            <path d="M123.9356,736.4193l-.0698-.6104h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M126.0772,733.0911c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M129.3467,736.4193c.02-.3301,.04-.8193,.04-1.25v-5.8467h.8696v3.0391h.02c.3096-.54,.8696-.8896,1.6489-.8896,1.1997,0,2.0488,.999,2.0391,2.4688,0,1.7285-1.0894,2.5879-2.1689,2.5879-.6997,0-1.2593-.2695-1.6191-.9092h-.0298l-.04,.7998h-.7598Zm.9097-1.9395c0,.1104,.02,.2197,.04,.3203,.1699,.6094,.6797,1.0293,1.3193,1.0293,.9194,0,1.4692-.75,1.4692-1.8594,0-.9688-.5-1.7988-1.4395-1.7988-.5996,0-1.1592,.4102-1.3394,1.0791-.02,.1006-.0498,.2207-.0498,.3604v.8691Z" fill="#fff" />
                                                            <path d="M135.3267,731.5814l1.0596,2.8584c.1099,.3203,.23,.7002,.3096,.9902h.02c.0903-.29,.1899-.6602,.3101-1.0098l.9595-2.8389h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5088-1.6592,3.0283-.4297,.3799-.8599,.5303-1.0796,.5703l-.2197-.7402c.2197-.0693,.5098-.21,.7695-.4297,.2397-.1895,.5396-.5293,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2295l-1.7891-4.458h.9595Z" fill="#fff" />
                                                            <path d="M146.9942,736.1194c-.3896,.1396-1.1592,.3691-2.0688,.3691-1.0195,0-1.8589-.2598-2.5186-.8887-.5796-.5605-.9395-1.46-.9395-2.5088,.0098-2.0088,1.3892-3.4785,3.6479-3.4785,.7798,0,1.3892,.1699,1.6792,.3096l-.21,.71c-.3599-.1602-.8096-.29-1.4893-.29-1.6392,0-2.7085,1.0195-2.7085,2.709,0,1.709,1.0293,2.7188,2.5986,2.7188,.5698,0,.9595-.0801,1.1592-.1807v-2.0088h-1.3691v-.6992h2.2188v3.2383Z" fill="#fff" />
                                                            <path d="M148.2969,733.0911c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M151.9566,734.1605c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M156.9663,734.1605c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M161.4864,732.8909c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M169.1866,729.6829h3.6279v.7295h-2.7588v2.2383h2.5488v.7197h-2.5488v3.0488h-.8691v-6.7363Z" fill="#fff" />
                                                            <path d="M178.0943,733.9603c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M183.5845,733.9603c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M185.7559,730.1927v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M188.0064,733.1605c0-.6191-.02-1.1191-.04-1.5791h.7896l.04,.8301h.02c.3599-.5898,.9297-.9395,1.7192-.9395,1.1694,0,2.0488,.9893,2.0488,2.458,0,1.7393-1.0596,2.5986-2.1987,2.5986-.6396,0-1.1992-.2793-1.4893-.7588h-.02v2.6279h-.8696v-5.2373Zm.8696,1.29c0,.1299,.02,.249,.04,.3594,.1597,.6094,.6895,1.0293,1.3193,1.0293,.9292,0,1.4692-.7598,1.4692-1.8691,0-.9688-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0898-.0303,.1094-.0601,.2393-.0601,.3594v.8301Z" fill="#fff" />
                                                            <path d="M196.375,736.4193l-.0698-.6104h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M199.5357,730.1927v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M201.7862,729.3226h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-35" data-name="item-id-box">
                                                    <g id="id-box-43" data-name="id-box">
                                                        <rect x="43.367" y="724.0856" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-38" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,729.7689h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M71.9292,731.3587h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                            <path d="M80.3374,733.2269c0,2.0088-.8096,3.3887-2.4688,3.3887-1.6792,0-2.4189-1.5098-2.4287-3.3486,0-1.8789,.7993-3.3682,2.4785-3.3682,1.7393,0,2.4189,1.5498,2.4189,3.3281Zm-3.3682,.04c-.0103,1.4893,.3496,2.1992,.9395,2.1992,.5894,0,.9092-.7393,.9092-2.2188,0-1.4395-.3096-2.1992-.9194-2.1992-.5596,0-.9395,.71-.9292,2.2188Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-011-item-row-2" data-name="loc-011-item-row">
                                                <g id="item-base-box-36" data-name="item-base-box">
                                                    <g id="base-box-41" data-name="base-box">
                                                        <rect x="217.637" y="742.2106" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="742.2106" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-41" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,751.1058c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,753.2249c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,748.3177v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,753.6448c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,748.3177v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,754.5443l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,751.0159c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,747.4476v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,748.347c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,751.0159c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,749.7064c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,751.1058c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,753.2249c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,748.3177v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,753.6448c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,748.3177v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,754.5443l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,751.0159c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,747.4476v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,748.347c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,751.0159c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,749.7064c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-36" data-name="item-type-box">
                                                    <g id="type-box-46" data-name="type-box">
                                                        <rect x="101.7643" y="742.2106" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-41" data-name="type-text">
                                                        <g>
                                                            <path d="M114.6275,747.8978c.3794-.0801,.9795-.1396,1.5889-.1396,.8696,0,1.4292,.1494,1.8491,.4893,.3496,.2598,.5596,.6602,.5596,1.1895,0,.6494-.4297,1.2197-1.1392,1.4795v.0195c.6396,.1602,1.3892,.6895,1.3892,1.6895,0,.5791-.23,1.0195-.5698,1.3486-.4697,.4297-1.2295,.6299-2.3286,.6299-.5996,0-1.0596-.04-1.3491-.0801v-6.626Zm.8691,2.7588h.7896c.9199,0,1.4595-.4805,1.4595-1.1299,0-.7891-.5996-1.0996-1.4795-1.0996-.3994,0-.6294,.0303-.7695,.0605v2.1689Zm0,3.2275c.1699,.0303,.4199,.04,.73,.04,.8994,0,1.729-.3301,1.729-1.3086,0-.9199-.7896-1.2998-1.7393-1.2998h-.7197v2.5684Z" fill="#fff" />
                                                            <path d="M120.0171,747.4476h.8794v7.0967h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M125.0557,754.5443l-.0698-.6104h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M130.6451,754.3646c-.23,.1191-.7397,.2793-1.3892,.2793-1.4595,0-2.4087-.9893-2.4087-2.4688,0-1.4893,1.0195-2.5684,2.5986-2.5684,.5195,0,.9795,.1299,1.2192,.25l-.1997,.6797c-.21-.1201-.54-.2305-1.0195-.2305-1.1094,0-1.709,.8203-1.709,1.8291,0,1.1201,.7192,1.8096,1.6787,1.8096,.5,0,.8296-.1299,1.0796-.2402l.1499,.6602Z" fill="#fff" />
                                                            <path d="M132.5464,751.9251h.02c.1201-.1699,.29-.3799,.4297-.5498l1.4194-1.6689h1.0591l-1.8687,1.9893,2.1289,2.8486h-1.0698l-1.6689-2.3193-.4497,.5v1.8193h-.8696v-7.0967h.8696v4.4775Z" fill="#fff" />
                                                            <path d="M136.8467,749.7064l.6396,2.459c.1396,.54,.2695,1.0391,.3599,1.5391h.0298c.1099-.4893,.27-1.0098,.4297-1.5293l.7896-2.4688h.7397l.7495,2.4189c.1797,.5801,.3198,1.0898,.4297,1.5791h.0303c.0796-.4893,.2095-.999,.3696-1.5693l.6895-2.4287h.8696l-1.5591,4.8379h-.7998l-.7393-2.3086c-.1699-.54-.3101-1.0195-.4302-1.5898h-.0195c-.1201,.5801-.27,1.0801-.4399,1.5996l-.7798,2.2988h-.7993l-1.4595-4.8379h.8999Z" fill="#fff" />
                                                            <path d="M146.4956,754.5443l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M149.6563,748.3177v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M152.3365,752.2855c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M156.8565,751.2161c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1699,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0591,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M162.1568,753.4847c.3896,.2402,.9595,.4395,1.5591,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3599-.9893-1.269-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1602,1.4893,.3301l-.2397,.71c-.2197-.1201-.6694-.3203-1.2793-.3203-.9194,0-1.269,.5498-1.269,1.0098,0,.6299,.4097,.9395,1.3389,1.2988,1.1396,.4404,1.7192,.9893,1.7192,1.9795,0,1.0391-.7695,1.9385-2.3589,1.9385-.6494,0-1.3589-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M168.2061,748.3177v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M173.1353,754.5443l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M176.2959,748.3177v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M179.5259,748.347c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M185.2642,752.0853c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M186.376,751.0159c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-36" data-name="item-id-box">
                                                    <g id="id-box-44" data-name="id-box">
                                                        <rect x="43.367" y="742.2106" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-39" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,747.8939h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M71.9292,749.4837h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                            <path d="M77.479,749.4837h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-012-item-row-2" data-name="loc-012-item-row">
                                                <g id="item-base-box-37" data-name="item-base-box">
                                                    <g id="base-box-42" data-name="base-box">
                                                        <rect x="217.637" y="760.3356" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                        <rect x="306.6372" y="760.3356" width="89.0002" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="base-text-42" data-name="base-text">
                                                        <g>
                                                            <path d="M248.1563,769.2308c0,2.3193-1.4092,3.5479-3.1284,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1284-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7798,2.7285,2.1489,2.7285,1.3794,0,2.1587-1.2695,2.1587-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M253.3369,771.3499c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M255.8584,766.4427v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M257.9893,771.7698c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M263.1285,766.4427v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M268.0576,772.6693l-.0698-.6104h-.0303c-.27,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M270.1988,769.1409c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M279.9263,765.5726v5.8467c0,.4307,.0103,.9199,.04,1.25h-.7896l-.04-.8398h-.02c-.2695,.54-.8594,.9492-1.6489,.9492-1.1694,0-2.0688-.9893-2.0688-2.458-.0103-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.02v-2.8887h.8794Zm-.8794,4.2275c0-.1094-.0103-.2598-.04-.3691-.1299-.5596-.6099-1.0195-1.2695-1.0195-.9092,0-1.4487,.7998-1.4487,1.8691,0,.9795,.4795,1.7891,1.4292,1.7891,.5894,0,1.1294-.3896,1.2891-1.0498,.0298-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M282.3682,766.472c.0103,.2998-.21,.54-.5596,.54-.3101,0-.5298-.2402-.5298-.54,0-.3096,.23-.5488,.5498-.5488,.3296,0,.5396,.2393,.5396,.5488Zm-.9795,6.1973v-4.8379h.8794v4.8379h-.8794Z" fill="#fff" />
                                                            <path d="M283.7285,769.1409c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M293.4463,767.8314c-.02,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6899,2.209-.4697,.4395-1.1489,.5801-1.7588,.5801-.5796,0-1.2192-.1406-1.6089-.4004l.2197-.6689c.3198,.1992,.8193,.3799,1.4189,.3799,.8999,0,1.5596-.4697,1.5596-1.6895v-.54h-.02c-.27,.4502-.7896,.8096-1.5396,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8394,0,1.2993,.4395,1.5093,.8389h.02l.04-.7295h.7695Zm-.9097,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8394,0-1.439,.71-1.439,1.8291,0,.9492,.4795,1.7393,1.4292,1.7393,.5396,0,1.0293-.3398,1.2192-.9004,.0498-.1494,.0698-.3193,.0698-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M330.3868,769.2308c0,2.3193-1.4092,3.5479-3.1279,3.5479-1.7793,0-3.0283-1.3789-3.0283-3.418,0-2.1387,1.3291-3.5381,3.1279-3.5381,1.8389,0,3.0283,1.4092,3.0283,3.4082Zm-5.2275,.1104c0,1.4385,.7803,2.7285,2.1494,2.7285,1.3789,0,2.1582-1.2695,2.1582-2.7988,0-1.3389-.6992-2.7383-2.1484-2.7383-1.4395,0-2.1592,1.3291-2.1592,2.8086Z" fill="#fff" />
                                                            <path d="M335.5674,771.3499c0,.5,.0107,.9395,.04,1.3193h-.7793l-.0498-.79h-.0205c-.2295,.3896-.7393,.8994-1.5986,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8789v2.6787c0,.9199,.2803,1.5391,1.0801,1.5391,.5889,0,.999-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8789v3.5186Z" fill="#fff" />
                                                            <path d="M338.0889,766.4427v1.3887h1.2598v.6699h-1.2598v2.6084c0,.5996,.1699,.9395,.6602,.9395,.2295,0,.3994-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4404,.1201-.7803,.1201-.4092,0-.7393-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M340.2198,771.7698c.2598,.1699,.7197,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8691-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6299-1.4189,1.6689-1.4189,.4902,0,.9199,.1396,1.1895,.2998l-.2197,.6396c-.1895-.1201-.54-.2803-.9893-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9189,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6592,1.4492-1.8086,1.4492-.5303,0-1.0195-.1299-1.3594-.3301l.2197-.6689Z" fill="#fff" />
                                                            <path d="M345.3594,766.4427v1.3887h1.2588v.6699h-1.2588v2.6084c0,.5996,.1699,.9395,.6592,.9395,.2305,0,.4004-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4395,.1201-.7793,.1201-.4102,0-.7402-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M350.2881,772.6693l-.0693-.6104h-.0303c-.2705,.3799-.79,.7197-1.4795,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.04-1.8096,2.9092-1.7988v-.1006c0-.3994-.1104-1.1191-1.0996-1.1191-.4502,0-.9199,.1396-1.2598,.3594l-.1992-.5791c.3994-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8389,1.0088,1.8389,1.9785v1.8096c0,.4189,.0205,.8291,.0801,1.1592h-.7998Zm-.1299-2.4688c-.959-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3799,.8398,.8301,.8398,.6289,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0498-.1895,.0498-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M352.4297,769.1409c0-.5-.0098-.9092-.04-1.3096h.7793l.0498,.7998h.0205c.2393-.46,.7998-.9092,1.5986-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8789v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0303,.4092-1.1797,.8994-.04,.1104-.0605,.2598-.0605,.4102v2.9082h-.8789v-3.5283Z" fill="#fff" />
                                                            <path d="M362.1573,765.5726v5.8467c0,.4307,.0098,.9199,.04,1.25h-.79l-.04-.8398h-.0195c-.2695,.54-.8594,.9492-1.6494,.9492-1.1689,0-2.0684-.9893-2.0684-2.458-.0107-1.6094,.9893-2.5986,2.1689-2.5986,.7393,0,1.2393,.3496,1.459,.7393h.0195v-2.8887h.8799Zm-.8799,4.2275c0-.1094-.0098-.2598-.04-.3691-.1299-.5596-.6094-1.0195-1.2695-1.0195-.9092,0-1.4482,.7998-1.4482,1.8691,0,.9795,.4795,1.7891,1.4287,1.7891,.5898,0,1.1299-.3896,1.2891-1.0498,.0303-.1201,.04-.2402,.04-.3799v-.8398Z" fill="#fff" />
                                                            <path d="M364.5987,766.472c.0107,.2998-.21,.54-.5596,.54-.3096,0-.5293-.2402-.5293-.54,0-.3096,.2295-.5488,.5498-.5488,.3291,0,.5391,.2393,.5391,.5488Zm-.9795,6.1973v-4.8379h.8799v4.8379h-.8799Z" fill="#fff" />
                                                            <path d="M365.959,769.1409c0-.5-.0098-.9092-.04-1.3096h.7803l.0498,.7998h.0195c.2402-.46,.7998-.9092,1.5996-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8799v-2.7891c0-.7793-.29-1.4287-1.1191-1.4287-.5801,0-1.0293,.4092-1.1797,.8994-.04,.1104-.0596,.2598-.0596,.4102v2.9082h-.8799v-3.5283Z" fill="#fff" />
                                                            <path d="M375.6768,767.8314c-.0195,.3506-.04,.7402-.04,1.3291v2.8086c0,1.1094-.2197,1.7891-.6895,2.209-.4697,.4395-1.1494,.5801-1.7588,.5801-.5801,0-1.2197-.1406-1.6094-.4004l.2197-.6689c.3203,.1992,.8193,.3799,1.4189,.3799,.9004,0,1.5596-.4697,1.5596-1.6895v-.54h-.0195c-.2705,.4502-.79,.8096-1.54,.8096-1.1992,0-2.0586-1.0195-2.0586-2.3584,0-1.6387,1.0693-2.5684,2.1787-2.5684,.8398,0,1.2998,.4395,1.5098,.8389h.0195l.04-.7295h.7695Zm-.9092,1.9092c0-.1494-.0098-.2793-.0498-.3994-.1602-.5098-.5898-.9297-1.2295-.9297-.8398,0-1.4395,.71-1.4395,1.8291,0,.9492,.4795,1.7393,1.4297,1.7393,.5391,0,1.0293-.3398,1.2188-.9004,.0498-.1494,.0703-.3193,.0703-.4697v-.8691Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-type-box-37" data-name="item-type-box">
                                                    <g id="type-box-47" data-name="type-box">
                                                        <rect x="101.7643" y="760.3356" width="115.8727" height="18.125" fill="#e7e7e8" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-42" data-name="type-text">
                                                        <g>
                                                            <path d="M114.5074,771.6097c.3896,.2402,.9595,.4395,1.5591,.4395,.8896,0,1.4092-.4697,1.4092-1.1494,0-.6289-.3599-.9893-1.269-1.3389-1.0996-.3896-1.7793-.96-1.7793-1.9092,0-1.0498,.8696-1.8291,2.1787-1.8291,.6899,0,1.1895,.1602,1.4893,.3301l-.2397,.71c-.2197-.1201-.6694-.3203-1.2793-.3203-.9194,0-1.269,.5498-1.269,1.0098,0,.6299,.4097,.9395,1.3389,1.2988,1.1396,.4404,1.7192,.9893,1.7192,1.9795,0,1.0391-.7695,1.9385-2.3589,1.9385-.6494,0-1.3589-.1895-1.7188-.4297l.2197-.7295Z" fill="#fff" />
                                                            <path d="M119.7671,767.8314l1.0596,2.8584c.1099,.3203,.23,.7002,.3096,.9902h.02c.0903-.29,.1899-.6602,.3101-1.0098l.9595-2.8389h.9292l-1.3188,3.4482c-.6299,1.6592-1.0596,2.5088-1.6592,3.0283-.4297,.3799-.8599,.5303-1.0796,.5703l-.2197-.7402c.2197-.0693,.5098-.21,.7695-.4297,.2397-.1895,.5396-.5293,.7397-.9795,.04-.0898,.0698-.1602,.0698-.21s-.02-.1201-.0601-.2295l-1.7891-4.458h.9595Z" fill="#fff" />
                                                            <path d="M124.1573,769.1409c0-.5-.0098-.9092-.04-1.3096h.7798l.0498,.7998h.02c.2397-.46,.7998-.9092,1.5991-.9092,.6699,0,1.709,.3994,1.709,2.0586v2.8887h-.8794v-2.7891c0-.7793-.2896-1.4287-1.1191-1.4287-.5801,0-1.0298,.4092-1.1797,.8994-.04,.1104-.0601,.2598-.0601,.4102v2.9082h-.8794v-3.5283Z" fill="#fff" />
                                                            <path d="M130.1968,770.4105c.02,1.1895,.7798,1.6787,1.6592,1.6787,.6299,0,1.0093-.1094,1.3394-.25l.1499,.6299c-.3101,.1396-.8398,.2998-1.6094,.2998-1.4888,0-2.3784-.9795-2.3784-2.4385s.8594-2.6084,2.2686-2.6084c1.5791,0,1.999,1.3887,1.999,2.2783,0,.1797-.02,.3203-.0298,.4102h-3.3984Zm2.5786-.6299c.0103-.5596-.23-1.4287-1.2192-1.4287-.8896,0-1.2793,.8193-1.3491,1.4287h2.5684Z" fill="#fff" />
                                                            <path d="M134.7168,765.5726h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                            <path d="M144.315,771.3499c0,.5,.0103,.9395,.04,1.3193h-.7798l-.0498-.79h-.02c-.23,.3896-.7397,.8994-1.5991,.8994-.7598,0-1.6689-.4199-1.6689-2.1182v-2.8291h.8794v2.6787c0,.9199,.2798,1.5391,1.0796,1.5391,.5894,0,.9995-.4092,1.1592-.7998,.0498-.1299,.0801-.2891,.0801-.4492v-2.9688h.8794v3.5186Z" fill="#fff" />
                                                            <path d="M145.7769,769.3411c0-.5703-.0098-1.0596-.04-1.5098h.7695l.0303,.9502h.04c.2197-.6504,.7495-1.0596,1.3394-1.0596,.0996,0,.1694,.0098,.2495,.0293v.8301c-.0898-.0205-.1797-.0303-.2998-.0303-.6196,0-1.0596,.4697-1.1792,1.1299-.02,.1191-.04,.2598-.04,.4092v2.5791h-.8696v-3.3281Z" fill="#fff" />
                                                            <path d="M148.9273,771.7698c.2598,.1699,.7192,.3496,1.1592,.3496,.6396,0,.9395-.3203,.9395-.7197,0-.4199-.25-.6494-.8994-.8896-.8696-.3096-1.2793-.7891-1.2793-1.3691,0-.7793,.6294-1.4189,1.6689-1.4189,.4897,0,.9194,.1396,1.1895,.2998l-.2197,.6396c-.1899-.1201-.54-.2803-.9897-.2803-.5195,0-.8096,.2998-.8096,.6602,0,.3994,.29,.5791,.9194,.8193,.8398,.3193,1.2695,.7393,1.2695,1.459,0,.8496-.6597,1.4492-1.8091,1.4492-.5298,0-1.0195-.1299-1.3594-.3301l.2202-.6689Z" fill="#fff" />
                                                            <path d="M154.0664,766.4427v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M158.4668,765.9329h3.6279v.7295h-2.7588v2.2383h2.5488v.7197h-2.5488v3.0488h-.8691v-6.7363Z" fill="#fff" />
                                                            <path d="M167.3745,770.2103c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M172.8648,770.2103c0,1.7891-1.2397,2.5684-2.4087,2.5684-1.3096,0-2.3188-.959-2.3188-2.4883,0-1.6191,1.0596-2.5684,2.3984-2.5684,1.3892,0,2.3291,1.0088,2.3291,2.4883Zm-3.8379,.0498c0,1.0596,.6094,1.8594,1.4688,1.8594,.8398,0,1.4692-.79,1.4692-1.8789,0-.8193-.4097-1.8594-1.4492-1.8594s-1.4888,.96-1.4888,1.8789Z" fill="#fff" />
                                                            <path d="M175.0362,766.4427v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M177.2867,769.4105c0-.6191-.02-1.1191-.04-1.5791h.7896l.04,.8301h.02c.3599-.5898,.9297-.9395,1.7192-.9395,1.1694,0,2.0488,.9893,2.0488,2.458,0,1.7393-1.0596,2.5986-2.1987,2.5986-.6396,0-1.1992-.2793-1.4893-.7588h-.02v2.6279h-.8696v-5.2373Zm.8696,1.29c0,.1299,.02,.249,.04,.3594,.1597,.6094,.6895,1.0293,1.3193,1.0293,.9292,0,1.4692-.7598,1.4692-1.8691,0-.9688-.5098-1.7988-1.4395-1.7988-.5996,0-1.1592,.4297-1.3291,1.0898-.0303,.1094-.0601,.2393-.0601,.3594v.8301Z" fill="#fff" />
                                                            <path d="M185.6553,772.6693l-.0698-.6104h-.0303c-.2695,.3799-.7896,.7197-1.479,.7197-.9795,0-1.4795-.6895-1.4795-1.3887,0-1.1699,1.0396-1.8096,2.9087-1.7988v-.1006c0-.3994-.1099-1.1191-1.0996-1.1191-.4497,0-.9194,.1396-1.2593,.3594l-.1997-.5791c.3999-.2598,.9795-.4297,1.5889-.4297,1.4795,0,1.8394,1.0088,1.8394,1.9785v1.8096c0,.4189,.02,.8291,.0796,1.1592h-.7993Zm-.1299-2.4688c-.9595-.0205-2.0488,.1494-2.0488,1.0889,0,.5703,.3794,.8398,.8296,.8398,.6294,0,1.0293-.3994,1.1689-.8096,.0303-.0898,.0503-.1895,.0503-.2793v-.8398Z" fill="#fff" />
                                                            <path d="M188.816,766.4427v1.3887h1.2593v.6699h-1.2593v2.6084c0,.5996,.1699,.9395,.6597,.9395,.23,0,.3999-.0293,.5098-.0596l.04,.6592c-.1699,.0703-.4399,.1201-.7798,.1201-.4097,0-.7397-.1299-.9492-.3701-.25-.2598-.3398-.6895-.3398-1.2588v-2.6387h-.75v-.6699h.75v-1.1592l.8594-.2295Z" fill="#fff" />
                                                            <path d="M191.0664,765.5726h.8794v3.0186h.02c.1401-.25,.3599-.4697,.6299-.6191,.2598-.1504,.5698-.25,.8994-.25,.6499,0,1.689,.3994,1.689,2.0684v2.8789h-.8794v-2.7783c0-.7803-.2896-1.4395-1.1191-1.4395-.5698,0-1.0195,.3994-1.1797,.8789-.0498,.1201-.0601,.25-.0601,.4199v2.9189h-.8794v-7.0967Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="item-id-box-37" data-name="item-id-box">
                                                    <g id="id-box-45" data-name="id-box">
                                                        <rect x="43.367" y="760.3356" width="58.3973" height="18.125" fill="#e9427d" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text-40" data-name="id-text">
                                                        <g>
                                                            <path d="M65.1201,766.0189h1.5293v5.457h2.6782v1.2793h-4.2075v-6.7363Z" fill="#fff" />
                                                            <path d="M71.9292,767.6087h-.02l-1.2393,.5889-.25-1.1387,1.7188-.7998h1.2598v6.4961h-1.4692v-5.1465Z" fill="#fff" />
                                                            <path d="M75.5401,772.7552v-.9189l.8394-.7598c1.4194-1.2695,2.1089-1.999,2.1289-2.7588,0-.5293-.3198-.9492-1.0693-.9492-.5601,0-1.0493,.2793-1.3892,.54l-.4302-1.0898c.4897-.3701,1.2495-.6699,2.1289-.6699,1.4692,0,2.2788,.8594,2.2788,2.0391,0,1.0898-.7896,1.959-1.729,2.7988l-.5996,.499v.0205h2.4487v1.249h-4.6074Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="title-row-6" data-name="title-row">
                                                <g id="delivered-box-2" data-name="delivered-box">
                                                    <g id="base-box-43" data-name="base-box">
                                                        <rect x="217.637" y="542.8502" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.0447" />
                                                        <rect x="306.6372" y="542.8502" width="89.0002" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.0447" />
                                                    </g>
                                                    <g id="base-text-43" data-name="base-text">
                                                        <g>
                                                            <path d="M243.0518,548.5374c.5596-.0898,1.2891-.1396,2.0586-.1396,1.2793,0,2.1089,.2295,2.7588,.7188,.6997,.5205,1.1392,1.3496,1.1392,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7285-.7095,.5898-1.7891,.8691-3.1084,.8691-.7896,0-1.3491-.0498-1.729-.0996v-6.6162Zm1.5293,5.4971c.1299,.0303,.3398,.0303,.5293,.0303,1.3794,.0098,2.2788-.75,2.2788-2.3594,.0103-1.3994-.8096-2.1387-2.1187-2.1387-.3398,0-.5596,.0303-.6895,.0596v4.4082Z" fill="#fff" />
                                                            <path d="M251.1309,553.2249c.0503,.6299,.6699,.9297,1.3794,.9297,.52,0,.9395-.0703,1.3491-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.769,.2998-1.6592,0-2.6089-.959-2.6089-2.4883,0-1.2393,.77-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0298,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1597-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M255.2417,548.0872h1.519v7.0967h-1.519v-7.0967Z" fill="#fff" />
                                                            <path d="M259.5606,548.9368c0,.4199-.3198,.7598-.8193,.7598-.48,0-.7998-.3398-.7896-.7598-.0103-.4395,.3096-.7695,.7993-.7695s.7998,.3301,.8096,.7695Zm-1.5688,6.2471v-4.8877h1.519v4.8877h-1.519Z" fill="#fff" />
                                                            <path d="M261.8614,550.2962l.6592,2.2686c.1201,.4102,.21,.7998,.2803,1.1895h.0298c.0801-.3994,.1602-.7695,.27-1.1895l.6294-2.2686h1.5991l-1.8188,4.8877h-1.519l-1.7793-4.8877h1.6494Z" fill="#fff" />
                                                            <path d="M267.0508,553.2249c.0503,.6299,.6699,.9297,1.3794,.9297,.52,0,.9395-.0703,1.3491-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.769,.2998-1.6592,0-2.6089-.959-2.6089-2.4883,0-1.2393,.77-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0298,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1597-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M271.1617,551.9056c0-.7197-.02-1.1895-.04-1.6094h1.3091l.0498,.8994h.04c.25-.709,.8496-1.0088,1.3193-1.0088,.1401,0,.21,0,.3198,.0195v1.4297c-.1099-.0205-.2397-.04-.4097-.04-.5596,0-.9395,.2998-1.0396,.7695-.02,.0996-.0298,.2197-.0298,.3398v2.4785h-1.519v-3.2783Z" fill="#fff" />
                                                            <path d="M276.0508,553.2249c.0503,.6299,.6699,.9297,1.3794,.9297,.52,0,.9395-.0703,1.3491-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.769,.2998-1.6592,0-2.6089-.959-2.6089-2.4883,0-1.2393,.77-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0298,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1597-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                            <path d="M284.899,548.0872v5.6377c0,.5488,.02,1.1289,.04,1.459h-1.3496l-.0698-.7197h-.02c-.3096,.5498-.8994,.8291-1.5293,.8291-1.1592,0-2.0889-.9893-2.0889-2.5078-.0098-1.6494,1.0195-2.5986,2.189-2.5986,.5996,0,1.0693,.209,1.2896,.5488h.02v-2.6484h1.519Zm-1.519,4.2783c0-.0801-.0103-.1904-.02-.2705-.0903-.4092-.4199-.749-.8896-.749-.6895,0-1.0493,.6191-1.0493,1.3887,0,.8301,.4097,1.3496,1.0391,1.3496,.4399,0,.7896-.2998,.8799-.7295,.0298-.1104,.04-.2197,.04-.3496v-.6396Z" fill="#fff" />
                                                        </g>
                                                        <g>
                                                            <path d="M342.439,548.4476v2.5879h2.5083v-2.5879h1.5195v6.7363h-1.5195v-2.8184h-2.5083v2.8184h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M353.9663,551.7454c0,2.209-1.3394,3.5479-3.3081,3.5479-1.999,0-3.1685-1.5088-3.1685-3.4277,0-2.0186,1.2896-3.5283,3.2783-3.5283,2.0688,0,3.1982,1.5498,3.1982,3.4082Zm-4.8672,.0898c0,1.3193,.6196,2.249,1.6392,2.249,1.0293,0,1.6191-.9795,1.6191-2.2891,0-1.209-.5796-2.248-1.6294-2.248-1.0293,0-1.6289,.9795-1.6289,2.2881Z" fill="#fff" />
                                                            <path d="M355.9888,549.7269h-1.8091v-1.2793h5.1772v1.2793h-1.8389v5.457h-1.5293v-5.457Z" fill="#fff" />
                                                            <path d="M361.6885,549.7269h-1.8091v-1.2793h5.1772v1.2793h-1.8389v5.457h-1.5293v-5.457Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="type-box-48" data-name="type-box">
                                                    <g id="type-box-49" data-name="type-box">
                                                        <rect x="101.7643" y="542.8502" width="115.8727" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="type-text-43" data-name="type-text">
                                                        <g>
                                                            <path d="M121.4698,555.1839v-6.7363h1.7788l1.3994,2.4688c.3999,.709,.7993,1.5488,1.0996,2.3086h.0298c-.1001-.8896-.1299-1.7988-.1299-2.8086v-1.9688h1.3994v6.7363h-1.5996l-1.439-2.5986c-.3999-.7197-.8394-1.5889-1.1694-2.3789l-.0298,.0098c.04,.8896,.0601,1.8389,.0601,2.9385v2.0293h-1.3994Z" fill="#fff" />
                                                            <path d="M131.0982,555.1839l-.0898-.4902h-.0303c-.3198,.3896-.8193,.5996-1.3989,.5996-.9897,0-1.5791-.7197-1.5791-1.499,0-1.2695,1.1392-1.8789,2.8682-1.8691v-.0693c0-.2598-.1396-.6299-.8896-.6299-.4995,0-1.0293,.1699-1.3491,.3701l-.2798-.9795c.3398-.1904,1.0093-.4297,1.8989-.4297,1.6289,0,2.1489,.959,2.1489,2.1084v1.6992c0,.4697,.02,.9189,.0698,1.1895h-1.3691Zm-.1802-2.3086c-.7993-.0107-1.4189,.1797-1.4189,.7695,0,.3896,.2598,.5791,.5996,.5791,.3799,0,.6895-.25,.7896-.5596,.02-.0801,.0298-.1699,.0298-.2598v-.5293Z" fill="#fff" />
                                                            <path d="M133.5996,551.8558c0-.6104-.02-1.1299-.04-1.5596h1.2793l.0698,.6602h.0298c.21-.3105,.6396-.7695,1.4795-.7695,.6294,0,1.1294,.3193,1.3394,.8291h.02c.1797-.25,.3994-.4502,.6294-.5898,.27-.1602,.5698-.2393,.9297-.2393,.9395,0,1.6489,.6592,1.6489,2.1182v2.8789h-1.479v-2.6592c0-.709-.23-1.1191-.7197-1.1191-.3501,0-.5996,.2402-.6997,.5303-.04,.1094-.0601,.2695-.0601,.3896v2.8584h-1.479v-2.7383c0-.6201-.2197-1.04-.6997-1.04-.3896,0-.6196,.2998-.7095,.5498-.0503,.1201-.0601,.2598-.0601,.3799v2.8486h-1.479v-3.3281Z" fill="#fff" />
                                                            <path d="M143.3687,553.2249c.0503,.6299,.6699,.9297,1.3794,.9297,.5195,0,.9395-.0703,1.3491-.2002l.2002,1.0293c-.5,.2002-1.1094,.2998-1.769,.2998-1.6592,0-2.6089-.959-2.6089-2.4883,0-1.2393,.77-2.6084,2.4688-2.6084,1.5791,0,2.1787,1.2285,2.1787,2.4385,0,.2598-.0298,.4893-.0498,.5996h-3.1484Zm1.7891-1.04c0-.3691-.1597-.9893-.8594-.9893-.6396,0-.8994,.5801-.9395,.9893h1.7988Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="id-box-46" data-name="id-box">
                                                    <g id="id-box_copy_13" data-name="id-box copy 13">
                                                        <rect x="43.367" y="542.8502" width="58.3973" height="18.125" fill="#fff" stroke="#fff" strokeMiterlimit="10" />
                                                    </g>
                                                    <g id="id-text_copy_13" data-name="id-text copy 13">
                                                        <g>
                                                            <path d="M69.8496,548.4788v6.7363h-1.5293v-6.7363h1.5293Z" fill="#fff" />
                                                            <path d="M71.1704,548.5687c.5596-.0898,1.2891-.1396,2.0591-.1396,1.2793,0,2.1084,.2295,2.7583,.7188,.6997,.5205,1.1392,1.3496,1.1392,2.5391,0,1.2891-.4697,2.1787-1.1191,2.7285-.7095,.5898-1.7891,.8691-3.1084,.8691-.7896,0-1.3491-.0498-1.729-.0996v-6.6162Zm1.5293,5.4971c.1299,.0303,.3398,.0303,.5298,.0303,1.3789,.0098,2.2783-.75,2.2783-2.3594,.0103-1.3994-.8096-2.1387-2.1187-2.1387-.3398,0-.5596,.0303-.6895,.0596v4.4082Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="main-title-6" data-name="main-title">
                                                <g id="feltham_ASP-2" data-name="feltham ASP">
                                                    <path d="M45.2539,529.4642h4.9414v1.498h-3.1064v1.8477h2.9023v1.4873h-2.9023v3.25h-1.835v-8.083Z" fill="#e9427d" />
                                                    <path d="M52.6094,535.1966c.0601,.7559,.8037,1.1152,1.6553,1.1152,.6235,0,1.1274-.084,1.6191-.2402l.2397,1.2363c-.5996,.2393-1.3315,.3594-2.123,.3594-1.9907,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9233-3.1309,2.9624-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0601,.7197h-3.7778Zm2.147-1.248c0-.4434-.1919-1.1865-1.0317-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1587Z" fill="#e9427d" />
                                                    <path d="M57.542,529.0325h1.8232v8.5146h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M62.8809,530.0032v1.6797h1.3071v1.3428h-1.3071v2.1221c0,.709,.168,1.0322,.7197,1.0322,.2275,0,.4077-.0244,.5396-.0479l.0122,1.3789c-.2402,.0957-.6719,.1562-1.1875,.1562-.5879,0-1.0796-.2041-1.3672-.5039-.3359-.3477-.5039-.9121-.5039-1.7393v-2.3984h-.7793v-1.3428h.7793v-1.2715l1.7871-.4082Z" fill="#e9427d" />
                                                    <path d="M65.2456,529.0325h1.8232v3.3457h.0239c.1919-.252,.4316-.4434,.7197-.5996,.2637-.1445,.6113-.2285,.9473-.2285,1.1636,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8228v-3.2256c0-.7676-.2642-1.2959-.9478-1.2959-.4795,0-.7793,.3115-.9111,.6357-.0483,.1191-.0601,.2754-.0601,.4082v3.4775h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M75.6123,537.5472l-.1079-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6792,.7197-1.1875,0-1.895-.8633-1.895-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2422v-.084c0-.3125-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2031-1.6191,.4434l-.3359-1.1758c.4077-.2275,1.2114-.5156,2.2788-.5156,1.9551,0,2.5786,1.1523,2.5786,2.5312v2.0391c0,.5625,.0239,1.1035,.084,1.4268h-1.6431Zm-.2158-2.7705c-.9595-.0117-1.7031,.2158-1.7031,.9238,0,.4668,.3115,.6953,.7192,.6953,.4561,0,.8276-.2998,.9478-.6719,.0239-.0957,.0361-.2031,.0361-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M78.6138,533.554c0-.7324-.0239-1.3555-.0479-1.8711h1.5352l.084,.791h.0361c.2515-.3711,.7676-.9238,1.7749-.9238,.7554,0,1.3555,.3848,1.6069,.9961h.0239c.2163-.3008,.48-.54,.7559-.708,.3237-.1924,.6836-.2881,1.1152-.2881,1.1274,0,1.979,.792,1.979,2.543v3.4541h-1.7749v-3.1904c0-.8516-.2759-1.3438-.8638-1.3438-.4194,0-.7192,.2891-.8394,.6367-.0479,.1309-.0723,.3232-.0723,.4668v3.4307h-1.7749v-3.2861c0-.7441-.2637-1.248-.8394-1.248-.4678,0-.7437,.3604-.8516,.6602-.0601,.1445-.0718,.3125-.0718,.4561v3.418h-1.7754v-3.9932Z" fill="#e9427d" />
                                                    <path d="M91.4175,529.5716c.564-.0957,1.3555-.168,2.4707-.168,1.1274,0,1.9312,.2158,2.4707,.6475,.5156,.4082,.8633,1.0791,.8633,1.8711s-.2637,1.4639-.7437,1.9189c-.6235,.5879-1.5469,.8516-2.6265,.8516-.2397,0-.4556-.0117-.6235-.0361v2.8906h-1.811v-7.9756Zm1.811,3.6699c.1558,.0361,.3477,.0479,.6118,.0479,.9712,0,1.5708-.4912,1.5708-1.3193,0-.7432-.5156-1.1875-1.4268-1.1875-.3721,0-.624,.0361-.7559,.0723v2.3867Z" fill="#e9427d" />
                                                    <path d="M98.3296,529.0325h1.8232v3.3457h.0239c.1919-.252,.4316-.4434,.7197-.5996,.2637-.1445,.6113-.2285,.9473-.2285,1.1636,0,2.0508,.8037,2.0508,2.5664v3.4307h-1.8228v-3.2256c0-.7676-.2642-1.2959-.9478-1.2959-.4795,0-.7793,.3115-.9111,.6357-.0483,.1191-.0601,.2754-.0601,.4082v3.4775h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M108.6958,537.5472l-.1079-.5879h-.0361c-.3838,.4678-.9834,.7197-1.6792,.7197-1.187,0-1.895-.8633-1.895-1.7988,0-1.5234,1.3672-2.2549,3.4424-2.2422v-.084c0-.3125-.168-.7559-1.0674-.7559-.5996,0-1.2354,.2031-1.6191,.4434l-.3359-1.1758c.4077-.2275,1.2114-.5156,2.2788-.5156,1.9551,0,2.5786,1.1523,2.5786,2.5312v2.0391c0,.5625,.0239,1.1035,.084,1.4268h-1.6431Zm-.2158-2.7705c-.9595-.0117-1.7031,.2158-1.7031,.9238,0,.4668,.3115,.6953,.7192,.6953,.4561,0,.8276-.2998,.9478-.6719,.0239-.0957,.0361-.2031,.0361-.3115v-.6357Z" fill="#e9427d" />
                                                    <path d="M111.6856,535.9525c.3359,.2031,1.0312,.4434,1.5708,.4434,.5518,0,.7798-.1914,.7798-.4922,0-.2988-.1797-.4434-.8633-.6719-1.2114-.4072-1.6792-1.0664-1.6675-1.7617,0-1.0918,.9355-1.9199,2.3867-1.9199,.6836,0,1.2954,.1562,1.6553,.3359l-.3237,1.2598c-.2642-.1445-.7676-.3359-1.2715-.3359-.4438,0-.6958,.1797-.6958,.4795,0,.2764,.228,.4199,.9478,.6719,1.1152,.3838,1.583,.9473,1.5952,1.8115,0,1.0908-.8638,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4082l.3237-1.3066Z" fill="#e9427d" />
                                                    <path d="M118.3086,535.1966c.0601,.7559,.8037,1.1152,1.6553,1.1152,.6235,0,1.1274-.084,1.6191-.2402l.2397,1.2363c-.5996,.2393-1.3315,.3594-2.123,.3594-1.9907,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9233-3.1309,2.9624-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0601,.7197h-3.7778Zm2.147-1.248c0-.4434-.1919-1.1865-1.0317-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1587Z" fill="#e9427d" />
                                                    <path d="M130.6426,531.2513h-3.0225l-.168,1.1992c.168-.0244,.312-.0244,.5039-.0244,.7437,0,1.499,.168,2.0508,.5635,.5879,.3965,.9473,1.043,.9473,1.9551,0,1.4512-1.2471,2.7344-3.3462,2.7344-.9473,0-1.7388-.2158-2.1704-.4434l.3237-1.3672c.3477,.168,1.0552,.3828,1.7632,.3828,.7554,0,1.5591-.3594,1.5591-1.1865,0-.8037-.6357-1.2959-2.1948-1.2959-.4321,0-.7319,.0244-1.0557,.0723l.5156-4.0898h4.2939v1.5Z" fill="#e9427d" />
                                                    <path d="M137.5196,533.7933v1.2119h-3.1421v-1.2119h3.1421Z" fill="#e9427d" />
                                                    <path d="M141.0972,529.4642h1.835v6.5479h3.2144v1.5352h-5.0493v-8.083Z" fill="#e9427d" />
                                                    <path d="M154.1729,533.4212c0,2.6504-1.6069,4.2578-3.9697,4.2578-2.3984,0-3.8018-1.8105-3.8018-4.1133,0-2.4229,1.5469-4.2344,3.9336-4.2344,2.4829,0,3.8379,1.8594,3.8379,4.0898Zm-5.8408,.1084c0,1.583,.7437,2.6982,1.9668,2.6982,1.2354,0,1.9434-1.1758,1.9434-2.7461,0-1.4512-.6958-2.6992-1.9551-2.6992-1.2354,0-1.9551,1.1758-1.9551,2.7471Z" fill="#e9427d" />
                                                    <path d="M161.314,537.3196c-.3359,.168-1.0918,.3477-2.0752,.3477-2.7944,0-4.2334-1.7393-4.2334-4.042,0-2.7588,1.9668-4.2939,4.4136-4.2939,.9473,0,1.667,.1924,1.9907,.3604l-.3716,1.4512c-.3721-.1562-.8877-.2998-1.5352-.2998-1.4512,0-2.5786,.875-2.5786,2.6738,0,1.6191,.9595,2.6387,2.5903,2.6387,.5518,0,1.1636-.1191,1.5234-.2637l.2759,1.4277Z" fill="#e9427d" />
                                                    <path d="M170.8775,537.3196c-.3359,.168-1.0918,.3477-2.0752,.3477-2.7944,0-4.2334-1.7393-4.2334-4.042,0-2.7588,1.9668-4.2939,4.4136-4.2939,.9473,0,1.667,.1924,1.9907,.3604l-.3716,1.4512c-.3721-.1562-.8877-.2998-1.5352-.2998-1.4512,0-2.5786,.875-2.5786,2.6738,0,1.6191,.9595,2.6387,2.5903,2.6387,.5518,0,1.1636-.1191,1.5234-.2637l.2759,1.4277Z" fill="#e9427d" />
                                                    <path d="M177.3096,535.6644c0,.7676,.0239,1.3916,.0479,1.8828h-1.583l-.084-.8271h-.0361c-.228,.3594-.7798,.959-1.835,.959-1.1875,0-2.063-.7441-2.063-2.5547v-3.4414h1.835v3.1543c0,.8516,.2759,1.3672,.9116,1.3672,.5039,0,.7915-.3477,.9116-.6367,.0479-.1074,.0601-.252,.0601-.3945v-3.4902h1.835v3.9814Z" fill="#e9427d" />
                                                    <path d="M178.7285,537.5472c.0239-.3838,.0479-1.0908,.0479-1.751v-6.7637h1.8232v3.3457h.0239c.3477-.5039,.9595-.8281,1.7749-.8281,1.4033,0,2.4346,1.1641,2.4229,2.9629,0,2.1113-1.3433,3.166-2.6865,3.166-.6836,0-1.3433-.252-1.7632-.9473h-.0239l-.0718,.8154h-1.5474Zm1.8711-2.4951c0,.1211,.0117,.2285,.0361,.3242,.1196,.4922,.5396,.8633,1.0674,.8633,.7793,0,1.2593-.5996,1.2593-1.6543,0-.9121-.4077-1.6309-1.2593-1.6309-.4922,0-.9478,.3711-1.0674,.8984-.0244,.1084-.0361,.2168-.0361,.3359v.8633Z" fill="#e9427d" />
                                                    <path d="M187.8355,530.0511c0,.5039-.3838,.9111-.9839,.9111-.5752,0-.959-.4072-.9473-.9111-.0117-.5273,.3721-.9229,.9595-.9229,.5879,0,.9595,.3955,.9717,.9229Zm-1.8833,7.4961v-5.8643h1.8232v5.8643h-1.8232Z" fill="#e9427d" />
                                                    <path d="M193.6421,537.4036c-.3237,.1436-.9355,.2637-1.6313,.2637-1.895,0-3.106-1.1514-3.106-2.998,0-1.7148,1.1753-3.1191,3.3579-3.1191,.48,0,1.0073,.084,1.3911,.2285l-.2876,1.3555c-.2158-.0957-.54-.1797-1.0195-.1797-.9595,0-1.583,.6836-1.5713,1.6426,0,1.0791,.7197,1.6426,1.6074,1.6426,.4316,0,.7676-.0723,1.0435-.1797l.2158,1.3438Z" fill="#e9427d" />
                                                    <path d="M194.6519,529.0325h1.8232v8.5146h-1.8232v-8.5146Z" fill="#e9427d" />
                                                    <path d="M199.3555,535.1966c.0601,.7559,.8037,1.1152,1.6553,1.1152,.6235,0,1.1274-.084,1.6191-.2402l.2397,1.2363c-.5996,.2393-1.3315,.3594-2.123,.3594-1.9907,0-3.1299-1.1514-3.1299-2.9863,0-1.4873,.9233-3.1309,2.9624-3.1309,1.8945,0,2.6143,1.4756,2.6143,2.9268,0,.3115-.0361,.5879-.0601,.7197h-3.7778Zm2.147-1.248c0-.4434-.1919-1.1865-1.0317-1.1865-.7676,0-1.0791,.6953-1.127,1.1865h2.1587Z" fill="#e9427d" />
                                                    <path d="M204.2764,535.9525c.3359,.2031,1.0312,.4434,1.5708,.4434,.5518,0,.7798-.1914,.7798-.4922,0-.2988-.1797-.4434-.8633-.6719-1.2114-.4072-1.6792-1.0664-1.6675-1.7617,0-1.0918,.9355-1.9199,2.3867-1.9199,.6836,0,1.2954,.1562,1.6553,.3359l-.3237,1.2598c-.2642-.1445-.7676-.3359-1.2715-.3359-.4438,0-.6958,.1797-.6958,.4795,0,.2764,.228,.4199,.9478,.6719,1.1152,.3838,1.583,.9473,1.5952,1.8115,0,1.0908-.8638,1.8945-2.543,1.8945-.7676,0-1.4512-.168-1.8945-.4082l.3237-1.3066Z" fill="#e9427d" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="bubbles-group-6" data-name="bubbles-group">
                                            <g id="loc-001-bubble-2" data-name="loc-001-bubble">
                                                <g id="body-25" data-name="body">
                                                    <rect x="196.9076" y="75.6393" width="29.6397" height="2.0011" transform="translate(-.0907 .251) rotate(-.0679)" fill="#e9427d" />
                                                    <circle cx="228.587" cy="72.6105" r="12.3333" fill="#e9427d" />
                                                    <polygon points="187.1238 76.2079 202.4277 83.9131 203.5721 71.4088 187.1238 76.2079" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-25" data-name="id">
                                                    <g>
                                                        <path d="M221.0039,68.1693h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M231.4776,72.2708c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M234.7422,69.4407h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9111h.8994v7.7959h-1.0195v-6.8125Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-002-bubble-2" data-name="loc-002-bubble">
                                                <g id="body-26" data-name="body">
                                                    <rect x="272.911" y="133.7982" width="29.6397" height="2.0011" transform="translate(-23.7196 197.7143) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="300.1943" cy="122.0177" r="12.3333" fill="#e9427d" />
                                                    <polygon points="267.9061 149.4443 284.4594 145.0212 276.9984 134.9216 267.9061 149.4443" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-26" data-name="id">
                                                    <g>
                                                        <path d="M292.6114,117.5765h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M303.085,121.678c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M304.0596,125.6605v-.6475l.8271-.8037c1.9912-1.8955,2.8906-2.9023,2.9023-4.0781,0-.791-.3838-1.5234-1.5469-1.5234-.708,0-1.2959,.3604-1.6553,.6602l-.3359-.7441c.54-.4551,1.3076-.791,2.207-.791,1.6787,0,2.3867,1.1514,2.3867,2.2666,0,1.4395-1.0439,2.6025-2.6865,4.1855l-.624,.5762v.0234h3.502v.876h-4.9766Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-003-bubble-2" data-name="loc-003-bubble">
                                                <g id="body-27" data-name="body">
                                                    <rect x="409.9138" y="294.141" width="29.6397" height="2.0011" transform="translate(-92.2421 310.7397) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="437.197" cy="282.3605" r="12.3333" fill="#e9427d" />
                                                    <polygon points="404.9088 309.7871 421.4622 305.364 414.0011 295.2644 404.9088 309.7871" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-27" data-name="id">
                                                    <g>
                                                        <path d="M429.6138,277.9193h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M440.0874,282.0208c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M441.314,284.7913c.2998,.1924,.9951,.4922,1.7266,.4922,1.3555,0,1.7754-.8633,1.7627-1.5117-.0117-1.0908-.9951-1.5586-2.0146-1.5586h-.5879v-.792h.5879c.7676,0,1.7393-.3955,1.7393-1.3193,0-.623-.3955-1.1748-1.3672-1.1748-.624,0-1.2236,.2754-1.5596,.5156l-.2754-.7676c.4072-.2998,1.1992-.5996,2.0391-.5996,1.5352,0,2.2305,.9111,2.2305,1.8584,0,.8037-.4795,1.4873-1.4395,1.835v.0244c.96,.1924,1.7393,.9111,1.7393,2.0029,0,1.2471-.9717,2.3389-2.8428,2.3389-.875,0-1.6426-.2764-2.0264-.5283l.2881-.8154Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-004-bubble-2" data-name="loc-004-bubble">
                                                <g id="body-28" data-name="body">
                                                    <rect x="498.3728" y="402.3194" width="29.6397" height="2.0011" transform="translate(-139.2523 384.6302) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="525.6561" cy="390.5389" r="12.3333" fill="#e9427d" />
                                                    <polygon points="493.3679 417.9655 509.9213 413.5425 502.4602 403.4429 493.3679 417.9655" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-28" data-name="id">
                                                    <g>
                                                        <path d="M518.0733,386.098h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M528.5469,390.1995c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6152,1.1279-4.0547,2.7109-4.0547,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0273,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M532.7832,394.181v-2.123h-3.6221v-.6953l3.4785-4.9766h1.1387v4.8457h1.0918v.8262h-1.0918v2.123h-.9951Zm0-2.9492v-2.6035c0-.4082,.0117-.8154,.0361-1.2227h-.0361c-.2393,.4551-.4316,.791-.6475,1.1504l-1.9072,2.6514v.0244h2.5547Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-005-bubble-2" data-name="loc-005-bubble">
                                                <g id="body-29" data-name="body">
                                                    <rect x="628.9175" y="569.9796" width="29.6397" height="2.0011" transform="translate(-213.398 495.2491) rotate(-36.5231)" fill="#e9427d" />
                                                    <circle cx="656.2007" cy="558.1992" r="12.3333" fill="#e9427d" />
                                                    <polygon points="623.9125 585.6258 640.4659 581.2027 633.0049 571.1031 623.9125 585.6258" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-29" data-name="id">
                                                    <g>
                                                        <path d="M648.6172,553.7581h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M659.0909,557.8597c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M664.7188,554.9339h-2.9746l-.2998,2.0029c.1797-.0244,.3477-.0479,.6357-.0479,.5996,0,1.1992,.1318,1.6787,.4189,.6123,.3486,1.1152,1.0195,1.1152,2.0029,0,1.5234-1.2109,2.6631-2.9023,2.6631-.8516,0-1.5703-.2402-1.9424-.4795l.2637-.8037c.3242,.1914,.959,.4316,1.667,.4316,.9951,0,1.8467-.6475,1.8467-1.6914-.0117-1.0068-.6836-1.7266-2.2422-1.7266-.4443,0-.792,.0479-1.0801,.084l.5039-3.7422h3.7305v.8877Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-006-bubble-2" data-name="loc-006-bubble">
                                                <g id="body-30" data-name="body">
                                                    <rect x="673.5122" y="621.8491" width="29.6397" height="2.0011" transform="translate(-227.6566 773.5399) rotate(-51.483)" fill="#e9427d" />
                                                    <circle cx="698.9568" cy="609.3238" r="12.3333" fill="#e9427d" />
                                                    <polygon points="672.9598 642.1165 687.8103 633.5702 677.995 625.7389 672.9598 642.1165" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-30" data-name="id">
                                                    <g>
                                                        <path d="M691.3741,604.8831h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M701.8477,608.9847c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0264,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                        <path d="M707.2715,605.9144c-.2158-.0117-.4922,0-.792,.0479-1.6543,.2754-2.5303,1.4873-2.71,2.7705h.0361c.3711-.4922,1.0195-.8994,1.8828-.8994,1.3789,0,2.3506,.9951,2.3506,2.5186,0,1.4268-.9717,2.7461-2.5908,2.7461-1.667,0-2.7578-1.2949-2.7578-3.3223,0-1.5352,.5508-2.7461,1.3184-3.5137,.6484-.6357,1.5117-1.0312,2.4951-1.1514,.3115-.0479,.5752-.0596,.7676-.0596v.8633Zm-.2998,4.4971c0-1.1152-.6357-1.7871-1.6074-1.7871-.6357,0-1.2227,.3965-1.5107,.96-.0723,.1201-.1201,.2754-.1201,.4678,.0244,1.2832,.6113,2.2305,1.7148,2.2305,.9121,0,1.5234-.7549,1.5234-1.8711Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-007-bubble-2" data-name="loc-007-bubble">
                                                <g id="body-31" data-name="body">
                                                    <rect x="710.7915" y="745.6799" width="29.6397" height="2.0011" transform="translate(-310.481 849.4238) rotate(-51.483)" fill="#e9427d" />
                                                    <circle cx="736.2362" cy="733.1546" r="12.3333" fill="#e9427d" />
                                                    <polygon points="710.2392 765.9472 725.0897 757.4009 715.2744 749.5697 710.2392 765.9472" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-31" data-name="id">
                                                    <g>
                                                        <path d="M728.6534,728.7132h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M739.127,732.8148c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M745.1621,729.0013v.6953l-3.3936,7.1006h-1.0918l3.3818-6.8965v-.0244h-3.8135v-.875h4.917Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-008-bubble-2" data-name="loc-008-bubble">
                                                <g id="body-32" data-name="body">
                                                    <rect x="713.2935" y="877.772" width="2.0011" height="29.6397" transform="translate(-428.915 895.6144) rotate(-51.483)" fill="#e9427d" />
                                                    <circle cx="735.3902" cy="907.1375" r="12.3333" fill="#e9427d" />
                                                    <polygon points="695.0272 877.2196 703.5735 892.0701 711.4048 882.2548 695.0272 877.2196" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-32" data-name="id">
                                                    <g>
                                                        <path d="M727.8067,902.6966h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M738.2803,906.7982c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4639,2.5781,3.9463Zm-4.2334,.1201c0,2.0264,.623,3.1777,1.583,3.1777,1.0791,0,1.5947-1.2588,1.5947-3.25,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                        <path d="M739.1582,908.8011c0-.9834,.5879-1.6797,1.5479-2.0869l-.0127-.0361c-.8633-.4072-1.2354-1.0791-1.2354-1.751,0-1.2354,1.0439-2.0752,2.4111-2.0752,1.5107,0,2.2666,.9482,2.2666,1.9189,0,.6602-.3232,1.3672-1.2832,1.8232v.0361c.9717,.3838,1.5713,1.0674,1.5713,2.0146,0,1.3555-1.1631,2.2666-2.6504,2.2666-1.6318,0-2.6152-.9707-2.6152-2.1104Zm4.1982-.0479c0-.9473-.6602-1.4033-1.7148-1.7031-.9121,.2637-1.4033,.8633-1.4033,1.6064-.0361,.792,.5635,1.4873,1.5586,1.4873,.9473,0,1.5596-.5869,1.5596-1.3906Zm-2.9023-3.8984c0,.7803,.5879,1.1992,1.4873,1.4395,.6709-.2275,1.1875-.708,1.1875-1.415,0-.624-.3721-1.2715-1.3193-1.2715-.876,0-1.3555,.5762-1.3555,1.2471Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-009-bubble-2" data-name="loc-009-bubble">
                                                <g id="body-33" data-name="body">
                                                    <rect x="729.979" y="992.458" width="29.6397" height="2.0011" transform="translate(-151.7674 134.4742) rotate(-9.3674)" fill="#e9427d" />
                                                    <circle cx="765.5655" cy="990.0581" r="12.3333" fill="#e9427d" />
                                                    <polygon points="720.4749 997.4415 737.2223 1001.0611 735.1933 988.6695 720.4749 997.4415" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-33" data-name="id">
                                                    <g>
                                                        <path d="M757.9825,985.6165h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M768.4561,989.7181c0,2.6514-.9834,4.1143-2.71,4.1143-1.5234,0-2.5547-1.4277-2.5791-4.0059,0-2.6143,1.1279-4.0537,2.7109-4.0537,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0273,.623,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1777-1.583-3.1777-.9229,0-1.5947,1.127-1.5947,3.25Z" fill="#fff" />
                                                        <path d="M770.042,992.9564c.2275,.0244,.4922,0,.8516-.0361,.6113-.084,1.1875-.335,1.6309-.7549,.5156-.4678,.8877-1.1514,1.0312-2.0752h-.0352c-.4326,.5273-1.0557,.8398-1.835,.8398-1.4033,0-2.3027-1.0557-2.3027-2.3867,0-1.4756,1.0674-2.7705,2.6621-2.7705s2.5781,1.2949,2.5781,3.2861c0,1.7148-.5752,2.9141-1.3428,3.6582-.5996,.5869-1.4277,.9473-2.2666,1.043-.3838,.0605-.7197,.0723-.9717,.0605v-.8643Zm.3838-4.4854c0,.9717,.5879,1.6553,1.499,1.6553,.708,0,1.2598-.3477,1.5352-.8154,.0605-.0957,.0957-.2158,.0957-.3838,0-1.3311-.4912-2.3506-1.5947-2.3506-.8994,0-1.5352,.791-1.5352,1.8945Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-010-bubble-2" data-name="loc-010-bubble">
                                                <g id="body-34" data-name="body">
                                                    <rect x="710.0494" y="1208.6598" width="29.6397" height="2.0011" transform="translate(-443.2006 419.5171) rotate(-24.9921)" fill="#e9427d" />
                                                    <circle cx="743.6235" cy="1202.4912" r="12.3333" fill="#e9427d" />
                                                    <polygon points="702.5169 1220.0474 719.6204 1219.0226 714.3288 1207.6354 702.5169 1220.0474" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-34" data-name="id">
                                                    <g>
                                                        <path d="M736.0401,1198.0501h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M743.6231,1199.3216h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9121h.8994v7.7969h-1.0195v-6.8125Z" fill="#fff" />
                                                        <path d="M752.669,1202.1517c0,2.6504-.9834,4.1133-2.71,4.1133-1.5234,0-2.5547-1.4268-2.5791-4.0059,0-2.6133,1.1279-4.0527,2.7109-4.0527,1.6426,0,2.5781,1.4629,2.5781,3.9453Zm-4.2334,.1201c0,2.0264,.624,3.1787,1.583,3.1787,1.0791,0,1.5947-1.2598,1.5947-3.251,0-1.9189-.4912-3.1787-1.583-3.1787-.9229,0-1.5947,1.1279-1.5947,3.251Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-011-bubble-2" data-name="loc-011-bubble">
                                                <g id="body-35" data-name="body">
                                                    <rect x="735.486" y="1261.3913" width="2.0011" height="29.6397" transform="translate(-733.1062 1383.0966) rotate(-64.1711)" fill="#e9427d" />
                                                    <circle cx="755.3048" cy="1285.3475" r="12.3333" fill="#e9427d" />
                                                    <polygon points="714.3138 1265.4462 725.9132 1278.0569 731.3973 1266.7612 714.3138 1265.4462" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-35" data-name="id">
                                                    <g>
                                                        <path d="M747.7217,1280.9066h1.0439v7.208h3.4541v.875h-4.498v-8.083Z" fill="#fff" />
                                                        <path d="M755.3047,1282.178h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9111h.8994v7.7949h-1.0195v-6.8115Z" fill="#fff" />
                                                        <path d="M761.46,1282.178h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9111h.8994v7.7949h-1.0195v-6.8115Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="loc-012-bubble-2" data-name="loc-012-bubble">
                                                <g id="body-36" data-name="body">
                                                    <polygon points="590.6871 1390.6392 590.8944 1392.6296 546.0745 1396.2831 545.8672 1394.2927 590.6871 1390.6392" fill="#e9427d" />
                                                    <circle cx="536.6801" cy="1393.5398" r="12.3333" fill="#e9427d" />
                                                    <polygon points="600.569 1390.6464 584.0674 1386.0339 585.3534 1398.5246 600.569 1390.6464" fill="#e9427d" fillRule="evenodd" />
                                                </g>
                                                <g id="id-36" data-name="id">
                                                    <g>
                                                        <path d="M529.0967,1389.0989h1.0439v7.208h3.4541v.876h-4.498v-8.084Z" fill="#fff" />
                                                        <path d="M536.6797,1390.3704h-.0234l-1.3555,.7314-.2041-.8037,1.7031-.9121h.8994v7.7969h-1.0195v-6.8125Z" fill="#fff" />
                                                        <path d="M540.545,1397.1829v-.6484l.8271-.8027c1.9912-1.8955,2.8906-2.9023,2.9023-4.0781,0-.791-.3838-1.5234-1.5469-1.5234-.708,0-1.2959,.3594-1.6553,.6602l-.3359-.7441c.54-.4551,1.3076-.791,2.207-.791,1.6787,0,2.3867,1.1504,2.3867,2.2666,0,1.4385-1.0439,2.6025-2.6865,4.1855l-.624,.5752v.0244h3.502v.876h-4.9766Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="feltham-signal-assets-group" className={props.signalAssetsDisplayToggle}>
                                <g id="feltham-signals-group-2" data-name="feltham-signals-group">
                                    <g id="signal-BEF2259BR-2" data-name="signal-BEF2259BR">
                                        <g id="head-41" data-name="head">
                                            <path d="M1003.1546,888.6216v18.5159h18.504v-18.5159h-18.504Zm9.252,2.5799c3.3429,0,6.104,2.4589,6.5932,5.6651h-13.1864c.4892-3.2062,3.2503-5.6651,6.5932-5.6651Zm0,13.3562c-3.0193,0-5.568-2.0049-6.394-4.7552h12.7881c-.826,2.7502-3.3747,4.7552-6.394,4.7552Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2281BR-2" data-name="signal-BEF2281BR">
                                        <g id="head-42" data-name="head">
                                            <path d="M240.7279,319.1799v18.5159h18.504v-18.5159h-18.504Zm9.252,2.5799c3.3429,0,6.104,2.4589,6.5932,5.6651h-13.1864c.4892-3.2062,3.2503-5.6651,6.5932-5.6651Zm0,13.3562c-3.0193,0-5.568-2.0049-6.394-4.7552h12.7881c-.826,2.7502-3.3747,4.7552-6.394,4.7552Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2283BR-2" data-name="signal-BEF2283BR">
                                        <g id="head-43" data-name="head">
                                            <path d="M142.9733,207.9389v18.5159h18.504v-18.5159h-18.504Zm9.252,2.58c1.392,0,2.688,.42,3.756,1.152l-9.288,9.288c-.72-1.068-1.152-2.364-1.152-3.756,0-3.6959,3-6.6839,6.684-6.6839Zm0,13.356c-1.236,0-2.388-.336-3.372-.924l9.132-9.132c.5881,.9959,.924,2.1479,.924,3.3839,0,3.6841-3,6.6721-6.684,6.6721Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2262BR-2" data-name="signal-BEF2262BR">
                                        <g id="head-44" data-name="head">
                                            <path d="M841.4301,740.2376v18.5159h18.504v-18.5159h-18.504Zm9.252,2.5799c3.3429,0,6.104,2.4589,6.5932,5.6651h-13.1864c.4892-3.2062,3.2503-5.6651,6.5932-5.6651Zm0,13.3562c-3.0193,0-5.568-2.0049-6.394-4.7552h12.7881c-.826,2.7502-3.3747,4.7552-6.394,4.7552Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2259-2" data-name="signal-BEF2259">
                                        <g id="head-45" data-name="head">
                                            <path d="M955.8817,878.5453h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2258-2" data-name="signal-BEF2258">
                                        <g id="head-46" data-name="head">
                                            <path d="M968.7079,734.3764h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2262-2" data-name="signal-BEF2262">
                                        <g id="head-47" data-name="head">
                                            <path d="M912.7219,733.9245h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2264-2" data-name="signal-BEF2264">
                                        <g id="head-48" data-name="head">
                                            <path d="M796.2173,735.0268h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2261-2" data-name="signal-BEF2261">
                                        <g id="head-49" data-name="head">
                                            <path d="M898.8114,878.122h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2263-2" data-name="signal-BEF2263">
                                        <g id="head-50" data-name="head">
                                            <path d="M850.372,879.5657h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2265-2" data-name="signal-BEF2265">
                                        <g id="head-51" data-name="head">
                                            <path d="M797.6049,861.1612h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2266-2" data-name="signal-BEF2266">
                                        <g id="signal-head-01">
                                            <g id="head-52" data-name="head">
                                                <path d="M499.7688,700.1312v6.6899s0,15.1301,0,15.1301c0,2.21,1.42,4.01,3.17,4.01h5.06c1.41,0,2.55-1.4399,2.55-3.23v-15.9102s0-6.8301,0-6.8301c0-1.5398-.98-2.7798-2.19-2.7798h-2.14v-.01h-1.57s0,.01,0,.01h-2.58c-1.27,0-2.3,1.3101-2.3,2.9199Zm6.11,17.1001c1.01,.24,1.83,1.0701,2.08,2.0801,.52,2.1499-1.37,4.0298-3.52,3.52-1.01-.25-1.84-1.0801-2.08-2.0901-.52-2.1501,1.37-4.03,3.52-3.51Zm0-8.9199c1.01,.25,1.83,1.0798,2.08,2.0898,.52,2.1401-1.37,4.03-3.52,3.51-1.01-.24-1.84-1.0701-2.08-2.0798-.52-2.1501,1.37-4.03,3.52-3.52Zm-1.4399-2.8301c-1.01-.2402-1.84-1.0701-2.08-2.0801-.52-2.1499,1.37-4.03,3.52-3.51,1.01,.24,1.83,1.0701,2.08,2.0798,.52,2.1501-1.37,4.0303-3.52,3.5103Z" fill="#e9427d" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="signal-BEF2991-2" data-name="signal-BEF2991">
                                        <g id="head-53" data-name="head">
                                            <path d="M528.4026,836.3916h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2982-2" data-name="signal-BEF2982">
                                        <g id="head-54" data-name="head">
                                            <path d="M755.5078,1129.4855h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2980-2" data-name="signal-BEF2980">
                                        <g id="head-55" data-name="head">
                                            <path d="M568.3423,1196.7712h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2975-2" data-name="signal-BEF2975">
                                        <g id="head-56" data-name="head">
                                            <path d="M546.9962,1304.2215h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2994-2" data-name="signal-BEF2994">
                                        <g id="head-57" data-name="head">
                                            <path d="M710.9732,902.69h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2984-2" data-name="signal-BEF2984">
                                        <g id="head-58" data-name="head">
                                            <path d="M749.622,1031.6563h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2978-2" data-name="signal-BEF2978">
                                        <g id="head-59" data-name="head">
                                            <path d="M746.2793,1329.936h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2973-2" data-name="signal-BEF2973">
                                        <g id="head-60" data-name="head">
                                            <path d="M578.6029,1344.2874h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2987-2" data-name="signal-BEF2987">
                                        <g id="head-61" data-name="head">
                                            <path d="M574.4067,902.1786h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2979-2" data-name="signal-BEF2979">
                                        <g id="head-62" data-name="head">
                                            <path d="M570.5048,1147.9156h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.78v13.7498c0,1.79,1.14,3.2302,2.55,3.2302h5.06c1.75,0,3.17-1.8,3.17-4.01v-12.8301c0-1.6101-1.03-2.9199-2.3-2.9199Zm-.29,14.54c-.2401,1.0098-1.07,1.8398-2.08,2.0898-2.15,.51-4.04-1.3699-3.52-3.52,.25-1.01,1.07-1.8398,2.08-2.0801,2.15-.5198,4.04,1.3601,3.52,3.5103Zm-5.6-9.78c.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51-.2401,1.01-1.07,1.8401-2.08,2.0798-2.15,.52-4.04-1.3599-3.52-3.5098Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2271-2" data-name="signal-BEF2271">
                                        <g id="head-63" data-name="head">
                                            <path d="M521.8681,608.3186h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2275-2" data-name="signal-BEF2275">
                                        <g id="head-64" data-name="head">
                                            <path d="M499.5994,557.3884h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2277-2" data-name="signal-BEF2277">
                                        <g id="head-65" data-name="head">
                                            <path d="M446.9698,514.6759h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2279-2" data-name="signal-BEF2279">
                                        <g id="head-66" data-name="head">
                                            <path d="M330.2075,378.153h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2281-2" data-name="signal-BEF2281">
                                        <g id="head-67" data-name="head">
                                            <path d="M203.973,246.6827h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-T2779-2" data-name="signal-T2779">
                                        <g id="head-68" data-name="head">
                                            <path d="M74.9599,93.97h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-T2778-2" data-name="signal-T2778">
                                        <g id="head-69" data-name="head">
                                            <path d="M181.9862,3.5212h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798V29.0413c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01V6.4412c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2283-2" data-name="signal-BEF2283">
                                        <g id="head-70" data-name="head">
                                            <path d="M117.1915,140.3271h-2.5717v-.0127s-1.5798,0-1.5798,0v.0127h-2.1328c-1.211,0-2.1928,1.2418-2.1928,2.7738v31.1468c0,1.784,1.1433,3.2302,2.5535,3.2302h5.0566c1.7528,0,3.1737-1.7975,3.1737-4.0148v-30.2181c0-1.6115-1.0326-2.9179-2.3067-2.9179Zm-.2855,31.9318c-.2435,1.01-1.0718,1.8383-2.0817,2.0817-2.1488,.518-4.0355-1.369-3.5175-3.5176,.2435-1.0099,1.0717-1.8381,2.0816-2.0816,2.1486-.518,4.0356,1.3687,3.5176,3.5175Zm-.0001-8.9113c-.2436,1.0099-1.0719,1.838-2.0818,2.0812-2.1485,.5177-4.035-1.3688-3.5174-3.5173,.2434-1.0099,1.0714-1.8382,2.0813-2.0818,2.1491-.5185,4.0364,1.3689,3.518,3.5179Zm0-8.5827c-.2436,1.0099-1.0719,1.838-2.0818,2.0812-2.1485,.5177-4.035-1.3688-3.5174-3.5173,.2434-1.0099,1.0714-1.8382,2.0813-2.0818,2.1491-.5185,4.0364,1.3689,3.518,3.5179Zm0-8.2457c-.2436,1.0098-1.0719,1.8379-2.0818,2.0812-2.1485,.5176-4.035-1.3688-3.5174-3.5173,.2434-1.0099,1.0714-1.8383,2.0813-2.0819,2.1491-.5185,4.0364,1.3689,3.518,3.518Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2268-2" data-name="signal-BEF2268">
                                        <g id="signal-head-01-2" data-name="signal-head-01">
                                            <g id="head-71" data-name="head">
                                                <path d="M757.8548,625.4227h-2.1497v-3.8838l-4.31-4.3101-1.35,1.3599,3.74,3.74v3.084h-.0803v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm0-8.4302c-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103,.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51Z" fill="#e9427d" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="signal-BEF2270-2" data-name="signal-BEF2270">
                                        <g id="head-72" data-name="head">
                                            <path d="M700.7515,547.785h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2272-2" data-name="signal-BEF2272">
                                        <g id="head-73" data-name="head">
                                            <path d="M652.3569,494.5695h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2274-2" data-name="signal-BEF2274">
                                        <g id="head-74" data-name="head">
                                            <path d="M579.7304,430.4448h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2276-2" data-name="signal-BEF2276">
                                        <g id="head-75" data-name="head">
                                            <path d="M463.3008,294.0682h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2278-2" data-name="signal-BEF2278">
                                        <g id="head-76" data-name="head">
                                            <path d="M325.3295,131.7203h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01v-21.8201c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2280-2" data-name="signal-BEF2280">
                                        <g id="head-77" data-name="head">
                                            <path d="M231.0637,10.1678h-2.58v-.01h-1.57v.01h-2.14c-1.21,0-2.19,1.24-2.19,2.7798v22.7402c0,1.79,1.14,3.23,2.55,3.23h5.06c1.75,0,3.17-1.8,3.17-4.01V13.0878c0-1.6099-1.03-2.9199-2.3-2.9199Zm-.29,23.53c-.24,1.01-1.07,1.8401-2.08,2.0901-2.15,.5098-4.04-1.3701-3.52-3.52,.25-1.01,1.07-1.8401,2.08-2.0801,2.15-.52,4.04,1.3599,3.52,3.51Zm0-8.9099c-.24,1.0098-1.07,1.8398-2.08,2.0798-2.15,.52-4.04-1.3699-3.52-3.51,.25-1.01,1.07-1.8398,2.08-2.0898,2.15-.51,4.04,1.3699,3.52,3.52Zm-5.6-9.8604c.25-1.0098,1.07-1.8398,2.08-2.0798,2.15-.52,4.04,1.3601,3.52,3.51-.24,1.01-1.07,1.8398-2.08,2.0801-2.15,.52-4.04-1.3601-3.52-3.5103Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2267-2" data-name="signal-BEF2267">
                                        <g id="head-78" data-name="head">
                                            <path d="M752.456,762.2036l-6.2155-5.1367c-1.1716-.9682-2.8739-.9382-4.0106,.0708l-2.6432,2.3462c-.754,.6692-1.1854,1.6291-1.1854,2.6372v4.2186c0,.8514,.6902,1.5416,1.5416,1.5416h11.7976c.8564,0,1.5507-.6943,1.5507-1.5507v-2.3535c0-.6861-.3062-1.3364-.8351-1.7735Zm-10.7491,4.2067c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm2.3905-5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm6.0674,5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2269-2" data-name="signal-BEF2269">
                                        <g id="head-79" data-name="head">
                                            <path d="M516.3536,793.9716l-6.2155-5.1367c-1.1716-.9682-2.8739-.9382-4.0106,.0708l-2.6432,2.3462c-.754,.6692-1.1854,1.6291-1.1854,2.6372v4.2186c0,.8514,.6902,1.5416,1.5416,1.5416h11.7976c.8564,0,1.5507-.6943,1.5507-1.5507v-2.3535c0-.6861-.3062-1.3364-.8351-1.7735Zm-10.7491,4.2067c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm2.3905-5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm6.0674,5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2995-2" data-name="signal-BEF2995">
                                        <g id="head-80" data-name="head">
                                            <path d="M742.6287,861.0391l-6.2155-5.1367c-1.1716-.9682-2.8739-.9382-4.0106,.0708l-2.6432,2.3462c-.754,.6692-1.1854,1.6291-1.1854,2.6372v4.2186c0,.8514,.6902,1.5416,1.5416,1.5416h11.7976c.8564,0,1.5507-.6943,1.5507-1.5507v-2.3535c0-.6861-.3062-1.3364-.8351-1.7735Zm-10.7491,4.2067c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm2.3905-5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Zm6.0674,5.3437c-.9265,0-1.6776-.7511-1.6776-1.6776s.7511-1.6776,1.6776-1.6776,1.6776,.7511,1.6776,1.6776-.7511,1.6776-1.6776,1.6776Z" fill="#e9427d" />
                                        </g>
                                    </g>
                                    <g id="signal-BEF2268OFF-2" data-name="signal-BEF2268OFF">
                                        <g id="base-58" data-name="base">
                                            <rect x="708.2394" y="640.5356" width="14.02" height="8.584" fill="#e9427d" />
                                        </g>
                                        <g id="name-73" data-name="name">
                                            <g>
                                                <path d="M713.5752,644.9114c0,1.7197-1.0459,2.6318-2.3213,2.6318-1.3193,0-2.2471-1.0234-2.2471-2.5361,0-1.5869,.9863-2.625,2.3213-2.625,1.3643,0,2.2471,1.0459,2.2471,2.5293Zm-3.8779,.0811c0,1.0684,.5781,2.0244,1.5938,2.0244,1.0234,0,1.6025-.9414,1.6025-2.0762,0-.9941-.5195-2.0322-1.5947-2.0322-1.0684,0-1.6016,.9863-1.6016,2.084Z" fill="#fff" />
                                                <path d="M714.4121,642.4642h2.6924v.541h-2.0469v1.6611h1.8906v.5342h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                                <path d="M718.0235,642.4642h2.6924v.541h-2.0469v1.6611h1.8906v.5342h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="signal-BEF2271OFF-2" data-name="signal-BEF2271OFF">
                                        <g id="base-59" data-name="base">
                                            <rect x="550.7573" y="680.1303" width="14.02" height="8.584" fill="#b2b2b2" />
                                        </g>
                                        <g id="name-74" data-name="name">
                                            <g>
                                                <path d="M556.0928,684.5052c0,1.7207-1.0459,2.6328-2.3213,2.6328-1.3193,0-2.2471-1.0234-2.2471-2.5361,0-1.5869,.9863-2.625,2.3213-2.625,1.3643,0,2.2471,1.0459,2.2471,2.5283Zm-3.8779,.082c0,1.0684,.5781,2.0244,1.5938,2.0244,1.0234,0,1.6025-.9424,1.6025-2.0771,0-.9932-.5195-2.0312-1.5947-2.0312-1.0684,0-1.6016,.9863-1.6016,2.084Z" fill="#fff" />
                                                <path d="M556.9297,682.0579h2.6924v.541h-2.0469v1.6621h1.8906v.5332h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                                <path d="M560.541,682.0579h2.6924v.541h-2.0469v1.6621h1.8906v.5332h-1.8906v2.2617h-.6455v-4.998Z" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>

                        </g>
                        <g id="feltham-key-plan-container">
                            <g>
                                <rect x="467.6546" y="41.8853" width="217.3071" height="165.1255" rx="17.2728" ry="17.2728" fill="#fff" filter="url(#drop-shadow-1)" />
                                <g>
                                    <path d="M534.4335,92.221v.7273h-2.7629v-.7273h2.7629Z" fill="#fff" />
                                    <path d="M544.4316,91.7622c0,2.5946-1.5768,3.9709-3.5006,3.9709-1.9912,0-3.3888-1.5434-3.3888-3.8256,0-2.3929,1.4875-3.9594,3.5006-3.9594,2.0581,0,3.3888,1.578,3.3888,3.8141Zm-5.8497,.1233c0,1.6103,.8726,3.0534,2.405,3.0534,1.5434,0,2.4154-1.4201,2.4154-3.1318,0-1.4984-.7826-3.0649-2.4044-3.0649-1.6103,0-2.416,1.4881-2.416,3.1433Z" fill="#fff" />
                                    <path d="M550.2248,94.1332c0,.559,.0115,1.0512,.045,1.4765h-.8726l-.0559-.8841h-.0225c-.257,.4369-.8276,1.0074-1.7895,1.0074-.8501,0-1.8679-.4703-1.8679-2.371v-3.1652h.9844v2.9969c0,1.0293,.3135,1.7221,1.208,1.7221,.6599,0,1.1186-.4576,1.2973-.8945,.0559-.1452,.0893-.3239,.0893-.5026v-3.3219h.9844v3.9363Z" fill="#fff" />
                                    <path d="M553.0436,88.642v1.5549h1.4091v.7492h-1.4091v2.9185c0,.6708,.1902,1.0512,.7383,1.0512,.257,0,.4472-.0334,.57-.0669l.045,.7388c-.1902,.0784-.4922,.1337-.8726,.1337-.4582,0-.8276-.1452-1.0622-.4138-.2801-.2905-.3804-.7723-.3804-1.4097v-2.9519h-.8391v-.7492h.8391v-1.2979l.9619-.257Z" fill="#fff" />
                                    <path d="M555.4256,94.6035c.2905,.1902,.8051,.3907,1.2973,.3907,.7158,0,1.0512-.3573,1.0512-.8045,0-.4703-.2795-.7273-1.0063-.9959-.9734-.3469-1.4316-.8829-1.4316-1.5319,0-.8726,.7043-1.5884,1.8673-1.5884,.5481,0,1.0293,.1568,1.3313,.3354l-.2461,.7158c-.2127-.1337-.604-.3135-1.1071-.3135-.5821,0-.906,.3354-.906,.7388,0,.4472,.3239,.6489,1.0287,.9164,.9394,.3585,1.4206,.8276,1.4206,1.6333,0,.9509-.7383,1.6218-2.0246,1.6218-.5925,0-1.1405-.1452-1.5209-.3688l.2461-.7492Z" fill="#fff" />
                                    <path d="M561.1744,88.642v1.5549h1.4091v.7492h-1.4091v2.9185c0,.6708,.1902,1.0512,.7383,1.0512,.257,0,.4472-.0334,.57-.0669l.045,.7388c-.1902,.0784-.4922,.1337-.8726,.1337-.4582,0-.8276-.1452-1.0622-.4138-.2801-.2905-.3804-.7723-.3804-1.4097v-2.9519h-.8391v-.7492h.8391v-1.2979l.9619-.257Z" fill="#fff" />
                                    <path d="M566.6881,95.6097l-.0784-.6824h-.034c-.3014,.4253-.8835,.8057-1.6552,.8057-1.0956,0-1.6552-.7723-1.6552-1.5549,0-1.3083,1.163-2.0241,3.2545-2.0137v-.1118c0-.4472-.1228-1.2518-1.2299-1.2518-.5037,0-1.0293,.1568-1.4091,.4023l-.2242-.6489c.4478-.2905,1.0962-.4807,1.7785-.4807,1.6552,0,2.0581,1.1296,2.0581,2.2142v2.0241c0,.4703,.0225,.929,.0893,1.2979h-.8945Zm-.1458-2.7629c-1.0737-.0219-2.2926,.1683-2.2926,1.2195,0,.6374,.4248,.9394,.9285,.9394,.7043,0,1.1521-.4472,1.3083-.906,.0334-.1003,.0559-.2121,.0559-.3135v-.9394Z" fill="#fff" />
                                    <path d="M569.0816,91.6619c0-.559-.0115-1.0178-.045-1.465h.8726l.0559,.8945h.0225c.268-.5141,.8945-1.0178,1.7895-1.0178,.7492,0,1.9122,.4472,1.9122,2.3041v3.232h-.9844v-3.1202c0-.8726-.3239-1.5999-1.2524-1.5999-.6489,0-1.1521,.4588-1.3198,1.0074-.045,.1222-.0674,.2905-.0674,.4576v3.2551h-.9838v-3.9478Z" fill="#fff" />
                                    <path d="M579.9632,87.6691v6.5424c0,.4807,.0115,1.0293,.045,1.3982h-.8835l-.045-.9394h-.0219c-.302,.604-.9619,1.0627-1.8454,1.0627-1.3088,0-2.3157-1.1077-2.3157-2.7514-.011-1.8004,1.1077-2.9081,2.4275-2.9081,.8276,0,1.3866,.3919,1.6327,.8276h.0225v-3.232h.9838Zm-.9838,4.7305c0-.1233-.0115-.2905-.045-.4138-.1452-.6259-.6824-1.1411-1.4206-1.1411-1.0172,0-1.6212,.8956-1.6212,2.0921,0,1.0962,.5366,2.0022,1.5993,2.0022,.6599,0,1.2639-.4369,1.4425-1.1746,.0334-.1337,.045-.2686,.045-.4253v-.9394Z" fill="#fff" />
                                    <path d="M582.6921,88.6754c.0115,.3354-.2346,.604-.6259,.604-.3469,0-.593-.2686-.593-.604,0-.3469,.2576-.6155,.6155-.6155,.3688,0,.6034,.2686,.6034,.6155Zm-1.0956,6.9343v-5.4128h.9838v5.4128h-.9838Z" fill="#fff" />
                                    <path d="M584.2136,91.6619c0-.559-.0115-1.0178-.045-1.465h.8726l.0559,.8945h.0225c.268-.5141,.8945-1.0178,1.7895-1.0178,.7492,0,1.9122,.4472,1.9122,2.3041v3.232h-.9844v-3.1202c0-.8726-.3239-1.5999-1.2524-1.5999-.6489,0-1.1521,.4588-1.3198,1.0074-.045,.1222-.0674,.2905-.0674,.4576v3.2551h-.9838v-3.9478Z" fill="#fff" />
                                    <path d="M595.0842,90.1969c-.0225,.3907-.045,.8276-.045,1.4869v3.1433c0,1.2414-.2461,2.0022-.7717,2.4713-.5256,.4922-1.2858,.6489-1.9682,.6489-.6489,0-1.3647-.1568-1.801-.4472l.2461-.7492c.3579,.2236,.9175,.4242,1.5884,.4242,1.0068,0,1.7445-.5256,1.7445-1.8892v-.604h-.0219c-.302,.5026-.8835,.906-1.7226,.906-1.3423,0-2.3041-1.1411-2.3041-2.6396,0-1.835,1.197-2.8747,2.4384-2.8747,.9394,0,1.4541,.4922,1.6886,.9394h.0225l.045-.8161h.861Zm-1.0178,2.1359c0-.1683-.011-.3135-.0559-.4472-.1787-.5706-.6599-1.0408-1.3757-1.0408-.9394,0-1.6103,.7942-1.6103,2.0471,0,1.0627,.5366,1.9457,1.5993,1.9457,.604,0,1.1521-.3792,1.3642-1.0063,.0559-.1671,.0784-.3573,.0784-.5256v-.9728Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M534.4341,114.8348v.7273h-2.7629v-.7273h2.7629Z" fill="#fff" />
                                    <path d="M538.964,110.6853v7.5383h-.974v-7.5383h.974Z" fill="#fff" />
                                    <path d="M540.7414,114.2769c0-.5602-.0104-1.0189-.0438-1.4662h.8714l.0565,.8945h.0219c.2686-.5141,.8956-1.0166,1.7901-1.0166,.7492,0,1.9122,.4472,1.9122,2.303v3.232h-.9844v-3.1191c0-.8737-.3239-1.5999-1.2529-1.5999-.6478,0-1.1515,.4588-1.3198,1.0063-.0438,.1233-.0669,.2916-.0669,.4588v3.2539h-.9844v-3.9467Z" fill="#fff" />
                                    <path d="M549.3194,114.5789c0-.6939-.0219-1.2541-.0438-1.7682h.8829l.045,.929h.0219c.4034-.6605,1.0408-1.0512,1.9238-1.0512,1.3083,0,2.2926,1.1065,2.2926,2.7502,0,1.9468-1.1849,2.9081-2.4598,2.9081-.7158,0-1.3428-.3124-1.6667-.8495h-.0219v2.9416h-.974v-5.8601Zm.974,1.442c0,.1452,.0219,.2801,.0438,.4023,.1798,.6824,.7723,1.1527,1.4765,1.1527,1.0408,0,1.6437-.8507,1.6437-2.0921,0-1.0846-.5694-2.0137-1.6103-2.0137-.6708,0-1.2967,.4818-1.4869,1.2195-.0334,.1233-.0669,.2686-.0669,.4034v.9279Z" fill="#fff" />
                                    <path d="M555.6832,114.5005c0-.6386-.0104-1.1861-.0438-1.6898h.861l.0334,1.0627h.045c.2455-.7262,.838-1.1849,1.4984-1.1849,.1118,0,.1902,.0104,.2789,.0323v.929c-.1003-.0231-.2006-.0334-.3354-.0334-.6927,0-1.1849,.5256-1.3198,1.2645-.0219,.1337-.045,.2905-.045,.4576v2.8851h-.9728v-3.7231Z" fill="#fff" />
                                    <path d="M564.1275,115.4734c0,2.001-1.3866,2.8736-2.6949,2.8736-1.465,0-2.5946-1.0731-2.5946-2.7848,0-1.812,1.1849-2.8736,2.6834-2.8736,1.5549,0,2.6061,1.1296,2.6061,2.7848Zm-4.2948,.0553c0,1.1849,.6824,2.0805,1.6448,2.0805,.9394,0,1.6437-.8841,1.6437-2.1036,0-.9164-.4588-2.0794-1.6218-2.0794s-1.6667,1.0743-1.6667,2.1024Z" fill="#fff" />
                                    <path d="M570.0337,112.8107c-.0231,.3919-.045,.8276-.045,1.4869v3.1444c0,1.2403-.2467,2.001-.7723,2.4713-.5256,.491-1.2864,.6478-1.9687,.6478-.6478,0-1.3636-.1568-1.8004-.4472l.2467-.7492c.3573,.2236,.9164,.4253,1.5884,.4253,1.0063,0,1.744-.5256,1.744-1.8903v-.604h-.0219c-.302,.5037-.8841,.906-1.7221,.906-1.3428,0-2.3041-1.14-2.3041-2.6396,0-1.8339,1.1965-2.8736,2.4379-2.8736,.9394,0,1.4535,.491,1.6886,.9383h.0231l.0438-.8161h.8622Zm-1.0178,2.137c0-.1683-.0115-.3135-.0565-.4472-.1787-.5717-.6593-1.0408-1.3751-1.0408-.9406,0-1.6114,.7942-1.6114,2.046,0,1.0627,.5371,1.9468,1.5999,1.9468,.604,0,1.1515-.3804,1.3647-1.0063,.0553-.1683,.0784-.3585,.0784-.5256v-.9728Z" fill="#fff" />
                                    <path d="M571.5529,114.5005c0-.6386-.0104-1.1861-.0438-1.6898h.861l.0334,1.0627h.045c.2455-.7262,.838-1.1849,1.4984-1.1849,.1118,0,.1902,.0104,.2789,.0323v.929c-.1003-.0231-.2006-.0334-.3354-.0334-.6927,0-1.1849,.5256-1.3198,1.2645-.0219,.1337-.045,.2905-.045,.4576v2.8851h-.9728v-3.7231Z" fill="#fff" />
                                    <path d="M575.6471,115.697c.0219,1.3302,.8726,1.8788,1.8558,1.8788,.7054,0,1.1307-.1233,1.4996-.2801l.1671,.7043c-.3469,.1568-.9394,.3366-1.8004,.3366-1.6667,0-2.6615-1.0973-2.6615-2.7295s.9613-2.9185,2.5381-2.9185c1.767,0,2.2373,1.5538,2.2373,2.5497,0,.2006-.0219,.3573-.0334,.4588h-3.8026Zm2.8851-.7054c.0115-.6259-.257-1.5987-1.3647-1.5987-.9947,0-1.4316,.9164-1.51,1.5987h2.8747Z" fill="#fff" />
                                    <path d="M580.5677,117.2173c.2905,.1902,.8057,.3919,1.2967,.3919,.7158,0,1.0524-.3585,1.0524-.8057,0-.4703-.2801-.7262-1.0074-.9959-.9728-.3458-1.4316-.8829-1.4316-1.5307,0-.8737,.7054-1.5884,1.8684-1.5884,.5475,0,1.0282,.1568,1.3302,.3343l-.2455,.7169c-.2121-.1349-.604-.3135-1.1077-.3135-.5809,0-.906,.3354-.906,.7377,0,.4472,.325,.6489,1.0293,.9175,.9394,.3573,1.4201,.8276,1.4201,1.6322,0,.9521-.7377,1.6229-2.0241,1.6229-.5925,0-1.1411-.1452-1.5215-.3688l.2467-.7504Z" fill="#fff" />
                                    <path d="M584.9974,117.2173c.2905,.1902,.8057,.3919,1.2967,.3919,.7158,0,1.0524-.3585,1.0524-.8057,0-.4703-.2801-.7262-1.0074-.9959-.9728-.3458-1.4316-.8829-1.4316-1.5307,0-.8737,.7054-1.5884,1.8684-1.5884,.5475,0,1.0282,.1568,1.3302,.3343l-.2455,.7169c-.2121-.1349-.604-.3135-1.1077-.3135-.5809,0-.906,.3354-.906,.7377,0,.4472,.325,.6489,1.0293,.9175,.9394,.3573,1.4201,.8276,1.4201,1.6322,0,.9521-.7377,1.6229-2.0241,1.6229-.5925,0-1.1411-.1452-1.5215-.3688l.2467-.7504Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M534.4341,138.8941v.7273h-2.7629v-.7273h2.7629Z" fill="#fff" />
                                    <path d="M543.2588,142.0373c-.3585,.1787-1.0743,.3573-1.9918,.3573-2.1243,0-3.7242-1.3417-3.7242-3.813,0-2.3606,1.5999-3.9594,3.9375-3.9594,.9394,0,1.5319,.2006,1.7889,.3354l-.234,.7942c-.37-.1798-.8956-.3135-1.5215-.3135-1.767,0-2.9416,1.1296-2.9416,3.1087,0,1.8454,1.0627,3.0315,2.8966,3.0315,.5925,0,1.1965-.1233,1.5884-.3135l.2017,.7723Z" fill="#fff" />
                                    <path d="M549.2191,139.5315c0,2.0022-1.3866,2.8747-2.6949,2.8747-1.465,0-2.5946-1.0743-2.5946-2.7848,0-1.812,1.1849-2.8747,2.6834-2.8747,1.5549,0,2.6061,1.1296,2.6061,2.7848Zm-4.2948,.0565c0,1.1849,.6824,2.0805,1.6448,2.0805,.9394,0,1.6437-.8841,1.6437-2.1036,0-.9164-.4588-2.0794-1.6218-2.0794s-1.6667,1.0731-1.6667,2.1024Z" fill="#fff" />
                                    <path d="M550.4605,138.335c0-.559-.0104-1.0178-.0438-1.465h.861l.0438,.8726h.0346c.302-.5152,.8045-.9959,1.699-.9959,.7388,0,1.2979,.4472,1.533,1.0846h.0219c.1683-.302,.3804-.536,.604-.7043,.325-.2455,.6824-.3804,1.1965-.3804,.7158,0,1.7785,.4703,1.7785,2.3491v3.1871h-.9613v-3.0637c0-1.0408-.3804-1.6667-1.1746-1.6667-.559,0-.9959,.4138-1.163,.8945-.045,.1349-.0784,.3135-.0784,.4922v3.3438h-.9625v-3.2436c0-.861-.3804-1.4869-1.1296-1.4869-.6144,0-1.0616,.4922-1.2184,.9844-.0565,.1452-.0784,.3124-.0784,.4807v3.2655h-.9625v-3.9478Z" fill="#fff" />
                                    <path d="M559.7878,138.637c0-.6939-.0219-1.2529-.0438-1.767h.8829l.045,.9279h.0219c.4034-.6593,1.0408-1.0512,1.9238-1.0512,1.3083,0,2.2926,1.1077,2.2926,2.7514,0,1.9468-1.1849,2.9081-2.4598,2.9081-.7158,0-1.3428-.3135-1.6667-.8495h-.0219v2.9404h-.974v-5.8601Zm.974,1.4431c0,.1452,.0219,.2789,.0438,.4023,.1798,.6824,.7723,1.1515,1.4765,1.1515,1.0408,0,1.6437-.8495,1.6437-2.0909,0-1.0846-.5694-2.0137-1.6103-2.0137-.6708,0-1.2967,.4818-1.4869,1.2195-.0334,.1233-.0669,.2686-.0669,.4023v.929Z" fill="#fff" />
                                    <path d="M566.1515,134.3423h.9844v7.9406h-.9844v-7.9406Z" fill="#fff" />
                                    <path d="M569.3398,139.7551c.0219,1.3313,.8726,1.8788,1.8558,1.8788,.7054,0,1.1307-.1222,1.4996-.2789l.1671,.7043c-.3469,.1568-.9394,.3354-1.8004,.3354-1.6667,0-2.6615-1.0962-2.6615-2.7283,0-1.6333,.9613-2.9197,2.5381-2.9197,1.767,0,2.2373,1.5549,2.2373,2.5508,0,.2006-.0219,.3573-.0334,.4576h-3.8026Zm2.8851-.7043c.0115-.6259-.257-1.5987-1.3647-1.5987-.9947,0-1.4316,.9164-1.51,1.5987h2.8747Z" fill="#fff" />
                                    <path d="M575.5802,135.3151v1.5549h1.4085v.7492h-1.4085v2.9197c0,.6708,.1902,1.0512,.7377,1.0512,.257,0,.4472-.0334,.5706-.068l.045,.7388c-.1902,.0784-.4922,.1337-.8726,.1337-.4588,0-.8276-.1452-1.0627-.4138-.2789-.2905-.3804-.7711-.3804-1.4085v-2.9531h-.838v-.7492h.838v-1.2979l.9625-.257Z" fill="#fff" />
                                    <path d="M578.5771,139.7551c.0219,1.3313,.8726,1.8788,1.8558,1.8788,.7054,0,1.1307-.1222,1.4996-.2789l.1671,.7043c-.3469,.1568-.9394,.3354-1.8004,.3354-1.6667,0-2.6615-1.0962-2.6615-2.7283,0-1.6333,.9613-2.9197,2.5381-2.9197,1.767,0,2.2373,1.5549,2.2373,2.5508,0,.2006-.0219,.3573-.0334,.4576h-3.8026Zm2.8851-.7043c.0115-.6259-.257-1.5987-1.3647-1.5987-.9947,0-1.4316,.9164-1.51,1.5987h2.8747Z" fill="#fff" />
                                </g>
                                <g>
                                    <path d="M534.4341,160.7333v.7262h-2.7629v-.7262h2.7629Z" fill="#fff" />
                                    <path d="M537.99,156.6841c.4922-.1003,1.1976-.1568,1.8684-.1568,1.0397,0,1.7105,.1902,2.1808,.6155,.3804,.3354,.5925,.8495,.5925,1.4316,0,.9947-.6259,1.6552-1.4201,1.9238v.0334c.5821,.2017,.9279,.7377,1.1077,1.5203,.2455,1.0512,.4242,1.7785,.5809,2.0702h-1.0063c-.1233-.2132-.2905-.8622-.5037-1.8004-.2236-1.0408-.6259-1.4316-1.51-1.4662h-.9164v3.2666h-.974v-7.4381Zm.974,3.4337h.9947c1.0408,0,1.7002-.5706,1.7002-1.4316,0-.9728-.7043-1.3982-1.7336-1.4097-.4691,0-.8057,.045-.9613,.0899v2.7514Z" fill="#fff" />
                                    <path d="M544.567,161.5932c.0219,1.3325,.8726,1.8788,1.8558,1.8788,.7054,0,1.1307-.1222,1.4996-.2789l.1671,.7054c-.3469,.1568-.9394,.3343-1.8004,.3343-1.6667,0-2.6615-1.095-2.6615-2.7283s.9613-2.9197,2.5381-2.9197c1.767,0,2.2373,1.5549,2.2373,2.5508,0,.2017-.0219,.3585-.0334,.4576h-3.8026Zm2.8851-.7031c.0115-.627-.257-1.5999-1.3647-1.5999-.9947,0-1.4316,.9175-1.51,1.5999h2.8747Z" fill="#fff" />
                                    <path d="M550.8075,157.1532v1.5549h1.4085v.7504h-1.4085v2.9185c0,.6708,.1902,1.0512,.7377,1.0512,.257,0,.4472-.0334,.5706-.0669l.045,.7377c-.1902,.0784-.4922,.1337-.8726,.1337-.4588,0-.8276-.1452-1.0627-.4126-.2789-.2916-.3804-.7723-.3804-1.4097v-2.9519h-.838v-.7504h.838v-1.2967l.9625-.2582Z" fill="#fff" />
                                    <path d="M556.3217,164.1221l-.0784-.6824h-.0334c-.302,.4242-.8841,.8045-1.6552,.8045-1.0962,0-1.6552-.7723-1.6552-1.5538,0-1.3094,1.163-2.0252,3.2539-2.0137v-.1118c0-.4472-.1233-1.2529-1.2299-1.2529-.5037,0-1.0293,.1568-1.4097,.4023l-.2236-.6478c.4472-.2905,1.0962-.4818,1.7785-.4818,1.6552,0,2.0575,1.1296,2.0575,2.2154v2.0241c0,.4703,.0231,.929,.0899,1.2979h-.8945Zm-.1452-2.7629c-1.0743-.0219-2.2938,.1671-2.2938,1.2184,0,.6386,.4253,.9406,.929,.9406,.7043,0,1.1515-.4472,1.3083-.906,.0334-.1014,.0565-.2132,.0565-.3135v-.9394Z" fill="#fff" />
                                    <path d="M559.8097,157.1878c.0115,.3343-.2351,.604-.6259,.604-.3469,0-.5925-.2697-.5925-.604,0-.3469,.257-.6155,.6144-.6155,.3688,0,.604,.2686,.604,.6155Zm-1.0962,6.9343v-5.414h.9844v5.414h-.9844Z" fill="#fff" />
                                    <path d="M561.3312,160.1731c0-.559-.0104-1.0166-.0438-1.465h.8714l.0565,.8956h.0219c.2686-.5152,.8956-1.0189,1.7901-1.0189,.7492,0,1.9122,.4472,1.9122,2.3053v3.232h-.9844v-3.1214c0-.8714-.3239-1.5987-1.2529-1.5987-.6478,0-1.1515,.4576-1.3198,1.0063-.0438,.1233-.0669,.2905-.0669,.4588v3.2551h-.9844v-3.949Z" fill="#fff" />
                                    <path d="M568.0868,161.5932c.0219,1.3325,.8726,1.8788,1.8569,1.8788,.7043,0,1.1296-.1222,1.4984-.2789l.1671,.7054c-.3469,.1568-.9394,.3343-1.8004,.3343-1.6667,0-2.6615-1.095-2.6615-2.7283s.9613-2.9197,2.5381-2.9197c1.767,0,2.2373,1.5549,2.2373,2.5508,0,.2017-.0219,.3585-.0334,.4576h-3.8026Zm2.8851-.7031c.0115-.627-.257-1.5999-1.3647-1.5999-.9947,0-1.4316,.9175-1.51,1.5999h2.8747Z" fill="#fff" />
                                    <path d="M577.8164,156.1804v6.5424c0,.4818,.0115,1.0305,.045,1.3993h-.8841l-.0438-.9406h-.0231c-.302,.604-.9613,1.0627-1.8454,1.0627-1.3083,0-2.3145-1.1065-2.3145-2.7502-.0115-1.8016,1.1065-2.9093,2.4263-2.9093,.8276,0,1.3866,.3919,1.6333,.8276h.0219v-3.232h.9844Zm-.9844,4.7305c0-.1222-.0104-.2905-.0438-.4126-.1464-.627-.6824-1.1411-1.4212-1.1411-1.0178,0-1.6218,.8945-1.6218,2.0909,0,1.0973,.5371,2.0033,1.5999,2.0033,.6593,0,1.2633-.4369,1.4431-1.1757,.0334-.1337,.0438-.2674,.0438-.4242v-.9406Z" fill="#fff" />
                                </g>
                                <rect x="493.9179" y="83.7997" width="27.9913" height="14.1413" fill="#e9427d" />
                                <g>
                                    <path d="M534.4335,69.9898v.7273h-2.7629v-.7273h2.7629Z" fill="#fff" />
                                    <path d="M538.9623,65.8402v7.5383h-.9728v-7.5383h.9728Z" fill="#fff" />
                                    <path d="M540.7408,69.4308c0-.559-.0115-1.0178-.045-1.465h.8726l.0559,.8945h.0225c.268-.5141,.8945-1.0178,1.7895-1.0178,.7492,0,1.9122,.4472,1.9122,2.3041v3.232h-.9844v-3.1202c0-.8726-.3245-1.5999-1.2524-1.5999-.6489,0-1.1521,.4588-1.3198,1.0074-.045,.1222-.0674,.2905-.0674,.4576v3.2551h-.9838v-3.9478Z" fill="#fff" />
                                    <path d="M546.8141,72.3723c.2905,.1902,.8051,.3907,1.2973,.3907,.7158,0,1.0512-.3573,1.0512-.8045,0-.4703-.2795-.7273-1.0063-.9959-.9734-.3469-1.4316-.8829-1.4316-1.5319,0-.8726,.7043-1.5884,1.8673-1.5884,.5481,0,1.0293,.1568,1.3313,.3354l-.2461,.7158c-.2127-.1337-.604-.3135-1.1071-.3135-.5821,0-.906,.3354-.906,.7388,0,.4472,.3239,.6489,1.0287,.9164,.9394,.3585,1.4206,.8276,1.4206,1.6333,0,.9509-.7383,1.6218-2.0246,1.6218-.5925,0-1.1405-.1452-1.5209-.3688l.2461-.7492Z" fill="#fff" />
                                    <path d="M552.5624,66.4108v1.5549h1.4091v.7492h-1.4091v2.9185c0,.6708,.1902,1.0512,.7383,1.0512,.257,0,.4472-.0334,.57-.0669l.045,.7388c-.1902,.0784-.4922,.1337-.8726,.1337-.4582,0-.8276-.1452-1.0622-.4138-.2801-.2905-.3804-.7723-.3804-1.4097v-2.9519h-.8391v-.7492h.8391v-1.2979l.9619-.257Z" fill="#fff" />
                                    <path d="M558.0767,73.3786l-.0784-.6824h-.034c-.3014,.4253-.8835,.8057-1.6552,.8057-1.0956,0-1.6552-.7723-1.6552-1.5549,0-1.3083,1.163-2.0241,3.2545-2.0137v-.1118c0-.4472-.1228-1.2518-1.2299-1.2518-.5037,0-1.0293,.1568-1.4091,.4023l-.2242-.6489c.4478-.2905,1.0962-.4807,1.7785-.4807,1.6552,0,2.0581,1.1296,2.0581,2.2142v2.0241c0,.4703,.0225,.929,.0893,1.2979h-.8945Zm-.1458-2.7629c-1.0737-.0219-2.2926,.1683-2.2926,1.2195,0,.6374,.4248,.9394,.9285,.9394,.7043,0,1.1521-.4472,1.3083-.906,.0334-.1003,.0559-.2121,.0559-.3135v-.9394Z" fill="#fff" />
                                    <path d="M560.4696,65.438h.9838v7.9406h-.9838v-7.9406Z" fill="#fff" />
                                    <path d="M563.1091,65.438h.9838v7.9406h-.9838v-7.9406Z" fill="#fff" />
                                    <path d="M571.1172,73.3786l-.0784-.6824h-.034c-.302,.4253-.8835,.8057-1.6552,.8057-1.0956,0-1.6552-.7723-1.6552-1.5549,0-1.3083,1.163-2.0241,3.2545-2.0137v-.1118c0-.4472-.1228-1.2518-1.2299-1.2518-.5037,0-1.0293,.1568-1.4091,.4023l-.2242-.6489c.4478-.2905,1.0962-.4807,1.7785-.4807,1.6552,0,2.0581,1.1296,2.0581,2.2142v2.0241c0,.4703,.0225,.929,.0893,1.2979h-.8945Zm-.1458-2.7629c-1.0737-.0219-2.2926,.1683-2.2926,1.2195,0,.6374,.4248,.9394,.9285,.9394,.7043,0,1.1521-.4472,1.3083-.906,.0334-.1003,.0559-.2121,.0559-.3135v-.9394Z" fill="#fff" />
                                    <path d="M574.6512,66.4108v1.5549h1.4091v.7492h-1.4091v2.9185c0,.6708,.1902,1.0512,.7383,1.0512,.257,0,.4472-.0334,.57-.0669l.045,.7388c-.1902,.0784-.4922,.1337-.8726,.1337-.4582,0-.8276-.1452-1.0622-.4138-.2801-.2905-.3804-.7723-.3804-1.4097v-2.9519h-.8391v-.7492h.8391v-1.2979l.9619-.257Z" fill="#fff" />
                                    <path d="M583.3969,73.1769c-.2576,.1349-.8276,.3135-1.5549,.3135-1.6327,0-2.6955-1.1077-2.6955-2.7629,0-1.6656,1.1411-2.8736,2.9081-2.8736,.5815,0,1.0962,.1452,1.3647,.2789l-.2242,.7607c-.2346-.1337-.6034-.257-1.1405-.257-1.2414,0-1.9122,.9175-1.9122,2.0471,0,1.2518,.8051,2.0241,1.8788,2.0241,.559,0,.9285-.1452,1.208-.2686l.1677,.7377Z" fill="#fff" />
                                    <path d="M589.3809,70.6272c0,2.0022-1.3872,2.8747-2.6955,2.8747-1.4656,0-2.5952-1.0743-2.5952-2.7848,0-1.812,1.1855-2.8747,2.6845-2.8747,1.5543,0,2.6061,1.1296,2.6061,2.7848Zm-4.2948,.0565c0,1.1849,.6818,2.0794,1.6437,2.0794,.9394,0,1.6443-.8829,1.6443-2.1024,0-.9175-.4588-2.0805-1.6218-2.0805s-1.6662,1.0743-1.6662,2.1036Z" fill="#fff" />
                                    <path d="M590.6217,69.4308c0-.559-.0115-1.0178-.045-1.465h.861l.045,.8726h.0334c.302-.5152,.8051-.9959,1.7002-.9959,.7383,0,1.2973,.4472,1.5324,1.0846h.0219c.1677-.302,.3804-.5371,.604-.7043,.3245-.2467,.6824-.3804,1.197-.3804,.7158,0,1.778,.4691,1.778,2.3491v3.1871h-.9619v-3.0649c0-1.0397-.3798-1.6656-1.174-1.6656-.5596,0-.9953,.4138-1.163,.8945-.045,.1337-.0784,.3135-.0784,.4922v3.3438h-.9619v-3.2436c0-.861-.3804-1.4869-1.1296-1.4869-.6155,0-1.0627,.4922-1.2195,.9832-.0559,.1464-.0778,.3135-.0778,.4818v3.2655h-.9619v-3.9478Z" fill="#fff" />
                                    <path d="M599.9496,69.4308c0-.559-.0115-1.0178-.045-1.465h.861l.045,.8726h.0334c.302-.5152,.8051-.9959,1.7002-.9959,.7383,0,1.2973,.4472,1.5324,1.0846h.0219c.1677-.302,.3804-.5371,.604-.7043,.3245-.2467,.6824-.3804,1.197-.3804,.7158,0,1.778,.4691,1.778,2.3491v3.1871h-.9619v-3.0649c0-1.0397-.3798-1.6656-1.174-1.6656-.5596,0-.9953,.4138-1.163,.8945-.045,.1337-.0784,.3135-.0784,.4922v3.3438h-.9619v-3.2436c0-.861-.3804-1.4869-1.1296-1.4869-.6155,0-1.0627,.4922-1.2195,.9832-.0559,.1464-.0778,.3135-.0778,.4818v3.2655h-.9619v-3.9478Z" fill="#fff" />
                                    <path d="M610.373,66.4442c.0115,.3354-.2346,.604-.6259,.604-.3469,0-.593-.2686-.593-.604,0-.3469,.2576-.6155,.6155-.6155,.3688,0,.6034,.2686,.6034,.6155Zm-1.0956,6.9343v-5.4128h.9838v5.4128h-.9838Z" fill="#fff" />
                                    <path d="M611.7602,72.3723c.2905,.1902,.8051,.3907,1.2973,.3907,.7158,0,1.0512-.3573,1.0512-.8045,0-.4703-.2795-.7273-1.0063-.9959-.9734-.3469-1.4316-.8829-1.4316-1.5319,0-.8726,.7043-1.5884,1.8673-1.5884,.5481,0,1.0293,.1568,1.3313,.3354l-.2461,.7158c-.2127-.1337-.604-.3135-1.1071-.3135-.5821,0-.906,.3354-.906,.7388,0,.4472,.3239,.6489,1.0287,.9164,.9394,.3585,1.4206,.8276,1.4206,1.6333,0,.9509-.7383,1.6218-2.0246,1.6218-.5925,0-1.1405-.1452-1.5209-.3688l.2461-.7492Z" fill="#fff" />
                                    <path d="M616.1892,72.3723c.2905,.1902,.8051,.3907,1.2973,.3907,.7158,0,1.0512-.3573,1.0512-.8045,0-.4703-.2795-.7273-1.0063-.9959-.9734-.3469-1.4316-.8829-1.4316-1.5319,0-.8726,.7043-1.5884,1.8673-1.5884,.5481,0,1.0293,.1568,1.3313,.3354l-.2461,.7158c-.2127-.1337-.604-.3135-1.1071-.3135-.5821,0-.906,.3354-.906,.7388,0,.4472,.3239,.6489,1.0287,.9164,.9394,.3585,1.4206,.8276,1.4206,1.6333,0,.9509-.7383,1.6218-2.0246,1.6218-.5925,0-1.1405-.1452-1.5209-.3688l.2461-.7492Z" fill="#fff" />
                                    <path d="M621.8476,66.4442c.0115,.3354-.2346,.604-.6259,.604-.3469,0-.593-.2686-.593-.604,0-.3469,.2576-.6155,.6155-.6155,.3688,0,.6034,.2686,.6034,.6155Zm-1.0956,6.9343v-5.4128h.9838v5.4128h-.9838Z" fill="#fff" />
                                    <path d="M628.2679,70.6272c0,2.0022-1.3872,2.8747-2.6955,2.8747-1.4656,0-2.5952-1.0743-2.5952-2.7848,0-1.812,1.1855-2.8747,2.6845-2.8747,1.5543,0,2.6061,1.1296,2.6061,2.7848Zm-4.2948,.0565c0,1.1849,.6818,2.0794,1.6437,2.0794,.9394,0,1.6443-.8829,1.6443-2.1024,0-.9175-.4588-2.0805-1.6218-2.0805s-1.6662,1.0743-1.6662,2.1036Z" fill="#fff" />
                                    <path d="M629.5093,69.4308c0-.559-.0115-1.0178-.045-1.465h.8726l.0559,.8945h.0225c.268-.5141,.8945-1.0178,1.7895-1.0178,.7492,0,1.9122,.4472,1.9122,2.3041v3.232h-.9844v-3.1202c0-.8726-.3239-1.5999-1.2524-1.5999-.6489,0-1.1521,.4588-1.3198,1.0074-.045,.1222-.0674,.2905-.0674,.4576v3.2551h-.9838v-3.9478Z" fill="#fff" />
                                    <path d="M636.8119,66.4442c.0115,.3354-.2346,.604-.6259,.604-.3469,0-.593-.2686-.593-.604,0-.3469,.2576-.6155,.6155-.6155,.3688,0,.6034,.2686,.6034,.6155Zm-1.0956,6.9343v-5.4128h.9838v5.4128h-.9838Z" fill="#fff" />
                                    <path d="M638.3334,69.4308c0-.559-.0115-1.0178-.045-1.465h.8726l.0559,.8945h.0225c.268-.5141,.8945-1.0178,1.7895-1.0178,.7492,0,1.9122,.4472,1.9122,2.3041v3.232h-.9844v-3.1202c0-.8726-.3245-1.5999-1.2524-1.5999-.6489,0-1.1521,.4588-1.3198,1.0074-.045,.1222-.0674,.2905-.0674,.4576v3.2551h-.9838v-3.9478Z" fill="#fff" />
                                    <path d="M649.2046,67.9657c-.0225,.3907-.045,.8276-.045,1.4869v3.1433c0,1.2414-.2461,2.0022-.7717,2.4713-.5256,.4922-1.2858,.6489-1.9682,.6489-.6489,0-1.3647-.1568-1.801-.4472l.2461-.7492c.3579,.2236,.9175,.4242,1.5884,.4242,1.0068,0,1.7445-.5256,1.7445-1.8892v-.604h-.0219c-.302,.5026-.8835,.906-1.7226,.906-1.3423,0-2.3041-1.1411-2.3041-2.6396,0-1.835,1.197-2.8747,2.4384-2.8747,.9394,0,1.4541,.4922,1.6886,.9394h.0225l.045-.8161h.861Zm-1.0178,2.1359c0-.1683-.011-.3135-.0559-.4472-.1787-.5706-.6599-1.0408-1.3757-1.0408-.9394,0-1.6103,.7942-1.6103,2.0471,0,1.0627,.5366,1.9457,1.5993,1.9457,.604,0,1.1521-.3792,1.3642-1.0063,.0559-.1671,.0784-.3573,.0784-.5256v-.9728Z" fill="#fff" />
                                </g>
                                <rect x="493.9179" y="61.5685" width="27.9913" height="14.1413" fill="#ec6608" />
                                <rect x="493.9179" y="106.031" width="27.9913" height="14.1413" fill="#4abab6" />
                                <rect x="493.9179" y="131.211" width="27.9913" height="14.1413" fill="#588fcc" />
                                <rect x="493.9179" y="154.1848" width="27.9913" height="14.1413" fill="#b2b2b2" opacity=".5" />
                                <g>
                                    <path d="M534.4335,182.4204v.7273h-2.7629v-.7273h2.7629Z" fill="#fff" />
                                    <path d="M537.9894,185.8092v-7.5383h1.0627l2.416,3.8141c.559,.8829,.9959,1.6783,1.3532,2.4494l.0231-.0115c-.0899-1.0063-.1118-1.9238-.1118-3.0972v-3.1548h.9164v7.5383h-.9844l-2.3929-3.8256c-.5256-.838-1.0293-1.699-1.4097-2.5151l-.0334,.0104c.0565,.9509,.0784,1.8569,.0784,3.1087v3.2217h-.9175Z" fill="#fff" />
                                    <path d="M550.2133,183.0579c0,2.0022-1.3866,2.8747-2.6949,2.8747-1.465,0-2.5946-1.0743-2.5946-2.7848,0-1.812,1.1849-2.8747,2.6834-2.8747,1.5549,0,2.6061,1.1296,2.6061,2.7848Zm-4.2948,.0553c0,1.1861,.6824,2.0817,1.6448,2.0817,.9394,0,1.6437-.8841,1.6437-2.1036,0-.9164-.4588-2.0805-1.6218-2.0805s-1.6667,1.0743-1.6667,2.1024Z" fill="#fff" />
                                    <path d="M552.6408,178.8415v1.5538h1.4085v.7504h-1.4085v2.9197c0,.6708,.1902,1.0512,.7377,1.0512,.257,0,.4472-.0346,.5706-.068l.045,.7388c-.1902,.0784-.4922,.1337-.8726,.1337-.4588,0-.8276-.1452-1.0627-.4138-.2789-.2916-.3804-.7711-.3804-1.4097v-2.9519h-.838v-.7504h.838v-1.2967l.9625-.257Z" fill="#fff" />
                                    <path d="M557.528,182.1634c0-.6939-.0219-1.2529-.0438-1.7682h.8829l.045,.929h.0219c.4034-.6593,1.0408-1.0512,1.9238-1.0512,1.3083,0,2.2926,1.1065,2.2926,2.7514,0,1.9468-1.1849,2.9081-2.4598,2.9081-.7158,0-1.3428-.3135-1.6667-.8507h-.0219v2.9416h-.974v-5.8601Zm.974,1.4431c0,.1452,.0219,.2789,.0438,.4023,.1798,.6812,.7723,1.1515,1.4765,1.1515,1.0408,0,1.6437-.8495,1.6437-2.0909,0-1.0858-.5694-2.0137-1.6103-2.0137-.6708,0-1.2967,.4807-1.4869,1.2195-.0334,.1233-.0669,.2686-.0669,.4023v.929Z" fill="#fff" />
                                    <path d="M566.8898,185.8092l-.0784-.6824h-.0334c-.302,.4253-.8841,.8057-1.6552,.8057-1.0962,0-1.6552-.7723-1.6552-1.5549,0-1.3083,1.163-2.0252,3.2539-2.0137v-.1107c0-.4472-.1233-1.2541-1.2299-1.2541-.5037,0-1.0293,.1568-1.4097,.4034l-.2236-.6489c.4472-.2905,1.0962-.4807,1.7785-.4807,1.6552,0,2.0575,1.1296,2.0575,2.2142v2.0252c0,.4691,.0231,.9279,.0899,1.2967h-.8945Zm-.1452-2.7629c-1.0743-.0219-2.2938,.1683-2.2938,1.2195,0,.6374,.4253,.9394,.929,.9394,.7043,0,1.1515-.4472,1.3083-.906,.0334-.1003,.0565-.2132,.0565-.3124v-.9406Z" fill="#fff" />
                                    <path d="M569.2827,182.085c0-.6374-.0104-1.1849-.0438-1.6898h.861l.0334,1.0627h.045c.2455-.7262,.838-1.1849,1.4984-1.1849,.1118,0,.1902,.0115,.2789,.0334v.9279c-.1003-.0219-.2006-.0323-.3354-.0323-.6927,0-1.1849,.5256-1.3198,1.2633-.0219,.1337-.0438,.2905-.0438,.4588v2.8851h-.974v-3.7242Z" fill="#fff" />
                                    <path d="M574.3936,178.8415v1.5538h1.4085v.7504h-1.4085v2.9197c0,.6708,.1902,1.0512,.7377,1.0512,.257,0,.4472-.0346,.5706-.068l.045,.7388c-.1902,.0784-.4922,.1337-.8726,.1337-.4588,0-.8276-.1452-1.0627-.4138-.2789-.2916-.3804-.7711-.3804-1.4097v-2.9519h-.838v-.7504h.838v-1.2967l.9625-.257Z" fill="#fff" />
                                    <path d="M584.1796,183.0579c0,2.0022-1.3866,2.8747-2.6949,2.8747-1.465,0-2.5946-1.0743-2.5946-2.7848,0-1.812,1.1849-2.8747,2.6834-2.8747,1.5549,0,2.6061,1.1296,2.6061,2.7848Zm-4.2948,.0553c0,1.1861,.6824,2.0817,1.6448,2.0817,.9394,0,1.6437-.8841,1.6437-2.1036,0-.9164-.4588-2.0805-1.6218-2.0805s-1.6667,1.0743-1.6667,2.1024Z" fill="#fff" />
                                    <path d="M585.5224,185.8092v-4.6636h-.7607v-.7504h.7607v-.2559c0-.7607,.1671-1.4546,.6259-1.8903,.3688-.3585,.861-.5037,1.3198-.5037,.3469,0,.6489,.0784,.8391,.1568l-.1349,.7596c-.1452-.0669-.3469-.1222-.6259-.1222-.8391,0-1.0512,.7377-1.0512,1.5653v.2905h1.3083v.7504h-1.3083v4.6636h-.9728Z" fill="#fff" />
                                    <path d="M595.7326,177.8686v6.5424c0,.4818,.0115,1.0293,.045,1.3982h-.8841l-.0438-.9394h-.0231c-.302,.604-.9613,1.0627-1.8454,1.0627-1.3083,0-2.3145-1.1077-2.3145-2.7514-.0115-1.8016,1.1065-2.9081,2.4263-2.9081,.8276,0,1.3866,.3919,1.6333,.8276h.0219v-3.232h.9844Zm-.9844,4.7305c0-.1222-.0104-.2905-.0438-.4138-.1464-.6259-.6824-1.14-1.4212-1.14-1.0178,0-1.6218,.8945-1.6218,2.0909,0,1.0962,.5371,2.0022,1.5999,2.0022,.6593,0,1.2633-.4369,1.4431-1.1746,.0334-.1337,.0438-.2686,.0438-.4242v-.9406Z" fill="#fff" />
                                    <path d="M598.4609,178.8749c.0115,.3354-.2351,.604-.6259,.604-.3469,0-.5925-.2686-.5925-.604,0-.3469,.257-.6144,.6144-.6144,.3688,0,.604,.2674,.604,.6144Zm-1.0962,6.9343v-5.414h.9844v5.414h-.9844Z" fill="#fff" />
                                    <path d="M599.8487,184.803c.2905,.189,.8057,.3919,1.2967,.3919,.7158,0,1.0524-.3585,1.0524-.8069,0-.4691-.2801-.7262-1.0074-.9947-.9728-.3469-1.4316-.8841-1.4316-1.5319,0-.8726,.7054-1.5884,1.8684-1.5884,.5475,0,1.0282,.1568,1.3302,.3354l-.2455,.7158c-.2121-.1337-.604-.3135-1.1077-.3135-.5809,0-.906,.3366-.906,.7388,0,.4472,.325,.6489,1.0293,.9164,.9394,.3585,1.4201,.8276,1.4201,1.6333,0,.9509-.7377,1.6218-2.0241,1.6218-.5925,0-1.1411-.1452-1.5215-.3688l.2467-.7492Z" fill="#fff" />
                                    <path d="M608.27,185.6075c-.257,.1349-.8276,.3135-1.5549,.3135-1.6322,0-2.6949-1.1065-2.6949-2.7618,0-1.6667,1.14-2.8747,2.9081-2.8747,.5809,0,1.095,.1452,1.3636,.2789l-.2236,.7607c-.2351-.1337-.604-.257-1.14-.257-1.2426,0-1.9134,.9164-1.9134,2.046,0,1.2541,.8057,2.0252,1.8788,2.0252,.5602,0,.929-.1464,1.208-.2686l.1683,.7377Z" fill="#fff" />
                                    <path d="M610.5176,178.8749c.0115,.3354-.2351,.604-.6259,.604-.3469,0-.5925-.2686-.5925-.604,0-.3469,.257-.6144,.6144-.6144,.3688,0,.604,.2674,.604,.6144Zm-1.0962,6.9343v-5.414h.9844v5.414h-.9844Z" fill="#fff" />
                                    <path d="M612.0391,182.1634c0-.6939-.0219-1.2529-.0438-1.7682h.8829l.045,.929h.0219c.4034-.6593,1.0408-1.0512,1.9238-1.0512,1.3083,0,2.2926,1.1065,2.2926,2.7514,0,1.9468-1.1849,2.9081-2.4598,2.9081-.7158,0-1.3428-.3135-1.6667-.8507h-.0219v2.9416h-.974v-5.8601Zm.974,1.4431c0,.1452,.0219,.2789,.0438,.4023,.1798,.6812,.7723,1.1515,1.4765,1.1515,1.0408,0,1.6437-.8495,1.6437-2.0909,0-1.0858-.5694-2.0137-1.6103-2.0137-.6708,0-1.2967,.4807-1.4869,1.2195-.0334,.1233-.0669,.2686-.0669,.4023v.929Z" fill="#fff" />
                                    <path d="M618.4029,177.8686h.9844v7.9406h-.9844v-7.9406Z" fill="#fff" />
                                    <path d="M622.1375,178.8749c.0115,.3354-.2351,.604-.6259,.604-.3469,0-.5925-.2686-.5925-.604,0-.3469,.257-.6144,.6144-.6144,.3688,0,.604,.2674,.604,.6144Zm-1.0962,6.9343v-5.414h.9844v5.414h-.9844Z" fill="#fff" />
                                    <path d="M623.659,181.8614c0-.5602-.0104-1.0178-.0438-1.4662h.8714l.0565,.8956h.0219c.2686-.5152,.8956-1.0178,1.7901-1.0178,.7492,0,1.9122,.4472,1.9122,2.3041v3.232h-.9844v-3.1202c0-.8726-.3239-1.5999-1.2529-1.5999-.6478,0-1.1515,.4588-1.3198,1.0074-.0438,.1222-.0669,.2905-.0669,.4588v3.2539h-.9844v-3.9478Z" fill="#fff" />
                                    <path d="M630.4147,183.2815c.0219,1.3313,.8726,1.8788,1.8569,1.8788,.7043,0,1.1296-.1222,1.4984-.2789l.1671,.7043c-.3469,.1568-.9394,.3354-1.8004,.3354-1.6667,0-2.6615-1.0962-2.6615-2.7295,0-1.6322,.9613-2.9185,2.5381-2.9185,1.767,0,2.2373,1.5538,2.2373,2.5497,0,.2017-.0219,.3585-.0334,.4588h-3.8026Zm2.8851-.7043c.0115-.6259-.257-1.5987-1.3647-1.5987-.9947,0-1.4316,.9164-1.51,1.5987h2.8747Z" fill="#fff" />
                                    <path d="M637.7063,184.803c.2905,.189,.8057,.3919,1.2967,.3919,.7158,0,1.0524-.3585,1.0524-.8069,0-.4691-.2801-.7262-1.0074-.9947-.9728-.3469-1.4316-.8841-1.4316-1.5319,0-.8726,.7054-1.5884,1.8684-1.5884,.5475,0,1.0282,.1568,1.3302,.3354l-.2455,.7158c-.2121-.1337-.604-.3135-1.1077-.3135-.5809,0-.906,.3366-.906,.7388,0,.4472,.325,.6489,1.0293,.9164,.9394,.3585,1.4201,.8276,1.4201,1.6333,0,.9509-.7377,1.6218-2.0241,1.6218-.5925,0-1.1411-.1452-1.5215-.3688l.2467-.7492Z" fill="#fff" />
                                    <path d="M642.2685,177.8686h.9844v3.3773h.0231c.1556-.2789,.4023-.5256,.7043-.6939,.2905-.1671,.6374-.2789,1.0063-.2789,.7273,0,1.8903,.4472,1.8903,2.3145v3.2217h-.9844v-3.1087c0-.8737-.3239-1.6114-1.2529-1.6114-.6374,0-1.14,.4472-1.3198,.9844-.0553,.1337-.0669,.2789-.0669,.4703v3.2655h-.9844v-7.9406Z" fill="#fff" />
                                    <path d="M653.3755,183.0579c0,2.0022-1.3866,2.8747-2.6949,2.8747-1.465,0-2.5946-1.0743-2.5946-2.7848,0-1.812,1.1849-2.8747,2.6834-2.8747,1.5549,0,2.6061,1.1296,2.6061,2.7848Zm-4.2948,.0553c0,1.1861,.6824,2.0817,1.6448,2.0817,.9394,0,1.6437-.8841,1.6437-2.1036,0-.9164-.4588-2.0805-1.6218-2.0805s-1.6667,1.0743-1.6667,2.1024Z" fill="#fff" />
                                    <path d="M654.9304,180.3952l.7158,2.7525c.1568,.604,.302,1.163,.4023,1.7221h.0334c.1233-.5487,.302-1.1296,.4818-1.7105l.8829-2.7641h.8276l.8391,2.7076c.2017,.6489,.3573,1.2184,.4807,1.767h.0334c.0899-.5487,.2351-1.1181,.4138-1.7566l.7723-2.7179h.9728l-1.7451,5.414h-.8945l-.8276-2.5831c-.1902-.604-.3469-1.1411-.4807-1.7785h-.0231c-.1337,.6489-.302,1.2068-.4922,1.7901l-.8714,2.5716h-.8956l-1.6322-5.414h1.0063Z" fill="#fff" />
                                    <path d="M662.7696,181.8614c0-.5602-.0104-1.0178-.0438-1.4662h.8714l.0565,.8956h.0219c.2686-.5152,.8956-1.0178,1.7901-1.0178,.7492,0,1.9122,.4472,1.9122,2.3041v3.232h-.9844v-3.1202c0-.8726-.3239-1.5999-1.2529-1.5999-.6478,0-1.1515,.4588-1.3198,1.0074-.0438,.1222-.0669,.2905-.0669,.4588v3.2539h-.9844v-3.9478Z" fill="#fff" />
                                </g>
                                <rect x="494.2473" y="153.5671" width="27.9913" height="14.1413" fill="#7b7b7d" />
                                <rect x="494.2473" y="176.541" width="27.9913" height="14.1413" fill="#b2b2b2" opacity=".5" />
                            </g>
                        </g>
                    </g>
                </svg>

            </div>

        </div>


    )
}

export default SchematicSVGContent;
