import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import ReadBtn from '../../assets/BtnIcon/BI-_Btn-Review.svg'
import EditBtn from '../../assets/BtnIcon/BI-_Btn-Edit.svg'


import useCheckUserRights from '../../hooks/useCheckUserRights'


import './table.css'

const TableContent = ({ filteredData, searchParam }) => {


    const { buttonStatus } = useCheckUserRights()
    const location = useLocation();
    const navigate = useNavigate()

    const [formDirect, setFormDirect] = useState("")

    useEffect(() => {
        const latestURL = window.location.href
        const urlArray = latestURL.split(/[/]/);
        const item = urlArray.length
        if (urlArray[item - 2] === "civils-works") {
            setFormDirect("civils")
        } else if (urlArray[item - 2] === "signalling-works") {
            setFormDirect("signalling")
        } else if (urlArray[item - 2] === "cabling") {
            setFormDirect("cabling")
        }
    }, [location])


    const [{ sortBy, asc }, setSort] = useState({ sortBy: "", asc: true })

    const onSort = (category) => {
        if (category === sortBy) {
            setSort({ sortBy: category, asc: !asc })
        } else {
            setSort({ sortBy: category, asc: true })
        }
    }

    const sortData = [...filteredData].sort((a, b) => {

        if (asc) {
            return a[sortBy] > b[sortBy] ? -1 : 1
        }
        return b[sortBy] > a[sortBy] ? -1 : 1
    })

    const readPathBtnFunction = (assetType, id) => {
        navigate(`/app/${(assetType).toLowerCase()}/asset/${id}`)

    }

    const editPathBtnFunction = (assetType, id) => { navigate(`/app/${(assetType).toLowerCase()}form/${formDirect}/${id}`) }

    return (

        <div className='detailsTable-main-container'>
            <div className='detailsTable-top-text-container summary-text-fs09 '>

                {searchParam.map((item, index) => { return (<div className={item.className + " detailTableHeader"} key={item.name + "header"} onClick={() => onSort(item.key)}>{item.name}</div>) })}
                <div className='detailTableHeader table-row-5p'>Review</div>
                {buttonStatus ? <div className='detailTableHeader table-row-5p'>Edit</div> : <></>}

            </div>
            {sortData.map((item, index) => {
                return (
                    <div className='detailsTable-row-container summary-text-fs09' key={index + "actionissues"}>

                        {searchParam.map((value, index2) => {
                            return <div className={value.className + " detailTableRow"} key={index2 + "row"} >
                                <div >{item[value.key]} </div>
                            </div>
                        })}

                        <div className='detailTableRow table-row-5p'><button className='table-btn' onClick={() => { readPathBtnFunction(item.assetType, item.id) }}> <img className='btn-img' src={ReadBtn} alt="btn-read" /></button></div>

                        {buttonStatus ? <div className='detailTableRow table-row-5p'><button className='table-btn' onClick={() => { editPathBtnFunction(item.assetType, item.id) }}> <img className='btn-img' src={EditBtn} alt="btn-read" /></button></div> : <></>}

                    </div>
                )
            })}
        </div>

    )
}

export default TableContent