import React from "react";
import { useState, useRef, useEffect, useContext } from 'react';

import { fibreCableDisplayStyles, mapStyles } from "../../constants/styles/mapStyles";

// MAPBOX LIBRARY
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";


import './mapbox.css'
import { getCoordinatesFromGeometry } from "../../pageStyles/MapBox/MapBox";

import { powerCableDisplayStyles } from "../../constants/styles/mapStyles";

mapboxgl.accessToken = mapStyles.accessToken;

const ProjectMap = ({ lonData, latData, currentItemData }) => {

    console.log("currentItemData", currentItemData.assetType)

    const coordinates = getCoordinatesFromGeometry(currentItemData)
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(0.09464420655139119);
    const [lat, setLat] = useState(52.21581857944906);
    const [zoom, setZoom] = useState(16);
    const [mapLoaded, setMapLoaded] = useState(false)
    const featureCollection = {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                geometry: {
                    "type": "LineString",
                    coordinates: currentItemData["coordinates"]
                }
            }
        ]
    }
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: process.env.REACT_APP_MAPBOX_STYLE_PLAIN,
            center: coordinates,
            zoom: zoom,
            preserveDrawingBuffer: true
        });
        map.current.on("load", () => {
            if (mapLoaded) return;

            map.current.addSource("line", {
                type: "geojson",
                data: featureCollection
            })
            map.current.addLayer({
                id: "projectassetline",
                type: "line",
                source: "line",
                paint: currentItemData.assetType === "PowerCable" ? powerCableDisplayStyles[0].paint : fibreCableDisplayStyles[0].paint,
            })
            setMapLoaded(true)
        })

    }, []);

    useEffect(() => {
        if (map.current) {
            map.current.flyTo({
                center: coordinates,
                zoom: 13.5
            })

            let marker = new mapboxgl.Marker().setLngLat({ lng: coordinates[0], lat: coordinates[1] }).addTo(map.current);

        }
        setLng(coordinates[0])
        setLat(coordinates[1])

    }, [currentItemData, map.current]);

    return (
        <div className="map-card">

            <div ref={mapContainer} className="map-container-project" />

        </div>
    );

}

export default ProjectMap;