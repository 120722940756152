import React from 'react';
import Graph from './Graph';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default function BarChart(prop) {

    // chart and ID are both coming from the graph.js

    const barChartStyle = (chart, id) => {
        let graph = am4core.create(id, am4charts.XYChart);

        graph.responsive.enabled = true;
        am4core.options.autoSetClassName = true;

        graph.cursor = new am4charts.XYCursor();
        graph.cursor.lineX.disabled = true;
        graph.cursor.lineY.disabled = true;
        graph.cursor.behavior = "none"

        // Add data
        graph.data = prop.data;

        // Set cell size in pixels
        // let cellSize = prop.cellSize;
        let cellSize = 50;

        let specificGraphDataLength = prop.data.Length
        // let specificGraphDataLength = 2


        graph.events.on("datavalidated", function (ev) {

            // Get objects of interest
            let graph = ev.target;


            // Set it on chart's container
            // graph.svgContainer.htmlElement.style.height = targetHeight * cellSize + + "px";
            graph.svgContainer.htmlElement.style.height = specificGraphDataLength * cellSize + 2 + "px";

        });

        /* Create axes */
        var categoryAxis = graph.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "itemNameText";
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.strokeWidth = 0;
        categoryAxis.id = "myCategoryAxis";
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        //create value axis
        var valueAxis = graph.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.grid.template.strokeWidth = 0;
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");
        valueAxis.min = 0;
        valueAxis.id = "myValueAxis";
        valueAxis.strictMinMax = true;


        //create columns

        var completedBar = graph.series.push(new am4charts.ColumnSeries());
        completedBar.dataFields.categoryY = "itemNameText";
        completedBar.dataFields.valueX = "qtyComplete";
        completedBar.name = "Qty Complete";
        completedBar.stacked = true;
        completedBar.columns.template.fillOpacity = 1;
        completedBar.columns.template.strokeOpacity = 0;
        // completedBar.tooltipText = "Complete: {valueX.value}";
        completedBar.fill = am4core.color("#17bebb");

        completedBar.columns.template.tooltipText = `[#fff font-size: 15px]{name}\n[/] [#fff font-size: 15px]{qtyComplete}:\n[/]`;
        completedBar.columns.template.tooltipX = am4core.percent(50);
        completedBar.columns.template.tooltipY = am4core.percent(0);
        completedBar.tooltip.background.cornerRadius = 5;
        completedBar.tooltip.getFillFromObject = false
        completedBar.tooltip.background.fill = am4core.color("#17bebb")
        completedBar.tooltip.background.strokeOpacity = 0;
        // completedBar.tooltip.pointerOrientation = "right";
        completedBar.tooltip.label.minWidth = 20;
        completedBar.tooltip.label.minHeight = 20;
        completedBar.tooltip.label.textAlign = "middle";
        completedBar.tooltip.label.TextValign = "middle";
        completedBar.tooltip.label.paddingLeft = "10"
        completedBar.tooltip.label.paddingRight = "10"
        completedBar.tooltip.label.paddingTop = "10"
        completedBar.tooltip.label.paddingBottom = "10"
        completedBar.tooltip.dy = -25;



        // categoryAxis.renderer.cellStartLocation = 0.2;
        // categoryAxis.renderer.cellEndLocation = 0.8;

        //...

        completedBar.columns.template.width = am4core.percent(50);








        var outstandingBar = graph.series.push(new am4charts.ColumnSeries());
        outstandingBar.dataFields.categoryY = "itemNameText";
        outstandingBar.dataFields.valueX = "qtyOutstanding";
        outstandingBar.name = "Qty Outstanding";
        outstandingBar.stacked = true;
        outstandingBar.columns.template.fillOpacity = 0.1;
        outstandingBar.columns.template.strokeOpacity = 0;
        // outstandingBar.tooltipText = "Outstanding: {valueX.value}";
        outstandingBar.fill = am4core.color("#ffffff99");



        //add chart cursor
        // graph.cursor = new am4charts.XYCursor();
        // graph.cursor.behavior = "zoomY";

        //add legend
        graph.legend = new am4charts.Legend();


        outstandingBar.legendSettings.labelText = "[#fff font-size: 16px]Baseline";
        // forecastSeries.legendSettings.labelText = "[font-size: 16px]Reforecast";
        completedBar.legendSettings.labelText = "[#fff font-size: 16px]Complete";






        graph.current = graph;
        return graph;

    }

    return (
        <Graph applyStyle={barChartStyle} graphRefresh={prop.data} graphRefresh2={prop.data} />
    )
}