import React, { useContext, useState, useEffect } from "react";

import DbCard from "../../components/cards/DbCard";

import { db } from "../../firebase";

import '../../CssGeneral/dashboardContent.css'

import { deleteDoc, doc } from 'firebase/firestore'
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { DataContext } from "../../Contexts/DataContext";

const DeleteCheck = () => {

    const params = useParams();
    const data = useContext(DataContext)
    const navigate = useNavigate()
    const selectedItemId = params.id
    const location = useLocation()

    const [currentItemData, setCurrentItemData] = useState(null)

    const [firebaseFolder, setFirebaseFolder] = useState(null)
    const [formDirectPartA, setFormDirectPartA] = useState("")
    const [formDirectPartB, setFormDirectPartB] = useState("")

    const latestURL = window.location.href
    const urlArray = latestURL.split(/[/]/)
    const length = urlArray.length;

    useEffect(() => {
        const selectCurrentItem = (dataSet) => {
            let output = [];
            dataSet.forEach((item, index) => {
                if (item.id === selectedItemId) {
                    output.push(item)
                }
            })
            return output
        }

        if (urlArray[length - 3] === "signalsdelete") {
            setCurrentItemData(selectCurrentItem(data.SignalCivilsData.features)[0].properties.Name)
            setFirebaseFolder("Signals")
            setFormDirectPartB("signals")

        }
        else if (urlArray[length - 3] === "equipmentcubiclesdelete") {
            setCurrentItemData(selectCurrentItem(data.EquipmentCubicleCivilsData.features)[0].properties.Name)
            setFirebaseFolder("EquipmentCubicles")
            setFormDirectPartB("equipment")


        } else if (urlArray[length - 3] === "utxproposedcentresdelete") {
            setCurrentItemData(selectCurrentItem(data.UTXProposedCentresData.features)[0].properties.Name)
            setFirebaseFolder("UTXProposedCentres")
            setFormDirectPartB("routeworks")

        } else if (urlArray[length - 3] === "powercablesdelete") {
            setCurrentItemData(selectCurrentItem(data.PowerCablesData.features)[0].properties.Name)
            setFirebaseFolder("PowerCables")
            setFormDirectPartB("power")

        } else if (urlArray[length - 3] === "fibrecablesdelete") {
            setCurrentItemData(selectCurrentItem(data.FibreCablesData.features)[0].properties.Name)
            setFirebaseFolder("FibreCables")
            setFormDirectPartB("fibre")
        }


    }, [])

    useEffect(() => {

        if (urlArray[length - 1] === "civils" || urlArray[length - 2] === "civils") {
            setFormDirectPartA('civils-works')
        } else if (urlArray[length - 1] === "signalling" || urlArray[length - 2] === "signalling") {
            setFormDirectPartA('signalling-works')
        } else if (urlArray[length - 1] === "cabling" || urlArray[length - 2] === "cabling") {
            setFormDirectPartA('cabling')
        }
    }, [])

    const [loadingData, setLoadingData] = useState(false)

    const navigatedPath = () => { navigate(`/app/${formDirectPartA}/${formDirectPartB}?type=table`) };

    const returnPath = () => { navigate(-1) };

    const handleDelete = async () => {
        setLoadingData(true)
        await deleteDoc(doc(db, firebaseFolder, selectedItemId));
        setLoadingData(false)
        navigatedPath(`/${formDirectPartA}/${formDirectPartB}?type=table`)
        // window.location.reload(false);
    };


    return (
        <div className="dashboard-container ">
            <DbCard
                cardType={'graph-card-wide card-height-min card-static'}
                icon={""}
                title={`Delete Asset`}
                cardContent={""}
                btnText={"Go back"}
                mainBtnText={`Delete`}
                loadingData={loadingData}
                btnDisplayBool={true}
                btnMainDisplayBool={true}
                OnClickFuncMainBtn={handleDelete}
                OnClickFunc={returnPath}>

                <div className="delete-details-container">
                    {currentItemData ? <div className="delete-text summary-text-fs1p3">Are you sure you want to delete  <span className="orange-name">{currentItemData} </span> ?
                    </div> : <div className="delete-text summary-text-fs1p3">Are you sure you want to delete this?
                    </div>}

                </div>

            </DbCard>


        </div >

    )
}

export default DeleteCheck