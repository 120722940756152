import React from 'react'

const SignalGroundIcon = () => {
    return (
        <g id="Icons">
            <g>
                <path d="m33.7912,16.721l-11.558-9.5519c-2.1785-1.8004-5.3441-1.7445-7.4578.1316l-4.9152,4.3629c-1.402,1.2444-2.2043,3.0293-2.2043,4.9039v7.8447c0,1.5832,1.2835,2.8666,2.8666,2.8666h21.9381c1.5926,0,2.8835-1.291,2.8835-2.8835v-4.3764c0-1.2758-.5694-2.4852-1.553-3.2979Zm-19.9884,7.8226c-1.7229,0-3.1196-1.3967-3.1196-3.1196s1.3967-3.1196,3.1196-3.1196,3.1196,1.3967,3.1196,3.1196-1.3967,3.1196-3.1196,3.1196Zm4.4453-9.9367c-1.7229,0-3.1196-1.3967-3.1196-3.1196s1.3967-3.1196,3.1196-3.1196,3.1196,1.3967,3.1196,3.1196-1.3967,3.1196-3.1196,3.1196Zm11.2826,9.9367c-1.7229,0-3.1196-1.3967-3.1196-3.1196s1.3967-3.1196,3.1196-3.1196,3.1196,1.3967,3.1196,3.1196-1.3967,3.1196-3.1196,3.1196Z" fill="#fff" />
                <polygon points="26.4246 35.6767 26.4246 27.2955 16.5755 27.2955 16.5755 35.6767 11.3494 35.6767 11.3494 37.1446 16.5755 37.1446 26.4246 37.1446 31.6507 37.1446 31.6507 35.6767 26.4246 35.6767" fill="#808e93" />
            </g>
        </g>
    )
}

export default SignalGroundIcon;
