
export const signalAssetPageContent = [
    { displayName: "Asset Name", keyValue: "Name", sectionTitle: "Details" },
    { displayName: "Foundation type", keyValue: "foundation type", sectionTitle: "Details" },
    { displayName: "Structure type", keyValue: "structure type", sectionTitle: "Details" },
    { displayName: "Signal type", keyValue: "signal head type", sectionTitle: "Details" },
    { displayName: "Aspect No.", keyValue: "aspect no", sectionTitle: "Details" },

    { displayName: "Base Status", keyValue: "Base", sectionTitle: "Install Status" },
    { displayName: "Structure Status", keyValue: "Structure", sectionTitle: "Install Status" },
    { displayName: "Signal Head Status", keyValue: "Signal Head", sectionTitle: "Install Status" },

    { displayName: "Base Date", keyValue: "Base_Date", sectionTitle: "Dates" },
    { displayName: "Structure Date", keyValue: "Base_Date", sectionTitle: "Dates" },
    { displayName: "Signal Date", keyValue: "Base_Date", sectionTitle: "Dates" }

]

export const equipmentAssetPageContent = [
    { displayName: "Asset Name", keyValue: "Name", sectionTitle: "Details" },
    { displayName: "Type", keyValue: "description", sectionTitle: "Details" },

    { displayName: "Base Status", keyValue: "Base", sectionTitle: "Install Status" },
    { displayName: "Cubicle Status", keyValue: "Structure", sectionTitle: "Install Status" },

    { displayName: "Base Date", keyValue: "Base_Date", sectionTitle: "Dates" },
    { displayName: "Cubicle Date", keyValue: "Base_Date", sectionTitle: "Dates" },

]

export const utxAssetPageContent = [
    { displayName: "Asset Name", keyValue: "Name", sectionTitle: "Details" },
    { displayName: "Type", keyValue: "type", sectionTitle: "Details" },

    { displayName: "Chambers Status", keyValue: "Base", sectionTitle: "table-row-20p" },
    { displayName: "Crossing Status", keyValue: "Structure", sectionTitle: "table-row-20p" },
]

export const cableAssetPageContent = [
    { displayName: "Asset Name", keyValue: "Name", sectionTitle: "Details" },
    { displayName: "Status", keyValue: "status", sectionTitle: "Details" },
    { displayName: "Length Total(Km)", keyValue: "length total", sectionTitle: "Qty" },
    { displayName: "Length Complete", keyValue: "length complete", sectionTitle: "Qty" }
]



const dataIdentifier = (type, data, selectedItemId) => {
    let dataEntry = data[type].features.find(item => item.id === selectedItemId)
    return { ...dataEntry["properties"], ...dataEntry["geometry"], assetType: dataEntry["type"], id: dataEntry.id }
}

export const assetIdentifier = (selectedAssetType, data, selectedItemId) => {
    switch (selectedAssetType) {
        case "signals":
            return {
                data: dataIdentifier("SignallingData", data, selectedItemId),
                pageContentList: signalAssetPageContent,
                title: "Signal",
                editRedirect: "/app/signalsform/civils/",
                returnRedirect: "/app/civils-works/signals?type=table",
                deleteRedirect: "/app/signalsdelete/civils/"
            }
        case "equipmentcubicles":
            return {
                data: dataIdentifier("EquipmentCubicleCivilsData", data, selectedItemId),
                pageContentList: equipmentAssetPageContent,
                title: "Equipment",
                editRedirect: "/app/equipmentcubiclesform/civils/",
                returnRedirect: "/app/civils-works/equipment?type=table",
                deleteRedirect: "/app/equipmentcubiclesdelete/civils/"
            }

        case "utxproposedcentres":
            return {
                data: dataIdentifier("UTXProposedCentresData", data, selectedItemId),
                pageContentList: utxAssetPageContent,
                title: "Track Crossing",
                editRedirect: "/app/utxproposedcentresform/civils/",
                returnRedirect: "/app/civils-works/routeworks?type=table",
                deleteRedirect: "/apputxproposedcentressdelete/civils/"
            }

        case "powercables":
            return {
                data: dataIdentifier("PowerCablesData", data, selectedItemId),
                pageContentList: cableAssetPageContent,
                title: "Power Cable",
                editRedirect: "/app/powercablesform/cabling/",
                returnRedirect: "/app/cabling/power?type=table",
                deleteRedirect: "/app/powercablesdelete/cabling/"

            }

        case "fibrecables":
            return {
                data: dataIdentifier("FibreCablesData", data, selectedItemId),
                pageContentList: cableAssetPageContent,
                title: "Fibre Cable",
                editRedirect: "/app/fibrecablesform/cabling/",
                returnRedirect: "/app/cabling/fibre?type=table",
                deleteRedirect: "/app/fibrecablesdelete/cabling/"
            }

        default:
            return {
                data: dataIdentifier("SignallingData", data, selectedItemId),
                pageContentList: signalAssetPageContent,
                title: "Signal",
                editRedirect: "/app/signalsform/civils/",
                returnRedirect: "/app/civils-works/signals?type=table",
                deleteRedirect: "/app/signalsdelete/civils/"

            }

    }
}
