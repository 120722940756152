

// These are the values that are used to populate the table content in the Table page
// They control the table headers and also the filter list

export const signalSearchParams = [
    { name: "Asset Name", key: "Name", className: "table-row-15p" },
    { name: "Structure type", key: "structure type", className: "table-row-15p" },
    { name: "Signal type", key: "signal head type", className: "table-row-15p" },

    { name: "Base Status", key: "Base", className: "table-row-15p" },
    { name: "Structure Status", key: "Structure", className: "table-row-15p" },

]

export const structureSearchParams = [
    { name: "Asset Name", key: "Name", className: "table-row-15p" },
    // { name: "Structure type", key: "structure type", className: "table-row-15p" },
    // { name: "Signal type", key: "signal head type", className: "table-row-15p" },

    // { name: "Base Status", key: "Base", className: "table-row-15p" },
    // { name: "Structure Status", key: "Structure", className: "table-row-15p" },

]


export const equipmentSearchParams = [
    { name: "Asset Name", key: "Name", className: "table-row-20p" },
    { name: "Type", key: "description", className: "table-row-20p" },
    // { name: "Design Status", key: "Design", className: "detailsTable-Icon-container" },
    { name: "Base Status", key: "Base", className: "table-row-20p" },
    { name: "Structure Status", key: "Structure", className: "table-row-20p" },

]

export const utxSearchParams = [
    { name: "Asset Name", key: "Name", className: "table-row-20p" },
    { name: "Type", key: "type", className: "table-row-20p" },

    { name: "Chambers Status", key: "Base", className: "table-row-20p" },
    { name: "Crossing Status", key: "Structure", className: "table-row-20p" },

]

export const cableSearchParams = [
    { name: "Asset Name", key: "Name", className: "table-row-20p" },
    { name: "Status", key: "status", className: "table-row-20p" },
    { name: "Length Total", key: "length total", className: "table-row-20p" },
    { name: "Length Complete", key: "length complete", className: "table-row-20p" }

]


const dataIdentifier = (type, data) => data[type].features.map(item => {
    return { ...item["properties"], assetType: item["type"], id: item.id }
})

export const assetTypeIdentifier = (name, data) => {
    switch (name) {
        case "Signals": return {
            searchParams: signalSearchParams,
            data: dataIdentifier("SignallingData", data),
            formPathRedirect: "/app/signalsform/civils"
        }
            break;
        case "Structures": return {
            searchParams: signalSearchParams,
            data: dataIdentifier("SignallingData", data),
            formPathRedirect: "/app/signalsform/civils"
        }
            break;
        case "ASP & PSP & LOC": return {
            searchParams: equipmentSearchParams,
            data: dataIdentifier("EquipmentCubicleCivilsData", data),
            formPathRedirect: "/app/equipmentcubiclesform/civils"
        }
            break;

        case "URX and UTX": return {
            searchParams: utxSearchParams,
            data: dataIdentifier("UTXProposedCentresData", data),
            formPathRedirect: "/app/utxproposedcentresform/civils/"
        }
            break;
        case "Power Cable": return {
            searchParams: cableSearchParams,
            data: dataIdentifier("PowerCablesData", data),
            formPathRedirect: "/app/powercablesform/cabling/"
        }
            break;
        case "Fibre Cable": return {
            searchParams: cableSearchParams,
            data: dataIdentifier("FibreCablesData", data),
            formPathRedirect: "/app/fibrecablesform/cabling/"
        }
            break;
        default: return {
            searchParams: signalSearchParams,
            data: dataIdentifier("SignallingData", data),
            formPathRedirect: "/app/signalsform/civils"
        }
            break;
    }
}



