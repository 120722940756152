import React from 'react'

const RebCabIcon = () => {
    return (
        <g>

            <rect x="2.0777" y="21.8472" width="38.8446" height="9.8094" fill="#808e93" opacity=".6" />
            <g>
                <path d="m28.0502,14.2709c-.9697,0-1.5342.9229-1.5342,2.1562,0,1.2427.583,2.1182,1.5439,2.1182.9697,0,1.5244-.9224,1.5244-2.1558,0-1.1392-.5459-2.1187-1.5342-2.1187Z" fill="#fff" />
                <path d="m14.3383,14.2899c-.3193,0-.5264.0283-.6494.0566v4.1519c.123.0278.3203.0278.499.0278,1.2998.0098,2.1465-.7061,2.1465-2.2217.0098-1.3179-.7627-2.0146-1.9961-2.0146Z" fill="#fff" />
                <path d="m4.8842,11.6409v9.6165h33.4111v-9.6165H4.8842Zm11.9209,7.1871c-.668.5552-1.6846.8188-2.9277.8188-.7441,0-1.2705-.0469-1.6289-.0942v-6.2324c.5273-.085,1.2148-.1318,1.9395-.1318,1.2051,0,1.9863.2168,2.5986.6777.6592.4897,1.0732,1.271,1.0732,2.3916,0,1.2144-.4424,2.0522-1.0547,2.5703Zm7.2471.7529h-1.5068l-1.3555-2.4478c-.377-.6777-.791-1.4971-1.1016-2.2407l-.0283.0093c.0381.8379.0566,1.7324.0566,2.7681v1.9111h-1.3184v-6.3457h1.6758l1.3184,2.3257c.377.6685.7529,1.4595,1.0352,2.1748h.0283c-.0938-.8379-.1221-1.6948-.1221-2.6455v-1.855h1.3184v6.3457Zm3.9326.1035c-1.8838,0-2.9854-1.4214-2.9854-3.229,0-1.9019,1.2148-3.3237,3.0889-3.3237,1.9482,0,3.0127,1.4595,3.0127,3.2104,0,2.0806-1.2617,3.3423-3.1162,3.3423Z" fill="#fff" />
            </g>
        </g>
    )
}


export default RebCabIcon;