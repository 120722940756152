import React from 'react'

const FBIconTable = ({ fill, onClick }) => {
    return (
        <div className='float-icon-container' onClick={onClick}>
            <svg id="new_icons" data-name="new icons" xmlns="http://www.w3.org/2000/svg" width="22mm" height="22mm" viewBox="0 0 62.3622 62.3622">
                <path d="m23.9993,33.9488l-2.8405,6.3737,1.0237,1.0241,6.3852-2.8298-4.5684-4.568ZM51.5791,3.6031l7.3331,7.3334-29.0112,29.0108-.0031.0011-11.6946,4.5351c-.0981.0264-.2031-.0808-.1747-.187l4.5385-11.6792.0008-.0031L51.5791,3.6031Z" fill={fill} fillRule="evenodd" />
                <path d="m56.2096,1.2902l5.0159,5.0159c.6375.6371.6337,1.6762,0,2.3167l-1.6751,1.695-7.3331-7.3331,1.6751-1.6946c.6337-.6414,1.6796-.6375,2.3171,0" fill={fill} fillRule="evenodd" />
                <path d="m7.1793,6.1364h25.8159v5.2461H7.1793c-.3494,0-.6666.1398-.8973.3655l-.0107.0104c-.2253.231-.3656.5482-.3656.8972v42.3766c0,.349.1403.6662.3656.8972l.0107.01c.2307.2257.5479.3659.8973.3659h42.3762c.3494,0,.6666-.1402.8981-.3659l.01-.01c.2253-.231.3655-.5482.3655-.8972v-25.1596h5.2457v25.1596c0,1.788-.7325,3.4156-1.9126,4.596l-.0107.0104c-1.1801,1.18-2.8076,1.913-4.5961,1.913H7.1793c-1.7884,0-3.416-.7329-4.596-1.913l-.01-.0104c-1.1808-1.1804-1.9134-2.8079-1.9134-4.596V12.6556c0-1.7884.7325-3.4156,1.9134-4.596l.01-.01c1.18-1.1804,2.8075-1.9133,4.596-1.9133" fill={fill} />
            </svg></div>
    )
}

export default FBIconTable