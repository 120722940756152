import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

export default function Graph(props) {
    const chart = useRef(null);
    const [id, setId] = useState(uuid());
    let graphRefresh = props.graphRefresh
    let graphRefresh2 = props.graphRefresh2

    useLayoutEffect(() => {
        let graph = props.applyStyle(chart, id);

        return () => {
            graph.dispose();
        };
    }, [graphRefresh, graphRefresh2]);

    return (
        <div id={id} className={`graph-height-general ${props.graphStyle}`}></div>

    );

}


