import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import Routestructure from './Routestructure';
import LogOutReview from './pages/LogOutReview';
import DataLoadedPage from './pages/DataLoadedPage'

function App() {
  const navigate = useNavigate();
  return (
    <>
      <Routes>
        <Route path='/app/*' element={<DataLoadedPage />}>
          <Route path="*" element={<Routestructure></Routestructure>} />
        </Route>

        <Route path="/logoutreview" element={<LogOutReview />} />
      </Routes>
    </>
  );
}

export default App;
