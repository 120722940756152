import React from 'react'
import { useLocation } from "react-router-dom"
import GraphPage from './GraphPage/GraphPage'

import { useDataContext } from '../Contexts/DataContext';

const SummaryDashboard = () => {

    const { findPathnameData } = useDataContext()
    const { pathname } = useLocation();
    return (
        <>
            <GraphPage dataForPathname={findPathnameData(pathname)}></GraphPage>
        </>
    )
}

export default SummaryDashboard