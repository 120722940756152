import React from 'react'

const SignalPointsIcon = () => {
    return (
        <g id="Icons">
            <g>
                <path d="M30.891,24.4995l10.9988-10.9984-.7901-.7901-9.7923,9.7922v-1.3264h-3.1462v-5.3571h3.1462v-1.9289h-3.1359l7.485-7.4851-.79-.7899-6.7053,6.7053v-3.7874h3.1462v-1.9287h-3.1462v-1.5152h-1.1173v1.5152h-8.3113v-1.5152h-1.1173v1.5152h-2.9613v1.9287h2.9613v5.3571h-2.9613v1.9289h2.9613v5.3571h-2.9613v1.9287h2.9613v5.3573h-2.9613v1.9284h2.9613v4.9745h-2.9613v1.9289h2.9613v3.4434h1.1173v-3.4434h8.3113v3.4434h1.1173v-3.4434h3.1462v-1.9289h-3.1462v-4.2759c0-.2371,.0509-.4642,.0683-.6986h3.0779v-1.9284h-2.7264c.434-1.4767,1.1912-2.8441,2.31-3.9633Zm-.1859-1.3941l-.6042,.6042c-.817,.8171-1.4412,1.7651-1.9397,2.7708v-3.375h2.5439Zm-3.6613,5.3573h-8.253c.1447-1.8801,.634-3.6862,1.4294-5.3573h6.8236v5.3573Zm0-7.286h-5.746c.5531-.8252,1.1904-1.6028,1.9087-2.3208l3.0363-3.0363h.801v5.3571Zm-8.3113-12.6431h8.3113v4.9049l-.4524,.4522h-7.8588v-5.3571Zm0,7.286h5.9302l-2.2463,2.2462c-.9459,.9461-1.7446,1.9961-2.4219,3.1109h-1.2619v-5.3571Zm.2525,7.2858c-.089,.2038-.172,.4095-.2525,.617v-.617h.2525Zm-.2525,12.2603v-4.9745h8.3113v4.9745h-8.3113Z" fill="#bce3f4" opacity=".6" />
                <polygon points="20.7583 13.0982 20.7583 12.0327 11.9156 12.0327 11.9156 7.9758 2.0068 7.9758 2.0068 36.9492 11.9156 36.9492 11.9156 32.6828 20.7583 32.6828 20.7583 31.6169 11.9156 31.6169 11.9156 13.0982 20.7583 13.0982" fill="#0f516e" stroke="#0f516e" strokeMiterlimit="10" strokeWidth=".4133" />
            </g>
        </g>
    )
}
export default SignalPointsIcon