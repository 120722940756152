import { JSONDataFilterByProperty } from '../../../../constants/functions/jsonDataFilterFunctions';
import { MapLine } from '../ComponentGeneral/MapLine'

import { statusTypes, powerCableDisplayStyles } from '../../../../constants/styles/mapStyles';

export const PowerNewCable = (map, isVisible, pathname, data, routesStructure, tableContent) => {

    const constantStyle = {
        map: map,
        isVisible,
        minZoom: 13,
        // maxZoom: 13,
        relatedPathNames: [routesStructure.civils_equipment_route, routesStructure.signalling_cubicles_route, routesStructure.cabling_all_route, routesStructure.cabling_power,],
        tableValues: tableContent,
        type: "line",
    }

    const symbolsData = statusTypes.map((statusType) => {
        return {
            status: statusType,
            data: JSONDataFilterByProperty(data, { "status": statusType }, statusType, "status"),
            source: statusType + "PowerCable",
            paint: powerCableDisplayStyles.filter((style) => style.status === statusType)[0].paint,
            ...constantStyle

        }
    })

    symbolsData.forEach((symbolData) => MapLine(symbolData));
};