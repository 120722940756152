export const routesStructure = {
    index_route: '/',
    project_summary_route: '/app/project-summary',
    access_route: '/app/access',
    civils_all_route: '/app/civils-works/all-works',
    civils_signals_route: '/app/civils-works/signals',
    civils_structures_route: '/app/civils-works/structures',
    civils_equipment_route: '/app/civils-works/equipment',
    civils_route_works_route: '/app/civils-works/routeworks',
    signalling_all_route: '/app/signalling-works/all-works',
    signalling_heads_route: '/app/signalling-works/heads',
    signalling_cubicles_route: '/app/signalling-works/cubicles',
    cabling_all_route: '/app/cabling/all-works',
    cabling_power: '/app/cabling/power',
    cabling_fibre: '/app/cabling/fibre',
}