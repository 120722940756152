import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Header from "./components/Header";
import SideNav from "./components/SideNav";
import { graphStyles } from './constants/styles/graphStyle';
import "./styles/Homepage/styles.scss";

import GraphBar from './components/GraphBarLeft/GraphBar/GraphBar';
import GraphBoxNew from './components/GraphBarLeft/GraphBoxNew/GraphBoxNew';
import PieChartList from './components/GraphBarLeft/svgGraph/PieChartList';

import FloatingButton from './components/floatingButton/FloatingButtons';

import { headerContent } from './constants/appSetup/headerIcons';

import * as am4core from '@amcharts/amcharts4/core';
import { useDataContext } from './Contexts/DataContext';
import { uiInterfaceUrlHides, floatingButtonUrlHides } from './constants/displayControl/uiInterfaceUrlHides'

am4core.addLicense(graphStyles.accessToken);

function Dashboard(props) {

    const [isHamburgerNavCollapsed, setIsHamburgerNavCollapsed] = useState(false);
    const data = useDataContext()
    const { findPathnameData } = useDataContext()
    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get("type");
    const { pathname } = useLocation();
    const dataForPathname = findPathnameData(pathname)



    const [sidebarIsDisplayed, setSidebarIsdisplayed] = useState(true)

    const [floatingButtonIsDisplayed, setFloatingButtonIsDisplayed] = useState(true)

    const latestURL = window.location.href
    const urlArray = latestURL.split(/[/]/)

    useEffect(() => {
        let containsUrl = urlArray.filter(item => uiInterfaceUrlHides.includes(item))
        if (containsUrl.length > 0) {
            setSidebarIsdisplayed(false)
            setIsHamburgerNavCollapsed(true)
        } else {
            setSidebarIsdisplayed(true)
            setIsHamburgerNavCollapsed(false)
        }

    }, [latestURL])

    useEffect(() => {
        let containsUrl = urlArray.filter(item => floatingButtonUrlHides.includes(item))
        if (containsUrl.length > 0) {
            setFloatingButtonIsDisplayed(false)

        } else {
            setFloatingButtonIsDisplayed(true)
        }

    }, [latestURL])


    const pathStructure = pathname.split("/").filter((routePart) => routePart !== "")

    if (pathStructure.length == 1) {


    }

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = headerContent["mobile-breakpoint"]

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    return (

        <>
            <Header setIsHamburgerNavCollapsed={setIsHamburgerNavCollapsed} />
            <div className="main-content-container">
                <div className={isHamburgerNavCollapsed ? "sidebar-thin" : "sidebar-wide"}>

                    {sidebarIsDisplayed & width > breakpoint ? <SideNav isHamburgerNavCollapsed={isHamburgerNavCollapsed}
                        dropDownMenuTitles={data.dropDownMenuTitles}
                        setIsHamburgerNavCollapsed={setIsHamburgerNavCollapsed}
                    /> : <></>}

                </div>

                {typeParam === "dashboard" || !["/", "/app/project-summary"].includes(pathname) && (<GraphBar
                    graphData={dataForPathname}
                >
                    <GraphBoxNew>
                        <PieChartList pieList={dataForPathname ? dataForPathname.pieData : []} />
                    </GraphBoxNew>
                </GraphBar>)}

                <div className='right-content-container'>

                    {props.children}

                    {floatingButtonIsDisplayed ? <FloatingButton pathname={pathname} latestURL={latestURL} /> : <></>}
                </div>
            </div>

        </>
    );
}

export default Dashboard;
