import React from 'react'

const FBIconSchematic = ({ fill, onClick }) => {
    return (
        <div className='float-icon-container' onClick={onClick}>
            <svg id="new_icons" data-name="new icons" xmlns="http://www.w3.org/2000/svg" width="22mm" height="22mm" viewBox="0 0 62.3622 62.3622">
                <path d="m60.6054,18.5207h-10.6392c-.5469-1.1846-1.7373-2.0107-3.1279-2.0107s-2.5806.8262-3.1279,2.0107h-1.7627c-3.7002,0-7.0156,1.6538-9.2666,4.2534v-11.8071c1.1426-.5625,1.936-1.7271,1.936-3.0869s-.7935-2.5249-1.936-3.0874V.7683h-3v4.0068c-1.1606.5562-1.9683,1.7319-1.9683,3.105s.8076,2.5483,1.9683,3.1045v26.3604c-2.251-2.5996-5.5664-4.2539-9.2666-4.2539h-2.3442c-.6108-.9712-1.6841-1.6221-2.9165-1.6221s-2.3062.6509-2.917,1.6221H2.4862v3h9.4327c.481,1.3281,1.7412,2.2822,3.2349,2.2822s2.7534-.9541,3.2344-2.2822h2.0264c5.1094,0,9.2666,4.1572,9.2666,9.2666v4.6641c-1.1606.5562-1.9683,1.7319-1.9683,3.1045s.8076,2.5488,1.9683,3.105v5.3628h3v-5.3804c1.1426-.5625,1.936-1.7275,1.936-3.0874s-.7935-2.5244-1.936-3.0869v-19.252c0-5.1094,4.1572-9.2666,9.2666-9.2666h1.8267c.5708,1.1191,1.7217,1.8936,3.064,1.8936s2.4932-.7744,3.0645-1.8936h10.7027v-3Z" fill={fill} fillRule="evenodd" />
            </svg>
        </div>
    )
}

export default FBIconSchematic