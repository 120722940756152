import { featurePullFactory } from "./featurePullFactory.js"

import { exportNameAliases } from "../appSetup/exportNameAliases.js";

export const geoJsonFromFeatureFactory = (featuresFromCollections, userID) => {
    const dataSetNames = Object.keys(featuresFromCollections)

    let geoJsonData = {
    }
    dataSetNames.forEach(dataName => {
        if (dataName != "loading") {

            exportNameAliases[dataName]["exportName"].forEach((dataSetAlias) => {
                switch (exportNameAliases[dataName]["type"]) {
                    case "FeatureCollection":
                        geoJsonData[dataSetAlias] = {
                            type: "FeatureCollection",
                            name: exportNameAliases[dataName]["featuresName"],
                            features: featurePullFactory(Object.keys(featuresFromCollections[dataName]).length > 0 ? featuresFromCollections[dataName] : [])
                        }
                        break;
                    case "GenericDataSingular":
                        geoJsonData[dataSetAlias] = featuresFromCollections[dataName][0]
                        break;

                    case "basicData":
                        geoJsonData[dataSetAlias] = featuresFromCollections[dataName]
                        break;
                    case "GenericData":

                        break;
                    default:
                        break;
                }

            })
        }
    });
    return geoJsonData;
}