import React, { useState, Children } from 'react'

import '../../CssGeneral/dashboard.css'

const DbCard = ({ cardType, icon, title, cardContent, children, btnText, btnDisplayBool, btnMainDisplayBool, OnClickFuncMainBtn, OnClickFunc, mainBtnText, warningDisplayBool, warningContent, topRowDisplayBool, loadingData }) => {


    const btnDisplay = btnDisplayBool
    const btnMainDisplay = btnMainDisplayBool
    const warningDisplay = warningDisplayBool
    const topRowIconDisplay = topRowDisplayBool

    return (
        <div className={`grid-item  ${cardType}`}>
            <div className='card-top-row'>
                <div className=' card-title-container'>
                    <div className='card-icon-container'>
                        {topRowIconDisplay ? <div className='card-icon'>{icon}
                        </div> : <></>}

                    </div>

                    <h3 className='card-title'>{title}</h3>
                </div>
                <div className='right-btn-container'>
                    {warningDisplay ? <div className=" summary-text-fs09 warning-text milestone-warning">{warningContent}</div> : <></>}
                    {btnMainDisplay ? <button className="card-top-btn-main tablet-display-off" onClick={OnClickFuncMainBtn} disabled={loadingData} style={{ backgroundColor: loadingData ? "grey" : "#17bebb" }}><h4 className="card-top-btn-text-main" >{mainBtnText}</h4></button> : <></>}
                    {btnDisplay ? <button className="card-top-btn tablet-display-off" onClick={OnClickFunc} disabled={loadingData} style={{ backgroundColor: loadingData ? "#ffffff70" : "#fff" }}><h4 className="card-top-btn-text" >{btnText} </h4></button> : <></>}
                </div>




            </div>
            <div className={`card-content ${cardContent}`}>
                {children}
            </div>
        </div>
    )
}

export default DbCard