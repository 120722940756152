
import Area1 from '../../../data/AccessTemplates/AccessTemplate1.geojson'
import Area2 from '../../../data/AccessTemplates/AccessTemplate2.geojson'
import Area3 from '../../../data/AccessTemplates/AccessTemplate3.geojson'
import Area4 from '../../../data/AccessTemplates/AccessTemplate4.geojson'
import Area5 from '../../../data/AccessTemplates/AccessTemplate5.geojson'
import Area6 from '../../../data/AccessTemplates/AccessTemplate6.geojson'
import Area7 from '../../../data/AccessTemplates/AccessTemplate7.geojson'
import Area8 from '../../../data/AccessTemplates/AccessTemplate8.geojson'
import Area9 from '../../../data/AccessTemplates/AccessTemplate9.geojson'
import Area10 from '../../../data/AccessTemplates/AccessTemplate10.geojson'
import Area11 from '../../../data/AccessTemplates/AccessTemplate11.geojson'
import Area12 from '../../../data/AccessTemplates/AccessTemplate12.geojson'
import Area13 from '../../../data/AccessTemplates/AccessTemplate13.geojson'
import Area14 from '../../../data/AccessTemplates/AccessTemplate14.geojson'

const mapTemplateSchemaFactory = (template, long, lat, zoom) => ({
    template,
    long,
    lat,
    zoom
})



export const mapTemplates = {
    "1": mapTemplateSchemaFactory(Area1, -0.7954, 51.4023, 12.33),
    "2": mapTemplateSchemaFactory(Area2, -0.8205, 51.4157, 11.16),
    "3": mapTemplateSchemaFactory(Area3, -0.8295, 51.3880, 10.57),
    "4": mapTemplateSchemaFactory(Area4, -0.8440, 51.3921, 10.57),
    "5": mapTemplateSchemaFactory(Area5, -0.8424, 51.3716, 10.57),
    "6": mapTemplateSchemaFactory(Area6, -0.8479, 51.3787, 10.29),
    "7": mapTemplateSchemaFactory(Area7, -0.8419, 51.4075, 10.42),
    "8": mapTemplateSchemaFactory(Area8, -0.8147, 51.3507, 10.87),
    "9": mapTemplateSchemaFactory(Area9, -0.8009, 51.3608, 11.04),
    "10": mapTemplateSchemaFactory(Area10, -0.8603, 51.4066, 11.18),
    "11": mapTemplateSchemaFactory(Area11, -0.8790, 51.4054, 11.02),
    "12": mapTemplateSchemaFactory(Area12, -0.8023, 51.3564, 11.19),
    "13": mapTemplateSchemaFactory(Area13, -0.8069, 51.3599, 11.19),
    "14": mapTemplateSchemaFactory(Area14, -0.8424, 51.3716, 10.57),
    "15": mapTemplateSchemaFactory(Area14, -0.8424, 51.3716, 10.57),

}

export const accessAreas = {
    "1": null
}
