// DISPLAY TOGGLE VARIABLES FOR THE SCHEMATIC GROUPS

// TO TURN ON AND OFF ANY SCHEMATIC CLASSNAME
export const cssDisplayHide = "hide-schematic-group";
export const cssDisplayShow = "";

// TO TURN ON AND OFF THE MAIN DISCIPLINE GROUPS
export const allSchematicGroupDisplay = {
    civilsGroupDisplayToggle: cssDisplayShow,
    signallingGroupDisplayToggle: cssDisplayShow,
    cableGroupDisplayToggle: cssDisplayShow,
    templateGroupDisplayToggle: cssDisplayHide
}

export const civilsSchematicGroupDisplay = {
    civilsGroupDisplayToggle: cssDisplayShow,
    civilsTableDisplayToggle: cssDisplayShow,
    signallingGroupDisplayToggle: cssDisplayHide,
    cableGroupDisplayToggle: cssDisplayHide,
    templateGroupDisplayToggle: cssDisplayHide
}

export const signallingSchematicGroupDisplay = {
    civilsGroupDisplayToggle: cssDisplayShow,
    signallingGroupDisplayToggle: cssDisplayShow,
    cableGroupDisplayToggle: cssDisplayHide,
    templateGroupDisplayToggle: cssDisplayHide,
    civilsTableDisplayToggle: cssDisplayHide,
}

export const cablingSchematicGroupDisplay = {
    civilsGroupDisplayToggle: cssDisplayHide,
    signallingGroupDisplayToggle: cssDisplayHide,
    cableGroupDisplayToggle: cssDisplayShow,
    templateGroupDisplayToggle: cssDisplayHide,
    civilsTableDisplayToggle: cssDisplayHide,
}

// TO TURN ON AND OFF THE SUB MAIN GROUPS

// ALL SUB MENU DEFAULT
export const allSubGroupsSchematicDisplay = {
    signalCivilsDisplayToggle: cssDisplayShow,
    equipmentBasesDisplayToggle: cssDisplayShow,
    utxDisplayToggle: cssDisplayShow,
    signalAssetsDisplayToggle: cssDisplayShow,
    locRebPSPDisplayToggle: cssDisplayShow,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide

}

// CIVILS WORKS
export const allCivilsSchematicDisplay = {
    signalCivilsDisplayToggle: cssDisplayShow,
    equipmentBasesDisplayToggle: cssDisplayShow,
    utxDisplayToggle: cssDisplayShow,
    signalAssetsDisplayToggle: cssDisplayHide,
    locRebPSPDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide,
    pointsDisplayToggle: cssDisplayHide
}

export const signalCivilsSchematicDisplay = {
    signalCivilsDisplayToggle: cssDisplayShow,
    equipmentBasesDisplayToggle: cssDisplayHide,
    utxDisplayToggle: cssDisplayHide,
    signalAssetsDisplayToggle: cssDisplayHide,
    locRebPSPDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayShow,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide
}

export const civilsBasesSchematicDisplay = {
    equipmentBasesDisplayToggle: cssDisplayShow,
    equipmentBasesTableDisplayToggle: cssDisplayShow,
    signalCivilsDisplayToggle: cssDisplayHide,
    utxDisplayToggle: cssDisplayHide,
    signalAssetsDisplayToggle: cssDisplayHide,
    locRebPSPDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide
}
export const civilsUtxSchematicDisplay = {
    utxDisplayToggle: cssDisplayShow,
    signalCivilsDisplayToggle: cssDisplayHide,
    equipmentBasesDisplayToggle: cssDisplayHide,
    signalAssetsDisplayToggle: cssDisplayHide,
    locRebPSPDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide
}
// SIGNALLING WORKS

export const allSignallSchematicDisplay = {
    signalCivilsDisplayToggle: cssDisplayShow,
    equipmentBasesDisplayToggle: cssDisplayShow,
    signalAssetsDisplayToggle: cssDisplayShow,
    locRebPSPDisplayToggle: cssDisplayShow,
    utxDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide,
    pointsDisplayToggle: cssDisplayShow

}

export const signallAssetSchematicDisplay = {
    signalAssetsDisplayToggle: cssDisplayShow,
    signalCivilsDisplayToggle: cssDisplayShow,
    equipmentBasesDisplayToggle: cssDisplayHide,
    locRebPSPDisplayToggle: cssDisplayHide,
    utxDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide,
    pointsDisplayToggle: cssDisplayHide
}


export const pspREBCabinetSchematicDisplay = {
    locRebPSPDisplayToggle: cssDisplayShow,
    equipmentBasesDisplayToggle: cssDisplayShow,
    signallingEquipmentTableDisplayToggle: cssDisplayShow,
    signalAssetsDisplayToggle: cssDisplayHide,
    signalCivilsDisplayToggle: cssDisplayHide,
    utxDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    pointsDisplayToggle: cssDisplayHide
}

export const pointsSchematicDisplay = {
    pointsDisplayToggle: cssDisplayShow,
    locRebPSPDisplayToggle: cssDisplayHide,
    equipmentBasesDisplayToggle: cssDisplayHide,
    signallingEquipmentTableDisplayToggle: cssDisplayHide,
    signalAssetsDisplayToggle: cssDisplayHide,
    signalCivilsDisplayToggle: cssDisplayHide,
    utxDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
}


export const powerSchematicDisplay = {
    civilsGroupDisplayToggle: cssDisplayShow,
    signallingGroupDisplayToggle: cssDisplayShow,
    locRebPSPDisplayToggle: cssDisplayShow,
    equipmentBasesDisplayToggle: cssDisplayShow,
    signallingEquipmentTableDisplayToggle: cssDisplayHide,
    signalAssetsDisplayToggle: cssDisplayHide,
    signalCivilsDisplayToggle: cssDisplayHide,
    utxDisplayToggle: cssDisplayHide,
    signalCivilsTableDisplayToggle: cssDisplayHide,
    equipmentBasesTableDisplayToggle: cssDisplayHide,
    pointsDisplayToggle: cssDisplayHide,
    signallingTableDisplayToggle: cssDisplayHide,

}

