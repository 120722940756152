import React from 'react'

const PspCabIcon = () => {
    return (
        <g>
            <rect x="2.0777" y="21.8472" width="38.8446" height="9.8094" fill="#808e93" opacity=".6" />
            <g>
                <path d="m26.2326,14.4613c-.2881,0-.4834.0278-.5859.0557v1.8525c.1211.0278.2695.0371.4746.0371.7539,0,1.2197-.3818,1.2197-1.0239,0-.5771-.4004-.9214-1.1084-.9214Z" fill="#fff" />
                <path d="m4.8742,11.5502v9.7065h33.251v-9.7065H4.8742Zm12.8623,5.2847c-.4844.4561-1.2012.6606-2.0381.6606-.1865,0-.3545-.0093-.4844-.0278v2.2432h-1.4053v-6.1899c.4375-.0742,1.0518-.1299,1.917-.1299.875,0,1.499.1675,1.918.5024.4004.3164.6699.8379.6699,1.4521s-.2051,1.1353-.5771,1.4893Zm3.0146,2.9692c-.7158,0-1.4238-.1865-1.7773-.3818l.2881-1.1729c.3818.1953.9688.3911,1.5732.3911.6514,0,.9961-.27.9961-.6797,0-.3906-.2979-.6143-1.0518-.8843-1.042-.3628-1.7217-.9399-1.7217-1.8521,0-1.0703.8936-1.8896,2.373-1.8896.708,0,1.2285.1489,1.6016.3164l-.3164,1.145c-.252-.1211-.6982-.2979-1.3125-.2979s-.9121.2793-.9121.605c0,.4004.3535.5771,1.1631.8843,1.1074.4097,1.6289.9868,1.6289,1.8711,0,1.0518-.8096,1.9453-2.5322,1.9453Zm7.418-2.9692c-.4844.4561-1.2012.6606-2.0381.6606-.1865,0-.3545-.0093-.4844-.0278v2.2432h-1.4053v-6.1899c.4375-.0742,1.0518-.1299,1.917-.1299.875,0,1.499.1675,1.918.5024.4004.3164.6699.8379.6699,1.4521s-.2051,1.1353-.5771,1.4893Z" fill="#fff" />
                <path d="m15.8,14.4613c-.2881,0-.4834.0278-.5859.0557v1.8525c.1211.0278.2695.0371.4746.0371.7539,0,1.2197-.3818,1.2197-1.0239,0-.5771-.4004-.9214-1.1084-.9214Z" fill="#fff" />
            </g>
        </g>

    )
}

export default PspCabIcon
