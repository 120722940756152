import CivilsIcon from '../../assets/HeaderTitleIcons/TitleIcons_civils-general-icon.svg'

export const graphBoxData = [

    {

        title: "CIVILS SUMMARY",
        graphHeaderIcon: CivilsIcon,
        id: 'hide-me',
        data: []
    }

]
