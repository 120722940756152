import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import DisplaySwitcher from './constants/displayControl/DisplaySwitcher';
import { defaultRouteRedirects } from './constants/dataContextComponents/routeSettings'
import { routesStructure } from './constants/appSetup/routesStructure';

import SummaryDashboard from './pageStyles/SummaryDashboard';
import SignalForm from './pageStyles/Forms/SignalForm';
import UtxForm from './pageStyles/Forms/UtxForm';
import EquipmentForm from './pageStyles/Forms/EquipmentForm';
import DeleteCheck from './pages/deleteChecks/DeleteCheck'
import ProjectInfoForm from './pageStyles/Forms/ProjectInfoForm';
import CableForm from './pageStyles/Forms/CableForm';
import AssetPage from './pages/assetPage/AssetPage';
import NoMobileView from './pages/NoMobileView';
import NoPageAvailable from './pages/NoPageAvailable';

import useCheckUserRights from './hooks/useCheckUserRights';

function Routestructure() {

  const { buttonStatus } = useCheckUserRights()

  return (

    <>
      <Routes>
        <Route path="*" element={<Navigate to={routesStructure[Object.keys(routesStructure)[1]].substring(5)}></Navigate>} />

        <Route path="projectinfoform/:id" element={buttonStatus ? <ProjectInfoForm /> : <NoPageAvailable />} />

        <Route path="signals/asset/:id" element={<AssetPage />} />
        <Route path="equipmentcubicles/asset/:id" element={<AssetPage />} />
        <Route path="utxproposedcentres/asset/:id" element={<AssetPage />} />
        <Route path="powercables/asset/:id" element={<AssetPage />} />
        <Route path="fibrecables/asset/:id" element={<AssetPage />} />

        <Route path="signalsform/civils" element={buttonStatus ? <SignalForm /> : <NoPageAvailable />} />
        <Route path="signalsform/civils/:id" element={buttonStatus ? <SignalForm /> : <NoPageAvailable />} />
        <Route path="signalsform/signalling" element={buttonStatus ? <SignalForm /> : <NoPageAvailable />} />
        <Route path="signalsform/signalling/:id" element={buttonStatus ? <SignalForm /> : <NoPageAvailable />} />
        <Route path="signalsdelete/civils/:id" element={buttonStatus ? <DeleteCheck /> : <NoPageAvailable />} />
        <Route path="signalsdelete/signalling/:id" element={buttonStatus ? <DeleteCheck /> : <NoPageAvailable />} />

        <Route path="equipmentcubiclesform/civils" element={buttonStatus ? <EquipmentForm /> : <NoPageAvailable />} />
        <Route path="equipmentcubiclesform/civils/:id" element={buttonStatus ? <EquipmentForm /> : <NoPageAvailable />} />
        <Route path="equipmentcubiclesform/signalling" element={buttonStatus ? <EquipmentForm /> : <NoPageAvailable />} />
        <Route path="equipmentcubiclesform/signalling/:id" element={buttonStatus ? <EquipmentForm /> : <NoPageAvailable />} />
        <Route path="equipmentcubiclesdelete/civils/:id" element={buttonStatus ? <DeleteCheck /> : <NoPageAvailable />} />
        <Route path="equipmentcubiclesdelete/signalling/:id" element={buttonStatus ? <DeleteCheck /> : <NoPageAvailable />} />

        <Route path="utxproposedcentresform/civils" element={buttonStatus ? <UtxForm /> : <NoPageAvailable />} />
        <Route path="utxproposedcentresform/civils/:id" element={buttonStatus ? <UtxForm /> : <NoPageAvailable />} />
        <Route path="utxproposedcentresdelete/civils/:id" element={buttonStatus ? <DeleteCheck /> : <NoPageAvailable />} />

        <Route path="powercablesform/cabling" element={buttonStatus ? <CableForm /> : <NoPageAvailable />} />
        <Route path="powercablesform/cabling/:id" element={buttonStatus ? <CableForm /> : <NoPageAvailable />} />
        <Route path="powercablesdelete/cabling/:id" element={buttonStatus ? <DeleteCheck /> : <NoPageAvailable />} />

        <Route path="fibrecablesform/cabling" element={buttonStatus ? <CableForm /> : <NoPageAvailable />} />
        <Route path="fibrecablesform/cabling/:id" element={buttonStatus ? <CableForm /> : <NoPageAvailable />} />
        <Route path="fibrecablesdelete/cabling/:id" element={buttonStatus ? <DeleteCheck /> : <NoPageAvailable />} />

        <Route path='nomobiledisplay' element={<NoMobileView
        />} />

        <Route path={routesStructure[Object.keys(routesStructure)[1]].substring(5)} element={<SummaryDashboard currentPath={useLocation().pathname}
        />} />

        {Object.keys(defaultRouteRedirects).map((beginningRoute, index) => {
          return <Route path={beginningRoute.substring(5)} key={index + "beg-route"} element={<Navigate replace to={defaultRouteRedirects[beginningRoute]} />} />
        })}
        {Object.keys(routesStructure).slice(1).map((value, index) => {

          return (<Route path={routesStructure[value].substring(5)} key={index + "add-route"} element={<DisplaySwitcher currentPath={useLocation().pathname}

          />} />)

        })

        }

      </Routes>

    </>

  );
}

export default Routestructure;
