export const collectionList = ["Signals",
    "EquipmentCubicles",
    "Structures",
    "UTXProposedCentres",
    "PowerCables",
    "FibreCables",
    "ProjectDetails",
    "UserMetaData"]


export const blank = []