import React, { useState, useEffect } from 'react';

import { useDataContext } from '../Contexts/DataContext';

import { headerContent } from "../constants/appSetup/headerIcons";

import { uiInterfaceUrlHides } from '../constants/displayControl/uiInterfaceUrlHides';

import './componentStyle.css'

export default function Header({ setIsHamburgerNavCollapsed }) {
  const data = useDataContext()

  const [headerIsDisplayed, setHeaderIsdisplayed] = useState(true)

  const latestURL = window.location.href
  const urlArray = latestURL.split(/[/]/)


  useEffect(() => {
    let containsUrl = urlArray.filter(item => uiInterfaceUrlHides.includes(item))
    if (containsUrl.length > 0) {
      setHeaderIsdisplayed(false)

    } else {
      setHeaderIsdisplayed(true)

    }

  }, [latestURL])


  const hamburgerButtonHandler = () => {
    setIsHamburgerNavCollapsed((prev) => !prev);
  };
  return (
    <div className="header">
      <div className="header-left">
        {headerIsDisplayed ? <img
          onClick={hamburgerButtonHandler}
          src={headerContent.HamburgerButton}
          alt=""
          className="header-left-hamburger"
        /> : <></>}
        <div className="header-left-logo">
          <img src={headerContent.LeftLogoRight} alt="leftlogo" />
          <img src={headerContent.LeftLogo} alt="leftlogo" />

        </div>
      </div>
      <div className="header-title-main">
        {headerContent.title}
      </div>

      <div className="header-right">
        <img src={headerContent.RightLogo} alt="rightlogo" />
      </div>
    </div>
  );
}
