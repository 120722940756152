import React from 'react'

const FBIconMapDark = ({ fill, onClick }) => {
    return (
        <div className='float-icon-container' onClick={onClick}>
            <svg id="new_icons" data-name="new icons" xmlns="http://www.w3.org/2000/svg" width="22mm" height="22mm" viewBox="0 0 62.3622 62.3622">
                <path d="m19.4425,23.6265c0-6.3428,5.0342-11.4971,11.3232-11.7178V.7729C17.9806.9951,7.6832,11.4219,7.6832,24.2607c0,12.5132,18.8423,35.603,23.0825,37.2388v-26.1553c-6.2891-.2207-11.3232-5.375-11.3232-11.7178Z" fill={fill} />
                <path d="m54.6788,24.2607C54.6788,11.2827,44.1583.7622,31.1813.7622c-.1396,0-.2769.0083-.4155.0107v11.1357c.1392-.0049.2749-.021.4155-.021,6.4829,0,11.7383,5.2559,11.7383,11.7388s-5.2554,11.7388-11.7383,11.7388c-.1406,0-.2764-.0161-.4155-.021v26.1553c.1572.061.2998.1001.4155.1001,3.2261,0,23.4976-24.3618,23.4976-37.3389Z" fill={fill} opacity=".39" />
            </svg>
        </div>
    )
}

export default FBIconMapDark