
// IMAGES
import UTXImageCentreOutstanding from '../../assets/MapSymbols/Icons/UTX-Dot-Outstanding.png'

import URXImageCentreOutstanding from '../../assets/MapSymbols/Icons/URX-Dot-Outstanding.png'

import OTXImageCentreOutstanding from '../../assets/MapSymbols/Icons/OTX-Dot-Outstanding.png'

import UTXImageCentreComplete from '../../assets/MapSymbols/Icons/UTX-Dot-Complete.png'

import URXImageCentreComplete from '../../assets/MapSymbols/Icons/URX-Dot-Complete.png'

import OTXImageCentreComplete from '../../assets/MapSymbols/Icons/OTX-Dot-Complete.png'

import UTXImageCentreExisting from '../../assets/MapSymbols/Icons/UTX-Dot-Existing.png'

import URXImageCentreExisting from '../../assets/MapSymbols/Icons/URX-Dot-Existing.png'

import OTXImageCentreExisting from '../../assets/MapSymbols/Icons/OTX-Dot-Existing.png'

export const utxMapSymbols = {
    UTXImageCentreOutstanding,
    URXImageCentreOutstanding,
    OTXImageCentreOutstanding,

    UTXImageCentreComplete,
    URXImageCentreComplete,
    OTXImageCentreComplete,

    UTXImageCentreExisting,
    URXImageCentreExisting,
    OTXImageCentreExisting

}