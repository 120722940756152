import React from 'react'

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const svgVariants1 = {
    hidden: { rotate: 18000 },
    visible: {
        rotate: 0,

        transition: {
            delay: 2,
            duration: 45,
            repeat: Infinity,
            type: "tween",
        },
    },
};

const divVariant = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,

        transition: { delay: 2 },
    },
};

const Loading = () => {

    return (
        <div className='centre-content-loading'>
            <motion.div
                variants={divVariant}
                initial="hidden"
                animate="visible"
                className="Loader"
            >
                <motion.svg
                    width="266"
                    height="266"
                    viewBox="0 0 261 266"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    variants={svgVariants1}
                    initial="hidden"
                    animate="visible"
                >
                    <motion.path
                        d="M237.768 132.822C237.768 192.234 189.605 240.398 130.192 240.398C70.7796 240.398 22.6156 192.234 22.6156 132.822C22.6156 73.4101 70.7796 25.2461 130.192 25.2461C189.605 25.2461 237.768 73.4101 237.768 132.822Z"
                        stroke="#E6E6E6"
                        strokeWidth="16"
                        strokeWiterlimit="10"
                        strokeLinecap="round"
                    />
                    <motion.path
                        d="M130.192 25.2463C156.982 25.2463 181.486 35.0396 200.318 51.2423"
                        stroke="#FF4C29"
                        strokeWidth="16"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    // variants={pathVariants1}
                    />
                </motion.svg>
            </motion.div>
        </div>
    )
}

export default Loading