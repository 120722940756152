import React from 'react'

const LocCabIcon = () => {
    return (
        <g>
            <path d="m4.8742,11.5502v9.7065h33.251v-9.7065H4.8742Zm12.8525,8.1606h-3.918v-6.2734h1.4238v5.082h2.4941v1.1914Zm3.1455.1025c-1.8613,0-2.9502-1.4058-2.9502-3.1929,0-1.8799,1.2002-3.2856,3.0527-3.2856,1.9268,0,2.9785,1.4429,2.9785,3.1743,0,2.0566-1.2471,3.3042-3.0811,3.3042Zm7.2227-1.1821c.4277,0,.9023-.0933,1.1816-.2051l.2139,1.1079c-.2607.1304-.8467.27-1.6104.27-2.168,0-3.2852-1.3496-3.2852-3.1367,0-2.1411,1.5264-3.3325,3.4248-3.3325.7354,0,1.2939.1489,1.5459.2793l-.2891,1.1265c-.2881-.1211-.6885-.2329-1.1914-.2329-1.126,0-2.001.6797-2.001,2.0757,0,1.2563.7441,2.0479,2.0107,2.0479Z" fill="#fff" />
            <path d="m20.9376,14.4613c-.959,0-1.5176.9121-1.5176,2.1313,0,1.2285.5771,2.0942,1.5264,2.0942.959,0,1.5088-.9121,1.5088-2.1313,0-1.1265-.54-2.0942-1.5176-2.0942Z" fill="#fff" />
        </g>
    )
}

export default LocCabIcon