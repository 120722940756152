export const JSONDataFilterByDualProperty = (sourceData, keyValue, dataStructureName, property, keyValue2, property2) => {
    const filteredData = sourceData.features.filter(sourceItem => {
        if (sourceItem.properties[property2] === keyValue2[property2]) {
            return sourceItem.properties[property] === keyValue[property]
        }
    })
    const { tableData, sideBarRightData } = sourceData;
    return (
        {
            "type": "FeatureCollection",
            "name": dataStructureName,
            "features": filteredData,
            "tableData": tableData ? tableData : [],
            "sideBarRightData": sideBarRightData ? sideBarRightData : [],
        })
}


export const JSONDataFilterByProperty = (data, keyValuePair, dataStructureName, property) => {
    const filteredData = data.features.filter(item => {
        return item.properties[property] === keyValuePair[property]
    })
    const { tableData, sideBarRightData } = data;
    return (
        {
            "type": "FeatureCollection",
            "name": dataStructureName,
            "features": filteredData,
            "tableData": tableData ? tableData : [],
            "sideBarRightData": sideBarRightData ? sideBarRightData : [],
        })
}

