


// ########################## PIE CHART ICONS  ####################

// ########################### CIVILS ICONS ##############################################

// SIGNAL CIVILS
import SignalStructureIcon from './SignalStructureIcon';
import SignalPostIcon from './SignalPostIcon';
import SignalFoundationIcon from './SignalFoundationIcon';

// EQUIPMENT AND BASES
import AspBaseIcon from './AspBaseIcon'
import PspBaseIcon from './PspBaseIcon'
import RebBaseIcon from './RebBaseIcon'
import LocBaseIcon from './LocBaseIcon'
import DnoBaseIcon from './DnoBaseIcon'

// UTX and OTX
import OtxIcon from './OtxIcon'
import UtxIcon from './UtxIcon'
import UrxIcon from './UrxIcon';


// ########################### SIGNALLING WORKS ICONS ##############################################

// SIGNALLING ASSETS
import SignalHeadIcon from './SignalHeadIcon'
import SignalGroundIcon from './SignalGroundIcon'
import SignalBannerIcon from './SignalBannerIcon'
import SignalOffIndicatorIcon from './SignalOffIndicatorIcon'

import SignalModIcon from './SignalModIcon'

import SignalAwsIcon from './SignalAwsIcon'
import SignalTpwsIcon from './SignalTpwsIcon'
import SignalAxleCounterIcon from './SignalAxleCounterIcon'
import SignalPointsIcon from './SignalPointsIcon';

// LOCS-PSP-REB
import AspCabIcon from './AspCabIcon'
import PspCabIcon from './PspCabIcon'
import RebCabIcon from './RebCabIcon'
import LocCabIcon from './LocCabIcon'
import DnoCabIcon from './DnoCabIcon'


// ########################### CABLING ICONS ##############################################
import CablePowerIcon from './CablePowerIcon'
import CableWtsIcon from './CableWtsIcon'
import CableSmbIcon from './CableSmbIcon'

// GENERAL
import CableGeneralIcon from './CableGeneralIcon'
import CivilsGeneralIcon from './CivilsGeneralIcon'
import SignallingGeneralIcon from './SignallingGeneralIcon'
import OverviewGeneralIcon from './OverviewGeneralIcon'
import TestingGeneralIcon from './TestingGeneralIcon'





import StatusIcon from '../../assets/HeaderTitleIcons/TitleIcons_signal-general-icon.svg'


const Icon = {
    CableGeneralIcon,
    OverviewGeneralIcon,
    CivilsGeneralIcon,
    SignallingGeneralIcon,
    TestingGeneralIcon,


    StatusIcon: StatusIcon,

    SignalStructureIcon,
    SignalPostIcon,
    SignalFoundationIcon,

    AspBaseIcon,
    PspBaseIcon,
    RebBaseIcon,
    LocBaseIcon,
    DnoBaseIcon,

    OtxIcon,
    UtxIcon,
    UrxIcon,

    SignalHeadIcon,
    SignalGroundIcon,
    SignalBannerIcon,
    SignalOffIndicatorIcon,
    SignalModIcon,
    SignalAwsIcon,
    SignalTpwsIcon,
    SignalAxleCounterIcon,
    SignalPointsIcon,

    AspCabIcon,
    PspCabIcon,
    RebCabIcon,
    LocCabIcon,
    DnoCabIcon,

    CablePowerIcon,
    CableWtsIcon,
    CableSmbIcon



};

export default Icon;
