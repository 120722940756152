import React from 'react'

const SignalFoundationIcon = () => {
    return (
        <g>
            <polygon points="17.1222 20.5602 26.1898 13.6413 26.1898 6.4542 17.1222 13.3725 17.1222 20.5602" fill="#fff" />
            <polygon points="17.1222 28.8455 26.1898 21.9273 26.1898 14.7395 17.1222 21.6583 17.1222 28.8455" fill="#fff" />
            <polygon points="17.1222 29.9436 17.1222 32.4211 26.1898 32.4211 26.1898 23.0254 17.1222 29.9436" fill="#fff" />
            <polygon points="26.1898 5.3561 26.1898 2.7617 17.1222 2.7617 17.1222 12.2744 26.1898 5.3561" fill="#fff" />
            <polygon points="27.4212 13.4206 27.4212 33.6233 15.9555 33.6233 15.9555 13.4206 6.623 13.4206 6.623 42.1096 36.7537 42.1096 36.7537 13.4206 27.4212 13.4206" fill="#808e93" />
        </g>
    )
}

export default SignalFoundationIcon;
