import React, { useState } from 'react'

import '../../CssGeneral/dashboard.css'
import '../../pageStyles/TablePage/table.css'
import TableContent from '../../pageStyles/TablePage/TableContent'
import useCheckUserRights from '../../hooks/useCheckUserRights'

const DbCardTable = ({ cardType, title, cardContent, OnClickFuncMainBtn, searchParams, item, filterData, assetTypeIdentifier }) => {

    const { buttonStatus } = useCheckUserRights()

    const [search, setSearch] = useState("")
    const [searchType, setSearchType] = useState(searchParams[0].key)

    return (
        <div className={`grid-item ${cardType} table-card`}>
            <div className='card-top-row'>
                <div className=' card-title-container'>
                    <h3 className='card-title'>{title}</h3>
                </div>

                <div className='filter-option-container'>
                    <div className='filter-title summary-text-fs09'>Filter by:</div>
                    <select onChange={(e) => setSearchType(e.target.value)}>
                        {searchParams.map((param, index) => { return <option key={index} value={param.key}>{param.name}</option> })}
                    </select>
                    <input type="text" className='filter-select-box' placeholder={`Search by`} onChange={(e) => { setSearch(e.target.value) }} />

                </div>

                <div className='right-btn-container'>
                    {buttonStatus ? <button className="card-top-btn-main " onClick={OnClickFuncMainBtn}><h4 className="card-top-btn-text" >Add Asset</h4></button> : <></>}

                </div>

            </div>
            <div className={`card-content ${cardContent}`}>
                {filterData.length > 0 ? <TableContent subTitle={item.name}
                    searchParam={assetTypeIdentifier(item.name).searchParams}
                    filteredData={assetTypeIdentifier(item.name).data.filter((item) => {
                        if (search === "") {
                            return true
                        } else {
                            return item[searchType].toLowerCase().includes(search.toLowerCase())
                        }
                    })} /> : <div className="no-data-container summary-text-fs1p1 ">No data based on the selected filters!</div>}
            </div>
        </div>
    )
}

export default DbCardTable