export const exportNameAliases = {
    "signals": {
        alias: "Signals",
        exportName: ["SignallingData", "SignalCivilsData"],
        featuresName: "Signals",
        type: "FeatureCollection"
    },
    "structures": {
        alias: "Structures",
        exportName: ["StructuresData"],
        featuresName: "Structures",
        type: "FeatureCollection"
    },
    "equipmentCubicles": {
        alias: "EquipmentCubicles",
        exportName: ["EquipmentCubicleCivilsData", "EquipmentCubicleSignallingData"],
        featuresName: "ASP & PSP & LOC",
        type: "FeatureCollection"
    },
    "uTXProposedCentres": {
        alias: "UTXProposedCentres",
        exportName: ["UTXProposedCentresData"],
        featuresName: "URX and UTX",
        type: "FeatureCollection"
    },

    "powerCables": {
        alias: "PowerCables",
        exportName: ["PowerCablesData"],
        featuresName: "Power Cable",
        type: "FeatureCollection"
    },
    "fibreCables": {
        alias: "FibreCables",
        exportName: ["FibreCablesData"],
        featuresName: "Fibre Cable",
        type: "FeatureCollection"
    },
    "projectDetails": {
        alias: "ProjectDetails",
        exportName: ["ProjectDetails"],
        type: "GenericDataSingular"
    },

    "userMetaData": {
        alias: "UserMetaData",
        exportName: ["UserMetaData"],
        type: "basicData"
    }


}