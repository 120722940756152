import React from 'react'

const PspBaseIcon = () => {
    return (
        <g id="Icons">
            <rect x="4.6674" y="10.019" width="33.6652" height="11.4444" fill="#808e93" opacity=".6" />
            <text transform="translate(13.1945 18.7245)" fill="#fff" font-family="MyriadPro-Bold, &apos;Myriad Pro&apos;" font-size="9.3066" font-weight="700"><tspan x="0" y="0">PSP</tspan></text>
            <g>
                <circle cx="3.0573" cy="25.1483" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="5.2697" cy="23.7714" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m8.5497,22.7967c.1025,0,.1856-.0831.1856-.1856s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="5.847" cy="25.7309" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="9.0032" cy="25.6071" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m11.819,23.1472c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m2.9751,28.8059c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="2.0004" cy="31.3277" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="5.29" cy="29.1501" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="7.2239" cy="28.9335" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m8.3224,27.9898c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m7.1311,31.5636c0,.1025.0831.1857.1857.1857s.1857-.0831.1857-.1857-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="11.8344" cy="29.4441" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="11.5404" cy="26.7211" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m12.9638,25.9475c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="15.1144" cy="22.8068" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m16.8626,25.5762c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857s-.1856.0831-.1856.1857c0,.1025.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="18.8739" cy="27.3864" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.8105,29.1965c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857s.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.7347,31.4558c0,.1025.0831.1857.1857.1857s.1856-.0831.1856-.1857-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="13.2578" cy="31.6101" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m20.8518,23.2168c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1856.0831-.1856.1856.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m23.0898,27.7035c.1025,0,.1857-.0831.1857-.1857,0-.1025-.0831-.1856-.1857-.1856s-.1856.0831-.1856.1856c0,.1025.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m23.3891,24.3308c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m25.8979,25.406c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="26.5709" cy="23.7583" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.0847" cy="22.8469" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m28.5667,26.4968c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857s.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.6131" cy="28.6318" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="27.7777" cy="31.2774" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m25.4802,29.6994c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m22.0223,31.5095c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="21.1869" cy="29.6065" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="18.8197" cy="30.0706" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="30.1448" cy="25.6265" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="34.9951" cy="29.6297" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="33.0196" cy="27.0653" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="34.0175" cy="28.3881" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.569,27.1466c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m30.2144,28.9567c0,.1025.0831.1857.1857.1857s.1857-.0831.1857-.1857-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="30.1448" cy="31.3006" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m35.2503,26.1718c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="33.5563" cy="23.6423" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="32.5351" cy="23.2478" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m29.6111,27.8195c.1025,0,.1856-.0831.1856-.1856s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m39.8073,27.7586c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857s-.1857.0831-.1857.1857c0,.1025.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.2673,29.3977c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m37.7415,22.9196c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="33.6026" cy="31.4166" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
            </g>
            <path d="m42.3715,33.1352H.6283v-12.0923h41.7432v12.0923Zm-40.9932-.75h40.2432v-10.5923H1.3783v10.5923Z" fill="#fff" />
        </g>
    )
}


export default PspBaseIcon