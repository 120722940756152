import React from 'react'

const SignalBannerIcon = () => {
    return (
        <g id="Icons">
            <g>
                <rect x="19.9521" y="22.7534" width="3.2877" height="16.0479" fill="#808e93" />
                <path d="m12.215,4.1986v18.5699h18.5699V4.1986H12.215Zm9.285,2.6242c3.2807,0,6.0011,2.3741,6.5526,5.4966h-13.1052c.5514-3.1225,3.2718-5.4966,6.5526-5.4966Zm0,13.3214c-3.0649,0-5.6395-2.0729-6.4155-4.8911h12.831c-.776,2.8182-3.3506,4.8911-6.4155,4.8911Z" fill="#fff" />
            </g>
        </g>
    )
}

export default SignalBannerIcon;
