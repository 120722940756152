import React from 'react'

const SignalOffIndicatorIcon = () => {
    return (
        <g id="Icons">
            <g id="Icons">
                <g>
                    <polygon points="37.4811 4.3284 10.4592 4.3284 10.4592 6.8576 19.008 6.8576 19.008 12.0489 20.487 12.0489 20.487 6.8576 37.4811 6.8576 37.4811 38.6716 38.9452 38.6716 38.9452 6.8576 38.9452 4.3284 37.4811 4.3284" fill="#808e93" />
                    <g>
                        <path d="m12.8913,16.5351c-1.9961,0-2.9937,1.843-2.9937,3.8933,0,1.9954,1.0808,3.7826,2.9788,3.7826,1.9128,0,2.9934-1.7598,2.9934-3.8796,0-1.8563-.97-3.7963-2.9785-3.7963Z" fill="#fff" />
                        <path d="m4.0548,12.049v15.9736h30.3499v-15.9736H4.0548Zm8.7529,13.1458c-2.4661,0-4.1985-1.9121-4.1985-4.7384,0-2.965,1.843-4.905,4.3367-4.905,2.5496,0,4.1987,1.9538,4.1987,4.7247,0,3.2143-1.9539,4.9187-4.3369,4.9187Zm10.9341-8.4794h-3.8242v3.1036h3.5332v.9974h-3.5332v4.226h-1.2051v-9.3387h5.0293v1.0117Zm6.7493,0h-3.8245v3.1036h3.5334v.9974h-3.5334v4.226h-1.2051v-9.3387h5.0295v1.0117Z" fill="#fff" />
                    </g>
                </g>
            </g>
        </g>
    )
}

export default SignalOffIndicatorIcon;
