
import StationNames from '../../../../data/FixedLocationsGeoData/StationNames.json'

import StationPtrBlank from '../../../../assets/MapSymbols/Icons/StationMarkerWhite.png'

// import StationPtrBlank from '../../../../assets/MapSymbols/v3/MapIcons_blank.png'


export const StationPointers = (map) => {

    // Station- Pointer
    map.on('load', () => {
        map.addSource('stationPointers', {
            'type': 'geojson',
            'data': StationNames,
            buffer: 0,
        });
        map.loadImage(
            StationPtrBlank,
            (error, image) => {
                if (error) throw error;
                map.addImage('station-pointer', image);
                map.addLayer({
                    id: 'Stations',
                    type: "symbol",
                    source: 'stationPointers',
                    "minzoom": 9,
                    "maxzoom": 16,

                    // "maxzoom": 14.5,
                    layout: {
                        "icon-image": 'station-pointer',
                        "icon-size": 0.15,
                        "text-field": ['get', 'name'],
                        // 'icon-rotate': ['get', 'rotation'],
                        'text-offset': ['get', 'text-offset'],

                        'text-anchor': 'top',
                        "icon-allow-overlap": true,
                        "text-allow-overlap": true,
                        visibility: "visible",
                    }, paint: {
                        'text-color': '#fff',
                        // 'text-color': '#142127',
                        // "text-halo-color": "#fff",
                        // "text-halo-width": 2
                    }
                });

            });
    })


}

