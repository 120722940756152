import React, { useContext } from 'react'
import DbCard from '../../components/cards/DbCard'
import { DataContext } from '../../Contexts/DataContext'
import ProjectMap from '../../components/ProjectMap/ProjectMap'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
    assetIdentifier
} from '../../constants/appSetup/assetPageContents'

import './assetPage.css'
import useCheckUserRights from '../../hooks/useCheckUserRights'

const AssetPage = () => {

    const { buttonStatus } = useCheckUserRights()

    const data = useContext(DataContext)
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams();
    const selectedItemId = params.id;
    const selectedAssetType = location.pathname.split("/")[2]

    const selectedAssetList = assetIdentifier(selectedAssetType, data, selectedItemId).pageContentList
    const selectedData = assetIdentifier(selectedAssetType, data, selectedItemId).data
    const selectedTitle = assetIdentifier(selectedAssetType, data, selectedItemId).title


    function getUniqueValues(array, key) {
        const uniqueValues = [];
        const lookup = {};

        for (const item of array) {
            const value = item[key];
            if (!lookup[value]) {
                lookup[value] = true;
                uniqueValues.push(value);
            }
        }

        return uniqueValues;
    }

    const sectionTitles = getUniqueValues(selectedAssetList, "sectionTitle")


    const EditRedirect = () => {
        navigate(`${assetIdentifier(selectedAssetType, data, selectedItemId).editRedirect}${selectedItemId}`)
    }

    const ReturnRedirect = () => {
        navigate(`${assetIdentifier(selectedAssetType, data, selectedItemId).returnRedirect}`)
    }

    const DeleteRedirect = () => {
        navigate(`${assetIdentifier(selectedAssetType, data, selectedItemId).deleteRedirect}${selectedItemId}`)
    }

    return (
        <div className="assetpage-main-container">
            <DbCard
                cardType={'graph-card-wide card-static'}
                btnMainDisplayBool={buttonStatus}
                mainBtnText={'Edit'}
                OnClickFuncMainBtn={EditRedirect}
                btnText={'Go back'}
                btnDisplayBool={true}
                OnClickFunc={ReturnRedirect}
                title={selectedData.Name}

            >

                <div className='asset-page-content-container'>

                    <>
                        {sectionTitles.map((sectionTitleName, index) => {
                            return (
                                <div className='asset-page-details-container'>
                                    <div className='asset-details-sub-title'>{sectionTitleName} </div>

                                    {selectedAssetList.map((item, index) => {
                                        if (item.sectionTitle === sectionTitleName) return (
                                            <div className='asset-details-row summary-text-fs1'>
                                                <span className='asset-bold-title'>{item.displayName}:</span>
                                                {selectedData[item.keyValue]}
                                            </div>
                                        )
                                    })
                                    }
                                </div>)
                        })}



                    </>
                    <div className='asset-page-details-container'>
                        <div className='project-page-map-container'>
                            <ProjectMap
                                lonData={selectedData.coordinates[0]}
                                latData={selectedData.coordinates[1]}
                                currentItemData={selectedData}
                            />
                        </div>
                    </div>




                </div>
                {buttonStatus ? <div className='delete-section'>
                    <button className="card-top-btn-main " onClick={DeleteRedirect}><h4 className="card-top-btn-text" >Delete this location?</h4></button>
                </div> : <></>}


            </DbCard>

        </div>

    )
}

export default AssetPage