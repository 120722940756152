import React from 'react'

const BarButton = (props) => {
    return (
        <button className="button-sidebar" id={props.id} onClick={props.buttonFunction}>
            <img className={props.className} src={props.image} alt="button" />
        </button>
    )
}

export default BarButton