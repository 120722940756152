import React from 'react'

const CablePowerIcon = () => {
    return (
        <g>
            <circle cx="15.3919" cy="19.4961" r="1.9214" fill="#fff" />
            <circle cx="28.334" cy="24.5686" r="1.9214" fill="#fff" />
            <g opacity=".6">
                <polygon points="35.5444 32.6215 7.4556 32.6215 7.4556 16.4153 10.5068 16.4153 10.5068 29.5703 32.4932 29.5703 32.4932 16.4153 35.5444 16.4153 35.5444 32.6215" fill="#808e93" />
                <polygon points="39.8391 18.2712 36.7323 18.2712 36.7323 14.55 6.2677 14.55 6.2677 18.2712 3.1609 18.2712 3.1609 11.4432 39.8391 11.4432 39.8391 18.2712" fill="#808e93" />
            </g>
            <path d="m27.4968,16.6475l-9.2365,3.2961,3.6701,2.0196-3.5224,1.9323s-.0223-1.6972-.1692-1.5958-3.1906,3.481-2.8148,3.4601,4.1021.5725,4.1091.3942-.709-1.2768-.709-1.2768l7.3214-2.5468s-4.3401-2.2915-3.9497-2.1735,6.6708-1.1163,6.6708-1.1163l-1.3698-2.3931Z" fill="#fff" />
        </g>
    )
}

export default CablePowerIcon
