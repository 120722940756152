import React, { useState } from 'react';

import Login from '../assets/graphics/LoginGraphics_HomeImage06.svg'

import './pages.css'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {

    e.preventDefault();
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setError(e.message);
      if (e.code === "auth/wrong-password") {
        alert("Incorrect password");
      }
      else if (e.code === "auth/user-not-found") {
        alert("Incorrect username");
      }
      else if (e.code === "auth/internal-error" || e.code === "auth/invalid-email") {
        alert("You havent completed all the details correctly");
      }



      else {
        alert(e.message)
      }

    }
  };

  return (
    <div className='log-page-main-container'>

      <img className="log-graphic" src={Login} alt="" />
      <div>
        <div className='log-title'><span className='log-color'>Sign in</span> to the <br></br> F2P Project <br></br> Integrated Works App </div>

      </div>
      <form onSubmit={handleSubmit}>
        <div className='log-input-containter'>
          <label className='log-label'>Email Address</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className='log-input'
            type='email'
          />
        </div>
        <div className='log-input-containter'>
          <label className='log-label'>Password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className='log-input'
            type='password'
          />
        </div>
        <div className='log-btn-container'>
          <button className='log-btn'>
            Log In
          </button>
        </div>

        {/* <p className='log-text'>
          Don't have an account yet?
          <Link to='/signup' className='underline'>
            Sign up.
          </Link>
        </p> */}

      </form>
    </div>
  );
};

export default Signin;
