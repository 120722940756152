import React from 'react'

const AspBaseIcon = () => {
    return (
        <g id="Icons">
            <defs>
                <pattern id="USGS_17_Sandy_Dry_Lake" data-name="USGS 17 Sandy Dry Lake" x="0" y="0" width="72" height="72" patternTransform="translate(-3034.7594 -2658.3469) scale(.6474)" patternUnits="userSpaceOnUse" viewBox="0 0 72 72">
                    <g>
                        <rect width="72" height="72" fill="none" />
                        <path d="M20.9971,3c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1191-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M19.9971,8.25c-.0596,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1094-.0703-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2597-.0898,.3603,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M20.2471,10.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2695-.0898,.3603,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1396-.0801,.1797c-.04,.04-.0996,.0703-.1699,.0703Z" fill="#1d1d1b" />
                        <path d="M17.9971,12.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M15.2471,10.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1201-.1006,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M22.7471,20c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2695-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M15.2471,23c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.1201-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M13.9971,24c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1309,.0703-.1797c.1103-.1006,.2597-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M23.9971,27c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1699c.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M30.7471,25.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M31.2471,23.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M29.2471,18.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M32.2471,18.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M32.4971,13.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M31.7471,11.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M29.2471,10.75c-.0694,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1094-.0703-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M32.2471,7c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M31.9971,3c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M29.2471,3.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M33.9971,1.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1191-.0694,.1699-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M41.4971,7c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2403-.0898,.3399,0,.0605,.0596,.0801,.1192,.0801,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M45.4971,6.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M40.2471,10.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M44.9971,11.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M44.9971,13.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.2598-.0898,.3506,.0098,.0498,.039,.0694,.0996,.0694,.1699s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M46.9971,14.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M46.9971,16.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.2598-.0898,.3506,.0098,.0498,.039,.0694,.1094,.0694,.1699s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M47.2471,22.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0401-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M38.9971,20.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M50.2471,13.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M52.2471,12c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M54.7471,11c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.08-.1797,.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M54.7471,6.75c-.0694,0-.1299-.0303-.17-.0703-.0498-.0606-.08-.1192-.08-.1797s.0302-.1201,.0703-.1699c.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M56.4971,.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2597-.0898,.3603,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M63.9971,6.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M64.9971,2.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M67.9971,3c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2695-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M67.9971,10.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M64.2471,12.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0898-.0996,.25-.0996,.3496-.0098,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M65.2471,15.75c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0704-.0801,.2598-.0898,.3506,.0098,.0498,.039,.0694,.1094,.0694,.1699s-.0196,.1191-.0694,.1699c-.04,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M63.9971,19.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M66.2471,19.75c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699,0-.0605,.0302-.1309,.0703-.1699,.0898-.0996,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M67.9971,21.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1201,.0703-.1699c.0996-.0996,.2695-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M69.2471,20.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M69.4971,18c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M71.2471,17.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1201,.0703-.1699c.1103-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M7.9971,47c-.0596,0-.1299-.0303-.1797-.0703-.04-.0606-.0703-.1192-.0703-.1797,0-.0703,.0303-.1309,.0703-.1797,.0898-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797,0,.0605-.0195,.1299-.0693,.1699-.0508,.0606-.1103,.0801-.1807,.0801Z" fill="#1d1d1b" />
                        <path d="M6.9971,52.25c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1797c.1103-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1192,.0693,.1797,0,.0703-.0195,.1299-.0693,.1797-.0508,.0508-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M7.2471,54.5c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1309,.0703-.1797,.0996-.0898,.2598-.0898,.3603,0,.04,.0488,.0693,.1094,.0693,.1797s-.0293,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M4.9971,56.75c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1699c.0996-.0996,.2598-.0996,.3603-.0098,.0498,.0488,.0693,.1094,.0693,.1797s-.0293,.1299-.0693,.1797c-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M2.2471,54.5c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1309,.0703-.1699,.1103-.1104,.2598-.0996,.3496-.0098,.0606,.0596,.0801,.1192,.0801,.1797s-.0195,.1299-.0693,.1797c-.0605,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M9.7471,64c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1797c.1103-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.0508-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M2.2471,67c-.0596,0-.1201-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1797c.0996-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797,0,.0606-.0195,.1299-.0693,.1797-.0605,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M.9971,68c-.0596,0-.1299-.0303-.1797-.0703-.04-.0498-.0703-.1094-.0703-.1797,0-.0606,.0303-.1309,.0703-.1797,.0996-.0898,.2695-.0898,.3603,0,.0498,.0488,.0693,.1191,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M10.9971,71c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M17.7471,69.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M18.2471,67.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0606-.08-.1191-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0606-.0196,.1299-.0694,.1699-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M16.2471,62.25c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1309,.0703-.1797c.0996-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M19.2471,62.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2695-.0898,.3603,0,.0401,.0391,.0694,.1094,.0694,.1797s-.0293,.1397-.0801,.1797c-.04,.04-.0996,.0703-.1699,.0703Z" fill="#1d1d1b" />
                        <path d="M19.4971,57.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M18.7471,55.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M16.2471,54.75c-.0596,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1094-.0703-.1797,0-.0605,.0302-.1201,.0703-.1699,.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M19.2471,51c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.0996-.0898,.2695-.0898,.3603,0,.0401,.0391,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M18.9971,47c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.0996-.0898,.2597-.0898,.3603,0,.0401,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M16.2471,47.25c-.0694,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1192-.0703-.1797,0-.0703,.0302-.1309,.0703-.1797,.0898-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M20.9971,45.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M28.4971,51c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M32.4971,50.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M27.2471,54.25c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1406,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.04,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M31.9971,55.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M31.9971,57.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M33.9971,58.25c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1406,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.04,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M33.9971,60.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M34.2471,66.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M25.9971,64.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,.0098,.0498,.0391,.0694,.1094,.0694,.1699,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M37.2471,57.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3399,0,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M39.2471,56c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M41.7471,55c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1191-.0694,.1699-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M41.7471,50.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M43.4971,44.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M50.9971,50.5c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M51.9971,46.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M54.9971,47c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M54.9971,54.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M51.2471,56.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M52.2471,59.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M50.9971,63.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M53.2471,63.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1191-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1191,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M54.9971,65.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M56.2471,64.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M56.4971,62c-.0596,0-.1299-.0303-.17-.0703-.0498-.0606-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.08-.1797,.0704-.0801,.2598-.0898,.3506,.0098,.0498,.039,.0694,.1094,.0694,.1699s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M58.2471,61.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2695-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.04,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M20.9971,27c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M22.7471,24c-.0694,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1094-.0703-.1797s.0302-.1309,.08-.1797c.1006-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.04,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M25.2471,24.5c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M35.2471,23.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M38.9971,24.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M44.9971,29.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M46.2471,29c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3399,0,.0605,.0596,.0801,.1192,.0801,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M55.2471,28c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M51.2471,25.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M51.9971,29.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M62.4971,35.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.2403-.0898,.3399,0,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M58.2471,38.25c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2695-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.04,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M48.9971,39c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M47.9971,41.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2403-.0898,.3399,0,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1191-.0694,.1699c-.0605,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M51.9971,43c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M53.4971,45c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M62.2471,48c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M64.9971,51.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0899-.0801,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M66.9971,51.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M69.4971,45c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M59.9971,39c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M47.2471,47.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M32.4971,47c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M36.9971,44.5c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M26.2471,37.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M29.7471,35.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M16.9971,28.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M10.4971,29.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M5.9971,31.5c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1201,.0703-.1699,.1201-.1104,.2598-.0996,.3603-.0098,.0498,.0596,.0693,.1192,.0693,.1797,0,.0703-.0195,.1299-.0693,.1797-.0508,.0508-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M2.4971,35.25c-.0693,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0801-.1797,.0801-.0898,.2402-.0898,.3398,0,.0606,.0488,.0801,.1094,.0801,.1797,0,.0605-.0195,.1299-.0693,.1699-.0508,.0606-.1103,.0801-.1807,.0801Z" fill="#1d1d1b" />
                        <path d="M11.2471,36.75c-.0694,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1192-.0703-.1797,0-.0703,.0302-.1309,.0703-.1797,.0996-.0898,.25-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M16.9971,42c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M12.7471,36c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M26.4971,30.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M37.7471,31c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M39.4971,32.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.2598-.0898,.3506,.0098,.0498,.039,.0694,.1094,.0694,.1699s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M27.9971,42c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1201,.0703-.1699c.1103-.0996,.2597-.0996,.3496-.0098,.0605,.0596,.0801,.1192,.0801,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M6.4971,20.5c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0703-.1797,.0996-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797,0,.0605-.0195,.1299-.0693,.1699-.0508,.0606-.1103,.0801-.1807,.0801Z" fill="#1d1d1b" />
                        <path d="M10.2471,20.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M11.7471,23.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M2.9971,25.75c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1797c.0996-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M64.2471,29.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.25-.0996,.3496-.0098,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M67.4971,30.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M69.4971,29.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M7.2471,15.75c-.0693,0-.1299-.0303-.1797-.0801-.04-.04-.0703-.0996-.0703-.1699s.0303-.1309,.0703-.1797c.0996-.0898,.2598-.0898,.3603,0,.04,.0488,.0693,.1094,.0693,.1797s-.0293,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M1.4971,9c-.0596,0-.1299-.0303-.1797-.0703-.04-.0498-.0703-.1094-.0703-.1797s.0303-.1309,.0703-.1797c.0898-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1192,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M14.4971,6.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M8.4971,5.25c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1797c.0996-.0898,.2695-.0898,.3603,.0098,.0498,.039,.0693,.0996,.0693,.1699,0,.0605-.0195,.1299-.0693,.1797-.0508,.0508-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M3.4971,2.5c-.0693,0-.1299-.0303-.1797-.0801-.04-.04-.0703-.0996-.0703-.1699s.0303-.1309,.0801-.1797c.0801-.0898,.25-.0898,.3506,0,.04,.0488,.0693,.1094,.0693,.1797s-.0293,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M3.7471,6c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1309,.0703-.1797,.0898-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M12.7471,14.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.0996-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M18.4971,19.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2695-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M21.2471,37c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M.7471,44.25c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0801-.1797c.0703-.0801,.25-.0898,.3506,.0098,.04,.039,.0693,.0996,.0693,.1699s-.0293,.1299-.0693,.1797c-.0508,.04-.1104,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M66.4971,37.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M67.9971,66c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1191-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.0996-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M59.7471,69.75c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M40.4971,68c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M43.9971,63c-.0596,0-.1201-.0195-.17-.0703-.0498-.04-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M25.7471,70c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0606-.0196,.1191-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M4.4971,60c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1309,.0703-.1699,.1103-.1104,.2598-.0996,.3603-.0098,.0498,.0596,.0693,.1192,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M26.4971,59.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M37.2471,48.5c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2403-.0898,.3399,0,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1299-.0694,.1797c-.0605,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M21.9971,41.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M8.7471,40.25c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0801-.1797,.0898-.0898,.25-.0898,.3506,0,.04,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.0508-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M23.9971,29c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M41.4971,34.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3399,0,.0605,.0596,.0801,.1192,.0801,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M51.4971,37.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M60.7471,46c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M67.4971,43.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M51.2471,66.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M39.9971,37.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M53.2471,15c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0704-.0801,.2598-.0898,.3506,.0098,.0498,.039,.0694,.1094,.0694,.1699s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M33.9971,16c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1406,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M27.4971,13.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1699c.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M12.7471,19.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M19.7471,26.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M49.4971,4.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0401-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M11.7471,1.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1797,.0996-.0898,.2695-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M55.2471,3.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M58.9971,20.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M67.4971,57.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.08-.1797,.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M47.2471,69.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.04-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M5.2471,69.75c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0606,.0303-.1201,.0703-.1699,.0996-.0996,.2598-.0996,.3603-.0098,.0498,.0488,.0693,.1191,.0693,.1797,0,.0703-.0195,.1299-.0693,.1797-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M4.7471,40.25c-.0596,0-.1299-.0303-.1797-.0703-.04-.0498-.0703-.1094-.0703-.1797s.0303-.1309,.0703-.1797c.0898-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1192,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M69.7471,12.75c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M23.2471,14c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1699c.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M20.4971,17.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0401-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0704-.0801,.2598-.0898,.3506,.0098,.0498,.039,.0694,.1094,.0694,.1699s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M14.7471,17.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M2.7471,13.25c-.0693,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0801-.1797,.0898-.0898,.25-.0898,.3506,0,.04,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M5.9971,11.75c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0703-.1797,.1201-.1006,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797,0,.0605-.0195,.1191-.0693,.1699-.0508,.0606-.1103,.0801-.1807,.0801Z" fill="#1d1d1b" />
                        <path d="M6.9971,2.75c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0703-.1797,.1103-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1192,.0693,.1797s-.0195,.1191-.0693,.1699c-.0508,.0606-.1103,.0801-.1807,.0801Z" fill="#1d1d1b" />
                        <path d="M9.9971,8.25c-.0693,0-.1299-.0303-.1797-.0703-.04-.0498-.0703-.1192-.0703-.1797,0-.0703,.0303-.1309,.0703-.1797,.0898-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M15.9971,8.25c-.0596,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1094-.0703-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M16.4971,3.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.1006,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M25.7471,7c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M36.9971,7.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M40.4971,4.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0401-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M37.4971,4.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0401-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M43.2471,2.25c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M49.4971,8.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0401-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M45.9971,9.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M35.7471,11c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M39.2471,15c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M24.7471,18c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.0996-.0898,.2695-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M8.2471,27.25c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1797c.1201-.1006,.2598-.0898,.3603,0,.0498,.0488,.0693,.1192,.0693,.1797,0,.0703-.0195,.1299-.0693,.1797-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M15.9971,31.75c-.0596,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1094-.0703-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M10.4971,33.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1103-.1006,.2597-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M5.2471,37c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1201,.0703-.1699,.0996-.0996,.2598-.0996,.3603-.0098,.0498,.0488,.0693,.1192,.0693,.1797,0,.0703-.0195,.1299-.0693,.1797-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M2.7471,32c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0801-.1797,.0898-.0898,.25-.0898,.3506,0,.04,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M7.4971,25c-.0693,0-.1299-.0303-.1797-.0703-.04-.0498-.0703-.1094-.0703-.1797s.0303-.1309,.0703-.1797c.1103-.0898,.25-.0898,.3496,0,.0606,.0488,.0801,.1192,.0801,.1797s-.0195,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M3.2471,20.5c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1309,.0703-.1699,.0996-.0996,.2598-.0996,.3603-.0098,.0498,.0488,.0693,.1192,.0693,.1797,0,.0703-.0195,.1299-.0693,.1797-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M20.4971,30.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M17.4971,35.5c-.0596,0-.1201-.0303-.17-.0703-.0498-.0606-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M15.9971,39c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M12.7471,41.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1797,.0996-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M12.7471,46.5c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.0996-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M3.9971,46.75c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797,0-.0605,.0303-.1309,.0703-.1797,.0996-.0898,.2598-.0898,.3496,0,.0606,.0488,.0801,.1192,.0801,.1797,0,.0703-.0195,.1299-.0693,.1797-.0508,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M5.7471,48.5c-.0693,0-.1299-.0303-.1797-.0801-.04-.04-.0703-.0996-.0703-.1699s.0303-.1309,.0703-.1797c.0996-.0898,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.04-.1103,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M10.9971,50.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M10.9971,54.5c-.0596,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1094-.0703-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M15.4971,58.5c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M9.9971,59.75c-.0693,0-.1299-.0303-.1797-.0703-.04-.0498-.0703-.1192-.0703-.1797,0-.0703,.0303-.1309,.0703-.1797,.0996-.0898,.25-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M5.9971,63.5c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1191-.0801-.1797,0-.0703,.0303-.1309,.0703-.1797,.1201-.1006,.2598-.0898,.3603,0,.0498,.0488,.0693,.1094,.0693,.1797,0,.0606-.0195,.1191-.0693,.1699-.0508,.0606-.1103,.0801-.1807,.0801Z" fill="#1d1d1b" />
                        <path d="M2.2471,62c-.0596,0-.1201-.0195-.1699-.0703-.0498-.0498-.0801-.1094-.0801-.1797s.0303-.1309,.0703-.1797c.1103-.0898,.25-.0898,.3496,0,.0606,.0488,.0801,.1192,.0801,.1797s-.0195,.1299-.0693,.1797c-.0605,.0508-.1201,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M7.2471,67.75c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1191-.0801-.1797,0-.0703,.0303-.1309,.0703-.1797,.0996-.0898,.2598-.0898,.3603,0,.04,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1699c-.0508,.0606-.1103,.0801-.1807,.0801Z" fill="#1d1d1b" />
                        <path d="M13.4971,69.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1191-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M13.4971,65.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0606,.0302-.1309,.0703-.1699,.1103-.1103,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M21.4971,68.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M22.9971,65.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0606,.0302-.1201,.0703-.1699,.1103-.1103,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1191,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M22.2471,60c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M24.2471,57.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M21.9971,53.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1699c.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M26.2471,47.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M25.7471,43.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0596,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M31.2471,37.5c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M32.2471,31c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1201,.0703-.1699,.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0293,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M25.9971,34c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M34.2471,27.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M37.2471,28.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3399,0,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M41.7471,25.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M42.9971,20.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M36.4971,19.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0401-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M27.7471,22.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.1103-.1104,.2597-.0996,.3603-.0098,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M51.9971,22c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M43.2471,17.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M50.4971,18c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M59.4971,17.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M58.2471,14c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M61.4971,11c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M58.7471,7.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M59.9971,4.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.0703-.1797c.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M67.4971,6c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M69.4971,8c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797,0-.0605,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M65.7471,9.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M61.7471,16.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M60.2471,23.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M55.4971,23.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M55.4971,18.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M64.9971,25.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M61.2471,27c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M57.7471,30.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M52.7471,32.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M47.4971,33.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3399,0,.0605,.0596,.0801,.1192,.0801,.1797s-.0196,.1191-.0694,.1699c-.0605,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M44.2471,39.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M33.9971,41.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M40.7471,48c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M45.7471,52.25c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M46.4971,56.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M41.9971,59.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M39.9971,62.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0899-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0606-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M35.9971,62.75c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.2598-.0898,.3506,0,.0498,.0596,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M36.4971,52.75c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M29.9971,63.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M31.4971,68.5c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1406,.08-.1797c.0899-.0801,.25-.0898,.3399,0,.0605,.0488,.0801,.1094,.0801,.1797,0,.0606-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M36.9971,71c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.1006-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M58.7471,57c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M62.9971,57c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M59.9971,50.75c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1406,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M54.4971,50.5c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2597-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M59.2471,44.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.0703-.1699c.0996-.0996,.2695-.0996,.3603-.0098,.0498,.0596,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.04,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M54.7471,41.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M55.7471,35.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M62.4971,42.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.2403-.0898,.3399,0,.0605,.0488,.0801,.1192,.0801,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M66.9971,47.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0293,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M69.4971,54.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M63.4971,61.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.08-.1797,.0704-.0801,.25-.1006,.3506,0,.0498,.0488,.0694,.1192,.0694,.1797s-.0196,.1191-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M69.2471,63.5c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M64.7471,65.5c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1191-.08-.1797s.0302-.1201,.0703-.1699c.0996-.0996,.2695-.0996,.3603-.0098,.0498,.0596,.0694,.1191,.0694,.1797s-.0196,.1191-.0694,.1699c-.04,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M65.7471,68.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0898,.2598-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797,0,.0703-.0196,.1299-.0694,.1797-.0507,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M69.2471,70.5c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1191-.08-.1797,0-.0703,.0302-.1309,.08-.1797,.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0606-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M62.4971,70.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.2403-.1006,.3399,0,.0605,.0488,.0801,.1191,.0801,.1797s-.0196,.1299-.0694,.1797c-.0605,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M60.7471,65.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M52.7471,69.75c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M44.7471,66.5c-.0694,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0606-.0196,.1299-.0694,.1699-.0507,.0498-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M44.4971,71.25c-.0596,0-.1201-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1201,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M28.4971,71.25c-.0694,0-.1299-.0303-.1797-.0801-.0401-.04-.0703-.0996-.0703-.1699s.0302-.1309,.08-.1797c.0899-.0898,.25-.0898,.3506,0,.0498,.0488,.0694,.1191,.0694,.1797s-.0196,.1299-.0694,.1797c-.04,.04-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                        <path d="M.7471,29.5c-.0596,0-.1299-.0303-.1699-.0703-.0498-.0498-.0801-.1192-.0801-.1797,0-.0703,.0303-.1309,.0801-.1797,.0801-.0898,.2402-.0898,.3506,0,.04,.0488,.0693,.1094,.0693,.1797s-.0195,.1299-.0693,.1797c-.0508,.0508-.1104,.0703-.1807,.0703Z" fill="#1d1d1b" />
                        <path d="M24.4971,1c-.0596,0-.1201-.0195-.17-.0703-.0498-.0498-.08-.1192-.08-.1797,0-.0703,.0302-.1309,.0703-.1797,.1103-.0898,.2597-.0898,.3603,0,.0401,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1699c-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M13.2471,5c-.0694,0-.1299-.0303-.1797-.0703-.0401-.0498-.0703-.1192-.0703-.1797,0-.0703,.0302-.1309,.0703-.1797,.0898-.0898,.2597-.0898,.3603,0,.0498,.0488,.0694,.1094,.0694,.1797,0,.0605-.0196,.1299-.0694,.1699-.0507,.0606-.1103,.0801-.1806,.0801Z" fill="#1d1d1b" />
                        <path d="M70.9971,35.25c-.0596,0-.1299-.0303-.17-.0703-.0498-.0498-.08-.1094-.08-.1797s.0302-.1309,.08-.1797c.0801-.0801,.25-.0898,.3506,0,.0498,.0488,.0694,.1094,.0694,.1797s-.0196,.1299-.0694,.1797c-.0507,.0508-.1103,.0703-.1806,.0703Z" fill="#1d1d1b" />
                    </g>
                </pattern>
            </defs>
            <g>
                <rect x="3.4852" y="10.6301" width="36.0296" height="10.8307" fill="#e1e5f2" opacity=".6" />
                <g>
                    <path d="M14.8331,17.8612l-.4785,1.7236h-1.5742l2.0527-6.7158h1.9927l2.0825,6.7158h-1.6343l-.5181-1.7236h-1.9229Zm1.7036-1.1362l-.4185-1.4248c-.1196-.3984-.2393-.8965-.3389-1.2949h-.02c-.0996,.3984-.1992,.9062-.3086,1.2949l-.3984,1.4248h1.4844Z" fill="#142127" />
                    <path d="M19.8521,18.0204c.4087,.2095,1.0361,.4185,1.6841,.4185,.6973,0,1.0659-.2891,1.0659-.7271,0-.4185-.3188-.6577-1.126-.9468-1.1157-.3887-1.8433-1.0063-1.8433-1.9829,0-1.146,.9565-2.0225,2.541-2.0225,.7568,0,1.3149,.1592,1.7134,.3389l-.3384,1.2251c-.269-.1294-.7476-.3184-1.4053-.3184s-.9766,.2988-.9766,.6475c0,.4282,.3789,.6177,1.2456,.9463,1.186,.4385,1.7437,1.0566,1.7437,2.0029,0,1.126-.8667,2.0825-2.71,2.0825-.7671,0-1.5244-.1992-1.9033-.4087l.3091-1.2554Z" fill="#142127" />
                    <path d="M25.1807,12.9589c.4683-.0796,1.126-.1396,2.0527-.1396,.9365,0,1.604,.1792,2.0522,.5381,.4287,.3389,.7173,.897,.7173,1.5542,0,.6577-.2188,1.2158-.6177,1.5942-.5181,.4883-1.2852,.7075-2.1821,.7075-.1992,0-.3784-.0098-.5181-.0298v2.4014h-1.5044v-6.626Zm1.5044,3.0488c.1294,.0298,.2891,.04,.5083,.04,.8071,0,1.3052-.4087,1.3052-1.0962,0-.6177-.4282-.9863-1.1855-.9863-.3091,0-.5181,.0298-.6279,.0596v1.9829Z" fill="#142127" />
                </g>
            </g>
            <rect x=".7136" y="21.8716" width="41.5728" height="10.4983" fill="url(#USGS_17_Sandy_Dry_Lake)" stroke="#142127" strokeMiterlimit="10" strokeWidth=".4022" />
        </g>
    )
}
export default AspBaseIcon