import React from 'react'

const RebBaseIcon = () => {
    return (
        <g id="Icons">
            <rect x="3.4852" y="9.2123" width="36.0296" height="12.2482" fill="#808e93" opacity=".6" />
            <text transform="translate(12.6109 18.5986)" fill="#fff" font-family="MyriadPro-Bold, &apos;Myriad Pro&apos;" font-size="9.9602" font-weight="700"><tspan x="0" y="0">DNO</tspan></text>
            <g>
                <circle cx="3.0241" cy="25.0633" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="5.2365" cy="23.6863" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m8.5164,22.7116c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857-.1025,0-.1856.0831-.1856.1857,0,.1025.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="5.8138" cy="25.6458" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m8.97,25.7077c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m11.7858,23.0621c.1025,0,.1856-.0831.1856-.1857,0-.1025-.0831-.1856-.1856-.1856s-.1857.0831-.1857.1856c0,.1025.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="2.9419" cy="28.5351" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m1.9672,31.4282c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m5.2568,28.8794c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857.1856-.0831.1856-.1857-.0831-.1857-.1856-.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="7.1907" cy="28.8484" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m8.2892,27.9047c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="7.2836" cy="31.4785" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="11.8012" cy="29.359" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="11.5073" cy="26.636" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m12.9306,25.8624c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.0812,22.9074c.1025,0,.1856-.0831.1856-.1856s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m16.8294,25.4911c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="18.8407" cy="27.3013" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.7773,29.1114c.1025,0,.1857-.0831.1857-.1857,0-.1025-.0831-.1856-.1857-.1856s-.1856.0831-.1856.1856c0,.1025.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.7015,31.3707c0,.1025.0831.1857.1856.1857.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m13.0389,31.525c0,.1025.0831.1856.1857.1856s.1856-.0831.1856-.1856-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="20.8186" cy="22.9461" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m23.0566,27.6184c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m23.3559,24.2457c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m25.8647,25.3209c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857s.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="26.5377" cy="23.6732" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.0515" cy="22.7619" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m28.5335,26.4116c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.5799" cy="28.5467" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m27.7445,31.378c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m25.447,29.6142c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="21.9891" cy="31.2387" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m21.1537,29.7071c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="18.7866" cy="29.9856" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m30.1116,25.7271c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m34.9619,29.7303c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="32.9864" cy="26.9802" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m33.9843,28.4887c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.5358,27.0615c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="30.3669" cy="28.8716" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m30.1116,31.4012c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m35.2172,26.0868c.1025,0,.1857-.0831.1857-.1857,0-.1025-.0831-.1856-.1857-.1856s-.1857.0831-.1857.1856c0,.1025.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="33.5231" cy="23.5572" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="32.5019" cy="23.1627" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m29.5778,27.7345c.1025,0,.1857-.0831.1857-.1857,0-.1025-.0831-.1856-.1857-.1856-.1025,0-.1856.0831-.1856.1856,0,.1025.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m39.7741,27.6735c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.2341,29.3126c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m37.7083,22.8345c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857s-.1857.0831-.1857.1857c0,.1025.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="33.5695" cy="31.3316" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
            </g>
            <path d="m42.3716,33.1352H.6285v-12.0923h41.7432v12.0923Zm-40.9932-.75h40.2432v-10.5923H1.3785v10.5923Z" fill="#fff" />
        </g>
    )
}


export default RebBaseIcon;