import Icons from '../../assets/pieIcons/Icons';


function pieCalculatorA(data, keyValue, name) {
    let inputData = [...data.features]

    let filteredData = inputData.filter((item) => item.properties[keyValue] === "Complete" || item.properties[keyValue] === "Outstanding")

    let qtyComplete = 0
    let qtyTotal = filteredData.length
    let qtyOutstanding = 0
    let percentageValue = 0

    filteredData.forEach((item) => {
        if (item.properties[keyValue] === "Complete") {
            qtyComplete = qtyComplete + 1;
        }
    })

    qtyOutstanding = qtyTotal - qtyComplete

    if (qtyTotal > 0) {
        percentageValue = Math.round((qtyComplete / qtyTotal) * 100)
    } else {
        percentageValue = 0
    }


    return {
        itemNameText: name,
        qtyTotal,
        qtyComplete,
        qtyOutstanding,
        percentageValue,
    }
}

function pieCalculatorB(data, keyValue, typeKey, typeValue, name) {

    let inputData = [...data.features].filter((item) => item.properties[typeKey] === typeValue)

    let filteredData = inputData.filter((item) => item.properties[keyValue] === "Complete" || item.properties[keyValue] === "Outstanding")

    let qtyComplete = 0
    let qtyTotal = filteredData.length
    let qtyOutstanding = 0
    let percentageValue = 0

    filteredData.forEach((item) => {
        if (item.properties[keyValue] === "Complete") {
            qtyComplete = qtyComplete + 1;
        }
    })

    qtyOutstanding = qtyTotal - qtyComplete

    if (qtyTotal > 0) {
        percentageValue = Math.round((qtyComplete / qtyTotal) * 100)
    } else {
        percentageValue = 0
    }

    return {
        itemNameText: name,
        qtyTotal,
        qtyComplete,
        qtyOutstanding,
        percentageValue,
    }
}

function pieCalculatorC(data, keyValue, keyValue2, typeKey, typeValue, name) {
    let inputData = [...data.features].filter((item) => item.properties[typeKey] === typeValue)

    let filteredData = inputData.filter((item) => item.properties[keyValue] === "Complete" || item.properties[keyValue] === "Outstanding")

    let qtyComplete = 0
    let qtyTotal = filteredData.length
    let qtyOutstanding = 0
    let percentageValue = 0

    filteredData.forEach((item) => {
        if (item.properties[keyValue] === "Complete" && item.properties[keyValue2] === "Complete") {
            qtyComplete = qtyComplete + 1;
        }
    })

    qtyOutstanding = qtyTotal - qtyComplete

    if (qtyTotal > 0) {
        percentageValue = Math.round((qtyComplete / qtyTotal) * 100)
    } else {
        percentageValue = 0
    }

    return {
        itemNameText: name,
        qtyTotal,
        qtyComplete,
        qtyOutstanding,
        percentageValue,
    }
}


const Phase5PieData = {
    Civils: [],
    Signalling: [],
    Cabling: [],

    setCivils: function ({ SignalCivilsData, StructuresData, EquipmentCubicleData, UTXProposedCentresData }) {
        this.Civils = [

            // SIGNAL CIVILS
            {
                groupName: 'All Civils Works',
                itemNameText: 'Signal Foundations',
                subCatergory: 'Signal Civils',
                PieIcon: Icons.SignalFoundationIcon,
                ...pieCalculatorA(SignalCivilsData, "Base", 'Signal Foundations')
            },
            {
                groupName: 'All Civils Works',
                itemNameText: 'Signal Posts',
                subCatergory: 'Signal Civils',
                PieIcon: Icons.SignalPostIcon,
                ...pieCalculatorB(SignalCivilsData, "Structure", "structure type", "Post", 'Signal Posts')
            },

            {
                groupName: 'All Civils Works',
                itemNameText: 'Signal Structures',
                subCatergory: 'Signal Civils',
                PieIcon: Icons.SignalPostIcon,
                ...pieCalculatorB(StructuresData, "Structure", "structure type", "Post", 'Signal Posts')
            },

            {
                groupName: 'All Civils Works',
                itemNameText: 'Loc Bases',
                subCatergory: 'Equipment Bases',
                PieIcon: Icons.LocBaseIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Base", "description", "LOC", 'Loc Bases')
            },
            {
                groupName: 'All Civils Works',
                itemNameText: 'ASP Bases',
                subCatergory: 'Equipment Bases',
                PieIcon: Icons.AspBaseIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Base", "description", "ASP", 'ASP Bases')


            },
            {
                groupName: 'All Civils Works',
                itemNameText: 'PSP Bases',
                subCatergory: 'Equipment Bases',
                PieIcon: Icons.PspBaseIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Base", "description", "PSP", 'PSP Bases')

            },
            {
                groupName: 'All Civils Works',
                itemNameText: 'REB Bases',
                subCatergory: 'Equipment Bases',
                PieIcon: Icons.RebBaseIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Base", "description", "REB", 'REB Bases')

            },
            {
                groupName: 'All Civils Works',
                itemNameText: 'DNO Bases',
                subCatergory: 'Equipment Bases',
                PieIcon: Icons.DnoBaseIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Base", "description", "DNO", 'DNO Bases')

            },

            {
                groupName: 'All Civils Works',
                itemNameText: 'UTXs',
                subCatergory: `UTX's & Ducts`,
                PieIcon: Icons.UtxIcon,
                ...pieCalculatorC(UTXProposedCentresData, "Base", "Structure", "type", "Under Track Crossing", 'UTXs')
            },

            {
                groupName: 'All Civils Works',
                itemNameText: `URX's`,
                subCatergory: `UTX's & Ducts`,
                PieIcon: Icons.UrxIcon,
                ...pieCalculatorC(UTXProposedCentresData, "Base", "Structure", "type", "Under Road Crossing", `URX's`)

            },
        ]

    },

    setSignalling: function ({ SignalCivilsData, EquipmentCubicleData }) {
        this.Signalling = [

            // SIGNAL ASSETS
            {
                groupName: 'All Signal Works',
                itemNameText: 'Signal Heads',
                subCatergory: 'Signal Assets',
                PieIcon: Icons.SignalHeadIcon,
                ...pieCalculatorB(SignalCivilsData, "Signal Head", "signal head type", "Signal Head", 'Signal Heads')
            },
            {
                groupName: 'All Signal Works',
                itemNameText: 'Banner Repeaters',
                subCatergory: 'Signal Assets',
                PieIcon: Icons.SignalBannerIcon,
                ...pieCalculatorB(SignalCivilsData, "Signal Head", "signal head type", "Banner Repeater", 'Banner Repeaters')

            },
            {
                groupName: 'All Signal Works',
                itemNameText: 'Ground Signals',
                subCatergory: 'Signal Assets',
                PieIcon: Icons.SignalGroundIcon,
                ...pieCalculatorB(SignalCivilsData, "Signal Head", "signal head type", "Ground Signal", 'Ground Signals')

            },
            {
                groupName: 'All Signal Works',
                itemNameText: 'Off Indicators',
                subCatergory: 'Signal Assets',
                PieIcon: Icons.SignalOffIndicatorIcon,
                ...pieCalculatorB(SignalCivilsData, "Signal Head", "signal head type", "Off Indicator", 'Off Indicators')

            },

            {
                groupName: 'All Signal Works',
                itemNameText: 'Loc Suites',
                subCatergory: 'Power Cubicles',
                ...pieCalculatorB(EquipmentCubicleData, "Structure", "description", "LOC", 'Loc Suites'),

                PieIcon: Icons.LocCabIcon,

            },
            {
                groupName: 'All Signal Works',
                itemNameText: 'ASPs',
                subCatergory: 'Power Cubicles',
                PieIcon: Icons.AspCabIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Structure", "description", "ASP", 'ASPs')

            },

            {
                groupName: 'All Signal Works',
                itemNameText: 'PSPs',
                subCatergory: 'Power Cubicles',
                PieIcon: Icons.PspCabIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Structure", "description", "PSP", 'PSPs')
            },
            {
                groupName: 'All Signal Works',
                itemNameText: 'REBs',
                subCatergory: 'Power Cubicles',
                PieIcon: Icons.RebCabIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Structure", "description", "REB", 'REBs')
            },
            {
                groupName: 'All Signal Works',
                itemNameText: 'DNOs',
                subCatergory: 'Power Cubicles',
                PieIcon: Icons.DnoCabIcon,
                ...pieCalculatorB(EquipmentCubicleData, "Structure", "description", "DNO", 'DNOs')
            }

        ];
    },
    setCabling: function ({ PowerCablesData, FibreCablesData }) {
        this.Cabling = [

            // CABLING
            {
                groupName: 'All Cabling',
                itemNameText: 'Power',
                subCatergory: 'Power',
                PieIcon: Icons.CablePowerIcon,
                ...pieCalculatorA(PowerCablesData, "status", 'Power Cable')
            },
            {
                groupName: 'All Cabling',
                itemNameText: 'Fibre',
                subCatergory: 'Fibre',
                PieIcon: Icons.CableWtsIcon,
                ...pieCalculatorA(FibreCablesData, "status", 'Fibre Cable')
            },



        ]

    },



}




export default Phase5PieData