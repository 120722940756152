import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

import { getDoc, doc } from "firebase/firestore";
import { auth, db } from '../firebase';
import Login from '../pages/Login';
import { useNavigate } from 'react-router-dom';

import Loading from '../components/Loading/Loading';

export const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {

  const [{ user, loading }, setState] = useState({ loading: true });
  const navigate = useNavigate();

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {

      const newState = {}

      if (currentUser) {

        const snapshot = await getDoc(doc(db, "UserMetaData", currentUser.uid))
        let userMetaData = {}
        if (snapshot.exists()) {
          userMetaData = { ...snapshot.data(), id: snapshot.id };
        }
        newState.user = { ...currentUser, ...userMetaData }
      }
      setState(newState)
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    if (user) {
      const pathname = window.location.pathname;
      if (!pathname.includes('/app/')) navigate('/app/dashboard', { replace: true })
    }
  }, [user]);
  if (loading) return <Loading />
  if (!user) return <Login />


  return (
    <UserContext.Provider value={{ createUser, user, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
