import React from 'react'

const RebCabIcon = () => {
    return (
        <g>
            <rect x="2.0777" y="21.8472" width="38.8446" height="9.8094" fill="#808e93" />
            <g>
                <path d="m26.1867,16.9369h-.4844v1.7686c.1396.0186.3076.0186.5488.0186.6426,0,1.21-.2417,1.21-.9028,0-.6328-.5674-.8843-1.2744-.8843Z" fill="#fff" />
                <path d="m27.3126,15.1498c0-.4746-.3633-.7256-1.0049-.7256-.3076,0-.4844.0186-.6055.0371v1.4614h.4658c.7441,0,1.1445-.3071,1.1445-.7729Z" fill="#fff" />
                <path d="m15.8566,14.4428c-.3447,0-.54.0278-.6426.0464v1.7314h.5586c.707,0,1.126-.354,1.126-.9028,0-.5771-.3906-.8657-1.042-.875Z" fill="#fff" />
                <path d="m4.8742,11.5502v9.7065h33.251v-9.7065H4.8742Zm12.2197,8.1606c-.1025-.1768-.251-.689-.4375-1.4614-.167-.7817-.4375-.9961-1.0146-1.0054h-.4277v2.4668h-1.4053v-6.1899c.4561-.0742,1.1357-.1299,1.8896-.1299.9307,0,1.582.1396,2.0283.4932.373.2979.5771.7354.5771,1.3125,0,.8003-.5674,1.3496-1.1074,1.5449v.0278c.4375.1768.6797.5957.8379,1.1729.1953.7075.3906,1.5264.5117,1.7686h-1.4521Zm6.2354,0h-4.002v-6.2734h3.8721v1.1636h-2.4482v1.3027h2.3086v1.1543h-2.3086v1.4893h2.5781v1.1636Zm4.9697-.5024c-.4561.3628-1.2188.5674-2.4668.5674-.6973,0-1.2188-.0464-1.5352-.0928v-6.1621c.3721-.0742,1.126-.1299,1.833-.1299.8662,0,1.3965.0835,1.8525.3535.4375.2324.7539.6606.7539,1.2285,0,.5586-.3252,1.0796-1.0332,1.3403v.0186c.7168.1958,1.2471.7354,1.2471,1.5454,0,.5674-.2598,1.0146-.6514,1.3311Z" fill="#fff" />
            </g>
        </g>
    )
}


export default RebCabIcon;