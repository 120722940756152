import React from 'react'

const SignalModIcon = () => {
    return (
        <g>
            <g>
                <path d="M9.53,7.88V34.73a2.79,2.79,0,0,0,2.78,2.79h5.52a3.46,3.46,0,0,0,3.46-3.47V8a2.52,2.52,0,0,0-2.52-2.52H11.92A2.39,2.39,0,0,0,9.53,7.88Zm6.66,26.21a3.15,3.15,0,0,1-3.83-3.83A3.1,3.1,0,0,1,14.63,28a3.15,3.15,0,0,1,3.83,3.83A3.1,3.1,0,0,1,16.19,34.09Zm0-9.72a3.15,3.15,0,0,1-3.83-3.83,3.1,3.1,0,0,1,2.27-2.27,3.15,3.15,0,0,1,3.83,3.83A3.1,3.1,0,0,1,16.19,24.37Zm0-9.36a3.15,3.15,0,0,1-3.83-3.83,3.1,3.1,0,0,1,2.27-2.27,3.15,3.15,0,0,1,3.83,3.83A3.11,3.11,0,0,1,16.19,15Z" fill="#004d6f" />
                <path d="M29.29,18.08c-.61-.59-1-1.43-.55-1.87l1.83-1.83-.25-.25c-.8-.8-3.36.6-4.22,1.9a2,2,0,0,0,.09,2.45l-5.92,5.93a1.3,1.3,0,0,0-.39.77,2.34,2.34,0,0,0,2.32,2.33,1.35,1.35,0,0,0,.78-.39l5.89-5.9a1.9,1.9,0,0,0,2.47.05c1.3-.87,2.7-3.42,1.9-4.22L33,16.8l-1.83,1.83c-.44.44-1.28.06-1.87-.55Zm3.84,7.18a5.13,5.13,0,0,1-.88,1.17l0,0a4.94,4.94,0,0,1-1.15.86,1.36,1.36,0,0,1-.85.17,1.42,1.42,0,0,1-.78-.39h0l-2.76-2.77.49-.48,2.76,2.76h0a.66.66,0,0,0,.38.19.67.67,0,0,0,.2,0l-3.15-3.15,1.57-1.57a1.54,1.54,0,0,0,.44.19l3,3,.18-.3a.67.67,0,0,0,.08-.43.66.66,0,0,0-.19-.38l-1.83-1.83a3.64,3.64,0,0,0,.72-.26l1.6,1.6a1.42,1.42,0,0,1,.39.78,1.36,1.36,0,0,1-.17.85Zm-8.7-5.93-1.78-1.79-.74,0L20,15.19l1-1,2.39,1.94,0,.74,1.12,1.11.67.67-.72.72Zm-3.2,6.82a1.38,1.38,0,1,1,1.95,0A1.38,1.38,0,0,1,21.23,26.15Z" fill="#bce3f4" fillRule="evenodd" />
            </g>
        </g>
    )
}

export default SignalModIcon;
