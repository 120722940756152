import React from 'react'

const AspCabIcon = () => {
    return (
        <g ><rect x="2.0777" y="21.8472" width="38.8446" height="9.8094" fill="#808e93" opacity=".6" />
            <g>
                <path d="m26.9308,14.4613c-.2881,0-.4844.0278-.5859.0557v1.8525c.1201.0278.2695.0371.4746.0371.7539,0,1.2188-.3818,1.2188-1.0239,0-.5771-.4004-.9214-1.1074-.9214Z" fill="#fff" />
                <path d="m16.1544,14.4984h-.0186c-.0938.3721-.1865.8472-.2891,1.21l-.3721,1.3311h1.3867l-.3906-1.3311c-.1123-.3721-.2236-.8379-.3164-1.21Z" fill="#fff" />
                <path d="m4.8742,11.5502v9.7065h33.251v-9.7065H4.8742Zm12.6758,8.1606l-.4834-1.6104h-1.7969l-.4463,1.6104h-1.4707l1.917-6.2734h1.8613l1.9453,6.2734h-1.5264Zm3.8994.0933c-.7168,0-1.4238-.1865-1.7783-.3818l.2891-1.1729c.3818.1953.9678.3911,1.5732.3911.6514,0,.9961-.27.9961-.6797,0-.3906-.2979-.6143-1.0518-.8843-1.043-.3628-1.7227-.9399-1.7227-1.8521,0-1.0703.8936-1.8896,2.374-1.8896.707,0,1.2285.1489,1.6006.3164l-.3164,1.145c-.251-.1211-.6982-.2979-1.3125-.2979s-.9121.2793-.9121.605c0,.4004.3535.5771,1.1631.8843,1.1084.4097,1.6289.9868,1.6289,1.8711,0,1.0518-.8096,1.9453-2.5312,1.9453Zm7.417-2.9692c-.4834.4561-1.2002.6606-2.0381.6606-.1855,0-.3535-.0093-.4834-.0278v2.2432h-1.4062v-6.1899c.4375-.0742,1.0518-.1299,1.918-.1299.875,0,1.498.1675,1.917.5024.4004.3164.6699.8379.6699,1.4521s-.2041,1.1353-.5771,1.4893Z" fill="#fff" />
            </g>
        </g>

    )
}

export default AspCabIcon
