
// IMAGES

import AspDotExisting from '../../assets/MapSymbols/Icons/Asp-Dot-Existing.png'
import PspDotExisting from '../../assets/MapSymbols/Icons/Psp-Dot-Existing.png'
import LocDotExisting from '../../assets/MapSymbols/Icons/Loc-Dot-Existing.png'
import RebDotExisting from '../../assets/MapSymbols/Icons/REB-Dot-Existing.png'
import DnoDotExisting from '../../assets/MapSymbols/Icons/DNO-Dot-Existing.png'

import AspDotOutstanding from '../../assets/MapSymbols/Icons/Asp-Dot-Outstanding.png'
import PspDotOutstanding from '../../assets/MapSymbols/Icons/PSP-Dot-Outstanding.png'
import LocDotOutstanding from '../../assets/MapSymbols/Icons/Loc-Dot-Outstanding.png'
import RebDotOutstanding from '../../assets/MapSymbols/Icons/REB-Dot-Outstanding.png'
import DnoDotOutstanding from '../../assets/MapSymbols/Icons/DNO-Dot-Outstanding.png'

import AspDotComplete from '../../assets/MapSymbols/Icons/Asp-Dot-Complete.png'
import PspDotComplete from '../../assets/MapSymbols/Icons/PSP-Dot-Complete.png'
import LocDotComplete from '../../assets/MapSymbols/Icons/Loc-Dot-Complete.png'
import RebDotComplete from '../../assets/MapSymbols/Icons/REB-Dot-Complete.png'
import DnoDotComplete from '../../assets/MapSymbols/Icons/DNO-Dot-Complete.png'


import AspRectExisting from '../../assets/MapSymbols/Icons/ASP-Rect-Existing.png'
import PspRectExisting from '../../assets/MapSymbols/Icons/PSP-Rect-Existing.png'
import LocRectExisting from '../../assets/MapSymbols/Icons/LOC-Rect-Existing.png'
import RebRectExisting from '../../assets/MapSymbols/Icons/REB-Rect-Existing.png'
import DnoRectExisting from '../../assets/MapSymbols/Icons/DNO-Rect-Existing.png'


import AspRectOutstanding from '../../assets/MapSymbols/Icons/ASP-Rect-Outstanding.png'
import PspRectOutstanding from '../../assets/MapSymbols/Icons/PSP-Rect-Outstanding.png'
import LocRectOutstanding from '../../assets/MapSymbols/Icons/LOC-Rect-Outstanding.png'
import RebRectOutstanding from '../../assets/MapSymbols/Icons/REB-Rect-Outstanding.png'
import DnoRectOutstanding from '../../assets/MapSymbols/Icons/DNO-Rect-Outstanding.png'

import AspRectCivilsComplete from '../../assets/MapSymbols/Icons/ASP-Rect-Civils-Complete.png'
import PspRectCivilsComplete from '../../assets/MapSymbols/Icons/PSP-Rect-Civils-Complete.png'
import LocRectCivilsComplete from '../../assets/MapSymbols/Icons/LOC-Rect-Civils-Complete.png'
import RebRectCivilsComplete from '../../assets/MapSymbols/Icons/REB-Rect-Civils-Complete.png'
import DnoRectCivilsComplete from '../../assets/MapSymbols/Icons/DNO-Rect-Civils-Complete.png'

import AspRectComplete from '../../assets/MapSymbols/Icons/ASP-Rect-Complete.png'
import PspRectComplete from '../../assets/MapSymbols/Icons/PSP-Rect-Complete.png'
import LocRectComplete from '../../assets/MapSymbols/Icons/LOC-Rect-Complete.png'
import RebRectComplete from '../../assets/MapSymbols/Icons/REB-Rect-Complete.png'
import DnoRectComplete from '../../assets/MapSymbols/Icons/DNO-Rect-Complete.png'

export const equipmentMapSymbols = {

    AspDotExisting,
    PspDotExisting,
    LocDotExisting,
    RebDotExisting,
    DnoDotExisting,

    AspDotOutstanding,
    PspDotOutstanding,
    LocDotOutstanding,
    RebDotOutstanding,
    DnoDotOutstanding,

    AspDotComplete,
    PspDotComplete,
    LocDotComplete,
    RebDotComplete,
    DnoDotComplete,

    AspRectExisting,
    PspRectExisting,
    LocRectExisting,
    RebRectExisting,
    DnoRectExisting,

    AspRectOutstanding,
    PspRectOutstanding,
    LocRectOutstanding,
    RebRectOutstanding,
    DnoRectOutstanding,

    AspRectCivilsComplete,
    PspRectCivilsComplete,
    LocRectCivilsComplete,
    RebRectCivilsComplete,
    DnoRectCivilsComplete,

    AspRectComplete,
    PspRectComplete,
    LocRectComplete,
    RebRectComplete,
    DnoRectComplete,

}