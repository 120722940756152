import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from "react-router-dom";

import MapBox from '../../pageStyles/MapBox/MapBox';
import SchematicSVGContent from '../../pageStyles/Schematics/SchematicSVGContent';
import TablePage from '../../pageStyles/TablePage/TablePage'
import GraphPage from '../../pageStyles/GraphPage/GraphPage';

import { mapStyles } from '../styles/mapStyles'

import { useDataContext } from '../../Contexts/DataContext';

// ASSET DATA

import { MapGroupedDataCombiner } from '../functions/FactoryFunctions';

import { headerContent } from '../appSetup/headerIcons';
import NoMobileView from '../../pages/NoMobileView';


export default function DisplaySwitcher(props) {

    const { findPathnameData } = useDataContext()
    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get("type");
    const { pathname } = useLocation();

    if (!typeParam) {

        return <Navigate replace to={`${pathname}?type=map`} />

    }

    const schematicToDisplay = <SchematicSVGContent />

    const mapStyle = mapStyles.mapStyle;
    const geoMapstyle = mapStyles.geoMapstyle;

    const [graphRefresh, setGraphRefresh] = useState(0)

    const getMapStyleForType = (type) => {

        switch (type) {

            case "map":
                return mapStyle
                break;
            case "geographic":
                return geoMapstyle;
                break;
            default:
                return mapStyle;
                break;
        }
    }

    const dataForPathname = findPathnameData(pathname)
    const filterData = dataForPathname.data;

    const combinedSideBarData = MapGroupedDataCombiner(filterData)
    const sideBarRightTitle = dataForPathname.listItemtitle

    const schematicSubMenuToggleDisplay = dataForPathname.schematicSubMenuToggleDisplay

    const schematicGroupToggleDisplay = dataForPathname.schematicGroupToggleDisplay

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = headerContent["mobile-breakpoint"]

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    const getDisplayForType = (type) => {
        switch (type) {
            case "dashboard":
                return <GraphPage
                    graphRefresh={graphRefresh}
                    dataForPathname={findPathnameData(pathname)} />
                break;
            case "map":
            case "geographic":
                return width > breakpoint ? <MapBox {...props} mapStyle={getMapStyleForType(type)} pathname={props.currentPath} sideBarRightTitle={sideBarRightTitle}
                    assetData={combinedSideBarData}
                    filteredData={filterData}

                /> : <NoMobileView />
                break;
            case "schematic":
                return width > breakpoint ? <SchematicSVGContent
                    {...schematicGroupToggleDisplay}
                    {...schematicSubMenuToggleDisplay}
                >

                </SchematicSVGContent> : <NoMobileView />
                break;
            case "table":
                return width > breakpoint ? <TablePage
                    features={true}


                /> : <NoMobileView />
                break;
            default:

                break;
        }
    }



    return (
        <>
            {getDisplayForType(typeParam)}
        </>
    )
}
