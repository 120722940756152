import React from 'react'

const LocBaseIcon = () => {
    return (
        <g id="Icons">
            <rect x="4.6674" y="10.019" width="33.6652" height="11.4444" fill="#808e93" opacity=".6" />
            <text transform="translate(13.1942 18.7245)" fill="#fff" font-family="MyriadPro-Bold, &apos;Myriad Pro&apos;" font-size="9.3066" font-weight="700"><tspan x="0" y="0" letter-spacing="-.038em">L</tspan><tspan x="4.4019" y="0">OC</tspan></text>
            <g>
                <circle cx="3.2063" cy="24.9037" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m5.4187,23.7124c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m5.9959,25.672c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857s.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="9.1521" cy="25.3625" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m11.9679,22.9025c.1025,0,.1857-.0831.1857-.1857,0-.1025-.0831-.1856-.1857-.1856s-.1857.0831-.1857.1856c0,.1025.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m3.1241,28.5612c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m2.1494,31.2687c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857s-.1857.0831-.1857.1857c0,.1025.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="5.439" cy="28.9055" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m7.373,28.8745c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="8.4714" cy="27.5595" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m7.2801,31.319c0,.1025.0831.1857.1856.1857s.1857-.0831.1857-.1857-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m11.9834,29.0138c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m11.6894,26.6621c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="13.1128" cy="25.5172" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.2633,22.7478c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m17.0116,25.3316c.1025,0,.1856-.0831.1856-.1857s-.0831-.1856-.1856-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m19.0229,27.3274c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857s.0831.1856.1856.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m15.9596,28.9519c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="16.0693" cy="31.2112" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m13.2211,31.3654c0,.1025.0831.1856.1857.1856s.1857-.0831.1857-.1856-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="21.0008" cy="22.7865" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m23.2389,27.4589c.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857-.1857.0831-.1857.1857.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="23.5381" cy="23.9005" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="26.0469" cy="24.9757" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m26.7199,23.6994c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.2337" cy="22.6023" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m28.7157,26.2521c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="28.7621" cy="28.3872" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="27.9267" cy="31.0328" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m25.6292,29.4547c.1025,0,.1857-.0831.1857-.1856s-.0831-.1857-.1857-.1857-.1857.0831-.1857.1857.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m22.1713,31.2649c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m21.3358,29.5475c.1025,0,.1857-.0831.1857-.1856,0-.1025-.0831-.1857-.1857-.1857s-.1857.0831-.1857.1857c0,.1025.0831.1856.1857.1856Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="18.9687" cy="29.826" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="30.2938" cy="25.3819" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="35.1441" cy="29.3851" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m33.1685,27.0064c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857-.1856.0831-.1856.1857.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="34.1665" cy="28.1435" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="38.718" cy="26.7163" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m30.3634,28.7121c0,.1025.0831.1857.1857.1857.1025,0,.1856-.0831.1856-.1857s-.0831-.1857-.1856-.1857c-.1025,0-.1857.0831-.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="30.2938" cy="31.056" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m35.3994,25.9272c.1025,0,.1856-.0831.1856-.1857s-.0831-.1856-.1856-.1856c-.1025,0-.1857.0831-.1857.1856s.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="33.7052" cy="23.3977" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="32.6841" cy="23.0031" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m29.76,27.5749c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m39.9563,27.514c.1025,0,.1857-.0831.1857-.1857s-.0831-.1856-.1857-.1856-.1857.0831-.1857.1856.0831.1857.1857.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <circle cx="38.4163" cy="28.9674" r=".1857" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m38.3258,31.888c.1025,0,.1857-.0831.1857-.1857s-.0831-.1857-.1857-.1857c-.1025,0-.1856.0831-.1856.1857s.0831.1857.1856.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
                <path d="m33.7517,30.9863c-.1025,0-.1857.0831-.1857.1857s.0831.1857.1857.1857.1856-.0831.1856-.1857-.0831-.1857-.1856-.1857Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".25" />
            </g>
            <path d="m42.3714,33.1352H.6282v-12.0923h41.7432v12.0923Zm-40.9932-.75h40.2432v-10.5923H1.3782v10.5923Z" fill="#fff" />
        </g>
    )
}


export default LocBaseIcon;