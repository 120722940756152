import React from 'react'

const SignalTpwsIcon = () => {
    return (
        <g id="Icons">
            <g>
                <path d="M13,6.55V36.38h17.1V6.55Zm7.78,28.76H14.62V29.2h6.11Zm0-7.07H14.62V22.13h6.11Zm0-7.08H14.62V15.05h6.11Zm0-7.07H14.62V8h6.11Zm7.73,21.22h-6.1V29.2h6.1Zm0-7.07h-6.1V22.13h6.1Zm0-7.08h-6.1V15.05h6.1Zm0-7.07h-6.1V8h6.1Z" fill="#0f516e" />
                <rect x="13.23" y="5.3" width="0.68" height="32.48" fill="#0f516e" />
                <rect x="29.01" y="5.23" width="0.68" height="32.48" fill="#0f516e" />
            </g>
        </g>
    )
}

export default SignalTpwsIcon
