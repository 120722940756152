import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../Contexts/AuthContext'

import { DataContext } from '../../Contexts/DataContext';
import { useNavigate, useParams } from 'react-router-dom'

import { db } from '../../firebase'

import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'


import { addUpdateLog } from '../../constants/databaseFunctions/UpdateLog'

import DbCard from '../../components/cards/DbCard'

import './form.css'

import FormMap from '../../components/FormMap/FormMap';

import { utxMapSymbols } from '../../constants/MapSymbols/UtxMapSymbols'

const UtxForm = () => {

    const params = useParams();
    const navigate = useNavigate()
    const selectedItemId = params.id
    const userData = useContext(UserContext)
    const data = useContext(DataContext)

    const selectCurrentItem = (dataSet) => {
        let output = [];
        dataSet.features.forEach((item, index) => {

            if (item.id === selectedItemId) {
                output.push(item)
            }
        })
        return output
    }

    const [formDirect, setFormDirect] = useState('/app/civils-works/routeworks?type=table')



    const currentItemData = selectCurrentItem(data.UTXProposedCentresData)[0]
    const itemName = currentItemData ? currentItemData.properties.Name : ""

    const lng = selectedItemId ? currentItemData.geometry.coordinates[0] : -0.6832668084
    const lat = selectedItemId ? currentItemData.geometry.coordinates[1] : 51.1020658558

    const zoom = selectedItemId ? 16 : 10

    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`

    let defaultFormData = { properties: { textOffset: [0, 0] }, geometry: { coordinates: [0, 0] }, type: "Point" }


    // IE CREATE 
    if (!selectedItemId || selectedItemId === undefined) {
        defaultFormData["dateCreated"] = new Date()
        defaultFormData["createdBy"] = logInUser
        defaultFormData["geometry"]["coordinates"][0] = -0.8457
        defaultFormData["geometry"]["coordinates"][1] = 51.4086
        defaultFormData["properties"]["rotation"] = 0
        defaultFormData["properties"]["textOffset"][0] = 0
        defaultFormData["properties"]["textOffset"][1] = 0
        defaultFormData["type"] = "Feature"
        defaultFormData["geometry"]["type"] = "Point"
        defaultFormData["properties"]["Name"] = "Crossing Name"
        defaultFormData["properties"]["location"] = ""
        defaultFormData["properties"]["Base"] = "Outstanding"
        defaultFormData["properties"]["Base"] = "Outstanding"
        defaultFormData["properties"]["Structure"] = "Outstanding"
        defaultFormData["properties"]["description"] = "Under Track Crossing"
        defaultFormData["properties"]["type"] = "Under Track Crossing"

    }
    // IE UPDATE
    if (selectedItemId) {
        defaultFormData["dateUpdated"] = new Date()
        defaultFormData["updatedBy"] = logInUser
    }

    const [formData, setFormData] = useState(defaultFormData);


    const handleFormPropertiesInputChange = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            properties: { ...formData.properties, [name]: value }
        }))

    }

    const handleRotationNumberInputChange = (e) => {
        const { name, value } = e.target
        let updatedRotation = Number(value)
        setFormData((curr) => ({
            ...curr,
            properties: {
                ...curr.properties,
                "rotation": updatedRotation
            }
        }))
    }

    const handleLongLatInputChange = ({ target }) => {
        const { name, value } = target;
        const indexForLongLat = {
            "long": 0,
            "lat": 1
        }
        let updatedLongLat = formData.geometry.coordinates;
        updatedLongLat[indexForLongLat[name]] = Number(value);
        setFormData((curr) => ({
            ...curr,
            geometry: {
                ...curr.geometry,
                coordinates: updatedLongLat
            }
        }))
    }

    const handleTextOffsetInputChange = ({ target }) => {
        const { name, value } = target;
        const indexForTextOffset = {
            "offsetx": 0,
            "offsety": 1
        }
        let updatedTextOffset = formData.properties["textOffset"];
        updatedTextOffset[indexForTextOffset[name]] = Number(value);
        setFormData((curr) => ({
            ...curr,
            properties: {
                ...curr.properties,
                "textOffset": updatedTextOffset
            }
        }))
    }

    const handleLngLatMapBox = (lngLat) => {
        setFormData((curr) => ({
            ...curr,
            geometry: {
                ...curr.geometry,
                coordinates: [lngLat.lng, lngLat.lat]
            }
        }))
    }



    useEffect(() => {
        if (params.id) {
            const docRef = doc(db, 'UTXProposedCentres', selectedItemId)
            getDoc(docRef).then(snapshot => {
                setFormData({ ...snapshot.data(), id: snapshot.id, dateUpdated: defaultFormData.dateUpdated, updatedBy: logInUser })
                setLoadingData(false)
            })
        }
    }, [])



    const statusOptions = ["Outstanding", "Complete", "Existing"]
    const type = ["Under Track Crossing", "Under Road Crossing", "Over Track Crossing"]

    const [loadingData, setLoadingData] = useState(selectedItemId ? true : false)


    const updateDataOnFireBase = async () => {
        setLoadingData(true)
        const collectionRef = doc(db, "UTXProposedCentres", selectedItemId)
        await setDoc(collectionRef, formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "UTx Details updated",
            update: `${userName} updated`
        })
        localStorage.removeItem("ProjectDetails")
        setLoadingData(false)
        navigate('/app/civils-works/routeworks?type=table')
    }

    const CreateDataEntryToFireBase = async () => {
        setLoadingData(true)
        await addDoc(collection(db, "UTXProposedCentres"), formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "New Equipment Asset",
            update: `${userName} added ${formData.properties.Name}`,
        })
        setLoadingData(false)
        navigate(formDirect)
    }

    let cardTitle = selectedItemId ? `Edit ${itemName}` : "Add Asset"
    let mainBtnTitle = selectedItemId ? "Update" : "Add"
    let btnFunction = selectedItemId ? updateDataOnFireBase : CreateDataEntryToFireBase


    const getMapIcon = (key) => {
        switch (key) {
            case "Under Track Crossing": return utxMapSymbols.UTXImageCentreOutstanding
                break;
            case "Under Road Crossing": return utxMapSymbols.URXImageCentreOutstanding
                break;
            case "Over Track Crossing": return utxMapSymbols.OTXImageCentreOutstanding
                break;

            default:
                return ""
                break;
        }
    }
    const mapIcon = getMapIcon(formData.properties.type)
    return (
        <>

            <div className="dashboard-container">
                <DbCard
                    cardType={'graph-card-wide card-height-min card-static'}
                    icon={""}
                    title={`${cardTitle}`}
                    cardContent={""}
                    btnText={"Go back"}
                    mainBtnText={`${mainBtnTitle} Asset`}
                    loadingData={loadingData}
                    btnDisplayBool={true}
                    btnMainDisplayBool={true}
                    OnClickFuncMainBtn={btnFunction}
                    OnClickFunc={() => navigate(-1)}>

                    <>
                        <div className='form-sub-title'>Asset Details</div>
                        <div className='form-boxes-container'>
                            {/* GENERAL SECTION */}
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Equipment Name</p>
                                    <input className='input-box' type="text" value={formData["properties"]["Name"]} name={'Name'} placeholder={"Edit Crossing ID..."} onChange={handleFormPropertiesInputChange} /><br />
                                </label>
                                <label className='label-container'>
                                    <p className='label-title'>Location Name</p>
                                    <input className='input-box' type="text" value={formData["properties"]["location"]} name={'location'} placeholder={"Edit location name..."} onChange={handleFormPropertiesInputChange} /><br />
                                </label>
                            </div>

                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Type</p>
                                    <select className='input-box' name={"type"} value={formData["properties"]["type"]} onChange={handleFormPropertiesInputChange}>
                                        {type.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                        </div>
                        <div className='form-sub-title'>Installation status</div>
                        <div className='form-boxes-container'>

                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Chambers Status</p>
                                    <select className='input-box' name={"Base"} value={formData["properties"]["Base"]} onChange={handleFormPropertiesInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>



                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Crossing Status</p>
                                    <select className='input-box' name={"Structure"} value={formData["properties"]["Structure"]} onChange={handleFormPropertiesInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>

                        </div>

                        <div className='form-sub-title'>Asset Location</div>
                        <div className='form-boxes-container'>

                            <div class="input-container">
                                {loadingData ? "" : <FormMap lng={lng} lat={lat} zoom={zoom} handleLngLatMapBox={handleLngLatMapBox} mapIcon={mapIcon}></FormMap>}
                                <div className='form-group-column-container'>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Longitude</p>
                                        <input className='input-box' type="number" value={formData["geometry"]["coordinates"][0]} name={'long'} placeholder={"Edit Longitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Latitude</p>
                                        <input className='input-box' type="number" value={formData["geometry"]["coordinates"][1]} name={'lat'} placeholder={"Enter latitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label>


                                    {/* <label className='label-container'>
                                        <p className='label-title label-pad-left'>Equipment degrees rotation on map</p>
                                        <input className='input-box' type="number" value={parseInt(formData["properties"]["rotation"])} name={'rotation'} placeholder={"Input a rotation..."} onChange={handleRotationNumberInputChange} /><br />
                                    </label> */}
                                </div>

                            </div>

                        </div>
                    </>
                </DbCard>
            </div >
        </>
    )
}

export default UtxForm