import React from 'react'

const CableGeneralIcon = () => {
    return (
        <g id="Icons">
            <g>
                <rect x="20.0188" y="7.0304" width="2.2391" height="23.147" fill="#ffffff" />
                <path d="M18.1459,31.778l-2.132-.6822c.8014-2.5026,2.3299-9.043-.7877-15.6981-1.2327-2.6317-3.0187-4.9528-5.3087-6.8989l1.4498-1.7056c2.536,2.1539,4.5171,4.7298,5.8871,7.6555,3.4533,7.3734,1.7739,14.5752,.8916,17.3293Z" fill="#ffffff" />
                <path d="M24.8169,31.778c-.8823-2.7541-2.5617-9.9559,.8916-17.3293,1.3699-2.9258,3.3505-5.5017,5.8871-7.6566l1.4498,1.7078c-2.29,1.9439-4.0759,4.2651-5.3087,6.8978-3.1176,6.6551-1.5892,13.1954-.7877,15.6981l-2.132,.6822Z" fill="#ffffff" />
                <path d="M8.8378,9.439c-1.8751,0-3.4008-1.5263-3.4008-3.4014s1.5257-3.4003,3.4008-3.4003,3.4008,1.5252,3.4008,3.4003-1.5257,3.4014-3.4008,3.4014Zm0-5.6821c-1.2579,0-2.2812,1.0234-2.2812,2.2807,0,1.2584,1.0234,2.2818,2.2812,2.2818s2.2812-1.0234,2.2812-2.2818c0-1.2573-1.0234-2.2807-2.2812-2.2807Z" fill="#ffffff" />
                <path d="M34.3426,9.439c-1.8751,0-3.4008-1.5263-3.4008-3.4014s1.5257-3.4003,3.4008-3.4003,3.4003,1.5252,3.4003,3.4003-1.5252,3.4014-3.4003,3.4014Zm0-5.6821c-1.2579,0-2.2812,1.0234-2.2812,2.2807,0,1.2584,1.0234,2.2818,2.2812,2.2818s2.2807-1.0234,2.2807-2.2818c0-1.2573-1.0234-2.2807-2.2807-2.2807Z" fill="#ffffff" />
                <path d="M21.1384,8.2057c-1.8751,0-3.4008-1.5252-3.4008-3.4003s1.5257-3.4014,3.4008-3.4014,3.4008,1.5263,3.4008,3.4014-1.5257,3.4003-3.4008,3.4003Zm0-5.6821c-1.2579,0-2.2812,1.0234-2.2812,2.2818,0,1.2573,1.0234,2.2807,2.2812,2.2807s2.2812-1.0234,2.2812-2.2807c0-1.2584-1.0234-2.2818-2.2812-2.2818Z" fill="#ffffff" />
                <rect x="12.6483" y="23.8798" width="16.747" height="17.9132" fill="#ffffff" />
            </g>
        </g>
    )
}
export default CableGeneralIcon