
import SignalBoxData from '../../../../data/FixedLocationsGeoData/SignalBoxes.json'

import SignalBoxImage from '../../../../assets/MapSymbols/Icons/SignalBox.png'


export const SignalBoxes = (map) => {

    // Station- Pointer
    map.on('load', () => {
        map.addSource('signalbox', {
            'type': 'geojson',
            'data': SignalBoxData,
            buffer: 0,
        });
        map.loadImage(
            SignalBoxImage,
            (error, image) => {
                if (error) throw error;
                map.addImage('signalbox', image);
                map.addLayer({
                    id: 'signalbox',
                    type: "symbol",
                    source: 'signalbox',
                    "minzoom": 9,
                    "maxzoom": 18,

                    // "maxzoom": 14.5,
                    layout: {
                        "icon-image": 'signalbox',
                        "icon-size": 0.25,
                        "text-field": ['get', 'name'],
                        // 'icon-rotate': ['get', 'rotation'],
                        'text-offset': ['get', 'text-offset'],

                        'text-anchor': 'top',
                        "icon-allow-overlap": true,
                        "text-allow-overlap": true,
                        visibility: "visible",
                    }, paint: {
                        'text-color': '#142127',
                        // 'text-color': '#142127',
                        "text-halo-color": "#fff",
                        "text-halo-width": 2
                    }
                });

            });
    })



}

