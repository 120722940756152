import React from 'react'

const FBIconSummary = ({ fill, onClick }) => {
    return (
        <div className='float-icon-container' onClick={onClick}>
            <svg id="new_icons" data-name="new icons" xmlns="http://www.w3.org/2000/svg" width="22mm" height="22mm" viewBox="0 0 62.3622 62.3622">
                <path d="m53.2233,32.8848v26.6236h-16.9487v-11.4117c0-.8143-.666-1.4805-1.4802-1.4805h-7.2265c-.8143,0-1.4805.6662-1.4805,1.4805v11.4117H9.1387v-26.6236L31.181,12.7109l22.0423,20.1738Z" fill={fill} fillRule="evenodd" />
                <polygon points=".0802 31.3176 31.181 2.8539 47.3332 17.6365 47.3332 14.8352 53.0443 14.8352 53.0443 22.8633 62.282 31.3176 58.8723 35.0439 31.181 9.7004 3.4896 35.0439 .0802 31.3176" fill={fill} />
            </svg></div>
    )
}

export default FBIconSummary