import React from 'react';

import './PieChart.css';

const radius = 39.23;
const circumference = Math.PI * (radius * 2);

const PieChart = ({
    itemNameText,
    PieIcon,
    qtyComplete,
    qtyTotal,
    percentageValue,
    textHide,
    styleChange
}) => {
    return (
        <div className={`pie-chart-container${styleChange}`}>
            <svg
                id='graph-container'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 98.88 100'
            >
                <g id='pie-container'>

                    {percentageValue === 0 ? (<g id='pink-line'>
                        <circle
                            cx='50.22'
                            cy='49.52'
                            r='39.23'
                            fill='none'
                            stroke='#ffffff99'
                            strokeLinecap='round'
                            strokeMiterlimit='10'
                            strokeWidth='11'
                            opacity='0.1'

                        />
                    </g>) : (<g id='grey-line'>
                        <circle
                            cx='50.22'
                            cy='49.52'
                            r='39.23'
                            fill='none'

                            stroke='#ffffff99'
                            strokeLinecap='round'
                            strokeMiterlimit='10'
                            strokeWidth='11'
                            opacity='0.1'

                        />
                    </g>)
                    }



                    {/* COMPONENTS THAT IS MANIPULATED */}
                    {percentageValue > 0 &&
                        <circle
                            id='bar'
                            r='39.23'
                            cx='50.22'
                            cy='49.52'
                            fill='transparent'
                            stroke-dasharray='246.5'
                            strokeLinecap='round'
                            stroke='#17bebb'
                            strokeWidth='11'
                            style={{
                                'strokeWidth': '11',
                                'stroke-dashoffset': '0',
                                'transform-box': 'fill-box',
                                'transform-origin': 'center',
                                transform: 'rotate(-90deg)',
                                strokeDashoffset: ((100 - percentageValue) / 100) * circumference,
                            }}
                        ></circle>

                    }

                    <g className='icon-container'>{<PieIcon />}</g>
                </g>
            </svg>
            <div className="pie-text-container">
                <h4 className={`pie-item-name-text ${textHide}`}>{itemNameText}</h4>
                <h4 className={`pie-item-qty-text ${textHide}`}>{`${qtyComplete}/${qtyTotal}`}</h4>
            </div>
        </div>
    );
};

export default PieChart;
