import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useDataContext } from '../../Contexts/DataContext';

// REACT LIBRARY
import { useResizeDetector } from 'react-resize-detector';

// MAPBOX LIBRARY
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

// import classes from "./Map.module.css";
import "mapbox-gl/dist/mapbox-gl.css";

//JSON DATA FILES ###################################

import { routesStructure } from '../../constants/appSetup/routesStructure';
import { addTableData } from '../../constants/functions/addTableData';

// COMPONENT TO DISPLAY ON MAP
import { TrackAccessPoints } from './MapComponents/Updated/TrackAccessPoints';
import { StationPointers } from './MapComponents/Updated/StationPointers'
import { StationPlatforms } from './MapComponents/Updated/StationPlatforms'
import { SignalBoxes } from './MapComponents/Updated/SignalBox'
import { LevelCrossingComponents } from './MapComponents/Updated/LevelCrossingComponents';
import { SignalCivilsComps } from './MapComponents/CivilsComponents/SignalCivilsComps';
import { EquipCubiclesCivilsComps } from './MapComponents/CivilsComponents/EquipCubiclesCivilsComps'
import { UtxNewComponent } from './MapComponents/CivilsComponents/UTXNewComponent'
import { UTXExistingComponent } from './MapComponents/Updated/UTXExistingComponent';
import { PowerExistingCable } from './MapComponents/Updated/PowerExistingCable';
import { PowerNewCable } from './MapComponents/Updated/PowerNewCable';
import { SignallingComps } from './MapComponents/SignallingComponents/SignallingComps';
import { EquipCubiclesComps } from './MapComponents/SignallingComponents/EquipCubiclesComps'

// ACCESS TEMPLATES
import TemplateBlank from '../../data/AccessTemplates/TemplateBlank.json'
import { mapTemplates } from './MapFiles/mapTemplates';

// COMPONENTS
import SideBarRight from '../../components/SideBarRight/SideBarRight'
import { defaultFlyToCoord, mapStyles } from '../../constants/styles/mapStyles'
import './Map.css'
import { FibreNewCable } from './MapComponents/Updated/FibreNewCable';


import { EquipCivilsDots } from './MapComponents/Final/EquipCivilDots';
import { EquipSignalsDots } from './MapComponents/Final/EquipSignalsDots';

mapboxgl.accessToken = mapStyles.accessToken;

var map = undefined;
export const getCoordinatesFromGeometry = (geometry) => {
    if (geometry.coordinates.length === 0) {

        return defaultFlyToCoord;
    }
    const getItemTypesOfArray = (array) => {
        let types = [];
        array.forEach(item => {
            const itemType = Array.isArray(item) ? "array" : typeof item
            if (!types.includes(itemType))
                types.push(itemType)
        })
        return types;
    }
    const coordsType = getItemTypesOfArray(geometry.coordinates)[0];
    switch (coordsType) {
        case "number":
            return geometry.coordinates
            break;
        case "array":
            const coordArray = [...geometry.coordinates];
            let midPoint = coordArray[Math.round((coordArray.length - 1) / 2)]

            return midPoint;
        default:
            break;
    }
}
const MapBox = props => {
    const data = useDataContext();
    const { pathname } = props;
    const { width, ref } = useResizeDetector();
    useEffect(() => {
        if (map && map.loaded() && map.isStyleLoaded())
            map.resize()
    }, [width])

    // Controls visibility of the map on the main layout
    const mapDisplayToggle = true;
    const mapContainer = useRef(null);
    const [mapIsLoaded, setMapIsLoaded] = useState(false);
    const [mapStyleChangeIncrement, setMapStyleChangeIncrement] = useState(0);
    const [accessTemplate, setAccessTemplate] = useState("");
    const [accessDetails, setAccessDetails] = useState({});
    const lngDefault = -0.7285
    const latDefault = 51.0965
    const zoomDefault = 10.16
    const [lng, setLng] = useState(-0.7285);
    const [lat, setLat] = useState(51.0965);
    const [zoom, setZoom] = useState(10.45
    );

    useEffect(() => {
        if (mapIsLoaded) {
            if (map.isStyleLoaded()) {
                map.getStyle().layers.filter((layer) => layer.metadata && layer.metadata.pathLayer).forEach((layer) => {
                    if (layer.metadata.relatedPathNames.includes(pathname)) {
                        map.setLayoutProperty(layer.id, "visibility", "visible");
                    } else {
                        map.setLayoutProperty(layer.id, "visibility", "none");
                    }
                })
            }
        }

    }, [pathname, mapIsLoaded, mapStyleChangeIncrement]
    )

    useEffect(() => {
        // Initialize map when component mounts
        initializeMap()
        // Clean up on unmount
        return () => map.remove();
    }, [props.mapStyle, data]);

    useEffect(() => {
    }, [accessDetails]);

    const initializeMap = () => {
        map = new mapboxgl.Map({
            container: mapContainer.current,
            style: props.mapStyle,
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false,
            minZoom: 10.0,
            maxZoom: 18,
            // maxBounds: bounds // Set the map's geographical boundaries.
        });

        // Controls
        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

        // disable map rotation using right click + drag
        map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        map.touchZoomRotate.disableRotation();

        // ICON POSITIONING LAYERS
        const displayMapElements = () => {

            // ****************EXISTING******************************

            StationPlatforms(map)
            StationPointers(map)
            SignalBoxes(map, true, createPopUp, pathname)
            LevelCrossingComponents(map, true, createPopUp, pathname, routesStructure, addTableData)
            // UTXExistingComponent(map, createPopUp)
            // PowerExistingCable(map, createPopUp)
            TrackAccessPoints(map, true, createPopUp, pathname, routesStructure, addTableData)

            SignalCivilsComps(map, true, createPopUp, pathname, data.SignalCivilsData, routesStructure)

            UtxNewComponent(map, true, createPopUp, pathname, routesStructure, addTableData, data.UTXProposedCentresData)
            PowerNewCable(map, true, pathname, data.PowerCablesData, routesStructure)
            FibreNewCable(map, true, pathname, data.FibreCablesData, routesStructure)

            EquipSignalsDots(map, true, createPopUp, pathname, data.EquipmentCubicleSignallingData, routesStructure)

            EquipCivilsDots(map, true, createPopUp, pathname, data.EquipmentCubicleCivilsData, routesStructure)

            SignallingComps(map, true, createPopUp, pathname, data.SignallingData, routesStructure)

        }

        displayMapElements()

        //ACCESS AREA STYLING
        map.on('load', () => {
            map.addSource('lines', {
                'type': 'geojson',
                'data': TemplateBlank,
            });
            map.addLayer({
                'id': 'lines',
                'type': 'line',
                'source': 'lines',
                layout: { visibility: "visible" },
                'paint': {
                    'line-width': 20,
                    'line-color': 'yellow',
                    // 'line-cap': 'round',
                    // 'line-join': 'round',
                    'line-opacity': 0.5
                }
            });
            setMapIsLoaded(true);
            const layerTest = map.getStyle()
        });

        map.addControl(new mapboxgl.NavigationControl(), "top-left");

        // **************KEEP FOR MAP BUILD********************
        // GIVES COORDINATES OF MOUSE
        // map.on('mousemove', (e) => {
        //     document.getElementById('info').innerHTML =
        //         // `e.point` is the x, y coordinates of the `mousemove` event
        //         // relative to the top-left corner of the map.
        //         JSON.stringify(e.point) +
        //         '<br />' +
        //         // `e.lngLat` is the longitude, latitude geographical position of the event.
        //         JSON.stringify(e.lngLat.wrap());
        // });
        // **************KEEP FOR MAP BUILD********************
    }

    // FUNCTION FOR ACCESS TEMPLATE DISPLAY
    useEffect(() => {
        if (accessTemplate == "" && map.getSource('lines')) {
            map.getSource("lines").setData(TemplateBlank)
            map.flyTo({
                center: [lngDefault, latDefault],
                zoom: zoomDefault
            })
            return;
        }
        if (mapTemplates[accessTemplate]) {
            const template = mapTemplates[accessTemplate];
            map.getSource("lines").setData(template["template"])
            map.flyTo({
                center: [template.long, template.lat],
                zoom: template.zoom
            })

        }


    }, [accessTemplate])


    function flyToSignal(currentFeature) {
        map.flyTo({
            center: getCoordinatesFromGeometry(currentFeature.geometry),
            zoom: 17
        });
    }

    function createPopUp(currentFeature, propertyData, tableData) {
        const base = currentFeature.properties.Structure;
        let tableDataKeys = [];
        let baseDefault = ''
        if (tableData !== undefined) {
            tableDataKeys = Object.keys(tableData);

        } else {
            baseDefault = `<h5>${propertyData ? `${propertyData.name}: ${propertyData.value}` : `Base: ${base}`}</h5>`
        }

        const popUps = document.getElementsByClassName('mapboxgl-popup');
        if (popUps[0]) popUps[0].remove();
        const popup = new mapboxgl.Popup({ closeOnClick: true })
            .setLngLat(getCoordinatesFromGeometry(currentFeature.geometry))
            .setHTML(`<h3>${currentFeature.properties.Name}</h3>
            <h4>${currentFeature.properties.type}</h4>
                ${baseDefault}
                ${tableDataKeys.map((key) => `<h5><b>${key}</b>: ${tableData[key]}</h5>`).join("")}
                `
            )
            .addTo(map);
    }


    return (
        <>
            {mapDisplayToggle && (
                <div className="map-main-container" ref={ref}>

                    {/* KEEP FOR MAP BUILD******************** */}
                    {/* <pre id="info"></pre> */}

                    <div className="coord-bar">
                        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                    </div>

                    <div ref={mapContainer} className="map-container" />


                </div>)}
            <SideBarRight
                sidebarRightDisplayToggle
                fixMapSize={() => map.resize()} assetData={props.assetData} flyToSignal={flyToSignal} createPopUp={createPopUp}
                sideBarRightTitle={props.sideBarRightTitle}
                filteredData={props.filteredData} />
        </>
    );
}

export default MapBox
