import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../Contexts/AuthContext'

import { DataContext } from '../../Contexts/DataContext';
import { useNavigate, useParams } from 'react-router-dom'

import { db } from '../../firebase'

import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'

import { addUpdateLog } from '../../constants/databaseFunctions/UpdateLog'

import DbCard from '../../components/cards/DbCard'

import { signalMapSymbols } from '../../constants/MapSymbols/SignalMapSymbols';

import './form.css'

import FormMap from '../../components/FormMap/FormMap';
import { defaultFlyToCoord } from '../../constants/styles/mapStyles';

const SignalForm = () => {

    const params = useParams();
    const navigate = useNavigate()
    const selectedItemId = params.id
    const userData = useContext(UserContext)
    const data = useContext(DataContext)
    const [loading, setLoading] = useState(params.id ? true : false)

    const [formDirect, setFormDirect] = useState("/app/project-summary")

    useEffect(() => {
        const latestURL = window.location.href
        const urlArray = latestURL.split(/[/]/);
        const item = urlArray.length
        if (urlArray[item - 1] === "civils" || urlArray[item - 2] === "civils") {
            setFormDirect('/app/civils-works/signals?type=table')
        } else if (urlArray[item - 1] === "signalling" || urlArray[item - 2] === "signalling") {
            setFormDirect('/app/signalling-works/signals?type=table')
        }
    }, [])

    const selectCurrentItem = (dataSet) => {
        let output = [];
        dataSet.features.forEach((item, index) => {

            if (item.id === selectedItemId) {
                output.push(item)
            }
        })
        return output
    }

    const currentItemData = selectCurrentItem(data.SignalCivilsData)[0]

    const lng = selectedItemId ? currentItemData.geometry.coordinates[0] : defaultFlyToCoord[0]
    const lat = selectedItemId ? currentItemData.geometry.coordinates[1] : defaultFlyToCoord[1]
    const zoom = selectedItemId ? 16 : 10


    const itemName = currentItemData ? currentItemData.properties.Name : ""


    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`

    let defaultFormData = { properties: { textOffset: [0, 0] }, geometry: { coordinates: [0, 0] }, type: "Point" }

    // IE CREATE 
    if (!selectedItemId || selectedItemId === undefined) {
        defaultFormData["dateCreated"] = new Date()
        defaultFormData["createdBy"] = logInUser
        defaultFormData["geometry"]["coordinates"][0] = defaultFlyToCoord[0]
        defaultFormData["geometry"]["coordinates"][1] = defaultFlyToCoord[1]
        defaultFormData["properties"]["rotation"] = 0
        defaultFormData["properties"]["textOffset"][0] = 0
        defaultFormData["properties"]["textOffset"][1] = 0
        defaultFormData["type"] = "Feature"
        defaultFormData["geometry"]["type"] = "Point"
        defaultFormData["properties"]["Name"] = ""
        defaultFormData["properties"]["Base"] = "Outstanding"
        defaultFormData["properties"]["Structure"] = "Outstanding"
        defaultFormData["properties"]["Signal Head"] = "Outstanding"
        defaultFormData["properties"]["structure type"] = "Post"
        defaultFormData["properties"]["signal head type"] = "Signal Head"
        defaultFormData["properties"]["type"] = defaultFormData["properties"]["signal head type"]
        defaultFormData["properties"]["aspect no"] = 2
        defaultFormData["properties"]["location"] = "Feltham Phase 5"
        defaultFormData["properties"]["Base_Actual"] = new Date()
        defaultFormData["properties"]["Base_Planned"] = new Date()
        defaultFormData["properties"]["Structure_Actual"] = new Date()
        defaultFormData["properties"]["Structure_Planned"] = new Date()
        defaultFormData["properties"]["Signal Head_Actual"] = new Date()
        defaultFormData["properties"]["Signal Head_Planned"] = new Date()
        defaultFormData["properties"]["Design"] = "Outstanding"
        defaultFormData["properties"]["Design_Planned"] = new Date()
        defaultFormData["properties"]["Design_Actual"] = new Date()
    }

    // IE UPDATE
    if (selectedItemId) {
        defaultFormData["dateUpdated"] = new Date()
        defaultFormData["updatedBy"] = logInUser
    }

    const [formData, setFormData] = useState(defaultFormData);

    const handleFormPropertiesInputChange = (e) => {
        const { name, value } = e.target
        let updatedProperties = { ...formData.properties, [name]: value };
        if (name == "signal head type" && value != "Signal Head") {
            updatedProperties["aspect no"] = ""
        } else {
            updatedProperties["aspect no"] = aspectQty[0]
        }
        setFormData((curr) => ({
            ...curr,
            properties: updatedProperties
        }))
    }


    const handleRotationNumberInputChange = (e) => {
        const { name, value } = e.target
        let updatedRotation = Number(value)
        setFormData((curr) => ({
            ...curr,
            properties: {
                ...curr.properties,
                "rotation": updatedRotation
            }
        }))
    }

    const installInputChange = (e) => {
        const { name, value } = e.target
        let updatedProperties = { ...formData.properties, [name]: value };
        let orderedKeyNames = ["Base", "Structure", "Signal Head"]
        const selectedKeyIndex = orderedKeyNames.indexOf(name)
        if (value == "Complete") {
            orderedKeyNames.splice(selectedKeyIndex + 1, orderedKeyNames.length - 1);
            if (orderedKeyNames.some((keyName) => updatedProperties[keyName] === "Outstanding")) {
                orderedKeyNames.forEach((keyName) => {
                    updatedProperties[keyName] = value;
                })
            }

        }
        setFormData((curr) => ({
            ...curr,
            properties: updatedProperties
        }))
    }

    const handleLongLatInputChange = ({ target }) => {
        const { name, value } = target;
        const indexForLongLat = {
            "long": 0,
            "lat": 1
        }
        let updatedLongLat = formData.geometry.coordinates;
        updatedLongLat[indexForLongLat[name]] = Number(value);
        setFormData((curr) => ({
            ...curr,
            geometry: {
                ...curr.geometry,
                coordinates: updatedLongLat
            }
        }))
    }

    const handleTextOffsetInputChange = ({ target }) => {
        const { name, value } = target;
        const indexForTextOffset = {
            "offsetx": 0,
            "offsety": 1
        }
        let updatedTextOffset = formData.properties["textOffset"];
        updatedTextOffset[indexForTextOffset[name]] = Number(value);
        setFormData((curr) => ({
            ...curr,
            properties: {
                ...curr.properties,
                "textOffset": updatedTextOffset
            }
        }))
    }

    const handleLngLatMapBox = (lngLat) => {
        setFormData((curr) => ({
            ...curr,
            geometry: {
                ...curr.geometry,
                coordinates: [lngLat.lng, lngLat.lat]
            }
        }))
    }

    useEffect(() => {
        if (params.id) {
            const docRef = doc(db, 'Signals', selectedItemId)
            getDoc(docRef).then(snapshot => {
                setFormData({ ...snapshot.data(), id: snapshot.id, dateUpdated: defaultFormData.dateUpdated, updatedBy: logInUser })
                setLoading(false);
            })
        }
    }, [])

    const [loadingData, setLoadingData] = useState(false)

    const updateDataOnFireBase = async () => {
        setLoadingData(true)
        const collectionRef = doc(db, "Signals", selectedItemId)
        await setDoc(collectionRef, formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "Signal updated",
            update: `${userName} updated ${formData.properties.Name}`
        })
        setLoadingData(false)
        navigate(formDirect)
        // window.location.reload(false);
    }

    const CreateDataEntryToFireBase = async () => {
        setLoadingData(true)
        await addDoc(collection(db, "Signals"), formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "New Signal Asset",
            update: `${userName} added ${formData.properties.Name}`,
        })
        setLoadingData(false)
        navigate(formDirect)
        // window.location.reload(false);

    }

    const getMapIcon = (key) => {
        switch (key) {
            case "Signal Head": return signalMapSymbols.Signal4AOutstanding
                break;
            case "Banner Repeater": return signalMapSymbols.SignalBannerOutstanding
                break;
            case "Ground Signal": return signalMapSymbols.SignalGroundOutstanding
                break;
            case "Off Indicator": return signalMapSymbols.SignalOffOutstanding
                break;
            default:
                return ""
                break;
        }
    }
    let cardTitle = selectedItemId ? `Edit ${itemName}` : "Add Asset"
    let mainBtnTitle = selectedItemId ? "Update" : "Add"
    let btnFunction = selectedItemId ? updateDataOnFireBase : CreateDataEntryToFireBase

    const statusOptions = ["Outstanding", "Complete", "Existing"]
    const type = ["Signal Post", "Signal Post Existing", "Banner Repeater", "Off Indicator", "Ground Signal",]
    const structureType = ["Post", "Post Existing", "Signal Structure Existing"]

    const signalType = ["Signal Head", "Banner Repeater", "Ground Signal", "Off Indicator"]
    const aspectQty = [2, 3, 4]
    const signalIcon = getMapIcon(formData.properties["signal head type"])
    return (
        <>
            <div className="dashboard-container">
                <DbCard
                    cardType={'graph-card-wide card-height-min card-static'}
                    icon={""}
                    title={`${cardTitle}`}
                    cardContent={""}
                    btnText={"Go back"}
                    mainBtnText={`${mainBtnTitle} Asset`}
                    btnDisplayBool={true}
                    btnMainDisplayBool={true}
                    OnClickFuncMainBtn={btnFunction}
                    loadingData={loadingData}
                    OnClickFunc={() => navigate(-1)}>

                    <>
                        <div className='form-sub-title'>Asset Details</div>
                        <div className='form-boxes-container'>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Signal Number</p>
                                    <input className='input-box' type="text" value={formData["properties"]["Name"]} name={'Name'} placeholder={"Add Signal ID..."} onChange={handleFormPropertiesInputChange} /><br />
                                </label>
                            </div>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Structure type</p>
                                    <select className='input-box' name={"structure type"} value={formData["properties"]["structure type"]} onChange={handleFormPropertiesInputChange}>
                                        {structureType.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Signal Head type</p>
                                    <select className='input-box' name={"signal head type"} value={formData["properties"]["signal head type"]} onChange={handleFormPropertiesInputChange}>
                                        {signalType.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Signal Aspect Qty</p>
                                    <select className='input-box' name={"aspect no"} value={formData["properties"]["aspect no"]} onChange={handleFormPropertiesInputChange} disabled={formData["properties"]["aspect no"] == ""}>
                                        {formData["properties"]["aspect no"] == "" ? <option value={""} disabled></option> : ""}
                                        {aspectQty.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                        </div>
                        <div className='form-sub-title'>Installation status</div>
                        <div className='form-boxes-container'>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Base Status</p>
                                    <select className='input-box' name={"Base"} value={formData["properties"]["Base"]} onChange={installInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Structure Status</p>
                                    <select className='input-box' name={"Structure"} value={formData["properties"]["Structure"]} onChange={installInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Signal Head Status</p>
                                    <select className='input-box' name={"Signal Head"} value={formData["properties"]["Signal Head"]} onChange={installInputChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                        </div>

                        <div className='form-sub-title'>Asset Location</div>
                        <div className='form-boxes-container'>
                            <div class="input-container">
                                {loading ? "" : <FormMap lng={lng} lat={lat} zoom={zoom} handleLngLatMapBox={handleLngLatMapBox} rotation={formData["properties"]["rotation"]} mapIcon={signalIcon} formData={formData} iconSize={150}>

                                </FormMap>}
                                <div className='form-group-column-container'>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Longitude</p>
                                        <input className='input-box' type="number" value={formData["geometry"]["coordinates"][0]} name={'long'} placeholder={"Edit Longitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Latitude</p>
                                        <input className='input-box' type="number" value={formData["geometry"]["coordinates"][1]} name={'lat'} placeholder={"Enter latitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Signal degrees rotation on map</p>
                                        <input className='input-box' type="number" value={parseInt(formData["properties"]["rotation"])} name={'rotation'} placeholder={"Input a rotation..."} onChange={handleRotationNumberInputChange} /><br />
                                    </label>
                                </div>
                            </div>

                            {/* <div class="input-container">
                                <div className='form-group-column-container'>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Signal degrees rotation on map</p>
                                        <input className='input-box' type="number" value={parseInt(formData["properties"]["rotation"])} name={'rotation'} placeholder={"Input a rotation..."} onChange={handleRotationNumberInputChange} /><br />
                                    </label>


                                </div>
                            </div> */}



                        </div>
                    </>
                </DbCard>
            </div >
        </>
    )
}

export default SignalForm