import React, { useState, useContext, useEffect } from 'react'
import './TablePage.css'
import { useLocation, useNavigate } from "react-router-dom";
import { useDataContext, DataContext } from '../../Contexts/DataContext';

import DbCardTable from '../../components/cards/DbCardTable';

import '../../CssGeneral/dashboard.css'

import {
    assetTypeIdentifier
}
    from '../../constants/appSetup/tableContentValues';

const TablePage = () => {

    const data = useContext(DataContext)



    const { findPathnameData, getAllData } = useDataContext()
    const { pathname } = useLocation();
    const dataForPathname = findPathnameData(pathname)
    const filterData = dataForPathname.data;
    const navigate = useNavigate();

    useEffect(() => {
        getAllData()
        if (window.innerWidth < 850)
            navigate("/app/project-summary")
    }, [])


    return (
        <>
            <div className="main-table-page-container">
                {filterData.map((item, index) => {
                    return <DbCardTable
                        key={index + "TF"}
                        cardType={'table-card-wide card-static'}
                        title={item.name}
                        OnClickFuncMainBtn={() => navigate(assetTypeIdentifier(item.name, data).formPathRedirect)}
                        searchParams={assetTypeIdentifier(item.name, data).searchParams}
                        item={item}
                        filterData={filterData}
                        assetTypeIdentifier={(name) => assetTypeIdentifier(name, data)}
                    >

                    </DbCardTable>

                })

                }


            </div>

        </>
    )
}

export default TablePage