import React from 'react'

const CableWtsIcon = () => {
    return (
        <g>
            <rect x="20.3298" y="10.6495" width="1.6677" height="17.2402" fill="#fff" />
            <path d="m18.9349,29.0818l-1.5879-.5081c.5969-1.864,1.7353-6.7353-.5867-11.6922-.9182-1.9601-2.2484-3.6889-3.954-5.1384l1.0798-1.2704c1.8888,1.6042,3.3644,3.5228,4.3848,5.7019,2.5721,5.4918,1.3213,10.8558.6641,12.9071Z" fill="#fff" />
            <path d="m23.9035,29.0818c-.6572-2.0513-1.908-7.4153.6641-12.9071,1.0204-2.1791,2.4955-4.0977,4.3848-5.7028l1.0798,1.272c-1.7056,1.4479-3.0358,3.1767-3.954,5.1376-2.3221,4.9568-1.1836,9.8282-.5867,11.6922l-1.5879.5081Z" fill="#fff" />
            <path d="m12.0021,12.4434c-1.3966,0-2.533-1.1368-2.533-2.5334s1.1364-2.5326,2.533-2.5326,2.533,1.136,2.533,2.5326-1.1364,2.5334-2.533,2.5334Zm0-4.2321c-.9369,0-1.6991.7622-1.6991,1.6987,0,.9373.7622,1.6995,1.6991,1.6995s1.6991-.7622,1.6991-1.6995c0-.9365-.7622-1.6987-1.6991-1.6987Z" fill="#fff" />
            <path d="m30.9984,12.4434c-1.3966,0-2.533-1.1368-2.533-2.5334s1.1364-2.5326,2.533-2.5326,2.5326,1.136,2.5326,2.5326-1.136,2.5334-2.5326,2.5334Zm0-4.2321c-.9369,0-1.6991.7622-1.6991,1.6987,0,.9373.7622,1.6995,1.6991,1.6995s1.6987-.7622,1.6987-1.6995c0-.9365-.7622-1.6987-1.6987-1.6987Z" fill="#fff" />
            <path d="m21.1637,11.5249c-1.3966,0-2.533-1.136-2.533-2.5326s1.1364-2.5334,2.533-2.5334,2.533,1.1368,2.533,2.5334-1.1364,2.5326-2.533,2.5326Zm0-4.2321c-.9369,0-1.6991.7622-1.6991,1.6995,0,.9365.7622,1.6987,1.6991,1.6987s1.6991-.7622,1.6991-1.6987c0-.9373-.7622-1.6995-1.6991-1.6995Z" fill="#fff" />
            <rect x="14.8401" y="23.1991" width="12.4734" height="13.342" fill="#808e93" />
        </g>
    )
}

export default CableWtsIcon;