export const DataFilterFunction = (dataSource, filterList) => {
    const filteredData = dataSource.filter(item => Object.entries(filterList)
        .every(([key, val]) => val !== '' ? item[key] === val : true));
    return filteredData;
}

export const AreaFilterFunction = (dataSource, filter) => {


    const filteredData = dataSource.features.filter(item => {
        return item.properties.location === filter.location
    })
    return (
        {
            "type": "FeatureCollection",
            "name": filter.location + " Data",
            "features": filteredData
        })
}

export const AreaFilterFunctionArray = (dataSource, filter) => {
    const filteredLocationData = dataSource.features.filter(item => {
        return item.properties.location === filter.location
    })
    return filteredLocationData
}

export const TypeFilterFunctionArray = (dataSource, filter) => {
    const filteredTypeData = dataSource.filter(item => {
        return item.properties.type === filter.type
    })
    return filteredTypeData
}

export const MapDataFilterFunction = (dataSource, filterList, itemName) => {

    const filteredData = dataSource.filter(item => {
        return item.properties.type === filterList.type
    })
    return (
        {
            "type": "FeatureCollection",
            "name": itemName,
            "features": filteredData
        })
}

// NEED TO REPLACE MAPDATFILTERFUNCTION WITH THIS EVENTUALLY

export const MapDataFilterFunctionType = (dataSource, filterList, itemName, itemType) => {

    const filteredData = dataSource.filter(item => {
        return item.properties[itemType] === filterList[itemType]
    })
    return (
        {
            "type": "FeatureCollection",
            "name": itemName,
            "features": filteredData
        })
}


export const MapDataStatusFilterFunction = (dataSource, filterList, filterName, itemName) => {

    const filteredData = dataSource.filter(item => {

        return item.properties[filterName] === filterList[filterName]
    })
    return (
        {
            "type": "FeatureCollection",
            "name": itemName,
            "features": filteredData
        })
}

// Needs looking at, the keys need to be added and combined dynamically.
export const MapDataCombiner = (dataGrouplist) => {
    let combinedData = [];
    dataGrouplist.forEach((item, index) => {
        combinedData = [...combinedData, ...item.features]
    })
    return (
        {
            "type": "FeatureCollection",
            "name": "Data Collection",
            "features": combinedData
        })

}

export const MapGroupedDataCombiner = (dataGrouplist) => {
    let combinedData = [];
    dataGrouplist.forEach((item, index) => {
        combinedData.push(item);
    })
    return combinedData;

}