// This dictates the content of the header and mobile size

// Icons for the header
import HamburgerButton from "../../assets/GeneralIcons/CLP-_IconHamburger.svg";
import LeftLogo from "../../assets/GeneralIcons/alstom-logo-white.svg";
import LeftLogoRight from "../../assets/GeneralIcons/OctaviusWhite-01.svg";
import RightLogo from "../../assets/GeneralIcons/NRLogoWhite.svg";

// Icons for the header


export const headerContent = {
    LeftLogo,
    LeftLogoRight,
    RightLogo,
    HamburgerButton,
    title: "F2P Resignalling Project",
    "mobile-breakpoint": 700


}