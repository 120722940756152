import React from 'react'

const OverviewGeneralIcon = () => {
    return (
        <g id="Icons">
            <g>
                <g>
                    <polygon points="6.2399 39.8139 12.3893 39.8139 12.3893 21.9987 6.2399 16.2872 6.2399 39.8139" fill="#0f516e" />
                    <polygon points="15.4027 24.7974 15.4027 39.8139 21.5522 39.8139 21.5522 24.8098 18.8845 28.0312 15.4027 24.7974" fill="#0f516e" />
                    <polygon points="26.6744 33.0094 30.5353 30.1088 24.5655 22.7354 24.5655 39.8139 30.7149 39.8139 30.7149 34.7291 26.6744 33.0094" fill="#0f516e" />
                </g>
                <polygon points="41.2818 36.9001 39.9111 25.7432 36.9453 27.9713 23.9798 11.9576 18.283 18.8372 4.5025 6.0382 1.7182 9.0356 18.6719 24.7823 23.9444 18.4154 33.6721 30.43 30.9263 32.4925 41.2818 36.9001" fill="#bce3f4" />
            </g>
        </g>
    )
}
export default OverviewGeneralIcon