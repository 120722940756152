import React from 'react'

const SightingIcon = () => {
    return (
        <g id="Icons">
            <path d="M30.2613,10.0218v13.7017c0,.7848,.6362,1.421,1.421,1.421h2.8138c.9754,0,1.7661-.7907,1.7661-1.7661V10.0852c0-.7089-.5747-1.2836-1.2836-1.2836h-3.4971c-.6739,0-1.2202,.5463-1.2202,1.2202Zm3.4,13.3768c-1.1957,.2882-2.2457-.7618-1.9574-1.9575,.1355-.562,.5964-1.0229,1.1584-1.1584,1.1957-.2883,2.2457,.7617,1.9575,1.9574-.1355,.562-.5964,1.023-1.1584,1.1584Zm-.0002-4.9592c-1.1956,.2881-2.2454-.7617-1.9573-1.9573,.1354-.562,.5962-1.0229,1.1582-1.1585,1.1959-.2885,2.2462,.7618,1.9576,1.9576-.1356,.562-.5965,1.0228-1.1585,1.1582Zm0-4.7761c-1.1956,.2881-2.2454-.7617-1.9573-1.9573,.1354-.562,.5962-1.0229,1.1582-1.1585,1.1959-.2885,2.2462,.7618,1.9576,1.9576-.1356,.562-.5965,1.0228-1.1585,1.1582Z" fill="#373d40" />
            <g>
                <polygon points="10.6914 33.6745 10.1405 35.0791 22.5717 35.0791 22.0208 33.6745 10.6914 33.6745" fill="#e1e5f2" />
                <polygon points="10.028 29.9876 7.8168 35.8691 9.2171 35.8691 11.4284 29.9876 10.028 29.9876" fill="#e1e5f2" />
                <polygon points="21.2837 29.9876 23.4949 35.8691 24.8952 35.8691 22.6842 29.9876 21.2837 29.9876" fill="#e1e5f2" />
                <polygon points="11.0598 32.3577 21.6524 32.3577 21.2837 31.1287 11.4284 31.1287 11.0598 32.3577" fill="#e1e5f2" />
                <path d="M17.1519,7.1321v-.0012h-1.3674c-7.2196,.4102-9.0467,7.6201-9.0467,14.5088,0,4.8498,3.3833,7.2192,6.1473,8.2402,.289-.6056,.8599-1.0336,1.5162-1.0336h3.7741c.6888,0,1.2825,.471,1.5562,1.1264,2.908-.9658,6.4114-3.3344,6.4114-8.3371,0-6.8721-1.7925-14.0621-8.991-14.5035Zm-4.096,2.8845c0-.5575,.4519-1.0094,1.0094-1.0094h4.3017c.5574,0,1.0094,.4519,1.0094,1.0094v.0002c0,.5575-.4519,1.0094-1.0094,1.0094h-4.3017c-.5574,0-1.0094-.4519-1.0094-1.0094v-.0002Zm-.3663,17.2013c-.6945,.6512-1.9838,.2122-2.8795-.9809-.8956-1.1928-1.0587-2.6878-.3642-3.3392,.6945-.6513,1.9837-.2121,2.879,.9809,.8958,1.1929,1.0589,2.6878,.3647,3.3392Zm3.7507-4.7205c-3.4404,0-6.9842-3.1144-6.9842-6.6097s3.5438-3.2907,6.9842-3.2907,6.9845-.2046,6.9845,3.2907-3.5443,6.6097-6.9845,6.6097Zm6.6301,3.7396c-.8956,1.1931-2.1848,1.6321-2.8791,.9809-.6945-.6514-.5314-2.1463,.3644-3.3392,.8955-1.193,2.1845-1.6322,2.8791-.9809,.6943,.6514,.5313,2.1464-.3644,3.3392Z" fill="#333" />
            </g>
            <rect x="32.5513" y="25.1444" width="1.7623" height="10.6341" fill="#e1e5f2" />
        </g>
    )
}
export default SightingIcon 