// MAP COMPONENT
import { MapSymbol } from '../ComponentGeneral/MapSymbol'
import { JSONDataFilterByProperty, JSONDataFilterByDualProperty } from '../../../../constants/functions/jsonDataFilterFunctions'
// IMAGES
import { equipmentMapSymbols } from '../../../../constants/MapSymbols/EquipmentMapSymbols'
import { uniqueEquipmentTypeIdentifier } from './functions/uniqueEquipmentTypeIdentifier'
import { equipmentDotDisplayStyles } from '../../../../constants/styles/mapStyles'

export const EquipCivilsDots = (map, isVisible, createPopUp, pathname, EquipmentCubicleCivilsData, routesStructure, tableContent) => {

    // Base data for these asset types
    const data = EquipmentCubicleCivilsData

    // Filter by status
    const existingBaseData = JSONDataFilterByProperty(data, { "Base": "Existing" }, "Existing", "Base")
    const existingStructureData = JSONDataFilterByProperty(data, { "Structure": "Existing" }, "Existing", "Structure")
    const outstandingData = JSONDataFilterByProperty(data, { "Base": "Outstanding" }, "Outstanding", "Base")
    const baseCompleteData = JSONDataFilterByDualProperty(data, { "Base": "Complete" }, "BaseComplete", "Base", { "Structure": "Outstanding" }, "Outstanding", "Structure")
    const cubicleCompleteData = JSONDataFilterByProperty(data, { "Structure": "Complete" }, "Complete", "Structure")

    const routePath = [routesStructure.civils_all_route, routesStructure.civils_equipment_route]

    const equipmentTypes = uniqueEquipmentTypeIdentifier(equipmentMapSymbols)
    const disciplineTypes = ["civils", "signals"]

    const baseInfo = {
        map: map,
        isVisible,
        createPopUp: createPopUp,
        relatedPathNames: routePath,
        tableValues: tableContent,
        currentPathName: pathname,
        ...equipmentDotDisplayStyles
    }
    const symbolsData = equipmentTypes.map(equipmentType => {
        const objects = [];
        objects.push({
            // Dots
            data: JSONDataFilterByProperty(existingBaseData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
            source: `${equipmentType}-Dot-${disciplineTypes[0]}-base-existing`,
            imageSource: equipmentMapSymbols[`${equipmentType}DotExisting`],
            imageName: `${equipmentType}-Dot-${disciplineTypes[0]}-base-existing-image`,
            ...baseInfo
        },
            {
                // Dots
                data: JSONDataFilterByProperty(existingStructureData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
                source: `${equipmentType}-Dot-${disciplineTypes[0]}-structure-existing`,
                imageSource: equipmentMapSymbols[`${equipmentType}DotExisting`],
                imageSource: equipmentMapSymbols.LocDotExisting,
                imageName: `${equipmentType}-Dot-${disciplineTypes[0]}-structure-existing-image`,
                ...baseInfo
            },
            {
                // Dots
                data: JSONDataFilterByProperty(outstandingData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
                source: `${equipmentType}-Dot-${disciplineTypes[0]}-Civils-Outstanding`,
                imageSource: equipmentMapSymbols[`${equipmentType}DotOutstanding`],
                imageName: `${equipmentType}-Dot-${disciplineTypes[0]}Civils-Outstanding-image`,
                ...baseInfo
            },
            {
                // Dots
                data: JSONDataFilterByProperty(baseCompleteData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
                source: `${equipmentType}-Dot-${disciplineTypes[0]}-base-complete`,
                imageSource: equipmentMapSymbols[`${equipmentType}DotComplete`],
                imageName: `${equipmentType}-Dot-${disciplineTypes[0]}base-complete-image`,
                ...baseInfo
            },
            {
                // Dots
                data: JSONDataFilterByProperty(cubicleCompleteData, { "description": equipmentType.toUpperCase(), }, equipmentType.toUpperCase(), "description"),
                source: `${equipmentType}-Dot-${disciplineTypes[0]}-complete`,
                imageSource: equipmentMapSymbols[`${equipmentType}DotComplete`],
                imageName: `${equipmentType}-Dot-${disciplineTypes[0]}-complete-image`,
                ...baseInfo
            }
        )
        return objects
    }).flat()

    symbolsData.forEach((symbolData) => MapSymbol(symbolData));




}
