export const mapLineStringPushFactory = (features) => {
    const coordinatesFactory = (coordinates) => coordinates.map((coordinate => {
        return { lat: coordinate[0], long: coordinate[1] }
    }));
    return features.map(feature => {
        let coordinates = feature.geometry.coordinates;
        feature.geometry.coordinates = coordinatesFactory(coordinates);
        return feature
    })

}