import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../Contexts/AuthContext'

import { DataContext } from '../../Contexts/DataContext';
import { useNavigate, Link, useParams } from 'react-router-dom'

import { db, uploadFile } from '../../firebase'

import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'


import { addUpdateLog } from '../../constants/databaseFunctions/UpdateLog'

import DbCard from '../../components/cards/DbCard'

import './form.css'


const ProjectInfoForm = () => {

    const params = useParams();
    const navigate = useNavigate()
    const selectedItemId = params.id
    // const selectedItemId = "Uvgq9J6OZMwCwcTKuz4n"


    const userData = useContext(UserContext)
    const { data, getAllData } = useContext(DataContext)



    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`


    let defaultFormData = {}
    // IE CREATE 
    // if (!selectedItemId || selectedItemId === undefined) {

    //     defaultFormData["dateCreated"] = new Date()
    //     defaultFormData["createdBy"] = logInUser

    // }
    // IE UPDATE
    if (selectedItemId) {
        defaultFormData["dateUpdated"] = new Date()
        defaultFormData["updatedBy"] = logInUser
        // defaultFormData["cablePercentage"] = ""
        // defaultFormData["testingPercentage"] = ""
    }

    const [formData, setFormData] = useState(defaultFormData);

    const handleFormInputChange = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            [name]: value
        }))


    }


    useEffect(() => {
        if (params.id) {
            const docRef = doc(db, 'ProjectDetails',
                selectedItemId)
            getDoc(docRef).then(snapshot => {
                setFormData({ ...snapshot.data(), id: snapshot.id })
            })
        }
    }, [])

    const [loadingData, setLoadingData] = useState(false)

    const updateDataOnFireBase = async () => {
        setLoadingData(true)
        const collectionRef = doc(db, "ProjectDetails", selectedItemId)
        await setDoc(collectionRef, formData);

        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "Project Details updated",
            update: `${userName} updated the project details`
        })
        setLoadingData(false)
        navigate(`/app/project-summary`)
        getAllData()


        // window.location.reload(false);
    }

    let mainBtnTitle = selectedItemId ? "Update" : "Update"
    let btnFunction = selectedItemId ? updateDataOnFireBase : updateDataOnFireBase



    return (
        <>

            <div className="dashboard-container">
                <DbCard
                    cardType={'graph-card-wide card-height-min card-static'}

                    title={"Project Info"}
                    cardContent={""}
                    btnText={"Go back"}
                    mainBtnText={`${mainBtnTitle} Project`}
                    btnDisplayBool={true}
                    loadingData={loadingData}
                    btnMainDisplayBool={true}
                    OnClickFuncMainBtn={btnFunction}
                    OnClickFunc={() => navigate(-1)}>

                    <>

                        {/* <div className='form-boxes-container'>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Cabling Percentage</p>
                                    <input className='input-box' type="number" value={formData["cablePercentage"]} name={"cablePercentage"} placeholder={"Edit percentage..."} onChange={handleFormInputChange} min={0} max={100} /><br />
                                </label>
                            </div>
                        </div> */}

                        <div className='form-boxes-container'>
                            <div class="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Testing Percentage</p>
                                    <input className='input-box' type="number" value={formData["testingPercentage"]} name={"testingPercentage"} placeholder={"Edit percentage..."} onChange={handleFormInputChange} min={0} max={100} /><br />
                                </label>
                            </div>
                        </div>

                    </>
                </DbCard>


            </div >
        </>
    )
}

export default ProjectInfoForm