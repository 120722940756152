import React, { useEffect, useState } from "react";

import PieChartData from "../constants/appSetup/PieChartData";


// Firebase
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { UserAuth } from "./AuthContext";

import CableData from '../tempData/PowerRouteProposed.json'

import { collectionList } from "../constants/appSetup/collections"

import { routeSettings } from "../constants/dataContextComponents/routeSettings";

import Loading from "../components/Loading/Loading";

import { geoJsonFromFeatureFactory } from "../constants/functions/geoJsonFromFeatureFactory";
import { mapLineStringPushFactory } from "../constants/functions/mapLineStringPushFactory";

import getDropDownMenuTitles from "../constants/appSetup/dropDownMenuTitles";


export const DataContext = React.createContext({});


// Firebase review functions
export const firebaseConsoleLog = (message, type = "message") => {
    const colors = {
        message: 'F7B05B',
        warning: "CF401D"
    }
    // console.log(`%c[FIREBASE] ${message}`, `background: #${colors[type]}; color: #1F1300; padding:5px;`)
}

const getAllCollectionData = (collectionNames) => {
    let collectionState = {}
    let promises = [];
    const updateCollectionState = (data) => {
        collectionState = { ...collectionState, ...data };
    }
    const collectionDataTemplate = (collectionName, collectionData) => {
        const lowerCaseFirstLetterOfString = (string) => string.charAt(0).toLowerCase() + string.slice(1);
        return {
            loading: false,
            [lowerCaseFirstLetterOfString(collectionName)]: collectionData
        }
    }
    const promiseFunction = (collectionName) => {
        const pseudoPromise = new Promise((resolve, reject) => {
            getDocs(collection(db, collectionName)).then((snapshot) => {
                const collectionData = snapshot.docs.filter((doc) => Object.keys(doc.data()).length > 0).map((doc) => ({ ...doc.data(), id: doc.id, type: collectionName }));
                updateCollectionState(collectionDataTemplate(collectionName, collectionData));
                resolve()
                firebaseConsoleLog("Fetched collection data for: " + collectionName + " With length of " + collectionData.length)
            });
        })
        return pseudoPromise;
    }

    collectionNames.forEach((collectionName) => {
        promises.push(promiseFunction(collectionName));
    })
    const lastPromise /* hahaha! */ = new Promise((resolve, reject) => {
        Promise.all(promises).then(() => {
            resolve(collectionState);
            firebaseConsoleLog("Fetched all collection data")

        })
    })
    return lastPromise;
}

export const DataContextProvider = ({ children }) => {
    const { user } = UserAuth();
    const [{ data, isLoading }, setState] = useState({ isLoading: true })

    const findPathnameData = (path) => {

        if (["/", "project-summary", "/project-summary", "/app/project-summary"].includes(path)) {
            path = "/app/civils-works/all-works"
        }
        const relevantPathObjects = dropDownMenuTitles.filter((item) => !(["Display", "Summary"].includes(item.title)))
        let matchedPathObject = null
        relevantPathObjects.forEach((relatedPathData) => {
            relatedPathData.subMenu.every((subMenuItem) => {

                if (path == `${subMenuItem.path}`) {
                    matchedPathObject = subMenuItem;
                    return false;
                }
                return true;
            });
        })
        return matchedPathObject;
    }


    useEffect(() => {
        const pushData = mapLineStringPushFactory(CableData.features)
        // uploadDataToFireBase(pushData, "PowerCables")

    }, [])

    function getAllData() {
        const collections = collectionList

        if (user)
            getAllCollectionData(collections).then((collectionState) => {
                console.log(collectionState, "collectionState checkData")
                let fireBaseGeoJsonData = geoJsonFromFeatureFactory(collectionState)
                fireBaseGeoJsonData.PowerCablesData.features = fireBaseGeoJsonData.PowerCablesData.features.map((item) => {
                    item.geometry.coordinates = item.geometry.coordinates.map((coordinate) => {
                        return coordinate.reverse();
                    })
                    return item;
                })
                fireBaseGeoJsonData.FibreCablesData.features = fireBaseGeoJsonData.FibreCablesData.features.map((item) => {
                    item.geometry.coordinates = item.geometry.coordinates.map((coordinate) => {
                        return coordinate.reverse();
                    })
                    return item;
                })
                setState({ data: { ...data, ...fireBaseGeoJsonData } });
                PieChartData.setCivils({ SignalCivilsData: fireBaseGeoJsonData.SignalCivilsData, EquipmentCubicleData: fireBaseGeoJsonData.EquipmentCubicleCivilsData, StructuresData: fireBaseGeoJsonData.StructuresData, UTXProposedCentresData: fireBaseGeoJsonData.UTXProposedCentresData })
                PieChartData.setSignalling({ SignalCivilsData: fireBaseGeoJsonData.SignalCivilsData, EquipmentCubicleData: fireBaseGeoJsonData.EquipmentCubicleCivilsData })
                PieChartData.setCabling({ PowerCablesData: fireBaseGeoJsonData.PowerCablesData, FibreCablesData: fireBaseGeoJsonData.FibreCablesData })

                let documentCount = 0;
                Object.keys(collectionState).filter(key => key != "loading").forEach((key) => {
                    documentCount += collectionState[key].length
                })
                firebaseConsoleLog("All data fetched with a total document count of " + documentCount)
            })
        firebaseConsoleLog("Component did mount")

    }

    useEffect(() => {
        if (user) getAllData()
    }, [user]);

    if (isLoading) return <Loading />


    const dropDownMenuTitles = getDropDownMenuTitles({ data })

    console.log(data, "checkData")

    return <DataContext.Provider value={{ ...data, findPathnameData, dropDownMenuTitles, routeSettings, dataLoading: isLoading, getAllData }}> {children}</DataContext.Provider >

}


export const useDataContext = () => React.useContext(DataContext);