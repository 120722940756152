import React from 'react'

import { useNavigate } from "react-router-dom";

import './pages.css'

const NoPageAvailable = () => {

    const navigate = useNavigate();

    const backRedirect = () => {
        navigate(`/app/project-summary`)

    }


    return (

        <div className='no-data-container'>
            <div className='go-back-header'> This content isnt available with your profile, contact your administrator</div>
            <button
                className={"noview-btn"}
                onClick={backRedirect}>
                <div className='btn-go-back-text'>Go back</div>

            </button>

        </div>

    )
}

export default NoPageAvailable