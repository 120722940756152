import React from 'react'

const SignalAwsIcon = () => {
    return (
        <g>
            <path d="M40.87,22.19l-6.69-4.87a2.37,2.37,0,0,0-1.63-.65h-.37V19.9h-.91V16.67h-1V19.9h-.91V16.67h-.61V19.9h-.9V16.67H15.91V19.9H15V16.67H14V19.9h-.91V16.67h-.6V19.9h-.91V16.67h-1.4a2.43,2.43,0,0,0-1.67.68L2.09,22.22a2.4,2.4,0,0,0,1.67,4.11H39.24A2.39,2.39,0,0,0,40.87,22.19Z" fill="#0f516e" />
        </g>
    )
}

export default SignalAwsIcon;
