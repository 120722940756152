
// GENERAL
import CableGeneralIcon from '../../assets/pieIcons/CableGeneralIcon'
import CivilsGeneralIcon from '../../assets/pieIcons/CivilsGeneralIcon'
import SignallingGeneralIcon from '../../assets/pieIcons/SignallingGeneralIcon'
import OverviewGeneralIcon from '../../assets/pieIcons/OverviewGeneralIcon'
import TestingGeneralIcon from '../../assets/pieIcons/TestingGeneralIcon'

import SightingIcon from '../../assets/pieIcons/SightingIcon'
import TrialHoleIcon from '../../assets/pieIcons/TrialHoleIcon'
import SurveyIcon from '../../assets/pieIcons/SurveyIcon'


const Icon = {
    CableGeneralIcon,
    OverviewGeneralIcon,
    CivilsGeneralIcon,
    SignallingGeneralIcon,
    TestingGeneralIcon,
    SightingIcon,
    TrialHoleIcon,
    SurveyIcon
};

export default Icon;
