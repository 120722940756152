import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';


import MapIcon from './floatBtnsvg/FBIconMap';
import MapDarkIcon from './floatBtnsvg/FBIconMapDark';
import SummaryIcon from './floatBtnsvg/FBIconSummary';
import TableIcon from './floatBtnsvg/FBIconTable';
import TimelineIcon from './floatBtnsvg/FBIconTimeline';
import SchematicIcon from './floatBtnsvg/FBIconSchematic';
import DashboardIcon from './floatBtnsvg/FBIconDashboard';

import './Options.css';

const Options = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get("type");
    const { pathname } = useLocation();

    const homePath = "/app/project-summary"

    const navigate = useNavigate()

    const summaryRedirect = () => {
        navigate(homePath)

    }
    const tableRedirect = () => {
        typeParam !== null ? navigate(`${pathname}?type=table`) :
            navigate(`/app/civils-works/all-works?type=table`)
    }

    const geoMapRedirect = () => {
        typeParam !== null ? navigate(`${pathname}?type=geographic`)
            : navigate(`/app/civils-works/all-works?type=geographic`)
    }

    const mapRedirect = () => {
        typeParam !== null ? navigate(`${pathname}?type=map`)
            : navigate(`/app/civils-works/all-works?type=map`)
    }


    const schematicRedirect = () => {
        typeParam !== null ? navigate(`${pathname}?type=schematic`)
            : navigate(`/app/civils-works/all-works?type=schematic`)
    }

    const dashboardRedirect = () => {
        typeParam !== null ? navigate(`${pathname}?type=dashboard`)
            : navigate(`/app/civils-works/all-works?type=dashboard`)
    }



    // const fill = "#373e40";
    const fill = "#fff";



    const [btn, setBtn] = useState(1)


    function arrangeIcons(btn) {
        let st = (+48) + (-(48) * btn)
        document.querySelectorAll('.float-icon-container').forEach(
            icon => {
                icon.style.left = `${st}px`;
                st += 48;
            }
        )
    }

    useEffect(() => {


        arrangeIcons(btn)
    }, [btn])



    return (
        <div className="options-container" onMouseOver={arrangeIcons.bind(null, 1)} onMouseOut={arrangeIcons.bind(null, btn)}>


            <SummaryIcon onClick={() => {
                setBtn(1)
                summaryRedirect()
            }} fill={fill} />



            <TableIcon fill={fill} onClick={() => {
                setBtn(2)
                tableRedirect()
            }} />

            <MapDarkIcon fill={fill} onClick={() => {
                setBtn(4)
                mapRedirect()
            }} />

            <MapIcon fill={fill} onClick={() => {
                setBtn(3)
                geoMapRedirect()
            }} />

            <SchematicIcon fill={fill} onClick={() => {
                setBtn(5)
                schematicRedirect()
            }} />

            <DashboardIcon fill={fill} onClick={() => {
                setBtn(6)
                dashboardRedirect()
            }} />




        </div >
    );
};

export default Options;
