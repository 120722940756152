import React from 'react'

const OtxIcon = () => {
    return (
        <g>
            <g>
                <g>
                    <rect x="2.75" y="5.14" width="37.5" height="6.94" fill="#bce3f4" />
                    <g>
                        <rect x="4.45" y="11.83" width="2.52" height="25.06" fill="#fff" />
                        <path d="M7.16,37H4.25V11.69H7.16Zm-2.52-.27H6.77V12H4.64Z" fill="#bce3f4" />
                    </g>
                    <rect x="3.93" y="11.96" width="0.52" height="24.82" fill="#bce3f4" />
                    <rect x="6.97" y="11.96" width="0.52" height="24.82" fill="#bce3f4" />
                    <rect x="3.48" y="5.92" width="5.49" height="5.49" fill="#fff" />
                    <rect x="9.61" y="5.92" width="5.49" height="5.49" fill="#fff" />
                    <rect x="15.75" y="5.92" width="5.49" height="5.49" fill="#fff" />
                    <rect x="21.88" y="5.92" width="5.49" height="5.49" fill="#fff" />
                    <rect x="28.02" y="5.92" width="5.49" height="5.49" fill="#fff" />
                    <rect x="34.15" y="5.92" width="5.49" height="5.49" fill="#fff" />
                    <rect x="6.09" y="4.72" width="0.34" height="7.93" transform="translate(7.98 -1.88) rotate(45)" fill="#bce3f4" />
                    <rect x="6.09" y="4.72" width="0.34" height="7.93" transform="translate(16.83 10.41) rotate(135)" fill="#bce3f4" />
                    <rect x="12.19" y="4.72" width="0.34" height="7.93" transform="translate(9.76 -6.19) rotate(45)" fill="#bce3f4" />
                    <rect x="12.19" y="4.72" width="0.34" height="7.93" transform="translate(27.24 6.1) rotate(135)" fill="#bce3f4" />
                    <rect x="18.29" y="4.72" width="0.34" height="7.93" transform="translate(11.55 -10.51) rotate(45)" fill="#bce3f4" />
                    <rect x="18.29" y="4.72" width="0.34" height="7.93" transform="translate(37.65 1.78) rotate(135)" fill="#bce3f4" />
                    <rect x="24.39" y="4.72" width="0.34" height="7.93" transform="translate(13.34 -14.82) rotate(45)" fill="#bce3f4" />
                    <rect x="24.39" y="4.72" width="0.34" height="7.93" transform="translate(48.06 -2.53) rotate(135)" fill="#bce3f4" />
                    <rect x="30.48" y="4.72" width="0.34" height="7.93" transform="translate(15.12 -19.13) rotate(45)" fill="#bce3f4" />
                    <rect x="30.48" y="4.72" width="0.34" height="7.93" transform="translate(58.47 -6.84) rotate(135)" fill="#bce3f4" />
                    <rect x="36.58" y="4.72" width="0.34" height="7.93" transform="translate(16.91 -23.44) rotate(45)" fill="#bce3f4" />
                    <rect x="36.58" y="4.72" width="0.34" height="7.93" transform="translate(68.88 -11.15) rotate(135)" fill="#bce3f4" />
                    <rect x="3.24" y="36.73" width="4.62" height="0.58" fill="#bce3f4" />
                    <g>
                        <rect x="36.24" y="11.83" width="2.52" height="25.06" fill="#fff" />
                        <path d="M39,37H36V11.69H39Zm-2.52-.27h2.13V12H36.43Z" fill="#bce3f4" />
                    </g>
                    <rect x="35.72" y="11.96" width="0.52" height="24.82" fill="#bce3f4" />
                    <rect x="38.75" y="11.96" width="0.52" height="24.82" fill="#bce3f4" />
                    <rect x="35.02" y="36.73" width="4.62" height="0.58" fill="#bce3f4" />
                </g>
                <rect x="7.59" y="12.08" width="28.13" height="1.89" fill="none" stroke="#0f516e" strokeMiterlimit="10" strokeWidth="0.23" />
                <rect x="33.53" y="13.97" width="2.19" height="22.83" fill="none" stroke="#0f516e" strokeMiterlimit="10" strokeWidth="0.23" />
                <rect x="7.87" y="13.97" width="2.19" height="22.83" fill="none" stroke="#0f516e" strokeMiterlimit="10" strokeWidth="0.23" />
                <path d="M8.46,37.57a11.32,11.32,0,0,0,.28-3c-.07-1.7-.48-5.66-.5-7.12,0-1.68.51-1.52.78-3.79a22.29,22.29,0,0,0-.11-5.12c-.07-.58-.15-1.13-.22-2.12-.14-1.73-.2-2.6,0-2.95.48-.74,1.66-.64,5.93-.31a41.43,41.43,0,0,0,6,.34c2.39-.18,2.49-.6,4.37-.5,2.4.13,2.67.85,4.45.55L32.75,13a1.52,1.52,0,0,1,1.14-.06c.72.36.76,1.64.78,2.26a22.77,22.77,0,0,1-.42,3,18.4,18.4,0,0,0,.31,2.78c.12,1.93-.38,2-.39,4.07,0,.83.07.83.42,4,.11,1,.38,7.16.43,7.73" fill="none" stroke="#49b9b5" strokeMiterlimit="10" strokeWidth="0.23" stroke-dasharray="0.9 0.9" />
                <g>
                    <rect x="22.96" y="34.89" width="0.32" height="1.36" fill="#0f516e" />
                    <rect x="22.57" y="34.68" width="1.16" height="0.26" fill="#0f516e" />
                    <rect x="22.22" y="36.12" width="1.79" height="0.25" fill="#0f516e" />
                </g>
                <g>
                    <rect x="18.85" y="34.89" width="0.32" height="1.36" fill="#0f516e" />
                    <rect x="18.45" y="34.68" width="1.16" height="0.26" fill="#0f516e" />
                    <rect x="18.1" y="36.12" width="1.79" height="0.25" fill="#0f516e" />
                </g>
                <path d="M25.1,18.4h-1a1.09,1.09,0,0,0-1.08-1H19.15a1.07,1.07,0,0,0-1.06,1h-.92a2.88,2.88,0,0,0-2.87,2.88V32l1.88,1.62h.37v1h8.82v-.94h.5l1.94-1.65v-11A2.71,2.71,0,0,0,25.1,18.4ZM19.41,19h3.33v1.29H19.41ZM17.05,31.34a1,1,0,1,1,1-1A1,1,0,0,1,17.05,31.34Zm8,0a1,1,0,1,1,1-1A1,1,0,0,1,25.08,31.34Zm1.35-7.1a1.28,1.28,0,0,1-1.28,1.28H17a1.25,1.25,0,0,1-1.26-1.26V22.47a.94.94,0,0,1,.94-.94h8.72a1.07,1.07,0,0,1,1.07,1.07Z" fill="#bce3f4" opacity="0.6" />
            </g>
        </g>
    )
}

export default OtxIcon;
